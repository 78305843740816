import * as React from "react";
import { Icon, List, ListItem } from "semantic-ui-react";
import { CaseItem, Description, StyleIcon, Title } from "./MoveDuplicateModal.style";
import { SearchSuitcaseType } from "../MoveDuplicateModal";

interface IModalListItem {
  item: SearchSuitcaseType;
  selectedID: number;
  itemClickHandler: (() => void) | undefined;
  hideArrowIcon?: boolean;
}

export const ModalListItem = ({ item, selectedID, itemClickHandler, hideArrowIcon }: IModalListItem): JSX.Element => {
  const { id, name, number_child_suitcases, number_insights_recursive, is_shared, has_child_suitcases } = item;
  const ifMultiLevelSuitcase = (number_child_suitcases && number_child_suitcases > 0) || has_child_suitcases;

  // Create description text:
  // - will only be displayed in the normal suitcases list
  // - use conditional checks to hide relative text gracefully when that data is unavailable
  const description = `
    ${is_shared === undefined ? "" : is_shared ? "Shared" : "Private"}
    ${number_child_suitcases || number_insights_recursive ? " -" : ""}
    ${number_child_suitcases ? `${number_child_suitcases} suitcases${number_child_suitcases > 1 ? "s" : ""}` : ""}
    ${number_insights_recursive ? ` | ${number_insights_recursive} insight${number_insights_recursive > 1 ? "s" : ""}` : ""}
  `;

  return (
    <ListItem key={id} className={`p-0 ${itemClickHandler ? "cursor-pointer" : "cursor-default"}`} onClick={itemClickHandler}>
      <CaseItem active={selectedID === id} disabled={!itemClickHandler}>
        <div className="d-flex align-items-center">
          <StyleIcon>
            <Icon name={!!ifMultiLevelSuitcase ? "folder" : "briefcase"} style={{ marginRight: "7px", fontSize: "30px" }} />
          </StyleIcon>
          <div className="d-flex align-items-start flex-column">
            <Title>{name}</Title>
            <Description>{description}</Description>
          </div>
        </div>
        {!!ifMultiLevelSuitcase && !hideArrowIcon && (
          <List.Content floated="right">
            <Icon className="mr-4" name="angle right" size="large" />
          </List.Content>
        )}
      </CaseItem>
    </ListItem>
  );
};
