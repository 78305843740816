import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Button, Form, Icon, Message, Modal } from "semantic-ui-react";
import { Field, Form as FinalForm } from "react-final-form";
import { composeValidators, mustBeValidEmail, required } from "common/helpers/finalForm";
import { FieldInput } from "pages/DatasetTemplateTable/includes/FieldInput";
import { getMixpanel } from "common/api";
import { FORM_ERROR } from "final-form";

interface IInvitePartnerModal {
  store?: any;
  isOpen: boolean;
  closeModal: () => void;
}

export const InvitePartnerModalComponent = ({ store, isOpen, closeModal }: IInvitePartnerModal): JSX.Element => {
  const onSubmit = async ({ email }): Promise<any> => {
    const error = await store.group.invitePartnerOrganisation(store.user.group.id, email);
    if (error) {
      return { [FORM_ERROR]: error };
    } else {
      getMixpanel(store).track("Invite Partner Organisation", { Page: "Settings > Organisation > Invitations" });
      closeModal();
    }
  };

  return (
    <Modal open={isOpen} onClose={closeModal} closeOnDimmerClick={false} size="small">
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit, submitError, form }) => {
          const { getFieldState } = form;
          const fieldMeta: any = getFieldState("email");
          return (
            <>
              <Modal.Header>Invite Partner Organisations</Modal.Header>
              <Modal.Content>
                <p className="fs-1250">Input the owner's email of the other organisation to become partner organisations.</p>
                <Form onSubmit={handleSubmit}>
                  <Field name="email" label="Email" component={FieldInput} validate={composeValidators(required, mustBeValidEmail)} />
                </Form>
                {submitError && !fieldMeta.modifiedSinceLastSubmit && (
                  <Message className="py-2 px-3 mt-4" size="mini" compact error>
                    <Icon name="info circle" />
                    {submitError}
                  </Message>
                )}
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={closeModal}>Cancel</Button>
                <Button color="purple" onClick={handleSubmit}>
                  Invite
                </Button>
              </Modal.Actions>
            </>
          );
        }}
      />
    </Modal>
  );
};

export const InvitePartnerModal = inject((stores: any) => ({
  store: stores.store,
}))(observer(InvitePartnerModalComponent));
