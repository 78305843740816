import * as React from "react";
import { components } from "react-select";
import { Icon } from "semantic-ui-react";

export const MultiValueRemove = (props) => {
  if (props.data.isLocked) {
    return (
      <components.MultiValueRemove {...props}>
        <Icon name="lock" size="tiny" />
      </components.MultiValueRemove>
    );
  } else {
    return <components.MultiValueRemove {...props} />;
  }
};
