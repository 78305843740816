import * as React from "react";
import validator from "validator";
import { Button, Form, Icon, Input, Modal, Dropdown, Divider, Checkbox, Header, Popup } from "semantic-ui-react";
import { IContributor } from "common/store/dataset";
import { formatCronString, getScheduledReminderDates, ISODateToLocalDate } from "common/helpers/dataset";

interface ITemplateContributorModal {
  isOpen: boolean;
  contributorDetails?: IContributor;
  closeModal: () => void;
  saveModal: (contributor) => any;
  deleteClickHandler?: ({ id, name, email }) => void;
  actionText: "Add" | "Update";
}

interface IShowError {
  first_name: null | string;
  last_name: null | string;
  email: null | string;
}

interface ICronString {
  weekdayNumber: number | null;
  weekday: string | null;
  month: number | null;
}

const weekdayNumberOptions = [
  { key: 1, value: 1, text: "1st" },
  { key: 2, value: 2, text: "2nd" },
  { key: 3, value: 3, text: "3rd" },
  { key: 4, value: 4, text: "4th" },
];

const weekdayOptions = [
  { key: 1, value: "MON", text: "Monday" },
  { key: 2, value: "TUE", text: "Tuesday" },
  { key: 3, value: "WED", text: "Wednesday" },
  { key: 4, value: "THU", text: "Thursday" },
  { key: 5, value: "FRI", text: "Friday" },
  { key: 6, value: "SAT", text: "Saturday" },
  { key: 7, value: "SUN", text: "Sunday" },
];

const monthOptions = [
  { key: 1, value: 1, text: 1 },
  { key: 2, value: 2, text: 2 },
  { key: 3, value: 3, text: 3 },
  { key: 4, value: 6, text: 6 },
  { key: 5, value: 12, text: 12 },
];

export const TemplateContributorModal = ({
  isOpen,
  contributorDetails,
  closeModal,
  saveModal,
  deleteClickHandler,
  actionText,
}: ITemplateContributorModal): JSX.Element => {
  const [contributor, setContributor] = React.useState<IContributor>({
    id: undefined,
    first_name: null,
    last_name: null,
    email: null,
    reminder_start_date: null,
    reminder_interval: null,
    send_welcome_email: true,
  });

  const [validationError, setValidationError] = React.useState<IShowError>({
    first_name: null,
    last_name: null,
    email: null,
  });

  const [cronString, setCronString] = React.useState<ICronString>({
    weekdayNumber: null,
    weekday: null,
    month: null,
  });

  React.useEffect(() => {
    if (contributorDetails) {
      setContributor(contributorDetails);
    } else {
      setContributor({
        id: undefined,
        first_name: null,
        last_name: null,
        email: null,
        reminder_start_date: null,
        reminder_interval: null,
        send_welcome_email: true,
      });
    }
    setCronString({
      weekdayNumber: null,
      weekday: null,
      month: null,
    });
    setValidationError({
      first_name: null,
      last_name: null,
      email: null,
    });
  }, [isOpen, contributorDetails]);

  const handleInputChange = (key, value) => {
    setContributor({ ...contributor, [key]: value });
  };

  const cronStringChange = (weekdayNumber, weekday, month) => {
    setCronString({ weekdayNumber: weekdayNumber || 1, weekday: weekday || "MON", month: month || 1 });
    const currentCronString = `0 0 0 ? 1/${month || 1} ${weekday || "MON"}#${weekdayNumber || 1}`;
    setContributor({ ...contributor, ["reminder_interval"]: currentCronString });
  };

  const clearReminder = () => {
    setContributor({ ...contributor, ["reminder_start_date"]: null, ["reminder_interval"]: null });
    setCronString({ weekdayNumber: null, weekday: null, month: null });
  };

  const toggleSendWelcomeEmail = () => {
    setContributor({ ...contributor, ["send_welcome_email"]: !contributor.send_welcome_email });
  };

  const inputValidation = async () => {
    const showErrorProcessed: IShowError = {
      first_name: null,
      last_name: null,
      email: null,
    };
    await Promise.all(
      Object.keys(showErrorProcessed).map(async (key) => {
        if (contributor[key]) {
          if (key === "email") {
            const isEmailFormatValid = validator.isEmail(contributor["email"]);
            if (isEmailFormatValid) {
              showErrorProcessed["email"] = null;
            } else {
              showErrorProcessed["email"] = "Please enter a valid email address.";
            }
          } else {
            showErrorProcessed[key] = null;
          }
        } else {
          showErrorProcessed[key] = "This field can't be blank.";
        }
      }),
    );
    setValidationError(showErrorProcessed);
    return Object.values(showErrorProcessed).every((value) => !value);
  };

  const handleSaveModal = async () => {
    // Frontend form input validation: is null or email format check
    const isValid = await inputValidation();
    if (isValid) {
      const res = await saveModal(contributor);
      // Create/update contributor endpoint checks if the email already exists in the current template and return an error message if it does
      if (res.body.error) {
        setValidationError({ ...validationError, ["email"]: res.body.error.message });
      } else {
        closeModal();
      }
    }
  };

  let scheduledReminderDates;
  if (contributor.reminder_start_date && contributor.reminder_interval) {
    scheduledReminderDates = getScheduledReminderDates(contributor.reminder_start_date, contributor.reminder_interval, 3);
  }

  return (
    <Modal open={isOpen} onClose={closeModal} closeOnDimmerClick={false}>
      <Modal.Header>{`${actionText} Contributor`}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths={2}>
            <Form.Field required>
              <label className="fs-1250">First name</label>
              <Input
                placeholder="First name"
                value={contributor.first_name || ""}
                onChange={(_, { value }) => handleInputChange("first_name", value)}
              />
              {validationError.first_name && <p className="text-primary fs-0875 fw-600 mt-2">{validationError.first_name}</p>}
            </Form.Field>
            <Form.Field required>
              <label className="fs-1250">Last name</label>
              <Input
                placeholder="Last name"
                value={contributor.last_name || ""}
                onChange={(_, { value }) => handleInputChange("last_name", value)}
              />
              {validationError.last_name && <p className="text-primary fs-0875 fw-600 mt-2">{validationError.last_name}</p>}
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field required>
              <label className="fs-1250">Email</label>
              <Input
                placeholder="Email"
                value={contributor.email || ""}
                onChange={(_, { value }) => handleInputChange("email", value.trim())}
              />
              {validationError.email && <p className="text-primary fs-0875 fw-600 mt-2">{validationError.email}</p>}
            </Form.Field>
          </Form.Group>
          {/* Send Welcome Email: only show on "Add contributor" */}
          {actionText === "Add" && (
            <>
              <Divider className="my-4" />
              <Header as="h5" className="mt-3">
                Welcome Message
              </Header>
              <Checkbox
                onChange={toggleSendWelcomeEmail}
                checked={contributor.send_welcome_email}
                label="Send Welcome Email to this contributor"
              />
            </>
          )}
          <Divider className="my-4" />
          <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center mb-3">
            <Header as="h5" className="mb-0">
              Contribution Reminder
            </Header>
            <div className="d-flex text-primary align-items-center cursor-pointer" onClick={clearReminder}>
              <p className="fs-0875 mt-1 mb-0 fw-700">Clear reminder</p>
              <Icon name="eraser" className="ml-1 mr-0" />
            </div>
          </div>
          <Form.Group widths={2}>
            <Form.Field>
              <label className="fs-1125 text-muted">Reminder start date</label>
              <Input
                type="date"
                value={contributor.reminder_start_date ? ISODateToLocalDate(contributor.reminder_start_date) : ""}
                onChange={(_, { value }) => handleInputChange("reminder_start_date", value)}
              />
              {contributor.reminder_start_date && (
                <p className="fs-0875 mt-1">
                  The first reminder will be sent on the reminder start date if it matches the reminder interval or the next matched date.
                </p>
              )}
            </Form.Field>
            <Form.Field>
              <label className="fs-1125 text-muted">Reminder interval</label>
              <div className="d-flex align-items-center" style={{ height: 41 }}>
                <Dropdown
                  compact
                  selection
                  placeholder="1st"
                  value={cronString.weekdayNumber || ""}
                  options={weekdayNumberOptions}
                  onChange={(_, { value }) => cronStringChange(value, cronString.weekday, cronString.month)}
                  className="h-100 mr-2"
                />
                <Dropdown
                  compact
                  selection
                  placeholder="Monday"
                  value={cronString.weekday || ""}
                  options={weekdayOptions}
                  onChange={(_, { value }) => cronStringChange(cronString.weekdayNumber, value, cronString.month)}
                  className="h-100"
                  style={{ minWidth: 115 }}
                />
                <p className="mb-0 mx-2">every</p>
                <Dropdown
                  compact
                  selection
                  placeholder="1"
                  value={cronString.month || ""}
                  options={monthOptions}
                  onChange={(_, { value }) => cronStringChange(cronString.weekdayNumber, cronString.weekday, value)}
                  className="h-100"
                />
                <p className="mb-0 mx-2">month(s)</p>
              </div>
              {contributor.reminder_interval && (
                <p className="my-2 bg-light px-3 fw-600 rounded-1" style={{ paddingTop: 11, paddingBottom: 11 }}>
                  {formatCronString(contributor.reminder_interval)}
                </p>
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label className="fs-1125 text-muted">
                Scheduled reminder dates
                <Popup
                  trigger={<Icon name="info circle" className="text-medium ml-1 cursor-pointer" />}
                  content={"Next 3 reminder dates"}
                  position="bottom center"
                  size="mini"
                  inverted
                />
              </label>
              <div className="my-1 bg-light px-3 rounded-1" style={{ paddingTop: 11, paddingBottom: 11 }}>
                {contributor.reminder_start_date && contributor.reminder_interval ? (
                  <>
                    {scheduledReminderDates ? (
                      <>
                        {scheduledReminderDates.map((date, index) => (
                          <p key={index} className="mb-1">
                            {date}
                          </p>
                        ))}
                      </>
                    ) : (
                      <p className="text-muted">
                        Dates are not available. Please re-select <b>Reminder interval</b>.
                      </p>
                    )}
                  </>
                ) : (
                  <p className="text-muted">No reminders set</p>
                )}
              </div>
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {deleteClickHandler && (
          <Button
            className="float-left ml-0"
            color="red"
            onClick={() =>
              deleteClickHandler({
                id: contributor.id,
                name: `${contributor.first_name} ${contributor.last_name}`,
                email: contributor.email,
              })
            }
          >
            Delete
            <Icon name="trash alternate" className="ml-1 mr-0" />
          </Button>
        )}
        <Button className="mr-2" onClick={closeModal}>
          Cancel
        </Button>
        <Button color="purple" onClick={handleSaveModal}>
          {actionText}
          <Icon name={actionText === "Add" ? "plus" : "save"} className="ml-1 mr-0" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
