import * as React from "react";
import styled from "styled-components";
import { Placeholder } from "semantic-ui-react";

interface ContentPlaceholderProps {
  color?: string;
  className?: string;
}

const StyledPlaceholder = styled(Placeholder)<{ color: string }>`
  max-width: none !important;
  &.ui.placeholder .line {
    background-color: ${(prop) => prop.color} !important;
  }
  margin-bottom: 40px;
`;

export const ContentPlaceholder = ({ color = "#f5f5f5", className }: ContentPlaceholderProps): JSX.Element => (
  <StyledPlaceholder className={className} color={color}>
    <StyledPlaceholder.Line />
    <StyledPlaceholder.Line />
    <StyledPlaceholder.Line />
    <StyledPlaceholder.Line />
    <StyledPlaceholder.Line />
  </StyledPlaceholder>
);
