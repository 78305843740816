import { DashboardAction } from "common/store/dashboard";

export const getDashboardCollaborators = (user: any, users: any[], groups: any[]): any[] => {
  const dashboardUsers = [...users];
  groups.forEach((group) => {
    const findGroup = user.group.partners.find((partner) => partner.id === group.id);
    findGroup?.users?.forEach((user) => {
      const ifUserExists = dashboardUsers.find((dashboardUser) => dashboardUser.id === user.id);
      if (!ifUserExists) {
        const formattedUser = {
          access_level: group.access_level,
          avatar: user.avatar,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          group: { id: group.id, logo: group.logo, name: group.name },
          id: user.id,
        };
        dashboardUsers.push(formattedUser);
      }
    });
  });
  return dashboardUsers;
};

export const sortActionByDate =
  (dateType: "due_date" | "created_at" | "resolved_at") =>
  (a: DashboardAction, b: DashboardAction): number => {
    const aTime = new Date(a[dateType]!);
    const bTime = new Date(b[dateType]!);
    if (dateType === "due_date") {
      return aTime.getTime() - bTime.getTime(); // Show actions due soon at the top
    } else {
      // Show added recently at the top for on-going actions
      // or resolved recently at the top for completed actions
      return bTime.getTime() - aTime.getTime();
    }
  };
