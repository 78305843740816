import * as React from "react";
import { Helmet } from "react-helmet";
import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Label, ResponsiveContainer } from "recharts";
import { landingPageBackend } from "common/api";
import styled from "styled-components";
import { Grid, Header } from "semantic-ui-react";
import { Button } from "common/styledComponents/elements";

export const StyleGridContainer = styled(Grid)`
  padding: 0 10% !important;
`;

const ChartContainer = styled.div`
  display: inline-block;
  width: 40%;
  margin: 0 5%;
  @media screen and (max-width: 1100px) {
    width: 90%;
  }
`;

const GraphContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 25px 47px;
`;

const BarChartCenter = styled(BarChart)``;

const Container = styled.div`
  margin: 0 auto;
  width: 64%;
`;

const ContainerLarge = styled.div`
  margin: 0 auto;
  width: 90%;
`;

const Select = styled.select`
  background-color: #f5f5f5;
  width: 40%;
  height: 25px;
  border: 0;
  border-bottom: 2px solid #d0d0d0;
  margin-bottom: 30px;
  margin-top: 25px;
  font-size: 18px;
  -webkit-appearance: none;
  background-image: url("../assets/arrow.svg");
  background-position: 100% -60%;
  background-repeat: no-repeat;
`;

const LegendGrid = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  margin: 0 5% 30px;
`;

const Meaning = styled.span`
  text-align: left;
`;

const Color = styled.div<{ color: string }>`
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: ${(props) => props.color};
`;

interface IStats {
  lga: string;
  Total: number;
  AGE: number;
  INDUSTRY: number;
  SEIFA: number;
  ATSI: number;
  FAMILIES: number;
  CALD: number;
  SOCAP: number;
  SIZE: number;
}

class Store {
  @observable lgaList: string[] = [];
  @observable data: [IStats[], IStats[]] = [[], []];
  categories = ["AGE", "SIZE", "SEIFA", "ATSI", "CALD", "FAMILIES", "SOCAP", "INDUSTRY"];
  colors = ["#57376F", "#BA0F4D", "#D2F015", "#130056", "#B88E15", "#361859", "#6B1D1D", "#166578"];
  labels = ["Australia", "your state"];
  meanings = [
    "Age distribution",
    "Population size",
    "Socio-Economic Index For Areas (SEIFA)",
    "Aboriginal and Torres Strait Islander (ATSI) Population",
    "Cultural and Linguistic Diversity",
    "Make-up of Families",
    "Participation in Unpaid or Charitable  Work",
    "Industries of Employment",
  ];

  @computed get loaded() {
    return this.lgaList.length > 0;
  }

  @action
  async select(lga: string) {
    const res: any = await landingPageBackend.get<[IStats[], IStats[]]>("top10", { lga });
    this.data = res.body.results;
  }

  @action
  async loadLGA() {
    const res: any = await landingPageBackend.get<string[]>("allLgas");
    this.lgaList = res.body.results;
    this.select("Sydney (C)");
  }
}

@observer
export class SimilarityModel extends React.Component {
  store = new Store();

  componentDidMount(): void {
    this.store.loadLGA();
  }

  render(): JSX.Element | boolean {
    return (
      this.store.loaded && (
        <div className="w-100 px-4">
          <Helmet>
            <title>Similarity Model</title>
          </Helmet>
          <div className="text-center">
            <Header as="h4" color="red" style={{ paddingTop: 40 }}>
              Explore our Similarity Model to find towns like yours
            </Header>
            <Container>
              <Select onChange={(e) => this.store.select(e.currentTarget.value)}>
                <option selected>Sydney (C)</option>
                {this.store.lgaList.map((s: any) => (
                  <option key={s}>{s}</option>
                ))}
              </Select>
            </Container>
          </div>
          {this.store.data.map((data, i) => (
            <ChartContainer>
              <Header as="h4" color="red">
                Top 10 towns most like yours in {this.store.labels[i]}
              </Header>
              <GraphContainer>
                <ResponsiveContainer width={"100%"} height={350}>
                  <BarChartCenter data={data} layout="vertical">
                    <CartesianGrid horizontal={false} />
                    <XAxis
                      type="number"
                      interval={0}
                      domain={[0, 5]}
                      ticks={[0, 1, 2, 3, 4, 5]}
                      orientation="top"
                      tickLine={false}
                      axisLine={false}
                      height={40}
                    >
                      <Label value="Difference" offset={-10} position="top" />
                    </XAxis>
                    <YAxis dataKey={"lga"} type="category" width={160} />
                    {this.store.data &&
                      this.store.categories.map((category, i) => (
                        <Bar dataKey={`${category}`} fill={this.store.colors[i]} stackId="a" name={this.store.meanings[i]} />
                      ))}
                  </BarChartCenter>
                </ResponsiveContainer>
              </GraphContainer>
            </ChartContainer>
          ))}
          <LegendGrid>
            {this.store.meanings.map((meaning, i) => (
              <Meaning>
                <Color color={this.store.colors[i]} /> {meaning}{" "}
              </Meaning>
            ))}
          </LegendGrid>
          <div className="text-center">
            <ContainerLarge style={{ padding: "20px 0" }}>
              <p style={{ fontSize: "20px" }}>
                This free Similar Communities Model tool is displaying Local Government Areas.If you have a Seer Data Plus subscription, you
                can search by SA2, SA3, postcode and LGA, and also access the full data set to create your own insights.
              </p>
            </ContainerLarge>
            <StyleGridContainer columns={3} stackable>
              <Grid.Row style={{ padding: "30px 0" }}>
                <Grid.Column>
                  <a href="https://seerdata.com.au/plans/plus-plan" target="_blank">
                    <Button>Find out more about Plus</Button>
                  </a>
                </Grid.Column>
                <Grid.Column>
                  <a href="https://seerdata.com.au/similar-communities-model-dataset-now-accessible-to-plus-subscribers" target="_blank">
                    <Button>The Similar Communities Model story</Button>
                  </a>
                </Grid.Column>
                <Grid.Column>
                  <a href="https://seerdata.com.au/data-science/similar-communities" target="_blank">
                    <Button>Watch video</Button>
                  </a>
                </Grid.Column>
              </Grid.Row>
            </StyleGridContainer>
          </div>
        </div>
      )
    );
  }
}
