import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Protected from "component/protected";
import { environment } from "common/constants";
import { Button } from "component/UI/Button";
import { UserAccess } from "./includes/UserAccess";
import { OrgPlan } from "./includes/OrgPlan";
import Store from "common/store";
import { FORM_ERROR } from "final-form";
import { OrgAccess } from "./includes/OrgAccess";
import { DatasetRefresh } from "./includes/DatasetRefresh";
import { ChangeOwner } from "./includes/ChangeOwner";
import { OrgExpiryDate } from "./includes/OrgExpiryDate";
import { OrgAddOn } from "./includes/OrgAddOn";
import { OrgPaidStatus } from "./includes/OrgPaidStatus";
import { AdminSearch } from "./includes/AdminSearch";
import { SearchResult } from "./includes/SearchResult";
import { Container, Icon } from "semantic-ui-react";
import { COLORS } from "component/UI/common";
import { ContentWrapper, PageHeader } from "./includes/Components";

interface Props {
  store?: Store;
}

const Component = (props: Props) => {
  const store = props.store!;
  const { adminActions } = store;
  const [activeForm, setActiveForm] = React.useState("");
  const [selectedResult, setSelectedResult] = React.useState<any>(null);

  const haveAccess = environment !== "production" || store!.user!.group.id === 102;
  if (!haveAccess) {
    return <p>You don't have access to this page</p>;
  }

  const datasetUserAccessSubmit = (values) => {
    const { dataset_id, user_id, access, admin } = values;
    const payload = access.value
      ? { user_access: { user_id: +user_id, admin: admin.value } }
      : { user_access_remove: { user_id: +user_id } };
    return adminActions.updateDataset(dataset_id, payload);
  };

  const datasetOrgAccessSubmit = (values) => {
    const { dataset_id, group_id, access } = values;
    const payload = access.value ? { group_access: { group_id: +group_id } } : { group_access_remove: { group_id: +group_id } };
    return adminActions.updateDataset(dataset_id, payload);
  };

  const datasetRefreshSubmit = (values) => {
    const { dataset_id } = values;
    return adminActions.updateDataset(dataset_id, { update: true });
  };

  const dashboardUserAccessSubmit = (values) => {
    const { dashboard_id, user_id, access, read_only } = values;
    const payload = access.value
      ? { user_access: { user_id: +user_id, read_only: read_only.value } }
      : { user_access_remove: { user_id: +user_id } };
    return adminActions.updateDashboard(dashboard_id, payload);
  };

  const dashboardOrgAccessSubmit = (values) => {
    const { dashboard_id, group_id, access, read_only } = values;
    const payload = access.value
      ? { group_access: { group_id: +group_id, read_only: read_only.value } }
      : { group_access_remove: { group_id: +group_id } };
    return adminActions.updateDashboard(dashboard_id, payload);
  };

  const dashboardOwnerSubmit = (values) => {
    const { dashboard_id, user_id } = values;
    return adminActions.updateDashboard(dashboard_id, { user_id: +user_id });
  };

  const suitcaseUserAccessSubmit = (values) => {
    const { suitcase_id, user_id, access, read_only } = values;
    const payload = access.value
      ? { user_access: { user_id: +user_id, read_only: read_only.value } }
      : { user_access_remove: { user_id: +user_id } };
    return adminActions.updateSuitcase(suitcase_id, payload);
  };

  const suitcaseOwnerSubmit = (values) => {
    const { suitcase_id, user_id } = values;
    return adminActions.updateSuitcase(suitcase_id, { user_id: +user_id });
  };

  const orgPlanSubmit = (values) => {
    const { group_id, group_type_id } = values;
    return adminActions.updateOrg(group_id, { group_type_id: group_type_id.value });
  };

  const orgExpiryDateSubmit = (values) => {
    const { group_id, expiry } = values;
    return adminActions.updateOrg(group_id, { expiry: expiry.replaceAll("-", "/") });
  };

  const orgAddOnSubmit = (values) => {
    const { group_id, access, add_on } = values;
    const payload = access.value === "add" ? { add_on_ids: add_on } : { add_on_ids_remove: add_on };
    return adminActions.updateOrg(group_id, payload);
  };

  const orgPaidStatusSubmit = (values) => {
    const { group_id, paid } = values;
    return adminActions.updateOrg(group_id, { paid: paid.value });
  };

  const withConfirmation = (submitFunction) => async (values) => {
    if (!window.confirm("Are you sure?")) {
      return;
    }
    const success = await submitFunction(values);
    if (success) {
      return setActiveForm("");
    } else {
      return { [FORM_ERROR]: "Request failed. Please contact the engineering team." };
    }
  };

  const getActionByValue = (value: string) => {
    for (const category of Object.keys(actionItems)) {
      const findAction = actionItems[category].find((action) => action.value === value);
      if (findAction) {
        return findAction;
      }
    }
  };

  const actionItems = {
    Dataset: [
      {
        value: "edit_dataset_user_access",
        label: "Edit user access",
        component: <UserAccess type="dataset" onSubmit={withConfirmation(datasetUserAccessSubmit)} />,
      },
      {
        value: "edit_dataset_organisation_access",
        label: "Edit Organisation access",
        component: <OrgAccess type="dataset" onSubmit={withConfirmation(datasetOrgAccessSubmit)} />,
      },
      {
        value: "trigger_dataset_refresh",
        label: "Trigger Dataset refresh",
        component: <DatasetRefresh onSubmit={withConfirmation(datasetRefreshSubmit)} />,
      },
    ],
    Dashboard: [
      {
        value: "edit_dashboard_user_access",
        label: "Edit user access",
        component: <UserAccess type="dashboard" onSubmit={withConfirmation(dashboardUserAccessSubmit)} />,
      },
      {
        value: "edit_dashboard_organisation_access",
        label: "Edit Organisation access",
        component: <OrgAccess type="dashboard" onSubmit={withConfirmation(dashboardOrgAccessSubmit)} />,
      },
      {
        value: "change_dashboard_owner",
        label: "Change Dashboard owner",
        component: <ChangeOwner type="dashboard" onSubmit={withConfirmation(dashboardOwnerSubmit)} />,
      },
    ],
    Suitcase: [
      {
        value: "suitcase_edit_user_access",
        label: "Edit user access",
        component: <UserAccess type="suitcase" onSubmit={withConfirmation(suitcaseUserAccessSubmit)} />,
      },
      {
        value: "change_suitcase_owner",
        label: "Change Suitcase owner",
        component: <ChangeOwner type="suitcase" onSubmit={withConfirmation(suitcaseOwnerSubmit)} />,
      },
    ],
    Organisation: [
      {
        value: "edit_plan",
        label: "Edit plan",
        component: <OrgPlan onSubmit={withConfirmation(orgPlanSubmit)} />,
      },
      {
        value: "edit_expiry_date",
        label: "Edit expiry date",
        component: <OrgExpiryDate onSubmit={withConfirmation(orgExpiryDateSubmit)} />,
      },
      {
        value: "edit_add_ons",
        label: "Edit add-ons",
        component: <OrgAddOn onSubmit={withConfirmation(orgAddOnSubmit)} />,
      },
      {
        value: "edit_paid_status",
        label: "Edit paid status",
        component: <OrgPaidStatus onSubmit={withConfirmation(orgPaidStatusSubmit)} />,
      },
    ],
  };

  return (
    <Container>
      <h1 className="text-secondary fs-4000">Seer Data Admin</h1>
      {activeForm ? (
        <>
          <PageHeader label={getActionByValue(activeForm).label} onCancel={() => setActiveForm("")} />
          <ContentWrapper>{getActionByValue(activeForm).component}</ContentWrapper>
        </>
      ) : (
        <>
          {selectedResult ? (
            <SearchResult data={selectedResult} onCancel={() => setSelectedResult(null)} />
          ) : (
            <>
              <AdminSearch onSelect={(selected) => setSelectedResult(selected)} />
              {Object.entries(actionItems).map(([category, actions]) => (
                <div key={category} className="mb-4">
                  <div className="py-2 px-3 rounded-3 mb-3" style={{ backgroundColor: COLORS.grey200 }}>
                    <p className="fs-1125 fw-700">{category}</p>
                  </div>
                  {actions.map((action, aIdx) => {
                    const { label, value } = action;
                    return (
                      <Button key={aIdx} onClick={() => setActiveForm(value)} className="d-block mb-3">
                        {label} <Icon name="angle right" />
                      </Button>
                    );
                  })}
                </div>
              ))}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export const AdminActions = Protected(inject("store")(observer(Component)));
