import Dataset from "common/store/dataset";
import { observable, action, computed } from "mobx";
import { parse, unparse } from "papaparse";
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as math from "mathjs";
import * as moment from "moment";
// @TODO - remove this poorly maintained module (any-date-parser) and use something more appropriate
// https://github.com/kensnyder/any-date-parser/issues/4
const dateParser = require("any-date-parser"); // eslint-disable-line
import { cloneDeep, difference } from "lodash";
import { backendUrl } from "common/constants";
import { generateSimpleSalt, hashSHA512 } from "common/helpers/string";
import { ParsedRecords } from "common/helpers/file";

export interface SSDCConfig {
  salt: string;
  mergeOps: MergeOp[];
  transformOps: TransformOp[];
  aggregations: Aggregation[];
  columnValidation: ColumnValidation;
  dataTypes?: DataTypes;
}

const PREVIEW_ROW_LIMIT = 100;

export interface MergeOp {
  left: number | null; // raw index or null if staging table
  leftColNames: string[];
  right: number; // raw index
  rightColNames: string[];
  type: "left join"; // support for others later
}

export interface MergeTablesProps {
  leftTable: ParsedRecords;
  rightTable: ParsedRecords;
  leftColNames: string[];
  rightColNames: string[];
  type: "left join"; // Just one type is supported for now
}

export interface SelectColumn {
  column: string;
}

export type TransformOp =
  | {
      type: "column hash";
      config: string; // "col_name"
    }
  | {
      type: "column rename";
      config: [string, string]; // ["col_to_rename", "new_name"]
    }
  | {
      type: "column drop";
      config: string; // "col_name"
    }
  | {
      type: "column merge";
      config: {
        columns: string[]; // ["first_col", "next_col", ... "nth_col"] - first col name is preserved
        separator: string;
      };
    }
  | {
      type: "formula";
      config: {
        newColName: string; // "col_name"
        expr: (SelectColumn | string)[];
      };
    }
  | {
      type: "datediff";
      config: {
        newColName: string;
        diff: [SelectColumn | string, SelectColumn | string];
        tableDateFormat: string | undefined;
      }; // a - b => { newCol: "Duration" => [col_a, col_b] }
    };

export type Aggregation =
  | {
      type: "sum" | "min" | "max" | "avg" | "count" | "count > 0" | "count not null" | "count distinct";
      column: string;
    }
  | {
      type: "count all";
    };

interface ValidationItem {
  allowEmpty: boolean;
}

export interface NumberValidation extends ValidationItem {
  type: "whole_number" | "number";
  subType: "valid_range" | "invalid_range";
  fromType: "gt" | "gte";
  from?: number;
  toType: "lt" | "lte";
  to?: number;
}

export interface ListValidation extends ValidationItem {
  type: "list";
  list: string[];
}

export interface RegExpValidation extends ValidationItem {
  type: "regexp";
  regexp: string;
}

export type ColumnValidation = Record<string, NumberValidation | ListValidation | RegExpValidation>;

export type TypeOverrides = Record<string, "string" | "number" | "date">; // { "<column_name>": "<column_type>", ...}

export type UploadMode = "overwrite" | "append" | "merge" | null;

export interface DataType {
  type: "string" | "number" | "list";
  separator?: string; // only used with "list" type
}

type DataTypes = Record<string, DataType>; // "string" is column name

const defaultAggregations: Aggregation[] = [{ type: "count all" }];

export class URLUpload {
  @observable files?: FileList;
  @observable dataset?: any; // data associated with the dataset we are working with
  @observable existingData?: Blob | null; // data associated with the dataset we are working with
  @observable raw?: ParsedRecords[]; // preview of raw files to row limit
  @observable staged?: ParsedRecords; // preview of staged data to row limit
  @observable mergeOps: MergeOp[] = [];
  @observable transformOps: TransformOp[] = [];
  @observable aggregations: Aggregation[] = defaultAggregations;
  @observable columnValidation: ColumnValidation = {};
  @observable dataTypes: DataTypes = {};
  @observable salt?: string;
  @observable typeOverrides: TypeOverrides = {};
  @observable redo: (MergeOp | TransformOp)[] = [];
  @observable ssdc_config: SSDCConfig | any = undefined; // unused currently
  @observable skipToLastStep = false;

  parent: Dataset;

  constructor(parent: Dataset) {
    this.parent = parent;
  }

  @computed get unmergedRawIdxs(): number[] {
    if (!this.raw || this.raw.length === 1) {
      return [];
    }
    const rawIdxs = Array.from(this.raw.keys());
    const mergedIdxs = this.mergeOps.reduce((prev, curr) => {
      const { left, right } = curr;
      const addIdxs: number[] = [];
      if (typeof left === "number") {
        addIdxs.push(left);
      }
      addIdxs.push(right);
      return [...prev, ...addIdxs];
    }, [] as number[]);
    return rawIdxs.filter((idxNumber) => !mergedIdxs.includes(idxNumber));
  }

  @computed get unprotectedStagedColIdxs(): number[] {
    if (!this.staged) {
      return [];
    }
    const allStagedCols = this.staged![0];
    const unprotected: number[] = [];
    const protectedCols: string[] = this.transformOps.filter((item) => item.type === "column hash").map((item) => item.config as string);
    for (const [idx, col] of Array.from(allStagedCols.entries())) {
      if (!protectedCols.includes(col)) {
        unprotected.push(idx);
      }
    }
    return unprotected;
  }

  /*
   * Initialise raw file previews and load dataset data
   * */
  @action async init(datasetId: number) {
    if (!this.files) {
      // @TODO - do we want to do anything if someone tries to load this page directly, force them to upload something etc?
      return;
    }

    const { error, result: raw } = await this.filesToRecords(PREVIEW_ROW_LIMIT);
    if (error) {
      // @TODO - handle and skip stuff
      alert(error);
      return;
    }
    if (raw.length === 1) {
      this.staged = raw[0]; // if only 1 file stage it immediately
    }
    this.raw = raw;

    // load dataset data
    const res: any = await fetch(`${backendUrl}/datasets/${datasetId}`, {
      headers: { "X-Token": this.parent.parent.token! },
    })
      .then((res) => res.json())
      .catch((e) => {
        console.log(e);
        return null;
      });

    if (res.data) {
      const data = res.data[0];
      this.dataset = data;

      const config = data.ssdc_config || this.ssdc_config;

      const newSalt = `${generateSimpleSalt()}${generateSimpleSalt()}${generateSimpleSalt()}`;
      this.salt = config?.salt || newSalt;
      // if ssdc_config is set we apply all the actions automatically to the files passed then let the user review/edit
      const mergeOps: MergeOp[] = config?.mergeOps?.length ? config.mergeOps : [];
      const transformOps: TransformOp[] = config?.transformOps?.length ? config.transformOps : [];
      const aggregations: Aggregation[] = config?.aggregations?.length ? config.aggregations : defaultAggregations;
      const columnValidation: ColumnValidation = config?.columnValidation || {};
      const dataTypes: DataTypes = config?.dataTypes || {};
      if (mergeOps.length || transformOps.length) {
        this.staged = await this.bulkApplyOperations(raw, mergeOps, transformOps);
      }
      this.mergeOps = mergeOps;
      this.transformOps = transformOps;
      this.aggregations = aggregations;
      this.columnValidation = columnValidation;
      this.dataTypes = dataTypes;
      if (config) {
        this.setSkipToLastStep(true);
      }
    } else {
      // @TODO - handle fetch error?
    }

    // load existing data for dataset if any
    const existingData: Blob | null = await fetch(`${backendUrl}/datasets/${datasetId}/url-download`, {
      headers: { "X-Token": this.parent.parent.token! },
    })
      .then((res) => (res.status === 200 ? res.blob() : null))
      .catch((e) => {
        console.log(e);
        return null;
      });
    if (existingData) {
      this.existingData = existingData;
    }
  }

  @action setSkipToLastStep(skip: boolean) {
    this.skipToLastStep = skip;
  }

  @action setColumnValidation(column: string, validation: NumberValidation | ListValidation | RegExpValidation) {
    this.columnValidation[column] = validation;
  }

  @action deleteColumnValidation(column: string) {
    delete this.columnValidation[column];
  }

  @action setDataTypes(dataTypes: DataTypes) {
    this.dataTypes = dataTypes;
  }

  /* note: this is for the preview while configuration is being decided only */
  @action applyMergeOpPreview(mergeOp: MergeOp) {
    this.redo = []; // reset redo ops
    // pick out the appropriate preview tables to merge
    const { left, right, ...rest } = mergeOp;
    // mergeTables is updating tables in place which causes side effects so use clones
    let staged = cloneDeep(this.staged || []);
    const raw = cloneDeep(this.raw);
    const leftTable = left !== null ? raw![left] : staged;
    const rightTable = raw![right];
    staged = this.mergeTables({ leftTable, rightTable, ...rest });
    this.staged = staged;
    this.mergeOps.push(mergeOp);
  }

  @action async applyTransformOpsPreview(transformOps: TransformOp[]) {
    this.redo = []; // reset redo ops
    let tempTable = cloneDeep(this.staged || []);
    for (const transformOp of transformOps) {
      tempTable = await this.applyTransform(tempTable, transformOp);
    }
    this.staged = tempTable;
    this.transformOps.push(...transformOps);
  }

  @action setAutoAggregations() {
    if (!Object.keys(this.dataTypes).length) {
      return;
    }
    const nextAggregations: Aggregation[] = [...defaultAggregations];
    Object.entries(this.dataTypes).forEach(([col, { type }]) => {
      if (type === "number") {
        const numberAggregationTypes = ["sum", "min", "max", "avg"] as const;
        nextAggregations.push(...numberAggregationTypes.map((type) => ({ type, column: col })));
      }
    });
    this.aggregations = nextAggregations;
  }

  @action async processUpload(uploadMode: UploadMode = "overwrite", force = false) {
    // process the full raw files
    const { error, result: raw } = await this.filesToRecords();
    if (error) {
      return { type: "parsing", message: error };
    }
    let processed = await this.bulkApplyOperations(raw, this.mergeOps, this.transformOps);

    // apply column validation
    const validationErrors = this.applyColumnValidations(processed, this.columnValidation);
    if (validationErrors) {
      return { type: "column_validation", validationErrors };
    }

    // apply datatypes validation
    const dataTypesValidationErrors = this.applyDataTypesValidations(processed, this.dataTypes);
    if (dataTypesValidationErrors) {
      return { type: "datatypes_validation", validationErrors: dataTypesValidationErrors };
    }

    if (Object.keys(this.dataTypes).length) {
      // transform list datatype data
      this.applyListDataTypeTransform(processed, this.dataTypes);
      // apply datatype to relevant column heading
      this.applyDataTypesToColumns(processed, this.dataTypes);
    }

    // load existing records if any, used for both append mode and error validation
    let existingRecords: ParsedRecords = [];
    await new Promise<void>((resolve, reject) => {
      if (!this.existingData) {
        return resolve();
      }
      const file = new File([this.existingData as Blob], "existing.csv");
      parse(file as any, {
        error: (error, file) => reject({ error, file }),
        complete: (results) => {
          existingRecords = results.data as string[][];
          resolve();
        },
        skipEmptyLines: "greedy",
      });
    });

    // upload error validation
    if (uploadMode === "append" || !force) {
      // ensure there is no mismatch in the headings, otherwise alert the user and cancel upload
      if (existingRecords.length) {
        const existingCols = existingRecords[0];
        const newCols = processed[0];
        const existingLength = existingCols.length;
        const newLength = newCols.length;
        const leftDiff = difference(existingCols, newCols);
        const rightDiff = difference(newCols, existingCols);
        if (
          existingLength === newLength &&
          !leftDiff.length &&
          !rightDiff.length &&
          !existingCols.every((colName, colIdx) => colName === newCols[colIdx])
        ) {
          // columns are the same but the order is wrong
          return { type: "column_mismatch", message: "Column mismatch. Order of uploaded columns does not match existing data columns." };
        }
        if (leftDiff.length || rightDiff.length) {
          // number of columns may or may not be the same but there are definite mismatches in terms of columns that exist in one and not the other
          const diffMeta = leftDiff
            .map((column) => ({ column, message: "Column missing in upload." }))
            .concat(rightDiff.map((column) => ({ column, message: "New column absent from existing data." })));
          return {
            type: "column_mismatch",
            message: "The column headers in the uploaded file don't match the existing data.",
            meta: diffMeta,
          };
        }
      }
    }

    // apply the mode operation if applicable
    if (uploadMode === "append") {
      processed = existingRecords.concat(processed.slice(1));
    }

    // generate csv file
    const csvString = unparse(processed);
    const encoder = new TextEncoder();
    const csvEncoded = encoder.encode(csvString);
    const csvFile = new File([csvEncoded], `${this.dataset!.id}.csv`, { type: "text/csv" });

    // compile dataset updates
    const updates: any = {
      ssdc_config: {
        salt: this.salt,
        mergeOps: this.mergeOps,
        transformOps: this.transformOps,
        aggregations: this.aggregations,
        columnValidation: this.columnValidation,
      },
    };
    if (Object.keys(this.dataTypes).length) {
      updates.ssdc_config.dataTypes = this.dataTypes;
    }
    const formData = new FormData();
    formData.append("file", csvFile);
    formData.append("json", JSON.stringify(updates));
    const ok = await fetch(`${backendUrl}/datasets/${this.dataset!.id}/url-upload`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "X-Token": this.parent.parent.token!,
      },
      body: formData,
    })
      .then((res) => res.ok)
      .catch((e) => {
        console.log(e);
        return false;
      });
    if (!ok) {
      return { type: "upload", message: "Something went wrong during the upload. Please try again." };
    }
    return;
  }

  // @TODO - also handle aggregation ops
  @action async undoOperation() {
    const hasOps = this.mergeOps.length || this.transformOps.length;
    if (!hasOps) {
      return;
    }
    const lastOpType = this.transformOps.length ? "transform" : "merge";
    const lastOpLocation = lastOpType === "transform" ? this.transformOps : this.mergeOps;
    let lastOp = lastOpLocation[lastOpLocation.length - 1];
    const ok = confirm(`This will undo the last ${lastOpType === "transform" ? lastOp.type : "merge"} operation.`);
    if (!ok) {
      return;
    }
    lastOp = lastOpLocation.pop() as MergeOp | TransformOp;

    // reapply all operations to get into previous staged state
    const raw = cloneDeep(this.raw) as ParsedRecords[];
    const processed = await this.bulkApplyOperations(raw, this.mergeOps, this.transformOps);
    this.staged = processed?.length ? processed : undefined;
    // unshift the undone operation to redo observable
    this.redo.unshift(lastOp);
  }

  // @TODO - also handle aggregation ops
  @action async redoOperation() {
    if (!this.redo.length) {
      return;
    }
    // figure out what the redo op has to be based on whether all merge ops have been applied
    const redoOpType = this.unmergedRawIdxs.length === 0 ? "transform" : "merge";
    let redoOp = this.redo[0];
    const ok = confirm(`This will reapply the last ${redoOpType === "transform" ? redoOp.type : "file merge"} operation.`);
    if (!ok) {
      return;
    }
    redoOp = this.redo.shift() as MergeOp | TransformOp; // actually shift the op off the stack
    const redoCopy = cloneDeep(this.redo); // copy the remaining redo state as it will be cleared on applying actions
    // apply the redo op
    if (redoOpType === "transform") {
      await this.applyTransformOpsPreview([redoOp as TransformOp]);
    } else {
      this.applyMergeOpPreview(redoOp as MergeOp);
    }
    this.redo = redoCopy; // update the redo state to what was left in the redo array
  }

  // take file list that may contain csv/xlsx and process to parsed records
  async filesToRecords(previewRows: number | null = null) {
    const result: ParsedRecords[] = [];
    let error: string | null = null;
    if (!this.files) {
      error = "No files to parse";
      return { result, error };
    }

    // convert any excels to csv
    const files = Array.from(this.files!);
    const csvs: File[] = [];
    for (const file of files) {
      if (file.type !== "text/csv") {
        const workbook = new Excel.Workbook();
        const inBuffer = await file.arrayBuffer();
        await workbook.xlsx.load(inBuffer);
        const outBuffer: ArrayBuffer = await workbook.csv.writeBuffer();
        const csvFile = new File([outBuffer], "data.csv", { type: "text/csv" });
        csvs.push(csvFile);
      } else {
        csvs.push(file);
      }
    }

    // parse the csvs into ParsedRecords with previewRows rows if set
    for (const csv of csvs) {
      try {
        let rows: ParsedRecords = [];
        await new Promise<void>((resolve, reject) => {
          parse(csv as any, {
            preview: previewRows || undefined,
            worker: previewRows ? true : undefined,
            step: previewRows ? (results: any) => rows.push(results.data as string[]) : undefined,
            error: (error, file) => reject({ error, file }),
            complete: previewRows
              ? () => resolve()
              : (results) => {
                  rows = results.data as string[][];
                  resolve();
                },
            skipEmptyLines: "greedy",
          });
        });
        result.push(rows);
      } catch (e) {
        console.error(e);
        error = "Parse file failed";
        break;
      }
    }

    return { result, error };
  }

  mergeTables(props: MergeTablesProps): ParsedRecords {
    const { leftTable, rightTable, leftColNames, rightColNames } = props; // "type" unused currently
    const leftColIdxs = leftColNames.map((col) => leftTable[0].indexOf(col));
    const rightColIdxs = rightColNames.map((col) => rightTable[0].indexOf(col));

    const removeIndexes = [...rightColIdxs]; // indexes to drop from right table (duplicate columns)
    // Check if right table contains any other columns from the left that should be removed to avoid duplicates
    for (const col of leftTable[0]) {
      if (rightTable[0].includes(col) && !rightColNames.includes(col)) {
        removeIndexes.push(rightTable[0].indexOf(col));
      }
    }
    // Arrange the indexes in reverse order so removing items won't shift other item's positions.
    removeIndexes.sort((a, b) => b - a);
    // Remove these duplicate columns
    for (const removeIndex of removeIndexes) {
      props.rightTable[0].splice(removeIndex, 1);
    }

    // compile a hash table of right table rows to avoid expensive find operations
    const hashSeparator = ":";
    const rightHashTable: Record<string, string[]> = {};
    for (let rIdx = 1; rIdx < rightTable.length; rIdx++) {
      const valueHash = rightColIdxs.map((cIdx) => rightTable[rIdx][cIdx]).join(hashSeparator);
      if (!rightHashTable[valueHash]) {
        rightHashTable[valueHash] = rightTable[rIdx]; // use the first occurrence as the match
      }
    }

    // merge the heading columns
    const mergedTable: ParsedRecords = [leftTable[0].concat(rightTable[0])];
    // Start from row 1 as 0 is for column headings
    for (let rIdx = 1; rIdx < leftTable.length; rIdx++) {
      const leftValues = leftColIdxs.map((cIdx) => leftTable[rIdx][cIdx]);
      const rightTableMatch = rightHashTable[leftValues.join(hashSeparator)];
      if (rightTableMatch) {
        // make a copy, so we don't affect the row if it is matched multiple times
        const matchCopy = [...rightTableMatch];
        for (const removeIndex of removeIndexes) {
          matchCopy.splice(removeIndex, 1);
        }
        mergedTable.push(leftTable[rIdx].concat(matchCopy));
      } else {
        // append blank values for missing data on unmatched rows
        mergedTable.push(leftTable[rIdx].concat(Array(rightTable[0].length).fill("")));
      }
    }
    return mergedTable;
  }

  async applyTransform(table: ParsedRecords, transformOp: TransformOp): Promise<ParsedRecords> {
    const result = cloneDeep(table);
    const { type, config } = transformOp;
    if (type === "column rename") {
      const [oldName, newName] = config;
      const renameIdx = result[0].indexOf(oldName);
      if (renameIdx !== -1) {
        result[0][renameIdx] = newName;
      }
    } else if (type === "column drop") {
      const dropIdx = result[0].indexOf(config);
      if (dropIdx !== -1) {
        for (const row of result) {
          row.splice(dropIdx, 1);
        }
      }
    } else if (type === "column merge") {
      const { columns, separator } = config;
      const mergeIdxs = columns.map((col) => result[0].indexOf(col));
      if (!mergeIdxs.includes(-1)) {
        // drop indexes must be sorted in descending order to ensure splicing works correctly
        const dropIdxs = mergeIdxs.slice(1);
        dropIdxs.sort((prev, next) => next - prev);
        for (const rowIdx of Array.from(result.keys())) {
          if (rowIdx !== 0) {
            // merge values into left merge column
            result[rowIdx][mergeIdxs[0]] = mergeIdxs.map((idx) => result[rowIdx][idx]).join(separator);
          }
          // drop merged columns
          for (const dropIdx of dropIdxs) {
            result[rowIdx].splice(dropIdx, 1);
          }
        }
      }
    } else if (type === "column hash") {
      const hashIdx = result[0].indexOf(config);
      if (hashIdx !== -1) {
        for (const rowIdx of Array.from(result.keys())) {
          if (rowIdx !== 0) {
            result[rowIdx][hashIdx] = await hashSHA512(`${result[rowIdx][hashIdx]}${this.salt}`);
          }
        }
      }
    } else if (type === "formula") {
      const { newColName, expr } = config;
      const colIdx = result[0].length;
      for (const rowIdx of Array.from(result.keys())) {
        if (rowIdx !== 0) {
          // Parse formula and get data from column
          const formula: string[] = expr.map((chunk) => {
            if (typeof chunk === "string") {
              return chunk;
            } else {
              const colIdx = result[0].indexOf(chunk.column);
              if (colIdx !== -1 && result[rowIdx][colIdx]) {
                return result[rowIdx][colIdx].toString();
              } else {
                // TODO: Rethink this return - perhaps NaN or null for the whole expression instead of evaluating
                return "0";
              }
            }
          });
          result[rowIdx][colIdx] = await math.evaluate(formula.join(""));
        } else {
          result[rowIdx][colIdx] = newColName; // table header row
        }
      }
    } else if (type === "datediff") {
      const { newColName, diff, tableDateFormat } = config;
      const [dateA, dateB] = diff;
      const colIdx = result[0].length;
      for (const rowIdx of Array.from(result.keys())) {
        if (rowIdx !== 0) {
          const date1 =
            typeof dateA === "string" ? moment(dateA) : moment(result[rowIdx][result[0].indexOf(dateA.column)], tableDateFormat);
          const date2 =
            typeof dateB === "string" ? moment(dateB) : moment(result[rowIdx][result[0].indexOf(dateB.column)], tableDateFormat);
          const dayDiff = Math.floor(date1.diff(date2, "days", true));
          result[rowIdx][colIdx] = dayDiff.toString();
        } else {
          result[rowIdx][colIdx] = newColName;
        }
      }
    }

    return result;
  }

  applyColumnValidations(data: ParsedRecords, columnValidation: ColumnValidation): any {
    const errors = {};
    if (Object.keys(columnValidation).length) {
      Object.keys(columnValidation).forEach((col) => {
        const validation = columnValidation[col];
        const { allowEmpty, type } = validation;
        const colIdx = data[0].indexOf(col);
        const errorEntries = {};
        for (let i = 1; i < data.length; i++) {
          const value = data[i][colIdx];
          if (value.trim() === "") {
            if (allowEmpty) {
              continue;
            } else {
              errorEntries[i + 1] = "Cannot be empty";
              continue;
            }
          }

          if (type.includes("number")) {
            if (type === "whole_number" && !Number.isInteger(+value)) {
              errorEntries[i + 1] = "Must be a whole number";
              continue;
            }
            const { subType, fromType, from, toType, to } = validation as NumberValidation;
            const inRange = checkInRange(+value, fromType, from, toType, to);
            if (subType === "valid_range" && !inRange) {
              errorEntries[i + 1] = "Out of the valid range";
            } else if (subType === "invalid_range" && inRange) {
              errorEntries[i + 1] = "Out of the valid range";
            }
          } else if (type === "list") {
            const { list } = validation as ListValidation;
            if (!list.includes(value)) {
              errorEntries[i + 1] = "Not found in the list";
            }
          } else {
            const { regexp } = validation as RegExpValidation;
            try {
              const re = new RegExp(regexp);
              const valid = re.test(value);
              if (!valid) {
                errorEntries[i + 1] = "Doesn't match the regular expression";
              }
            } catch (e) {
              console.log(e);
              errorEntries[i + 1] = "Invalid regular expression";
            }
          }
        }

        if (Object.keys(errorEntries).length) {
          errors[col] = errorEntries;
        }
      });
    }
    if (Object.keys(errors).length) {
      return errors;
    } else {
      return null;
    }
  }

  /* useful for things like undo refresh state, init dataset operations, process upload etc */
  async bulkApplyOperations(raw: ParsedRecords[], mergeOps: MergeOp[], transformOps: TransformOp[]): Promise<ParsedRecords> {
    let processed: ParsedRecords = [];
    if (raw.length === 1) {
      processed = raw[0];
    }
    for (const mergeOp of mergeOps) {
      const { left, right, ...rest } = mergeOp;
      const leftTable = left !== null ? raw![left] : processed;
      const rightTable = raw![right];
      processed = this.mergeTables({ leftTable, rightTable, ...rest });
    }
    for (const transformOp of transformOps) {
      processed = await this.applyTransform(processed, transformOp);
    }
    return processed;
  }

  // DataTypes validation only check for number columns
  applyDataTypesValidations(processed: ParsedRecords, dataTypes: DataTypes) {
    const errors = {};
    if (Object.keys(dataTypes).length) {
      Object.entries(dataTypes).forEach(([col, dataType]) => {
        const { type } = dataType;
        if (type === "number") {
          const colIdx = processed[0].indexOf(col);
          const errorEntries = {};
          for (let i = 1; i < processed.length; i++) {
            const value = processed[i][colIdx].trim();
            if (value && isNaN(Number(value))) {
              errorEntries[i + 1] = "Must be number";
            }
          }
          if (Object.keys(errorEntries).length) {
            errors[col] = errorEntries;
          }
        }
      });
    }
    return Object.keys(errors).length ? errors : null;
  }

  // Use a separator to split the list items into an array. For example, the value ‘a,b,c’ with a separator would become [‘a’, ‘b’, ‘c’].
  applyListDataTypeTransform(processed: ParsedRecords, dataTypes: DataTypes) {
    Object.entries(dataTypes).forEach(([col, dataType]) => {
      const { type, separator } = dataType;
      if (type === "list") {
        const colIdx = processed[0].indexOf(col);
        for (let i = 1; i < processed.length; i++) {
          processed[i][colIdx] = JSON.stringify(processed[i][colIdx].split(separator!));
        }
      }
    });
  }

  // Prepend columns with ‘number:’ or ‘list:’ where appropriate; otherwise, leave them as is for strings.
  // This is because backend currently expects these data types in the raw csv
  applyDataTypesToColumns(processed: ParsedRecords, dataTypes: DataTypes) {
    const nextColumns = processed[0].map((col) => {
      const { type } = dataTypes[col];
      if (type === "number" || type === "list") {
        return `${type}:${col}`;
      }
      return col;
    });
    processed[0] = nextColumns;
  }
}

// Useful for "applyColumnValidations" to check if a value falls within a range
const checkInRange = (
  value: number,
  fromType: "gt" | "gte",
  from: number | undefined,
  toType: "lt" | "lte",
  to: number | undefined,
): boolean => {
  let valid = true;
  if (from !== undefined) {
    valid = fromType === "gt" ? value > from : value >= from;
  }
  if (to !== undefined) {
    valid = valid && (toType === "lt" ? value < to : value <= to);
  }
  return valid;
};
