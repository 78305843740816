import * as React from "react";
import styled from "styled-components";
import Store from "../../common/store";

const Container = styled.div`
  position: fixed;
  bottom: 100px;
  left: 50px;
  min-height: 56px;
  padding: 16px;
  transition-duration: 334ms;
  transition-property: opacity, transform;
  transition-timing-function: ease-out;
  width: 344px;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.1),
    0 6px 9px rgba(0, 0, 0, 0.2);
  background: #fff;
`;

const Close = styled.div`
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  font-size: 32px;
  font-weight: normal;
  cursor: pointer;
  float: right;
`;

const Body = styled.div`
  padding: 3px 32px 0 0;
`;

export default class Notification extends React.Component<{ store: Store; close?: boolean }> {
  store = this.props.store;

  onExit() {
    this.store.notification = { show: false, message: "" };
  }

  render() {
    return (
      <Container onClick={(e) => e.stopPropagation()}>
        <Close onClick={() => this.onExit()}>{this.props.close ? "×" : null}</Close>
        <Body>{this.props.children}</Body>
      </Container>
    );
  }
}
