import * as React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { Button, Heading, HR } from "common/styledComponents/elements";
import { Builder } from "common/store/builder";
import { MultiIndexTable } from "./table/MultiIndexTable";
import { ExploreMultiIndexChartResult } from "./Explore/ExploreMultiIndexChartResult";
import { Icon } from "semantic-ui-react";

const ViewButton = styled(Button)`
  width: 50%;
  @media (min-width: 576px) {
    width: initial;
  }
`;

// to make mobile friendly for the layout
const EditButton = styled(Button)`
  width: 100%;
  display: block;
  @media (min-width: 576px) {
    width: initial;
    display: initial;
  }
`;

type InsightContentProps = RouteComponentProps<{}> & {
  builder?: Builder;
  readonly?: boolean;
  forceChart?: boolean;
};

export const NewInsightContent = withRouter(
  inject((stores: any) => ({ builder : stores.store.builder }))(
    observer((props: InsightContentProps) => {
      const builder = props.builder!;

      const [contentToggle, setContentToggle] = React.useState("table");
      const insightCode = undefined; // TODO: Support insight embed codes

      const editInsight = () => {
        props.history.push(`/builder/updateInsight/${builder.parent.insight.result.id}`);
      };

      return (
        <>
          <HR />

          {insightCode ? (
            // IFrame Insight
            <>
              <Heading>Insight</Heading>
              <div id="resExport" className="p-4 mt-5 bg-white rounded shadow">
                <div className="w-100" dangerouslySetInnerHTML={{ __html: insightCode }} style={{ height: "600px" }} />
              </div>
            </>
          ) : (
            // Table & Chart Insight
            <>
              <span className="float-sm-right">
                {builder.tables.length > 0 && (
                  <>
                    <ViewButton
                      onClick={() => setContentToggle("table")}
                      className={`shadow-none border rounded-0 rounded-left ${
                        contentToggle === "table" ? "bg-white text-primary" : "bg-light text-medium"
                      }`}
                    >
                      Table view <Icon name="table" />
                    </ViewButton>
                    <ViewButton
                      id="userflow-element-insight-chart-view"
                      onClick={() => setContentToggle("chart")}
                      className={`shadow-none border rounded-0 rounded-right ${
                        contentToggle === "chart" ? "bg-white text-primary" : "bg-light text-medium"
                      }`}
                    >
                      Chart view <Icon name="chart bar" />
                    </ViewButton>
                  </>
                )}

                {!props.readonly &&
                  <EditButton className="ml-sm-3 mt-3 mt-sm-0" onClick={editInsight}>
                    Edit insight <Icon name="edit" />
                  </EditButton>
                }
              </span>

              <Heading>Insight</Heading>

              <div id="resExport" className="p-2 mt-5 bg-white rounded shadow">
                {contentToggle === "table" && !props.forceChart ? (
                  // Table View
                  <MultiIndexTable editMode={false} />
                ) : (
                  // Chart View
                  <ExploreMultiIndexChartResult
                    insight={true}
                    setCurrentStep={() => console.log("hi")}
                    editMode={false}
                    isAnimationDisabled={props.forceChart}
                  />
                )}
              </div>
            </>
          )}
        </>
      );
    })
  )
);
