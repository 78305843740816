import * as React from "react";
import { Label, Table, Image, Icon } from "semantic-ui-react";

interface IDatasetAccess {
  headerItems: string[];
  datasetAccess: any;
  editClickHandler: (name, id) => void;
}

export const DatasetAccessTable = ({ headerItems, datasetAccess, editClickHandler }: IDatasetAccess): JSX.Element => {
  const isDisplayedForGroups = headerItems.indexOf("Logo") >= 0;

  return (
    <Table celled striped selectable>
      <Table.Header>
        <Table.Row>
          {headerItems.map((item) => (
            <Table.HeaderCell key={item} className="fs-1125 text-secondary">
              {item}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {datasetAccess?.map((item) => {
          const { id, logo, name, first_name, last_name, email, group, access_limit, dataset_group_id, dataset_user_id } = item;
          return (
            <Table.Row key={id} className="fs-1000 text-dark fw-500">
              {isDisplayedForGroups && (
                <Table.Cell collapsing>
                  <Image src={logo || "/assets/seer-default-company-logo.png"} size="mini" circular style={{ width: 40 }} />
                </Table.Cell>
              )}
              {isDisplayedForGroups && (
                <Table.Cell collapsing className="fw-700">
                  {name}
                </Table.Cell>
              )}
              {!isDisplayedForGroups && <Table.Cell className="fw-700">{`${first_name} ${last_name}`}</Table.Cell>}
              {!isDisplayedForGroups && <Table.Cell collapsing>{email}</Table.Cell>}
              {!isDisplayedForGroups && <Table.Cell collapsing>{group.name}</Table.Cell>}
              <Table.Cell className="fw-700">
                {access_limit ? (
                  <>
                    {Object.keys(access_limit).map((key) => (
                      <div key={key}>
                        <p className="mb-1">{key}</p>
                        {access_limit[key].length !== 0
                          ? access_limit[key].map((category) => (
                              <Label key={category} className="mb-1 ml-0 mr-1" circular>
                                {category}
                              </Label>
                            ))
                          : "No Access"}
                      </div>
                    ))}
                  </>
                ) : (
                  "Full Access"
                )}
              </Table.Cell>
              <Table.Cell collapsing>
                <div
                  className="text-secondary d-flex align-items-center cursor-pointer"
                  onClick={() => {
                    if (isDisplayedForGroups) {
                      editClickHandler(name, dataset_group_id);
                    } else {
                      editClickHandler(`${first_name} ${last_name}`, dataset_user_id);
                    }
                  }}
                >
                  <p className="fs-1000 fw-700 mb-0 mt-1">Edit</p>
                  <Icon name="edit" className="ml-2 mt-0" />
                </div>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};
