import * as React from "react";
import { Button, Icon, Modal, Radio } from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";

interface IMessages {
  title: string;
  content: null | string;
  extraContent?: null | string;
}

interface IShowDatasetMessages {
  "Welcome Message": boolean;
  "Reminder Message": boolean;
  "Thank You Message": boolean;
}

interface IEmailMessagesModal {
  subHeading: string;
  isOpen: boolean;
  emailMessages: IMessages[];
  closeModal: () => void;
  saveModal: (messages) => void;
}

export const EmailMessagesModal = ({ subHeading, isOpen, emailMessages, closeModal, saveModal }: IEmailMessagesModal): JSX.Element => {
  const [ messages, setMessages ] = React.useState<IMessages[]>([]);
  const [ showDatasetMessages, setShowDatasetMessages ] = React.useState<IShowDatasetMessages>({
    "Welcome Message": false,
    "Reminder Message": false,
    "Thank You Message": false,
  });

  React.useEffect(() => {
    setMessages(emailMessages);
    setShowDatasetMessages({
      "Welcome Message": false,
      "Reminder Message": false,
      "Thank You Message": false,
    });
  }, [isOpen, emailMessages]);

  const handleTextAreaChange = (title, value) => {
    setMessages(messages.map(message => {
      if (message.title === title) {
        message.content = value;
      }
      return message;
    }));
  };

  const toggleDatasetMessages = (title) => {
    setShowDatasetMessages({
      ...showDatasetMessages,
      [title]: !showDatasetMessages[title],
    });
  };

  return (
    <Modal open={isOpen} onClose={closeModal} closeOnDimmerClick={false}>
      <Modal.Header className="fs-2000 fw-700 text-secondary">
        Configure Emails
        <p className="fs-1250 fw-500 text-dark mt-2">{subHeading}</p>
      </Modal.Header>
      <Modal.Content style={{ overflow: "auto", maxHeight: "calc(100vh - 280px)", minHeight: 500 }}>
        {
          messages?.map((message, index) => {
            const { title, content, extraContent } = message;
            return (
              <div key={index}>
                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center mb-2">
                  <h5 className="mb-0">{title}</h5>
                  {
                    extraContent &&
                    <div className="d-flex align-items-center">
                      <p className="mb-0 mr-2 fw-600 text-secondary">
                        {`${showDatasetMessages[title] ? "Hide" : "Show"} Dataset Message`}
                      </p>
                      <Radio toggle onChange={() => toggleDatasetMessages(title)} />
                    </div>
                  }
                </div>
                {
                  showDatasetMessages[title] &&
                  <div className="bg-light p-2 mb-2 rounded-1">
                    <p className="text-prewrap mb-2">{extraContent}</p>
                    <Button
                      size="mini" className="mr-0"
                      onClick={() => handleTextAreaChange(title, extraContent)}
                    >
                      <Icon name="copy" />Copy to template
                    </Button>
                  </div>
                }
                <TextareaAutosize
                  placeholder="Enter email message"
                  value={content || undefined}
                  minRows={2}
                  style={{ resize: "none", width: "100%", padding: 5, marginBottom: 15 }}
                  onChange={(e) => handleTextAreaChange(title, e.target.value)}
                />
              </div>
            );
          })
        }
      </Modal.Content>
      <Modal.Actions>
        <Button className="mr-2" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          className="bg-primary text-white bg-hover-red"
          onClick={() => {
            saveModal(messages);
            closeModal();
          }}
        >
          Update
          <Icon name="save" className="ml-1 mr-0" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
