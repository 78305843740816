import * as React from "react";

interface IExplorePillLabel {
  hasBorder?: boolean;
  label: string | number;
}

export const ExplorePillLabel = ({ hasBorder = false, label }: IExplorePillLabel): JSX.Element => (
  <div
    className={`d-inline-block fs-0875 rounded-pill px-3 py-1 mr-2 ${hasBorder ? "border" : ""}`}
    style={{ background: "rgba(255, 255, 255, 0.2" }}
  >
    {label}
  </div>
);
