import { inject, observer } from "mobx-react";
import React = require("react");
import * as qs from "qs";
import { RouteComponentProps, withRouter } from "react-router";
import { ModalActions, Modal, Icon, Dropdown, Button, Container, Message, SemanticICONS, Confirm, Header } from "semantic-ui-react";
import Protected from "component/protected";
import Store from "common/store";
import ManageAccess from "component/popup/manageAccess/ManageAccess";
import Editable from "component/Editable";
import Attachments from "component/attachments";
import InsightsList from "component/InsightsList";
import Comments from "component/Comments/comments";
import { Hr, EditName, VerticalDash, StyleNameContainer, StyleSuitaseType, StyleMemberLength } from "./suitcaseOverviewPage.styles";
import { database, getMixpanel } from "common/api";
import { Heading } from "common/styledComponents/elements";
import styled from "styled-components";
import { getTinymce } from "@tinymce/tinymce-react/lib/cjs/main/ts/TinyMCE";
import { NavLink, Prompt } from "react-router-dom";
import { shortenString } from "common/helpers/string";
import { getAccessLevel, getLatestUpdatedDate, getReducedSuitcaseUsers, SUITCASES_TAB } from "common/helpers/suitcases";
import { Helmet } from "react-helmet";
import { find } from "lodash";
import { withColorApplicator } from "component/ColorPaletteWidgets/withColorApplicator";
import { ColorApplicatorModal } from "./includes/ColorApplicatorModal";
import { ShowLoaderGlobal } from "component/LoaderGlobal";
import { SuitcasesList } from "component/Suitcases/includes/SuitcasesList";
import { CreateSuitcaseModal } from "./includes/CreateSuitcaseModal";
import { MoveDuplicateModal } from "component/MoveDuplicateModal/MoveDuplicateModal";
import { Breadcrumbs } from "component/Breadcrumbs/Breadcrumbs";
import { printPdf } from "common/constants";
import { ContentPlaceholder } from "component/ContentPlaceholder";
import { TinymceEditor } from "component/TinymceEditor";
import { LocaliseSuitcaseModal } from "component/LocaliseSuitcaseModal/LocaliseSuitcaseModal";
import { UnlayerEditor } from "component/Unlayer/UnlayerEditor";
import { getFeaturePermissions } from "common/helpers/user";
import { MetaInfoBar } from "component/MetaInfoBar/MetaInfoBar";
import { COLORS } from "component/UI/common";
import { ContentPageEmptyStateDescription, EmptyStateSecondary } from "component/EmptyState/EmptyState";
import { getChartConfiguration, getChartData, getChartKey, getChartTables, getCustomChartColorNumber } from "common/helpers/chart";
import { getMissingFallback } from "common/helpers/explore";
import { DescriptionWrapper } from "common/styledComponents/descriptionWrapper";

const StyledContainer = styled(Container)`
  &.ui.container {
    max-width: 940px !important;
  }
`;

export const StyledDescription = styled.div`
  word-break: break-word;
  & img {
    max-width: 100%;
    height: auto;
  }
`;

// label mapping for suitcase actions
const actionText = {
  "Manage access": "Manage access",
  Duplicate: "Duplicate",
  "Suitcase Colors": (
    <span style={{ lineHeight: "1.5" }}>
      Brush <Icon name="paint brush" className="pl-2" />
    </span>
  ),
  Delete: "Delete",
  Print: (
    <span>
      Print <Icon name="print" className="pl-2" />
    </span>
  ),
  Move: "Move",
  "Create Suitcase": "Create Suitcase",
  "Localise Suitcase": (
    <span style={{ lineHeight: "1.5" }}>
      Localise Suitcase <Icon name="map marker alternate" className="pl-2" />
    </span>
  ),
};

// suitcase owner actions for Standard users
const ownerStandardActions = ["Manage access", "Duplicate", "Suitcase Colors", "Delete", "Print"];

// ancestor suitcase owner actions for Standard users
const ancestorOwnerStandardActions = ["Duplicate", "Suitcase Colors", "Delete", "Print"];

// write access actions for Standard users
const writeStandardActions = ["Duplicate", "Suitcase Colors", "Print"];

// suitcase owner actions for Plus and Unlimited users
const ownerPremiumActions = [
  "Manage access",
  "Duplicate",
  "Move",
  "Create Suitcase",
  "Suitcase Colors",
  "Localise Suitcase",
  "Delete",
  "Print",
];

// ancestor suitcase owner actions for Plus and Unlimited users
const ancestorOwnerPremiumActions = ["Duplicate", "Move", "Create Suitcase", "Suitcase Colors", "Localise Suitcase", "Delete", "Print"];

// write access actions for Plus and Unlimited users
const writePremiumActions = ["Duplicate", "Move", "Create Suitcase", "Suitcase Colors", "Localise Suitcase", "Print"];

// draft suitcase actions
const draftActions = ["Duplicate", "Suitcase Colors", "Print"];

// read-only actions
const readActions = ["Duplicate", "Print"];

// public link actions
const publicLinkActions = ["Print"];

interface Props extends RouteComponentProps<{ suitcaseId: string; publicKey: string }> {
  store: Store;
  applicator: any;
}

class Component extends React.Component<Props> {
  state: any = {
    showEditor: false,
    showUnlayer: false,
    suitcaseLoadError: false,
    showCreateSuitcaseModal: false,
    showColorsModal: false,
    showLocaliseSuitcaseModal: false,
    suitcaseColorsLoading: false,
    suitcaseColorsMessage: null,
    showMoveSuitcaseModalType: "",
    showDuplicateSuitcaseModal: false,
    showDeleteSuitcaseModal: false,
    deleteWarningMessage: "",
    showCheckboxes: false,
    selectedSuitcases: new Array<number>(),
    openCreateSuitcaseModalFrom: "", // Mixpanel tracking: where the user click "Create suitcase": Actions Dropdown or Suitcases List
    printLoading: false, // pdf print only
    showEditorLoader: true,
    showManageAccessModal: false,
  };

  store = this.props.store!;

  unlayerRef = React.createRef<any>();

  async handleActions(_, e) {
    const { value: action } = e;
    if (action === "Duplicate") {
      this.setState({ showDuplicateSuitcaseModal: true });
      getMixpanel(this.store).track("Suitcase > Duplicate > Open");
    } else if (action === "Delete") {
      this.setState({ deleteWarningMessage: this.generateDeleteWarningMessage(), showDeleteSuitcaseModal: true });
    } else if (action === "Print") {
      window.open(`${window.location.origin}${this.props.location.pathname}?print=true`);
    } else if (action === "Suitcase Colors") {
      this.setState({ showColorsModal: true });
      getMixpanel(this.store).track("Suitcase > Colour Applicator > Open");
    } else if (action === "Move") {
      this.setState({ showMoveSuitcaseModalType: "SINGLE" });
    } else if (action === "Create Suitcase") {
      !this.store.user!.group.expired &&
        this.setState({
          showCreateSuitcaseModal: true,
          openCreateSuitcaseModalFrom: "Actions Dropdown",
        });
    } else if (action === "Localise Suitcase") {
      this.setState({ showLocaliseSuitcaseModal: true });
      getMixpanel(this.store).track("Suitcase > Localise Suitcase > Open");
    } else if (action === "Manage access") {
      this.handleShare();
    }
  }

  handleShare() {
    getMixpanel(this.store).track("Manage Access");
    this.setState({ showManageAccessModal: true });
  }

  async componentDidUpdate(prevProps) {
    const suitcaseId = this.getCurrentSuitcaseID();
    const suitcaseIdPrev = Number(prevProps.match.params.suitcaseId);
    const { publicKey } = this.props.match.params;
    const { publicKey: publicKeyPrev } = prevProps.match.params;
    if (suitcaseIdPrev !== suitcaseId || publicKeyPrev !== publicKey) {
      this.setState({ showEditor: false });
      await this.reloadSuitcase();
      this.pageViewEvent();
    }
    if (this.state.showManageAccessModal && !(this.store.user && this.store.suitcase.active?.user_owns_ancestor)) {
      this.setState({ showManageAccessModal: false });
    }
  }

  async componentDidMount() {
    const suitcaseId = this.getCurrentSuitcaseID();
    if (this.store.suitcase.active && this.store.suitcase.active!.id !== suitcaseId) {
      this.store.suitcase.active = undefined;
    }
    await this.reloadSuitcase();
    this.store.insight.comments.type = "suitcases";
    this.pageViewEvent();

    if (this.store.insightbuilder.datasets.length === 0) {
      // load available datasets if missing, needed to display messaging around insights that are using datasets that are
      // no longer available on their current subscription (i.e. dropped from plus to standard)
      this.store.insightbuilder.searchDatasets();
    }

    this.checkPrint();
  }

  reloadSuitcase = async () => {
    const suitcaseId = this.getCurrentSuitcaseID();
    const { publicKey } = this.props.match.params;
    if (publicKey) {
      const result = await this.store.suitcase.getPublicSuitcase(suitcaseId, publicKey);
      if (!result) {
        this.setState({ suitcaseLoadError: true });
      }
    } else {
      await this.store.suitcase.getSuitcases(suitcaseId);
    }
  };

  pageViewEvent = () => {
    const { publicKey } = this.props.match.params;
    const _active: any = this.store.suitcase.active;
    const suitcaseOwner = _active && find(_active.users, (user) => user.id === _active.user_id);
    const org_name = suitcaseOwner?.org_name;
    const owner_name = `${suitcaseOwner?.first_name || ""} ${suitcaseOwner?.last_name || ""}`.trim();
    getMixpanel(this.store).track("Page view", {
      Page: "Suitcase",
      "Suitcase Owner Organisation": org_name || undefined,
      "Suitcase Owner Name": owner_name || undefined,
      "Suitcase Name": _active?.name || undefined,
      "Suitcase ID": _active?.id || undefined,
      "Public Link": !!publicKey,
    });
  };

  // check if we are on a print page and if so print page accordingly
  checkPrint = async () => {
    if (qs.parse(this.props.location.search.slice(1))?.print) {
      this.setState({ printLoading: true });
      document.body.className += " pdf-print-suitcase";

      await new Promise((resolve) => {
        setTimeout(() => {
          // scroll to bottom to trigger lazy loads

          // keep scrolling 2500px at a time till we reach the bottom - this is so we don't scroll too fast when a lot of insights are displayed which can cause us to miss some
          const scrollPx = 2500;
          const scrollFunc = () => {
            if (window.scrollY + scrollPx > document.body.scrollHeight) {
              // scroll to bottom and clear interval
              window.scrollTo({
                top: document.body.scrollHeight,
                left: 0,
                behavior: "smooth",
              });
              window[printPdf.scrollIntervalVarName] && clearInterval(window[printPdf.scrollIntervalVarName]);
            } else {
              // scroll by scrollPx
              window.scrollTo({
                top: window.scrollY + scrollPx,
                left: 0,
                behavior: "smooth",
              });
            }
          };
          window[printPdf.scrollIntervalVarName] = setInterval(scrollFunc, 500); // scroll every 500ms

          // check every 500ms whether all insights have been loaded and resolve when they have
          window[printPdf.insightsLoadedIntervalName] = setInterval(() => {
            if (
              this.store.suitcase?.active &&
              (window[printPdf.insightsLoadedVarName] || 0) >= this.store.suitcase.active.insights.length
            ) {
              clearInterval(window[printPdf.insightsLoadedIntervalName]);
              // wait an extra second in case loads already complete but still scrolling
              setTimeout(resolve, 1000);
            }
          }, 500);
        }, 250);
      });
      this.setState({ printLoading: false });

      // print
      window.addEventListener("afterprint", () => window.close());
      window.print();
    }
  };

  unlayerOnSave = async (dashboard_config, description) => {
    await this.store.suitcase.updateInfo({ dashboard_config, description }, true);
    getMixpanel(this.store).track("Suitcase > Drag & Drop Editor > Save");
    this.setUnlayerDisplay(false);
  };

  setEditorDisplay = (vis: boolean) => {
    this.setState({ showEditor: vis });
  };

  setUnlayerDisplay = (vis: boolean) => {
    if (vis) {
      getMixpanel(this.store).track("Suitcase > Drag & Drop Editor > Open");
    }
    this.setState({ showUnlayer: vis });
  };

  onApplySuitcaseBrush = async () => {
    const { generateColors, selectedBrush, getCanvasColors, selectedPalette } = this.props.applicator;
    this.setState({ suitcaseColorsLoading: true });
    // Get the color palette "id" and save it to the "default_color_palette" of the "suitcase".
    // - If "defaultColorPalettes (Seer color palettes)" are selected, save "id" as "null" since they are not stored in the database and don't have an "id".
    const colorPaletteId = this.store.colorPalette.allColorPalettes[selectedPalette].id || null;
    this.store.suitcase.updateInfo({ default_color_palette: colorPaletteId });
    if (this.store.suitcase.active?.insights?.length) {
      getMixpanel(this.store).track("Suitcase > Colour Applicator > Apply");
      const failed: string[] = [];
      for (let i = 0; i < this.store.suitcase.active?.insights.length; i++) {
        try {
          const nextInsight = this.store.suitcase.active?.insights[i];
          const getRes: any = await database.get(`insights/${nextInsight.id}`, "", this.store.token!);
          const json = JSON.parse(getRes.body.data.insight.json);
          const { chart, tables, userDefinedCharts, columns, calcMissingFallback, calcMissingFallbackValue } = json;
          const chartTables = getChartTables(tables);
          const { legend, xAxis, series } = getChartConfiguration(userDefinedCharts, chartTables, columns);
          const chartData = getChartData(
            xAxis,
            legend,
            series,
            columns,
            tables,
            getMissingFallback(calcMissingFallback, calcMissingFallbackValue),
          );
          const chartKey = getChartKey(chartData);
          const colorNumber = getCustomChartColorNumber(chart.type, xAxis, chartKey, chartTables, columns, series, legend);
          const colors = generateColors([colorNumber]);
          json.customChartColors = colors.reduce((pre, cur) => pre.concat(cur), []);
          json.canvas = getCanvasColors();
          json.selectedBrush = selectedBrush;
          const putRes: any = await database.put(`insights/${nextInsight.id}`, { json: JSON.stringify(json) }, this.store.token!);
          if (putRes.statusCode !== 200) {
            failed.push(nextInsight.name);
          }
        } catch (e) {
          console.log(e);
          failed.push(this.store.suitcase.active?.insights[i].name);
        }
      }
      if (failed.length) {
        this.setState({ suitcaseColorsMessage: `Failed to update colours for these Insights: ["${failed.join('","')}"].` });
      } else {
        this.setState({
          showColorsModal: false,
          suitcaseColorsMessage: "Successfully updated your Insight colours. Click OK to refresh the page.",
        });
      }
    } else {
      this.setState({ suitcaseColorsMessage: "No Insights to update." });
    }
    this.setState({ suitcaseColorsLoading: false });
  };

  getSuitcaseActions = () => {
    const paidPlan = ["Plus", "Unlimited"].includes(this.store.userPlan);
    const activeSuitcase: any = this.store.suitcase.active || {};
    const { publicKey } = this.props.match.params;
    const currentUserID = this.store.user?.id;
    const { read_only_duplicate: readOnlyActionsAllowed, user_owns_ancestor, user_access, is_prepacked, user_id } = activeSuitcase;

    if (publicKey) {
      return publicLinkActions;
    }

    if (activeSuitcase.private) {
      return draftActions;
    }

    if (user_id === currentUserID) {
      return paidPlan ? ownerPremiumActions : ownerStandardActions;
    } else if (user_owns_ancestor) {
      return paidPlan ? ancestorOwnerPremiumActions : ancestorOwnerStandardActions;
    }

    const accessLevel = { owner: "Owner", write: "Full Access", read: "Read Only" }[user_access || "Not found"];
    if (accessLevel === "Full Access") {
      return paidPlan ? writePremiumActions : writeStandardActions;
    } else if (accessLevel === "Read Only" && (readOnlyActionsAllowed || readOnlyActionsAllowed === null)) {
      return readOnlyActionsAllowed || is_prepacked ? readActions : [];
    }

    if (is_prepacked) {
      return readActions;
    }

    return readOnlyActionsAllowed ? publicLinkActions : [];
  };

  getDropdownActionsOptions = () => this.getSuitcaseActions().map((value) => ({ value, key: value, text: actionText[value] }));

  getCurrentSuitcaseID = () => Number(this.props.match.params.suitcaseId);

  generateDeleteWarningMessage = () => {
    const activeSuitcase = this.store.suitcase.active!;
    if (!activeSuitcase) {
      return "";
    }
    const accumulatedSuitcases = activeSuitcase.number_descendent_suitcases || 0;
    const accumulatedInsights = activeSuitcase.number_insights_recursive || 0;

    if (accumulatedSuitcases === 0 && accumulatedInsights === 0) {
      return `${activeSuitcase!.name} will be deleted.`;
    }

    if (accumulatedSuitcases === 0) {
      return `${accumulatedInsights} Insight${accumulatedInsights > 1 ? "s" : ""} in ${activeSuitcase!.name} will be deleted.`;
    }

    if (accumulatedInsights === 0) {
      return `${accumulatedSuitcases} Suitcase${accumulatedSuitcases > 1 ? "s" : ""} in ${activeSuitcase!.name} will be deleted.`;
    }

    return `${accumulatedSuitcases} Suitcase${accumulatedSuitcases > 1 ? "s" : ""} and ${accumulatedInsights} insight${accumulatedInsights > 1 ? "s" : ""} in ${activeSuitcase!.name} will be deleted.`;
  };

  duplicateSuitcaseAction = async (destinationSuitcaseID: number | null): Promise<void> => {
    const currentSuitcaseID = this.getCurrentSuitcaseID();
    // Duplicate Suitcase
    const res: any = await database.post(`suitcases/${currentSuitcaseID}/copies`, {}, this.store.token!);
    const newSuitcaseID = res.body.data.suitcase.id;
    // Move Suitcase
    await database.put(`suitcases/${newSuitcaseID}`, { parent_id: destinationSuitcaseID }, this.store.token!);
    this.setState({ showDuplicateSuitcaseModal: false });
    getMixpanel(this.store).track("Duplicate Suitcase", { "Old Suitcase": currentSuitcaseID, "New Suitcase": newSuitcaseID });
    this.props.history.push(`/suitcases/${newSuitcaseID}`);
  };

  moveSuitcaseAction = async (destinationID: number | null) => {
    const currentSuitcaseID = this.getCurrentSuitcaseID();
    await this.store.suitcase.moveSuitcase(currentSuitcaseID, destinationID);
    this.setState({ showMoveSuitcaseModalType: "" });
    getMixpanel(this.store).track("Suitcase > Move Suitcase");
  };

  bulkMoveSuitcasesAction = async (destinationID: number | null) => {
    await this.store.suitcase.bulkMoveSuitcases(this.state.selectedSuitcases, destinationID);
    getMixpanel(this.store).track("Suitcase > Bulk Move Suitcases", { "Number affected": this.state.selectedSuitcases.length });
    this.setState({ showCheckboxes: false });
    this.setState({ showMoveSuitcaseModalType: "" });
    this.setState({ selectedSuitcases: [] });
  };

  deleteSuitcaseAction = async () => {
    const { id, parent_id } = this.store.suitcase.active!;
    await this.store.suitcase.deleteSuitcase(id);
    this.props.history.push(parent_id ? `/suitcases/${parent_id}` : "/suitcases");
    this.setState({ showDeleteSuitcaseModal: false });
    getMixpanel(this.store).track("Delete Suitcase");
  };

  localiseSuitcaseAction = async (insightIDs: number[], localisations, isDuplicateSuitcase) => {
    const suitcaseID = this.getCurrentSuitcaseID();
    const res: any = await database.post(
      "suitcases/localise/initiate",
      {
        suitcase_id: suitcaseID,
        insights: insightIDs,
        localisations,
        duplicate: isDuplicateSuitcase,
      },
      this.store.token!,
    );
    if (res?.statusCode === 200) {
      getMixpanel(this.store).track("Suitcase > Localise Suitcase > Apply");
      this.setState({ showLocaliseSuitcaseModal: false });
      alert("Your localisation request is being processed. You'll receive a report email once the process is completed.");
    }
  };

  render() {
    const suitcase = this.store.suitcase;
    const { active: activeSuitcase } = suitcase;
    const { userPlan } = this.store;
    const isPaidUser = userPlan === "Plus" || userPlan === "Unlimited";
    const { unlayerEditor: canUseUnlayer } = getFeaturePermissions(this.store);
    const currentSuitcaseID = this.getCurrentSuitcaseID();
    const currentSuitcaseParentID = activeSuitcase?.parent_id || 0;
    const noAccess = !suitcase.loading && !activeSuitcase;
    const hasOwnerAccess = this.store.user && activeSuitcase?.user_owns_ancestor;
    const { publicKey } = this.props.match.params;
    const readOnly =
      !!publicKey || !(activeSuitcase && ["Owner", "Full Access"].includes(getAccessLevel(activeSuitcase, this.store!.user!.id) || ""));

    const {
      showEditor, // Tinymce editor for Standard Users
      showUnlayer, // Unlayer editor for Paid Users
      suitcaseLoadError,
      showCreateSuitcaseModal,
      showColorsModal,
      showLocaliseSuitcaseModal,
      suitcaseColorsLoading,
      suitcaseColorsMessage,
      showMoveSuitcaseModalType,
      showDuplicateSuitcaseModal,
      showDeleteSuitcaseModal,
      deleteWarningMessage,
      openCreateSuitcaseModalFrom,
      printLoading,
      showEditorLoader,
      selectedSuitcases,
      showManageAccessModal,
    } = this.state;

    // Bulk Suitcase Actions - Select Handlers
    const selectSuitcase = (suitcaseId) => {
      this.setState({ selectedSuitcases: [...selectedSuitcases, suitcaseId] });
    };
    const deselectSuitcase = (suitcaseId) => {
      const index = selectedSuitcases.findIndex((s) => s === suitcaseId);
      const newSuitcaseArray = [...selectedSuitcases];
      if (index >= 0) {
        newSuitcaseArray.splice(index, 1);
        this.setState({ selectedSuitcases: newSuitcaseArray });
      }
    };

    const reducedSuitcaseUsers = (activeSuitcase && getReducedSuitcaseUsers(activeSuitcase)) || [];

    const getSubSuitcases = () =>
      suitcase.projects.filter((p) => p.parent_id === activeSuitcase!.id).sort((a, b) => a.name.localeCompare(b.name));

    // For move suitcase(s) and duplicate suitcase in MoveAndDuplicateModal
    const suitcasesToBeActioned =
      showMoveSuitcaseModalType === "BULK"
        ? getSubSuitcases().filter((suitcase) => selectedSuitcases.some((id) => id === suitcase.id))
        : [{ id: currentSuitcaseID, name: activeSuitcase?.name }];

    const actionsDropdownOptions = this.getDropdownActionsOptions();

    return (
      <>
        {(suitcaseColorsLoading || printLoading) && <ShowLoaderGlobal />}
        {/* Delete suitcase confirm */}
        <Confirm
          open={showDeleteSuitcaseModal}
          header="Are you sure?"
          content={deleteWarningMessage}
          confirmButton="Yes, delete"
          onCancel={() => this.setState({ showDeleteSuitcaseModal: false })}
          onConfirm={this.deleteSuitcaseAction}
        />
        <Modal open={!!suitcaseColorsMessage} onClose={() => this.setState({ suitcaseColorsMessage: null })}>
          <Modal.Content>{suitcaseColorsMessage}</Modal.Content>
          <ModalActions>
            <Button
              color="purple"
              onClick={() => {
                this.setState({ suitcaseColorsMessage: null });
                window.location.reload();
              }}
            >
              OK
            </Button>
          </ModalActions>
        </Modal>
        {/* Move suitcase modal */}
        <MoveDuplicateModal
          type="suitcase"
          isOpen={!!showMoveSuitcaseModalType}
          closeModal={() => this.setState({ showMoveSuitcaseModalType: "" })}
          actionClickHandler={showMoveSuitcaseModalType === "BULK" ? this.bulkMoveSuitcasesAction : this.moveSuitcaseAction}
          itemsToBeActioned={suitcasesToBeActioned}
          itemsParentSuitcaseID={showMoveSuitcaseModalType === "BULK" ? currentSuitcaseID : currentSuitcaseParentID}
          heading={
            showMoveSuitcaseModalType === "BULK"
              ? `Move ${selectedSuitcases.length} Suitcase${selectedSuitcases.length > 1 ? "s" : ""}`
              : "Move 1 Suitcase"
          }
          actionText="Move"
        />
        {/* Duplicate suitcase modal */}
        <MoveDuplicateModal
          type="suitcase"
          isOpen={showDuplicateSuitcaseModal}
          closeModal={() => this.setState({ showDuplicateSuitcaseModal: false })}
          actionClickHandler={(destinationSuitcaseID) => this.duplicateSuitcaseAction(destinationSuitcaseID)}
          itemsToBeActioned={suitcasesToBeActioned}
          itemsParentSuitcaseID={currentSuitcaseParentID}
          heading="Duplicate Suitcase"
          actionText="Duplicate"
        />
        <LocaliseSuitcaseModal
          isOpen={showLocaliseSuitcaseModal}
          closeClickHandler={() => this.setState({ showLocaliseSuitcaseModal: false })}
          confirmClickHandler={(insightIDs, localisations, isDuplicateSuitcase) =>
            this.localiseSuitcaseAction(insightIDs, localisations, isDuplicateSuitcase)
          }
        />
        <Prompt when={showEditor || showUnlayer} message="Are you sure you want to leave? You may have unsaved changes." />
        <StyledContainer>
          {noAccess && (
            <Header as="h4" className="text-center">
              You do not have access to this Suitcase.
            </Header>
          )}
          {suitcaseLoadError && (
            <Header as="h4" className="text-center">
              Page is not accessible. Please check your URL.
            </Header>
          )}
          {activeSuitcase && (
            <>
              <Helmet>
                <title>{`${shortenString(activeSuitcase.name, 5)} - Suitcase #${activeSuitcase.id}`}</title>
              </Helmet>
              <CreateSuitcaseModal
                isOpen={showCreateSuitcaseModal}
                closeModal={() => this.setState({ showCreateSuitcaseModal: false })}
                parentId={activeSuitcase.id}
                openFrom={openCreateSuitcaseModalFrom}
              />
              <ColorApplicatorModal
                applicator={this.props.applicator}
                isOpen={showColorsModal}
                closeModal={() => this.setState({ showColorsModal: false })}
                onApplyBrush={this.onApplySuitcaseBrush}
              />
              {hasOwnerAccess && showManageAccessModal && (
                <ManageAccess store={this.store} onClose={() => this.setState({ showManageAccessModal: false })} />
              )}

              {/* Suitcase Breadcrumbs */}
              <Breadcrumbs
                items={(publicKey ? [] : [{ pathname: "/suitcases", label: "Suitcases" }]).concat(
                  activeSuitcase!.breadcrumbs.map((bc) => ({
                    ...bc,
                    pathname: `${bc.pathname}${publicKey ? `/${publicKey}` : ""}`, // append public key to path links if set
                  })),
                )}
              />

              {!publicKey && (
                <>
                  <div className="d-flex justify-content-between pdf-hide flex-column flex-sm-row">
                    <div style={{ display: "flex", marginBottom: "10px" }}>
                      <div style={{ width: "40px", textAlign: "center", marginRight: "20px" }}>
                        {activeSuitcase.is_prepacked ? (
                          <Icon name={"suitcase rolling" as SemanticICONS} className="text-secondary fs-2500" />
                        ) : (
                          <Icon name={reducedSuitcaseUsers.length === 1 ? "lock" : "lock open"} className="text-secondary fs-2500" />
                        )}
                      </div>
                      <div>
                        {activeSuitcase.is_prepacked && (
                          <NavLink to="/suitcases" onClick={() => suitcase.setActiveSuitcasesTab(SUITCASES_TAB.PRE_PACKED)}>
                            <StyleSuitaseType>Prepacked</StyleSuitaseType>
                          </NavLink>
                        )}
                        {!activeSuitcase.is_prepacked && reducedSuitcaseUsers.length === 1 && (
                          <NavLink to="/suitcases" onClick={() => suitcase.setActiveSuitcasesTab(SUITCASES_TAB.PRIVATE)}>
                            <StyleSuitaseType>Private</StyleSuitaseType>
                          </NavLink>
                        )}
                        {!activeSuitcase.is_prepacked && reducedSuitcaseUsers.length > 1 && (
                          <NavLink to="/suitcases" onClick={() => suitcase.setActiveSuitcasesTab(SUITCASES_TAB.SHARED)}>
                            <StyleSuitaseType>Shared</StyleSuitaseType>
                          </NavLink>
                        )}

                        <StyleMemberLength>{reducedSuitcaseUsers.length} Members</StyleMemberLength>
                      </div>
                    </div>

                    {/* Suitcase Actions */}
                    <div className="pdf-hide" style={{ display: "flex", alignSelf: "flex-start" }}>
                      {hasOwnerAccess && !activeSuitcase.private && (
                        <Button className="mr-3" color="red" onClick={() => this.handleShare()}>
                          <span style={{ whiteSpace: "nowrap" }}>
                            Share <Icon name="share square" className="ml-2" />
                          </span>
                        </Button>
                      )}

                      {!!actionsDropdownOptions.length && (
                        <Dropdown
                          compact
                          selectOnBlur={false}
                          text="Actions"
                          value="" // Explicitly set value to "" to allow the same action to be triggered consecutively via the "onChange" event
                          selection
                          options={actionsDropdownOptions}
                          onChange={(_, e) => this.handleActions(_, e)}
                        />
                      )}
                    </div>
                  </div>
                  <VerticalDash className="d-none d-sm-inline-block" />
                </>
              )}
              <div className="d-flex flex-column-reverse flex-sm-row align-items-sm-center">
                <StyleNameContainer className="w-100">
                  <div style={{ display: "inline-block", width: "40px", textAlign: "center", marginRight: "20px" }}>
                    <Icon name="suitcase" style={{ marginLeft: "2px", color: COLORS.indigo600, lineHeight: "52px" }} className="fs-2000" />
                    <span className="sr-only">Suitcase</span>
                  </div>
                  <Editable
                    readOnly={readOnly || activeSuitcase.private}
                    id="suitcaseName"
                    text={activeSuitcase.name}
                    save={(name) => suitcase.updateInfo({ name })}
                    style={{
                      color: `${activeSuitcase.name.includes("Untitled") ? "#919191" : COLORS.indigo600}`,
                      fontWeight: "bold",
                      fontSize: "2.25rem",
                      borderColor: "#C3C3C3",
                    }}
                  />
                </StyleNameContainer>
                {publicKey && (
                  <div className="pdf-hide pl-sm-3">
                    <Dropdown
                      selectOnBlur={false}
                      text="Actions"
                      value=""
                      fluid
                      selection
                      options={actionsDropdownOptions}
                      onChange={(_, e) => this.handleActions(_, e)}
                    />
                  </div>
                )}
              </div>
              {!publicKey && (
                <MetaInfoBar
                  updated={getLatestUpdatedDate(activeSuitcase)}
                  roleLabel="OWNER"
                  custodian={activeSuitcase.users.find((user) => user.id === activeSuitcase.user_id)!}
                  usersSharedWith={reducedSuitcaseUsers.filter((user) => user.id !== activeSuitcase.user_id)}
                />
              )}
              <Hr />
              {/* Summary */}
              {activeSuitcase.private ? (
                <Message
                  header="This is the default Private Suitcase and can't be edited or shared."
                  size="tiny"
                  style={{ background: "#FBFDEF", color: "#8DC63F", border: "1px solid #8DC63F" }}
                />
              ) : (
                <>
                  {(!readOnly || (activeSuitcase.description?.length || 0) > 0) && !activeSuitcase.private && (
                    <EditName desc style={{ marginTop: 20, marginBottom: 40, width: "100%" }}>
                      <div className="pdf-hide mt-4 mb-4 d-sm-flex align-items-sm-center justify-content-sm-between">
                        <Heading>Summary</Heading>
                        {!readOnly && (
                          <>
                            {showEditor || showUnlayer ? (
                              <div>
                                <Button
                                  className="mr-3"
                                  onClick={() => {
                                    this.setEditorDisplay(false);
                                    this.setUnlayerDisplay(false);
                                  }}
                                >
                                  Cancel
                                  <Icon name="archive" className="ml-2" />
                                </Button>
                                <Button
                                  color="purple"
                                  onClick={() => {
                                    if (showUnlayer) {
                                      this.unlayerRef.current.editor.exportHtml(
                                        (data) => {
                                          let dashboard_config: any = null;
                                          let description: any = null;
                                          // Check all rows and all columns in each row to see if there is content
                                          if (
                                            !data.design.body.rows.every((row) =>
                                              row.columns.every((column) => column.contents.length === 0),
                                            )
                                          ) {
                                            dashboard_config = data.design; // For editing in Unlayer
                                            description = `
                                        ${data.chunks.body}
                                        <style>${data.chunks.css}</style>
                                        <style>.u_body {min-height: auto !important}</style>
                                        <style>a {text-decoration: none} a:hover {text-decoration: none}</style>
                                        `; // For displaying in Summary section
                                          }
                                          this.unlayerOnSave(dashboard_config, description);
                                        },
                                        {
                                          cleanup: true,
                                        },
                                      );
                                    } else {
                                      suitcase.updateInfo({ description: getTinymce(window)?.activeEditor?.getContent() });
                                      this.setEditorDisplay(false);
                                    }
                                  }}
                                >
                                  Save
                                  <Icon name="save" className="ml-2" />
                                </Button>
                              </div>
                            ) : (
                              <>
                                {!activeSuitcase.description ? (
                                  <Button
                                    onClick={() => {
                                      if (canUseUnlayer) {
                                        this.setUnlayerDisplay(true);
                                      } else {
                                        this.setEditorDisplay(true);
                                      }
                                    }}
                                  >
                                    Write summary
                                    <Icon name="edit" className="ml-2" />
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      if (canUseUnlayer && activeSuitcase.dashboard_config) {
                                        this.setUnlayerDisplay(true); // Use Unalyer if Paid Users have description created with Unlayer
                                      } else {
                                        this.setEditorDisplay(true); // Use Tinymce for Paid Users have description created with Tinymce & all Standard Users
                                      }
                                    }}
                                  >
                                    Edit summary
                                    <Icon name="edit" className="ml-2" />
                                  </Button>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                      {/* Standard User or Paid User who has used Tinymce before */}
                      {showEditor && (
                        <>
                          {showEditorLoader && <ContentPlaceholder />}
                          <TinymceEditor
                            description={activeSuitcase.description!}
                            initHandler={() => this.setState({ showEditorLoader: false })}
                          />
                        </>
                      )}
                      {/* Paid User Unlayer Editor */}
                      {showUnlayer && (
                        <UnlayerEditor ref={this.unlayerRef} config={activeSuitcase.dashboard_config} token={this.store.token!} />
                      )}
                      {/* Display description */}
                      {!showEditor && !showUnlayer && (
                        <>
                          {activeSuitcase.description ? (
                            <DescriptionWrapper>
                              <StyledDescription dangerouslySetInnerHTML={{ __html: activeSuitcase.description }} />
                            </DescriptionWrapper>
                          ) : (
                            <EmptyStateSecondary
                              inverted
                              hideIcon
                              heading="No Suitcase Summary Yet"
                              description={
                                <ContentPageEmptyStateDescription
                                  onClick={() => {
                                    if (canUseUnlayer) {
                                      this.setUnlayerDisplay(true);
                                    } else {
                                      this.setEditorDisplay(true);
                                    }
                                  }}
                                />
                              }
                            />
                          )}
                        </>
                      )}
                    </EditName>
                  )}
                </>
              )}
              {/* Sub-Suitcases */}
              {suitcase.projects.filter((sc) => sc.parent_id === activeSuitcase.id).length > 0 && (
                <>
                  <div className="pdf-hide mt-4 mb-4 d-sm-flex align-items-sm-center justify-content-sm-between" style={{ clear: "both" }}>
                    <Heading>Suitcases</Heading>
                    {!publicKey &&
                      ["Owner", "Full Access"].includes(getAccessLevel(activeSuitcase, this.store!.user!.id) || "") &&
                      isPaidUser && (
                        <div>
                          {this.state.showCheckboxes ? (
                            <>
                              <Button onClick={() => this.setState({ showCheckboxes: false, selectedSuitcases: [] })}>Cancel</Button>
                              <Button
                                color="purple"
                                className="mr-3"
                                onClick={() => this.setState({ showMoveSuitcaseModalType: "BULK" })}
                                disabled={selectedSuitcases.length === 0}
                              >
                                Move
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                style={{ cursor: this.store.user!.group.expired ? "not-allowed" : "pointer" }}
                                onClick={() =>
                                  !this.store.user!.group.expired &&
                                  this.setState({ showCreateSuitcaseModal: true, openCreateSuitcaseModalFrom: "Sub-suitcases List" })
                                }
                              >
                                Create suitcase <Icon className="mr-0 ml-2" name="suitcase" />
                              </Button>
                              {getSubSuitcases().some((s) => ["owner", "write"].includes(s.user_access || "")) && (
                                <Dropdown
                                  className="ml-3"
                                  icon="ellipsis vertical"
                                  text=" "
                                  options={[
                                    { key: 1, text: "Bulk Edit", value: 1, onClick: () => this.setState({ showCheckboxes: true }) },
                                  ]}
                                  simple
                                  item
                                />
                              )}
                            </>
                          )}
                        </div>
                      )}
                  </div>
                  <SuitcasesList
                    publicKey={publicKey}
                    showFolder={true}
                    showAccess={!publicKey}
                    showCheckboxes={this.state.showCheckboxes}
                    selectSuitcase={selectSuitcase}
                    deselectSuitcase={deselectSuitcase}
                    suitcases={getSubSuitcases()}
                  />
                </>
              )}

              {/* Insights */}
              {!(activeSuitcase.insights.length === 0 && readOnly) && (
                <InsightsList suitcase={activeSuitcase} store={suitcase} readonly={readOnly} publicUrlKey={publicKey} />
              )}

              {/* Attachments */}
              {!(activeSuitcase.files?.length === 0 && readOnly) && (
                <div className={activeSuitcase.files?.length > 0 ? "" : "pdf-hide"}>
                  {(activeSuitcase.files?.length > 0 || !publicKey) && (
                    <Attachments store={suitcase} readonly={readOnly} publicKey={publicKey} />
                  )}
                </div>
              )}

              {/* Comments */}
              {!publicKey && !(activeSuitcase.comments?.length === 0 && readOnly) && this.store.insight.comments.type === "suitcases" && (
                <div className={this.store.insight.comments?.mainComments?.length > 0 ? "" : "pdf-hide"} style={{ marginTop: 100 }}>
                  <Header as="h3" color="purple">
                    Comments
                  </Header>
                  <Hr />
                  <Comments key={currentSuitcaseID} store={this.store} readonly={readOnly} />
                </div>
              )}

              {/* No Content Public Link Message */}
              {publicKey &&
                !activeSuitcase.description?.length &&
                suitcase.projects.length === 0 &&
                !activeSuitcase.insights.length &&
                !activeSuitcase.files?.length && <div className="text-center pt-6 mt-6">No content to display.</div>}
            </>
          )}
        </StyledContainer>
      </>
    );
  }
}

export const Suitcase = withColorApplicator(withRouter(Protected(inject("store")(observer(Component)))));
