// Route Helpers

export const isPublicURL = (): boolean => {
  const { pathname } = window.location;
  const suitcaseFound = pathname.match(/^\/suitcases\/[0-9]+(\/[^#?\/]+$)/);
  if (suitcaseFound && suitcaseFound[1] !== "/explore") {
    // Filter out "/suitcases/:suitcaseId/explore"
    return true; // - /suitcases/:id/:public_key
  } else if (pathname.match(/^\/insights\/[0-9]+\/[^#?\/]+$/)) {
    return true;
  } else if (pathname.match(/^\/dashboards\/[0-9]+\/[^#?\/]+$/)) {
    return true;
  }
  return false;
};
