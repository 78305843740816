import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Container, Icon } from "semantic-ui-react";
import Store from "common/store";
import Protected from "component/protected";
import { StyleHeading } from "component/Suitcases/SuitcasesContent.style";
import { DatasetTemplateList } from "./includes/DatasetTemplateList";
import { CreateModal } from "./includes/CreateModal";
import { EmptyStatePrimary } from "component/EmptyState/EmptyState";
import { getMixpanel } from "common/api";
import { COLORS } from "component/UI/common";

interface IDatasets {
  store?: Store;
}

const datasetsHeaderItems = [
  {
    name: "Name",
    sortColumn: "name",
    className: "",
  },
  {
    name: "Templates",
    sortColumn: "Templates",
    className: "w-15",
  },
  {
    name: "Link",
    className: "w-15 cursor-default",
  },
  {
    name: "Latest Data",
    className: "w-15",
  },
];

const dashboardEmptyStateDescription = (
  <p className="fs-1125 mb-5" style={{ color: COLORS.grey800 }}>
    <span>
      Collect internal or external data in a single place. Ingested automatically into your Self-service Data Collection dataset. Explore,
      analyse and create Insights from the collected data.{" "}
    </span>
    <span>
      <a
        target="_blank"
        href="https://knowledge.seerdata.ai/what-is-self-service-data-collection"
        className="text-decoration-underline"
        style={{ color: COLORS.blue }}
      >
        Click here
      </a>
    </span>
    <span> to learn more about Self-service Data Collection.</span>
  </p>
);

const DatasetsComponent = ({ store }: IDatasets): JSX.Element => {
  const [showCreateDatasetModal, setShowCreateDatasetModal] = React.useState(false);
  const history = useHistory();
  const hasAccessToDatasets =
    store!.hasGroupAddOn("Self-service Data Ingestion") || store!.userPlan === "Plus" || store!.userPlan === "Unlimited";

  React.useEffect(() => {
    store!.dataset.getDatasets();
    window.scrollTo(0, 0);
  }, []);

  const { datasets } = store!.dataset;
  const datasetsProcessed = datasets?.map((ds) => {
    const { templates } = ds;
    return {
      ...ds,
      templatesLength: templates.length,
    };
  });

  const createDataset = async (nameStr): Promise<string | void> => {
    const name = (nameStr || "").trim();
    if (!name) {
      return "Dataset name is required";
    }
    const datasetID = await store!.dataset.createDataset(name);
    getMixpanel(store!).track("Create Dataset", { "Dataset Name": name, "Dataset Id": datasetID });
    history.push(`/datasets/${datasetID}`);
  };

  const upgradePlan = () => {
    getMixpanel(store!).track("Click Upgrade Plan", { Page: "Datasets" });
    window.open("https://seerdata.ai/plus-plan/", "_blank");
  };

  return (
    <Container>
      <Helmet>
        <title>Datasets</title>
      </Helmet>
      {/* Create Dataset Modal */}
      <CreateModal
        heading="Dataset"
        isOpen={showCreateDatasetModal}
        closeModal={() => setShowCreateDatasetModal(false)}
        saveModal={createDataset}
      />
      <div className="d-flex justify-content-between align-items-center mb-3">
        <StyleHeading>Datasets</StyleHeading>
        {hasAccessToDatasets && (
          <Button id="userflow-element-create-dataset" className="bg-primary text-white" onClick={() => setShowCreateDatasetModal(true)}>
            Create Dataset
            <Icon className="plus ml-2 mr-0" />
          </Button>
        )}
      </div>
      {hasAccessToDatasets ? (
        <>
          {datasetsProcessed?.length ? (
            <DatasetTemplateList
              headerItems={datasetsHeaderItems}
              // Pass re-formatted datasets to child component to make sorting works
              datasets={datasetsProcessed}
            />
          ) : (
            <EmptyStatePrimary
              heading="No Datasets Available"
              description={dashboardEmptyStateDescription}
              image="/assets/dashboard_empty_state_image.png"
              primaryButton={{
                label: "Create Dataset",
                icon: "plus",
                onClick: () => setShowCreateDatasetModal(true),
              }}
            />
          )}
        </>
      ) : (
        <EmptyStatePrimary
          heading="Self-service Data Collection is an Add On for organisations that have a Seer Data Plus plan"
          description={dashboardEmptyStateDescription}
          image="/assets/dashboard_empty_state_image.png"
          primaryButton={{
            label: "Upgrade Your Plan",
            icon: "arrow circle up",
            onClick: upgradePlan,
          }}
          secondaryButton={{
            label: "Lean more about your plans",
            icon: "external alternate",
            onClick: () => store!.handleUpgradeShow(true),
          }}
        />
      )}
    </Container>
  );
};

export const Datasets = Protected(inject("store")(observer(DatasetsComponent)));
