import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Builder } from "common/store/builder";
import { Grid } from "semantic-ui-react";
import { buildArrayCombos } from "common/helpers/dimensions";
import { canChartCalculation } from "common/helpers/explore";
import { CHART_FONT_FAMILY, CHART_PRIMARY_COLOR, ifShowTableNames, isALLChartTablesCal } from "common/helpers/chart";
import { ColorSquare, getWrapperComponent, LegendLayout } from "component/Charts/Legend/includes/LegendComponents";
import { withColorPicker } from "./withColorPicker";

interface ExploreLegendContent {
  builder?: Builder;
  edit?: boolean;
  showGridLayout?: boolean;
  condensedLegend?: boolean;
  hideTableNames?: boolean;
  colorSquareClickHandler: (tableID: number, colorIdxInTableColors: number, color: string, colorIdxInAllColors: number) => void;
}

const GeneralChartLegendComponent = (props: ExploreLegendContent): JSX.Element | null => {
  const { builder, edit, showGridLayout, condensedLegend, hideTableNames, colorSquareClickHandler } = props;
  const { chartTables: chartTablesUnfiltered, newChartData: chartData, chartLegend: chartsLegend, chartColors } = builder!;
  // filter out any calculations that can't be charted with the current chart configuration
  const chartTables = chartTablesUnfiltered.filter(
    (t) =>
      t.type !== "calc" ||
      canChartCalculation(
        builder!.columns,
        isALLChartTablesCal(chartTablesUnfiltered) ? builder!.columns : builder!.chartXAxisArray,
        !!builder!.chartSeries?.length,
      ),
  );
  // Making a shallow copy here as `legendColors` will be modified with `splice` below
  const legendColors = [...chartColors];
  const chartLegendDims = chartsLegend.map((d) => d.dimension);
  const chartTablesColorsLength: number[] = []; // Store the color length of each chartTable for calculating the color index in all customChartColors

  if (chartData.length === 0) {
    return null;
  }

  const WrapperComponent = getWrapperComponent(showGridLayout);

  return (
    // Multi-table legend
    <>
      {chartTables.map((table, tableIdx) => {
        const { filters, rows, columns } = table;

        const allTableDims = table.type === "calc" ? [] : [...filters, ...rows, ...columns];
        const legendTableDims = chartLegendDims
          .map((v) => {
            if (v.startsWith("!")) {
              return chartsLegend.find((d) => d.dimension === v);
            } else {
              return allTableDims.find((d) => d.dimension === v);
            }
          })
          .filter((d) => d !== undefined);

        let currentLegendColors;
        const legendTableIndex = buildArrayCombos(legendTableDims.map((d) => d.values));
        if (legendTableDims.length === 0) {
          // Handle the no multiple categories row variables scenario(single row table)
          currentLegendColors = legendColors.splice(0, 1);
          chartTablesColorsLength.push(1);
        } else {
          currentLegendColors = legendColors.splice(0, legendTableIndex.length);
          chartTablesColorsLength.push(legendTableIndex.length as number);
        }
        const prevTablesColorsLength = chartTablesColorsLength.slice(0, tableIdx).reduce((prev, curr) => prev + curr, 0);
        const gridColumns: any = Math.min(
          builder!.isValidSimplePieChart()
            ? builder!.chartXAxisArray[0].values.length
            : legendTableDims[legendTableDims.length - 1]?.values.length || 1,
          4,
        );

        return (
          <div key={table.id} className={`${condensedLegend ? "" : "mb-4"}`}>
            {ifShowTableNames(chartTables) && !hideTableNames && (
              <p
                className={`${condensedLegend ? "mb-1" : "mb-2"} fs-1000 fw-700`}
                style={{ color: CHART_PRIMARY_COLOR, fontFamily: CHART_FONT_FAMILY }}
              >
                {table.name}
              </p>
            )}
            <Grid className={`${condensedLegend ? "" : "mb-4"}`} columns={gridColumns} padded doubling>
              {builder!.isValidSimplePieChart() ? (
                <>
                  {builder!.chartXAxisArray[0].values.map((val, idx) => (
                    <WrapperComponent
                      labelIdx={idx}
                      render={() => (
                        <div className="d-flex align-items-start">
                          <ColorSquare
                            edit={edit}
                            color={chartColors[idx]}
                            onClick={() =>
                              edit ? colorSquareClickHandler(table.id as number, idx as number, chartColors[idx], idx as number) : null
                            }
                          />
                          <p className="fs-0750 fw-500" style={{ color: CHART_PRIMARY_COLOR }}>
                            {builder!.chartXAxisArray[0].dimension.startsWith("!") ? val.split(":::")[1] : val}
                          </p>
                        </div>
                      )}
                    />
                  ))}
                </>
              ) : (
                <>
                  {legendTableDims.length > 0 ? (
                    legendTableDims.filter((d) => d.values.length).length > 0 && (
                      <LegendLayout
                        legendItems={legendTableIndex}
                        table={table}
                        edit={edit}
                        colors={currentLegendColors}
                        colorSquareClickHandler={colorSquareClickHandler}
                        Wrapper={WrapperComponent}
                        prevTablesColorsLength={prevTablesColorsLength}
                      />
                    )
                  ) : (
                    <div className="d-flex align-items-start my-1 mr-2 pl-0">
                      <ColorSquare
                        edit={edit}
                        color={currentLegendColors[0]}
                        onClick={() =>
                          edit
                            ? colorSquareClickHandler(table.id as number, 0, currentLegendColors[0] as string, prevTablesColorsLength)
                            : null
                        }
                      />
                      <p className="fs-0750 fw-500" style={{ color: CHART_PRIMARY_COLOR }}>
                        {table.name}
                      </p>
                    </div>
                  )}
                </>
              )}
            </Grid>
          </div>
        );
      })}
    </>
  );
};

export const GeneralChartLegend = withColorPicker(
  inject((stores: any) => ({
    builder: stores.store.builder,
  }))(observer(GeneralChartLegendComponent)),
);
