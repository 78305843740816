import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Dataset from "common/store/dataset";
import { Button, Divider, List, ListItem } from "semantic-ui-react";
import { Aggregation } from "common/store/dataset/urlUpload";

interface IAggregationsList {
  dataset?: Dataset;
}

const AggregationsListComponent = (props: IAggregationsList): JSX.Element => {
  const { urlupload } = props.dataset!;
  const { aggregations } = urlupload;

  const displayAggregationRule = (aggregation: Aggregation): string =>
    aggregation.type === "count all" ? "Number of records" : `${aggregation.column} - ${aggregation.type}`;

  const doAutoAggregate = () => {
    if (!confirm("Applying Auto Aggregations will reset any existing aggregations. Confirm to continue.")) {
      return;
    }
    urlupload.setAutoAggregations();
  };

  return (
    <>
      <Button color="purple" onClick={doAutoAggregate} fluid>
        Auto Aggregation
      </Button>
      <Divider />
      <List ordered>
        {aggregations.map((agg, idx) => (
          <ListItem key={`${agg.type}-${idx}`}>{displayAggregationRule(agg)}</ListItem>
        ))}
      </List>
    </>
  );
};

export const AggregationsList = inject((stores: any) => ({
  dataset: stores.store.dataset,
}))(observer(AggregationsListComponent));
