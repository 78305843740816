import React = require("react");
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Icon, Checkbox, Button, Popup } from "semantic-ui-react";

import Store from "../../../common/store";
import { UserAccessRow } from "./UserAccessRow";
import { getMixpanel } from "../../../common/api";
import { OrgAccessRow } from "./OrgAccessRow";
import { PublicLink } from "./PublicLink";

import { StyleModal, StyleContent, StyleActions, StyleHeader } from "../../../common/styledComponents/_modals.style";
import { getAccessLevel } from "common/helpers/suitcases";

// TODO: Consolidate all access type strings in a central place with fewer versions
const userAccessTypes = [
  { key: 1, text: "No access", value: "no_access" },
  { key: 2, text: "Read only", value: "read_only" },
  { key: 3, text: "Full access", value: "full_access" },
];

const accessTextTranslation = {
  no_access: "No Access",
  read_only: "Read Only",
  full_access: "Full Access",
};

const ManageAccess: React.FC<{ store: Store; onClose: () => any }> = ({ store, onClose }) => {
  const AccessTypes = {
    NoAccess: "no_access",
    ReadOnly: "read_only",
    FullAccess: "full_access",
    Custom: "custom",
  };

  const addMember = store.suitcase.addMember;

  const activeSuitcase = store.suitcase.active!;
  // const isPlusCustomer = (find(store.group_types, { name: "Plus" }) || { id: -1 }).id === store.user!.group?.group_type_id;
  const allSuitcaseUsers = [...store.suitcase.active!.users, ...store.suitcase.active!.users_implied];
  const impliedSuitcaseUsers = store.suitcase.active!.users_implied;

  const ownerOrgUsers = [...store.user!.group.users]; // Create a copy as it changes the content
  const orgPartnerships = store.group.partnerOrganisations;
  const orgSupportPartnership = store.user!.group?.support_partner;
  const shareableOrganisations = [
    { ...store.user!.group, type: "Your" },
    ...store.group.partnerOrganisations,
    { ...store.user!.group?.support_partner, type: "Support" },
  ];
  const owner = allSuitcaseUsers.find((user) => user.id === store.suitcase.active!.user_id);
  const [readOnlyActionsToggleValue, setReadOnlyActionsToggleValue] = React.useState(activeSuitcase?.read_only_duplicate || false);
  const [showOrgUsers, setShowOrgUsers] = React.useState(false);

  const getNonOwnerUsers = (users) => users.filter((user) => user.id !== store.suitcase.active!.user_id);

  const getSupportGroupUsers = () => (orgSupportPartnership ? orgSupportPartnership.users : []);

  const getPartnerGroupUsers = (id: number) => orgPartnerships.find((partner) => partner.id === id)?.users;

  const getOrgUsers = (org) => (org.type === "Your" ? getNonOwnerUsers(org.users) : org.users);

  // computes organisation level access on a whole based on the underlying user access
  const computeOrgLevelAccess = (orgId: number) => {
    const orgUsers =
      orgId <= 0 ? getSupportGroupUsers() : orgId === store.user!.group.id ? getNonOwnerUsers(ownerOrgUsers) : getPartnerGroupUsers(orgId);

    // TODO: Update the find here to cater for scenarios where an implied user has a greater access than user
    // No damage done for now because any implied access will cause the org-level setting to be disabled anyway
    let groupSuitcaseUsers = orgUsers.map((gUser) => allSuitcaseUsers.find((user) => user.id === gUser.id));
    groupSuitcaseUsers = groupSuitcaseUsers.filter((user) => user != null);
    const implied = orgUsers.some((user) => impliedSuitcaseUsers.find((u) => u.id === user.id));
    if (groupSuitcaseUsers == null || groupSuitcaseUsers.length === 0) {
      return [AccessTypes.NoAccess, implied];
    } else {
      if (groupSuitcaseUsers.length !== orgUsers.length) {
        return [AccessTypes.Custom, implied];
      } else if (groupSuitcaseUsers.every((user) => user.read_only === true)) {
        return [AccessTypes.ReadOnly, implied];
      } else if (groupSuitcaseUsers.every((user) => user.read_only === false)) {
        return [AccessTypes.FullAccess, implied];
      } else {
        return [AccessTypes.Custom, implied];
      }
    }
  };

  const checkAccess = (id: number) => {
    const accessLevel = getAccessLevel(activeSuitcase, id);

    if (accessLevel === "Read Only") {
      return AccessTypes.ReadOnly;
    } else if (accessLevel === "Full Access" || accessLevel === "Owner") {
      return AccessTypes.FullAccess;
    } else {
      return AccessTypes.NoAccess;
    }
  };

  const handlePublicAccessChange = (link_share: string) => {
    store.suitcase.updateInfo({ link_share: link_share === "true" }, true);
    if (link_share === "true") {
      getMixpanel(store).track("Public Link Share Enabled");
    }
  };

  const originalAccess = shareableOrganisations.map((org) => {
    const id = org.type === "Support" ? -1 : org.id;
    const usersList = getOrgUsers(org);
    const [accessValue, hasImpliedAccess] = computeOrgLevelAccess(id);
    return {
      value: accessValue,
      hasImpliedAccess,
      users: usersList.map((user) => ({
        value: checkAccess(user.id),
      })),
    };
  });
  const [newAccess, setNewAccess] = React.useState(originalAccess);

  const updateAccessState = (accessType, orgIdx, userIdx?: number | undefined) => {
    const nextState = [...newAccess];
    if (typeof userIdx === "undefined" || userIdx < 0) {
      // org access change
      nextState[orgIdx].value = accessType;
      nextState[orgIdx].users = newAccess[orgIdx].users.map(() => ({
        value: accessType,
      }));
    } else {
      // user access change
      nextState[orgIdx].value = "custom";
      nextState[orgIdx].users[userIdx].value = accessType;
    }
    setNewAccess(nextState);
  };

  // adds members to suitcase
  const onSubmit = () => {
    // compile changes to user access
    const userIds: Array<any> = [];
    const userIdsToRemove: Array<any> = [];
    for (let orgIdx = 0; orgIdx < originalAccess.length; orgIdx++) {
      const org = shareableOrganisations[orgIdx];
      const usersList = getOrgUsers(org);

      for (let userIdx = 0; userIdx < originalAccess[orgIdx].users.length; userIdx++) {
        if (originalAccess[orgIdx].users[userIdx].value !== newAccess[orgIdx].users[userIdx].value) {
          // access change occurred
          if (newAccess[orgIdx].users[userIdx].value === AccessTypes.NoAccess) {
            userIdsToRemove.push(usersList[userIdx].id); // access removed
          } else {
            // access modified
            userIds.push({
              user_id: usersList[userIdx].id,
              read_only: newAccess[orgIdx].users[userIdx].value !== AccessTypes.FullAccess,
            });
          }
        }
      }
    }
    addMember.addMembersNew(userIds);
    addMember.removeMembers(userIdsToRemove);
    if (readOnlyActionsToggleValue !== activeSuitcase?.read_only_duplicate) {
      store.suitcase.updateInfo({ read_only_duplicate: readOnlyActionsToggleValue }, true);
    }
    onClose();
    getMixpanel(store).track("Save Suitcase access");
  };

  const allowedUserAccessTypes = (userId) => {
    const currentUserAccess = impliedSuitcaseUsers.filter((u) => u.id === userId);

    if (currentUserAccess.length === 0) {
      return userAccessTypes;
    }

    if (currentUserAccess.some((u) => u.read_only === false)) {
      // Full Access
      // If user has implied or explicit non-read-only access, that overrides any other access level specified
      return userAccessTypes.map((u) => Object.assign({}, u, u.value === AccessTypes.FullAccess ? {} : { disabled: true }));
    } else {
      // Read Only
      return userAccessTypes.map((u) => Object.assign({}, u, u.value === AccessTypes.NoAccess ? { disabled: true } : {}));
    }
  };

  React.useEffect(() => {
    setNewAccess(originalAccess);
    setReadOnlyActionsToggleValue(activeSuitcase?.read_only_duplicate || false);
  }, []);

  return (
    <>
      <StyleModal onClose={onClose} open>
        <StyleHeader>
          Manage access
          <Button color="red" basic className="float-right" onClick={() => setShowOrgUsers(!showOrgUsers)}>
            {showOrgUsers ? (
              <>
                <Icon name="compress" />
                Collapse orgs
              </>
            ) : (
              <>
                <Icon name="expand" />
                Expand orgs
              </>
            )}
          </Button>
        </StyleHeader>

        <StyleContent>
          {/* Public Link Sharing */}
          <PublicLink suitcase={activeSuitcase} onChange={handlePublicAccessChange} />

          <div className="py-3 border-bottom">
            <Checkbox
              toggle
              checked={readOnlyActionsToggleValue}
              size="small"
              onChange={(_, data) => setReadOnlyActionsToggleValue(data.checked || false)}
              label={
                <label className="fs-1000 fw-600 text-dark">
                  Allow Read-Only user actions
                  <Popup
                    content={
                      <div>
                        Enabling this will allow:
                        <ul>
                          <li>
                            Seer Data platform users you have given read-only access to this Suitcase to use the Print / Duplicate actions.
                            Along with being able to use the Duplicate / Download actions on the Insights contained in this Suitcase (but
                            not it's descendant Suitcases).
                          </li>
                          <li>Public link viewers the ability to use the Print action (if public link sharing is enabled).</li>
                        </ul>
                      </div>
                    }
                    trigger={<Icon className="ml-2" name="info circle" />}
                  />
                </label>
              }
            />
          </div>

          {activeSuitcase.user_id === store.user!.id && (
            <>
              {/* Owner Row */}
              <UserAccessRow
                user={owner}
                isOwner
                editable={false}
                options={null}
                setByParent={false}
                index={0}
                value="full_access"
                onChange={() => null}
              />

              {/* List of shareable orgs and users */}
              {shareableOrganisations.map((org, orgIdx) => {
                const usersList = getOrgUsers(org);
                return (
                  <div key={orgIdx}>
                    <OrgAccessRow
                      index={orgIdx}
                      org={org}
                      orgType={org.type ?? "Partner"}
                      value={newAccess[orgIdx].value}
                      onChange={(_, { value }) => updateAccessState(value, orgIdx)}
                      editable={!originalAccess[orgIdx].hasImpliedAccess}
                    />
                    {showOrgUsers &&
                      usersList.map((user, userIdx) => {
                        const impliedAccessLevel = getAccessLevel(store.suitcase.active!, user.id, true);

                        return (
                          <UserAccessRow
                            user={user}
                            isOwner={false}
                            index={userIdx}
                            value={newAccess[orgIdx].users[userIdx].value}
                            onChange={(_, { value }) => updateAccessState(value, orgIdx, userIdx)}
                            editable={impliedAccessLevel !== "Full Access"}
                            setByParent={
                              originalAccess[orgIdx].users[userIdx].value === newAccess[orgIdx].users[userIdx].value &&
                              impliedAccessLevel === accessTextTranslation[originalAccess[orgIdx].users[userIdx].value]
                            }
                            options={allowedUserAccessTypes(user.id)}
                          />
                        );
                      })}
                  </div>
                );
              })}
            </>
          )}
        </StyleContent>
        <StyleActions>
          <Button onClick={onClose}>
            Cancel <Icon name="close" className="ml-1 mr-0" />
          </Button>
          <Button color="purple" onClick={() => onSubmit()}>
            Save <Icon name="save" className="ml-1 mr-0" />
          </Button>
        </StyleActions>
      </StyleModal>
    </>
  );
};

export default inject("store")(observer(ManageAccess));
