import * as React from "react";
import { inject, observer } from "mobx-react";
import styled, { css } from "styled-components";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelProps,
  LineChart,
  Line,
  AreaChart,
  Area,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Text,
} from "recharts";

import { Button, Grid, Icon, Popup } from "semantic-ui-react";
// import _ = require("lodash");
import Store from "common/store";
import {
  getCategory,
  getExtraXAxisConfig,
  showExtraXAxis,
  toDonutChartData,
  getPercentData,
  CHART_FONT_FAMILY,
  CHART_SECONDARY_COLOR,
  DEFAULT_LABEL_STYLING,
  CHART_AXIS_COLOR,
  CHART_GRID_COLOR,
  CHART_TOOLTIP_FILL,
  CHART_PRIMARY_COLOR,
  getSimplePieChartData,
  includes,
  formatter,
  getMetricChartData,
  getFilteredChartTables,
  defaultAngle,
  defaultLabelFont,
} from "common/helpers/chart";
import { getMixpanel } from "common/api";
import { CustomAxisTick, CustomBarLabel, CustomPieToolTip, CustomToolTip } from "component/Charts/CustomCharts";
import { SimplePieChart } from "component/Charts/SimplePieChart";
import { MetricChart } from "component/Charts/MetricChart";
import { MetricChartLegend } from "component/Charts/Legend/MetricChartLegend";
import { GeneralChartLegend } from "component/Charts/Legend/GeneralChartLegend";
import { ObjectAny } from "common/helpers/types";
import {
  getComputedTicks,
  getDomainMinAndMaxValue,
  getMinAndMaxChartData,
  ifCanShowBarLabel,
  ifFormattedAsPercentage,
  widthToHeightRatioFallback,
} from "./includes/helper";
import { TableSource } from "component/table/includes/TableSource";

interface IMultiIndexChartState {
  tooltipDataKey: string | undefined;
}

interface IMultiIndexChart {
  store?: Store;
  insight?: boolean;
  setCurrentStep?: (string) => void;
  editMode?: boolean;
  hideLegend?: boolean;
  hideLayoutConfig?: boolean;
  chartLayoutOverride?: any;
  isAnimationDisabled?: boolean; // Disable charts animation when downloading insights
  overrideMetricLabelSize?: boolean;
  condensedEmbed?: boolean; // modulates render to suit condensedEmbed insights
  showAllInsightsSources?: boolean; // show source tables for all embedded insights in dashboard. When "true", "showSource" (set on insight level) will be ignored
  forceWidthToHeightRatioFallback?: boolean;
}

// @TODO - fix me, declare types when extending on default html element props
const ChartWithLegendContainer = styled.div<any>`
  height: calc(100% - 60px);
  ${(props) =>
    !props.condensedEmbed &&
    css`
      overflow: auto;
      scrollbar-gutter: stable;
    `}
`;

const AXIS_TICK_LABEL_MAX_WIDTH_PX = 150;

const RESPONSE_DEBOUNCE = 100; // Add debounce to ResponsiveContainer to remove "ResizeObserver loop completed with undelivered notifications." error in dev environment after upgrading recharts to "2.12.7"

const STROKE_WIDTH = 3; // Stroke width for the Line chart

// plain div for use with condensedEmbed layouts
const html_div = ({ children, ...rest }) => <div {...rest}>{children}</div>;

@observer
class ExploreMultiIndexChartResultComponent extends React.Component<IMultiIndexChart> {
  state: IMultiIndexChartState = {
    tooltipDataKey: undefined,
  };
  builder = this.props.store!.builder;
  editMode = this.props.editMode ?? true;

  componentDidUpdate() {
    const { chartXAxisArray, chart } = this.builder;
    if (chartXAxisArray.length > 1 && chart.type.includes("Horizontal")) {
      const chartType = chart.type;
      this.builder.updateChart("type", chartType.replace("Horizontal", "Vertical"));
    }
  }

  // Show "Year School Term" and "Year" when "When" variables are "Year School Term:::2020 T1" and "Year:::2020"
  CustomExtraAxisTick = (props, extraXAxisConfig, XAxisGroupNum, isBarChart?) => {
    const { x, y, payload, visibleTicksCount, width } = props;
    const { value, offset, index } = payload;
    const { text, startDivider, endDivider } = extraXAxisConfig;
    const dividerHeight = 20;
    // When there are multiple items in "chartXAxisArray"(Multiple X-Axises), we need to get the base index of the current index in "extraXAxisConfig" to show duplicated variables in the Extra X-Axis.
    // Code example: 2 items in "chartXAxisArray"; "baseLength = 2"; "visibleTicksCount = 10". Results: 0 >> 0; 4 >> 4; 5 >> 0; 9 >> 4
    const baseLength = visibleTicksCount / XAxisGroupNum;
    const baseIndex = index - baseLength * Math.floor(index / (visibleTicksCount / XAxisGroupNum));

    let textAnchor: "start" | "middle" | "end" | "inherit" = "middle";
    const currentTextIndex = text.indexOf(baseIndex);
    if (currentTextIndex >= 0) {
      const middleTextIndex = (startDivider[currentTextIndex] + endDivider[currentTextIndex]) / 2;
      if (baseIndex < middleTextIndex) {
        textAnchor = "start";
      } else if (baseIndex > middleTextIndex) {
        textAnchor = "end";
      }
    }

    const hasText = currentTextIndex >= 0;
    const hasStartDivider = startDivider.indexOf(baseIndex) >= 0;
    const hasEndDivider = endDivider.indexOf(baseIndex) >= 0;

    return (
      <>
        {hasText && (
          <Popup
            size="mini"
            position="bottom center"
            content={value.split(":::")[0]}
            inverted
            trigger={
              <Text
                x={x + (!isBarChart && hasStartDivider ? 10 : 0)}
                y={y - 5}
                textAnchor={textAnchor}
                verticalAnchor="start"
                fill={CHART_SECONDARY_COLOR}
                style={{ fontSize: 11, fontWeight: "bold", fontFamily: CHART_FONT_FAMILY }}
                width={isBarChart ? offset * 2 : width / visibleTicksCount}
                maxLines={2}
              >
                {value.split(":::")[0]}
              </Text>
            }
          />
        )}
        {(!isBarChart && hasText && hasStartDivider && hasEndDivider) ||
          (hasStartDivider && <path d={`M${x - (isBarChart ? offset : 0)},${y - 10}v${+dividerHeight}`} stroke={CHART_AXIS_COLOR} />)}
        {(!isBarChart && hasText && hasStartDivider && hasEndDivider) ||
          (hasEndDivider && <path d={`M${x + (isBarChart ? offset : 0)},${y - 10}v${+dividerHeight}`} stroke={CHART_AXIS_COLOR} />)}
      </>
    );
  };

  CustomAxisTickGroup = (props, XAxisGroupItemIndex) => {
    const { chartXAxisArray } = this.builder;
    const { labelFont } = this.builder.chart;
    const { x, y, payload, index } = props;
    const text = payload.value.toString().includes(":::") ? payload.value.split(":::")[1] : payload.value;

    // Get grouped label items length
    let length = 0;
    if (XAxisGroupItemIndex === 0) {
      length = chartXAxisArray[chartXAxisArray.length - XAxisGroupItemIndex - 1].values.length;
    } else {
      length =
        chartXAxisArray[chartXAxisArray.length - XAxisGroupItemIndex - 1].values.length *
        chartXAxisArray[chartXAxisArray.length - XAxisGroupItemIndex].values.length;
    }

    if (index === 0 || index % length === 0) {
      return (
        <>
          <g>
            <path d={`M${x},${y}v${+(labelFont || defaultLabelFont) * 2}`} stroke={CHART_AXIS_COLOR} />
          </g>
          <g transform={`translate(${x + 10},${y + (labelFont || defaultLabelFont) * 0.5})`}>
            <Text
              x={0}
              y={0}
              textAnchor="start"
              verticalAnchor="start"
              width={AXIS_TICK_LABEL_MAX_WIDTH_PX}
              fill={CHART_SECONDARY_COLOR}
              style={{ fontSize: `${labelFont || defaultLabelFont}px`, fontFamily: CHART_FONT_FAMILY }}
              maxLines={2}
            >
              {text}
            </Text>
          </g>
        </>
      );
    }

    return <div></div>;
  };

  render(): JSX.Element {
    const {
      isAnimationDisabled,
      chartLayoutOverride,
      hideLegend,
      hideLayoutConfig,
      condensedEmbed,
      forceWidthToHeightRatioFallback,
      showAllInsightsSources,
    } = this.props;
    const {
      newChartData: chartData,
      columns,
      chart,
      chartKey,
      chartLegend,
      chartXAxisArray,
      chartXAxisCombo,
      chartSeries,
      chartSeriesSingular,
      chartColors,
      chartLayout: insightChartLayout,
      chartTables,
      decimalPoints,
      downloadingChart,
    } = this.builder;
    const filteredChartTables = getFilteredChartTables(chartTables, columns, chartXAxisArray, chartSeries);
    const chartLayout = chartLayoutOverride || insightChartLayout;
    const { dataMin, dataMax } = getMinAndMaxChartData(chartData);
    const {
      type,
      angle,
      labelLength,
      labelFont,
      xLabel,
      yLabel,
      percentage,
      userDefinedMaxValue,
      userDefinedMinValue,
      userDefinedIncrements,
      metricBigLabelFontSize,
      metricSmallLabelFontSize,
      showBarLabel,
      barLabelFontSize,
      showRawValueInTooltip,
      condensedLegend,
      hideTableNames,
      showSource,
      axisTickInterval,
      metricLabelOverride,
      widthToHeightRatio,
    } = chart;
    const [minDomainValue, maxDomainValue] = getDomainMinAndMaxValue(userDefinedMinValue, userDefinedMaxValue, dataMin, dataMax, type);
    const computedTicks = getComputedTicks(userDefinedIncrements, userDefinedMinValue, userDefinedMaxValue, type);
    const XAxisLabelFont = labelFont || defaultLabelFont;
    const XAxisAngle = angle || defaultAngle;
    const formatChartData: ObjectAny[] =
      includes(type, "proportion") || includes(type, "percentage") ? getPercentData(chartKey, chartData) : chartData;
    const currentFormatter = formatter(percentage, type, chartData, filteredChartTables);
    const isPercentage = ifFormattedAsPercentage(percentage, type, filteredChartTables);
    const axisTick = CustomAxisTick(labelLength, angle, labelFont, AXIS_TICK_LABEL_MAX_WIDTH_PX);
    const allowDataOverflow = userDefinedMaxValue !== undefined && userDefinedMinValue !== undefined; // Set it to false if "userDefinedMaxValue" and "userDefinedMinValue" are not defined; otherwise, the chart will be clipped. See doc here: https://recharts.org/en-US/api/YAxis#allowDataOverflow

    let simplePieChartData;
    if (type.includes("Pie")) {
      simplePieChartData = getSimplePieChartData(chartLegend, chartXAxisArray, formatChartData, filteredChartTables);
    }

    let metricChartData;
    if (type.includes("Metric")) {
      metricChartData = getMetricChartData(formatChartData, filteredChartTables as ObjectAny[], columns);
    }

    const displayedChartSeries = type.includes("Pie")
      ? simplePieChartData.series
      : type.includes("Metric")
        ? undefined
        : chartSeriesSingular;

    const chartLayoutButtonDisabled = chartData.length === 0;

    const XAxisLabel0 = chartXAxisArray[chartXAxisArray.length - 1]; // Get "label0" object
    const XAxisGroups = [...chartXAxisArray].slice(0, -1).reverse(); // Remove "label0" from "chartXAxisArray"
    const XAxisGroupsComboNum = XAxisGroups.reduce((prev, curr) => prev * curr.values.length, 1);

    let ctxXAxisHeight: CanvasRenderingContext2D;
    const measureXAxisHeight = (text: string) => {
      if (!ctxXAxisHeight) {
        ctxXAxisHeight = document.createElement("canvas").getContext("2d")!;
        ctxXAxisHeight.font = `${XAxisLabelFont}px ${CHART_FONT_FAMILY}`;
      }

      const textLength =
        ctxXAxisHeight.measureText(text).width > AXIS_TICK_LABEL_MAX_WIDTH_PX
          ? AXIS_TICK_LABEL_MAX_WIDTH_PX
          : ctxXAxisHeight.measureText(text).width;

      if (angle === 0) {
        if (type.includes("Horizontal")) {
          return textLength;
        }
        return XAxisLabelFont * 2.4;
      }

      if (Math.abs(XAxisAngle) === 90) {
        if (type.includes("Horizontal")) {
          return XAxisLabelFont * 2.4;
        }
        return textLength;
      } else {
        const radian = ((2 * Math.PI) / 360) * Math.abs(XAxisAngle);
        if (type.includes("Horizontal")) {
          return Math.cos(radian) * textLength;
        }
        return Math.sin(radian) * textLength;
      }
    };

    let ctxYAxis: CanvasRenderingContext2D;
    const measureYAxisWidth = (text: string) => {
      if (!ctxYAxis) {
        ctxYAxis = document.createElement("canvas").getContext("2d")!;
        ctxYAxis.font = `12px ${CHART_FONT_FAMILY}`;
      }

      return ctxYAxis.measureText(text).width;
    };

    let maxXAxisHeight = 40;
    let maxYAxisWidth = 10;
    if (chartData.length > 0) {
      for (const singleChartData of chartData) {
        for (const dataItem of singleChartData) {
          let XAxisText = dataItem.label0.toString();
          if (XAxisText.includes(":::")) {
            XAxisText = XAxisText.split(":::")[1];
          }
          const XAxisHeight = measureXAxisHeight(XAxisText);
          if (XAxisHeight > maxXAxisHeight) {
            maxXAxisHeight = XAxisHeight;
          }

          for (const keyValue of chartKey.values) {
            const formattedValueY = currentFormatter(dataItem[keyValue]);
            const textWidthY = measureYAxisWidth(formattedValueY);
            if (textWidthY > maxYAxisWidth) {
              maxYAxisWidth = textWidthY;
            }
          }
        }
      }
    }
    // Add more space if there are "xLabel" or "yLabel"
    maxXAxisHeight =
      xLabel && XAxisGroups.length === 0 ? maxXAxisHeight + 40 : angle === 0 ? maxXAxisHeight : maxXAxisHeight + 2 * XAxisLabelFont;
    maxYAxisWidth = yLabel ? maxYAxisWidth + 40 : maxYAxisWidth + 15;

    let ctxXAxisWidth: CanvasRenderingContext2D;
    const measureXAxisMargin = (text: string, position: "start" | "end") => {
      if (!ctxXAxisWidth) {
        ctxXAxisWidth = document.createElement("canvas").getContext("2d")!;
        ctxXAxisWidth.font = `${XAxisLabelFont}px ${CHART_FONT_FAMILY}`;
      }

      const textLength =
        ctxXAxisWidth.measureText(text).width > AXIS_TICK_LABEL_MAX_WIDTH_PX
          ? AXIS_TICK_LABEL_MAX_WIDTH_PX
          : ctxXAxisWidth.measureText(text).width;

      if (angle === 0) {
        if (position === "start") {
          if (maxYAxisWidth > textLength / 2) {
            return 0;
          }
          return textLength / 2 - maxYAxisWidth;
        } else {
          return textLength / 2;
        }
      }

      if (XAxisAngle === -90) {
        if (position === "start") {
          return 0;
        } else {
          return XAxisLabelFont * 2.4;
        }
      } else if (XAxisAngle === 90) {
        if (position === "start") {
          return 0;
        } else {
          return XAxisLabelFont;
        }
      } else if (XAxisAngle < 0) {
        if (position === "start") {
          const radian = ((2 * Math.PI) / 360) * Math.abs(XAxisAngle);
          const XAxisMargin = Math.cos(radian) * textLength;
          if (XAxisMargin > maxYAxisWidth) {
            return XAxisMargin - maxYAxisWidth;
          } else {
            return 0;
          }
        } else {
          return XAxisLabelFont * 2.4;
        }
      } else {
        if (position === "start") {
          return XAxisLabelFont;
        } else {
          const radian = ((2 * Math.PI) / 360) * Math.abs(XAxisAngle);
          return Math.cos(radian) * textLength;
        }
      }
    };

    const XAxisMarginLeft = type.includes("Horizontal") ? maxXAxisHeight : measureXAxisMargin(XAxisLabel0?.values[0], "start");
    const XAxisMarginRight = measureXAxisMargin(XAxisLabel0?.values[XAxisLabel0.values.length - 1], "end");
    const chartTopMargin = showBarLabel && ifCanShowBarLabel(type) ? (barLabelFontSize || 11) + 5 : 0;

    const labelX: LabelProps = {
      value: xLabel,
      position: "bottom",
      style: { fontSize: "13px", fontWeight: "bold", fontFamily: CHART_FONT_FAMILY, fill: CHART_PRIMARY_COLOR },
      offset: -15,
    };
    const labelY: LabelProps = {
      value: yLabel,
      position: "left",
      angle: -90,
      textAnchor: "middle",
      style: { textAnchor: "middle", fontSize: "13px", fontWeight: "bold", fontFamily: CHART_FONT_FAMILY, fill: CHART_PRIMARY_COLOR },
      offset: -15,
    };

    const chartLayoutColumnArr: number[] = [];
    for (let i = 0; i < chartLayout; i++) {
      chartLayoutColumnArr.push(i);
    }
    const chartLayoutRowArr: number[] = [];
    for (let i = 0; i < Math.ceil((displayedChartSeries?.values?.length || 1) / chartLayout); i++) {
      chartLayoutRowArr.push(i);
    }

    // avoid using Grid when condensedEmbed set on insight with only one chart to display
    const GridElem = condensedEmbed && chartLayout === 1 ? html_div : Grid;
    const GridRow = condensedEmbed && chartLayout === 1 ? html_div : Grid.Row;
    const GridColumn = condensedEmbed && chartLayout === 1 ? html_div : Grid.Column;

    return (
      <>
        {/* Chart column layout toggles and Back and Next buttons */}
        {!hideLayoutConfig && (
          <div
            className="d-flex bg-white align-items-center justify-content-between w-100 border-bottom"
            style={{ height: 60, padding: 10, borderRadius: "4px 4px 0px 0px" }}
          >
            <div className="d-flex align-items-center">
              <p className="mb-0 mx-2 text-secondary fs-1000 fw-700">Charts layout:</p>
              <Button.Group basic size="mini">
                <Button disabled={chartLayoutButtonDisabled} active={chartLayout === 1} onClick={() => this.builder.setChartLayout(1)}>
                  <Icon className="m-0 fs-1125" name="stop" />
                </Button>
                <Button disabled={chartLayoutButtonDisabled} active={chartLayout === 2} onClick={() => this.builder.setChartLayout(2)}>
                  <Icon className="m-0 fs-1125" style={{ verticalAlign: "baseline" }} name="th large" />
                </Button>
                <Button disabled={chartLayoutButtonDisabled} active={chartLayout === 3} onClick={() => this.builder.setChartLayout(3)}>
                  <Icon className="m-0 fs-1125" name="th" />
                </Button>
              </Button.Group>
            </div>

            {this.editMode && (
              <div>
                <Button
                  className="mr-2"
                  onClick={() => {
                    this.props.setCurrentStep && this.props.setCurrentStep("table");
                    this.builder.ui.setLeftTabFolded(true);
                    getMixpanel(this.builder.parent).track("Insight Builder > Table", { "Button Click": "Back" });
                  }}
                  size="tiny"
                >
                  <Icon name="arrow left" /> Back
                </Button>
                <Button
                  id="next-btn-chart-step"
                  className="bg-primary text-white bg-hover-red"
                  onClick={() => {
                    this.props.setCurrentStep && this.props.setCurrentStep("preview");
                    getMixpanel(this.builder.parent).track("Insight Builder > Preview", { "Button Click": "Next" });
                  }}
                  size="tiny"
                >
                  Next
                  <Icon name="arrow right" />
                </Button>
              </div>
            )}
          </div>
        )}

        {formatChartData.length === 0 ? (
          <p className="p-4 fs-1125 fw-600 text-medium">No charts available</p>
        ) : type.includes("Pie") &&
          (chartTables?.length > 1 || chartLegend?.length > 1 || chartXAxisArray?.length > 1 || chartSeriesSingular) ? (
          <>
            <p className="pt-4 px-4 pb-2 mb-0 fs-1125 fw-600 text-medium">Pie chart is not available. Pie chart can be displayed when:</p>
            <ul className="mt-0 fs-1000 text-medium">
              <li>only one chart table enabled;</li>
              <li>there are only one or two multi-category variables selected in the table.</li>
            </ul>
          </>
        ) : type.includes("Metric") && metricChartData[0].length > 5 ? (
          <p className="pt-4 px-4 pb-2 mb-0 fs-1125 fw-600 text-medium">
            Metric chart is not available when there are more than 5 data points.
          </p>
        ) : (
          <ChartWithLegendContainer id="chartWithLegend" condensedEmbed={condensedEmbed}>
            <div
              id="chartContainer"
              className={condensedEmbed ? "" : "px-4 pt-4 pb-3"}
              style={{ width: condensedEmbed ? "100%" : "calc(100% - 16px)" }}
            >
              {/* Need to set the left margin to 0 because the Semantic Grid has -14px margin by default, which causes the axis label to be cut off */}
              <GridElem columns={chartLayout} style={condensedEmbed ? { marginLeft: 0 } : {}}>
                {chartLayoutRowArr.map((rowIndex, rIdx) => (
                  <>
                    {displayedChartSeries && (
                      <GridRow className={chartLayout !== 1 && condensedEmbed ? `pb-2 ${rIdx !== 0 ? "pt-0" : ""}` : ""}>
                        {chartLayoutColumnArr.map((columnIndex) => {
                          const chartIndex = rowIndex * chartLayoutColumnArr.length + columnIndex;
                          if (chartIndex + 1 > displayedChartSeries.values.length) {
                            return null;
                          }
                          return (
                            <GridColumn key={chartIndex} className={chartLayout !== 1 && condensedEmbed ? "pl-0" : ""}>
                              <h2
                                key={`${chartIndex} title`}
                                style={{ color: CHART_PRIMARY_COLOR, marginLeft: condensedEmbed ? 0 : 20 }}
                                className={`fs-${chartLayout === 1 ? 1375 : chartLayout === 2 ? 1250 : 1125}`}
                              >
                                {getCategory(displayedChartSeries.values[chartIndex])}
                              </h2>
                            </GridColumn>
                          );
                        })}
                      </GridRow>
                    )}
                    <GridRow className={chartLayout !== 1 && condensedEmbed ? "pb-2 pt-0" : ""}>
                      {chartLayoutColumnArr.map((columnIndex) => {
                        const chartIndex = rowIndex * chartLayoutColumnArr.length + columnIndex;
                        const data = type.includes("Pie")
                          ? simplePieChartData["data"][chartIndex]
                          : type.includes("Metric")
                            ? metricChartData[chartIndex]
                            : formatChartData[chartIndex];
                        if (chartIndex + 1 > displayedChartSeries?.values.length || !data) {
                          return null;
                        }
                        const hasFewerBars = data.length <= 4;
                        const aspect =
                          (!forceWidthToHeightRatioFallback && widthToHeightRatio) || widthToHeightRatioFallback(type, chartLayout);
                        return (
                          <GridColumn
                            key={chartIndex}
                            style={{ maxWidth: "100%" }}
                            className={chartLayout !== 1 && condensedEmbed ? "p-0" : ""}
                          >
                            {type.includes("bar") && (
                              <ResponsiveContainer width="100%" aspect={aspect} className="mb-1" debounce={RESPONSE_DEBOUNCE}>
                                <BarChart
                                  key={chartIndex}
                                  data={data}
                                  // 'layout': The layout of bars in the chart. Default: "horizontal". Opposite of chart type - 'chart.type'
                                  layout={type.includes("Horizontal") ? "vertical" : "horizontal"}
                                  margin={{
                                    // left: XAxisMarginLeft,
                                    right: XAxisMarginRight,
                                    top: chartTopMargin,
                                  }}
                                  maxBarSize={hasFewerBars ? 100 / data.length + 30 : 60 / chartLayout}
                                  accessibilityLayer
                                >
                                  <CartesianGrid
                                    strokeDasharray="8 8"
                                    vertical={type.includes("Horizontal") ? true : false}
                                    horizontal={type.includes("Horizontal") ? false : true}
                                    stroke={CHART_GRID_COLOR}
                                  />
                                  {type.includes("Horizontal") ? (
                                    <XAxis
                                      type="number"
                                      tick={DEFAULT_LABEL_STYLING}
                                      tickFormatter={currentFormatter}
                                      label={labelX}
                                      height={maxYAxisWidth}
                                      tickLine={false}
                                      axisLine={false}
                                      domain={[minDomainValue, maxDomainValue]}
                                      ticks={computedTicks}
                                      minTickGap={1}
                                      allowDataOverflow={allowDataOverflow}
                                    />
                                  ) : (
                                    <XAxis
                                      dataKey="label0"
                                      tick={axisTick("x")}
                                      textAnchor="end"
                                      interval={axisTickInterval || 0}
                                      label={XAxisGroups.length === 0 ? labelX : undefined}
                                      height={maxXAxisHeight}
                                      tickLine={false}
                                      axisLine={{ stroke: CHART_AXIS_COLOR }}
                                    />
                                  )}
                                  {/* Extra XAxis */}
                                  {showExtraXAxis(XAxisLabel0) && chartLayout === 1 && !type.includes("Horizontal") && (
                                    <XAxis
                                      dataKey="label0"
                                      axisLine={false}
                                      tickLine={false}
                                      tick={(props) =>
                                        this.CustomExtraAxisTick(props, getExtraXAxisConfig(XAxisLabel0.values), XAxisGroupsComboNum, true)
                                      }
                                      interval={0}
                                      xAxisId="ExtraXAxis"
                                    />
                                  )}
                                  {/* Multiple grouped XAxis */}
                                  {XAxisGroups.length > 0 &&
                                    XAxisGroups.map((_, index) => (
                                      <XAxis
                                        dataKey={`label${index + 1}`}
                                        axisLine={false}
                                        tickLine={false}
                                        interval={0}
                                        tick={(props) => this.CustomAxisTickGroup(props, index)}
                                        xAxisId={`label${index + 1}`}
                                        label={index === XAxisGroups.length - 1 ? labelX : undefined}
                                        height={index === XAxisGroups.length - 1 ? XAxisLabelFont * 2 + 60 : XAxisLabelFont * 2 + 15}
                                      />
                                    ))}
                                  {type.includes("Horizontal") ? (
                                    <YAxis
                                      dataKey="label0"
                                      type="category"
                                      tick={axisTick("y")}
                                      textAnchor="end"
                                      interval={axisTickInterval || 0}
                                      label={labelY}
                                      width={maxXAxisHeight}
                                      tickLine={false}
                                      axisLine={{ stroke: CHART_AXIS_COLOR }}
                                    />
                                  ) : (
                                    <YAxis
                                      type="number"
                                      tick={DEFAULT_LABEL_STYLING}
                                      tickFormatter={currentFormatter}
                                      label={labelY}
                                      width={maxYAxisWidth}
                                      tickLine={false}
                                      axisLine={false}
                                      domain={[minDomainValue, maxDomainValue]}
                                      ticks={computedTicks}
                                      minTickGap={1}
                                      allowDataOverflow={allowDataOverflow}
                                    />
                                  )}
                                  <Tooltip
                                    offset={0}
                                    isAnimationActive={false}
                                    cursor={{ fill: CHART_TOOLTIP_FILL }}
                                    content={CustomToolTip(
                                      this.state.tooltipDataKey,
                                      filteredChartTables,
                                      percentage,
                                      decimalPoints,
                                      !!showRawValueInTooltip,
                                      type,
                                    )}
                                  />

                                  {chartKey.values.map((dataKey, idx) => (
                                    <Bar
                                      key={`${dataKey} ${chartIndex} ${idx}`}
                                      stackId={type.includes("clustered") ? undefined : "stacked"} // Use the same "stackId" for stacked bar charts
                                      dataKey={dataKey}
                                      fill={chartColors[idx]}
                                      radius={
                                        idx === chartKey.values.length - 1
                                          ? type.includes("Horizontal")
                                            ? [0, 4, 4, 0]
                                            : [4, 4, 0, 0]
                                          : type.includes("clustered")
                                            ? type.includes("Horizontal")
                                              ? [0, 4, 4, 0]
                                              : [4, 4, 0, 0]
                                            : [0, 0, 0, 0]
                                      }
                                      onMouseEnter={(props) => {
                                        const { tooltipPayload } = props;
                                        this.setState({ ...this.state, tooltipDataKey: tooltipPayload[0].dataKey });
                                      }}
                                      onMouseLeave={() => {
                                        this.setState({ ...this.state, tooltipDataKey: undefined });
                                      }}
                                      isAnimationActive={!isAnimationDisabled}
                                      label={
                                        showBarLabel && ifCanShowBarLabel(type)
                                          ? type.includes("stacked") && idx !== chartKey.values.length - 1
                                            ? false // Only show the bar labels for the last bar when bar type is "stacked"
                                            : CustomBarLabel(isPercentage, decimalPoints, barLabelFontSize, type.includes("Vertical"))
                                          : false
                                      }
                                    />
                                  ))}
                                </BarChart>
                              </ResponsiveContainer>
                            )}
                            {type === "Line" && (
                              <ResponsiveContainer width="100%" aspect={aspect} className="mb-1" debounce={RESPONSE_DEBOUNCE}>
                                <LineChart
                                  key={chartIndex}
                                  data={data}
                                  margin={{
                                    left: XAxisMarginLeft,
                                    right: XAxisMarginRight,
                                    top: STROKE_WIDTH, // Set top margin to STROKE_WIDTH to avoid the line being cut off
                                  }}
                                  accessibilityLayer
                                >
                                  <CartesianGrid strokeDasharray="8 8" vertical={false} stroke={CHART_GRID_COLOR} />
                                  <XAxis
                                    dataKey="label0"
                                    textAnchor="end"
                                    interval={axisTickInterval || 0}
                                    label={XAxisGroups.length === 0 ? labelX : undefined}
                                    tick={axisTick("x")}
                                    height={maxXAxisHeight}
                                    tickLine={false}
                                    axisLine={{ stroke: CHART_AXIS_COLOR }}
                                  />
                                  {/* Extra XAxis */}
                                  {showExtraXAxis(XAxisLabel0) && chartLayout === 1 && (
                                    <XAxis
                                      dataKey="label0"
                                      axisLine={false}
                                      tickLine={false}
                                      tick={(props) =>
                                        this.CustomExtraAxisTick(props, getExtraXAxisConfig(XAxisLabel0.values), XAxisGroupsComboNum)
                                      }
                                      interval={0}
                                      xAxisId="ExtraXAxis"
                                    />
                                  )}
                                  {/* Multiple grouped XAxis */}
                                  {XAxisGroups.length > 0 &&
                                    XAxisGroups.map((_, index) => (
                                      <XAxis
                                        dataKey={`label${index + 1}`}
                                        axisLine={false}
                                        tickLine={false}
                                        interval={0}
                                        tick={(props) => this.CustomAxisTickGroup(props, index)}
                                        xAxisId={`label${index + 1}`}
                                        label={index === XAxisGroups.length - 1 ? labelX : undefined}
                                        height={index === XAxisGroups.length - 1 ? XAxisLabelFont * 2 + 60 : XAxisLabelFont * 2 + 15}
                                      />
                                    ))}
                                  <YAxis
                                    type="number"
                                    tick={DEFAULT_LABEL_STYLING}
                                    tickFormatter={currentFormatter}
                                    label={labelY}
                                    width={maxYAxisWidth}
                                    tickLine={false}
                                    axisLine={false}
                                    domain={[minDomainValue, maxDomainValue]}
                                    ticks={computedTicks}
                                    minTickGap={1}
                                    allowDataOverflow={allowDataOverflow}
                                  />
                                  <Tooltip
                                    offset={0}
                                    isAnimationActive={false}
                                    cursor={{ fill: "transparent", stroke: CHART_AXIS_COLOR, strokeWidth: 2 }}
                                    content={CustomToolTip(
                                      this.state.tooltipDataKey,
                                      filteredChartTables,
                                      percentage,
                                      decimalPoints,
                                      !!showRawValueInTooltip,
                                      type,
                                    )}
                                  />
                                  {chartKey.values.map((dataKey, idx) => (
                                    <Line
                                      key={`${dataKey} ${chartIndex} ${idx}`}
                                      type="monotone"
                                      dataKey={dataKey}
                                      strokeWidth={STROKE_WIDTH}
                                      stroke={chartColors[idx]}
                                      dot={false}
                                      activeDot={{
                                        r: 5,
                                        strokeWidth: 0,
                                        onMouseEnter: () => {
                                          this.setState({ ...this.state, tooltipDataKey: dataKey });
                                        },
                                        onMouseLeave: () => {
                                          this.setState({ ...this.state, tooltipDataKey: undefined });
                                        },
                                      }}
                                      onMouseEnter={() => {
                                        this.setState({ ...this.state, tooltipDataKey: dataKey });
                                      }}
                                      onMouseLeave={() => {
                                        this.setState({ ...this.state, tooltipDataKey: undefined });
                                      }}
                                      isAnimationActive={!isAnimationDisabled}
                                    />
                                  ))}
                                </LineChart>
                              </ResponsiveContainer>
                            )}
                            {type.includes("Line ") && (
                              <ResponsiveContainer width="100%" aspect={aspect} className="mb-1" debounce={RESPONSE_DEBOUNCE}>
                                <AreaChart
                                  key={chartIndex}
                                  // stackOffset={type.includes("percentage") ? "expand" : undefined}
                                  data={data}
                                  margin={{
                                    left: XAxisMarginLeft,
                                    right: XAxisMarginRight,
                                  }}
                                  accessibilityLayer
                                >
                                  <CartesianGrid strokeDasharray="8 8" vertical={false} stroke={CHART_GRID_COLOR} />
                                  <XAxis
                                    dataKey="label0"
                                    textAnchor="end"
                                    interval={axisTickInterval || 0}
                                    label={XAxisGroups.length === 0 ? labelX : undefined}
                                    tick={axisTick("x")}
                                    height={maxXAxisHeight}
                                    tickLine={false}
                                    axisLine={{ stroke: CHART_AXIS_COLOR }}
                                  />
                                  {/* Extra XAxis */}
                                  {showExtraXAxis(XAxisLabel0) && chartLayout === 1 && (
                                    <XAxis
                                      dataKey="label0"
                                      axisLine={false}
                                      tickLine={false}
                                      tick={(props) =>
                                        this.CustomExtraAxisTick(props, getExtraXAxisConfig(XAxisLabel0.values), XAxisGroupsComboNum)
                                      }
                                      interval={0}
                                      xAxisId="ExtraXAxis"
                                    />
                                  )}
                                  {/* Multiple grouped XAxis */}
                                  {XAxisGroups.length > 0 &&
                                    XAxisGroups.map((_, index) => (
                                      <XAxis
                                        dataKey={`label${index + 1}`}
                                        axisLine={false}
                                        tickLine={false}
                                        interval={0}
                                        tick={(props) => this.CustomAxisTickGroup(props, index)}
                                        xAxisId={`label${index + 1}`}
                                        label={index === XAxisGroups.length - 1 ? labelX : undefined}
                                        height={index === XAxisGroups.length - 1 ? XAxisLabelFont * 2 + 60 : XAxisLabelFont * 2 + 15}
                                      />
                                    ))}
                                  <YAxis
                                    type="number"
                                    tick={DEFAULT_LABEL_STYLING}
                                    tickFormatter={currentFormatter}
                                    label={labelY}
                                    width={maxYAxisWidth}
                                    tickLine={false}
                                    axisLine={false}
                                    domain={[minDomainValue, maxDomainValue]}
                                    ticks={computedTicks}
                                    minTickGap={1}
                                    allowDataOverflow={allowDataOverflow}
                                  />
                                  <Tooltip
                                    offset={0}
                                    isAnimationActive={false}
                                    cursor={{ fill: "transparent", stroke: CHART_AXIS_COLOR, strokeWidth: 2 }}
                                    content={CustomToolTip(
                                      this.state.tooltipDataKey,
                                      filteredChartTables,
                                      percentage,
                                      decimalPoints,
                                      !!showRawValueInTooltip,
                                      type,
                                    )}
                                  />
                                  <defs>
                                    {chartKey.values.map((dataKey, idx) => (
                                      <linearGradient id={idx} key={`${dataKey}${idx}`} x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="0%" stopColor={chartColors[idx]} stopOpacity={0.9} />
                                        <stop offset="100%" stopColor={chartColors[idx]} stopOpacity={0.8} />
                                      </linearGradient>
                                    ))}
                                  </defs>
                                  {chartKey.values.map((dataKey, idx) => (
                                    <Area
                                      key={`${dataKey} ${chartIndex} ${idx}`}
                                      type="monotone"
                                      dataKey={dataKey}
                                      stackId="stacked"
                                      strokeWidth={1}
                                      stroke={chartColors[idx]}
                                      fillOpacity={1}
                                      fill={`url('#${idx}')`}
                                      activeDot={{
                                        r: 5,
                                        strokeWidth: 0,
                                        onMouseEnter: () => {
                                          this.setState({ ...this.state, tooltipDataKey: dataKey });
                                        },
                                        onMouseLeave: () => {
                                          this.setState({ ...this.state, tooltipDataKey: undefined });
                                        },
                                      }}
                                      onMouseEnter={() => {
                                        this.setState({ ...this.state, tooltipDataKey: dataKey });
                                      }}
                                      onMouseLeave={() => {
                                        this.setState({ ...this.state, tooltipDataKey: undefined });
                                      }}
                                      isAnimationActive={!isAnimationDisabled}
                                    />
                                  ))}
                                </AreaChart>
                              </ResponsiveContainer>
                            )}
                            {type.includes("Pie") && (
                              <ResponsiveContainer width="100%" aspect={aspect} className="mb-1" debounce={RESPONSE_DEBOUNCE}>
                                <SimplePieChart
                                  data={data}
                                  chartColors={chartColors}
                                  strokeWidth={2}
                                  size={chartLayout === 1 ? "huge" : chartLayout === 2 ? "big" : "small"}
                                  CustomToolTip={CustomPieToolTip(filteredChartTables, isPercentage, decimalPoints)}
                                />
                              </ResponsiveContainer>
                            )}
                            {type.includes("Donut") && (
                              <ResponsiveContainer width="100%" aspect={aspect} className="mb-1" debounce={RESPONSE_DEBOUNCE}>
                                <PieChart width={400} height={400}>
                                  {toDonutChartData(data, chartKey.values, chartXAxisCombo).map((singleData, index) => {
                                    const size = 65 / data.length;
                                    return (
                                      <Pie
                                        data={singleData}
                                        labelLine={false}
                                        dataKey="value"
                                        nameKey="name"
                                        innerRadius={`${size * index + 35}%`}
                                        outerRadius={`${size * index + size + 35}%`}
                                        startAngle={90}
                                        endAngle={450}
                                        isAnimationActive={!isAnimationDisabled}
                                      >
                                        {chartKey.values.map((_value, idx) => (
                                          <Cell
                                            key={`cell-${idx}`}
                                            fill={chartColors[idx]}
                                            strokeWidth={4}
                                            onMouseEnter={() => {
                                              this.setState({ ...this.state, tooltipDataKey: "value" });
                                            }}
                                            onMouseLeave={() => {
                                              this.setState({ ...this.state, tooltipDataKey: undefined });
                                            }}
                                          />
                                        ))}
                                      </Pie>
                                    );
                                  })}
                                  <Tooltip
                                    offset={0}
                                    isAnimationActive={false}
                                    content={CustomToolTip(
                                      this.state.tooltipDataKey,
                                      filteredChartTables,
                                      percentage,
                                      decimalPoints,
                                      false,
                                      "donut",
                                    )}
                                  />
                                </PieChart>
                              </ResponsiveContainer>
                            )}
                            {type.includes("Metric") && (
                              <ResponsiveContainer width="100%" aspect={aspect} className="mb-1" debounce={RESPONSE_DEBOUNCE}>
                                <MetricChart
                                  data={data}
                                  chartColors={chartColors}
                                  width={400}
                                  height={400}
                                  filteredChartTables={filteredChartTables}
                                  columns={columns}
                                  decimalPoints={decimalPoints}
                                  bigLabelFontSize={metricBigLabelFontSize || undefined}
                                  smallLabelFontSize={metricSmallLabelFontSize || undefined}
                                  labelOverride={metricLabelOverride}
                                  // bigLabelFontSize={overrideMetricLabelSize ? metricBigLabelFontSize : undefined}
                                  // smallLabelFontSize={overrideMetricLabelSize ? metricSmallLabelFontSize : undefined}
                                />
                              </ResponsiveContainer>
                            )}
                          </GridColumn>
                        );
                      })}
                    </GridRow>
                  </>
                ))}
              </GridElem>
              {(!hideLegend || downloadingChart) && (
                <Legend
                  align="left"
                  wrapperStyle={{ position: "relative", zIndex: 2, ...(condensedEmbed ? {} : { marginLeft: 20, marginTop: 20 }) }}
                  content={
                    type.includes("Metric") ? (
                      <MetricChartLegend
                        edit={this.editMode}
                        showGridLayout={true}
                        condensedLegend={condensedLegend || condensedEmbed}
                        hideTableNames={hideTableNames}
                      />
                    ) : (
                      <GeneralChartLegend
                        edit={this.editMode}
                        showGridLayout={true}
                        condensedLegend={condensedLegend || condensedEmbed}
                        hideTableNames={hideTableNames}
                      />
                    )
                  }
                />
              )}
              {(showAllInsightsSources || showSource || downloadingChart) && <TableSource />}
            </div>
          </ChartWithLegendContainer>
        )}
      </>
    );
  }
}

export const ExploreMultiIndexChartResult = inject("store")(observer(ExploreMultiIndexChartResultComponent));
