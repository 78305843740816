/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Button, Confirm, Icon, Popup, Table } from "semantic-ui-react";
import { useState } from "react";
import styled from "styled-components";

import Store from "common/store/insightbuilder";
import { Builder, IFilter } from "common/store/builder";
import {
  formatValue,
  getDimType,
  getCombinedDims,
  comparableDimensions,
  robotCalcTableTransform,
  DEFAULT_TABLE_NAME_REGEX,
} from "common/helpers/data";
import { TableActionsDropdown } from "./includes/TableActionsDropdown";
import { Heading as StyledHeading } from "common/styledComponents/elements";
import { buildArrayCombos } from "common/helpers/dimensions";
import { calcToFormula, getMissingFallback, tableContainsComparable } from "common/helpers/explore";
import { getCNumber } from "common/helpers/explore";
import { TableFormula, TableSource } from "./includes/TableSource";
import { TableSortWidget } from "./includes/TableSortWidget";
import { InlineEdit } from "component/InlineEdit/InlineEdit";
import { CalcDropdown } from "./includes/CalcDropdown";

// @TODO - fix me, declare types when extending on default html element props
const TableContainer = styled.div.attrs<{ maxWidth?: any }>((props) => ({
  maxWidth: props.maxWidth || "initial",
}))`
  max-width: ${(props) => props.maxWidth};
  @media (max-width: 1140px) {
    max-width: 100%;
  }
`;

const TableCellSelectTable = styled(Table.Cell)`
  background: #fde7c8;
  &:hover {
    background: #ffd59a;
  }
`;

type HierarchicalTableProps = RouteComponentProps<any> & {
  store?: Store;
  builderStore?: Builder;
  editMode?: boolean;
  noMaxWidth?: boolean; // @TODO - remove this when component is refactored
};

interface IDeleteTable {
  id: undefined | number;
  name: string;
}

export const MultiIndexTable = withRouter(
  inject((stores: any) => ({ store: stores.store.insightbuilder, builderStore: stores.store.builder }))(
    observer((props: HierarchicalTableProps) => {
      const { noMaxWidth } = props;
      const builderStore = props.builderStore!;
      const { calcMissingFallback, calcMissingFallbackValue, RATAutoQuery } = builderStore;
      const { showTableStructureConfirmation } = builderStore.ui;
      const editMode = props.editMode ?? true;
      const calcIsOpen = builderStore.calc.open;
      const robotIsOpen = builderStore.robot.open;
      // Only show TableSortWidget in rows when only one variable in columns
      const isSingleColumnIndexTable = builderStore.columns.length === 1;
      // Only show TableSortWidget in columns when all tables are single row index tables
      const ifAllTablesAreSingleRowIndex = builderStore.tables
        .filter((table) => table?.type !== "calc")
        .every((table) => table.rows.length === 1);
      const showTableNameRows =
        builderStore.tables.length > 1 ||
        (builderStore.tables.length === 1 && !builderStore.tables[0].name.match(DEFAULT_TABLE_NAME_REGEX)) ||
        editMode;

      const [sortIsOpen, setSortIsOpen] = useState(false);

      const [deleteTableConfirmationOpen, setDeleteTableConfirmationOpen] = useState(false);
      const [deleteTable, setDeleteTable] = useState<IDeleteTable>({
        id: undefined,
        name: "",
      });

      const combinedDimensions: IFilter[] = getCombinedDims(builderStore.columns);
      const newColumnIndex = buildArrayCombos(combinedDimensions.map((col) => col.values));

      const tableRowIndexes = builderStore.tables.map((table) =>
        buildArrayCombos(
          table?.rows?.length > 0 && Object.keys(table.rows[0]).includes("dimension")
            ? table.rows.map((row) => row.values)
            : Object.entries(table.rows || {}).map(([_, v]) => v),
        ),
      );
      const maxRowVariables = tableRowIndexes.reduce(
        (max, tableRowsCategories) => (tableRowsCategories[0]?.length > max ? tableRowsCategories[0].length : max),
        0,
      );

      const countConsecutiveElements = (array) => {
        let counter = 1;
        for (let i = 0; i < array.length; i++) {
          if (array[i] === array[i + 1]) {
            counter++;
          } else {
            break;
          }
        }
        return counter;
      };

      const deleteTableHandler = (tableID, tableName) => {
        setDeleteTableConfirmationOpen(true);
        const deleteTable = { id: tableID, name: tableName };
        setDeleteTable(deleteTable);
      };

      // Workaround to fix "Update insight" crash bug when "dimensions" is not available
      let isPivotDisabled = false;
      if (Object.keys(builderStore.dimensions).length > 0) {
        isPivotDisabled =
          builderStore.tables.length > 1 ||
          builderStore.columns.length === 0 ||
          !builderStore.tables[0]?.rows ||
          builderStore.tables[0].rows.length === 0 ||
          tableContainsComparable(builderStore);
      }

      // Close Calc and Robot
      React.useEffect(() => {
        if (robotIsOpen) {
          builderStore.toggleRobotTool();
        }
        if (calcIsOpen) {
          builderStore.setCalcIsOpen(false);
        }
      }, []);

      return (
        <>
          <Confirm
            open={deleteTableConfirmationOpen}
            header="Delete table"
            content={`Are you sure you want to delete table: ${deleteTable.name}`}
            confirmButton="Yes, delete"
            onCancel={() => setDeleteTableConfirmationOpen(false)}
            onConfirm={() => {
              deleteTable.id && builderStore!.deleteTable(deleteTable.id);
              setDeleteTableConfirmationOpen(false);
            }}
          />
          <TableContainer
            className="w-100 h-100 px-4 pt-4 d-flex flex-column"
            maxWidth={noMaxWidth ? undefined : !builderStore.activeTableId ? "calc(100vw - 183px)" : "calc(100vw - 500px)"}
          >
            {editMode && (
              <div className="d-flex align-items-center justify-content-between">
                <StyledHeading>Results table</StyledHeading>

                {/* Overall table buttons */}
                <div>
                  {!!RATAutoQuery ? (
                    <Button
                      onClick={() => {
                        const ok = confirm(
                          "Your automatically applied analysis tool query will be removed and you will be able to make edits again.",
                        );
                        if (ok) {
                          builderStore.setRATAutoQuery(undefined);
                        }
                      }}
                      size="small"
                    >
                      Unlock editing
                      <Icon name="unlock" className="ml-2 mr-0" />
                    </Button>
                  ) : (
                    <>
                      <Button disabled={isPivotDisabled || robotIsOpen} onClick={() => builderStore.pivotTable()} size="small">
                        Pivot
                        <Icon name="retweet" className="ml-2 mr-0" />
                      </Button>
                      <Button
                        disabled={builderStore.tables.length > 1 || robotIsOpen}
                        onClick={() => builderStore.clearRows()}
                        size="small"
                      >
                        Clear rows
                        <Icon name="bars" className="ml-2 mr-0" />
                      </Button>
                      <Button onClick={() => setSortIsOpen(!sortIsOpen)} size="small" disabled={robotIsOpen}>
                        Sort
                        <Icon name="sort numeric down" className="ml-2 mr-0" />
                      </Button>
                      <CalcDropdown />
                      <Button
                        disabled={
                          !(
                            !builderStore.activeTableId &&
                            (builderStore.tables.length === 0 ||
                              (builderStore.tables[0]?.columns?.length > 0 && builderStore.tables[0]?.results?.length > 0)) &&
                            !robotIsOpen
                          )
                        }
                        onClick={() => {
                          if (builderStore.tables.length === 1) {
                            builderStore.setActiveTableId(builderStore.tables[0].id);
                            // Show table structure confirmation just as user tries to create their second table
                            builderStore.ui.setShowTableStructureConfirmation(true);
                          } else {
                            builderStore.addTable();
                          }
                        }}
                        size="small"
                      >
                        New query
                        <Icon name="plus" className="ml-2 mr-0" />
                      </Button>
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="h-100 overflow-auto px-1" style={{ paddingBottom: 75 }}>
              <Table celled structured unstackable className="fs-0875 my-4">
                <Table.Header>
                  {/* TABLE COLUMNS */}

                  {builderStore.tables.length > 0 &&
                    combinedDimensions.map((c, i) => (
                      <>
                        <Table.Row>
                          {(calcIsOpen || robotIsOpen) && i === 0 && (
                            <Table.HeaderCell rowSpan={newColumnIndex.length + 1}>#</Table.HeaderCell>
                          )}
                          <Table.HeaderCell colSpan={maxRowVariables} rowSpan={2} className="fw-700 bg-muted text-white border-light">
                            {c.dimension.startsWith("!") ? c.dimension.substring(1) : c.dimension}
                          </Table.HeaderCell>

                          {c.dimension.startsWith("!") &&
                            newColumnIndex.map(
                              (colCombo, j) =>
                                (j === 0 || newColumnIndex[j - 1][i].split(":::")[0] !== newColumnIndex[j][i].split(":::")[0]) && (
                                  <Table.HeaderCell
                                    colSpan={countConsecutiveElements(newColumnIndex.slice(j).map((x) => x[i].split(":::")[0]))}
                                    className="fw-700 bg-muted text-white border-light text-center py-1"
                                  >
                                    {colCombo[i].split(":::")[0]}
                                  </Table.HeaderCell>
                                ),
                            )}
                        </Table.Row>

                        <Table.Row>
                          {newColumnIndex.map(
                            (colCombo, j) =>
                              (j === 0 || newColumnIndex[j - 1][i] !== newColumnIndex[j][i]) &&
                              (i === 0 ? (
                                <Table.HeaderCell
                                  colSpan={countConsecutiveElements(newColumnIndex.slice(j).map((x) => x[i]))}
                                  className="fw-400 text-center bg-muted text-white border-light position-relative"
                                  style={{ padding: sortIsOpen ? 20 : "0.9em 0.8em" }}
                                >
                                  {c.dimension.startsWith("!") ? colCombo[i].split(":::")[1] : colCombo[i]}
                                  {sortIsOpen && i === combinedDimensions.length - 1 && ifAllTablesAreSingleRowIndex && (
                                    <TableSortWidget
                                      ascendingClickHandler={() => builderStore.sortByValues("Column", true, undefined, colCombo)}
                                      descendingClickHandler={() => builderStore.sortByValues("Column", false, undefined, colCombo)}
                                    />
                                  )}
                                </Table.HeaderCell>
                              ) : (
                                <Table.HeaderCell
                                  colSpan={countConsecutiveElements(newColumnIndex.slice(j).map((x) => x[i]))}
                                  className="fw-400 text-center bg-muted text-white border-light position-relative"
                                  style={{ border: "1px solid", padding: sortIsOpen ? 20 : "0.9em 0.8em" }}
                                >
                                  {c.dimension.startsWith("!") ? colCombo[i].split(":::")[1] : colCombo[i]}
                                  {sortIsOpen && i === combinedDimensions.length - 1 && ifAllTablesAreSingleRowIndex && (
                                    <TableSortWidget
                                      ascendingClickHandler={() => builderStore.sortByValues("Column", true, undefined, colCombo)}
                                      descendingClickHandler={() => builderStore.sortByValues("Column", false, undefined, colCombo)}
                                    />
                                  )}
                                </Table.HeaderCell>
                              )),
                          )}
                        </Table.Row>
                      </>
                    ))}
                </Table.Header>

                {builderStore.tables.map((table, tableIdx) => {
                  const rowIndex = tableRowIndexes[tableIdx];
                  const lastCalculableRowIdx =
                    builderStore.tables
                      .slice(0, tableIdx)
                      .filter((table) => table.type === "result")
                      .reduce((acc, next) => acc + next.rows.reduce((acc, next) => acc * next.values.length, 1), 0) +
                    builderStore.tables.slice(0, tableIdx).filter((table) => table.type === "calc").length;

                  if (table.type === "result") {
                    return (
                      <>
                        {/* TABLE NAME & ACTIONS */}
                        {showTableNameRows && (
                          <Table.Row
                            className={
                              builderStore.activeTableId
                                ? builderStore.activeTableId === table.id
                                  ? "border border-5 border-info"
                                  : "opacity"
                                : ""
                            }
                          >
                            <Table.Cell
                              colSpan={
                                newColumnIndex.length + (maxRowVariables > 0 ? maxRowVariables : 1) + (calcIsOpen || robotIsOpen ? 1 : 0)
                              }
                              className="bg-medium"
                            >
                              <div className="text-white fw-700 d-flex align-items-center">
                                <InlineEdit
                                  readOnly={!editMode}
                                  value={table.name}
                                  aria-label={`Table Name - ${table.name}`}
                                  onSave={(name) => builderStore.changeTableName(table.id, name)}
                                />
                                {editMode && (
                                  <>
                                    <Popup
                                      content="Table Actions"
                                      position="top center"
                                      size="mini"
                                      trigger={
                                        <TableActionsDropdown
                                          tableId={table.id}
                                          deleteHandler={() => deleteTableHandler(table.id, table.name)}
                                          disabled={!!RATAutoQuery}
                                        />
                                      }
                                    />
                                    {builderStore.activeTableId && builderStore.activeTableId === table.id && (
                                      <Button
                                        size="mini"
                                        color="purple"
                                        className="ml-5"
                                        onClick={() => {
                                          builderStore.saveTableQuery(table.id);
                                          builderStore.setActiveTableId(undefined);
                                          if (showTableStructureConfirmation) {
                                            builderStore.ui.setShowTableStructureConfirmation(false);
                                            builderStore.addTable();
                                          }
                                        }}
                                      >
                                        {showTableStructureConfirmation ? "Confirm Structure" : "Done Editing"}
                                      </Button>
                                    )}
                                  </>
                                )}
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        )}

                        {/* TABLE ROW VARIABLE NAMES */}
                        {Object.keys(table.rows).length > 0 && (
                          <Table.Row
                            className={
                              builderStore.activeTableId
                                ? builderStore.activeTableId === table.id
                                  ? "border-left border-right border-5 border-info"
                                  : "opacity"
                                : ""
                            }
                          >
                            {robotIsOpen && <Table.Cell className="fw-700 bg-modern-grey px-2" />}
                            {calcIsOpen && (
                              <TableCellSelectTable
                                className="fw-700 bg-modern-grey px-2 text-dark cursor-pointer"
                                selectable
                                onClick={() => {
                                  builderStore.calc.eqString += `Table${table.id}`;
                                }}
                              >
                                Table {table.id}
                              </TableCellSelectTable>
                            )}
                            {table.rows.map((rowVariable, rowVariableIterator) => (
                              <Table.Cell
                                className="fw-700 bg-modern-grey border-bottom border-light"
                                colSpan={rowVariableIterator === 0 ? maxRowVariables - (rowIndex[0]?.length ?? 1) + 1 : 1}
                              >
                                {rowVariable.dimension}
                              </Table.Cell>
                            ))}
                            {table.rows && (
                              <Table.Cell
                                colSpan={newColumnIndex.length}
                                className="fw-700 bg-modern-grey border-bottom border-light"
                              ></Table.Cell>
                            )}
                          </Table.Row>
                        )}

                        {/* TABLE SECTION */}
                        <Table.Body
                          className={
                            builderStore.activeTableId
                              ? builderStore.activeTableId === table.id
                                ? "border border-5 border-info border-top-0"
                                : "opacity"
                              : ""
                          }
                        >
                          {/* TABLE SECTION WITH NO ROW INDEX */}
                          {rowIndex.length === 0 && (
                            <Table.Row>
                              {calcIsOpen && (
                                <Table.Cell
                                  selectable
                                  warning
                                  className="fw-700 bg-modern-grey px-2 text-dark cursor-pointer"
                                  onClick={() => {
                                    builderStore.calc.eqString += `Row${lastCalculableRowIdx + 1}`;
                                  }}
                                >
                                  {lastCalculableRowIdx + 1}
                                </Table.Cell>
                              )}
                              {robotIsOpen && (
                                <Table.Cell
                                  selectable={!!table.results?.length}
                                  warning
                                  className="fw-700 bg-modern-grey px-2 text-dark cursor-pointer"
                                  // no row index so just table.filters applicable for this "row"
                                  onClick={
                                    !!table.results?.length
                                      ? () => builderStore.selectRobotRow(table.filters, table.userSelectedDatasets[0])
                                      : null
                                  }
                                >
                                  {lastCalculableRowIdx + 1}
                                </Table.Cell>
                              )}

                              <Table.Cell
                                // rowSpan={countConsecutiveElements(rowIndex.slice(rowIterator).map(x => x[categoryIterator]))}
                                colSpan={maxRowVariables}
                                className="fw-400 bg-modern-grey"
                              >
                                {/* {table.rows ? category : "Result"} */}
                                {/* Results */}
                              </Table.Cell>

                              {/* TABLE VALUES NO ROW INDEX */}
                              {newColumnIndex.map((colCombo) => {
                                if (table.results) {
                                  const matchedResult = table.results.find(
                                    (res) =>
                                      colCombo.every(
                                        (colCategory, i) =>
                                          res[
                                            combinedDimensions[i].dimension.startsWith("!")
                                              ? colCategory.split(":::")[0]
                                              : combinedDimensions[i].dimension
                                          ]?.toString() ===
                                          (combinedDimensions[i].dimension.startsWith("!")
                                            ? colCategory.split(":::")[1]
                                            : colCategory.toString()),
                                      ),
                                    // && (!table.row || [table.row].every((rowVariable, i) => rowVariable && res[rowVariable] === row[i]))
                                  );
                                  if (matchedResult) {
                                    return (
                                      <Table.Cell className="text-right">
                                        {formatValue(matchedResult["Value"], [matchedResult["Measured quantity"]])}
                                      </Table.Cell>
                                    );
                                  }
                                  return <Table.Cell>-</Table.Cell>;
                                } else {
                                  console.log(JSON.stringify(table));
                                  return <></>;
                                }
                              })}
                            </Table.Row>
                          )}

                          {/* TABLE WITH ROW INDEX */}
                          {rowIndex.map((row, rowIterator) => (
                            <Table.Row>
                              {calcIsOpen && (
                                <Table.Cell
                                  selectable
                                  warning
                                  className="fw-700 bg-modern-grey px-2 text-dark cursor-pointer"
                                  onClick={() => {
                                    builderStore.calc.eqString += `Row${lastCalculableRowIdx + rowIterator + 1}`;
                                  }}
                                >
                                  {lastCalculableRowIdx + rowIterator + 1}
                                </Table.Cell>
                              )}
                              {robotIsOpen && (
                                <Table.Cell
                                  selectable={!!table.results.length}
                                  warning
                                  className="fw-700 bg-modern-grey px-2 text-dark cursor-pointer"
                                  onClick={
                                    !!table.results.length
                                      ? () => {
                                          builderStore.selectRobotRow(
                                            [...table.filters, ...table.rows.map((_r, i) => ({ ..._r, values: [row[i]] }))],
                                            table.userSelectedDatasets[0],
                                          );
                                        }
                                      : null
                                  }
                                >
                                  {lastCalculableRowIdx + rowIterator + 1}
                                </Table.Cell>
                              )}

                              {row ? (
                                row.map(
                                  (category, categoryIterator) =>
                                    (rowIterator === 0 ||
                                      rowIndex[rowIterator - 1][categoryIterator] !== rowIndex[rowIterator][categoryIterator]) &&
                                    (categoryIterator === 0 && table.rows ? (
                                      <Table.Cell
                                        rowSpan={countConsecutiveElements(rowIndex.slice(rowIterator).map((x) => x[categoryIterator]))}
                                        colSpan={maxRowVariables - rowIndex[0].length + 1}
                                        className="fw-400 bg-modern-grey position-relative"
                                        style={{ padding: sortIsOpen ? 20 : "0.9em 0.8em" }}
                                      >
                                        {category}
                                        {sortIsOpen && categoryIterator === row.length - 1 && isSingleColumnIndexTable && (
                                          <TableSortWidget
                                            ascendingClickHandler={() => builderStore.sortByValues("Row", true, table.id, row)}
                                            descendingClickHandler={() => builderStore.sortByValues("Row", false, table.id, row)}
                                          />
                                        )}
                                      </Table.Cell>
                                    ) : (
                                      <Table.Cell
                                        rowSpan={countConsecutiveElements(rowIndex.slice(rowIterator).map((x) => x[categoryIterator]))}
                                        // colSpan={maxRowIndexSize - rowIndex[0].length + 1}
                                        className="fw-400 bg-modern-grey position-relative"
                                        style={{ padding: sortIsOpen ? 20 : "0.9em 0.8em" }}
                                      >
                                        {category}
                                        {sortIsOpen && categoryIterator === row.length - 1 && isSingleColumnIndexTable && (
                                          <TableSortWidget
                                            ascendingClickHandler={() => builderStore.sortByValues("Row", true, table.id, row)}
                                            descendingClickHandler={() => builderStore.sortByValues("Row", false, table.id, row)}
                                          />
                                        )}
                                        {/* {table.row ? category : "Result"} */}
                                      </Table.Cell>
                                    )),
                                )
                              ) : (
                                <Table.Cell
                                  // rowSpan={countConsecutiveElements(rowIndex.slice(rowIterator).map(x => x[categoryIterator]))}
                                  className="fw-400 bg-modern-grey"
                                >
                                  {/* {table.rows ? category : "Result"} */}
                                  {/* Results */}
                                </Table.Cell>
                              )}

                              {/* TABLE VALUES WITH ROW INDEX */}
                              {row &&
                                newColumnIndex.map((colCombo) => {
                                  if (table.results) {
                                    const matchedResult = table.results.find(
                                      (res) =>
                                        colCombo.every(
                                          (colCategory, i) =>
                                            res[
                                              combinedDimensions[i].dimension.startsWith("!")
                                                ? colCategory.split(":::")[0]
                                                : combinedDimensions[i].dimension
                                            ]?.toString() ===
                                            (combinedDimensions[i].dimension.startsWith("!")
                                              ? colCategory.split(":::")[1]
                                              : colCategory.toString()),
                                        ) && row.every((rowVariable, i) => rowVariable && res[table.rows[i].dimension] === rowVariable),
                                    );
                                    if (matchedResult) {
                                      return (
                                        <Table.Cell className="text-right">
                                          {formatValue(matchedResult["Value"], [matchedResult["Measured quantity"]])}
                                        </Table.Cell>
                                      );
                                    }
                                    return <Table.Cell>-</Table.Cell>;
                                  } else {
                                    console.log(JSON.stringify(table));
                                    return <></>;
                                  }
                                })}
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </>
                    );
                  } else if (table.type === "calc") {
                    {
                      /* CALCULATIONS */
                    }
                    return (
                      <Table.Body>
                        <Table.Row>
                          {calcIsOpen && (
                            <Table.Cell
                              selectable
                              warning
                              className="fw-700 bg-modern-grey px-2 text-dark cursor-pointer"
                              onClick={() => {
                                builderStore.calc.eqString += `Row${lastCalculableRowIdx + 1}`;
                              }}
                            >
                              {lastCalculableRowIdx + 1}
                            </Table.Cell>
                          )}
                          {robotIsOpen && (
                            <Table.Cell
                              selectable
                              warning
                              className="fw-700 bg-modern-grey px-2 text-dark cursor-pointer"
                              onClick={() => {
                                const robotData = robotCalcTableTransform(table, builderStore.tables);
                                builderStore.selectRobotCalcRow({ ...robotData, rowNumber: lastCalculableRowIdx + 1 });
                              }}
                            >
                              {lastCalculableRowIdx + 1}
                            </Table.Cell>
                          )}
                          <Table.Cell
                            colSpan={maxRowVariables}
                            className="text-white fw-700 bg-medium position-relative"
                            style={{ padding: sortIsOpen ? 20 : "0.9em 0.8em" }}
                          >
                            <div className="text-white fw-700 d-flex align-items-center">
                              <Popup
                                trigger={<Icon name="calculator" className="mr-2 cursor-pointer" />}
                                content={<TableFormula formulaItems={calcToFormula(table, builderStore.tables)} inverted />}
                                position="bottom left"
                                size="mini"
                                offset={[-8, 5]}
                                inverted
                                hideOnScroll
                                wide
                              />
                              <InlineEdit
                                readOnly={!editMode}
                                value={table.name}
                                aria-label={`Table Name - ${table.name}`}
                                onSave={(name) => builderStore.changeTableName(table.id, name)}
                              />
                              {editMode && (
                                <Popup
                                  content="Calculation Actions"
                                  position="top center"
                                  size="mini"
                                  trigger={
                                    <TableActionsDropdown
                                      tableId={table.id}
                                      deleteHandler={() => deleteTableHandler(table.id, table.name)}
                                      calculation
                                      disabled={!!RATAutoQuery}
                                    />
                                  }
                                />
                              )}
                            </div>
                            {sortIsOpen && isSingleColumnIndexTable && (
                              <TableSortWidget
                                ascendingClickHandler={() => builderStore.sortByValues("Calc", true, table.id)}
                                descendingClickHandler={() => builderStore.sortByValues("Calc", false, table.id)}
                              />
                            )}
                          </Table.Cell>

                          {newColumnIndex.map((colCombo) => {
                            const value = getCNumber(
                              builderStore.tables,
                              table,
                              colCombo,
                              combinedDimensions,
                              getMissingFallback(calcMissingFallback, calcMissingFallbackValue),
                            );
                            return (
                              <Table.Cell className="text-right">
                                {value === null || isNaN(value)
                                  ? "-"
                                  : formatValue(value, [table.format !== "Decimal" ? "(%)" : "Decimal"])}
                              </Table.Cell>
                            );
                          })}
                        </Table.Row>
                      </Table.Body>
                    );
                  }
                  return <></>; // invalid type
                })}
              </Table>
              <TableSource />
            </div>
          </TableContainer>
        </>
      );
    }),
  ),
);
