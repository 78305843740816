import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { Button, Header, Icon, Popup, Progress, Table } from "semantic-ui-react";

import { Field, Form as FinalForm } from "react-final-form";

import { Helmet } from "react-helmet";
import { PageContainerWide } from "pages/PageContainerWide";
import { getMixpanel } from "common/api";
import { Heading } from "common/styledComponents/elements";

interface OnboardingProps {
  store?: Store;
}

export const RestackingSurvey = inject("store")(
  observer((props: OnboardingProps) => {
    const [step, setStep] = React.useState(1);
    const [formResponse, setFormResponse] = React.useState<any>(undefined);

    const orgTypeResponses = [
      "Service Provider: Antenatal Care (ANC)",
      "Service Provider: Early Childhood Care and Education (ECEC)",
      "Service Provider: Parenting Programs (PP)",
      "Service Provider: Sustained Nurse Home Visiting (SNHV)",
      "Service Provider: Early Years of School (EYS)",
      "Local Community Initiative",
      "Government staff (e.g. funders of services across the early years and policy advisors)",
      "Measurement and evaluation specialist",
      "Researcher",
      "Other",
    ];

    const dataUseCategories = {
      "Collect and enter data":
        "i.e., gathering information about clients/patients, children, parents, or families and recording this in data storage systems or electronic record keeping software",
      "Analyse and interpret data":
        "i.e., using existing information from data systems to produce reports about clients or groups of clients for administrators, regulatory authorities, industry peak bodies, or government",
      "Make decisions based on data":
        "i.e., using findings from service data to inform decisions about improving service quality or reach/access, whether at a service, community, state, or national level",
    };
    const dataUseOptions = ["Never", "Once a month or less", "A few times per month", "Once a week", "A few times or more per week"];

    const resourceOptions = [
      "Data system platform",
      "Data-use resources",
      "Data-use training events",
      "Implementation group meetings",
      "Data quality improvement support",
      "RSTO Indicator guide",
      "Community of practice",
      "I have not attended or used any RSTO events and/or resources in the past six months",
      "Other",
    ];

    const handleMixpanelSubmit = (newData) => {
      getMixpanel(props.store!).track("RSTO Survey Submission", { ...formResponse, ...newData });
    };

    return (
      <PageContainerWide>
        <Helmet>
          <title>RSTO Survey</title>
        </Helmet>

        <div className="mx-3">
          <div className="d-flex align-items-center mb-1">
            <div className="w-15">
              <img
                src="/assets/rsto/rsto_logo_full_color.png"
                className="mb-3"
                style={{ maxHeight: 100, width: "auto", maxWidth: "100%" }}
                alt="RSTO Logo"
              />
            </div>

            <div className="w-50 text-right">
              {/* {step > 1 &&
            <a className="text-medium" href="#" onClick={() => setStep(step - 1 || 1)}><Icon name="arrow left" /> Back</a>
          } */}
            </div>
          </div>
          <div className="w-100">
            <Progress percent={10 * step + Math.round((step / 5) * 100)} color="teal" size="tiny" indicating />
          </div>

          <div className="d-flex align-items-center my-5">
            {[1, 2, 3].includes(step) && (
              <div className="mt-0 mb-3">
                <Header as="h1" size="large" className="text-secondary">
                  Here are a few short questions ...
                </Header>
              </div>
            )}

            {step === 1 && (
              <div className="w-100">
                <div className="mb-3">
                  <Header as="h1" size="large" className="text-secondary">
                    Which best describes your role?
                  </Header>
                  <Heading size="large">Choose all that apply.</Heading>
                </div>

                <div>
                  <FinalForm
                    onSubmit={(data) => {
                      setStep(step + 1);
                      setFormResponse(data);
                    }}
                    render={({ handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        {orgTypeResponses.map((t) => (
                          <label className="d-block mb-3 fs-1500 d-flex align-items-center">
                            <Field
                              className="mr-3 flex-shrink-0 flex-grow-0"
                              style={{ width: "20px", height: "20px" }}
                              type="checkbox"
                              component="input"
                              name="Org Type"
                              value={t}
                              label={t}
                            />
                            {` ${t}`}
                          </label>
                        ))}
                        <Button className="mt-4" type="submit">
                          Next
                        </Button>
                      </form>
                    )}
                  />
                </div>
              </div>
            )}

            {step === 2 && (
              <div className="w-100">
                <div className="mb-3">
                  <Header as="h1" size="large" className="text-secondary">
                    How often do you use data in the following ways?
                  </Header>
                </div>

                <div>
                  <FinalForm
                    onSubmit={(data) => {
                      setStep(step + 1);
                      setFormResponse({ ...formResponse, ...data });
                    }}
                    render={({ handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <Table basic="very">
                          <Table.Header>
                            <Table.HeaderCell></Table.HeaderCell>
                            {dataUseOptions.map((t) => (
                              <Table.HeaderCell>{t}</Table.HeaderCell>
                            ))}
                          </Table.Header>

                          {Object.keys(dataUseCategories).map((category) => (
                            <Table.Row>
                              <Table.Cell width={5}>
                                {category}
                                <Popup
                                  content={dataUseCategories[category]}
                                  trigger={<Icon name="info circle" size="small" className="ml-1 text-medium align-top" />}
                                  position="bottom center"
                                  inverted
                                  wide
                                />
                              </Table.Cell>
                              {dataUseOptions.map((option) => (
                                <Table.Cell>
                                  <Field
                                    type="radio"
                                    component="input"
                                    name={`${category} (Data Use Frequency)`}
                                    value={option}
                                    label={category}
                                  />
                                </Table.Cell>
                              ))}
                            </Table.Row>
                          ))}
                        </Table>

                        <Button className="mt-4" type="submit">
                          Next
                        </Button>
                      </form>
                    )}
                  />
                </div>
              </div>
            )}

            {step === 3 && (
              <div className="w-100">
                <div className="mb-3">
                  <Header as="h1" size="large" className="text-secondary">
                    Which of the following Restacking the Odds resources have you used in the past six months?
                  </Header>
                  <Heading size="large">Choose all that apply.</Heading>
                </div>

                <div>
                  <FinalForm
                    onSubmit={(data) => {
                      setStep(step + 1);
                      handleMixpanelSubmit(data);
                    }}
                    render={({ handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        {resourceOptions.map((t) => (
                          <label className="d-block mb-3 fs-1500 d-flex align-items-center">
                            <Field
                              className="mr-3 flex-shrink-0 flex-grow-0"
                              style={{ width: "20px", height: "20px" }}
                              type="checkbox"
                              component="input"
                              name="Resources Used (Past 6 Months)"
                              value={t}
                              label={t}
                            />
                            {` ${t}`}
                          </label>
                        ))}
                        <Button className="mt-4" type="submit">
                          Next
                        </Button>
                      </form>
                    )}
                  />
                </div>
              </div>
            )}

            {step === 4 && (
              <div className="w-75">
                <div className="my-4">
                  <Header as="h1" size="large" className="text-secondary">
                    Amazing! Now, let's start exploring the platform...
                  </Header>
                </div>

                <Button size="big" as="a" href="/" className="bg-primary text-white bg-hover-red mt-4">
                  Let's go <Icon name="arrow alternate circle right" />
                </Button>
              </div>
            )}
          </div>
        </div>
      </PageContainerWide>
    );
  }),
);
