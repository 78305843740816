import * as React from "react";
import { Button } from "component/UI/Button";
import { COLORS } from "component/UI/common";
import { Icon, SemanticICONS } from "semantic-ui-react";

interface EmptyStateButton {
  label: string;
  icon: SemanticICONS;
  onClick: () => void;
}

interface EmptyStateProps {
  heading: string;
  description: string | React.ReactNode;
  image: string;
  primaryButton: EmptyStateButton;
  secondaryButton?: EmptyStateButton;
}

interface ListPageEmptyStateContentProps {
  description: string;
  link: string;
  text: string;
}

interface ContentPageEmptyStateDescriptionProps {
  onClick: () => void;
}

interface EmptyStateSecondaryProps {
  inverted?: boolean;
  hideIcon?: boolean;
  heading: string;
  description?: React.ReactNode;
}

// TODO: replace this with actual svg in assets
export const DASHED_LINE_STYLE =
  "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23D6D6D6' stroke-width='4' stroke-dasharray='3%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e";

export const EmptyStatePrimary = (props: EmptyStateProps): JSX.Element => {
  const { heading, description, image, primaryButton, secondaryButton } = props;
  return (
    <div
      className="bg-white p-6 w-100 mt-5 d-flex align-items-center justify-content-center"
      style={{ backgroundImage: `url("${DASHED_LINE_STYLE}")`, borderRadius: 8 }}
    >
      <div className="d-flex flex-column flex-lg-row align-items-center" style={{ maxWidth: 880 }}>
        <div className="mb-5 mb-lg-0 mr-0 mr-lg-6">
          <p className="fs-1500 fw-700 mb-3" style={{ color: COLORS.grey800 }}>
            {heading}
          </p>
          {typeof description === "string" ? (
            <p className="fs-1125 mb-5" style={{ color: COLORS.grey800 }}>
              {description}
            </p>
          ) : (
            description
          )}
          <div className="d-flex flex-column flex-xl-row align-items-start align-items-xl-center">
            <Button icon labelPosition="right" colorConfig="aquaCTA" className="mr-4 mb-4 mb-xl-0" onClick={primaryButton.onClick}>
              {primaryButton.label}
              <Icon name={primaryButton.icon} className="ml-2 mr-0" />
            </Button>
            {secondaryButton && (
              <Button colorConfig="purple" onClick={secondaryButton.onClick}>
                {secondaryButton.label} <Icon name={secondaryButton.icon} className="ml-2 mr-0" />
              </Button>
            )}
          </div>
        </div>
        <div>
          <img src={image} alt="Empty State Image" />
        </div>
      </div>
    </div>
  );
};

// Used as "description" content of "EmptyStateSecondary" component on Dashboards/Suitcases/Datasets list page
export const ListPageEmptyStateDescription = (props: ListPageEmptyStateContentProps): JSX.Element => {
  const { description, link, text } = props;
  return (
    <p className="fs-1125 text-center">
      <span style={{ color: COLORS.grey800, maxWidth: 516 }}>{description}</span>
      {link && (
        <span>
          {" "}
          Click here to{" "}
          <a href={link} target="_blank" style={{ color: COLORS.blue }}>
            <span className="text-decoration-underline mr-1">learn more</span>
            <Icon name="external alternate" className="mr-0" />
          </a>
          {` about ${text}.`}
        </span>
      )}
    </p>
  );
};

// Used as "description" content of "EmptyStateSecondary" component on Suitcase/Insight page
export const ContentPageEmptyStateDescription = (props: ContentPageEmptyStateDescriptionProps): JSX.Element => {
  const { onClick } = props;
  return (
    <p className="fs-1125">
      <span className="text-decoration-underline cursor-pointer" style={{ color: COLORS.blue }} onClick={onClick}>
        Click here
      </span>
      <span className="text-muted"> to write a summary.</span>
    </p>
  );
};

export const EmptyStateSecondary = (props: EmptyStateSecondaryProps): JSX.Element => {
  const { inverted, hideIcon, heading, description } = props;
  return (
    <div
      className={`p-5 w-100 mt-5 d-flex align-items-center justify-content-center ${inverted ? "" : "bg-white"}`}
      style={{ backgroundImage: `url("${DASHED_LINE_STYLE}")`, borderRadius: 8 }}
    >
      <div className="d-flex flex-column align-items-center" style={{ maxWidth: 516 }}>
        {!hideIcon && (
          <div
            className="d-flex align-items-center justify-content-center mb-3"
            style={{ backgroundColor: inverted ? "#fff" : COLORS.indigo100, width: 50, height: 50, borderRadius: 25 }}
          >
            <Icon name="inbox" className="text-secondary" style={{ marginRight: -2, marginTop: -5 }} />
          </div>
        )}
        <p className="fs-1500 text-center fw-700 mb-3" style={{ color: COLORS.grey800 }}>
          {heading}
        </p>
        {description}
      </div>
    </div>
  );
};
