import * as React from "react";
import { NotificationFeed } from "component/NotificationPopup/notificationFeed";
import { Header } from "semantic-ui-react";

export const NotificationFeedPage = (): JSX.Element => (
  <>
    <Header as="h2" color="purple" className="mb-0">
      Your Notifications
    </Header>
    <NotificationFeed />
  </>
);
