import * as React from "react";
import { Button, Icon } from "semantic-ui-react";
import { Dropdown, Search } from "semantic-ui-react";
import styled from "styled-components";

export const PageHeader = (props) => {
  const { label, onCancel } = props;
  return (
    <div className="d-flex justify-content-between align-items-center mb-5">
      <p className="fs-1000 mb-0">
        Seer Data Admin &gt; <b>{label}</b>
      </p>
      <Button color="red" basic size="tiny" onClick={onCancel}>
        Go back to main page <Icon name="arrow left" className="ml-1 mr-0" />
      </Button>
    </div>
  );
};

export const ContentWrapper = styled.div`
  max-width: 400px;
`;

export const SearchWrapper = styled.div`
  position: relative;
  width: 400px;
  margin-bottom: 40px;
`;

export const StyledSearch = styled(Search)`
  display: inline-block;
  .ui.input {
    width: 400px;
  }
  .results {
    max-height: 600px;
    width: 400px !important;
    overflow: auto;
  }
`;

export const StyledSearchDropdown = styled(Dropdown)`
  &.ui.dropdown {
    position: absolute;
    right: 15px;
    top: 10px;
    font-weight: 700;
    font-size: 13px;
  }
  &.ui.dropdown > .dropdown.icon {
    margin-left: 5px;
  }
  &.ui.dropdown .menu > .item {
    font-size: 13px;
  }
  &.ui.dropdown .menu > .item {
    padding: 10px 15px !important;
    border-radius: 4px;
  }
  &.ui.dropdown > .left.menu {
    margin-top: 10px;
    padding: 10px;
    border: 0;
  }
`;
