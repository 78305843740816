import { action, observable } from "mobx";
import { Builder } from "./builder";

type tableTabType = "topic" | "quantity" | "time" | "location" | "dataset" | "robot" | "advanced";

export class BuilderUserInterface {
  @observable leftTabFolded = true;
  @observable showTableStructureConfirmation = false;
  @observable activeTableTab: tableTabType = "topic";

  parent: Builder;

  constructor(parent: Builder) {
    this.parent = parent;
  }

  @action setLeftTabFolded(value: boolean): void {
    this.leftTabFolded = value;
  }

  @action setShowTableStructureConfirmation(value: boolean): void {
    this.showTableStructureConfirmation = value;
  }

  @action setActiveTableTab(value: tableTabType): void {
    this.activeTableTab = value;
  }
}
