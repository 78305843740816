import * as React from "react";
import { IIndicatorModuleData } from "component/Modules/IndicatorModule";
import { RestackingDashboardModule } from "component/Modules/RestackingDashboardModule";

export const BackupRestackingDashboardECECGowrie = () => {
  const indicatorData: IIndicatorModuleData[] = [
    {
      heading: "Participation: Who is accessing our services?",
      subheading: "How are our services being accessed?",
      description:
        "This refers to both who uses the service and how much (dosage). Children and families need to attend at the right dosage levels for benefits to be realised.",
      indicators: [
        {
          indicatorTitle: "Participation Indicator: Attendance amongst enrolled children: 2 years before school",
          chart1: {
            id: 92565,
            title:
              "Timeline: Proportion of children at your centres attending for less than 15 hours or 15 hours+ per week on average in the age groups two years before starting formal school",
          },
          chart2: {
            id: 92566,
            title:
              "Benchmarks: Proportion of children attending ECEC for 15 hours or more weekly on average in the two years before starting formal school",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10986/c7908e91-e653-4974-9b47-a2be1dbaeb44ba91efb6-95e2-4e4b-bf3e-667d8dd1bb9e",
          chart1Explainer: {
            shows:
              "The chart shows the rolling 12-month window of the proportion of enrolled children, 2 years before starting school age, attending at least 600 hours pa (15 hours pw) by centre",
            why: "ECEC starting age, program duration and program intensity are associated with children’s cognitive, academic, language and socio-emotional outcomes",
          },
          chart2Explainer: {
            shows:
              "The chart shows the proportion of enrolled children, 2 years before starting school age, attending at least 600 hours pa (15 hours pw) in the last 12 months, with results plotted against RSTO evidence",
            why: "ECEC starting age, program duration and program intensity are associated with children’s cognitive, academic, language and socio-emotional outcomes. The Preschool Reform Agreement recommends that all children attend at least 600 hours of ECEC per year in the two years before starting formal school.",
          },
          why: "ECEC starting age, program duration and program intensity are associated with children’s cognitive, academic, language and socio-emotional outcomes. RSTO evidence identifies that all children attend at least 15 hours pw, 40 weeks of the year, equivalent to 600 hou rs pa, two years before starting school.",
        },
        {
          indicatorTitle: "Participation Indicator: Weekly Attendance amongst enrolled children: 2 years before school (Jan - Apr 2023)",
          chart1: {
            id: 92568,
            title:
              "Timeline: Proportion of children at your centres attending for less than 15 hours or 15 hours+ per week on average in the age groups two years before starting formal school",
          },
          chart2: {
            // id: 89566,
            // title: "Benchmarks: Proportion of children attending ECEC for 15 hours or more weekly on average in the two years before starting formal school",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10986/c7908e91-e653-4974-9b47-a2be1dbaeb44ba91efb6-95e2-4e4b-bf3e-667d8dd1bb9e",
          chart1Explainer: {
            shows: "The chart shows the number of enrolled children per hours attended per week in the 2 years before starting school age",
            why: "ECEC starting age, program duration and program intensity are associated with children’s cognitive, academic, language and socio-emotional outcomes",
          },
          // chart2Explainer: {
          //   shows: "The chart shows the proportion of enrolled children, 2 years before starting school age, attending at least 600 hours pa (15 hours pw), with results plotted against RSTO evidence",
          //   why: "ECEC starting age, program duration and program intensity are associated with children’s cognitive, academic, language and socio-emotional outcomes. The Preschool Reform Agreement recommends that all children attend at least 600 hours of ECEC per year in the two years before starting formal school.",
          // },
          why: "ECEC starting age, program duration and program intensity are associated with children’s cognitive, academic, language and socio-emotional outcomes. RSTO evidence identifies that all children attend at least 15 hours pw, 40 weeks of the year, equivalent to 600 hou rs pa, two years before starting school.",
        },
        {
          indicatorTitle:
            "Targeted Participation Indicator: Attendance amongst enrolled children in priority groups: 3 years before school",
          chart1: {},
          chart2: {},
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10986/c7908e91-e653-4974-9b47-a2be1dbaeb44ba91efb6-95e2-4e4b-bf3e-667d8dd1bb9e",
          chart1Explainer: {
            shows:
              "The chart shows the proportion of enrolled children in priority groups, 3 years before starting school age, attending at least 600 hours pa",
            why: "ECEC starting age, program duration and program intensity are associated with children’s cognitive, academic, language and socio-emotional outcomes",
          },
          chart2Explainer: {
            shows:
              "The chart shows the proportion of enrolled children in priority groups, 3 years before starting school age, attending at least 600 hours pa, against RSTO evidence.",
            why: "ECEC starting age, program duration and program intensity are associated with children’s cognitive, academic, language and socio-emotional outcomes. The Preschool Reform Agreement recommends that all children attend at least 600 hours of ECEC per year in the two years before starting formal school.",
          },
          why: "Optimal levels of ECEC starting age, program duration and program intensity, associated with children’s cognitive, academic, language and socio-emotional outcomes vary for children from priority groups. ECEC starting age, program duration and program intensity are a ssociated with children’s cognitive, academic, language and socio-emotional outcomes. RSTO evidence identifies that all children attend at least 15 hours pw, 40 weeks of the year, equivalent to 600 hours pa, three years before starting school for priority groups. Priority Groups include: refugees or asylum seeker populations, disability populations, Aboriginal and Torres Strait Islander populations, Health Care Card holders, children in out-of-home care, and culturally and linguistically diverse (CALD) populations",
        },
      ],
    },
    {
      heading: "Quality: Are we delivering high-quality services?",
      subheading: "Are we delivering high quality services?",
      description:
        "ECEC service quality is associated with improved child outcomes, including cognitive/academic and social/emotional outcomes.",
      indicators: [
        {
          indicatorTitle: "Quality Indicator: ECEC services with ACECQA Quality Rating (RSTO Adjusted)",
          chart1: {
            // id: 88634,
            image:
              "https://seer-media.s3.ap-southeast-2.amazonaws.com/insight-files/667158fd-d66b-4674-9d25-6762e47c1d42/ACECQA%20Ratings%20Per%20Centre.png",
            title: "QL1: ACECQA NQS Quality Area ratings per centre",
          },
          chart2: {
            id: 84230,
            title:
              "Benchmarks: Proportion of ECEC services rated ‘exceeding’ the standard in quality areas 1, 4 and 5 and at least ‘meeting’ the standard in all other quality areas",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10984/c7908e91-e653-4974-9b47-a2be1dbaeb44ba91efb6-95e2-4e4b-bf3e-667d8dd1bb9e",
          chart1Explainer: {
            shows:
              "The table shows the ACECQA Quality Area (QA) ratings per ECEC centre, as well as whether the centre is meeting RSTO Evidence.",
            why: "ECEC service quality is associated with improved child outcomes (cognitive/academic and social-emotional).",
          },
          chart2Explainer: {
            shows:
              "The chart shows the proportion of ECEC services rated ‘exceeding’ the standard in quality areas 1, 4 and 5 and at least ‘meeting’ the standard in all other quality areas according to the ACECQA assessment, against benchmarks and RSTO evidence.",
            why: "ECEC service quality areas QA1, QA4 and QA5 are evidence-based indicators that are associated with improved child outcomes (cognitive/academic and social-emotional).",
          },
          why: "A strategy with ‘quality’ is one for which there is robust evidence showing it delivers the desired outcomes. A large number of research studies have explored aspects of this question (i.e., “What works?”). Quality Areas that had the most robust evidence relating to child development outcomes included QA1 Educational program and practice, QA4 – Staffing arrangements, and QA5 – Relationships with children. This forms the basis of the RSTO-adjusted National Quality Score (NQS) definition.",
        },
      ],
    },
    {
      heading: "Quantity: Are sufficient services available?",
      subheading: "Are sufficient services available?",
      description:
        "This relates to the physical access to local services in sufficient quantity. Quantity indicators help to determine the amount of resource and infrastructure needed to deliver the service for a given population at the right quality and dose.",
      indicators: [
        {
          indicatorTitle: "Quantity Indicator: ECEC places per target population",
          chart1: {
            id: 89171,
            title: "Proportion of ECEC places per target population (2-5 years)",
          },
          chart2: {
            id: 90026,
            title: "Benchmarks for proportion of ECEC places per target population (2-5 years)",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10985/c7908e91-e653-4974-9b47-a2be1dbaeb44ba91efb6-95e2-4e4b-bf3e-667d8dd1bb9e",
          chart1Explainer: {
            shows:
              "The chart shows the number of places (15pw) for children aged 0- 5 years available in the specified provider SA2 catchment areas, relative to the population. The gray areas show the gap between places provided and those needed to ensure adequate opportunity for participation.",
            why: "To ensure adequate ECEC places for the population (2-5 years).",
          },
          chart2Explainer: {
            shows:
              "The chart above shows the number of children in the SA2 catchment areas, aged 0-5 who do not have an approved place at an ECEC centre within their SA2 area.",
            why: "There should be at least one ECEC place for every child in the local population to ensure adequate opportunity for participation.",
          },
          why: "‘Quantity’ helps us determine the quantum of effort and infrastructure needed to deliver ECEC adequately for a given population. It takes into account a) infrastructure i.e. are their sufficient places for the defined population to attend for fifteen hours or more and b) workforce i.e. is there a sufficient workforce of qualified ECEC workers and teachers. There should be at least one ECEC place provided for every child in the local catchment area population to attend. This will ensure adequate opportunity for participation.",
        },
        {
          indicatorTitle: "Quantity Indicator: Gowrie Contribution to ECEC places per target population",
          chart1: {
            id: 89893,
            title: "Proportion of ECEC places per target population (2-5 years)",
          },
          chart2: {
            // id: 90026,
            // title: "Benchmarks for proportion of ECEC places per target population (2-5 years)",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10985/c7908e91-e653-4974-9b47-a2be1dbaeb44ba91efb6-95e2-4e4b-bf3e-667d8dd1bb9e",
          chart1Explainer: {
            shows:
              "The chart shows the number of places (15pw) for children aged 0- 5 years available in the specified provider SA2 catchment areas, relative to the population. The gray areas show the gap between places provided and those needed to ensure adequate opportunity for participation.",
            why: "To ensure adequate ECEC places for the population (2-5 years).",
          },
          // chart2Explainer: {
          //   shows: "The chart above shows the proportion of 15 hour per week ECEC places for children aged 0-5 years available in the provider SA2 catchment areas, plotted against Metropolitan, State and National benchmarks, as well as RSTO evidence.",
          //   why: "There should be at least one ECEC place for every child in the local population to ensure adequate opportunity for participation.",
          // },
          why: "‘Quantity’ helps us determine the quantum of effort and infrastructure needed to deliver ECEC adequately for a given population. It takes into account a) infrastructure i.e. are their sufficient places for the defined population to attend for fifteen hours or more and b) workforce i.e. is there a sufficient workforce of qualified ECEC workers and teachers. There should be at least one ECEC place provided for every child in the local catchment area population to attend. This will ensure adequate opportunity for participation.",
        },
      ],
    },
  ];

  const dashboardData = {
    heading: "Gowrie Victoria",
    subheading: "Early Childhood Education & Care (ECEC)",
    description:
      "Quality early childhood education and care (ECEC) provides valuable play-based opportunities for learning, developmental and social engagement for children before they begin school. Restacking The Odds has identified several evidence-based indicators in this area.",
    strategyLink: "https://www.rsto.org.au/",
    indicatorModuleData: indicatorData,
  };

  return <RestackingDashboardModule {...dashboardData} />;
};
