import * as wnac from "./customizations/wnac";

const defaultStyleFn = (layerOpts) => (feature) => {
  if (feature) {
    // nothing custom allowed here currently, limit of config JSON
  }
  return layerOpts.defaultStyle || {};
};

// used when onEachFeature enabled and does not have custom onEachFeature set, default to simple tooltips
const defaultOnEachFeatureFn = (layerOpts) => (feature: any, leaflet_layer: any) => {
  const tooltipContent = `
    <div>
      ${layerOpts.properties
        .map((property) => {
          const { label, name } = property;
          return `
          <p style="margin: 0"><b>${label}</b>: ${feature.properties[name]}</p>
        `;
        })
        .join("\n")}
    </div>
  `;
  leaflet_layer.bindTooltip(tooltipContent, { direction: "top", sticky: true });
};

const getCustomizationObject = (customization) => {
  if (!customization.style) {
    customization.style = defaultStyleFn;
  }
  if (!customization.onEachFeature) {
    customization.onEachFeature = defaultOnEachFeatureFn;
  }
  return customization;
};

export const customizations = {
  wnac: getCustomizationObject(wnac),
  default: {
    style: defaultStyleFn,
    onEachFeature: defaultOnEachFeatureFn,
  },
};
