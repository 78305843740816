import * as React from "react";
import { inject, observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { withRouter, RouteComponentProps } from "react-router";

import Store from "common/store";
import Protected from "component/protected";
import { ShowLoaderGlobal } from "component/LoaderGlobal";
import InsightOverview from "component/overview/InsightOverview";
import { Main } from "./Insight.style";
import { shortenString } from "common/helpers/string";
import { Header } from "semantic-ui-react";

type PropsType = RouteComponentProps<{ id: string; key?: string }> & {
  store: Store;
};

class Insight extends React.Component<PropsType> {
  store = this.props.store;
  insight = this.store.insight;
  builder = this.store.builder;

  async componentDidMount() {
    const { id, key: publicUrlKey } = this.props.match.params;

    await this.insight.load(parseInt(id, 10), false, publicUrlKey);
    await this.builder.loadInsight(parseInt(id, 10), false, publicUrlKey, this.insight.result);

    this.insight.comments.type = "insights";
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  async componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)) {
      const { id, key: publicUrlKey } = this.props.match.params;
      await this.insight.load(parseInt(id, 10), false, publicUrlKey);
      await this.builder.loadInsight(parseInt(id, 10), false, publicUrlKey, this.insight.result);
    }
  }

  render() {
    const { key: publicUrlKey } = this.props.match.params;
    const isPublicUrl = !!publicUrlKey;
    const activeSuitcase = this.store.suitcase.active;

    if (this.store.loading || this.builder.loading.loadInsight) {
      return <ShowLoaderGlobal />;
    }
    if (!isPublicUrl && activeSuitcase?.id !== this.insight.result.suitcase_id) {
      return (
        <Header as="h4" className="text-center mb-1">
          You do not have access to this Insight.
        </Header>
      );
    }
    const insight = this.insight;

    return (
      <>
        <Main id="insight" style={{ overflow: "auto" }}>
          <Helmet>
            <title>{`${shortenString(this.insight.result.name, 5)} - Insight #${this.insight.result.id}`}</title>
          </Helmet>
          <div style={{ position: "relative" }}>
            <InsightOverview store={insight.parent} publicUrlKey={publicUrlKey || false} />
          </div>
        </Main>
      </>
    );
  }
}

export default withRouter(Protected(inject("store")(observer(Insight))));
