import * as React from "react";
import { Dropdown } from "semantic-ui-react";
import * as qs from "qs";
import { stripURLData } from "pages/EmbedInsight/EmbedInsight";
import { DashFilterOptions, getSpacingString, Spacing } from "./helpers";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Adjust right padding to accommodate the dropdown caret-down icon/Reset button
const caretDownIconWidth = 42;
const getDropdownPaddingString = (spacing: Spacing) =>
  typeof spacing === "number"
    ? `${spacing}px ${spacing + caretDownIconWidth}px ${spacing}px ${spacing}px`
    : `${spacing.top}px ${spacing.right + caretDownIconWidth}px ${spacing.bottom}px ${spacing.left}px`;

const CustomDropdown = styled(Dropdown)<{ styling?: any }>`
  &.ui.ui.multiple.dropdown {
    padding: ${(prop) =>
      prop.styling?.padding === undefined ? ".75em 3.5em .75em 1em" : getDropdownPaddingString(prop.styling?.padding)} !important;
    border-width: ${(prop) =>
      prop.styling?.border?.width === undefined ? "1px" : getSpacingString(prop.styling?.border?.width)} !important;
    border-radius: ${(prop) =>
      prop.styling?.border?.radius === undefined ? "4px" : getSpacingString(prop.styling?.border?.radius)} !important;
    border-color: ${(prop) => prop.styling?.border?.color || "#dadada"};
    min-height: auto !important;
    min-width: auto !important;
  }
  &.ui.ui.multiple.dropdown:hover {
    border-color: ${(prop) => prop.styling?.border?.colorHover || "#022CC3"};
    .visible.menu.transition {
      border-color: ${(prop) => prop.styling?.border?.colorHover || "#022CC3"} !important;
    }
  }
  .visible.menu.transition {
    border-color: ${(prop) => prop.styling?.border?.color || "#dadada"} !important;
    border-width: ${(prop) =>
      prop.styling?.border?.width === undefined ? "1px" : getSpacingString(prop.styling?.border?.width)} !important;
    margin-left: ${(prop) =>
      prop.styling?.border?.width === undefined
        ? "-1px"
        : `${typeof prop.styling?.border?.width === "number" ? `-${prop.styling?.border?.width}px` : `-${prop.styling?.border?.width?.left}px`}`} !important;
    width: ${(prop) =>
      prop.styling?.border?.width === undefined
        ? "calc(100% + 2px)"
        : `${typeof prop.styling?.border?.width === "number" ? `calc(100% + ${prop.styling?.border?.width * 2}px)` : `calc(100% + ${prop.styling?.border?.width?.left + prop.styling?.border?.width?.right}px)`}`} !important;
  }
  .visible.menu.transition:hover {
    border-color: ${(prop) => prop.styling?.border?.colorHover || "#022CC3"} !important;
  }
  .default.text {
    white-space: normal !important;
    margin: 0 !important;
  }
  .text,
  .item,
  .ui.label {
    font-family: ${(prop) => prop.styling?.label?.fontFamily || "Open Sans"};
    font-size: ${(prop) => prop.styling?.label?.fontSize || 14}px;
    font-weight: ${(prop) => prop.styling?.label?.fontWeight || 400};
  }
  .dropdown.icon {
    top: calc(50% - 10px) !important;
  }
  .clear.dropdown.icon {
    opacity: 1 !important;
  }
  .clear.dropdown.icon::before {
    content: "Reset" !important;
    font-family: "Open Sans";
    font-size: 12px;
    color: #c00130;
    font-weight: 700;
  }
  a {
    text-decoration: none !important;
    color: ${(prop) => prop.styling?.tag?.colorText || "#3E3E3E"} !important;
    background-color: ${(prop) => prop.styling?.tag?.colorBg || "#EEEEEE"} !important;
    border: none;
    padding: 0.75em !important;
  }
  a:hover {
    text-decoration: none !important;
    color: ${(prop) => prop.styling?.tag?.colorText || "#3E3E3E"} !important;
    background-color: ${(prop) => prop.styling?.tag?.colorBg || "#EEEEEE"} !important;
    border: none;
  }
`;

interface Props {
  dashboardDescriptionID: string;
  variable: string;
  // selected in case we want to have default selections displayed in future, can ignore for now
  categories: { name: string; selected: boolean }[];
  insightIds: number[]; // specific insights to apply filter to, if empty array apply to all
  dashFilterOptions: DashFilterOptions | undefined;
}

export const DataFilter = (props: Props) => {
  const { variable, categories, dashboardDescriptionID, insightIds, dashFilterOptions } = props;
  const options = categories.map(({ name }, idx) => ({ key: `${idx}-${name}`, text: name, value: name }));
  const defaultSelectedOptions = categories.filter((cat) => cat.selected).map(({ name }) => name);
  const [selectValue, setSelectValue] = React.useState<any>(defaultSelectedOptions);
  const { styling } = dashFilterOptions || {};
  const fontSrc = styling?.label?.fontUrl !== "none" && styling?.label?.fontUrl;

  const applyInsightFilter = (filterValue) => {
    const embeds = document.querySelectorAll(`#${dashboardDescriptionID} iframe.seer-dashboards-insight`);
    for (const embed of Array.from(embeds) as HTMLEmbedElement[]) {
      const { src } = embed;
      const path = src.slice(0, src.indexOf("?"));
      const queryStr = src.slice(src.indexOf("?") + 1);
      const query = qs.parse(queryStr);
      const url = query.url || "";
      const { id } = stripURLData(url);
      if (!id || (insightIds.length && !insightIds.includes(+id))) {
        // skip if:
        // - id not found in url
        // - insightIds is not empty and id not in list
        continue;
      }
      query.filter = query.filter || {};
      query.filter[variable] = filterValue;
      if (!filterValue.length) {
        delete query.filter[variable]; // if no categories selected, remove the filter
      }
      // update the embed src with the newly applied filters
      embed.src = `${path}?${qs.stringify(query)}`;
    }
  };

  return (
    <>
      {fontSrc && (
        <Helmet>
          <link rel="stylesheet" href={fontSrc} />
        </Helmet>
      )}
      <CustomDropdown
        styling={styling}
        multiple
        selection
        clearable
        value={selectValue}
        options={options}
        placeholder={`Filter by ${variable}`}
        onChange={(_e, { value }) => {
          if (value.length === 0) {
            value = defaultSelectedOptions;
          }
          setSelectValue(value);
          applyInsightFilter(value);
        }}
      />
    </>
  );
};
