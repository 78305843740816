import * as React from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";

export const TotalsModal = ({ isOpen, close, updateTotals, variableData }) => {
  const [value, setValue] = React.useState(variableData.totals || null);
  React.useEffect(() => {
    setValue(variableData.totals || "none");
  }, [variableData]);
  const onSubmit = (): any => {
    updateTotals(value);
    close();
  };
  return (
    <Modal open={!!isOpen} onClose={close}>
      <Modal.Header className="fs-2000 fw-700 text-secondary">{variableData.variable} Totals configuration</Modal.Header>
      <Modal.Content>
        <h5>Configure totals for {variableData.variable}</h5>
        <Form onSubmit={() => null}>
          <Form.Group grouped>
            <Form.Radio
              label="Manual"
              value="manual"
              checked={value === "manual"}
              onChange={(_, { value: nextVal }) => setValue(nextVal)}
            />
            <Form.Radio
              label="Summed"
              value="summed"
              checked={value === "summed"}
              onChange={(_, { value: nextVal }) => setValue(nextVal)}
            />
            <Form.Radio label="None" value="none" checked={value === "none"} onChange={(_, { value: nextVal }) => setValue(nextVal)} />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={close}>Cancel</Button>
        <Button type="submit" className="bg-primary text-white bg-hover-red" onClick={onSubmit}>
          Update
          <Icon name="save" className="ml-2 mr-0" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
