import { observable, action } from "mobx";

import { database } from "../api";
import Store from "../store";

export default class NotificationsStore {
  @observable notifications: any[] = [];
  parent: Store;

  constructor(parent: Store) {
    this.parent = parent;
  }

  @action async getNotifications() {
    const res: any = await database.get("notifications", "", this.parent.token!);

    if (res.statusCode === 200) {
      this.parent.notifications = res.body.data.notifications;
    }
  }

  @action async markNotificationsAsRead(ids: number[]) {
    const body = { notification_ids: ids };
    const res: any = await database.post("notifications/mark-as-viewed", body, this.parent.token!);

    if (res.statusCode === 200) {
      ids.map((id) => {
        const index = this.parent.notifications.findIndex((x) => x.id === id);
        Object.assign(this.parent.notifications[index], { viewed: true });
      });
    }
  }

  @action async getUser(token: string) {
    const res: any = await database.get(`notifications/unsubscribe/${token}`, "", this.parent.token!);

    if (res.statusCode === 200) {
      return res.body.data.user;
    } else {
      return {};
    }
  }

  @action async unsubscribe(token: string) {
    const res: any = await database.put(`notifications/unsubscribe/${token}`, {}, this.parent.token!);

    if (res.statusCode === 200) {
      return true;
    } else {
      return false;
    }
  }
}
