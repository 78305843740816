import { COLORS } from "component/UI/common";

const transparentPurple = "rgba(205, 146, 205, 0.2)";

export const unlayerCustomCSS = `
.btn-primary.btn.btn-sm {
  background-color: ${COLORS.indigo600} !important;
  border-color: ${COLORS.indigo600} !important;
}

.blockbuilder-layer.blockbuilder-layer-selected {
  --accent-color: ${COLORS.indigo600} !important;
}

.blockbuilder-layer {
  --accent-color: ${COLORS.indigo600} !important;
}

.blockbuilder-placeholder .blockbuilder-placeholder-empty {
  outline: ${COLORS.indigo600} dashed 1px !important;
  color: ${COLORS.indigo600} !important;
  background-color: ${transparentPurple} !important;
}

.blockbuilder-placeholder a, .blockbuilder-placeholder a:hover {
  background-color: ${COLORS.indigo600} !important;
}

.blockbuilder-placeholder {
  --accent-color: ${COLORS.indigo600} !important;
}

.blockbuilder-layer-controls .blockbuilder-layer-control:hover {
  --accent-color: ${COLORS.indigo600} !important;
}

.tabs a.active {
  border-bottom: 1px solid ${COLORS.red500} !important;
}

.rc-slider .rc-slider-track {
  background-color: ${COLORS.indigo600} !important;
}

.rc-slider .rc-slider-handle {
  border-color: ${COLORS.indigo600} !important;
}

.react-toggle--checked .react-toggle-track {
  background-color: ${COLORS.indigo600} !important;
}

.react-toggle--checked .react-toggle-thumb {
  border-color: ${COLORS.indigo600} !important;
}

.u_content_video img {
  width: 100% !important;
  height: auto !important;
  max-width: 100% !important;
}
`;
