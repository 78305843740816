import styled from "styled-components";
import { Modal } from "semantic-ui-react";
import { device, $opensans, $border } from "./_config.style";
import { COLORS } from "component/UI/common";

export const StyleModal = styled(Modal)`
  max-width: 600px !important;
  top: 60px !important;
  bottom: 60px !important;
  overflow-y: auto;
  @media ${device.tablet} {
    left: 60px !important ;
    right: 60px !important;
    width: auto !important;
  }
  @media ${device.tabletH} {
    top: 60px !important;
    bottom: 60px;
  }
`;

export const StyleHeader = styled(Modal.Header)`
  border-bottom: ${$border};
  font-family: ${$opensans} !important;
  font-weight: bold !important;
  font-size: 30px !important;
  line-height: 41px !important;
  color: ${COLORS.indigo600} !important;
  height: 80px !important;
`;

export const StyleContent = styled(Modal.Content)`
  position: absolute !important;
  overflow-y: auto !important;
  top: 80px !important;
  bottom: 80px !important;
  @media (max-height: 600px) {
    overflow-y: auto !important;
    /* height: 100% - 10px !important; */
  }
`;
export const StyleActions = styled(Modal.Actions)`
  background-color: white !important;
  height: 80px !important;
  position: absolute;
  bottom: 0 !important;
  width: 100% !important;
  padding: 20px !important;
`;
