import { Heading } from "common/styledComponents/elements";
import { StylePopup } from "component/insightBuilder/insightBuilderStyles/whereWhatWhen.style";
import * as React from "react";
import { Icon } from "semantic-ui-react";

export interface SummaryChart {
  heading: string;
  subHeading?: string;
  chartID: number | string;
  suitcaseID: number;
  hideTitle: boolean;
}

export interface IIndicatorSummaryModule {
  summaryCharts: SummaryChart[];
}

export const IndicatorSummaryModule = (props: IIndicatorSummaryModule) => {
  const { summaryCharts } = props;
  const key = "1ae988bb-85ba-44bc-b9b4-5cfc6b32705e64eaa2f6-e843-4de9-9452-800838cb36e6";

  return (
    <>
      <div className="d-flex justify-content-center">
        <div
          className="text-white rounded-4 p-2 mt-5 mb-3"
          style={{ width: "85%", backgroundColor: "#f68d3a" }}
          id="u_content_summary_heading_1"
        >
          <p className="fs-1500 mb-0 text-center" style={{ fontFamily: "Bebas Neue", textTransform: "uppercase" }}>
            Summary
          </p>
        </div>
      </div>

      <div className="d-flex justify-content-center my-4">
        <div className={"d-flex align-items-stretch justify-content-between"} style={{ width: "85%" }}>
          {summaryCharts.map((chart) => (
            <div className={"align-self-start w-100  bg-white h-100 rounded-4 mx-4 pt-4"} id={`u_content_summary_chart_${chart.chartID}`}>
              <div className="d-flex justify-content-between">
                <StylePopup
                  trigger={
                    <a href={`/suitcases/${chart.suitcaseID}/${key}`} target="_blank" className="d-inline-block">
                      <div className="pl-4 pt-2">
                        <span
                          className="fs-1500 mb-0 mr-1"
                          style={{ fontFamily: "Bebas Neue", textTransform: "uppercase", color: chart.subHeading ? "#504F5C" : "#f68d3a" }}
                        >
                          {chart.heading}
                        </span>
                        <Icon
                          name="external alternate"
                          className="mt-1 cursor-pointer"
                          style={{ color: chart.subHeading ? "#504F5C" : "#f68d3a" }}
                        />
                      </div>
                    </a>
                  }
                  content="Open suitcase in new tab"
                  position="middle bottom"
                  size="mini"
                  offset={[10, 0]}
                  inverted
                />
              </div>
              {chart.subHeading && (
                <div className="mt-2 px-4" style={{ minHeight: 50 }}>
                  <Heading className="fs-1500 fw-400" style={{ fontFamily: "Bebas Neue", color: "#faab70" }}>
                    {chart.subHeading}
                  </Heading>
                </div>
              )}
              <div style={{ height: "360px" }}>
                <iframe
                  style={{ width: "100%", height: "100%", border: "none" }}
                  className="seer-dashboards-insight"
                  src={`/embed/insight?url=https://seerplatform.com/insights/${chart.chartID}/${key}&columns=1&hideLegend=false&hideTitle=${chart.hideTitle}`}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
