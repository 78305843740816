import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Accordion, AccordionContent, AccordionTitle, Dropdown, DropdownItem, DropdownMenu, Form, Icon, List, ListItem, Modal, ModalActions, ModalContent, ModalHeader } from "semantic-ui-react";
import { Button } from "component/UI/Button";
import { Form as FinalForm, Field } from "react-final-form";
import { getMixpanel } from "common/api";
import { FieldCheck } from "component/FinalFormFields/FieldCheck";
import { FieldInput } from "component/FinalFormFields/FieldInput";
import { composeValidators, mustBeNumber, required } from "common/helpers/finalForm";
import { Builder } from "common/store/builder";

interface Props {
  builder?: Builder;
}

const Component = (props: Props): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [accordionActive, setAccordionActive] = React.useState(false);

  const builder = props.builder!;
  const calcIsOpen = builder.calc.open;
  const robotIsOpen = builder.robot.open;
  const { calcMissingFallback, calcMissingFallbackValue } = builder;

  const onSubmit = (values: { calcMissingFallback: boolean; calcMissingFallbackValue: string}) => {
    const { calcMissingFallback, calcMissingFallbackValue } = values;
    builder.setCalcMissingFallback(calcMissingFallback);
    builder.setCalcMissingFallbackValue(parseFloat(calcMissingFallbackValue as string));
    getMixpanel(builder.parent).track("Insight Builder > Update missing data fallback", { "Enabled": calcMissingFallback, "Value": calcMissingFallbackValue });
    setOpen(false);
  };

  return (
    <>
      <Dropdown text="Calculation" button disabled={robotIsOpen} style={{ fontSize: 15 }}>
        <DropdownMenu direction="left">
          <DropdownItem
            onClick={() => builder.setCalcIsOpen(!calcIsOpen)}
            disabled={calcIsOpen}
            className="d-flex justify-content-between"
          >
            New calculation<Icon name="calculator" className="ml-2 mr-0" />
          </DropdownItem>
          <DropdownItem
            onClick={() => setOpen(true)}
            className="d-flex justify-content-between"
          >
            Manage missing data fallback<Icon name="edit" className="ml-2 mr-0" />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Modal open={open}>
        <FinalForm
          onSubmit={onSubmit}
          initialValues={{ calcMissingFallback, calcMissingFallbackValue: calcMissingFallbackValue?.toString() }}
          render={(formRenderProps) => {
            const { handleSubmit, values } = formRenderProps;
            const { calcMissingFallback, calcMissingFallbackValue } = values;
            return (
              <>
                <ModalHeader className="text-secondary">Manage missing data fallback</ModalHeader>
                <ModalContent>
                  <Accordion fluid styled className="mb-4">
                    <AccordionTitle onClick={() => setAccordionActive(!accordionActive)} className="text-dark">
                      <Icon name="dropdown" />
                      Warning: You have the option to replace missing values in this dataset to enable calculations. Please be cautious when using this feature.
                    </AccordionTitle>
                    <AccordionContent active={accordionActive}>
                      <List bulleted>
                        <ListItem>
                          <b>Context Matters:</b> Replacing missing values may lead to inaccurate results if the missing data is not intended to represent the replacement value. Consider the context of your data before applying this option.
                        </ListItem>
                        <ListItem>
                          <b>Data Integrity:</b> Ensure that replacing missing values does not compromise the integrity of your analysis or lead to misleading Insights.
                        </ListItem>
                        <ListItem>
                          <b>Consult Guidance:</b> If unsure, consult with your data governance team or follow established guidelines for handling missing data in your specific use case.
                        </ListItem>
                      </List>
                      <p className="fs-1000">Use this feature judiciously to maintain the accuracy and reliability of your analysis.</p>
                    </AccordionContent>
                  </Accordion>
                  <Form onSubmit={handleSubmit}>
                    <label className="fs-1000 text-dark fw-700">Enable missing data fallback</label>
                    <Field
                      name="calcMissingFallback"
                      toggle
                      component={FieldCheck}
                    />
                    {calcMissingFallback && (
                      <Field
                        name="calcMissingFallbackValue"
                        label="Set missing data fallback value"
                        value={calcMissingFallbackValue}
                        component={FieldInput}
                        validate={composeValidators(required, mustBeNumber)}
                        className="w-50"
                      />
                    )}
                  </Form>
                </ModalContent>
                <ModalActions>
                  <Button colorConfig="red" onClick={() => setOpen(false)}>Close</Button>
                  <Button colorConfig="purpleInverted" onClick={handleSubmit}>Save</Button>
                </ModalActions>
              </>
            );
          }}
        />
      </Modal>
    </>
  );
};

export const CalcDropdown = inject((stores: any) => ({
  builder: stores.store.builder,
}))(observer(Component));
