import React = require("react");
import { Icon, Popup } from "semantic-ui-react";

import { Avatar } from "component/Avatar";
import {
  StyleNameSuitcase,
  StyleOrganisation,
  StyleTileSuitcase,
  StyleUserInfoSuitcase,
  AccessDropdown,
} from "component/Members/includes/addMember.style";
import { COLORS } from "component/UI/common";

const orgAccessTypes = [
  { key: 1, text: "No access", value: "no_access" },
  { key: 2, text: "Read only", value: "read_only" },
  { key: 3, text: "Full access", value: "full_access" },
  { key: 4, text: "Custom", value: "custom" },
];

export const OrgAccessRow = ({ index, org, orgType, value, onChange, editable }) => {
  const orgName = org.name ?? org.partner_group_name;

  return (
    <StyleTileSuitcase key={index} firstItem>
      <StyleUserInfoSuitcase>
        <div style={{ marginRight: "20px" }}>
          <Avatar name={orgName} image={org.logo ?? org.partner_group_logo ?? ""} />
        </div>
        <div>
          <StyleNameSuitcase style={{ color: `${COLORS.indigo600}` }}>
            <span>{org.name ?? org.partner_group_name}</span>
          </StyleNameSuitcase>
          <StyleOrganisation>{orgType} organisation</StyleOrganisation>
        </div>
      </StyleUserInfoSuitcase>

      {editable ? (
        <AccessDropdown className="org-access" selectOnBlur={false} selection options={orgAccessTypes} value={value} onChange={onChange} />
      ) : (
        <div className="d-flex align-items-center">
          <Popup
            size="tiny"
            wide={true}
            content="Access inherited from parent suitcases"
            position="left center"
            trigger={<Icon name="info circle" color="grey" className="mr-2" />}
          />
          <AccessDropdown
            className="org-access"
            selectOnBlur={false}
            selection
            options={orgAccessTypes}
            value={value}
            onChange={onChange}
            disabled={!editable}
          />
        </div>
      )}
    </StyleTileSuitcase>
  );
};
