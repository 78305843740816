import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { Avatar } from "component/Avatar";
import { Comment } from "./Comment";
import { CommentMentions } from "./CommentMentions";
import { COLORS } from "component/UI/common";
import { ConversationsSideBarContentWrapper, TextButton } from "./Collaborate.style";
import { FilterOption } from "./components";

interface Props {
  dashboardID: number;
  dashboardUsers: any[];
  filter: FilterOption;
  onReply: (message: string, replyTo: number) => Promise<boolean>;
  store?: Store;
}

const Component = (props: Props): JSX.Element => {
  const { dashboardID, dashboardUsers, filter, onReply, store } = props;
  const { user, dashboard: dashboardStore } = store!;
  const [unfoldMessageID, setUnfoldMessageID] = React.useState<number | null>(null);
  const [replyMessageID, setReplyMessageID] = React.useState<number | null>(null);

  const comments = dashboardStore.dashboardComments;
  const parentComments = comments
    ?.filter((comment) => {
      const isParentComment = comment.reply_to === null;
      if (filter === "unresolved") {
        return !comment.resolved && isParentComment;
      } else if (filter === "resolved") {
        return comment.resolved && isParentComment;
      } else {
        return isParentComment;
      }
    })
    .reverse();

  return (
    <>
      {parentComments.length === 0 ? (
        <>
          {filter === "resolved" && comments.length !== 0 ? (
            <p className="fs-1000">No archived comments.</p>
          ) : (
            <div className="bg-white rounded-3 p-4" style={{ border: `2px dashed ${COLORS.grey400}` }}>
              <p className="fs-1000 fw-700 mb-2" style={{ color: COLORS.grey800 }}>
                Start a Conversation
              </p>
              <p className="fs-1000" style={{ color: COLORS.grey800 }}>
                A collaborative space to leave dashboard observations and questions. Tag others, reply in threads and archive resolved
                comments through this new feature. Comments are visible to all dashboard members.
              </p>
              <img src="/assets/conversation_image.png" alt="Conversation Image" />
            </div>
          )}
        </>
      ) : (
        <div className="overflow-hidden">
          <ConversationsSideBarContentWrapper>
            {parentComments.map((comment) => {
              const { id, user_id } = comment;
              const childComments = comments?.filter((comment) => comment.reply_to === id);
              return (
                <div key={id} className="bg-white rounded-3 p-3 mb-3">
                  <Comment
                    dashboardID={dashboardID}
                    comment={comment}
                    showResolveIcon
                    commentWriter={user_id === user!.id}
                    dashboardUsers={dashboardUsers}
                  />
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      {unfoldMessageID !== id && childComments.length > 0 && (
                        <>
                          <div className="mr-2" style={{ height: 1, width: 25, backgroundColor: COLORS.grey400 }}></div>
                          <TextButton
                            color={COLORS.grey600}
                            hoverColor={COLORS.grey800}
                            onClick={() => {
                              setUnfoldMessageID(id as number);
                              setReplyMessageID(id as number);
                            }}
                          >
                            {`View ${childComments.length} replies`}
                          </TextButton>
                        </>
                      )}
                    </div>
                    {unfoldMessageID !== id && replyMessageID !== id && (
                      <TextButton
                        color={COLORS.grey600}
                        hoverColor={COLORS.grey800}
                        onClick={() => {
                          setUnfoldMessageID(id as number);
                          setReplyMessageID(id as number);
                        }}
                      >
                        Reply
                      </TextButton>
                    )}
                  </div>
                  {unfoldMessageID === id && (
                    <div>
                      {childComments.length > 0 && (
                        <div className="d-flex align-items-center mb-3">
                          <div className="bg-grey mr-2" style={{ height: 1, width: 25 }}></div>
                          <TextButton
                            color={COLORS.grey600}
                            hoverColor={COLORS.grey800}
                            onClick={() => {
                              setUnfoldMessageID(null);
                              setReplyMessageID(null);
                            }}
                          >
                            {`Hide ${childComments.length} replies`}
                          </TextButton>
                          <div className="bg-grey ml-2 flex-grow-1" style={{ height: 1, width: 30 }}></div>
                        </div>
                      )}
                      {childComments.map((childComment) => {
                        const { id: childCommentID, user_id: childCommentUserID } = childComment;
                        return (
                          <Comment
                            key={childCommentID}
                            dashboardID={dashboardID}
                            comment={childComment}
                            commentWriter={childCommentUserID === user!.id}
                            dashboardUsers={dashboardUsers}
                          />
                        );
                      })}
                    </div>
                  )}
                  {replyMessageID === id && (
                    <div className="d-flex align-items-start w-100 mt-3">
                      <Avatar
                        className="cursor-pointer mr-2"
                        color={COLORS.indigo500}
                        image={user!.avatar}
                        name={`${user!.first_name} ${user!.last_name}`}
                        size={25}
                      />
                      <CommentMentions autoFocus mentionUsers={dashboardUsers} onSave={(message) => onReply(message, id as number)} />
                    </div>
                  )}
                </div>
              );
            })}
          </ConversationsSideBarContentWrapper>
        </div>
      )}
    </>
  );
};

export const Comments = inject("store")(observer(Component));
