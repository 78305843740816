import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import { Avatar } from "component/Avatar";
import { DraftInfo, DraftLink, DraftName, AccessPopup, StackedGridStyle } from "./SuitcasesElement";
import { StyleBox, StyleBoxContainer, StyleBoxHeading, StyleBoxInfo, StyleBoxText, StyleBoxTextSmall } from "./SuitcasesGrid.style";
import { IListProject } from "common/store/suitcase";

interface ISuitcasesGrid {
  suitcases: any;
  draft?: any;
  showAccess?: boolean;
  suitcaseClickCallback?: () => void;
}

export const SuitcasesGrid = ({ draft, suitcases, showAccess, suitcaseClickCallback }: ISuitcasesGrid): JSX.Element => {
  const history = useHistory();

  const handleBoxClick = (id: number) => {
    suitcaseClickCallback && suitcaseClickCallback();
    history.push(`/suitcases/${id}`);
  };

  const handleNameClick = (e) => {
    suitcaseClickCallback && suitcaseClickCallback();
    e.stopPropagation();
  };

  return (
    <StyleBoxContainer>
      {draft &&
        draft.map((suitcase: IListProject, index: number) => {
          const { id, name } = suitcase;
          return (
            <StyleBox key={index} onClick={() => handleBoxClick(id)} highlight="true">
              <div>
                <StyleBoxHeading>
                  <DraftName name={name} />
                </StyleBoxHeading>
                <DraftInfo />
              </div>
              <DraftLink />
            </StyleBox>
          );
        })}
      {suitcases.length > 0 &&
        suitcases.map((suitcase: IListProject, index: number) => {
          const {
            id,
            name,
            updated_at,
            owner: ownerData,
            number_insights_recursive: insights,
            user_access: access,
            highlight,
            number_child_suitcases: subSuitcases = 0,
          } = suitcase;
          const owner = ownerData?.first_name ? `${ownerData.first_name} ${ownerData.last_name}` : undefined;
          const { avatar } = ownerData || {};
          return (
            <StyleBox
              key={index}
              onClick={() => handleBoxClick(id)}
              // Show purple outline when the Pre-packed suitcase is highlighted
              highlight={highlight ? highlight.toString() : "false"}
            >
              <div>
                <StyleBoxHeading>
                  <Link to={`/suitcases/${id}`} className="text-secondary" onClick={(e) => handleNameClick(e)}>
                    {name}
                  </Link>
                </StyleBoxHeading>
                {subSuitcases > 0 && <StyleBoxText>{`${subSuitcases} suitcases |`}</StyleBoxText>}
                <StyleBoxText>{`${insights} insights`}</StyleBoxText>
              </div>
              <StyleBoxInfo>
                <div className="d-flex align-items-center">
                  {/* Hide Owner column for Private Suitcases tab */}
                  {!draft && owner && (
                    <div className="mr-2">
                      <Avatar name={owner} image={avatar ? avatar : ""} />
                    </div>
                  )}
                  {updated_at && <StyleBoxTextSmall>{DateTime.fromISO(updated_at).setZone("local").toFormat("DD")}</StyleBoxTextSmall>}
                </div>
                {/* Show Access column for Shared Suitcases tab */}
                {showAccess && <AccessPopup access={access} />}
              </StyleBoxInfo>
              {subSuitcases > 0 && <StackedGridStyle />}
            </StyleBox>
          );
        })}
    </StyleBoxContainer>
  );
};
