import { action, observable } from "mobx";
import Store from "../store";
import { database, datasets } from "common/api";
import { ObjectAny } from "common/helpers/types";
import { backendUrl, environment } from "common/constants";
import { ls } from "common/helpers/storage";
import { sortDimensionValues, transformResToDims } from "common/helpers/data";
import { URLUpload } from "common/store/dataset/urlUpload";
import { DATA_SOURCE_STEPS } from "pages/DatasetTemplates/includes/NewDataSourceModal";
import { SSDCConfig } from "pages/DatasetDataUpload/includes/helpers";

interface IUpdateHistory {
  id: number;
  dataset_template_id: number;
  name: string;
  email: string;
  datetime: string;
}

export interface IContributor {
  id?: number;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  dataset_template_id?: number;
  reminder_start_date: string | null;
  reminder_interval: string | null;
  sent_history?: string[] | null;
  send_welcome_email?: boolean;
}

interface ITemplate extends IEmailMessages {
  id: number;
  name: string;
  dataset_id: number;
  url: string;
  contributor_link: string;
  update_history: IUpdateHistory[] | [];
  deleted_at: string | null;
  contributors: IContributor[];
  tables: TemplateTable[];
}

interface TemplateTable {
  id: number;
  dataset_template_id: number;
  deleted_at: string | null;
  name: string;
  schema: { aggregations: any[]; columns: ObjectAny[]; rows: ObjectAny[] };
}

export interface IDataset extends IEmailMessages {
  id: number;
  name: string;
  link: string;
  templates: ITemplate[];
}

interface ISingleDataset {
  id: number;
  name: string;
  ssdc_config: SSDCConfig;
  updated_at: null | string;
  published: boolean;
  dataset_status_id: null | number;
  preprocessed: boolean;
}

interface IEmailMessages {
  contributor_welcome_message: string | null;
  contributor_reminder_message: string | null;
  contributor_thank_you_message: string | null;
}

// key-value store for latest data found for dataset/template/table, cache key, value is an object with variables as properties and categories as values
type LatestData = Record<string, ObjectAny>;

interface IDataSourceStep {
  content: React.FunctionComponent<any>;
}

export default class Dataset {
  @observable datasets: IDataset[] | null = null;
  @observable currentDataset: ISingleDataset | null = null;
  @observable info = {
    // Info for saveInsight => Save Insight / Export Insight / Move Insight
    show: false,
    iName: "",
    iSubheading: "",
    sName: "",
    sDescription: "",
    groupSelected: -1,
    loading: false,
  };
  @observable latestData: LatestData = {};
  @observable newDataModalStep: IDataSourceStep = DATA_SOURCE_STEPS.dataSourceSelection; // @TODO - revisit this - why are components living inside a mobx store???
  @observable showCreateTemplateModal: boolean = false;
  @observable showSessionExpired: boolean = false;
  @observable datasetStatusLabels: { id: number; label: "queued" | "ingesting" }[] | null = null;

  parent: Store;
  urlupload = new URLUpload(this);

  constructor(parent: Store) {
    this.parent = parent;
  }

  @action setShowCreateTemplateModal(show: boolean) {
    this.showCreateTemplateModal = show;
  }

  @action async getLatestData(type: "dataset" | "template" | "table", input: ObjectAny): Promise<Record<string, string>> {
    const cacheKey = `${environment === "production" ? "" : `${environment}/`}latestData/${type}/${input.id}`;
    // check if set
    if (this.latestData[cacheKey]) {
      return this.latestData[cacheKey];
    }
    // check if in ls
    const lsValue = ls.getItem(cacheKey);
    if (lsValue) {
      const data = JSON.parse(lsValue);
      this.latestData[cacheKey] = data;
      return data;
    }
    const cacheExpiry = Date.now() + (1000 * 60 * 60); // expire in 1 hour for now
    // compile fresh data
    const data = {};
    // generic handler for getting the latest time variable data for all SSDC levels [dataset | template | table]
    const updateData = async (data: Record<string, string>, datasetKey: string, baseFilters: ObjectAny) => {
      const res: any = await datasets.post("v2/qs", { datasets: [datasetKey], filters: { ...baseFilters } }, this.parent.token || "");
      const whens = Object.entries(transformResToDims(res)).filter((entry: any) => entry[1].type === "When").map(entry => entry[0]);
      for (const when of whens) {
        const res: any = await datasets.post("v2/qs", { datasets: [datasetKey], filters: { ...baseFilters, [when]: [] } }, this.parent.token || "");
        let dims = transformResToDims(res);
        dims = { [when]: dims[when] }; // avoid sorting all returned dims, just the ones we want
        dims = sortDimensionValues(dims);
        data[when] = dims[when].values.slice(-1)[0]?.name || "";
      }
    };
    if (type === "dataset") {
      await updateData(data, input.key, {});
    } else if (type === "template") {
      const templateVariable = input.dataset.key === "maranguka" ? "Data Source" : "Template"; // "maranguka" uses "Data Source" instead of "Template", non SSDC dataset
      await updateData(data, input.dataset.key, { [templateVariable]: [input.name] });
    } else {
      await updateData(data, input.dataset.key, { "Table": [input.name] });
    }
    ls.setItem(cacheKey, JSON.stringify(data), cacheExpiry);
    this.latestData[cacheKey] = data;
    return data;
  }

  @action async getDatasets(): Promise<void> {
    const res: any = await database.get("dataset-templates", "", this.parent.token!);

    if (res.body.data) {
      // Remove history record created by Seer Team Members from Update History
      const filteredDatasets = res.body.data.datasets.map(dataset => {
        const filteredTemplates = dataset.templates.map(template => {
          const filteredUpdateHistory = template.update_history.filter(history => !history.email.includes("@seerdata.com.au"));
          return {...template, update_history: filteredUpdateHistory };
        });
        return {...dataset, templates: filteredTemplates};
      });
      this.datasets = filteredDatasets;
    }
  }

  @action async getDataset(datasetId: number): Promise<void> {
    const res: any = await database.get(`datasets/${datasetId}`, "", this.parent.token!);
    if (res?.body?.data) {
      this.currentDataset = res.body.data[0];
    }
  }

  @action async getDatasetStatusLabels(): Promise<void> {
    const res: any = await database.get("datasets/dataset-status", null);
    if (res?.body?.data?.dataset_status) {
      this.datasetStatusLabels = res.body.data.dataset_status;
    }
  }

  queueIngestion = async (datasetID: number): Promise<boolean | void> => {
    const findQueueLabel = this.datasetStatusLabels?.find(s => s.label === "queued");
    if (findQueueLabel) {
      const res: any = await database.post(`datasets/${datasetID}`, {
        dataset_status_id: findQueueLabel.id,
      }, this.parent.token!);
      return res.statusCode === 200;
    }
  };

  @action async createDataset(name: string): Promise<void | number> {
    const res: any = await database.post("datasets", { name }, this.parent.token!);
    if (res.body.data) {
      // Get updated datasets
      await this.getDatasets();
      return res.body.data.new_dataset.id;
    }
  }

  deleteDataset = async (datasetID: number): Promise<boolean> => {
    const res: any = await database.delete(`datasets/${datasetID}`, "", this.parent.token!);
    return res?.statusCode === 200;
  };

  @action async updateDatasetEmailMessages(dataset_id: number, messages: IEmailMessages): Promise<void> {
    const { contributor_welcome_message, contributor_reminder_message, contributor_thank_you_message } = messages;
    await database.post(`datasets/${dataset_id}`, {
      contributor_welcome_message,
      contributor_reminder_message,
      contributor_thank_you_message,
    }, this.parent.token!);

    // Get updated datasets
    this.getDatasets();
  }

  @action async createTemplate(name: string, dataset_id: number): Promise<void | number> {
    const res: any = await database.post("dataset-templates", { name, dataset_id }, this.parent.token!);
    if (res.body.data) {
      // Get updated datasets
      await this.getDatasets();
      return res.body.data.new_template.id;
    }
  }

  deleteTemplate = async (templateID: number): Promise<boolean> => {
    const res: any = await database.delete(`dataset-templates/${templateID}`, "", this.parent.token!);
    return res?.statusCode === 200;
  };

  @action async duplicateTemplate(templateID: number): Promise<void | number> {
    const res: any = await database.post(`dataset-templates/${templateID}/duplicate`, {}, this.parent.token!);
    if (res.body.data) {
      // Get updated datasets
      await this.getDatasets();
      return res.body.data.duplicated_templates[0].id;
    }
  }

  updateTemplate = async (templateID: number, payload: ObjectAny): Promise<boolean> => {
    const res: any = await database.post(`dataset-templates/${templateID}`, payload, this.parent.token!);
    return res?.statusCode === 200;
  };

  @action async addTemplateContributor(template_id: number, contributor: IContributor): Promise<void> {
    const { first_name, last_name, email, reminder_start_date, reminder_interval, send_welcome_email } = contributor;
    const res: any = await database.post(`dataset-templates/${template_id}/contributors`, {
      first_name,
      last_name,
      email,
      reminder_start_date,
      reminder_interval,
      send_welcome_email,
    }, this.parent.token!);

    if (!res.body.error) {
      this.getDatasets();
    }

    return res;
  }

  @action async updateTemplateContributor(contributor: IContributor): Promise<void> {
    const { id, first_name, last_name, email, reminder_start_date, reminder_interval } = contributor;
    const res: any = await database.put(`dataset-template-contributors/${id}`, {
      first_name,
      last_name,
      email,
      reminder_start_date,
      reminder_interval,
    }, this.parent.token!);

    if (!res.body.error) {
      this.getDatasets();
    }

    return res;
  }

  @action async deleteTemplateContributor(contributorID: number): Promise<void> {
    await database.delete(`dataset-template-contributors/${contributorID}`, {}, this.parent.token!);

    // Get updated datasets
    this.getDatasets();
  }

  @action setShowSessionExpired(value: boolean): void {
    this.showSessionExpired = value;
  }

  @action setNewDataModalStep(step: IDataSourceStep) {
    this.newDataModalStep = step;
  }

  getDatasetTemplateTable = async (id: string | number): Promise<any> => {
    const res: any = await database.get(`dataset-template-table/${id}`, "", this.parent.token!);
    return res?.body?.data;
  };

  createDatasetTemplateTable = async (body: ObjectAny): Promise<boolean> => {
    const res: any = await database.post("dataset-template-table", body, this.parent.token!);
    return res?.statusCode === 200;
  };

  updateDatasetTemplateTable = async (id: number, body: ObjectAny): Promise<boolean> => {
    const res: any = await database.put(`dataset-template-table/${id}`, body, this.parent.token!);
    return res?.statusCode === 200;
  };

  duplicateDatasetTemplateTable = async (tableID: number, templateID: number): Promise<boolean> => {
    const res: any = await database.post(`dataset-template-table/${tableID}/duplicate`, { dataset_template_id: templateID }, this.parent.token!);
    return res?.statusCode === 200;
  };

  moveDatasetTemplateTable = async (tableID: number, templateID: number): Promise<boolean> => {
    const res: any = await database.put(`dataset-template-table/${tableID}/move`, { dataset_template_id: templateID }, this.parent.token!);
    return res?.statusCode === 200;
  };

  deleteDatasetTemplateTable = async (id: number): Promise<boolean> => {
    const res: any = await database.delete(`dataset-template-table/${id}`, "", this.parent.token!);
    return res?.statusCode === 200;
  };

  // manually send contributor email reminders
  sendReminders = async (ids: number[]): Promise<boolean> => {
    const res: any = await database.post("dataset-templates/send-contributor-reminder", { ids }, this.parent.token!);
    return res?.statusCode === 200;
  };

  contributorEmailCheck = async (email: string): Promise<boolean> => {
    const res: any = await database.post("dataset-contributors/email-check", { email });
    return res?.statusCode === 200;
  };

  contributorLogin = async (email: string, verification_code: string): Promise<boolean> => {
    const res: any = await database.post("dataset-contributors/login", { email, verification_code });
    const token = res?.body?.data?.contributor_token;
    if (token) {
      this.parent.logInContributor(token);
    }
    return res?.statusCode === 200;
  };

  contributorDatasetTemplateGet = async (id: string | number): Promise<any> => {
    const res: any = await database.get(`dataset-contributors/dataset-template/${id}`, "", this.parent.contributor_token!);
    const template = res?.body?.data?.template;
    let error: null | string = null;
    if (!template) {
      if (res?.statusCode === 401 && res?.body?.error?.type === "JWT Exception") {
        this.setShowSessionExpired(true);
        this.parent.logOutContributor();
      } else {
        error = res?.statusCode === 401 ? "You are not authorised to view this template." : "Error loading data for template.";
      }
    }
    return { template, error };
  };

  contributorDatasetTemplateTableGet = async (id: string | number): Promise<any> => {
    const res: any = await database.get(`dataset-contributors/dataset-template-table/${id}`, "", this.parent.contributor_token!);
    const dataset_template_table = res?.body?.data?.template_table;
    let error: null | string = null;
    if (!dataset_template_table) {
      if (res?.statusCode === 401 && res?.body?.error?.type === "JWT Exception") {
        this.setShowSessionExpired(true);
        this.parent.logOutContributor();
      } else {
        error = res?.statusCode === 401 ? "You are not authorised to view this table." : "Error loading data for template.";
      }
    }
    return { dataset_template_table, error };
  };

  contributorDatasetTemplateTableUpdate = async (id: number, body: ObjectAny): Promise<boolean> => {
    const res: any = await database.put(`dataset-contributors/dataset-template-table/${id}`, body, this.parent.contributor_token!);
    return res?.statusCode === 200;
  };

  getDatasetAccess = async (datasetID: number): Promise<any> => {
    const res: any = await database.get(`dataset-access/${datasetID}`, "", this.parent.token!);
    if (res.body) {
      return res.body.data.datasetAccess;
    }
  };

  addDatasetGroupAccess = async (datasetID: number, groupID: number, accessLimit: any): Promise<boolean> => {
    const res: any = await database.post("dataset-access/group", {
      dataset_id: datasetID,
      group_id: groupID,
      access_limit: accessLimit,
    }, this.parent.token!);
    return res?.statusCode === 200;
  };

  updateDatasetGroupAccess = async (datasetGroupID: number, datasetID: number, accessLimit: any): Promise<boolean> => {
    const res: any = await database.put(`dataset-access/group/${datasetGroupID}`, {
      dataset_id: datasetID,
      access_limit: accessLimit,
    }, this.parent.token!);
    return res?.statusCode === 200;
  };

  deleteDatasetGroupAccess = async (datasetGroupID: number, datasetID: number): Promise<boolean> => {
    const res: any = await fetch(`${backendUrl}/dataset-access/group/${datasetGroupID}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-Token": this.parent.token! || "-none-",
      },
      body: JSON.stringify({ dataset_id: datasetID }),
    });
    return res?.ok;
  };

  addDatasetUserAccess = async (datasetID: number, userID: number, accessLimit: any): Promise<boolean> => {
    const res: any = await database.post("dataset-access/user", {
      dataset_id: datasetID,
      user_id: userID,
      access_limit: accessLimit,
      admin: false,
    }, this.parent.token!);
    return res?.statusCode === 200;
  };

  updateDatasetUserAccess = async (datasetUserID: number, datasetID: number, accessLimit: any): Promise<boolean> => {
    const res: any = await database.put(`dataset-access/user/${datasetUserID}`, {
      dataset_id: datasetID,
      access_limit: accessLimit,
    }, this.parent.token!);
    return res?.statusCode === 200;
  };

  deleteDatasetUserAccess = async (datasetUserID: number, datasetID: number): Promise<boolean> => {
    const res: any = await fetch(`${backendUrl}/dataset-access/user/${datasetUserID}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-Token": this.parent.token! || "-none-",
      },
      body: JSON.stringify({ dataset_id: datasetID }),
    });
    return res?.ok;
  };
}
