import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { Icon, Form, Popup, Message, Table, Modal, ModalActions, ModalHeader, ModalContent, Button } from "semantic-ui-react";
import { ActionsList } from "./ActionsList";
import { UploadMode } from "common/store/dataset/urlUpload";
import { useHistory } from "react-router-dom";

interface Props {
  store?: Store;
}

interface ErrorProps {
  error: {
    type: string;
    message?: string;
    meta?: { column: string; message: string }[];
    validationErrors?: Record<string, Record<number, string>>;
  };
}

const Error = (props: ErrorProps) => {
  const { error } = props;
  const getHeadingLabel = (type: string) => {
    if (type === "parsing") {
      return "Parsing error";
    } else if (type === "column_mismatch") {
      return "Column mismatch error";
    } else if (type === "upload") {
      return "Parsing error";
    } else {
      return "Error";
    }
  };
  if (error.type === "column_validation") {
    return <ColumnValidationError error={error.validationErrors} />;
  }
  return (
    <Message negative>
      <Message.Header>{getHeadingLabel(error.type)}</Message.Header>
      <p>{error.message}</p>
      {error.type === "column_mismatch" && <p>You can override this in overwrite mode. Use at your discretion.</p>}
      {error.meta && (
        <Popup
          trigger={
            <p>
              <Icon size="small" name="info circle" /> Hover here for more info
            </p>
          }
          content={
            <Table compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Column</Table.HeaderCell>
                  <Table.HeaderCell>Error</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {error.meta.map((item, idx) => (
                  <Table.Row key={`${item.column}-${idx}`}>
                    <Table.Cell>{item.column}</Table.Cell>
                    <Table.Cell>{item.message}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          }
        />
      )}
    </Message>
  );
};

const ColumnValidationError = ({ error }) => {
  const [showModal, setShowModal] = React.useState(false);
  const errorLines = Object.keys(error).reduce((acc, cur) => acc + Object.keys(error[cur]).length, 0);
  if (errorLines <= 5) {
    let message = "Please check the uploaded file. Validation failed for:";
    Object.keys(error).forEach((col) => {
      message = message.concat(` "${col}" on lines [${Object.keys(error[col]).join(",")}];`);
    });
    alert(message);
    return <></>;
  }
  return (
    <div className="my-4">
      <p className="text-primary fs-1200 mt-0 mb-2">Please check the uploaded file. Validation failed for many lines.</p>
      <Modal
        onClose={() => setShowModal(false)}
        open={showModal}
        trigger={
          <p className="cursor-pointer text-primary fw-700 my-0" onClick={() => setShowModal(true)}>
            See details
          </p>
        }
      >
        <ModalHeader>Validation errors</ModalHeader>
        <ModalContent>
          <Table compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Line Number</Table.HeaderCell>
                <Table.HeaderCell>Column Name</Table.HeaderCell>
                <Table.HeaderCell>Error</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.keys(error).map((col, cIdx) =>
                Object.keys(error[col]).map((row, rIdx) => (
                  <Table.Row key={`${cIdx}-${rIdx}`}>
                    <Table.Cell>{`Line ${row}`}</Table.Cell>
                    <Table.Cell>{col}</Table.Cell>
                    <Table.Cell>{error[col][row]}</Table.Cell>
                  </Table.Row>
                )),
              )}
            </Table.Body>
          </Table>
        </ModalContent>
        <ModalActions>
          <Button onClick={() => setShowModal(false)}>OK</Button>
        </ModalActions>
      </Modal>
    </div>
  );
};

const Component = (props: Props): JSX.Element => {
  const store = props.store!;
  const { urlupload } = store.dataset;
  const { existingData } = urlupload;
  const [uploadMode, setUploadMode] = React.useState<UploadMode>(null);
  const [userVerified, setUserVerified] = React.useState<boolean | undefined>(false);
  const [error, setError] = React.useState<any>(undefined);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [forceUpload, setForceUpload] = React.useState<boolean>(false);
  const history = useHistory();

  React.useEffect(() => {
    if (!existingData) {
      setUploadMode("overwrite");
    }
  }, [existingData]);
  React.useEffect(() => {
    if (uploadMode !== "overwrite") {
      setForceUpload(false);
    }
  }, [uploadMode]);

  return (
    <>
      <ActionsList actions={urlupload.transformOps} />
      <Form onSubmit={(e) => e.preventDefault()}>
        <Form.Checkbox
          className="mt-3"
          checked={userVerified}
          onChange={(_, data) => setUserVerified(data.checked)}
          label="I have verified that the data table on the left contains no personally identifiable information, or that I have suitably applied protections to it."
        />
        {existingData && (
          <>
            <label className="fw-bold">Select an upload method</label>
            <Form.Group>
              <Form.Field>
                <Form.Radio
                  radio
                  label={
                    <label>
                      <Popup trigger={<span>Append</span>} content="This will append your upload to the existing data" />
                    </label>
                  }
                  name="uploadMode"
                  value="append"
                  checked={uploadMode === "append"}
                  onChange={(_, data) => setUploadMode(data.value as UploadMode)}
                />
              </Form.Field>
              <Form.Field>
                <Form.Radio
                  radio
                  label={
                    <label>
                      <Popup trigger={<span>Overwrite</span>} content="This will overwrite the existing data with your upload" />
                    </label>
                  }
                  name="uploadMode"
                  value="overwrite"
                  checked={uploadMode === "overwrite"}
                  onChange={(_, data) => setUploadMode(data.value as UploadMode)}
                />
              </Form.Field>
            </Form.Group>
          </>
        )}
        {error && (
          <>
            {uploadMode === "overwrite" && error.type !== "column_validation" && (
              <Form.Checkbox
                checked={forceUpload}
                onChange={(_, data) => {
                  const nextVal = !!data.checked;
                  const ok =
                    !nextVal ||
                    confirm(
                      "WARNING: This action will overwrite all existing data with the new format you're uploading. Are you sure you want to proceed?",
                    );
                  if (ok) {
                    setForceUpload(!!data.checked);
                  }
                }}
                label={
                  <label className="text-danger">
                    Force upload <Icon name="warning sign" />
                  </label>
                }
              />
            )}
            <Error error={error} />
          </>
        )}
        <Button
          disabled={!uploadMode || !userVerified || uploading}
          icon
          labelPosition="left"
          color="purple"
          onClick={async () => {
            setError(undefined);
            setUploading(true);
            const error = await urlupload.processUpload(uploadMode, forceUpload);
            setUploading(false);
            if (error) {
              setError(error);
            } else {
              alert(
                "Your data is on its way! Please check back in a few minutes to manage your uploaded data. You will now be redirected to your list of datasets.",
              );
              history.push("/datasets");
            }
          }}
          fluid
        >
          <Icon name="upload" />
          Upload Data
        </Button>
      </Form>
    </>
  );
};

export const ConfigReview = inject("store")(observer(Component));
