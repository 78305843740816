import * as React from "react";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { useLeafletContext } from "@react-leaflet/core";
import { SearchControlOptions } from "leaflet-geosearch/lib/SearchControl";

interface GeoSearchProps extends SearchControlOptions {
  provider: any; // allow null value to be passed through to default to OpenStreetMapProvider provider
}

export const GeoSearch = (props: GeoSearchProps): null => {
  const { provider, ...rest } = props;
  const context = useLeafletContext();
  const { map } = context;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - this is needed, see the docs
  const searchControl = new GeoSearchControl({
    provider: provider || new OpenStreetMapProvider(), // defaults to open street map
    ...rest,
  });

  React.useEffect(() => {
    map.addControl(searchControl);
    return () => {
      map.removeControl(searchControl);
    };
  }, []);
  return null;
};
