import Store from "common/store";
import { Builder, IFilter } from "common/store/builder";
import { Heading } from "common/styledComponents/elements";
import { COLORS } from "component/UI/common";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Function to help with reordering the result
const reorder = (list: IFilter[], startIndex, endIndex): IFilter[] => {
  const deepCopy = Array.from(list);
  const [removed] = deepCopy.splice(startIndex, 1);
  deepCopy.splice(endIndex, 0, removed);

  return deepCopy;
};

/**
 * Moves an item from one list to another list.
 */
const move = (sourceItems, destinationItems, source, destination): Record<string, IFilter[]> => {
  const sourceClone = Array.from(sourceItems);
  const destClone = Array.from(destinationItems);
  const [removed] = sourceClone.splice(source.index, 1);

  destClone.splice(destination.index, 0, removed);

  const result = {};
  result[source.droppableId] = sourceClone;
  result[destination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, isFilter?) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  background: isDragging ? COLORS.indigo600 : isFilter ? "#c3c3c3" : COLORS.red500,

  fontSize: "10px",
  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
  color: "#ffffff",
  border: 0,
  // padding: "12px 20px",
  fontWeight: "bold",
  borderRadius: "5px",
  textAlign: "center",
  outline: "none",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "#f5f5f5",
  padding: grid * 2,
  display: "flex",
  overflow: "auto",
  margin: "20px 0 20px 0",
  borderRadius: "0.3rem",
});

interface PropsType {
  store?: Store;
  builder?: Builder;
  rows?: string[];
  columns?: string[];
}

export const DraggableLists = inject((stores: any) => ({ store: stores.store!.insightbuilder, builder: stores.store!.builder }))(
  observer((props: PropsType) => {
    const getVariableOptions = (): IFilter[] => {
      const allocatedVariables = [...props.builder!.rows, ...props.builder!.columns];
      const variables = [
        ...props.builder!.filters,
        // TODO: Uncomment this once Blend is working properly (will require changes in MultiIndexTable as well)
        // { dimension: "Dataset", values: [] }
      ];
      const remainingOptions = variables.filter((v) => !allocatedVariables.find((av) => av.dimension === v.dimension));

      return remainingOptions;
    };

    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    const getList = (id) => {
      const id2List = {
        filters: getVariableOptions(),
        rows: props.builder!.rows,
        columns: props.builder!.columns,
      };
      return id2List[id];
    };

    const onDragEnd = (result) => {
      const { source, destination, draggableId } = result;

      // Dropped outside the list
      if (!destination) {
        return;
      }

      // Dropped into filters list but has multiple categories, so needs to be in either rows or columns
      if (destination.droppableId === "filters") {
        if (source.droppableId === "columns") {
          const variable = props.builder!.columns.find((v) => v.dimension === draggableId);
          if (variable && variable.values.length > 1) {
            return;
          }
        } else if (source.droppableId === "rows") {
          const variable = props.builder!.rows.find((v) => v.dimension === draggableId);
          if (variable && variable.values.length > 1) {
            return;
          }
        }
      }

      const sourceItems = getList(source.droppableId);
      const destinationItems = getList(destination.droppableId);

      // REORDER
      if (source.droppableId === destination.droppableId) {
        const items: IFilter[] = reorder(sourceItems, source.index, destination.index);

        if (source.droppableId === "columns") {
          props.builder!.setColumns(items);
        } else if (source.droppableId === "rows") {
          props.builder!.setRows(items);
        }
      } else {
        // MOVE
        const result: Record<string, IFilter[]> = move(sourceItems, destinationItems, source, destination);

        result.rows && props.builder!.setRows(result.rows);
        result.columns && props.builder!.setColumns(result.columns);
        result.filters && props.builder!.setFilters(result.filters);
        // refresh dynamic queries when dimensions are moved to ensure they are set in the right location
        props.builder?.refreshDynamicQueries();
      }
    };

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Heading className="fs-1125 my-0">Columns</Heading>
        {props.builder!.tables.length === 1 && (
          <p className="fs-0875">Any table sections you add later will need to have the same columns as your first table.</p>
        )}
        <Droppable droppableId="columns" direction="horizontal">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {props.builder!.columns.map((item, index) => (
                <Draggable key={item.dimension} draggableId={item.dimension} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, item.values.length === 1)}
                    >
                      {item.dimension}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        <Heading className="fs-1125 my-0">Rows</Heading>
        {props.builder!.activeTableId ? (
          <Droppable droppableId="rows" direction="horizontal">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {props.builder!.rows.map((item, index) => (
                  <Draggable key={item.dimension} draggableId={item.dimension} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, item.values.length === 1)}
                      >
                        {item.dimension}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ) : (
          <div className="mb-3">
            <p>You need to select a table section to adjust the row index.</p>
          </div>
        )}

        <Heading className="fs-1125 my-0" style={{ clear: "both" }}>
          Filters
        </Heading>
        <p className="fs-0875">
          Filters are items that are not neccessary in the table, but can be dragged into columns or rows if you wish to display it.
        </p>
        <Droppable droppableId="filters" direction="horizontal">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {getVariableOptions().map((item, index) => (
                <Draggable key={item.dimension} draggableId={item.dimension} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, true)}
                    >
                      {item.dimension}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }),
);
