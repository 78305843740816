import * as React from "react";
import { Icon, Popup } from "semantic-ui-react";

interface IColorCircle {
  color: string;
  colorIndex: number;
  showIndex?: boolean;
  selectable?: boolean;
  handleCircleOnClick?: (() => void) | null;
  handleCrossOnClick?: (() => void) | null;
  handleEditOnClick?: (() => void) | null;
  disableTooltip?: boolean;
  showOutline?: boolean;
}

export const ColorCircle = ({
  color,
  colorIndex,
  showIndex = false,
  selectable = false,
  handleCircleOnClick,
  handleCrossOnClick,
  handleEditOnClick,
  disableTooltip,
  showOutline,
}: IColorCircle): JSX.Element => (
  <div className="position-relative" key={`${color}-${colorIndex}`}>
    {handleCrossOnClick && (
      <span className="position-absolute" style={{ top: "-4px", right: "4px" }}>
        <Icon onClick={handleCrossOnClick} circular name="close" color="grey" size="tiny" className="bg-white cursor-pointer" />
        <span className="sr-only" onClick={handleCrossOnClick}>
          {`Remove colour - ${color}`}
        </span>
      </span>
    )}
    {handleEditOnClick && (
      <span className="position-absolute" style={{ bottom: "-4px", right: "4px" }}>
        <Icon onClick={handleEditOnClick} circular name="edit" color="grey" size="tiny" className="bg-white cursor-pointer" />
        <span className="sr-only" onClick={handleEditOnClick}>
          {`Edit colour - ${color}`}
        </span>
      </span>
    )}
    <Popup
      // disabled={disableTooltip || false} // need to upgrade version to use
      className={disableTooltip ? "d-none" : ""} // workaround because disabled not available in semantic v0.84.0
      content={color}
      position="top center"
      size="mini"
      trigger={
        <div
          onClick={() => handleCircleOnClick && handleCircleOnClick()}
          className={`mb-3 mr-3 rounded-circle d-flex align-items-center justify-content-center ${handleCircleOnClick ? "cursor-pointer" : ""}`}
          style={{ backgroundColor: color, height: "28px", width: "28px", border: showOutline ? "1px solid grey" : undefined }}
        >
          {showIndex && (
            <span className="fs-0875 text-white fw-600" style={{ bottom: "-4px", right: "4px" }}>
              {colorIndex + 1}
            </span>
          )}
          {selectable && (
            <span>
              <Icon name="plus" size="small" className="text-white mr-0" />
              <span className="sr-only">{`Add colour - ${color}`}</span>
            </span>
          )}
        </div>
      }
    />
  </div>
);
