/* helpers for any file related interactions that can be reused */
import { stringify } from "csv-stringify/browser/esm/sync";

// records passed from csv/excel using papaparse csv-parse etc
export type ParsedRecords = string[][];

// convert ParsedRecords to a File (text/csv) for upload
export const parsedRecordsToFile = async (records: ParsedRecords, filename: string): Promise<File> => {
  // stringify the records to a csv string
  const csvString = stringify(records);
  // encode and create file for upload
  const encoder = new TextEncoder();
  const csvEncoded = encoder.encode(csvString);
  return new File([csvEncoded], filename, { type: "text/csv" });
};
