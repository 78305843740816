// @TODO - move me to component dir or to an includes for explore etc
import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { find, includes } from "lodash";
import Select, { createFilter } from "react-select";
import { Icon, Button, Card, Label, Popup, Checkbox } from "semantic-ui-react";

import { collator, RANGE_COMPATIBLE_VARIABLES } from "common/helpers/data";
import { VTitle, StylePopup } from "component/insightBuilder/insightBuilderStyles/whereWhatWhen.style";
import { CustomDropdownIndicator } from "component/Explore/includes/CustomDropdownIndicator";
import { DropdownTitle } from "component/Explore/includes/DropdownTitle";
import { CustomMultiValueLabel } from "component/Explore/includes/CustomMultiValueLabel";
import { Builder } from "common/store/builder";
import Store from "common/store";
import { SortableSelect } from "./includes/SortableSelect";
import { customReactSelectStyles } from "common/helpers/dataset";
import { MultiValueRemove } from "./includes/StyleAndComponents";

const selectionGroupDetails = {
  What: {
    heading: "What topic are you interested in?",
    title: "Topic",
    tooltip: "Topic or category of interest (eg. education, school, employment, offence).",
  },
  How: {
    heading: "What would you like to measure?",
    title: "Quantity",
    tooltip: "The value, measured quantity or detailed data description.",
  },
  When: {
    heading: "What time period would you like to look at?",
    title: "Time",
    tooltip: "Time period availability is dependent on the update frequency of external datasets.",
  },
  Where: {
    heading: "What location would you like to analyse?",
    title: "Location",
    tooltip: "Type of location you want to filter (eg. State, SA2, LGA).",
  },
  Source: {
    heading: "What dataset are you interested in?",
    title: "Dataset",
    tooltip: "Name of the data collection",
  },
};

const defaultSort = (a, b) => collator.compare(a, b);

const getParentOptions = (builder, selectionType) => {
  if (selectionType === "Source") {
    // source logic different to dimensions / values
    return builder.datasetsToQuery.map((dataset) => {
      const foundDataset = find(builder.allDatasets, (source) => source.key === dataset);
      return {
        label: foundDataset?.name || dataset,
        value: dataset,
        isLocked: builder.tables.length > 1 && builder.columns.some((c) => "Source" === c.dimension),
      };
    });
  } else if (selectionType === "How") {
    // treated like parent but is options are actually child values of a dimension
    return (builder.dimensions["Measured quantity"]?.values || [])
      .filter((value) => value.requirement !== "excluded")
      .map((value) => ({
        key: value.name,
        label: value.name,
        value: value.name,
        data: value,
        isLocked:
          builder.tables.length > 1 && builder.columns.some((c) => c.dimension === "Measured quantity" && c.values.includes(value.name)),
      }));
  } else {
    return Object.keys(builder.dimensions)
      .sort(defaultSort)
      .filter((dimStr) => builder.dimensions[dimStr].type === selectionType && builder.dimensions[dimStr].requirement !== "excluded")
      .filter((dimStr) => isComparableDimAllowed(dimStr, selectionType, builder))
      .map((dimStr) => ({
        key: dimStr,
        label: dimStr,
        value: dimStr,
        data: builder.dimensions[dimStr],
        isLocked: builder.tables.length > 1 && builder.columns.some((c) => dimStr === c.dimension),
      }));
  }
};

// Selecting  more than one When or Where variable is only allowed if the first When/Where variable is in the columns of the table
const isComparableDimAllowed = (dimStr: string, selectionType: string, builder: Builder) => {
  if (["When", "Where"].includes(selectionType)) {
    const selectedVarsWithSameType = builder.selectedDimensionsByType[selectionType];
    if (selectedVarsWithSameType?.length > 0) {
      // if there is already another Where/When selected
      return selectedVarsWithSameType.every((v) => dimStr === v.dimension || builder.columns.some((c) => c.dimension === v.dimension));
    } else {
      return true;
    }
  } else {
    return true;
  }
};

// values / categories
const getChildOptions = (builder, dimension) =>
  (builder.dimensions[dimension]?.values || [])
    .filter((value) => value.requirement !== "excluded")
    .map((value) => ({
      key: value.name,
      label: value.name,
      value: value.name,
      data: value,
      isChild: true,
    }));

const getParentSelected = (builder, selectionType, parentOptions) => {
  const allQsFilters = builder.qsAllFilters;
  // @TODO look into whether we should be using allQsFilters here as selectors are table specific and qsAllFilters has everything across all tables meaning we have to filter options
  if (selectionType === "Source") {
    return parentOptions.filter((option) => builder.userSelectedDatasets.includes(option.value));
  } else if (selectionType === "How") {
    const selected = allQsFilters["Measured quantity"] || [];
    return selected.map((item) => find(parentOptions, (option) => option.value === item)).filter((option) => option !== undefined);
  } else {
    const selected = Object.keys(allQsFilters).filter((variable) => builder.dimensions[variable]?.type === selectionType);
    return selected.map((item) => find(parentOptions, (option) => option.value === item)).filter((option) => option !== undefined);
  }
};

const getChildSelected = (builder, dimension, childOptions) => {
  const allQsFilters = builder.qsAllFilters;
  const selected = allQsFilters[dimension] || [];
  return selected.map((item) => find(childOptions, (option) => option.value === item)).filter((s) => s !== undefined);
  // filter was needed to get around something being selected, but not available in options yet
  // TODO: Investigate the order of operations around something being added to filters and dimensions being updated
};

const prependSelectAll = (options, showSelectAll) =>
  showSelectAll ? [{ label: "Select all", value: "SEER_SELECT_ALL" }, ...options] : options;

const shouldShowSelectAll = (options, numberSelected, disallowConstrained) => {
  if (options.length > 50) {
    return false; // hard limit for selections to avoid insane query sizes
  }

  if (options.length - 1 <= numberSelected) {
    return false; // all selected or all but 1 option
  } else if (disallowConstrained) {
    return false;
  }
  // check if there is a common source amongst all options
  const sources = options.map((option) => Object.keys(option.data.source));
  const commonSources = sources[0].filter((firstOptSource) => sources.every((sourceArray) => includes(sourceArray, firstOptSource)));
  return commonSources.length !== 0;
};

// const getQueryParams = props => {
//   const { insight, suitcase } = qs.parse(props.location.search?.slice(1) || "");
//   return { insight: insight ? Number(insight) : undefined, suitcase: suitcase ? Number(suitcase) : undefined };
// };

// Check if "Where" or "When" has "comparable" variables from qs and show the helper text
const hasComparableDimes = (builder, varType: "Where" | "When") =>
  Object.keys(builder.dimensions)
    .filter((dimStr) => builder.dimensions[dimStr].type === varType)
    .some((dimStr) => builder.dimensions[dimStr].requirement === "comparable");

// Drag and drop to sort categories
const reorderCategories = (builder, dimValue) => (result) => {
  const { source, destination } = result;
  // Do nothing if drop outside of the container
  if (!destination) {
    return;
  }
  // Do nothing if the item is dropped back to its original location
  if (source.index === destination.index) {
    return;
  }
  const findDim = [...builder.columns, ...builder.rows].find((dim) => dim.dimension === dimValue);
  if (findDim) {
    const movedCat = findDim.values.splice(source.index, 1)[0];
    findDim.values.splice(destination.index, 0, movedCat);
  }
  // Update reordered dim values for all inactive tables
  for (const table of builder.tables.filter((table) => table.id !== builder.activeTableId)) {
    const findTableDim = [...table.columns, ...table.rows].find((dim) => dim.dimension === dimValue);
    if (findTableDim) {
      findTableDim.values = [...findDim.values];
    }
  }
  // If "userDefinedCharts" exists, find the sorted dim and update categories
  if (builder.userDefinedCharts && Object.keys(builder.userDefinedCharts).length) {
    const { legend, series, xAxis } = builder.userDefinedCharts;
    const findUserDefinedChartsDim =
      legend.find((dim) => dim.dimension === dimValue) ||
      series.find((dim) => dim.dimension === dimValue) ||
      xAxis.find((dim) => dim.dimension === dimValue);
    if (findUserDefinedChartsDim) {
      findUserDefinedChartsDim.values = [...findDim.values];
    }
  }
};

// Helper component for variable selection: use "SortableSelect" for "How (Measured Quantity)"; otherwise, use the standard react-select component.
const VariableSelect = ({ useSortableSelect, onDragEnd, ...props }) => {
  if (useSortableSelect) {
    return <SortableSelect onDragEnd={onDragEnd} {...props} />;
  } else {
    return <Select {...props} />;
  }
};

interface IBuilderSelector {
  store?: Store;
  selectionGroups: string[];
  forMultiStep?: boolean;
  handleSkipAction?: () => void;
  hideTitle?: boolean;
}

export const BuilderSelector = inject("store")(
  observer((props: IBuilderSelector) => {
    const { selectionGroups, forMultiStep, handleSkipAction, hideTitle } = props;
    const { builder } = props.store!;

    return (
      <div className="fs-1250">
        {selectionGroups.map((selectionType) => {
          const parentOptions = getParentOptions(builder, selectionType);
          const parentValue = getParentSelected(builder, selectionType, parentOptions);
          const isSelectionTypeHow = selectionType === "How";

          // For Multi-step Explore, skip this step if "Source" is selected
          if (forMultiStep && selectionType === "Source" && parentValue.length === 1) {
            if (handleSkipAction) {
              handleSkipAction();
            }
          }
          // For Multi-step Explore, skip this step if there is no options for "What/How/Where/When"
          if (forMultiStep && parentOptions.length === 0 && selectionType !== "Source") {
            if (handleSkipAction) {
              return handleSkipAction();
            }
            return <React.Fragment key={selectionType}></React.Fragment>;
          }

          // append select all and set disabled options as appropriate
          const showParentSelectAll = isSelectionTypeHow && shouldShowSelectAll(parentOptions, parentValue.length, false);
          const parentOptionsWithSelectAll = prependSelectAll(parentOptions, showParentSelectAll);
          return (
            <div key={selectionType} className="mb-3" style={{ position: "relative" }}>
              {!hideTitle && (
                <DropdownTitle
                  forMultiStep={forMultiStep}
                  heading={selectionGroupDetails[selectionType].heading}
                  title={selectionGroupDetails[selectionType].title}
                  tooltip={selectionGroupDetails[selectionType].tooltip}
                />
              )}
              {isSelectionTypeHow && (
                <VTitle>
                  {"Measured Quantity".toUpperCase()}{" "}
                  {builder.tables.length > 1 && builder.columns.some((c) => c.dimension === "Measured quantity") && <Icon name="lock" />}
                </VTitle>
              )}
              <VariableSelect
                useSortableSelect={isSelectionTypeHow}
                onDragEnd={isSelectionTypeHow ? reorderCategories(builder, "Measured quantity") : undefined}
                isSortDisabled={parentValue.length === 1}
                components={{ DropdownIndicator: CustomDropdownIndicator, MultiValueRemove, MultiValueLabel: CustomMultiValueLabel }}
                isClearable={parentValue.some((v) => !v.isLocked)}
                isDisabled={
                  // locations and time are comparables; you can't have SA2 in columns and then SA1 in row
                  // so you shouldn't be allowed to select another option in the table-specific edit mode
                  // similar logic applies to Measured Quantity
                  ["When", "Where", "How"].includes(selectionType)
                    ? parentValue.some((v) => v.isLocked)
                    : parentOptions.every((o) => o.isLocked)
                }
                styles={customReactSelectStyles}
                isMulti
                // TODO: Check if it's necessary to treat Source differently like below
                // isMulti={selectionType !== "Source"}
                name={selectionType}
                value={parentValue}
                placeholder="Select or search"
                options={parentOptionsWithSelectAll}
                // TODO: Check on whether we need MenuList
                // components={parentOptions.length > 100 ? { MenuList } : undefined}
                filterOption={createFilter({ ignoreAccents: false })} // improves render speed
                onChange={(_nextValue, changeData: any) => {
                  // supporting [select-option,remove-value,clear] actions
                  const { action, option, removedValue, removedValues } = changeData;
                  if (changeData.action === "select-option") {
                    if (selectionType === "Source") {
                      builder.selectDataset(option?.value);
                    } else if (isSelectionTypeHow) {
                      if (option?.value === "SEER_SELECT_ALL") {
                        builder.selectAllValues(
                          "Measured quantity",
                          parentOptions.map((opt) => opt.value),
                          parentOptions.map((opt) => Object.keys(opt.data.source)),
                        );
                      } else {
                        builder.selectValue("Measured quantity", option?.value, option?.data?.source);
                      }
                    } else {
                      if (option?.value === "SEER_SELECT_ALL") {
                        builder.selectAllDimensions(
                          parentOptions.map((opt) => opt.value),
                          parentOptions.map((opt) => Object.keys(opt.data.source)),
                        );
                      } else {
                        builder.selectDimension(option?.value, option?.data?.source);
                      }
                    }
                  } else if (action === "remove-value") {
                    if (isSelectionTypeHow) {
                      builder.deselectValue("Measured quantity", removedValue?.value);
                    } else {
                      builder.deselectDimension(removedValue?.value);
                      builder.setUserDeselectedDimensions(removedValue?.value);
                    }
                  } else if (action === "clear") {
                    if (isSelectionTypeHow) {
                      builder.deselectAllValues("Measured quantity");
                    } else {
                      builder.deselectAllDimensions(removedValues?.map((opt) => opt.value) || []);
                    }
                  }
                  // Clear customChartColors if variables changed and get colors from the table
                  builder.hasCustomChartColors && builder.setCustomChartColors([]);
                  // Reset user defined charts if variables are changed and user defined charts exists
                  builder.userDefinedChartsVariables.length > 0 && builder.setUserDefinedCharts({});
                }}
              />
              {!includes(["How", "Source"], selectionType) &&
                parentValue.length > 0 &&
                parentValue.map((dimension) => {
                  const constrainedSelectionType = includes(["What", "How"], selectionType);
                  const areResultsAvailable = builder.results.length !== 0;
                  const childOptions = getChildOptions(builder, dimension.label);
                  const childValue = getChildSelected(builder, dimension.label, childOptions);
                  // append select all and set disabled options as appropriate
                  const showChildSelectAll = shouldShowSelectAll(
                    childOptions,
                    childValue.length,
                    constrainedSelectionType && !areResultsAvailable,
                  );
                  const childOptionsWithSelectAll = prependSelectAll(childOptions, showChildSelectAll); // append select all if allowed
                  const childOptionsWithDisabled = childOptionsWithSelectAll.map((option) => {
                    const disable = constrainedSelectionType && !areResultsAvailable && childValue.length > 0;
                    return disable ? { ...option, isDisabled: true } : option; // disable options where appropriate
                  });
                  const categorySelectHandler = async (action, option, removedValue) => {
                    if (action === "select-option") {
                      if (option?.value === "SEER_SELECT_ALL") {
                        await builder.selectAllValues(
                          dimension.value,
                          childOptions.map((opt) => opt.value),
                          childOptions.map((opt) => Object.keys(opt.data.source)),
                        );
                      } else {
                        await builder.selectValue(dimension.value, option?.value, option?.data?.source);
                      }
                    } else if (action === "remove-value") {
                      await builder.deselectValue(dimension.value, removedValue?.value);
                    } else if (action === "clear") {
                      await builder.deselectAllValues(dimension.value);
                    }
                  };
                  // compile dynamic filter variables
                  const dynamicDimFilter = builder.getDynamicDimFilter(dimension.value);
                  const allowedDynamicOptions = childOptionsWithDisabled.filter((opt) => opt.value !== "SEER_SELECT_ALL");
                  const selectedDynamicFromIdx = dynamicDimFilter
                    ? allowedDynamicOptions.findIndex((opt) => opt.value === dynamicDimFilter["gte"])
                    : -1;
                  const selectedDynamicToIdx = dynamicDimFilter
                    ? allowedDynamicOptions.findIndex((opt) => opt.value === dynamicDimFilter["lte"])
                    : -1;
                  const selectedDynamicFrom = selectedDynamicFromIdx !== -1 ? allowedDynamicOptions[selectedDynamicFromIdx] : null;
                  const selectedDynamicTo = selectedDynamicToIdx !== -1 ? allowedDynamicOptions[selectedDynamicToIdx] : null;
                  const fromOptions = selectedDynamicTo
                    ? allowedDynamicOptions.filter((_opt, idx) => idx <= selectedDynamicToIdx)
                    : allowedDynamicOptions;
                  const toOptions = selectedDynamicFrom
                    ? allowedDynamicOptions.filter((_opt, idx) => idx >= selectedDynamicFromIdx)
                    : allowedDynamicOptions;
                  const lastOptions = new Array(50).fill(0).map((_, i) => ({ label: i + 1, value: i + 1 }));
                  return (
                    <div key={dimension.label} style={{ position: "relative" }}>
                      <VTitle>
                        <div className="d-flex align-items-center justify-content-between">
                          {dimension.label.toUpperCase()}
                          {/* dynamic selector only available for compatible variables */}
                          {RANGE_COMPATIBLE_VARIABLES.includes(dimension.value) && (
                            <div className="d-flex align-items-center">
                              <Button.Group size="mini">
                                <Button
                                  className="p-2"
                                  active={!dynamicDimFilter}
                                  onClick={() => builder.toggleDynamicDim(dimension.value, false)}
                                >
                                  Choose
                                </Button>
                                <Button
                                  className="p-2"
                                  active={dynamicDimFilter?.type === "range" || (!!dynamicDimFilter && !dynamicDimFilter.type)}
                                  onClick={() => builder.toggleDynamicDim(dimension.value, true, "range")}
                                >
                                  Range
                                </Button>
                                <Button
                                  className="p-2"
                                  active={dynamicDimFilter?.type === "last"}
                                  onClick={() => builder.toggleDynamicDim(dimension.value, true, "last")}
                                >
                                  Last
                                </Button>
                              </Button.Group>
                              <StylePopup
                                trigger={<Icon name="info circle" className="ml-2 cursor-pointer" />}
                                content={
                                  <div>
                                    <p>
                                      Dynamic category selection is enabled for some variables including{" "}
                                      {RANGE_COMPATIBLE_VARIABLES.join(", ")}. Options include:
                                    </p>
                                    <p>
                                      <b>Choose:</b> categories individually.
                                    </p>
                                    <p>
                                      <b>Range:</b> Define a 'From' and/or 'To' range.
                                    </p>
                                    <p>
                                      <b>Last:</b> Pick the most recent 'n' categories.
                                    </p>
                                  </div>
                                }
                                position="right center"
                                size="mini"
                                inverted
                              />
                            </div>
                          )}
                        </div>
                      </VTitle>
                      {!!dynamicDimFilter ? (
                        <Card className="m-0 w-100">
                          <Card.Content className="px-3 py-2">
                            {(dynamicDimFilter.type === "range" || !dynamicDimFilter.type) && (
                              <>
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                  <div className="fs-1000" style={{ width: 45 }}>
                                    <b>From</b>
                                  </div>
                                  <Select
                                    components={{
                                      DropdownIndicator: CustomDropdownIndicator,
                                      MultiValueRemove,
                                      MultiValueLabel: CustomMultiValueLabel,
                                    }}
                                    styles={customReactSelectStyles}
                                    className="w-100 ml-3"
                                    placeholder="Select or search"
                                    options={fromOptions}
                                    name={`from_${dimension.value}`}
                                    value={selectedDynamicFrom}
                                    onChange={(...rsArgs) => builder.changeDynamicDim(dimension.value, "gte", rsArgs)}
                                    isClearable
                                  />
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                  <div className="fs-1000" style={{ width: 45 }}>
                                    <b>To</b>
                                  </div>
                                  <Select
                                    components={{
                                      DropdownIndicator: CustomDropdownIndicator,
                                      MultiValueRemove,
                                      MultiValueLabel: CustomMultiValueLabel,
                                    }}
                                    styles={customReactSelectStyles}
                                    className="w-100 ml-3"
                                    placeholder="Select or search"
                                    options={toOptions}
                                    name={`to_${dimension.value}`}
                                    value={selectedDynamicTo}
                                    onChange={(...rsArgs) => builder.changeDynamicDim(dimension.value, "lte", rsArgs)}
                                    isClearable
                                  />
                                </div>
                              </>
                            )}
                            {dynamicDimFilter.type === "last" && (
                              <div className="d-flex align-items-center justify-content-between mb-2">
                                <div className="fs-1000" style={{ width: 45 }}>
                                  <b>Last</b>
                                </div>
                                <Select
                                  components={{
                                    DropdownIndicator: CustomDropdownIndicator,
                                    MultiValueRemove,
                                    MultiValueLabel: CustomMultiValueLabel,
                                  }}
                                  styles={customReactSelectStyles}
                                  className="w-100 ml-3"
                                  placeholder="Select or search"
                                  options={lastOptions}
                                  name={`last_${dimension.value}`}
                                  value={
                                    typeof dynamicDimFilter.last === "number"
                                      ? { label: dynamicDimFilter.last, value: dynamicDimFilter.last }
                                      : null
                                  }
                                  onChange={(...rsArgs) => builder.changeDynamicDim(dimension.value, "last", rsArgs)}
                                  isClearable
                                />
                              </div>
                            )}
                            {props.store!.userPlan === "Standard" ? (
                              <p className="mb-0">
                                Upgrade to a Premium plan to have your Insight refresh automatically.{" "}
                                <a href="https://seerdata.ai/plans/" target="_blank" className="text-info">
                                  Learn More
                                </a>
                              </p>
                            ) : (
                              <Label color={builder.autoRefresh ? "teal" : undefined}>
                                <div className="d-flex justify-content-center align-items-center">
                                  <Checkbox className="mr-2" checked={builder.autoRefresh} onChange={() => builder.toggleAutoRefresh()} />
                                  <div>
                                    Auto refresh Insight{" "}
                                    <Popup
                                      trigger={<Icon name="info circle" />}
                                      content={
                                        <span>If checked, your insight will be automatically updated when new data is available.</span>
                                      }
                                      inverted
                                    />
                                  </div>
                                </div>
                              </Label>
                            )}
                          </Card.Content>
                        </Card>
                      ) : (
                        <SortableSelect
                          onDragEnd={reorderCategories(builder, dimension.value)}
                          isSortDisabled={childValue.length === 1}
                          components={{
                            DropdownIndicator: CustomDropdownIndicator,
                            MultiValueLabel: CustomMultiValueLabel,
                            MultiValueRemove,
                          }}
                          styles={customReactSelectStyles}
                          isMulti
                          name={dimension.label}
                          value={childValue}
                          placeholder="Select or search"
                          options={childOptionsWithDisabled}
                          // TODO: Check on whether we need MenuList
                          // components={childOptions.length > 100 ? { MenuList } : undefined}
                          filterOption={createFilter({ ignoreAccents: false })} // improves render speed
                          onChange={async (_, changeData: any) => {
                            // supporting [select-option,remove-value,clear] actions
                            const { action, option, removedValue } = changeData;
                            // Update all tables when categories of the locked column variable change
                            if (builder.tables.length > 1 && builder.columns.map((c) => c.dimension).indexOf(dimension.key) >= 0) {
                              const storedActiveTableId = builder.activeTableId;

                              // TODO: Fix the handling of this in this commit and the previous one, temporary solution for CHA
                              // for (const table of builder.tables) {
                              await builder.setActiveTableId(storedActiveTableId);
                              await categorySelectHandler(action, option, removedValue);
                              // await builder.saveTableQuery(storedActiveTableId);
                              // }
                              builder.setActiveTableId(storedActiveTableId);
                              // Just update the current active table
                            } else {
                              await categorySelectHandler(action, option, removedValue);
                            }

                            // refresh chart configuration
                            builder.refreshChartConfig();
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              {/* Only show this helper text when Where/When has comparable options */}
              {!forMultiStep && (selectionType === "Where" || selectionType === "When") && hasComparableDimes(builder, selectionType) && (
                <div className="d-flex mt-2 text-dark">
                  <Icon name="info circle" className="mr-2 fs-1000" />
                  <p className="fs-0875">{`Comparable options available. Move "${selectionType === "Where" ? "Location" : "Time"}" to columns in "Advanced" to select more options.`}</p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }),
);
