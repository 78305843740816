import * as React from "react";

// -=-=-=-=Store=-=-=-=-=-=-
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
// import Store from "../../common/store";

// -=-=-=-=Styles-=-=-=-=-=-
import {
  StyleUpgradeModal,
  StyleUpgradeContent,
  StyleLogoWrapper,
  StyleCTAHeading,
  StyleCTAWrapper,
  StyleGrid,
  StyleGridRow,
  StyleGridColumn,
} from "./popupStyles/upgrade.style";

import { PlanCard } from "./PlanCard";
import { getMixpanel } from "../../common/api";
import { Link } from "react-router-dom";
import { COLORS } from "component/UI/common";
import { Button } from "semantic-ui-react";

const plans = [
  {
    header: "Standard",
    description: "Seer Data's Standard Plan gets you started accessing and analysing data.",
    lists: [
      "Build unlimited Suitcases and Insights using Open Data",
      "Access to Pre-Packed Suitcases of Insights",
      "Share Suitcases and Insights with up to 5 team members",
    ],
    planId: 1,
    button: "Optimise my plan",
    link: "https://seerdata-1.hubspotpagebuilder.com/optimise-seer-standard-subscription",
    mixpanelId: "Upgrade Click: Standard Plan",
    style: {},
  },
  {
    header: "Plus",
    description: "All Standard features Plus greater power to access, analyse and share data.",
    lists: [
      "Invite partner organisations to share data and collaborate",
      "Personalised onboarding and training for up to 10 users",
      "Access to Seer Data data collections and Data Science advice",
      "Access to add ons including Self-service Dataset Collection",
    ],
    planId: 2,
    button: "Find out more",
    link: "https://seerdata.ai/plans/plus-plan",
    mixpanelId: "Upgrade Click: Plus Plan",
    style: { border: `2px solid ${COLORS.indigo600}`, boxShadow: "0px 4px 16px rgba(103, 39, 103, 0.4)" },
  },
  {
    header: "Data Space",
    description: "Designed to facilitate shared learning and collaboration for community benefit and control.",
    lists: [
      "Customised, central and secure architecture for data sharing and collaboration",
      "Self-service Dataset Collection from multiple contributors with automated reminders",
      "Multiple organisations with up to 20 Users (more on request)",
      "Facilitates impact tracking, continuous and shared measurement and learning for community action and benefit",
    ],
    planId: 4,
    button: "Find out more",
    link: "https://seerdata.ai/plans/data-space",
    mixpanelId: "Upgrade Click: Data Space",
    style: { border: `1px solid ${COLORS.indigo600}` },
  },
];

const requestUrl = "https://seerdata-1.hubspotpagebuilder.com/learn-more-about-building-a-custom-plan";

export const Upgrade = inject("store")(
  observer((props: any) => {
    const { store } = props;

    const handleClose = () => {
      store.handleUpgradeShow(false);
    };

    const trackMixpanel = () => {
      getMixpanel(store).track("Upgrade Click: Build a Plan");
    };

    return (
      <StyleUpgradeModal closeIcon centered size={"large"} open={store.upgradeShow} onClose={handleClose}>
        <StyleUpgradeContent>
          <StyleGrid stackable centered={true}>
            <StyleGridRow columns={3} stretched>
              {plans.map((plan, idx) => {
                const { header, description, lists, planId, button, link, style, mixpanelId } = plan;
                return (
                  <StyleGridColumn key={idx}>
                    <PlanCard
                      header={header}
                      description={description}
                      lists={lists}
                      planId={planId}
                      button={button}
                      link={link}
                      style={style}
                      store={store}
                      mixpanelId={mixpanelId}
                    />
                  </StyleGridColumn>
                );
              })}
            </StyleGridRow>
          </StyleGrid>
          <StyleCTAWrapper>
            <StyleCTAHeading>Need a customised plan? Let us know what you need:</StyleCTAHeading>
            <Link to={{ pathname: requestUrl }} target="_blank">
              <Button color="purple" onClick={trackMixpanel}>
                Build a plan
              </Button>
            </Link>
          </StyleCTAWrapper>
          <StyleLogoWrapper>
            <img src="/assets/seer-upgrade-logo.png" alt="Seer Data logo" />
          </StyleLogoWrapper>
        </StyleUpgradeContent>
      </StyleUpgradeModal>
    );
  }),
);
