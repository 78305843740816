import * as React from "react";
import { Loader } from "semantic-ui-react";
import {
  PREVIEW_AXIS_TICK_LABEL_MAX_WIDTH_PX,
  defaultAngle,
  getChartColors,
  getChartData,
  getChartKey,
  getChartXAxisCombo,
  PREVIEW_LABEL_FONT_SIZE,
  getPercentData,
  includes,
  DEFAULT_CHART_CONFIG,
  getChartTables,
  CHART_FONT_FAMILY,
  getSimplePieChartData,
  formatter,
  getMetricChartData,
  getFilteredChartTables,
  getChartConfiguration,
} from "common/helpers/chart";
import { NewBuilderBarChart } from "./NewBuilderBarChart";
import { NewBuilderLineChart } from "./NewBuilderLineChart";
import { NewBuilderAreaChart } from "./NewBuilderAreaChart";
import { NewBuilderDonutChart } from "./NewBuilderDonutChart";
import ErrorBoundary from "pages/ErrorBoundary";
import { SimplePieChart } from "component/Charts/SimplePieChart";
import { CustomAxisTick, CustomPieToolTip, CustomToolTip } from "component/Charts/CustomCharts";
import { MetricChart } from "component/Charts/MetricChart";
import { IFilter, TypeDecimalPoints } from "common/store/builder";
import { getComputedTicks, getDomainMinAndMaxValue, getMinAndMaxChartData, ifFormattedAsPercentage } from "./helper";
import { getMissingFallback } from "common/helpers/explore";

interface INewBuilderChartPreview {
  insightJson: any;
  insightTitle: string;
  layout: "block" | "grid";
  insightID?: number;
}

const Component = ({ insightJson, insightTitle, layout, insightID }: INewBuilderChartPreview): JSX.Element => {
  const [tooltipDataKey, setTooltipDataKey] = React.useState<any>(undefined);
  const [graphWidth, setGraphWidth] = React.useState(250);

  const updateGraphWidth = (layout: string): void => {
    if (layout === "block") {
      // Two columns layout
      setGraphWidth(400);
    } else {
      // Three columns layout
      setGraphWidth(250);
    }
  };

  React.useEffect(() => {
    updateGraphWidth(layout);
  }, [insightJson, insightTitle, layout]);

  if (insightJson === null) {
    return (
      <div className="d-flex align-items-center justify-content-center w-100">
        <Loader active inline="centered" />
      </div>
    );
  }

  const { chart, tables, userDefinedCharts, customChartColors, columns, decimalPoints, calcMissingFallback, calcMissingFallbackValue } =
    insightJson;
  const chartTables = getChartTables(tables);

  if (Object.keys(insightJson).length === 0 || !chartTables || chartTables.length === 0) {
    return (
      <div className="d-flex align-items-center justify-content-center w-100">
        <span className="text-center text-grey w-100">No preview available.</span>
      </div>
    );
  }

  const { legend, xAxis, series } = getChartConfiguration(userDefinedCharts, chartTables, columns);
  const {
    type,
    angle,
    labelLength,
    percentage,
    userDefinedMaxValue,
    userDefinedMinValue,
    userDefinedIncrements,
    showRawValueInTooltip,
    axisTickInterval,
    metricLabelOverride,
  } = chart || DEFAULT_CHART_CONFIG;
  const filteredChartTables = getFilteredChartTables(chartTables, columns as IFilter[], xAxis, series);

  const chartData = getChartData(xAxis, legend, series, columns, tables, getMissingFallback(calcMissingFallback, calcMissingFallbackValue));
  const chartKey = getChartKey(chartData);
  const chartXAxisCombo = getChartXAxisCombo(xAxis);
  const chartColors = getChartColors(customChartColors || [], type, chartTables, columns, xAxis, series);
  const currentFormatter = formatter(percentage, type, chartData, filteredChartTables);
  const axisTick = CustomAxisTick(labelLength, angle, PREVIEW_LABEL_FONT_SIZE, PREVIEW_AXIS_TICK_LABEL_MAX_WIDTH_PX);
  const isPercentage = ifFormattedAsPercentage(percentage, type, filteredChartTables);
  const { dataMin, dataMax } = getMinAndMaxChartData(chartData);
  const [minDomainValue, maxDomainValue] = getDomainMinAndMaxValue(userDefinedMinValue, userDefinedMaxValue, dataMin, dataMax, type);
  const computedTicks = getComputedTicks(userDefinedIncrements, userDefinedMinValue, userDefinedMaxValue, type);
  const allowDataOverflow = userDefinedMaxValue !== undefined && userDefinedMinValue !== undefined;

  const XAxisAngle = angle || defaultAngle;
  const formatChartData: any[] =
    includes(type, "proportion") || includes(type, "percentage") ? getPercentData(chartKey, chartData) : chartData;
  // TODO: For now just show the first chart. Use carousel to show multiple charts in the future
  const data = type.includes("Pie")
    ? getSimplePieChartData(legend, xAxis, formatChartData, filteredChartTables)["data"][0]
    : type.includes("Metric")
      ? getMetricChartData(formatChartData, filteredChartTables, columns as IFilter[])[0]
      : formatChartData[0];

  let ctxXAxisHeight: CanvasRenderingContext2D;
  const measureXAxisHeight = (text: string) => {
    if (!ctxXAxisHeight) {
      ctxXAxisHeight = document.createElement("canvas").getContext("2d")!;
      ctxXAxisHeight.font = `${PREVIEW_LABEL_FONT_SIZE}px ${CHART_FONT_FAMILY}`;
    }
    const textLength =
      ctxXAxisHeight.measureText(text).width > PREVIEW_AXIS_TICK_LABEL_MAX_WIDTH_PX
        ? PREVIEW_AXIS_TICK_LABEL_MAX_WIDTH_PX
        : ctxXAxisHeight.measureText(text).width;

    if (angle === 0) {
      if (type.includes("Horizontal")) {
        return textLength;
      }
      return PREVIEW_LABEL_FONT_SIZE;
    }

    if (Math.abs(XAxisAngle) === 90) {
      if (type.includes("Horizontal")) {
        return PREVIEW_LABEL_FONT_SIZE * 2.4;
      }
      return textLength;
    } else {
      const radian = ((2 * Math.PI) / 360) * Math.abs(XAxisAngle);
      if (type.includes("Horizontal")) {
        return Math.cos(radian) * textLength;
      }
      return Math.sin(radian) * textLength + 5;
    }
  };

  let ctxYAxis: CanvasRenderingContext2D;
  const measureYAxisWidth = (text: string) => {
    if (!ctxYAxis) {
      ctxYAxis = document.createElement("canvas").getContext("2d")!;
      ctxYAxis.font = `${PREVIEW_LABEL_FONT_SIZE}px ${CHART_FONT_FAMILY}`;
    }

    return ctxYAxis.measureText(text).width + 5;
  };

  let maxXAxisHeight = 16;
  let maxYAxisWidth = 0;
  if (chartData.length > 0) {
    for (const singleChartData of chartData) {
      for (const dataItem of singleChartData) {
        const XAxisHeight = measureXAxisHeight(dataItem.label0);
        if (XAxisHeight > maxXAxisHeight) {
          maxXAxisHeight = XAxisHeight;
        }

        for (const keyValue of chartKey.values) {
          const formattedValueY = currentFormatter(dataItem[keyValue]);
          const textWidthY = measureYAxisWidth(formattedValueY);
          if (textWidthY > maxYAxisWidth) {
            maxYAxisWidth = textWidthY;
          }
        }
      }
    }
  }

  // Get chart height: "Card height" - "Card bottom height" - "Insight title height (get numbers of line and multiple fontsize)"
  const singleLineLetterNumber = layout === "block" ? 44 : 26;
  const graphHeight = insightTitle ? 280 - Math.ceil(insightTitle.length / singleLineLetterNumber) * 24 : 0;

  return (
    <>
      <div className="w-100 px-4 py-0 d-flex justify-content-center">
        {/* TODO: show series title when we use carousel for multiple series */}
        {type.includes("bar") && (
          <NewBuilderBarChart
            data={data}
            chartKey={chartKey}
            chartColors={chartColors}
            type={type}
            graphWidth={graphWidth}
            graphHeight={graphHeight}
            maxXAxisHeight={maxXAxisHeight}
            maxYAxisWidth={maxYAxisWidth}
            minDomainValue={minDomainValue}
            maxDomainValue={maxDomainValue}
            computedTicks={computedTicks}
            formatter={currentFormatter}
            setTooltipDataKey={setTooltipDataKey}
            CustomAxisTick={axisTick}
            CustomToolTip={CustomToolTip(
              tooltipDataKey,
              filteredChartTables,
              percentage as boolean,
              decimalPoints as TypeDecimalPoints,
              !!showRawValueInTooltip,
              type,
            )}
            allowDataOverflow={allowDataOverflow}
            axisTickInterval={axisTickInterval}
          />
        )}
        {type === "Line" && (
          <NewBuilderLineChart
            data={data}
            chartKey={chartKey}
            chartColors={chartColors}
            graphWidth={graphWidth}
            graphHeight={graphHeight}
            maxXAxisHeight={maxXAxisHeight}
            maxYAxisWidth={maxYAxisWidth}
            minDomainValue={minDomainValue}
            maxDomainValue={maxDomainValue}
            computedTicks={computedTicks}
            formatter={currentFormatter}
            setTooltipDataKey={setTooltipDataKey}
            CustomAxisTick={axisTick}
            CustomToolTip={CustomToolTip(
              tooltipDataKey,
              filteredChartTables,
              percentage as boolean,
              decimalPoints as TypeDecimalPoints,
              !!showRawValueInTooltip,
              type,
            )}
            allowDataOverflow={allowDataOverflow}
            axisTickInterval={axisTickInterval}
          />
        )}
        {type.includes("Line ") && (
          <NewBuilderAreaChart
            id={insightID}
            data={data}
            chartKey={chartKey}
            chartColors={chartColors}
            graphWidth={graphWidth}
            graphHeight={graphHeight}
            maxXAxisHeight={maxXAxisHeight}
            maxYAxisWidth={maxYAxisWidth}
            minDomainValue={minDomainValue}
            maxDomainValue={maxDomainValue}
            computedTicks={computedTicks}
            formatter={currentFormatter}
            setTooltipDataKey={setTooltipDataKey}
            CustomAxisTick={axisTick}
            CustomToolTip={CustomToolTip(
              tooltipDataKey,
              filteredChartTables,
              percentage as boolean,
              decimalPoints as TypeDecimalPoints,
              !!showRawValueInTooltip,
              type,
            )}
            allowDataOverflow={allowDataOverflow}
            axisTickInterval={axisTickInterval}
          />
        )}
        {type.includes("Pie") && (
          <SimplePieChart
            data={data}
            chartColors={chartColors}
            width={graphWidth}
            height={graphHeight}
            size={layout === "block" ? "small" : "mini"}
            CustomToolTip={CustomPieToolTip(filteredChartTables, isPercentage, decimalPoints as TypeDecimalPoints)}
          />
        )}
        {type.includes("Donut") && (
          <NewBuilderDonutChart
            data={data}
            chartKey={chartKey}
            chartColors={chartColors}
            chartXAxisCombo={chartXAxisCombo}
            graphWidth={graphWidth}
            graphHeight={graphHeight}
            setTooltipDataKey={setTooltipDataKey}
            CustomToolTip={CustomToolTip(
              tooltipDataKey,
              filteredChartTables,
              percentage as boolean,
              decimalPoints as TypeDecimalPoints,
              false,
              "donut",
            )}
          />
        )}
        {type.includes("Metric") && (
          <MetricChart
            data={data}
            chartColors={chartColors}
            width={graphWidth}
            height={graphHeight}
            filteredChartTables={filteredChartTables}
            columns={columns}
            decimalPoints={decimalPoints}
            labelOverride={metricLabelOverride}
          />
        )}
      </div>
    </>
  );
};

export const NewBuilderChartPreview = (props: INewBuilderChartPreview): JSX.Element => (
  <ErrorBoundary
    errorContent={
      <div className="p-3">
        Legacy insights are no longer supported in the platform. Please reach out to support if you need any assistance.
      </div>
    }
  >
    <Component {...props} />
  </ErrorBoundary>
);
