import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import { StylePillButton } from "pages/Dashboard/includes/DashboardCollaborate/includes/components";
import { COLORS } from "component/UI/common";
import { database, getMixpanel } from "common/api";
import Store from "common/store";
import { DateTime } from "luxon";
import { Avatar } from "component/Avatar";
import { IMember } from "common/store/suitcase";
import { ContentPlaceholder } from "component/ContentPlaceholder";
import { webUrl } from "common/constants";

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  flex-grow: 1;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }
`;

interface Props {
  filters: { label: string; value: string}[];
  store?: Store;
}

interface Item {
  id: number;
  name: string;
  owner?: IMember;
  updated_at: string;
  updated_by_user?: IMember;
  link?: string;
}

const Component = (props: Props): JSX.Element => {
  const { filters, store } = props;
  const [activeFilter, setActiveFilter] = React.useState(filters[0].value);
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState<Item[]>([]);

  const itemClickMixpanelTrack = (id: number) => {
    getMixpanel(store!).track("Recent Activity Item Click", { Page: "Homepage", Type: activeFilter, ID: id });
  };

  React.useEffect(() => {
    const getItems = async () => {
      setLoading(true);
      if (activeFilter === "datasets") {
        await store!.builder.getAllDatasets();
        const { allDatasets } = store!.builder;
        const recentDatasets = allDatasets.filter(dataset => dataset.updated_at)
          .sort((a, b) => new Date(b.updated_at as string).getTime() - new Date(a.updated_at as string).getTime())
          .slice(0, 5).map(dataset => ({
            id: dataset.id,
            name: dataset.name,
            updated_at: dataset.updated_at,
            link: `${webUrl}/explore`,
          }));
        setItems(recentDatasets as Item[]);
      } else {
        const res: any = await database.get(`${activeFilter}/recent`, "", store!.token!);
        if (res?.statusCode === 200) {
          setItems(res?.body?.data[activeFilter]);
        }
      }
      setLoading(false);
    };
    getItems();
  }, [activeFilter]);

  return (
    <div className="mb-5">
      <div className="d-flex flex-column flex-sm-row justify-content-between mb-4">
        <div className="d-flex align-items-center mb-3 mb-sm-0">
          <p className="fs-1500 fw-700 mb-0 mr-3" style={{ color: COLORS.indigo600 }}>Recent Activity</p>
          {/* bug in fomantic, chartline instead of chart line like semantic */}
          <Icon name={"chartline" as "chart line"} style={{ color: COLORS.indigo600 }} />
        </div>
        <div>
          {filters.map(item => {
            const { label, value } = item;
            return (
              <StylePillButton
                key={value}
                size="tiny"
                active={activeFilter === value}
                className="cursor-pointer mr-2 mb-2 mb-md-0"
                onClick={() => setActiveFilter(value)}
                disabled={loading}
              >
                {label}
              </StylePillButton>
            );
          })}
        </div>
      </div>
      {loading ? (
        <ContentPlaceholder className="w-100 h-100" />
      ) : (
        <div>
          {items.map(item => {
            const { id, name, owner, updated_at, updated_by_user, link } = item;
            const displayedUser = (updated_by_user || owner) as IMember;
            return (
              <MainContainer
                key={`${activeFilter} - ${id}`}
                onClick={() => {
                  window.open(link || `${webUrl}/${activeFilter}/${id}`, "_blank");
                  itemClickMixpanelTrack(id);
                }}
              >
                {displayedUser && (
                  <Avatar name={`${displayedUser.first_name} ${displayedUser.last_name}`} size={42} image={displayedUser.avatar || ""} className="mr-3" />
                )}
                <ContentContainer>
                  <a
                    className="mb-0 fw-700 fs-1125 text-decoration-none"
                    style={{ color: COLORS.indigo600 }}
                    href={link || `/${activeFilter}/${id}`}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                      itemClickMixpanelTrack(id);
                    }}
                  >
                    {name}
                  </a>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-0 fw-700 fs-1125 mr-2" style={{ color: COLORS.grey500 }}>Updated at:</p>
                    <p className="mb-0 fw-400 fs-1125" style={{ color: COLORS.grey500 }}>{DateTime.fromISO(updated_at).setZone("local").toFormat("DD")}</p>
                  </div>
                </ContentContainer>
              </MainContainer>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const RecentActivity = inject("store")(observer(Component));
