export const COLORS = {
  grey100: "#F5F5F5",
  grey200: "#E5E5E5",
  grey300: "#D6D6D6",
  grey400: "#CACBCD",
  grey500: "#999999",
  grey600: "#7F7F7F",
  grey700: "#5F5F5F",
  grey800: "#504F5B",
  grey900: "#212121",
  indigo600: "#5F2D5F ", // Primary purple
  indigo500: "#734473",
  indigo400: "#AC74AA",
  indigo300: "#D6BAD5",
  indigo200: "#F3E9F3",
  indigo100: "#FAF7FA",
  aqua600: "#038386",
  aqua500: "#2DC3C2",
  aqua400: "#57CFCE",
  aqua300: "#81DBDA",
  aqua200: "#C0EDED",
  aqua100: "#EAF9F9",
  red600: "#AC173C",
  red500: "#D71D4B", // Primary red
  red400: "#DF4A6F",
  red300: "#EB8EA5",
  red200: "#F7D2DB",
  red100: "#FDF1F4",
  blue: "#022CC3",
  blue200: "#DBF2FF",
  yellow: "#F2C04C",
  orange: "#EA993E",
};
