import { COLORS } from "component/UI/common";
import styled from "styled-components";

export const MultiRowTable = styled.table`
  width: 100%;
  font-family: Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #dddddd;
    text-align: left;
    vertical-align: top;
  }
  th {
    background-color: #231f21;
    color: #fff;
    padding: 16px;
  }
  td {
    background-color: #fff;
  }
  .action-td {
    text-align: center;
    vertical-align: middle;
  }
`;

export const MultiRowTableTextarea = styled.textarea`
  font-family: Helvetica, sans-serif;
  font-size: 20px;
  width: 100%;
  padding: 14px;
  box-sizing: border-box;
  border: none;
  resize: vertical;
  min-height: 51px;
  margin-bottom: -6px;
  &:focus-visible {
    outline: 2px solid ${COLORS.blue};
  }
`;

export const MultiRowTableAddButton = styled.div`
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  background-color: #dddddd;
  color: #3e3e3e;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    background-color: #d3d3d3;
  }
  &:focus-visible {
    outline: 2px solid ${COLORS.blue};
  }
`;

export const MultiRowTableDeleteWrapper = styled.span`
  cursor: pointer;
  &:focus-visible {
    outline: 2px solid ${COLORS.blue};
  }
`;
