import { COLORS } from "component/UI/common";
import styled from "styled-components";

export const CustomFormSelectWrapper = styled.div`
  position: relative;
  margin-bottom: 30px;
  &::after {
    position: absolute;
    content: "❯";
    font-size: 20px;
    pointer-events: none;
    color: #fff;
    right: 20px;
    top: 20px;
    transform: rotate(90deg);
  }
`;

export const CustomFormSelect = styled.select`
  background-color: #231f21;
  display: flex;
  padding: 20px;
  font-family: Helvetica, sans-serif;
  font-size: 20px;
  color: #fff;
  width: 100%;
  border: none;
  appearance: none;
  cursor: pointer;
  &:focus-visible {
    outline: 2px solid ${COLORS.blue};
  }
`;

export const CustomFormHeading = styled.p`
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const CustomFormTextarea = styled.textarea`
  font-family: Helvetica, sans-serif;
  font-size: 20px;
  width: 100%;
  resize: vertical;
  padding: 12px;
  box-sizing: border-box;
  border: 2px solid #231f21;
  min-height: 51px;
  &:focus-visible {
    outline: 2px solid ${COLORS.blue};
  }
`;
