import * as React from "react";
import { Button, Divider, Form, Header, Icon, Label, Modal } from "semantic-ui-react";
import { Field, Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { uniq, includes } from "lodash";
import { FieldInputHidden } from "./FieldInput";
import Store from "common/store";
import { datasets as dataApi } from "common/api";
import { collator, sortYear } from "common/helpers/data";
import { VariableData } from "common/helpers/dataset";
import { generators } from "./helpers";
import { CustomAutoSuggest } from "./CustomAutosuggest";
import { composeValidators } from "common/helpers/finalForm";

interface Props {
  store?: Store;
  isOpen: boolean;
  close: () => any;
  onAdd: (_) => any;
  onGenerate: (_) => any;
  variableData: VariableData;
}

export const Component = ({ store, isOpen, close: closeModal, onAdd, onGenerate, variableData }: Props): JSX.Element => {
  const [formType, setFormType] = React.useState("add");
  const [categories, setCategories] = React.useState<any>([]);
  const [categoriesToBeAdded, setCategoriesToBeAdded] = React.useState<any>([]);
  const existingCategories = variableData.categories.map((cat) => cat.toString().toLowerCase());
  const toBeFilteredOutCategories = [...existingCategories, ...categoriesToBeAdded];
  const mustBeUniqueInToBeAddedList = (values) => (value) =>
    includes(values, value?.toString().toLowerCase()) ? `"${value}" already exists in the list below.` : undefined;
  const mustBeUniqueInExistingList = (values) => (value) =>
    includes(values, value?.toString().toLowerCase()) ? `"${value}" is already added to ${variableData.variable}.` : undefined;
  const loadCategories = async () => {
    const res: any = await dataApi.post("v2/qs", { filters: { [variableData.variable]: [] } }, store!.token ?? "");
    const json = res?.body;
    if (json) {
      const allCats = json.data.datasets
        .map((ds) =>
          ds.dimensions
            .filter((dim) => dim.name === variableData.variable)
            .map((dim) => dim.values.map((val) => val.name.toString()))
            .reduce((acc, next) => [...acc, ...next], []),
        )
        .filter((dim) => dim?.length)
        .reduce((acc, next) => [...acc, ...next], []);
      const cats: never[] = uniq(allCats);
      // sorting
      if (includes(["year month", "year quarter"], variableData.variable.toLowerCase())) {
        const customSorted = cats.map((name) => ({ name })); // reformat to use with sortYear
        customSorted.sort(sortYear(variableData.variable.toLowerCase() === "year month" ? "Month" : "Quarter"));
        const formatted = customSorted.map((cat) => cat.name);
        setCategories(formatted);
      } else {
        cats.sort((a, b) => collator.compare(a, b));
        setCategories(cats);
      }
    } else {
      setCategories([]); // unknown variable
    }
  };
  React.useEffect(() => {
    setCategoriesToBeAdded([]);
  }, [isOpen, formType]);
  React.useEffect(() => {
    loadCategories();
  }, [variableData]);
  const close = () => {
    setFormType("add"); // reset formType on close
    closeModal();
  };
  const onSubmit = (values): any => {
    if (formType === "add") {
      const error = onAdd(categoriesToBeAdded);
      if (error) {
        return { [FORM_ERROR]: error };
      } else {
        close();
      }
    } else {
      const generator = generators[variableData.variable];
      const validationErrors = generator.submitValidator(values);
      if (validationErrors) {
        return validationErrors;
      }
      const defaultTransform = (val) => val; // by default no change to value
      const transformer = generator.valueTransform || defaultTransform; // use generator value transform for arguments if set
      const generatedCategories = generator.generatorFunction(...generator.arguments.map((arg) => transformer(values[arg.name])));
      const error = onGenerate(generatedCategories);
      if (error) {
        return { [FORM_ERROR]: error };
      } else {
        close();
      }
    }
  };
  const selectHandler = (value) => {
    setCategoriesToBeAdded([...categoriesToBeAdded, value.toString()]);
  };
  const removeCategoryHandler = (index) => {
    const copiedCategories = [...categoriesToBeAdded];
    copiedCategories.splice(index, 1);
    setCategoriesToBeAdded(copiedCategories);
  };
  return (
    <Modal open={!!isOpen} onClose={close} closeOnDimmerClick={false}>
      {formType === "add" ? (
        <FinalForm
          onSubmit={onSubmit}
          render={({ submitError, handleSubmit, form }) => {
            const { getFieldState, restart } = form;
            const fieldMeta: any = getFieldState("category");
            return (
              <>
                <Modal.Header>Add categories</Modal.Header>
                <Modal.Content>
                  <Header as="h5" color="purple" className="mb-2">
                    Variable: {variableData.variable}
                  </Header>
                  <Header as="h5" className="mb-2 mt-0">
                    Search or create categories
                  </Header>
                  <p>Type to search over existing categories or type in a new custom category.</p>
                  <Form>
                    <Field
                      name="category"
                      component={FieldInputHidden}
                      validate={composeValidators(
                        mustBeUniqueInToBeAddedList(categoriesToBeAdded),
                        mustBeUniqueInExistingList(existingCategories),
                      )}
                    />
                    {fieldMeta && (
                      <CustomAutoSuggest
                        fieldMeta={fieldMeta}
                        rawOptions={categories}
                        toBeFilteredOutOptions={toBeFilteredOutCategories}
                        resetForm={restart}
                        onSelect={selectHandler}
                      />
                    )}
                  </Form>
                  <div className="mb-2">
                    {categoriesToBeAdded.map((c, index) => (
                      <Label key={c}>
                        {c}
                        <Icon
                          name="delete"
                          onClick={() => {
                            removeCategoryHandler(index);
                            restart(); // Clear "FORM_ERROR"
                          }}
                        />
                      </Label>
                    ))}
                  </div>
                  {submitError && (
                    <>
                      {submitError.map((e) => (
                        <p key={e} className="text-primary mb-1">
                          {e}
                        </p>
                      ))}
                    </>
                  )}
                  {generators[variableData.variable] && (
                    <>
                      <Divider className="my-4" />
                      <div className="mt-3">
                        <p>Alternatively click here to switch to generating categories for this variable</p>
                        <Button
                          onClick={() => {
                            setFormType("generate");
                            restart(); // Clear "FORM_ERROR"
                          }}
                        >
                          Generate Categories
                        </Button>
                      </div>
                    </>
                  )}
                </Modal.Content>
                <Modal.Actions>
                  <Button type="button" onClick={close}>
                    Cancel
                  </Button>
                  <Button
                    disabled={categoriesToBeAdded.length === 0 || fieldMeta.error}
                    type="submit"
                    color="purple"
                    onClick={handleSubmit}
                  >
                    Add categories <Icon name="plus" className="ml-1 mr-0" />
                  </Button>
                </Modal.Actions>
              </>
            );
          }}
        />
      ) : (
        <FinalForm
          onSubmit={onSubmit}
          render={({ handleSubmit, submitError }) => (
            <>
              <Modal.Header>Generate categories for {variableData.variable}</Modal.Header>
              <Modal.Content>
                <p>Fill in all fields to generate categories.</p>
                <Form onSubmit={handleSubmit}>
                  {generators[variableData.variable].arguments.map((field) => (
                    <Field {...field} />
                  ))}
                </Form>
                {submitError && <p className="text-danger">{submitError}</p>}
                <div className="mt-3">
                  <p>Alternatively click here to manually enter categories for this variable</p>
                  <Button onClick={() => setFormType("add")}>Enter Manually</Button>
                </div>
              </Modal.Content>
              <Modal.Actions>
                <Button type="button" onClick={close}>
                  Cancel
                </Button>
                <Button type="submit" color="purple" onClick={handleSubmit}>
                  Generate categories <Icon name="plus" className="ml-1 mr-0" />
                </Button>
              </Modal.Actions>
            </>
          )}
        />
      )}
    </Modal>
  );
};

export const CategoryModal = inject("store")(observer(Component));
