import * as React from "react";
import { sortBy } from "lodash";
import { DateTime } from "luxon";
import { Link, useHistory } from "react-router-dom";
import { Icon, Label, Popup, Table } from "semantic-ui-react";
import {
  StyleTable,
  StyleTableCellHeading,
  StyleTableCellText,
  StyleTableHeader,
  StyleTableHeaderCell,
  StyleTableRow,
} from "component/Suitcases/includes/SuitcasesList.style";
import { LatestDataPopup } from "./LatestData";

interface IHeaderItems {
  name: string;
  sortColumn?: string;
  className: string;
}

interface IDatasets {
  id: number;
  name: string;
  link: string;
  templatesLength: number;
}

interface IDatasetTemplate {
  datasetID: number;
  templateID: number;
  name: string;
  last_updated_at: string | null;
  last_updated_by: string | null;
  contributors: string[];
  contributorsLength: number;
}

interface IDatasetTemplateList {
  headerItems: IHeaderItems[];
  datasets?: IDatasets[];
  templates?: IDatasetTemplate[];
  id?: string;
}

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_SORT":
      if (state.column === action.column) {
        return {
          ...state,
          direction: state.direction === "ascending" ? "descending" : "ascending",
        };
      } else {
        return {
          column: action.column,
          direction: "ascending",
        };
      }
    default:
      throw new Error();
  }
};

// This component is used for both rendering dataset lists and dataset template list
export const DatasetTemplateList = ({ id, headerItems, datasets, templates }: IDatasetTemplateList): JSX.Element => {
  const history = useHistory();
  const [state, dispatch] = React.useReducer(reducer, { column: null, direction: null });
  const { column, direction } = state;
  // Temporary Icon as Semantic UI doesn't show the sort icon at first:
  // Show Sort icon to let users know that the list is sortable. Hide this after users do sorting
  const [showSortIcon, setShowSortIcon] = React.useState(true);

  const handleRowClick = (datasetID: number, templateID?: number) => {
    history.push(`/datasets/${datasetID}${templateID ? `/templates/${templateID}` : ""}`);
  };

  const handleNameClick = (e) => {
    e.stopPropagation();
  };

  const tableData = datasets ? datasets : templates;
  // determine sort based on reducer state
  const sortedColumn = column ? sortBy([...tableData!], [column]) : tableData;
  const sortedDirection: any = direction === "descending" ? [...sortedColumn!].reverse() : sortedColumn;

  return (
    <StyleTable sortable celled fixed id={id || undefined}>
      <StyleTableHeader>
        <StyleTableRow>
          {headerItems.map((item, index) => {
            const { name, sortColumn, className } = item;

            return (
              <StyleTableHeaderCell
                sorted={column === sortColumn ? direction : null}
                onClick={
                  sortColumn
                    ? () => {
                        dispatch({ type: "CHANGE_SORT", column: sortColumn });
                        setShowSortIcon(false);
                      }
                    : null
                }
                className={className}
                key={index}
              >
                {name}
                {showSortIcon && sortColumn && <Icon name="sort" className="ml-1" />}
              </StyleTableHeaderCell>
            );
          })}
        </StyleTableRow>
      </StyleTableHeader>
      <Table.Body>
        {sortedDirection?.map((data, index) => {
          // For "/datasets" page list
          if (datasets) {
            const { id: datasetID, name, templatesLength, link } = data;

            return (
              <StyleTableRow key={datasetID} onClick={() => handleRowClick(datasetID)}>
                <StyleTableCellHeading>
                  <Link to={`/datasets/${datasetID}`} className="text-secondary" onClick={(e) => handleNameClick(e)}>
                    {name}
                  </Link>
                </StyleTableCellHeading>
                <StyleTableCellText>{templatesLength}</StyleTableCellText>
                <StyleTableCellText>
                  {link ? (
                    <a href={link} target="_blank" onClick={(e) => handleNameClick(e)}>
                      <Icon name="external alternate" className="text-grey text-hover-secondary" />
                    </a>
                  ) : (
                    "-"
                  )}
                </StyleTableCellText>
                <StyleTableCellText>
                  <LatestDataPopup type="dataset" data={data} />
                </StyleTableCellText>
              </StyleTableRow>
            );
            // For "/datasets/:datasetID" page list
          } else {
            const { datasetID, templateID, name, last_updated_at, last_updated_by, contributors } = data;

            return (
              <StyleTableRow
                id={id && !index ? `${id}-first-item` : undefined}
                key={templateID}
                onClick={() => handleRowClick(datasetID, templateID)}
              >
                <StyleTableCellHeading>
                  <Link
                    to={`/datasets/${datasetID}/templates/${templateID}`}
                    className="text-secondary"
                    onClick={(e) => handleNameClick(e)}
                  >
                    {name}
                  </Link>
                </StyleTableCellHeading>
                <StyleTableCellText>
                  {last_updated_at ? DateTime.fromISO(last_updated_at).setZone("local").toFormat("DD") : "-"}
                </StyleTableCellText>
                <StyleTableCellText>{last_updated_by || "-"}</StyleTableCellText>
                <StyleTableCellText>
                  <div className="d-flex align-items-center">
                    <div>{contributors.length > 0 ? contributors[0] : "-"}</div>
                    {contributors.length > 1 && (
                      <Popup
                        trigger={
                          <Label circular className="ml-1 text-medium">
                            {`+${contributors.length - 1}`}
                          </Label>
                        }
                        content={contributors.join(", ")}
                        position="top center"
                        size="mini"
                        inverted
                      />
                    )}
                  </div>
                </StyleTableCellText>
                <StyleTableCellText>
                  <LatestDataPopup type="template" data={data} />
                </StyleTableCellText>
              </StyleTableRow>
            );
          }
        })}
      </Table.Body>
    </StyleTable>
  );
};
