import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { StyleSelect } from "./UIComponents";
import { Button, Icon } from "semantic-ui-react";
import { TransformOp } from "common/store/dataset/urlUpload";

interface IDataPrivacyEdit {
  store?: Store;
}

const DataPrivacyEditComponent = (props: IDataPrivacyEdit): JSX.Element => {
  const { store } = props;
  const urlupload = store!.dataset.urlupload;

  const [protectColSelectedOpts, setProtectColSelectedOpts] = React.useState<any[]>([]);

  const handleSubmit = async () => {
    const transformOps: TransformOp[] = [];
    if (protectColSelectedOpts.length === 1) {
      // simple hash of one column
      transformOps.push({ type: "column hash", config: protectColSelectedOpts[0].label });
    } else {
      // advanced hash of multiple columns; merge all first, rename and hash resultant column accordingly
      transformOps.push({
        type: "column merge",
        config: {
          columns: protectColSelectedOpts.map((opt) => opt.label),
          separator: " ",
        },
      });
      const mergedColName = protectColSelectedOpts.map((opt) => opt.label).join(" - ");
      transformOps.push({ type: "column rename", config: [protectColSelectedOpts[0].label, mergedColName] });
      transformOps.push({ type: "column hash", config: mergedColName });
    }
    await urlupload.applyTransformOpsPreview(transformOps);
    setProtectColSelectedOpts([]);
  };

  return (
    <>
      <p className="fs-1000 fw-700">Protect Sensitive Data</p>
      <p>Select a column which contains personal identifying information</p>
      <div className="mb-3">
        <StyleSelect
          isClearable
          isMulti
          name="private_cols"
          value={protectColSelectedOpts}
          placeholder="Select or search"
          options={urlupload.unprotectedStagedColIdxs.map((idx) => ({ value: idx, label: urlupload.staged![0][idx] }))}
          onChange={(selection) => setProtectColSelectedOpts(selection as any[])}
        />
      </div>
      <div className="d-flex justify-content-end">
        <Button disabled={!protectColSelectedOpts?.length} onClick={handleSubmit}>
          Protect
          <Icon name="eye slash outline" className="ml-2 mr-0" />
        </Button>
      </div>
    </>
  );
};

export const DataPrivacyEdit = inject("store")(observer(DataPrivacyEditComponent));
