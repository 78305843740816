import styled from "styled-components";
import { $darkgrey, $black, $border } from "./_config.style";
import { COLORS } from "component/UI/common";

export const StyleLabel = styled.p`
  color: ${COLORS.indigo600};
  font-weight: bold;
  text-align: left;

  font-size: 18px;
  line-height: 24px;
  margin: 10px 0 10px 0 !important;
`;

export const StyleSmallText = styled.p`
  color: ${$darkgrey};

  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  margin: 10px 0;
`;

export const StyleInput = styled.input`
  padding: 0 10px;
  width: 100%;
  height: 40px;
  background: white;
  border: ${$border};
  border-radius: 4px;
  margin: 0 0 10px 0;
  color: ${$black};
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  ::placeholder {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
  }
`;

export const StyleTerms = styled.span`
  color: ${COLORS.red500};
  font-weight: normal;
  cursor: pointer;
`;
