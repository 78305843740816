import * as React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm, Field } from "react-final-form";
import Store from "common/store";
import { Form } from "semantic-ui-react";
import { database, getMixpanel } from "common/api";
import { userExists } from "common/helpers/user";
import { createNewOrg } from "common/helpers/signup";
import { COLORS } from "component/UI/common";
import { FormHeading, FormWrapper } from "./includes/Components.style";
import { BottomSection } from "./includes/BottomSection";
import { composeValidators, requiredCustomMessage, mustBeNumber, required, mustBeValidEmail } from "common/helpers/finalForm";
import { FieldCheck } from "component/FinalFormFields/FieldCheck";
import { FORM_ERROR } from "final-form";
import { Button } from "component/UI/Button";
import { FieldInput } from "./includes/FieldInput";

// Sign Up Form Flow
// 1. Validate User Input
// 2. Send User Verification Code Via Email (ensures this is a real user and prevents typos)
// 3. Disable Other Fields and Validate User-Entered Verification Code
// 4. Create New Organisation

interface Props {
  store: Store;
}

interface InvitationProps {
  id: number;
  name: string;
  email: string;
  token: string;
  type: "partnership" | "membership";
}

interface FormValueProps {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  organisation: string;
  verification_code: string;
  agree_to: boolean;
}

const Component = (props: Props): JSX.Element => {
  const { store } = props;
  const [step, setStep] = useState(1);
  const [initialValues, setInitialValues] = useState<FormValueProps>({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    organisation: "",
    verification_code: "",
    agree_to: false,
  });
  const [invitation, setInvitation] = useState<InvitationProps | null>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const onSubmit = async (values: FormValueProps): Promise<any> => {
    const { email, first_name, last_name, phone, organisation, verification_code } = values;
    if (step === 1) {
      // Send verification email
      const emailAlreadyExists = await userExists(email);
      if (emailAlreadyExists) {
        return { [FORM_ERROR]: "This email address is already registered. Please log in or use a different email." };
      }
      const res: any = await database.post("emails", {
        email,
        full_name: `${first_name} ${last_name}`,
        type: "verification",
      });
      if (res.statusCode === 200) {
        setStep(2);
      } else {
        return { [FORM_ERROR]: "Something went wrong. Please check your email and try again." };
      }
    } else {
      const created = await createNewOrg(organisation, first_name, last_name, email, phone, verification_code, invitation?.token || "");
      if (!created) {
        if (created === null) {
          return { [FORM_ERROR]: "Something went wrong. Please try again later." };
        } else {
          return { [FORM_ERROR]: "The verification code entered seems to be wrong. Please try again." };
        }
      }
    }
  };

  useEffect(() => {
    const getInvitationDetail = async (token) => {
      const res: any = await database.get(`invitations/${token}`, "");
      if (res.body.data) {
        const { invitation } = res.body.data;
        setInvitation(invitation);
        setInitialValues({ ...initialValues, email: invitation.email });
      }
    };
    getMixpanel(store).track("Page view", { Page: "Signup owner" }); // only fire on mount
    if (token) {
      getInvitationDetail(token);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <FormWrapper>
        <FinalForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={(formRenderProps) => {
            const { handleSubmit, submitting, submitError } = formRenderProps;
            return (
              <Form onSubmit={handleSubmit} id={token ? "user-signup-neworg-accept-partner-invite" : "user-signup-neworg"}>
                {step === 1 ? (
                  <>
                    <div className="mb-4 text-center text-md-left">
                      <FormHeading>Join Seer Data & Analytics</FormHeading>
                      <p className="fs-1250 fw-600 my-0" style={{ color: COLORS.grey800 }}>
                        {invitation ? (
                          <>
                            <span className="fw-700">{invitation.name}</span> has invited you to become a partner organisation on Seer Data.
                          </>
                        ) : (
                          <>
                            <p>Create an account and start your 30 day Seer Data Plus trial today.</p>
                            <p>
                              On expiry of the trial period, your account will downgrade to Seer Standard. We do not ask for payment details
                              during the trial period.
                            </p>
                          </>
                        )}
                      </p>
                    </div>
                    <Field
                      id="signup-email-label"
                      label="Your work email"
                      name="email"
                      component={FieldInput}
                      placeholder="E.g user@company.com"
                      validate={composeValidators(required, mustBeValidEmail)}
                      className="mb-4"
                      disabled={invitation}
                    />
                    <Field
                      id="signup-first-name-label"
                      label="First name"
                      name="first_name"
                      component={FieldInput}
                      validate={composeValidators(required)}
                      className="mb-4"
                    />
                    <Field
                      id="signup-last-name-label"
                      label="Last name"
                      name="last_name"
                      component={FieldInput}
                      validate={composeValidators(required)}
                      className="mb-4"
                    />
                    <Field
                      id="signup-phone-label"
                      label="Contact phone number"
                      name="phone"
                      component={FieldInput}
                      validate={composeValidators(required, mustBeNumber)}
                      className="mb-4"
                    />
                  </>
                ) : (
                  <>
                    <div className="mb-4">
                      <p className="fs-2000 fw-700 mt-0 mb-3" style={{ color: COLORS.indigo600 }}>
                        Finish creating your account
                      </p>
                      <p className="fs-1250 fw-600 my-0" style={{ color: COLORS.grey800 }}>
                        Check your work email account for a temporary sign in code.
                      </p>
                    </div>
                    <Field
                      id="signup-organisation-label"
                      label="Organisation"
                      name="organisation"
                      component={FieldInput}
                      validate={composeValidators(required)}
                      helperText="This will be the name of your group. Don't worry you can change it later in your account settings."
                      className="mb-4"
                    />
                    <Field
                      id="signup-verification-code-label"
                      label="Verification code"
                      name="verification_code"
                      type="password"
                      component={FieldInput}
                      validate={composeValidators(required)}
                      helperText="We just sent you a temporary verification code, please check your inbox."
                      className="mb-4"
                    />
                    <Field
                      id="signup-agree-to-label"
                      name="agree_to"
                      label="You agree to the Terms of Service and Privacy Policy"
                      component={FieldCheck}
                      validate={requiredCustomMessage("Please read over and agree to the Terms of Service and Privacy Policy to continue.")}
                    />
                  </>
                )}
                <Button fluid colorConfig="purpleInverted" type="submit" disabled={submitting}>
                  {step === 1 ? "Send temporary login code" : "Create organisation account"}
                </Button>
                {submitError && (
                  <p role="alert" style={{ color: COLORS.red500 }}>
                    <b>{submitError}</b>
                  </p>
                )}
              </Form>
            );
          }}
        />
      </FormWrapper>
      <BottomSection text="Already have an account?" label="Login" link="/login" />
    </>
  );
};

export const Signup = inject("store")(observer(Component));
