import * as React from "react";
import { isEqual } from "lodash";
import { Checkbox, Segment, Table, Popup, Label, Header } from "semantic-ui-react";

interface ILocaliseSuitcaseInsightsTable {
  insights: any[];
  selectedInsightIDs: number[];
  selectAllChangeHandler: (isAllInsightsSelected) => void;
  checkboxChangeHandler: (id) => void;
}

export const LocaliseSuitcaseInsightsTable = ({
  insights,
  selectedInsightIDs,
  selectAllChangeHandler,
  checkboxChangeHandler,
}: ILocaliseSuitcaseInsightsTable): JSX.Element => {
  // Get al insight ids and selected insights ids in ascending order then compare
  const ascendingOrderSelectedInsightIDs = [...selectedInsightIDs].sort((a, b) => a - b);
  const ascendingOrderAllInsightIDs = insights.map((insight) => insight.id).sort((a, b) => a - b);
  const isAllInsightsSelected = isEqual(ascendingOrderSelectedInsightIDs, ascendingOrderAllInsightIDs);

  return (
    <>
      <Header as="h5" className="my-0">
        Select Insights to localise
      </Header>
      {insights.length > 0 ? (
        <Segment className="p-0 overflow-auto" style={{ maxHeight: 375 }}>
          <Table celled striped selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing className="fs-1125 text-secondary">
                  <div className="d-flex align-items-center cursor-pointer" onClick={() => selectAllChangeHandler(isAllInsightsSelected)}>
                    <Checkbox className="mr-2" checked={isAllInsightsSelected} />
                    <span className="fs-1000 fs-500">Select All</span>
                  </div>
                </Table.HeaderCell>
                <Table.HeaderCell className="fs-1125 text-secondary">Insight Name</Table.HeaderCell>
                <Table.HeaderCell className="fs-1125 text-secondary">Variables</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {insights.map((insight) => {
                const { id, name, insightVariables } = insight;
                return (
                  <Table.Row key={id}>
                    <Table.Cell collapsing className="fw-700">
                      <Checkbox checked={selectedInsightIDs.indexOf(id) >= 0} onChange={() => checkboxChangeHandler(id)} />
                    </Table.Cell>
                    <Table.Cell className="fw-700">{name}</Table.Cell>
                    <Table.Cell className="fw-700">
                      {insightVariables && (
                        <Popup
                          size="mini"
                          inverted
                          content={
                            <div>
                              {Object.keys(insightVariables).map((variable) => (
                                <div key={variable} className="mb-1">
                                  <p className="mb-1 fs-1000">{variable}</p>
                                  <Label.Group circular size="small">
                                    {insightVariables[variable].map((category) => (
                                      <Label key={`${category}-${typeof category}`}>{category}</Label>
                                    ))}
                                  </Label.Group>
                                </div>
                              ))}
                            </div>
                          }
                          trigger={<div className="text-primary">View list</div>}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Segment>
      ) : (
        <p className="fs-1125 fw-700 text-medium">No Insights available.</p>
      )}
    </>
  );
};
