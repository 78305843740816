import { includes } from "lodash";
import { database } from "common/api";
import Store from "common/store";
import { ls } from "./storage";
import { ObjectAny } from "common/helpers/types";
import { environment } from "common/constants";


export const userExists = async (email: string): Promise<boolean> => {
  const res: any = await database.head("users", { email });
  return res.statusCode === 200;
};

const tempUnlayerUsers = [774,277,650,824,820]; // @TODO - remove user restriction after go live (currently Seer Team - Ricki, Cat, Lavinia, Ryan, Nik)
export const getFeaturePermissions = (store: Store): ObjectAny => {
  const unlayerEditor = includes(["Plus", "Unlimited"], store.userPlan) && (environment !== "production" || includes(tempUnlayerUsers, store.user?.id));
  return {
    unlayerEditor,
    // @TODO - add more feature checks..
  };
};

export const getExplorePath = () => ls.getItem("isAdvancedExploreUser")
  ? "/builder/new"
  : "/explore";
