import { Heading } from "common/styledComponents/elements";
import * as React from "react";
import { PageContainer } from "./PageContainer";

export const PlatformGeneralError = (): JSX.Element => (
  <PageContainer>
    <div className="text-center mt-5 pt-5">
      <Heading className="fs-5000">Oops!</Heading>
      <Heading>Something unexpected has happened...</Heading>

      <img className="my-5" width="30%" src="/assets/error/storm_cloud.png" alt="Storm Cloud" />

      <p className="fs-1500">Would you like to refresh the page and try again?</p>
      <p className="fs-1500">Please reach out to support if you need any assistance.</p>
    </div>
  </PageContainer>
);
