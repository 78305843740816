import * as React from "react";
import styled from "styled-components";
import { COLORS } from "component/UI/common";
import { Header } from "semantic-ui-react";

const Container = styled.div`
  background-color: ${COLORS.grey900};
  padding: 140px 0px;
  position: relative;
  margin-bottom: -20px;
`;

const ContentWrapper = styled.div`
  max-width: 940px;
  margin: 0 auto;
  color: #fff;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 940px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Footer = () => (
  <Container>
    <img src="/assets/homepage/homepage_footer_bg_right.png" className="position-absolute" style={{ width: 360, top: 0, right: 0 }} />
    <img src="/assets/homepage/homepage_footer_bg_left.png" className="position-absolute" style={{ width: 360, bottom: 0, left: 0 }} />
    <ContentWrapper>
      <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center mb-3">
        <div className="d-flex mr-4 mb-3 mb-sm-0">
          <img src="/assets/homepage/homepage_footer_aboriginal_flag.jpg" alt="Aboriginal Flag" className="mr-3" />
          <img src="/assets/homepage/homepage_footer_torres_strait_islander_flag.jpg" alt="Torres Strait Islander" />
        </div>
        <Header as="h4" className="my-0" inverted>
          Acknowledgement of Country
        </Header>
      </div>
      <p className="fw-600 fs-1250">
        Seer Data & Analytics acknowledges all Aboriginal and Torres Strait Islander Traditional Custodians of Country and recognises their
        continuing connection to land, sea, culture and community. We pay our respects to leaders past, present and emerging.
      </p>
    </ContentWrapper>
  </Container>
);
