import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { ContentPlaceholder } from "component/ContentPlaceholder";
import { CompletenessMeasureType } from "common/store/completenessMeasure";
import { Button, Icon, Message } from "semantic-ui-react";
import { CompletenessMeasuresList } from "./CompletenessMeasuresList";
import Store from "common/store";
import { webUrl } from "common/constants";
import { getMixpanel } from "common/api";
import { COLORS } from "component/UI/common";
import { EmptyStateSecondary, ListPageEmptyStateDescription } from "component/EmptyState/EmptyState";

interface Props {
  datasetID: number;
  store?: Store;
}

const StyledMessage = styled(Message)`
  &.ui.compact.icon.message {
    position: fixed !important;
    bottom: 20px;
    background: ${COLORS.aqua200};
    color: ${COLORS.aqua600};
    border-radius: 0.5rem;
    border: 1px solid ${COLORS.aqua600};
    font-weight: 700;
    width: auto !important;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    box-shadow: none;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }
  &.ui.compact.icon.message.show {
    opacity: 1;
  }
  &.ui.icon.message > .icon:not(.close) {
    font-size: 1rem !important;
  }
`;

const Component = (props: Props): JSX.Element => {
  const { datasetID, store } = props;
  const { completenessMeasure: measureStore } = store!;

  const [completenessMeasures, setCompletenessMeasures] = React.useState<CompletenessMeasureType[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

  const sortedCompletenessMeasures = completenessMeasures.sort((a, b) => a.name.localeCompare(b.name));

  const loadCompletenessMeasures = async () => {
    const res: any = await measureStore.getCompletenessMeasures(datasetID);
    setLoading(false);
    if (res.data) {
      setCompletenessMeasures(res.data.dataset_completeness_measures as CompletenessMeasureType[]);
    }
  };

  const createCompletenessMeasure = () => {
    window.location.href = `${webUrl}/datasets/${datasetID}/completeness-measures/new`;
  };

  const editCompletenessMeasure = (measureID: number) => {
    window.location.href = `${webUrl}/datasets/${datasetID}/completeness-measures/${measureID}`;
  };

  const deleteCompletenessMeasure = async (measureID: number): Promise<void> => {
    const success = await measureStore.deleteCompletenessMeasure(measureID);
    if (success) {
      await loadCompletenessMeasures();
    }
    getMixpanel(store!).track("Completeness Measures > Delete Measure", { "Dataset Id": datasetID, "Measure Id": measureID });
  };

  const copyEmbedLink = async (measureID: number, e: React.MouseEvent<HTMLElement>): Promise<void> => {
    e.stopPropagation();
    const embedLink = `<div style="height: 250px"><iframe class="seer-dashboard-completeness-measure" loading="lazy" style="width: 100%; height: 100%; border: none;" src="${webUrl}/embed/dataset-completeness-measure?id=${measureID}" allow="fullscreen"></iframe></div>`;
    try {
      navigator.clipboard.writeText(embedLink);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
      getMixpanel(store!).track("Completeness Measures > Copy Embed Link", { "Dataset Id": datasetID, "Measure Id": measureID });
    } catch (err) {
      console.log("Failed to copy:", err);
    }
  };

  React.useEffect(() => {
    loadCompletenessMeasures();
    getMixpanel(store!).track("Completeness Measures");
  }, []);

  React.useEffect(() => {
    const reloadCompletenessMeasuresEvent = (res: any) => {
      if (res.dataset_id === datasetID) {
        loadCompletenessMeasures();
      }
    };
    store!.socket.on("completenessMeasureUpdated", reloadCompletenessMeasuresEvent);
    return () => {
      store!.socket.off("completenessMeasureUpdated", reloadCompletenessMeasuresEvent);
    };
  }, []);

  if (loading) {
    return <ContentPlaceholder />;
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button color="red" className="flex-shrink-0" onClick={() => createCompletenessMeasure()}>
          Create Measure <Icon name="plus" className="mr-0 ml-1" />
        </Button>
      </div>
      <div>
        {sortedCompletenessMeasures.length ? (
          <CompletenessMeasuresList
            completenessMeasures={sortedCompletenessMeasures}
            editHandler={(id) => editCompletenessMeasure(id)}
            deleteHandler={(id) => deleteCompletenessMeasure(id)}
            copyHandler={(id, e) => copyEmbedLink(id, e)}
          />
        ) : (
          <EmptyStateSecondary
            heading="No Completeness Measures Created Yet"
            description={
              <ListPageEmptyStateDescription
                description="Here is where you will find data completeness measures created for this dataset."
                link="https://knowledge.seerdata.ai/what-is-a-dataset-completeness-measure"
                text="completeness measures"
              />
            }
          />
        )}
      </div>
      <StyledMessage
        size="mini"
        compact
        content="Embed code was successfully copied to clipboard"
        icon="check circle"
        className={`${showSuccessMessage ? "show" : ""}`}
      />
    </>
  );
};

export const CompletenessMeasures = inject("store")(observer(Component));
