import * as React from "react";
import { Modal } from "semantic-ui-react";
import { inject, observer } from "mobx-react";
import { uniqueId } from "lodash";
import Select from "react-select";
import Store from "common/store";
import { getMixpanel, database, datasets } from "common/api";
import { ContentPlaceholder } from "component/ContentPlaceholder";
import { SeerButton } from "pages/PartnerOrganisations/includes/PartnerOrganisationsButtons";
import { toolName as insightToolName } from "component/Unlayer/includes/customJsInsightTool";
import { sortDimensionValues, transformResToDims } from "common/helpers/data";

interface ComponentProps {
  store?: Store;
  isOpen: boolean;
  data: any;
  close: () => any;
  editorRef: any;
}

interface IState {
  loading: boolean;
  selectionData: any;
  variableOptionData: any;
  categoryOptionData: any;
}

class Component extends React.Component<ComponentProps> {
  state: IState = {
    loading: false,
    selectionData: undefined,
    variableOptionData: undefined,
    categoryOptionData: undefined,
  };

  saveFilter = async () => {
    const { editorRef, close, data } = this.props;
    const { selectionData } = this.state;
    const { variable } = selectionData;
    editorRef.frame.iframe.contentWindow.postMessage(
      {
        ...data,
        data: selectionData,
      },
      "https://editor.unlayer.com",
    );
    getMixpanel(this.props.store!).track("Save Filter to Dashboard", { Variable: variable });
    close();
  };

  onSelectVariable = async (newValue: any) => {
    const { store } = this.props;
    const nextSelectionData = newValue?.data;
    let nextCategoryOptionData = [];
    const { variable } = nextSelectionData;
    this.setState({ loading: true });
    const res: any = await datasets.post("v2/qs", { filters: { [variable]: [] } }, store!.token!);
    if (res?.body?.data?.datasets) {
      const dims = sortDimensionValues(transformResToDims(res));
      nextCategoryOptionData = dims[variable].values.map((value) => value.name);
      // set the categories automatically to all if less than 100 otherwise force the user to pick out the desired categories
      nextSelectionData.categories = nextCategoryOptionData.length < 100 ? nextCategoryOptionData : [];
    }
    this.setState({ selectionData: nextSelectionData, categoryOptionData: nextCategoryOptionData });
    this.setState({ loading: false });
  };

  // loads variable data for filter options
  loadVariableOptionData = async () => {
    this.setState({ loading: true });
    const { editorRef, store } = this.props;
    const insightIds: any = await new Promise((resolve) => {
      editorRef.exportHtml(async (editorData) => {
        // get unique embeded insight ids
        const embededInsights = (editorData?.design?.body?.rows || [])
          .reduce((prev, next) => [...prev, ...(next.columns || [])], [])
          .reduce((prev, next) => [...prev, ...(next.contents || [])], [])
          .filter((content) => content.slug === insightToolName);
        const uniqueInsightIds = Array.from(
          new Set(
            embededInsights
              .map((config) => {
                let id = 0;
                try {
                  id = JSON.parse(config.values.insightData).id;
                } catch (e) {
                  // continue if broken json, id = 0 will be filtered out
                }
                return id;
              })
              .filter((id) => !!id),
          ),
        );
        resolve(uniqueInsightIds);
      });
    });
    // load unique insights and compile option data
    const variableMap = {};
    for (const id of insightIds) {
      try {
        const res: any = await database.get(`insights/${id}`, null, store!.token!);
        const json = JSON.parse(res.body.data.insight.json);
        const filters = [
          ...(json?.columns || []),
          ...(json?.tables || []).reduce((prev, next) => [...prev, ...(next.rows || []), ...(next.filters || [])], []),
        ];
        for (const filter of filters) {
          variableMap[filter.dimension] = [...(variableMap[filter.dimension] || []), ...filter.values];
        }
      } catch (e) {
        // continue and ignore any broken insights
      }
    }
    const optionData: any = [];
    // filter down unique categories and sort them
    for (const variable of Object.keys(variableMap)) {
      // categories are now populated dynamically on save filter
      optionData.push({ variable, filterId: uniqueId(), categories: [] });
    }
    // sort options by variable name before finishing
    optionData.sort((a, b) => a.variable.localeCompare(b.variable));
    this.setState({ variableOptionData: optionData, loading: false });
  };

  componentDidUpdate(prevProps: ComponentProps): void {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ selectionData: undefined, optionData: undefined });
      if (this.props.isOpen) {
        this.loadVariableOptionData();
      }
    }
  }

  render(): JSX.Element {
    const { loading, selectionData, variableOptionData, categoryOptionData } = this.state;
    const { isOpen, close } = this.props;
    const variableOptions = (variableOptionData || []).map((opt) => ({
      key: opt.variable,
      value: opt.variable,
      label: opt.variable,
      data: opt,
    }));
    const selectedVariable = selectionData ? variableOptions.find((opt) => opt.key === selectionData.variable) : null;
    const categoryOptions = selectionData ? (categoryOptionData || []).map((opt) => ({ key: opt, value: opt, label: opt })) : [];
    const selectedCategories = selectionData?.categories ? categoryOptions.filter((opt) => selectionData.categories.includes(opt.key)) : [];

    return (
      <Modal open={isOpen} onClose={close}>
        <Modal.Header className="fs-2000 fw-700 text-secondary">Select Filter</Modal.Header>
        <Modal.Content>
          <p className="text-secondary fs-1250 fw-600 my-2">Select a variable to filter on</p>
          {loading ? (
            <ContentPlaceholder />
          ) : (
            <>
              <Select
                autoFocus
                placeholder="Select a variable to filter on"
                options={variableOptions}
                onChange={this.onSelectVariable}
                isDisabled={loading || !variableOptionData}
                value={selectedVariable}
              />
              {selectionData && (
                <>
                  <p className="text-secondary fs-1250 fw-600 my-2">Select categories to make available</p>
                  <Select
                    isMulti
                    placeholder="Select categories to make available"
                    options={categoryOptions}
                    onChange={(newValue) => {
                      const nextSelectionData = { ...selectionData, categories: newValue.map((opt) => opt.key) };
                      this.setState({ selectionData: nextSelectionData });
                    }}
                    value={selectedCategories}
                    isDisabled={loading || !categoryOptionData}
                  />
                </>
              )}
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <SeerButton category="secondary" label="Cancel" disabled={loading} onClick={close} />
          <SeerButton category="primary" label="Save" disabled={loading || !selectionData?.categories?.length} onClick={this.saveFilter} />
        </Modal.Actions>
      </Modal>
    );
  }
}

export const FilterPickerModal = inject("store")(observer(Component));
