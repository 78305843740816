/**
 * Global and environment specific constants
 */

const { hostname, protocol } = window.location;
const mixpanelTokenProd = "a8989c765b2bada034d0a6a05b4d321b"; // production
const mixpanelTokenTest = "66c19724f3f01876b092e864cbbe27fb";
const mixpanelProxyUrlProd = "https://config.seerplatform.com";
const mixpanelProxyUrlTest = "https://config.staging.seerplatform.com";
const hubspotScriptSrcProd = "//js.hs-scripts.com/8971531.js";
const hubspotScriptSrcTest = "//js.hs-scripts.com/9235068.js";
const userflowTokenTest = "ct_7jhcdtv5ujcedmjm4s457mppji";
const userflowTokenProd = "ct_hgyxukll7ngpvasbzvw4uca2la";

export const environment = (() => {
  if (hostname.match(/localhost/)) {
    return "local";
  } else if (hostname.match(/dev/)) {
    return "development";
  } else if (hostname.match(/staging/)) {
    return "staging";
  } else {
    return "production";
  }
})();

const environmentSpecificVars = {
  // Set the following env variables to use endpoints other than the current host (on local / dev environment)
  // export BACKEND_URL='http://backend.staging.seerplatform.com' DATA_URL='http://data.staging.seerplatform.com'
  local: {
    webUrl: `${protocol}//${hostname}:5001`,
    backendUrl: process.env.BACKEND_URL || `${protocol}//${hostname}:8081`,
    dataUrl: process.env.DATA_URL || `${protocol}//${hostname}:5000`,
    mixpanelToken: mixpanelTokenTest,
    mixpanelProxyUrl: mixpanelProxyUrlTest,
    hubspotScriptSrc: hubspotScriptSrcTest,
    userflowToken: userflowTokenTest,
  },
  development: {
    webUrl: `${protocol}//${hostname}`,
    backendUrl: `${protocol}//backend.${hostname}`,
    dataUrl: `${protocol}//data.${hostname}`,
    mixpanelToken: mixpanelTokenTest,
    mixpanelProxyUrl: mixpanelProxyUrlTest,
    hubspotScriptSrc: hubspotScriptSrcTest,
    userflowToken: userflowTokenTest,
  },
  staging: {
    webUrl: `${protocol}//${hostname}`,
    backendUrl: `${protocol}//backend.${hostname}`,
    dataUrl: `${protocol}//data.${hostname}`,
    mixpanelToken: mixpanelTokenTest,
    mixpanelProxyUrl: mixpanelProxyUrlTest,
    hubspotScriptSrc: hubspotScriptSrcTest,
    userflowToken: userflowTokenTest,
  },
  production: {
    webUrl: `${protocol}//${hostname}`,
    backendUrl: `${protocol}//backend.${hostname}`,
    dataUrl: `${protocol}//data.${hostname}`,
    mixpanelToken: mixpanelTokenProd,
    mixpanelProxyUrl: mixpanelProxyUrlProd,
    hubspotScriptSrc: hubspotScriptSrcProd,
    userflowToken: userflowTokenProd,
  },
};

export const { webUrl, backendUrl, dataUrl, mixpanelToken, mixpanelProxyUrl, hubspotScriptSrc, userflowToken } =
  environmentSpecificVars[environment];

// any global vars related to pdf print page
export const printPdf = {
  scrollIntervalVarName: "seerPrintInsightsScrollInterval", // name of window variable to store interval id in
  insightsLoadedIntervalName: "seerPrintInsightsLoadedInterval", // name of window variable to store interval id in
  insightsLoadedVarName: "seerPrintInsightsLoaded", // name of window variable to store how many insights we have finished loading data for
};
