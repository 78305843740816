import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { ListItem } from "semantic-ui-react";
import { Aggregation } from "common/store/dataset/urlUpload";
import { StyledList } from "../DatasetURLUpload.style";

interface IAggregationsList {
  store?: Store;
}

const AggregationsListComponent = (props: IAggregationsList): JSX.Element => {
  const { store } = props;
  // const urlupload = store!.dataset.urlupload;

  // const [doAutoAggregate, setDoAutoAggregate] = React.useState(false);

  // const hasAggregations = !!urlupload.aggregations.length;

  const displayAggregationRule = (item: Aggregation) => {
    if (item.type === "count all") {
      return <>Number of records</>;
    } else {
      return (
        <>
          {item.column} - {item.type}
        </>
      );
    }
  };

  return (
    <>
      {/* @TODO - auto aggs to be re-enabled when backend is ready to handle them appropriately */}
      {/*
      <div className="d-flex justify-content-between">
        <p className="fs-1000 fw-700">Auto Aggregation</p>
        <Checkbox
          toggle
          checked={doAutoAggregate}
          onChange={(_e, { checked }) => {
            const nextChecked = checked || false;
            if (nextChecked) {
              const ok = hasAggregations
                ? confirm("Applying Auto Aggregations will reset any existing aggregations. Confirm to continue.")
                : true;
              if (!ok) {
                return;
              }
              urlupload.setAutoAggregations();
              setDoAutoAggregate(nextChecked);
            }
            setDoAutoAggregate(nextChecked);
          }}
        />
      </div>
      <Divider />
       */}
      <StyledList ordered>
        {store!.dataset.urlupload.aggregations.map((agg) => (
          <>
            <ListItem>{displayAggregationRule(agg)}</ListItem>
          </>
        ))}
      </StyledList>
    </>
  );
};

export const AggregationsList = inject("store")(observer(AggregationsListComponent));
