import * as React from "react";
import { Icon, Segment } from "semantic-ui-react";

export const NewDataSourceTypeCustom = () => (
  <>
    <Segment basic textAlign="left">
      <p className="fs-1000 fw-700">{"Import Existing Data > Other"}</p>
    </Segment>
    <Segment basic textAlign="center">
      <h3>
        Contact Request Submitted <Icon name="check circle" color="green" />{" "}
      </h3>
      <p className="fs-1125">Thank you - someone from our team will be in touch within the next 2 business days.</p>
    </Segment>
  </>
);
