import styled from "styled-components";
import { Dropdown, Search } from "semantic-ui-react";
import { purpleBoxShadow, scrollBarStyle } from "component/Header/includes/header.style";
import { COLORS } from "component/UI/common";

export const StyledHeaderSearchWrapper = styled.div`
  position: relative;
  @media screen and (max-width: 480px) {
    flex-grow: 1;
  }
`;

export const StyledSearchBox = styled(Search)`
  display: inline-block;
  margin-left: 20px;
  font-size: 13px;
  .ui.input {
    width: 400px;
    height: 34px;
    display: flex;
  }
  &.ui.search .prompt ~ .search.icon {
    color: ${COLORS.indigo500};
  }
  .ui.icon.input > i.icon {
    opacity: 1;
  }
  .ui.input > input {
    border: none;
    background-color: ${COLORS.indigo100};
    color: ${COLORS.indigo600};
  }
  .ui.input.focus > input,
  .ui.input > input:focus {
    border: none;
  }
  .ui[class*="left icon"].input > input#header_search {
    padding-right: 105px !important;
  }
  &.ui.search > .results {
    max-height: 600px;
    overflow-y: auto;
    width: 400px !important;
    border: none;
    box-shadow: ${purpleBoxShadow} !important;
    ${scrollBarStyle}
  }
  &.ui.category.search > .results .category .result:hover,
  .ui.search > .results .result:hover {
    background: ${COLORS.indigo100};
  }
  &.ui.category.search > .results .category {
    display: block;
  }
  &.ui.category.search > .results .category > .name {
    display: flex;
    width: 100%;
    height: 44px;
    background-color: ${COLORS.indigo400};
  }
  &.ui.category.search > .results .category .results {
    display: block;
    width: 100%;
  }
  @media screen and (max-width: 1084px) {
    .ui.input {
      width: 350px;
    }
    &.ui.search > .results {
      width: 350px !important;
    }
    margin-left: 0;
  }
  @media screen and (max-width: 480px) {
    .ui[class*="left icon"].input > input#header_search {
      padding-right: 1em !important;
    }
    .ui.input {
      width: 100%;
    }
    &.ui.mini.search {
      width: 100%;
    }
    &.ui.search > .results {
      width: 100% !important;
    }
  }
`;

export const StyledDropDown = styled(Dropdown)`
  &.ui.dropdown {
    position: absolute;
    right: 15px;
    top: 9px;
    font-weight: 700;
    font-size: 13px;
    color: ${COLORS.indigo600};
  }
  &.ui.dropdown > .dropdown.icon {
    margin-left: 5px;
  }
  &.ui.dropdown .menu > .item {
    font-size: 13px;
  }
  &.ui.dropdown .menu .selected.item,
  .ui.dropdown.selected {
    color: ${COLORS.indigo600};
  }
  &.ui.dropdown .menu > .item {
    padding: 10px 15px !important;
    border-radius: 4px;
    &:hover {
      background: ${COLORS.indigo100} !important;
    }
  }
  &.ui.dropdown .menu .selected.item,
  .ui.dropdown.selected {
    background: ${COLORS.indigo100} !important;
  }
  &.ui.dropdown > .left.menu {
    margin-top: 10px;
    padding: 10px;
    border: 0;
    box-shadow: ${purpleBoxShadow} !important;
  }
`;
