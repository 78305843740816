import Store from "../store";
import { database } from "common/api";

export interface CompletenessMeasureType {
  name: string;
  dataset_id: number;
  value: number | null;
  config: CompletenessMeasureConfig;
  updated_at: string;
  refresh_triggered_at: string;
}

export interface CompletenessMeasureAdvancedConfig {
  measuredQuantities?: string[]; // required if aggregate dataset
  variables?: string[]; // required if URL dataset
  limitCategories: boolean;
  categoryLimits?: {
    variable: string;
    categories: string[];
  }[];
  timeLimit: boolean;
  timeType?: "absolute" | "relative";
  absoluteTime?: {
    from: string; // iso date string
    to: string; // iso date string
  };
  relativeTime?: {
    unit: "day" | "week" | "month" | "year";
    value: number;
  };
}
export interface CompletenessMeasureConfig {
  type: "whole" | "advanced";
  advanced?: CompletenessMeasureAdvancedConfig;
}

export default class CompletenessMeasure {
  parent: Store;

  constructor(parent: Store) {
    this.parent = parent;
  }

  getCompletenessMeasures = async (datasetId: number): Promise<any> => {
    const res: any = await database.get(`datasets/${datasetId}/completeness-measures`, "", this.parent.token!);
    return res?.body;
  };

  createCompletenessMeasure = async (datasetId: number, body: Partial<CompletenessMeasureType>): Promise<boolean> => {
    const res: any = await database.post(`datasets/${datasetId}/completeness-measures`, body, this.parent.token!);
    return res?.statusCode === 200;
  };

  getCompletenessMeasure = async (id: number): Promise<any> => {
    const res: any = await database.get(`dataset-completeness-measures/${id}`, "", this.parent.token!);
    return res?.body;
  };

  updateCompletenessMeasure = async (id: number, body: Partial<CompletenessMeasureType>): Promise<boolean> => {
    const res: any = await database.put(`dataset-completeness-measures/${id}`, body, this.parent.token!);
    return res?.statusCode === 200;
  };

  deleteCompletenessMeasure = async (id: number): Promise<boolean> => {
    const res: any = await database.delete(`dataset-completeness-measures/${id}`, "", this.parent.token!);
    return res?.statusCode === 200;
  };
}
