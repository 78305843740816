import * as React from "react";
import { inject } from "mobx-react";
import { Form, FormField, Button, Icon, Checkbox, Popup } from "semantic-ui-react";
import { Builder } from "common/store/builder";
import {
  getMinAndMaxChartData,
  getMinAndMaxIncrements,
  ifCanShowBarLabel,
  ifChartCanSetAxisIncrements,
  ifChartCanSetAxisValue,
  ifValidIncrement,
  isProportionsOrPercentageChart,
} from "./helper";
import { observer } from "mobx-react-lite";
import { defaultAngle, defaultLabelFont } from "common/helpers/chart";

interface IExploreChartBasic {
  builder?: Builder;
}

const ExploreChartBasicComponent = (props: IExploreChartBasic): JSX.Element | null => {
  const [errors, setErrors] = React.useState({
    showLabelFontError: false,
    showAngleError: false,
    showLabelLengthError: false,
    showMaxAxisValueError: false,
    showMinAxisValueError: false,
    showAxisIncrementsError: false,
    showBarLabelError: false,
    showAxisTickIntervalError: "",
  });

  const { builder } = props;
  const { chart, decimalPoints, newChartData } = builder!;
  const {
    type,
    yLabel,
    xLabel,
    percentage,
    labelFont,
    angle,
    labelLength,
    userDefinedMaxValue,
    userDefinedMinValue,
    userDefinedIncrements,
    showBarLabel,
    barLabelFontSize,
    showRawValueInTooltip,
    condensedLegend,
    hideTableNames,
    condensedEmbed,
    showSource,
    axisTickInterval,
    metricLabelOverride,
  } = chart;
  const { dataMin, dataMax } = getMinAndMaxChartData(newChartData);
  const { minIncrement, maxIncrement } = getMinAndMaxIncrements(userDefinedMinValue, userDefinedMaxValue, type);

  if (!chart) {
    return null;
  }

  const setLabelFont = (e) => {
    const labelFontValue = parseFloat(e.target.value);
    if (labelFontValue >= 5 && labelFontValue <= 20) {
      setErrors({ ...errors, showLabelFontError: false });
      builder!.updateChart("labelFont", labelFontValue);
    } else {
      setErrors({ ...errors, showLabelFontError: true });
    }
  };

  const setAngle = (e) => {
    const angleValue = parseFloat(e.target.value);
    if (angleValue >= -90 && angleValue <= 90) {
      setErrors({ ...errors, showAngleError: false });
      builder!.updateChart("angle", angleValue);
    } else {
      setErrors({ ...errors, showAngleError: true });
    }
  };

  const setLabelLength = (e) => {
    const labelLengthValue = parseFloat(e.target.value);
    if (labelLengthValue > 0) {
      setErrors({ ...errors, showLabelLengthError: false });
      builder!.updateChart("labelLength", labelLengthValue);
    } else {
      setErrors({ ...errors, showLabelLengthError: true });
    }
  };

  const setAxisValue = (e: React.ChangeEvent<HTMLInputElement>, type: "Min" | "Max") => {
    const userDefinedValue = parseFloat(e.target.value as string);
    const errorKey = `show${type}AxisValueError`;
    const valueKey = `userDefined${type}Value`;
    if (isNaN(userDefinedValue)) {
      // When value is cleared in the input, set `userDefined${type}Value` to undefined
      setErrors({ ...errors, [errorKey]: false });
      builder!.updateChart(valueKey, undefined);
      builder!.updateChart("userDefinedIncrements", undefined); // When max or min value is cleared, need to clear increment field as well
    } else {
      if ((type === "Min" && userDefinedValue <= dataMin) || (type === "Max" && userDefinedValue >= dataMax)) {
        setErrors({ ...errors, [errorKey]: false });
        builder!.updateChart(valueKey, userDefinedValue);
      } else {
        setErrors({ ...errors, [errorKey]: true });
      }
    }
  };

  const setAxisIncrement = (e) => {
    const userDefinedIncrements = parseFloat(e.target.value as string);
    if (isNaN(userDefinedIncrements)) {
      setErrors({ ...errors, showAxisIncrementsError: false });
      builder!.updateChart("userDefinedIncrements", undefined);
    } else {
      builder!.updateChart("userDefinedIncrements", userDefinedIncrements);
      setErrors({
        ...errors,
        showAxisIncrementsError: !ifValidIncrement(userDefinedIncrements, userDefinedMinValue, userDefinedMaxValue, type),
      });
    }
  };

  const setAxisTickInterval = (e) => {
    const userDefinedInterval = parseFloat(e.target.value);
    if (isNaN(userDefinedInterval)) {
      setErrors({ ...errors, showAxisTickIntervalError: "" });
      builder!.updateChart("axisTickInterval", undefined);
    } else if (userDefinedInterval < 0) {
      setErrors({ ...errors, showAxisTickIntervalError: "Value must be greater than or equal to 0" });
    } else if (!Number.isInteger(userDefinedInterval)) {
      setErrors({ ...errors, showAxisTickIntervalError: "Value must be an integer" });
    } else {
      setErrors({ ...errors, showAxisTickIntervalError: "" });
      builder!.updateChart("axisTickInterval", userDefinedInterval);
    }
  };

  const setBarLabelValue = (e) => {
    const userDefinedBarLabelFontSize = parseFloat(e.target.value as string);
    if (userDefinedBarLabelFontSize >= 5 && userDefinedBarLabelFontSize <= 20) {
      setErrors({ ...errors, showBarLabelError: false });
      builder!.updateChart("barLabelFontSize", userDefinedBarLabelFontSize);
    } else {
      setErrors({ ...errors, showBarLabelError: true });
    }
  };

  return (
    <div className="mb-6 seer-default-styles">
      <Form onSubmit={() => null} className="mb-3">
        {(type.includes("bar") || type.includes("Line")) && (
          <>
            <FormField>
              <label>Vertical Axis Title</label>
              <input type="text" value={yLabel} onChange={(e) => builder!.updateChart("yLabel", e.target.value)} />
            </FormField>
            <FormField>
              <label>Horizontal Axis Title</label>
              <input type="text" value={xLabel} onChange={(e) => builder!.updateChart("xLabel", e.target.value)} />
            </FormField>
          </>
        )}
        <FormField>
          <label>Percentage / Decimal</label>
          <Button attached="left" size="tiny" active={percentage} onClick={() => builder!.updateChart("percentage", true)}>
            %
          </Button>
          <Button attached="right" size="tiny" active={!percentage} onClick={() => builder!.updateChart("percentage", false)}>
            0.0
          </Button>
        </FormField>
        {(type.includes("bar") || type.includes("Line")) && (
          <>
            <FormField>
              <label>Label Font Size</label>
              <input type="number" defaultValue={labelFont || defaultLabelFont} onChange={(e) => setLabelFont(e)} />
              {errors.showLabelFontError && <p className="fs-0875 text-danger">Value needs to be between 5 to 20.</p>}
            </FormField>
            <FormField>
              <label>Angle</label>
              <input type="number" defaultValue={angle === undefined ? defaultAngle : angle} onChange={(e) => setAngle(e)} />
              {errors.showAngleError && <p className="fs-0875 text-danger">Value needs to be between -90 to 90.</p>}
            </FormField>
            <FormField>
              <label>Max Label Length</label>
              <input type="number" defaultValue={labelLength > 0 ? labelLength : undefined} onChange={(e) => setLabelLength(e)} />
              {errors.showLabelLengthError && <p className="fs-0875 text-danger">Value needs to be larger than 0.</p>}
            </FormField>
          </>
        )}
        {type === "Metric" && (
          <FormField>
            <label>
              Metric label override
              <Popup
                trigger={<Icon name="info circle" className="text-medium ml-1 cursor-pointer" style={{ marginTop: -10 }} size="small" />}
                position="right center"
                size="mini"
                inverted
              >
                Update the metric chart label text
              </Popup>
            </label>
            <input type="text" value={metricLabelOverride} onChange={(e) => builder!.updateChart("metricLabelOverride", e.target.value)} />
          </FormField>
        )}
        {ifChartCanSetAxisValue(type) && (
          <>
            <FormField>
              <label>Max Axis Value</label>
              <input type="number" defaultValue={userDefinedMaxValue} onChange={(e) => setAxisValue(e, "Max")} />
              {errors.showMaxAxisValueError && (
                <p className="fs-0875 text-danger">{`The value needs to be greater than or equal to ${dataMax}`}</p>
              )}
            </FormField>
            <FormField>
              <label>
                Min Axis Value
                <Popup
                  trigger={<Icon name="info circle" className="text-medium ml-1 cursor-pointer" style={{ marginTop: -10 }} size="small" />}
                  position="right center"
                  size="mini"
                  inverted
                >
                  To enable this field, the <b>Max</b> axis value must be set first.
                </Popup>
              </label>
              <input
                type="number"
                disabled={userDefinedMaxValue === undefined}
                defaultValue={userDefinedMinValue}
                onChange={(e) => setAxisValue(e, "Min")}
              />
              {errors.showMinAxisValueError && (
                <p className="fs-0875 text-danger">{`The value needs to be smaller than or equal to ${dataMin}`}</p>
              )}
            </FormField>
          </>
        )}
        {ifChartCanSetAxisIncrements(type) && (
          <>
            <FormField>
              <label>
                Axis Increments
                <Popup
                  trigger={<Icon name="info circle" className="text-medium ml-1 cursor-pointer" style={{ marginTop: -10 }} size="small" />}
                  position="right center"
                  size="mini"
                  inverted
                >
                  {!isProportionsOrPercentageChart(type) && (
                    <li>
                      To enable this field, set both the <b>Max</b> and <b>Min</b> axis values first.
                    </li>
                  )}
                  <li>If some ticks are too close, they may not be displayed.</li>
                </Popup>
              </label>
              <input
                type="number"
                disabled={!isProportionsOrPercentageChart(type) && (userDefinedMaxValue === undefined || userDefinedMinValue === undefined)}
                value={typeof userDefinedIncrements === "number" ? userDefinedIncrements : ""}
                onChange={setAxisIncrement}
              />
              {errors.showAxisIncrementsError && (
                <p className="fs-0875 text-danger">{`The value needs to be greater than or equal to ${minIncrement} and less than ${maxIncrement}`}</p>
              )}
            </FormField>
            <FormField>
              <label>
                Primary Axis Tick Interval
                <Popup
                  trigger={<Icon name="info circle" className="text-medium ml-1 cursor-pointer" style={{ marginTop: -10 }} size="small" />}
                  position="right center"
                  size="mini"
                  inverted
                >
                  When the value is 0 or left blank, all ticks will be displayed.
                </Popup>
              </label>
              <input type="number" min="0" step="1" value={axisTickInterval} onChange={setAxisTickInterval} />
              {errors.showAxisTickIntervalError && <p className="fs-0875 text-danger">{errors.showAxisTickIntervalError}</p>}
            </FormField>
          </>
        )}
        <FormField>
          <label>
            Decimal Points
            <Popup
              trigger={<Icon name="info circle" className="text-medium ml-1 cursor-pointer" style={{ marginTop: -10 }} size="small" />}
              content="Applies to metric charts and the tooltips of all other chart types"
              position="right center"
              size="mini"
              inverted
            />
          </label>
          <Button.Group basic className="w-100" size="tiny">
            <Button value={0} active={decimalPoints === undefined} onClick={() => builder!.setDecimalPoints(undefined)}>
              Auto
            </Button>
            <Button value={0} active={decimalPoints === 0} onClick={() => builder!.setDecimalPoints(0)}>
              0
            </Button>
            <Button value={1} active={decimalPoints === 1} onClick={() => builder!.setDecimalPoints(1)}>
              1
            </Button>
            <Button value={2} active={decimalPoints === 2} onClick={() => builder!.setDecimalPoints(2)}>
              2
            </Button>
          </Button.Group>
        </FormField>
        {isProportionsOrPercentageChart(type) && (
          <FormField>
            <label>
              Show Raw Values in Tooltip
              <Popup
                trigger={<Icon name="info circle" className="text-medium ml-1 cursor-pointer" style={{ marginTop: -10 }} size="small" />}
                position="right center"
                size="mini"
                inverted
              >
                Applies to proportional bar charts and percentage line charts.
              </Popup>
            </label>
            <Checkbox
              toggle
              checked={showRawValueInTooltip}
              size="small"
              onChange={() => builder!.updateChart("showRawValueInTooltip", !showRawValueInTooltip)}
            />
          </FormField>
        )}
        {ifCanShowBarLabel(type) && (
          <>
            <FormField>
              <label>
                Show Value Above Bars
                <Popup
                  trigger={<Icon name="info circle" className="text-medium ml-1 cursor-pointer" style={{ marginTop: -10 }} size="small" />}
                  position="right center"
                  size="mini"
                  inverted
                >
                  Applies to vertical bar charts that are not proportional.
                </Popup>
              </label>
              <Checkbox toggle checked={showBarLabel} size="small" onChange={() => builder!.updateChart("showBarLabel", !showBarLabel)} />
            </FormField>
            <FormField>
              <label>Bar Value Font Size</label>
              <input type="number" defaultValue={barLabelFontSize || 11} onChange={(e) => setBarLabelValue(e)} disabled={!showBarLabel} />
              {errors.showBarLabelError && <p className="fs-0875 text-danger">Value needs to be between 5 and 20.</p>}
            </FormField>
          </>
        )}
        <FormField>
          <label>Condensed Legend</label>
          <Checkbox
            toggle
            checked={condensedLegend}
            size="small"
            onChange={() => builder!.updateChart("condensedLegend", !condensedLegend)}
          />
        </FormField>
        <FormField>
          <label>Hide Table Names in Legend</label>
          <Checkbox toggle checked={hideTableNames} size="small" onChange={() => builder!.updateChart("hideTableNames", !hideTableNames)} />
        </FormField>
        <FormField>
          <label>
            Condensed Embed
            <Popup
              trigger={<Icon name="info circle" className="text-medium ml-1 cursor-pointer" style={{ marginTop: -10 }} size="small" />}
              position="right center"
              size="mini"
              inverted
            >
              Insight will have reduced padding when embedded to make the best use of available space
            </Popup>
          </label>
          <Checkbox toggle checked={condensedEmbed} size="small" onChange={() => builder!.updateChart("condensedEmbed", !condensedEmbed)} />
        </FormField>
        <FormField>
          <label>Show source on chart</label>
          <Checkbox toggle checked={showSource} size="small" onChange={() => builder!.updateChart("showSource", !showSource)} />
        </FormField>
      </Form>
      {/* <StyleTitle>
          Highlight
        </StyleTitle>
        <StyleDD
          search
          multiple
          style={{ width: "100%", color: "#2D2D2D" }}
          onChange={(_, val) => chart.highlights = val.value as string[]}
          className="selection"
          options={makeDDF(columnsArray)}
          placeholder={"Search or select"}
        /> */}
    </div>
  );
};

export const ExploreChartBasic = inject((stores: any) => ({
  builder: stores.store.builder,
}))(observer(ExploreChartBasicComponent));
