import * as React from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { Field, Form as FinalForm } from "react-final-form";
import { FieldInputHidden } from "./FieldInput";
import { AddVariableTab } from "./AddVariableTab";

interface Props {
  isOpen: boolean;
  type: string;
  close: () => any;
  onAdd: (_) => any;
  qsVariables: string[];
}

export const VariableModal = ({ isOpen, type, close, onAdd, qsVariables }: Props): JSX.Element => {
  const onSubmit = ({ variable }): any => {
    const error = onAdd(variable);
    if (error) {
      return { variable: error };
    } else {
      close();
    }
  };
  return (
    <Modal open={isOpen} onClose={close}>
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit, form }) => {
          const { getFieldState, restart } = form;
          const fieldMeta: any = getFieldState("variable");
          return (
            <>
              <Modal.Header className="fs-2000 fw-700 text-secondary">Add {type === "rows" ? "row" : "column"} variable</Modal.Header>
              <Modal.Content>
                <Form onSubmit={handleSubmit}>
                  <Field name="variable" component={FieldInputHidden} />
                  <AddVariableTab fieldMeta={fieldMeta} qsVariables={qsVariables} resetForm={restart} handleSubmit={handleSubmit} />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button type="button" onClick={close}>
                  Cancel
                </Button>
                <Button type="submit" className="bg-primary text-white bg-hover-red" onClick={handleSubmit}>
                  Add {type === "rows" ? "row" : "column"} <Icon name="plus" className="ml-2 mr-0" />
                </Button>
              </Modal.Actions>
            </>
          );
        }}
      />
    </Modal>
  );
};
