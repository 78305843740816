import Store from "../store";
import { database } from "common/api";

export default class AdminActions {
  parent: Store;

  constructor(parent: Store) {
    this.parent = parent;
  }

  searchAdmin = async (type: "users" | "groups" | "datasets", keyword: string): Promise<any> => {
    const res: any = await database.post(`platform-admin/${type}`, { keyword }, this.parent.token!);
    return res;
  };

  updateDataset = async (dataset_id: string, payload: any): Promise<boolean> => {
    const res: any = await database.put(`platform-admin/datasets/${dataset_id}`, payload, this.parent.token!);
    return res?.statusCode === 200;
  };

  updateDashboard = async (dashboard_id: string, payload: any): Promise<boolean> => {
    const res: any = await database.put(`platform-admin/dashboards/${dashboard_id}`, payload, this.parent.token!);
    return res?.statusCode === 200;
  };

  updateSuitcase = async (suitcase_id: string, payload: any): Promise<boolean> => {
    const res: any = await database.put(`platform-admin/suitcases/${suitcase_id}`, payload, this.parent.token!);
    return res?.statusCode === 200;
  };

  updateOrg = async (group_id: string, payload: any): Promise<boolean> => {
    const res: any = await database.put(`platform-admin/groups/${group_id}`, payload, this.parent.token!);
    return res?.statusCode === 200;
  };
}
