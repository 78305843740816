import * as React from "react";
import { inject, observer } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";
import Store, { IProject } from "common/store/suitcase";
import { Button, Icon } from "semantic-ui-react";
import CardListView from "./CardListView";
// import TableListView from "./TableListView";
import { Hr } from "pages/Suitcase/suitcaseOverviewPage.styles";
import { InvertedButton } from "common/styledComponents/elements";
import { getExplorePath } from "common/helpers/user";
import { COLORS } from "./UI/common";
import { InsightsOrderModal } from "pages/Suitcase/includes/InsightsOrderModal";
import { getMixpanel } from "common/api";

const Heading = styled.h2`
  font-size: 30px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  line-height: 41px;
  color: ${COLORS.indigo600};
`;

type PropsType = RouteComponentProps<{}> & {
  store?: Store;
  readonly?: boolean;
  publicUrlKey?: string;
  suitcase: IProject;
};

const showNoEditMessage = (isPublicLink, insightData, availableDatasets) => {
  if (isPublicLink || availableDatasets.length === 0 || !insightData?.key) {
    return false; // don't show for guests on public link either
  }
  // insight json unavailable on suitcase page so only show message if key (dataset) is set on insight
  return !availableDatasets.some(ds => ds.key === insightData.key);
};

class InsightsList extends React.Component<PropsType> {
  store = this.props.store!;
  suitcase = this.props.suitcase;
  state = {
    layout: "grid",
    showInsightsOrderModal: false,
  };
  input: HTMLInputElement | null | void = name;
  // project_ids = this.store.projects.map(project => project.id);
  // prepacked_suitcase_user_ids = this.store.prepackedprojects.map(user => user.id);
  suitcaseUser = this.suitcase.users?.find(user => user.id === this.store?.parent.user?.id);

  changeLayout(selectedLayout: string) {
    this.setState({ layout: selectedLayout });
  }

  saveInsightsOrder(insightsIds: number[]) {
    this.store.updateInfo({ insight_order: JSON.stringify(insightsIds) });
    this.setState({ showInsightsOrderModal: false });
    getMixpanel(this.store.parent).track("Update Insights Order", { "Suitcase ID": this.suitcase.id });
  };

  render() {
    const viewOptions = [
      {
        value: "block",
        icon: "block layout",
      },
      {
        value: "grid",
        icon: "grid layout",
      },
      // TODO: Make TableListView non-attachment specific before enabling it for insights
      //
      // {
      //   value: "list",
      //   icon: "list layout"
      // },
    ];
    const { publicUrlKey, readonly } = this.props;
    const availableDatasets = this.store.parent.insightbuilder.datasets;
    const { sortedInsights } = this.store;

    return (
      <div>
        <InsightsOrderModal
          isOpen={this.state.showInsightsOrderModal}
          onClose={() => this.setState({ showInsightsOrderModal: false })}
          insights={sortedInsights.map(insight => ({ id: insight.id, name: insight.name }))}
          onSave={(insightsIds) => this.saveInsightsOrder(insightsIds)}
        />
        <div style={{ display: "inline-block", width: "100%", marginTop: "50px" }}>
          <Heading style={{ float: "left" }}>Insights</Heading>

          <div className="text-right pdf-hide">
            {!this.suitcaseUser?.read_only &&
              !publicUrlKey &&
              (!readonly || this.store.parent.user!.group.name === "Seer Team") && (
                <>
                  {sortedInsights.length > 1 && (
                    <Button
                      basic
                      color="red"
                      onClick={() => this.setState({ showInsightsOrderModal: true })}
                    >
                      Update order<Icon name="sort" className="ml-2 mr-0" />
                    </Button>
                  )}
                  <InvertedButton
                    style={this.store.parent.user!.group.expired ? { cursor: "not-allowed" } : {}}
                    i={0}
                    onClick={() =>
                      this.store.parent.user!.group.expired
                        ? null
                        : this.props.history.push(`/suitcases/${this.suitcase.id}${getExplorePath()}`)
                    }
                  >
                    New insight
                    <Icon name="search" className="ml-2" />
                  </InvertedButton>
                </>
              )}
            <Button.Group style={{ marginLeft: "30px" }} role="radiogroup" aria-label="Insights Layout" className="d-none d-lg-inline-flex">
              {viewOptions.map((viewOption, i) => (
                <Button
                  role="radio"
                  aria-checked={this.state.layout === viewOption.value}
                  aria-label={`${viewOption.value} view`}
                  icon={viewOption.icon}
                  style={
                    this.state.layout === viewOption.value ? { color: "darkslategrey" } : { color: "lightslategrey" }
                  }
                  active={this.state.layout === viewOption.value}
                  onClick={() => this.changeLayout(viewOption.value)}
                  key={i}
                />
              ))}
            </Button.Group>
          </div>
        </div>

        <Hr />

        {/* TODO: Make TableListView non-attachment specific before enabling it for insights */}

        {/* {this.state.layout === "list" &&
          <TableListView store={this.store} />
        } */}

        {(this.state.layout === "grid" || this.state.layout === "block") && (
          <div>
            <CardListView
              layout={this.state.layout}
              type="insight"
              list={sortedInsights.map(insight => ({
                id: insight.id,
                name: insight.name,
                itemUrl:
                  publicUrlKey ? `/insights/${insight.id}/${publicUrlKey}` : `/insights/${insight.id}`,
                imageUrl: insight.banner,
                type: "insight",
                created_at: insight.created_at,
                showNoEditMessage: showNoEditMessage(publicUrlKey, insight, availableDatasets),
              }))}
              cardActions={[]}
              publicKey={publicUrlKey}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(inject("store")(observer(InsightsList)));
