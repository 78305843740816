import { observable, action, computed } from "mobx";
import Store from "../store";
import { database, getMixpanel } from "../api";
import validator from "validator";
interface IGroup {
  avatar: string;
  banner: string;
  description: string;
  user_id: number; // These 5 relate to group owner
  first_name: string;
  last_name: string;
  email: string;
  organisation: string;
  id: number;
  name: string;
  users: IUser[];
  users_count: number;
  logo: string;
}

interface IUser {
  first_name: string;
  last_name: string;
  avatar: string;
  id: number;
  admin: boolean;
  email: string;
  organisation: string;
}

interface IInvitation {
  id: number;
  group_id: number;
  logo: string;
  name: string;
  user_count: number;
}

export default class Group {
  @observable active: IGroup | null = null; // Current group selected
  @observable myGroups: IGroup[] = []; // Groups I am in
  @observable invitations: IInvitation[] = []; // Invitations to groups
  @observable loading = false;
  @observable allUsers: IUser[] = []; // Users from sitewide search

  parent: Store;

  constructor(parent: Store) {
    this.parent = parent;
  }

  @computed get partnerOrganisations(): any[] {
    return this.parent.user!.group.partners.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
  }

  @computed get sentPartnerInvitations(): any[] {
    return this.parent.user!.group.partnership_invitations_sent;
  }

  @computed get receivedPartnerInvitations(): any[] {
    return this.parent.user!.group.partnership_invitations_received;
  }

  @computed get allOrganisations(): any[] {
    const ownGroup = {
      id: this.parent.user!.group.id,
      logo: this.parent.user!.group.logo,
      name: this.parent.user!.group.name,
      owner: this.parent.user!.group.users.find((user) => user.id === this.parent.user!.group.user_id),
      user_id: this.parent.user!.group.user_id,
      users: this.parent.user!.group.users.filter((user) => user.id !== this.parent.user!.id), // Exclude the current user
    };

    return [ownGroup, ...this.partnerOrganisations];
  }

  @action async sendEmailInvite(emailString: string): Promise<string | void> {
    const email = (emailString || "").trim();
    const data: any = { email };
    const group_id = this.parent.user!.group.id;
    if (validator.isEmail(email)) {
      const res: any = await database.post(`groups/${group_id}/invitations`, data, this.parent.token!);
      if (res.statusCode === 400) {
        return res.body.error.fields[0].limit || res.body.error.fields[0].email;
      } else if (res.statusCode === 200) {
        getMixpanel(this.parent).track("Invite send");
        this.parent.user!.group.invitations.unshift(res.body.data.invitation);
        return;
      }
    } else {
      return "That is not a valid email address";
    }
  }

  @action async removeMember(memberId: number) {
    const deleteUser = confirm("Are you sure you want to remove this user?");
    if (deleteUser) {
      const req: any = await database.delete(`groups/${this.parent.user!.group.id}/users/${memberId}`, "", this.parent.token!);
      if (req.statusCode === 200) {
        if (memberId === this.parent.user!.id) {
          this.parent.logout();
        } else {
          this.parent.user!.group.users = this.parent.user!.group.users.filter((user) => user.id !== memberId);
          if (this.parent.suitcase.active) {
            this.parent.suitcase.active.users = this.parent.suitcase.active.users.filter((user) => user.id !== memberId);
          }
        }
      }
    }
  }

  @action async setNewOwner(user_id: number) {
    const confirmed = confirm("Are you sure you want to promote this user to Owner? You will be demoted to Admin.");
    if (confirmed) {
      await database.put(`groups/${this.parent.user!.group.id}`, { user_id }, this.parent.token!);
      this.parent.reloginUserFromAuthToken();
    }
  }

  @action async groupAcceptInvitation(groupID: number, invitationID: number): Promise<void> {
    const res: any = await database.post(`groups/${groupID}/partners`, { invitation_id: invitationID }, this.parent.token!);
    if (res?.body?.data) {
      this.parent.reloginUserFromAuthToken();
      getMixpanel(this.parent).track("Accept Invitation", { Page: "Settings > Organisation > Invitations" });
    }
  }

  @action async groupDeleteInvitation(invitationID: number): Promise<void> {
    await database.delete(`invitations/${invitationID}`, "", this.parent.token!);
    this.parent.reloginUserFromAuthToken();
  }

  @action async invitePartnerOrganisation(groupID: number, email: string): Promise<void | string> {
    const res: any = await database.post(`groups/${groupID}/invitations`, { email, type: "partnership" }, this.parent.token!);
    if (res?.body?.data) {
      this.parent.reloginUserFromAuthToken();
    } else {
      return res.body.error.message;
    }
  }
}
