import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { getMixpanel } from "common/api";
import { Button, Card, Container, Divider, Label, Segment } from "semantic-ui-react";
import Store from "common/store";
import { DATA_SOURCE_STEPS } from "./NewDataSourceModal";

interface INewDataSourceOptions {
  store?: Store;
  stepHandler: (arg0: any) => any;
  modalCloseHandler: () => any;
}

export const NewDataSourceOptions = inject("store")(
  observer(({ store, stepHandler, modalCloseHandler }: INewDataSourceOptions) => (
    <Segment basic textAlign="left">
      <h2>Collect New Data</h2>

      <Container textAlign="center" className="mb-5">
        <Button
          color="teal"
          content="Create New Template"
          icon="add"
          labelPosition="left"
          onClick={() => {
            store!.dataset.setShowCreateTemplateModal(true);
            modalCloseHandler();
          }}
        />
      </Container>

      <Divider horizontal>Or</Divider>

      <h2>Import Existing Data</h2>

      <Card.Group itemsPerRow={3} centered>
        <Card
          className="mr-6"
          onClick={() => {
            getMixpanel(store!).track("Collect Data > New Data Source > Upload Excel File");
            stepHandler(DATA_SOURCE_STEPS.dataSourceExcelUpload);
          }}
        >
          <Card.Content>
            <Card.Header>
              Upload Excel File
              <Label color="teal" floating>
                Beta
              </Label>
            </Card.Header>
            <Card.Description>
              Import existing data from Excel files. This feature is being co-designed with our customers to assess and expand our supported
              data formats.
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <div className="text-right">
              <Button className="bg-primary text-white">Select</Button>
            </div>
          </Card.Content>
        </Card>

        <Card
          onClick={() => {
            getMixpanel(store!).track("Collect Data > New Data Source > Request Custom");
            stepHandler(DATA_SOURCE_STEPS.dataSourceCustomSelected);
          }}
        >
          <Card.Content>
            <Card.Header content="Other" />
            <Card.Description>
              If you have other data ingestion needs or integration requests, click here and we will be in touch with you to discuss
              requirements.
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <div className="text-right">
              <Button className="bg-primary text-white">Select</Button>
            </div>
          </Card.Content>
        </Card>
      </Card.Group>
    </Segment>
  )),
);
