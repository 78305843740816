// `v2/smart-insights` supported location variables map
export const SMART_INSIGHTS_VARIABLE_MAP = {
  "Statistical Area Level 2 (SA2)": "SA2",
  "Statistical Area Level 3 (SA3)": "SA3",
  "Statistical Area Level 4 (SA4)": "SA4",
  "Local Government Area (LGA)": "LGA",
  "Postal Area (POA)": "POA",
  "State Suburb (SSC)": "SSC",
};

// Percentile background colors based on generated "TopBottomNum"
export const TOP_BOTTOM_LABEL_COLOR_MAP = {
  1: "#ffa600",
  5: "#ffaf37",
  10: "#ffb856",
  20: "#ffc171",
  30: "#ffca8b",
  40: "#ffd4a4",
  50: "#fbddbe",
};

// Convert "pctl" to integer and should be >= 1 and <= 99
export const getPctlNum = (pctl: number): number => {
  const pctlNum = Number((pctl * 100).toFixed(0));
  if (pctlNum < 1) {
    return 1;
  } else if (pctlNum > 99) {
    return 99;
  } else {
    return pctlNum;
  }
};

// Helper function to generate number for "Top/Bottom" label.
// Returned values are "1/5/10/20/30/40/50"
export const generateTopBottomNum = (pctlNum: number): number => {
  let number;
  if (pctlNum >= 50) {
    if (pctlNum === 99) {
      number = 1;
    } else if (pctlNum < 99 && pctlNum >= 95) {
      number = 5;
    } else {
      number = (10 - Math.floor(pctlNum / 10)) * 10;
    }
  } else {
    if (pctlNum === 1) {
      number = 1;
    } else if (pctlNum > 1 && pctlNum <= 5) {
      number = 5;
    } else {
      number = Math.floor((pctlNum + 10) / 10) * 10;
    }
  }
  return number;
};

// Convert "pctlNum" to "Top/Bottom 1/5/10/20/30/40/50"
export const generateTopBottomLabel = (pctlNum: number): string => {
  let label;
  if (pctlNum >= 50) {
    label = "Top";
  } else {
    label = "Bottom";
  }
  return `${label} ${generateTopBottomNum(pctlNum)}%`;
};
