// Full Screen Loader component
import * as React from "react";

// Should only be used once across the entire app, at app root, so is only rendered in one place
export const LoaderGlobal = () => (
  <>
    <div
      id="loader-global"
      style={{
        display: "none",
        zIndex: 9999,
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <img
        src="/assets/seer-loader.gif"
        alt="Loading..."
        width="170"
        height="170"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
    <div
      id="loader-global-backdrop"
      style={{
        display: "none",
        position: "fixed",
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 9990,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    />
  </>
);

// Use this to display the loader anywhere in the app
export const ShowLoaderGlobal = () => (
  <div>
    {/* please don't copy this behaviour elsewhere */}
    <style>{`
      body #loader-global { display: block !important; }
      body #loader-global-backdrop { display: block !important; }
    `}</style>
  </div>
);
