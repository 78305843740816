import * as React from "react";
import {
  MultiRowTable,
  MultiRowTableAddButton,
  MultiRowTableDeleteWrapper,
  MultiRowTableTextarea,
} from "./includes/CustomFormMultiRow.style";
import { Icon, Popup } from "semantic-ui-react";
import { ls } from "common/helpers/storage";

// Generate initial values for the table;
// Returns a 2D array with 3 rows and a specified number of columns (columnsLength), each initialized with empty strings.
const getInitialValues = (columnsLength: number): string[][] =>
  Array(3)
    .fill(null)
    .map(() => Array(columnsLength).fill(""));

export const CustomFormMultiRowNew = (props): JSX.Element => {
  const { lsKey, savedValues, columns, tooltips } = props;
  const [values, setValues] = React.useState<string[][]>(getInitialValues(columns.length));

  React.useEffect(() => {
    if (savedValues) {
      setValues(savedValues);
    }
  }, [savedValues]);

  const addRowClickHandler = () => {
    const nextValues = [...values];
    nextValues.push(["", "", ""]);
    setValues(nextValues);
    ls.setItem(lsKey, nextValues);
  };

  const addRowPressHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      addRowClickHandler();
      e.preventDefault();
    }
  };

  const removeRowClickHandler = (rIdx: number) => {
    const nextValues = [...values];
    nextValues.splice(rIdx, 1);
    setValues(nextValues);
    ls.setItem(lsKey, nextValues);
  };

  const removeRowPressHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>, rIdx: number) => {
    if (e.key === "Enter" || e.key === " ") {
      removeRowClickHandler(rIdx);
      e.preventDefault();
    }
  };

  const cellOnChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>, rIdx: number, cIdx: number) => {
    const nextValues = [...values];
    nextValues[rIdx][cIdx] = e.target.value;
    setValues(nextValues);
    ls.setItem(lsKey, nextValues);
  };

  return (
    <>
      {/* Workaround: Set table "title" to "" to prevent the <Icon> tooltip from being covered by the popup that appears for the table title */}
      <MultiRowTable title="" style={{ fontFamily: "Source Sans Pro" }}>
        <thead>
          <tr>
            {columns.map((cItem, cIdx) => (
              <th key={cItem}>
                {cItem}
                <Popup
                  content={tooltips[cIdx]}
                  size="tiny"
                  position="bottom center"
                  inverted
                  trigger={
                    <MultiRowTableDeleteWrapper tabIndex={0} role="button" aria-label={tooltips[cIdx]}>
                      <Icon name="info circle" className="m-0 ml-1 fs-1125" />
                    </MultiRowTableDeleteWrapper>
                  }
                />
              </th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {values.map((rValues, rIdx) => (
            <tr key={rIdx}>
              {columns.map((cItem, cIdx) => (
                <td key={cIdx}>
                  <MultiRowTableTextarea
                    aria-label={`Add your response to ${cItem} row${rIdx + 1}`}
                    placeholder="Your answer"
                    rows={1}
                    onChange={(e) => cellOnChangeHandler(e, rIdx, cIdx)}
                    value={rValues[cIdx] || ""}
                  />
                </td>
              ))}
              <td className="action-td">
                <MultiRowTableDeleteWrapper
                  tabIndex={0}
                  role="button"
                  aria-label={`Delete row${rIdx + 1}`}
                  onClick={() => removeRowClickHandler(rIdx)}
                  onKeyDown={(e) => removeRowPressHandler(e, rIdx)}
                >
                  <Icon name="trash alternate outline" className="m-0 fs-1125" />
                </MultiRowTableDeleteWrapper>
              </td>
            </tr>
          ))}
        </tbody>
      </MultiRowTable>
      <MultiRowTableAddButton
        role="button"
        aria-labelledby="addNewRole"
        tabIndex={0}
        onClick={addRowClickHandler}
        onKeyDown={(e) => addRowPressHandler(e)}
      >
        <p id="addNewRole">+ Add another row</p>
      </MultiRowTableAddButton>
    </>
  );
};
