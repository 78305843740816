import { DateTime } from "luxon";

const sortByGroupName = (a, b) => a.name.localeCompare(b.name);
const sortByUserGroupName = (a, b) => a.group.name.localeCompare(b.group.name);
const sortByUserName = (a, b) => {
  const userAName = `${a.first_name} ${a.last_name}`;
  const userBName = `${b.first_name} ${b.last_name}`;
  return userAName.localeCompare(userBName);
};

// Dashboards Access Org List Sorted Order:
// 1. User's Org
// 2. Access Level("Full Access" > "Read Only")
// 3. Org Name Alphabetical Order
export const getSortedDashboardGroups = (groups: any[], userGroupID: number): any[] => {
  const sortedGroups: any[] = [];
  const fullAccessGroups: any[] = [];
  const readOnlyGroups: any[] = [];
  groups.sort(sortByGroupName).forEach((group) => {
    if (group.id === userGroupID) {
      sortedGroups.push(group);
    } else if (group.access_level === "write") {
      fullAccessGroups.push(group);
    } else if (group.access_level === "read") {
      readOnlyGroups.push(group);
    }
  });
  return [...sortedGroups, ...fullAccessGroups, ...readOnlyGroups];
};

// Dashboards Access User List Sorted Order:
// 1. Access Level("Owner" > "Full Access" > "Read Only")
// 2. Org Name Alphabetical Order
// 3. User Name Alphabetical Order
export const getSortedDashboardUsers = (usersRaw: any[]): any[] => {
  const sortedUsers: any[] = [];
  const fullAccessUsers: any[] = [];
  const readOnlyUsers: any[] = [];
  // workaround due to situations where a user is not assigned to a group to avoid breaking the application
  const users = usersRaw.map((user) => ({ ...user, group: user.group || { name: "User not part of an organisation" } }));
  users
    .sort(sortByUserName)
    .sort(sortByUserGroupName)
    .forEach((user) => {
      if (user.access_level === "owner") {
        sortedUsers.push(user);
      } else if (user.access_level === "write") {
        fullAccessUsers.push(user);
      } else if (user.access_level === "read") {
        readOnlyUsers.push(user);
      }
    });
  return [...sortedUsers, ...fullAccessUsers, ...readOnlyUsers];
};

export const formatDate = (date: string, format: string): string => DateTime.fromISO(date).setZone("local").toFormat(format);
