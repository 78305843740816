import * as React from "react";
import { Input } from "semantic-ui-react";
import { StyleSelect } from "./UIComponents";
import { DataType } from "common/store/dataset/urlUpload";

interface DataTypeEditorProps {
  dataType: DataType;
  onChangeDataType: (dataType: DataType) => void;
}

const dataTypeOptions = [
  { value: "string", label: "String" },
  { value: "number", label: "Number" },
  { value: "list", label: "List" },
];

export const DataTypeEditor = (props: DataTypeEditorProps) => {
  const { dataType, onChangeDataType } = props;
  const { type, separator } = dataType || {};
  const timeoutRef: any = React.useRef();
  const [inputValue, setInputValue] = React.useState(separator || ",");

  const handleTypeChange = (type: "string" | "number" | "list") => {
    if (type === "list") {
      onChangeDataType({
        type: "list",
        separator: separator || ",",
      });
    } else {
      onChangeDataType({ type });
    }
  };

  const handleSeparatorChange = (value: string) => {
    setInputValue(value);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      const nextDataType: DataType = { ...dataType, separator: value.trim() };
      onChangeDataType(nextDataType);
    }, 500);
  };

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <StyleSelect
        value={dataTypeOptions.find((o) => o.value === type)}
        options={dataTypeOptions}
        onChange={(option) => handleTypeChange(option.value)}
      />
      {type === "list" && (
        <Input
          fluid
          size="small"
          error={inputValue.trim() === ""}
          value={inputValue}
          label="Separator"
          onChange={(_, { value }) => handleSeparatorChange(value)}
        />
      )}
    </>
  );
};
