import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { UnifiedSearch } from "component/UnifiedSearch/UnifiedSearch";
import { ExploreTabItem, IExploreTabItem } from "./includes/ExploreTabItem";
import { getCategorySelectionState, isAnyFilterSelected, TABLE_SELECTION_STATE_OPTIONS } from "common/helpers/explore";
import { Icon, SemanticICONS } from "semantic-ui-react";
import { BuilderSelector } from "pages/Builder/BuilderSelector";
import { DraggableLists } from "component/table/includes/DraggableLists";
import { DropdownTitle } from "./includes/DropdownTitle";
import Select from "react-select";
import { Button as StyledButton } from "common/styledComponents/elements";

interface IExploreTableTab {
  store?: Store;
  forceTabSwitch: "topic" | null;
  handleClearAll: (e) => void;
  analystPortal?: boolean;
}

export const ExploreTableTab = inject("store")(
  observer(({ store, forceTabSwitch, handleClearAll, analystPortal }: IExploreTableTab): JSX.Element => {
    const { insightbuilder, builder } = store!;
    const { leftTabFolded, activeTableTab } = builder.ui;

    const { RED_DOT, NO_DOT, LOCK_ICON, GREEN_DOT } = TABLE_SELECTION_STATE_OPTIONS;

    // Trigger re-render to switch table tab to "Topic" after "Reset all"
    React.useEffect(() => {
      if (forceTabSwitch) {
        builder.ui.setActiveTableTab(forceTabSwitch);
      }
    }, [forceTabSwitch]);

    const handleChangeTab = (label) => {
      if (label === "analysis") {
        if (builder.isTableStepComplete) {
          builder.toggleRobotTool();
        }
      } else {
        builder.ui.setActiveTableTab(label);
        builder.ui.setLeftTabFolded(false);
      }
    };

    const handleShowFullQuery = () => {
      insightbuilder.toggleFullQuery();
    };

    const whatsSelectionState = getCategorySelectionState(builder, "What");
    const howsSelectionState = getCategorySelectionState(builder, "How");
    const whensSelectionState = getCategorySelectionState(builder, "When");
    const wheresSelectionState = getCategorySelectionState(builder, "Where");
    const datasetSelectionState = builder.userSelectedDatasets.length ? NO_DOT : RED_DOT;

    const tableTabItems: IExploreTabItem[] = [
      {
        icon: "file alternate outline",
        label: "topic",
        active: activeTableTab === "topic",
        selectionState: whatsSelectionState,
      },
      {
        icon: "bookmark outline",
        label: "quantity",
        active: activeTableTab === "quantity",
        selectionState: howsSelectionState,
      },
      {
        icon: "calendar alternate outline",
        label: "time",
        active: activeTableTab === "time",
        selectionState: whensSelectionState,
      },
      {
        icon: "map outline",
        label: "location",
        active: activeTableTab === "location",
        selectionState: wheresSelectionState,
      },
      {
        icon: "folder outline",
        label: "dataset",
        active: activeTableTab === "dataset",
        selectionState: datasetSelectionState,
      },
      {
        icon: "robot" as SemanticICONS,
        label: "analysis",
        active: activeTableTab === "robot",
        selectionState: builder.isTableStepComplete ? GREEN_DOT : LOCK_ICON,
        visible: store!.userPlan !== "Standard",
      },
      {
        icon: "sliders horizontal",
        label: "advanced",
        active: activeTableTab === "advanced",
        position: "bottom",
        folded: leftTabFolded,
      },
    ];

    return (
      <>
        {/* Unified search */}
        {leftTabFolded ? (
          <div
            className="d-flex align-items-center justify-content-center cursor-pointer text-medium"
            style={{ height: 60, width: 82, borderRadius: "0 0 0 4px" }}
            onClick={() => builder.ui.setLeftTabFolded(true)}
          >
            <div className="d-flex flex-column align-items-center mt-1">
              <Icon circular name="search" size="small" className="mr-0 mb-0" />
            </div>
          </div>
        ) : (
          <div style={{ padding: "11px 15px" }}>
            <UnifiedSearch />
          </div>
        )}
        {/* Divider */}
        <div className="bg-lightgrey" style={{ height: "1px" }}></div>
        {/* Tab container */}
        <div className="d-flex align-items-stretch" style={{ height: "calc(100vh - 219px)", minHeight: 485 }}>
          {/* Tab items */}
          <div
            id="userflow-element-explore-data-table-tabs"
            className="position-relative bg-hover-grey"
            style={{
              minWidth: 82,
              borderRight: leftTabFolded ? "1px solid rgba(0, 0, 0, 0)" : "1px solid #E5E5E5",
            }}
          >
            {tableTabItems.map((item, index) => {
              const { icon, label, active, selectionState, position, folded, visible } = item;
              return (
                <ExploreTabItem
                  key={index}
                  icon={icon}
                  label={label}
                  active={active}
                  selectionState={selectionState}
                  position={position && position}
                  folded={folded && folded}
                  onClick={() => handleChangeTab(label)}
                  visible={visible}
                  disabled={!!builder.RATAutoQuery}
                />
              );
            })}
          </div>
          {/* Tab content */}
          {!leftTabFolded && (
            // minWidth is set on the div below to ensure the react-select elements inside it do not exceed the space available
            // https://stackoverflow.com/questions/43054689/fill-remaining-space-until-max-width
            <div className="position-relative flex-sm-grow-1" style={{ minWidth: "0px" }}>
              <div className="overflow-auto" style={{ height: "calc(100vh - 267px)", padding: 20 }}>
                {builder.activeTableId ? (
                  <>
                    {activeTableTab === "topic" && <BuilderSelector selectionGroups={["What"]} />}
                    {activeTableTab === "quantity" && <BuilderSelector selectionGroups={["How"]} />}
                    {activeTableTab === "time" && <BuilderSelector selectionGroups={["When"]} />}
                    {activeTableTab === "location" && <BuilderSelector selectionGroups={["Where"]} />}
                    {activeTableTab === "dataset" && <BuilderSelector selectionGroups={["Source"]} />}
                    {activeTableTab === "advanced" && (
                      <>
                        <DropdownTitle title="Advanced" tooltip="Advanced table structure options" />
                        <div className="mb-2 fs-1000">
                          <p>The items that form your query are shown below.</p>
                          <p>
                            You can <strong>drag and drop</strong> the items to change the table structure.
                          </p>
                        </div>
                        <DraggableLists />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="h-75 d-flex flex-column justify-content-center align-content-center fs-1000">
                      {builder.tables.length > 0 ? (
                        <>
                          <p className="text-center fw-600 fs-1250">Select a table to edit:</p>
                          <Select
                            options={builder.tables
                              .filter((t) => t.type !== "calc")
                              .map((t) => ({ key: t.id, value: t.id, label: t.name }))}
                            onChange={(data: any) => builder.setActiveTableId(data!.value)}
                          />
                        </>
                      ) : (
                        <StyledButton onClick={() => builder.setActiveTableId(builder.addTable())}>Create New Table</StyledButton>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div
                className="w-100 d-flex align-items-center justify-content-between position-absolute ff-primary"
                style={{ bottom: 0, padding: 20 }}
              >
                <h3 className="text-primary fs-0875 cursor-pointer m-0" onClick={(e) => handleClearAll(e)}>
                  {isAnyFilterSelected(builder)
                    ? window.location.pathname.includes("builder/new") || window.location.pathname.includes("explore")
                      ? "Reset all"
                      : "Discard changes"
                    : ""}
                </h3>
                {!analystPortal && (
                  <h3 className="text-secondary fs-0875 cursor-pointer m-0" onClick={handleShowFullQuery}>
                    {`${insightbuilder.showFullQuery ? "Hide" : "Show"} full query`}
                  </h3>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }),
);
