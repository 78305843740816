import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Table, Image } from "semantic-ui-react";
import { SeerButton } from "./PartnerOrganisationsButtons";

interface IPartnerInvitationsList {
  // If passed invitations are Received Invitations we need to show "sender_user" detail, if passed invitations are Sent Invitations we need to show "received_user" detail
  showUserRole: "sender_user" | "receiver_user";
  partnerInvitations: any[];
  cancelLabel: string;
  cancelClickHandler: (invitationID) => void;
  confirmLabel?: string;
  confirmClickHandler?: (invitationID) => void;
}

const PartnerInvitationsListComponent = ({ showUserRole, partnerInvitations, cancelLabel, cancelClickHandler, confirmLabel, confirmClickHandler }: IPartnerInvitationsList): JSX.Element => (
  <>
    {
      partnerInvitations.length > 0 ?
      <>
        <Table basic="very">
          <Table.Header className="fs-0875">
            <Table.Row>
              <Table.HeaderCell>Logo</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Owner</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body className="fs-1125">
            {
              partnerInvitations.map(invitation => {
                const { id, email } = invitation;
                const user = invitation[showUserRole];
                // "When a non-existing user is invited & passed in invitations are sent invitations, the "user" doesn't exist. So we can use "email" as the "displayedEmail" as it's always the invitee's email. And we are not able to display "Logo" and "Org Name" when inviting non-existing user
                return (
                  <Table.Row>
                    <Table.Cell collapsing>
                      <Image src={user?.group?.logo || "/assets/seer-default-company-logo.png"} size="mini" circular style={{ width: 40 }} />
                    </Table.Cell>
                    <Table.Cell>{user?.group?.name || "-"}</Table.Cell>
                    <Table.Cell>{user ? user.email : email}</Table.Cell>
                    <Table.Cell collapsing>
                      <SeerButton
                        category="tertiary"
                        label={cancelLabel}
                        onClick={() => cancelClickHandler(id)}
                      />
                      {
                        confirmClickHandler &&
                        <SeerButton
                          category="secondary"
                          label={confirmLabel!}
                          onClick={() => confirmClickHandler(id)}
                        />
                      }
                    </Table.Cell>
                  </Table.Row>
                );
              })
            }
          </Table.Body>
        </Table>
      </> :
      <p className="fs-1125 text-muted">No pending invitations yet.</p>
    }
  </>
);

export const PartnerInvitationsList = inject((stores: any) => ({
  ui: stores.store.ui,
}))(observer(PartnerInvitationsListComponent));
