import * as React from "react";
import { MultiRowTable, MultiRowTableAddButton, MultiRowTableDeleteWrapper, MultiRowTableTextarea } from "./includes/CustomFormMultiRow.style";
import { Icon } from "semantic-ui-react";
import { ls } from "common/helpers/storage";

const INITIAL_VALUES = [
  ["", "", ""],
  ["", "", ""],
  ["", "", ""],
];

const COLUMN_ITEMS = [
  "Gender equality issue",
  "Impact",
  "Positive/Negative",
];

export const CustomFormMultiRowNew = (props): JSX.Element => {
  const { title, lsKey, savedValues } = props;
  const [values, setValues] = React.useState<string[][]>(INITIAL_VALUES);

  React.useEffect(() => {
    if (savedValues) {
      setValues(savedValues);
    }
  }, [savedValues]);

  const addRowClickHandler = () => {
    const nextValues = [...values];
    nextValues.push(["", "", ""]);
    setValues(nextValues);
    ls.setItem(lsKey, nextValues);
  };

  const addRowPressHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      addRowClickHandler();
      e.preventDefault();
    }
  };

  const removeRowClickHandler = (rIdx: number) => {
    const nextValues = [...values];
    nextValues.splice(rIdx, 1);
    setValues(nextValues);
    ls.setItem(lsKey, nextValues);
  };

  const removeRowPressHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>, rIdx: number) => {
    if (e.key === "Enter" || e.key === " ") {
      removeRowClickHandler(rIdx);
      e.preventDefault();
    }
  };

  const cellOnChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>, rIdx: number, cIdx: number) => {
    const nextValues = [...values];
    nextValues[rIdx][cIdx] = e.target.value;
    setValues(nextValues);
    ls.setItem(lsKey, nextValues);
  };

  return (
    <>
      <MultiRowTable title={title}>
        <thead>
          <tr>
            <th>Gender equality issue</th>
            <th>Impact</th>
            <th>Positive/Negative</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {values.map((rValues, rIdx) => (
            <tr key={rIdx}>
              {COLUMN_ITEMS.map((cItem, cIdx) => (
                <td key={cIdx}>
                  <MultiRowTableTextarea
                    aria-label={`Add your response to ${cItem} row${rIdx + 1}`}
                    placeholder="Your answer"
                    rows={1}
                    onChange={(e) => cellOnChangeHandler(e, rIdx, cIdx)}
                    value={rValues[cIdx] || ""}
                  />
                </td>
              ))}
              <td className="action-td">
                <MultiRowTableDeleteWrapper
                  tabIndex={0}
                  role="button"
                  aria-label={`Delete row${rIdx + 1}`}
                  onClick={() => removeRowClickHandler(rIdx)}
                  onKeyDown={(e) => removeRowPressHandler(e, rIdx)}
                  className="cursor-pointer"
                >
                  <Icon
                    name="trash alternate outline"
                    className="m-0 fs-1125"
                  />
                </MultiRowTableDeleteWrapper>
              </td>
            </tr>
          ))}
        </tbody>
      </MultiRowTable>
      <MultiRowTableAddButton role="button" aria-labelledby="addNewRole" tabIndex={0} onClick={addRowClickHandler} onKeyDown={(e) => addRowPressHandler(e)}>
        <p id="addNewRole">+ Add another row</p>
      </MultiRowTableAddButton>
    </>
  );
};
