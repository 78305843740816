import * as React from "react";
import Select from "react-select";
import { Button, Checkbox, Header } from "semantic-ui-react";
import { IType } from "../ManageAccessModal";
import { customReactSelectStyles } from "common/helpers/dataset";

interface IAddAccessWidget {
  type: IType;
  onClose: () => void;
  options: any[];
  onConfirm: (ids, accessLevel) => void;
}

export const AddAccessWidget = ({ type, onClose, options, onConfirm }: IAddAccessWidget): JSX.Element => {
  const [selectedIDs, setSelectedIDs] = React.useState<any[]>([]);
  const [accessLevel, setAccessLevel] = React.useState<any>("write");

  return (
    <>
      <p className="text-medium fw-600 cursor-pointer" onClick={onClose}>
        &lt; Back to Access List
      </p>
      <Header as="h4" color="purple">{`Add ${type} access`}</Header>
      <Header as="h5">{`1. Select ${type}s`}</Header>
      <Select
        isMulti
        isClearable={true}
        styles={customReactSelectStyles}
        placeholder={`Search and select ${type.toLowerCase()}s`}
        options={options}
        onChange={(selectedOptions) => {
          if (selectedOptions.length > 0) {
            setSelectedIDs(selectedOptions.map((o) => o.id));
          } else {
            setSelectedIDs([]);
          }
        }}
      />
      <Header as="h5">2. Set access level</Header>
      <Checkbox
        radio
        label="Full Access"
        name="checkboxRadioGroup"
        value="write"
        checked={accessLevel === "write"}
        onChange={(_e, data) => setAccessLevel(data.value)}
        style={{ marginRight: 20 }}
      />
      <Checkbox
        radio
        label="Read Only"
        name="checkboxRadioGroup"
        value="read"
        checked={accessLevel === "read"}
        onChange={(_e, data) => setAccessLevel(data.value)}
      />
      <div className="d-flex mt-5">
        <Button onClick={onClose} style={{ marginRight: 20 }}>
          Back
        </Button>
        <Button color="purple" disabled={selectedIDs.length === 0} onClick={() => onConfirm(selectedIDs, accessLevel)}>
          {`Add ${type}s`}
        </Button>
      </div>
    </>
  );
};
