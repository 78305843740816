import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Grid } from "semantic-ui-react";
import { database } from "common/api";
import { ImageContainer } from "./includes/RSTOInvite.style";
import { ContentPlaceholder } from "component/ContentPlaceholder";
import { PartnerInviteForm } from "./includes/PartnerInviteForm";
import { MemberInviteForm } from "./includes/MemberInviteForm";
import { COLORS } from "component/UI/common";
import { Redirect } from "react-router-dom";

const RSTO_TOS_URL = "https://seerdata.ai/terms-of-service-rsto/";

export const Component = ({ store }) => {
  const [loading, setLoading] = React.useState(true);
  const [email, setEmail] = React.useState("");
  const [groupName, setGroupName] = React.useState("");
  const [inviteType, setInviteType] = React.useState("");
  const [tokenError, setTokenError] = React.useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const getInvitationDetails = async (token) => {
    const res: any = await database.get(`invitations/${token}`, "");
    setLoading(false);
    if (res.statusCode === 200) {
      const { email, name, type } = res.body.data.invitation;
      setEmail(email as string);
      setGroupName(name as string);
      setInviteType(type as string);
    } else {
      setTokenError("The invitation token provided is invalid. Please check the URL.");
    }
  };

  React.useEffect(() => {
    if (token) {
      getInvitationDetails(token);
    } else {
      setTokenError("The invitation token provided is invalid. Please check the URL.");
      setLoading(false);
    }
  }, []);

  return (
    <div className="d-flex justify-content-center mx-4 mb-4">
      {store.authenticated && <Redirect to="/" />}
      {loading ? (
        <ContentPlaceholder className="w-100" />
      ) : (
        <Grid columns={2} stackable className="mx-4 bg-white p-0 rounded-5 overflow-hidden shadow-md" style={{ maxWidth: 940 }}>
          <Grid.Column className="p-0 d-flex justify-content-between flex-column">
            <div className="p-5">
              <img
                src="/assets/rsto/rsto_logo_full_color.png"
                className="mb-5"
                style={{ maxHeight: 120, width: "auto", maxWidth: "100%" }}
              />
              <p className="fw-700 text-secondary">
                {inviteType === "partnership"
                  ? "Restacking the Odds (RSTO) invited you to become a partner organisation on Seer Data."
                  : `You've been invited to join your organisation - ${groupName} on Seer Data.`}
              </p>
              <p className="fs-1000 text-muted">
                You will be able to access, analyse, benchmark and visualise your data to improve decision making and ensure children and
                families are getting quality services where and when they need them.
              </p>
            </div>
            <ImageContainer
              style={{
                backgroundImage: 'url("/assets/rsto/invitation_image.png")',
                backgroundPosition: "bottom",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: 340,
              }}
            ></ImageContainer>
          </Grid.Column>
          <Grid.Column className="p-5" style={{ background: COLORS.indigo100 }}>
            {tokenError ? (
              <p className="fs-1125 fw-600 text-primary">{tokenError}</p>
            ) : (
              <>
                {inviteType === "partnership" ? (
                  <PartnerInviteForm email={email} />
                ) : (
                  <MemberInviteForm email={email} groupName={groupName} token={token} />
                )}
                <div className="mb-5">
                  <p className="fs-1000 fw-700 text-muted">
                    By signing up, you agree to the{" "}
                    <a style={{ color: COLORS.blue }} href={RSTO_TOS_URL} target="_blank">
                      RSTO Terms of Service
                    </a>{" "}
                    and{" "}
                    <a style={{ color: COLORS.blue }} href="https://seerdata.ai/privacy-policy/" target="_blank">
                      Seer Data Privacy Policy
                    </a>
                    .
                  </p>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="d-flex align-items-center">
                    <p className="fs-0875 text-secondary mr-3 mt-3">Powered by</p>
                    <a href="https://seerdata.ai/" target="_blank">
                      <img src="/assets/seer-upgrade-logo.png" style={{ height: 40, width: "auto" }} />
                    </a>
                  </div>
                </div>
              </>
            )}
          </Grid.Column>
        </Grid>
      )}
    </div>
  );
};

export const RSTOInvite = inject("store")(observer(Component));
