import { observable, action } from "mobx";
import { database } from "../api";

import Store from "../store";
import Comments from "./comments";
import InsightBuilder from "./insightbuilder";

export interface IComment {
  id: number;
  user_id: number;
  avatar: string;
  first_name: string;
  last_name: string;
  message: string;
  created_at: string;
  edited: number;
  edited_at: string;
  thread_id: number;
  reply_to: number;
  needsanswer: number;
  answered: number;
  likes: ILikes[];
}

interface IInsightResponse {
  id: number;
  name: string;
  comments: IComment[];
  graphs: IGraph[];
  json: string;
  versions: string;
  keywords: string;
  likes: ILikes[];
  suitcase_id: number;
  templates: any[];
  subheading: string;
  key: string;
  user_id: number;
  avatar: string;
  first_name: string;
  last_name: string;
  code: string;
  updated_at: string;
  banner: string;
  suitcase_org_id?: number | undefined;
}

interface IGraph {
  id: number | undefined;
  name: string;
}

interface ILikes {
  id: number;
  insight_id: number;
  user_id: number;
  comment_id: number;
  emoji: string;
  first_name: string;
  last_name: string;
  avatar: string;
}

const defaultResult: IInsightResponse = {
  id: 0,
  name: "",
  comments: [],
  graphs: [],
  json: "",
  keywords: "",
  likes: [],
  suitcase_id: -1,
  templates: [],
  subheading: "",
  versions: "",
  key: "",
  user_id: -1,
  avatar: "",
  first_name: "",
  last_name: "",
  code: "",
  updated_at: "",
  banner: "",
};

export default class Insight {
  @observable result: IInsightResponse = defaultResult;
  @observable newKeywords = "";
  @observable versions: any[] = [];
  @observable currentVersion = 0;
  @observable savedJSON?; // For checking if changes have been made
  @observable draftName = "";
  @observable draftKeywords: string | undefined = undefined;
  @observable data?: any = null; // see "load" @action, this is the JSON parsed result.json

  // loading state for each async action
  @observable loading = {
    load: false,
  };

  // error state for each async action
  @observable error = {
    load: false,
  };

  parent: Store;

  comments = new Comments(this);

  constructor(parent: Store) {
    this.parent = parent;
  }

  @action setDraftName(name: string): void {
    this.draftName = name;
  }

  @action setDraftKeywords(content: string): void {
    this.draftKeywords = content;
  }

  // @TODO - remove these lint ignore statements after removal of undesirable editMode parameter
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  @action async load(id: number, editMode: boolean, publicUrlKey?: string, skipSuitcaseLoad = false, preloadedData?: any) {
    this.error.load = false;
    this.parent.loading = true; // @TODO - investigate whether this can be removed, not an intuitive place for loading state for this specific sub-store
    this.loading.load = true;
    this.currentVersion = 0;
    Object.assign(this.parent.insightbuilder, new InsightBuilder(this.parent));
    try {
      let result: any = preloadedData || null;
      if (!result) {
        const url = publicUrlKey ? `insights/${id}/${publicUrlKey}` : `insights/${id}`;
        const res: any = await database.get(url, "", publicUrlKey ? undefined : this.parent.token!);
        result = res?.body?.data?.insight;
      }
      if (result) {
        if (!skipSuitcaseLoad) {
          // @TODO - why is this required here, investigate
          if (publicUrlKey) {
            await this.parent.suitcase.getPublicSuitcase(result.suitcase_id, publicUrlKey);
          } else {
            await this.parent.suitcase.getSuitcases(result.suitcase_id);
          }
        }
        Object.assign(result, {
          templates: result.templates ? JSON.parse(result.templates.toString()) : [],
        });
        // this.comments.addLikes(result.comments);
        let insightJson = {};
        let insightVersions = [];
        try {
          insightJson = JSON.parse(result.json);
          insightVersions = JSON.parse(result.versions);
        } catch (err) {}

        Object.assign(this, {
          result,
          newKeywords: result.keywords,
          data: insightJson,
          versions: insightVersions,
        });
      } else {
        Object.assign(this, new Insight(this.parent));
        this.error.load = true;
      }
      this.parent.loading = false;
      this.loading.load = false;
    } catch (err) {
      this.parent.loading = false;
      this.loading.load = false;
      this.error.load = true;
    }
  }

  // @TODO - clean this up
  @action async updateInfo(info: any) {
    Object.keys(info).forEach(async (key) => {
      if (this.result[key] !== info[key]) {
        this.result[key] = info[key];
        await database.put(`insights/${this.result.id}`, info, this.parent.token!);
        this.updateList();
      }
    });
  }

  @action updateList() {
    // updates active insight name and subheading
    Object.assign(this.parent.suitcase.active!.insights.filter((insight) => insight.id === this.result.id)[0], {
      name: this.result.name,
      subheading: this.result.subheading,
    });
  }

  @action async saveInsight(suitcase_id: any, info: any) {
    if (suitcase_id) {
      info.suitcase_id = suitcase_id;
    }
    const res: any = await database.post("insights", { key: this.parent.insightbuilder.dataset, ...info }, this.parent.token!);
    const insight = res.body.data.insight;
    if (suitcase_id) {
      const res: any = await database.get(`suitcases/${suitcase_id}`, "", this.parent.token!);
      if (res.body.data) {
        this.parent.socket.emit("updateSuitcases", {
          ids: res.body.data.suitcase.users.map((user: any) => user.id).filter((id) => id !== this.parent.user?.id),
        });
      }
    }
    return insight.id;
  }

  // get data for insight
  getInsightData = async (id: string | number): Promise<any | undefined> => {
    const res: any = await database.get(`insights/${id}`, "", this.parent.token!);
    return res?.body?.data?.insight;
  };

  // Search Insights by insight name or by the owner's first and last name
  searchInsights = async (searchString: string, limit: number, skip?: number): Promise<any> => {
    const url = `search/insights?q=${encodeURIComponent(searchString)}${skip ? `&$skip=${skip}` : ""}&$limit=${limit}`;
    const res: any = await database.get(url, "", this.parent.token!);
    return res?.body;
  };
}
