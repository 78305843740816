import * as React from "react";
import { Button, Icon, Label, List, ListItem, Modal, Segment } from "semantic-ui-react";
import { CaseItem, Description, Title } from "component/MoveDuplicateModal/includes/MoveDuplicateModal.style";

interface ITableMoveDuplicate {
  action: string;
  isOpen: boolean;
  closeModal: () => void;
  actionClickHandler: (templateID: undefined | number) => void;
  currentTemplateID?: number;
  templateList: any[];
}

export const TableMoveDuplicateModal = ({
  action,
  isOpen,
  closeModal,
  actionClickHandler,
  currentTemplateID,
  templateList,
}: ITableMoveDuplicate): JSX.Element => {
  const [selectedTemplateID, setSelectedTemplateID] = React.useState<any>(undefined);

  React.useEffect(() => {
    setSelectedTemplateID(currentTemplateID);
  }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Modal.Header>{`${action} table to...`}</Modal.Header>
      <Modal.Content>
        <Segment className="p-0 shadow-none overflow-auto" style={{ maxHeight: 375 }}>
          <List selection divided verticalAlign="middle" size="huge">
            {templateList.length > 0 ? (
              templateList.map((template) => {
                const { id, name, contributors, tables } = template;
                return (
                  <ListItem key={id} className="p-0 cursor-pointer" onClick={() => setSelectedTemplateID(id)}>
                    <CaseItem active={selectedTemplateID === id} className="pl-4">
                      <div>
                        <Title>
                          {name}
                          {currentTemplateID === id && (
                            <Label className="ml-2" size="tiny">
                              Current Template
                            </Label>
                          )}
                        </Title>
                        <Description>
                          {tables.length} tables | {contributors.length} contributors
                        </Description>
                      </div>
                    </CaseItem>
                  </ListItem>
                );
              })
            ) : (
              <p className="fs-1125 text-medium m-3">No template available in this dataset.</p>
            )}
          </List>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button className="mr-2" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={selectedTemplateID === undefined ? true : false}
          color="purple"
          onClick={() => actionClickHandler(selectedTemplateID === currentTemplateID ? undefined : selectedTemplateID)} // If duplicate to the current template, pass "undefined" as "templateID" to the backend
        >
          {action}
          <Icon name={action === "Duplicate" ? "copy" : "sign-in"} className="ml-1 mr-0" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
