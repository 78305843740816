import * as React from "react";
import { ContentWrapper, PageHeader } from "./Components";

interface User {
  type: "users";
  first_name: string;
  last_name: string;
  group_id: number;
  group_name: string;
}

interface Group {
  type: "groups";
  name: string;
  add_ons: { id: number; name: string }[];
  deleted_at: null | string;
  expiry: null | string;
  group_type_name: string;
  members: { id: number; email: string; deactivated_at: null | string }[];
  paid: boolean;
  trial_end_date: null | string;
  user_email: string;
  user_id: number;
}

interface Dataset {
  type: "datasets";
  name: string;
  key: string;
  published: boolean;
  updated_at: null | string;
  deleted_at: null | string;
}

type Data = { id: number } & (User | Group | Dataset);

interface Props {
  data: Data;
  onCancel: () => void;
}

const User = (props): JSX.Element => {
  const { first_name, last_name, id, group_id, group_name } = props;
  return (
    <>
      <h2 className="text-secondary mb-5">{`Showing details for User "${first_name} ${last_name}"`}</h2>
      <ContentWrapper>
        <p><b>Full name: </b>{`${first_name} ${last_name}`}</p>
        <p><b>User ID: </b>{id}</p>
        <p><b>Organisation name: </b>{group_name}</p>
        <p><b>Organisation ID: </b>{group_id}</p>
      </ContentWrapper>
    </>
  );
};

const Group = (props): JSX.Element => {
  const { id, name, add_ons, deleted_at, expiry, group_type_name, members, paid, trial_end_date, user_email, user_id } = props;
  return (
    <>
      <h2 className="text-secondary mb-5">{`Showing details for organisation "${name}"`}</h2>
      <ContentWrapper>
        <p><b>Organisation name: </b>{name}</p>
        <p><b>Organisation ID: </b>{id}</p>
        <p><b>Owner ID: </b>{user_id}</p>
        <p><b>Owner email address: </b>{user_email}</p>
        <p><b>Subscription plan: </b>{group_type_name}</p>
        <p><b>Paid status: </b>{paid ? "Yes" : "No"}</p>
        <p><b>Expiry date: </b>{expiry || "-"}</p>
        <p className="mb-0"><b>Add-ons: </b></p>
        <ul>
          {add_ons.map(addOn => {
            const { id, name } = addOn;
            return (
              <li key={id}>{name}</li>
            );
          })}
        </ul>
        <p><b>Deleted date: </b>{deleted_at || "-"}</p>
        <p><b>Trial end date: </b>{trial_end_date || "-"}</p>
        <p className="mb-2"><b>Organisation members: </b></p>
        {members.map((member) => {
          const { id, email, deactivated_at } = member;
          return (
            <div key={id} className="mb-2">
              <p className="mb-1">{id}</p>
              <p className="mb-1">{email}</p>
              <p className="mb-3">{deactivated_at ? "Inactive" : "Active"}</p>
            </div>
          );
        })}
      </ContentWrapper>
    </>
  );
};

const Dataset = (props): JSX.Element => {
  const { id, name, datasetKey, published, updated_at, deleted_at } = props;
  return (
    <>
      <h2 className="text-secondary mb-5">{`Showing details for Dataset "${name}"`}</h2>
      <ContentWrapper>
        <p><b>Dataset name: </b>{name}</p>
        <p><b>Dataset ID: </b>{id}</p>
        <p><b>Dataset key: </b>{datasetKey}</p>
        <p><b>Published status: </b>{published ? "Yes" : "No"}</p>
        <p><b>Deleted status: </b>{deleted_at || "-"}</p>
        <p><b>Last updated at: </b>{updated_at || "-"}</p>
      </ContentWrapper>
    </>
  );
};

export const SearchResult = (props: Props) => {
  const { data, onCancel } = props;
  const { type, id } = data;
  return (
    <div>
      <div>
        <PageHeader
          label={type === "users" ? `${data.first_name} ${data.last_name}` : data.name}
          onCancel={onCancel}
        />
        {type === "users" && (
          <User
            id={id}
            first_name={data.first_name}
            last_name={data.last_name}
            group_id={data.group_id}
            group_name={data.group_name}
          />
        )}
        {type === "groups" && (
          <Group
            id={id}
            name={data.name}
            add_ons={data.add_ons}
            deleted_at={data.deleted_at}
            expiry={data.expiry}
            group_type_name={data.group_type_name}
            members={data.members}
            paid={data.paid}
            trial_end_date={data.trial_end_date}
            user_email={data.user_email}
            user_id={data.user_id}
          />
        )}
        {type === "datasets" && (
          <Dataset
            id={id}
            name={data.name}
            datasetKey={data.key}
            published={data.published}
            updated_at={data.updated_at}
            deleted_at={data.deleted_at}
          />
        )}
      </div>
    </div>
  );
};
