import * as React from "react";
import * as L from "leaflet";
import { MapContainer as Map, TileLayer, LayersControl, useMapEvent, GeoJSON, Circle } from "react-leaflet";
import { useLeafletContext } from "@react-leaflet/core";
import { Helmet } from "react-helmet";
import { Dropdown as SemanticDropdown } from "semantic-ui-react";
import styled from "styled-components";
import { VectorTileLayer } from "component/LeafletCustom/VectorTileLayer";
import { FullScreenControl } from "component/LeafletCustom/FullScreenControl";
import { GeoSearch } from "component/LeafletCustom/GeoSearch";
import { BottomLeftContent, ContentBox } from "pages/Maps/includes/Layout";
import { blueIcon, greenIcon, yellowIcon, orangeIcon, redIcon, purpleIcon } from "pages/Maps/includes/Icons";
import { brushInterpolate } from "common/helpers/palette";

const proximityCanvas = ["#e37700", "#ffed50", "#236A4D"];
const proximityColors = brushInterpolate(proximityCanvas, [101])[0]; // need 101 colors for value between 0-100%

const Dropdown = styled(SemanticDropdown)`
  border: 2px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px !important;
  background-clip: padding-box !important;
`;

const serviceTypeList = [
  "Early childhood education",
  "Education support",
  "Family violence child safety",
  "Out of home care",
  "Parenting",
  "Pregnancy and birth",
];
const colorsOrder = [blueIcon, greenIcon, yellowIcon, orangeIcon, redIcon, purpleIcon];
const serviceTypeOptions = serviceTypeList.map((item) => ({ key: item, value: item.replace(/ /g, "-"), text: item }));
const serviceTypeLabelMap = serviceTypeOptions.reduce((prev, curr) => ({ ...prev, [curr.value]: curr.text }), {});
const serviceTypeMarkerIconMap = serviceTypeList.reduce((prev, curr, idx) => ({ ...prev, [curr]: colorsOrder[idx] }), {});

const serviceDistanceOptions = [
  { key: "2.5km", value: "2500", text: "2.5km" },
  { key: "5km", value: "5000", text: "5km" },
  { key: "10km", value: "10000", text: "10km" },
];
const serviceDistanceLabelMap = serviceDistanceOptions.reduce((prev, curr) => ({ ...prev, [curr.value]: curr.text }), {});

export const ComTasServices = (): JSX.Element => {
  const DEFAULT_ZOOM = 7;
  const DEFAULT_MAP_CENTRE: any = [-42.13655, 146.223918];
  const MAP_BOUNDS: any = [
    [-38, 140],
    [-45, 152],
  ];
  const DEFAULT_OPACITY = 0.7;
  const DEFAULT_LW = 1;
  const HOVER_OPACITY = 0.7; // no change desired..
  const HOVER_LW = 2;
  const [visibleLayers, setVisibleLayers] = React.useState<string[]>([]);
  const [serviceType, setServiceType] = React.useState(serviceTypeOptions[0].value);
  const [distance, setDistance] = React.useState(serviceDistanceOptions[0].value); // distance from service
  const [serviceMarkerData, setServiceMarkerData] = React.useState<any>(null);
  const typeDropdownRef = React.useRef<any>(null);
  const distanceDropdownRef = React.useRef<any>(null);

  const pickQuintilleColor = (property) => {
    const proximityValue = Math.floor(property[`val_${distance}_${serviceTypeLabelMap[serviceType]}`] * 100);
    return proximityColors[proximityValue];
  };
  const legends = {
    sa1_21_map: {
      colors: proximityColors,
      label: "Service Proximity",
    },
  };

  const defaultStyle = (properties) => ({
    stroke: false,
    weight: DEFAULT_LW,
    fillOpacity: DEFAULT_OPACITY,
    opacity: 1, // line opacity
    fillColor: pickQuintilleColor(properties),
    fill: pickQuintilleColor(properties),
  });
  const defaultHighlightStyleFn = () => ({
    stroke: true,
    color: "#ffffff", // stroke colour
    weight: HOVER_LW,
    fillOpacity: HOVER_OPACITY,
  });
  const renderFieldValue = (field, value) => {
    if (field === "percent_in" || field.startsWith("val_")) {
      const number = Number(value);
      return isNaN(number) ? "-" : `${(number * 100).toFixed()}%`;
    }
    return value;
  };
  const tooltipRenderer = (fields, aliases) => (properties) => `
    <div>
      ${fields
        .map((field, idx) => {
          const wrapper = idx === 0 ? "b" : "span";
          return `
          <p style="margin: 0; font-size: 1rem;">
            <${wrapper}>${aliases[idx]}: ${renderFieldValue(field, properties[field])}</${wrapper}>
          </p>
        `;
        })
        .join("")}
    </div>
  `;
  const vectorLayersConfig = [
    {
      key: "sa1_21_map",
      name: "Data On",
      defaultChecked: true,
      url: "https://seer-maps.s3.ap-southeast-2.amazonaws.com/cta/services/sa1_21_map/{z}/{x}/{y}.pbf",
      vectorTileLayerStyles: { sa1_21_map: defaultStyle },
      highlightStyleFn: defaultHighlightStyleFn,
      maxNativeZoom: 16,
      idProperty: "SA1_CODE21",
      interactive: true,
      // @TODO - we want to render service proximity differently
      tooltipContent: tooltipRenderer(
        [`val_${distance}_${serviceTypeLabelMap[serviceType]}`, "SA2_NAME21", "SA1_CODE21", "population_total"],
        ["Proximity Value", "SA2 NAME", "SA1 CODE", "Total Population"],
      ),
    },
  ];

  // custom component purely for watching for map events as eventHandlers not working on some components in this version of react-leaflet
  const vectorNameKeyMap = vectorLayersConfig.reduce((prev, next) => ({ ...prev, [next.name]: next.key }), {});
  const vectorKeyOrder = vectorLayersConfig.map((config) => config.key);
  const WatchLayerChanges = () => {
    const map = useLeafletContext().map;
    React.useEffect(() => {
      const overlayaddListener = (e) => {
        const newValue = [...visibleLayers, vectorNameKeyMap[e.name] || "markers"];
        newValue.sort((a, b) => vectorKeyOrder.indexOf(a) - vectorKeyOrder.indexOf(b)); // sort same as config order rather than appended
        setVisibleLayers(newValue);
      };
      const overlayremoveListener = (e) => setVisibleLayers(visibleLayers.filter((key) => key !== (vectorNameKeyMap[e.name] || "markers")));
      map.on({
        overlayadd: overlayaddListener,
        overlayremove: overlayremoveListener,
      });
      return () => {
        map.off({
          overlayadd: overlayaddListener,
          overlayremove: overlayremoveListener,
        });
      };
    }, []);
    // watch click events to manually blur the service data filter dropdowns, workaround for leaflet not propagating some click events
    useMapEvent("click", (event) => {
      const clickPath = event.originalEvent.composedPath();
      const clickedTypeDropdown = clickPath.some((element) => element instanceof Element && element.id === "service_type_dropdown");
      const clickedDistanceDropdown = clickPath.some((element) => element instanceof Element && element.id === "service_distance_dropdown");
      if (!clickedTypeDropdown && typeDropdownRef.current?.state.open) {
        typeDropdownRef.current.close();
      }
      if (!clickedDistanceDropdown && distanceDropdownRef.current?.state.open) {
        distanceDropdownRef.current.close();
      }
    });
    return null;
  };

  // load service marker GeoJSON data
  const loadServiceMarkerData = async () => {
    const files = serviceTypeOptions.map((item) => [item.value, `tascoss_markers_${item.value}.geojson`]);
    const data = {};
    for (const [typeValue, filename] of files) {
      const json = await fetch(`https://seer-maps.s3.ap-southeast-2.amazonaws.com/cta/services/${filename}`)
        .then((res) => res.json())
        .catch(() => null);
      if (json) {
        data[typeValue] = json;
      }
    }
    setServiceMarkerData(data);
  };
  React.useEffect(() => {
    loadServiceMarkerData();
  }, []);

  const serviceMarkerGeoJSON = serviceMarkerData?.[serviceType];
  const markersLayerVisible = visibleLayers.includes("markers");
  const visibleVectorLayers = visibleLayers.filter((layer_name) => !!legends[layer_name]);

  return (
    <>
      <Helmet>
        <body className="hide-header-and-hubspot" />
        <title>Communities of Tasmania Parks Map</title>
      </Helmet>

      <Map
        center={DEFAULT_MAP_CENTRE}
        zoom={DEFAULT_ZOOM}
        minZoom={DEFAULT_ZOOM}
        maxZoom={16}
        maxBounds={MAP_BOUNDS}
        // style={{ background: colorPalette.background }}
        className="m-0 w-100 h-100"
      >
        <FullScreenControl />
        <GeoSearch provider={null} showMarker={false} autoClose position="topright" style="button" />
        <BottomLeftContent>
          {/* legend content */}
          {!!visibleVectorLayers.length && (
            <ContentBox>
              {/* for open layers, map out the legend*/}
              {visibleVectorLayers.map((layer_name, idx) => {
                const legendConfig = legends[layer_name];
                const multiColor = !!legendConfig.colors;
                return (
                  <div key={layer_name}>
                    {multiColor ? (
                      <>
                        <p className="mb-1">{legendConfig.label}</p>
                        <div className="d-flex mb-1">
                          {legendConfig.colors.map((color) => (
                            <div style={{ height: 15, width: 1, background: color }} key={color.replace(/\W/, "")} />
                          ))}
                        </div>
                        <p className="m-0">Low to high</p>
                      </>
                    ) : (
                      <div className="d-flex">
                        <div className="mr-2" style={{ height: 15, width: 15, background: legendConfig.color }} />
                        <p className="m-0">{legendConfig.label}</p>
                      </div>
                    )}
                    {idx !== visibleVectorLayers.length - 1 && <hr />}
                  </div>
                );
              })}
            </ContentBox>
          )}
          {/* this dropdown only has relevance when at least one layer of any type is visible */}
          {!!visibleLayers.length && (
            <Dropdown
              id="service_type_dropdown"
              ref={typeDropdownRef}
              options={serviceTypeOptions}
              value={serviceType}
              text={`Service type: ${serviceTypeLabelMap[serviceType] || "Select a type"}`}
              onChange={(_e, data) => setServiceType(`${data.value}`)}
              closeOnBlur={false}
              clearable={false}
              selection
            />
          )}
          {/* this dropdown only has relevance when vector layer is enabled */}
          {!!visibleVectorLayers.length && (
            <Dropdown
              id="service_distance_dropdown"
              ref={distanceDropdownRef}
              options={serviceDistanceOptions}
              value={distance}
              text={`Distance from service: ${serviceDistanceLabelMap[distance] || "Select a distance"}`}
              onChange={(_e, data) => setDistance(`${data.value}`)}
              closeOnBlur={false}
              clearable={false}
              selection
            />
          )}
        </BottomLeftContent>
        <WatchLayerChanges />

        {serviceMarkerGeoJSON && markersLayerVisible && (
          <GeoJSON
            key={serviceType}
            data={serviceMarkerGeoJSON}
            onEachFeature={(feature, layer) => {
              const renderProperties = [
                ["Organisation name", "Org_name"],
                ["Category 1", "Sub_Service1"],
                ["Category 2", "Sub_Service2"],
                // ["Service Description", "Service_Description"],
              ];
              const tooltipContent = `
                <div>
                  ${renderProperties
                    .map(
                      ([label, key]) => `
                    <p style="margin: 0; font-size: 1rem;"><b>${label}</b>: ${feature.properties[key]}</p>
                  `,
                    )
                    .join("\n")}
                </div>
              `;
              layer.bindTooltip(tooltipContent, { direction: "top", sticky: true });
            }}
            pointToLayer={(feature, latLng) => {
              const useIcon = serviceTypeMarkerIconMap[feature?.properties.Sub_Service1] || blueIcon;
              return L.marker(latLng, { icon: useIcon });
            }}
          />
        )}

        {/* only one base tile map */}
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
          attribution={
            '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>'
          }
        />

        {/* controlled layers */}
        <LayersControl position="bottomright">
          {/* hidden layer just for toggling marker GeoJSON on/off since GeoJSON doesn't work correctly here when the data source changes */}
          <LayersControl.Overlay name="Markers On" checked={visibleLayers.includes("markers")} key="markers">
            <Circle center={DEFAULT_MAP_CENTRE} stroke={false} fill={false} />
          </LayersControl.Overlay>

          {/* vector tiles */}
          {vectorLayersConfig.map((config) => {
            const { name, key, ...rest } = config;
            return (
              <LayersControl.Overlay name={name} checked={visibleLayers.includes(key)} key={key}>
                <VectorTileLayer key={`${key}_${serviceType}_${distance}`} {...rest} />
              </LayersControl.Overlay>
            );
          })}
        </LayersControl>
      </Map>
    </>
  );
};
