import * as React from "react";
import { Form } from "semantic-ui-react";
import Select from "react-select";
import { customReactSelectStyles } from "common/helpers/dataset";

export const FieldSelect = ({ input, meta, label, placeholder, options, disabled = false }) => {
  const error = meta.error || meta.submitError;
  const showError = error && meta.touched;
  return (
    <Form.Field disabled={disabled}>
      {label && <label>{label}</label>}
      <Form.Select
        {...input}
        disabled={disabled}
        onChange={(_, data) => input.onChange(data.value || "")}
        options={options}
        placeholder={placeholder || ""}
        error={showError}
        multiple={!!input.multiple}
      />
      {showError && (
        <p className="text-danger fs-0875">
          <b>{error}</b>
        </p>
      )}
    </Form.Field>
  );
};

export const FieldReactSelect = (props): JSX.Element => {
  const { input, meta, label, styles, placeholder, isMulti, isDisabled, isClearable, isLoading, options, onChangeCallback } = props;
  const error = meta.error || meta.submitError;
  const showError = error && meta.touched;
  return (
    <Form.Field>
      {label && <label>{label}</label>}
      <Select
        {...input}
        onChange={(option) => {
          input.onChange(option);
          onChangeCallback && onChangeCallback(option);
        }}
        isMulti={isMulti}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        styles={{ ...customReactSelectStyles, ...styles }}
        placeholder={placeholder || ""}
        options={options}
      />
      {showError && (
        <p className="text-danger fs-0875">
          <b>{error}</b>
        </p>
      )}
    </Form.Field>
  );
};
