import * as React from "react";
import { Form, Icon, Ref } from "semantic-ui-react";
import * as AutoSuggest from "react-autosuggest";
import { includes } from "lodash";
import AutoSuggestHighlightMatch = require("autosuggest-highlight/match");
import AutoSuggestHighlightParse = require("autosuggest-highlight/parse");

const maxSuggestionsToDisplay = 10;

interface Props {
  rawOptions: string[];
  toBeFilteredOutOptions?: string[];
  fieldMeta: any;
  resetForm: () => void;
  // TODO: refactor the code to remove "submitForm" when work on handling select multiple variables at once
  submitForm?: () => void;
  onSelect?: (value) => any;
}

export const CustomAutoSuggest = (props: Props): JSX.Element => {
  const [suggestions, setSuggestions] = React.useState<Array<any>>([]);
  const { rawOptions, toBeFilteredOutOptions, fieldMeta, resetForm, onSelect, submitForm } = props;
  return (
    <AutoSuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={({ value }) => {
        const inputValue = value.toString().trim().toLowerCase();
        const inputLength = inputValue.length;
        const addNew: any = [{ isNew: true, text: value }];
        const next: Array<any> =
          inputLength === 0
            ? []
            : rawOptions
                .filter((cat: any) => cat.toString().toLowerCase().slice(0, inputLength) === inputValue)
                .filter((cat) => !includes(toBeFilteredOutOptions, cat.toString().toLowerCase()))
                .slice(0, maxSuggestionsToDisplay);

        // Only show "Add new" if the value doesn't already exist in the options list and toBeFilteredOutOptions list
        if (
          next.find((option) => option.toString().toLowerCase() === inputValue) === undefined &&
          toBeFilteredOutOptions?.find((option) => option.toString().toLowerCase() === inputValue) === undefined
        ) {
          setSuggestions(next.map((text) => ({ text })).concat(addNew));
        } else {
          setSuggestions(next.map((text) => ({ text })));
        }
      }}
      onSuggestionsClearRequested={() => setSuggestions([])}
      getSuggestionValue={(suggestion: any) => suggestion.text}
      renderSuggestion={(suggestion, { query }) => {
        const text = suggestion.text;
        const matches = AutoSuggestHighlightMatch(text, query);
        const parts = AutoSuggestHighlightParse(text, matches);
        return (
          <span>
            {suggestion.isNew ? (
              <>
                <Icon className="mr-2" name="plus" /> Add "{suggestion.text}"
              </>
            ) : (
              parts.map((part, idx) => (
                <span className={part.highlight ? "highlight" : ""} key={`${idx}${part.text}`}>
                  {part.text}
                </span>
              ))
            )}
          </span>
        );
      }}
      inputProps={{
        value: fieldMeta?.value?.toString() || "",
        onChange: (_, { newValue }) => {
          resetForm();
          fieldMeta.change(newValue);
        },
        onBlur: () => fieldMeta.blur(),
        onFocus: () => fieldMeta.focus(),
        autoFocus: true,
      }}
      renderInputComponent={({ ref: inputRef, ...inputProps }: any) => (
        <>
          <Form.Field>
            <Ref innerRef={inputRef}>
              <Form.Input
                {...inputProps}
                fluid
                placeholder="Search or create..."
                error={
                  fieldMeta.error || (fieldMeta.submitError && fieldMeta.touched)
                    ? { content: fieldMeta.error || fieldMeta.submitError, pointing: "above" }
                    : undefined
                }
                width={undefined} // inputProps is passing down some unwanted props
                size={undefined} // inputProps is passing down some unwanted props
              />
            </Ref>
          </Form.Field>
        </>
      )}
      onSuggestionSelected={(_e, { suggestionValue }) =>
        setTimeout(() => {
          // timeout to ensure value is updated before submit
          if (onSelect && !fieldMeta.error) {
            onSelect(suggestionValue);
            resetForm(); // Clear the text inside input box
          } else if (submitForm) {
            submitForm();
          }
        }, 10)
      }
    />
  );
};
