import validator from "validator";

export const composeValidators = (...validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined);
export const required = (value) => (value ? undefined : "*Required");
export const selectionRequired = (values) => (values?.length ? undefined : "*Required"); // Used for multiple selection dropdown
export const mustBeNumber = (value) => (isNaN(value) ? "Must be a number" : undefined);
export const notRequiredMustBeNumber = (value) => value !== undefined && isNaN(value) ? "Must be a number" : undefined; // Not required, but if provided, it must be a number
export const minValue = (min) => (value) => isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;
export const mustBeValidEmail = (value) => validator.isEmail(value) ? undefined : "Must be a valid email";
export const validDate = (value) => new Date(value).toString() === "Invalid Date" ? "Not a valid date" : undefined;
export const requiredCustomMessage = (errorMessage: string) => (value) => value ? undefined : errorMessage;
