import styled from "styled-components";

// This wrapper is used for Suitcases and Insights descriptions created with TinyMCE to maintain a consistent look and feel after integrating the generated Semantic theme.
export const DescriptionWrapper = styled.div`
  h1, h2, h3, h4, h5, h6, a {
    "font-family": "Lato";
  }
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 15px;
  }
  h5 {
    font-size: 14px;
  }
  h6 {
    font-size: 10px;
  }
  a {
    color: #4183c4;
  }
`;
