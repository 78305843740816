// Based of https://react-dnd.github.io/react-dnd/examples/sortable/simple with updates to work with x and y axis movements
import * as React from "react";
import { useDrag, useDrop } from "react-dnd";
import { XYCoord } from "dnd-core";

interface DragItem {
  id: string;
  index: number;
  type: string;
}
export const DraggableCanvasColor = ({ children, moveCanvasItem, index, id }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "canvasItem",
    collect: (monitor) => ({ handlerId: monitor.getHandlerId() }),
    hover: (item: DragItem, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle (-14px margin)
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top - 14) / 2;
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left - 14) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // When dragging right, only move when the cursor is past 50%
      // When dragging left, only move when the cursor is before 50%
      let moveIt = false;
      if (dragIndex < hoverIndex && hoverClientY >= hoverMiddleY) {
        // Dragging downwards
        moveIt = true;
      } else if (dragIndex > hoverIndex && hoverClientY <= hoverMiddleY) {
        // Dragging upwards
        moveIt = true;
      } else if (dragIndex < hoverIndex && hoverClientX >= hoverMiddleX) {
        // Dragging right
        moveIt = true;
      } else if (dragIndex > hoverIndex && hoverClientX <= hoverMiddleX) {
        // Dragging left
        moveIt = true;
      }

      if (moveIt) {
        // Time to actually perform the action
        moveCanvasItem(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      }
    },
  });

  const [, drag] = useDrag({
    item: { type: "canvasItem", index, id },
    // collect: (monitor: any) => ({ isDragging: monitor.isDragging() }),
  });

  drag(drop(ref));
  return (
    <div ref={ref} data-handler-id={handlerId} style={{ cursor: "all-scroll" }}>
      {children}
    </div>
  );
};
