import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Icon, Button } from "semantic-ui-react";
import * as copy from "copy-to-clipboard";

import { ExplorePillLabel } from "./ExplorePillLabel";
import { getCategorySelectionState } from "common/helpers/explore";
import { Builder } from "common/store/builder";

interface IExploreFullQuery {
  insightbuilder?: any;
  builder?: Builder;
  analystPortal?: boolean;
}

const queryItems = [
  {
    label: "Topic",
    keyword: "Whats",
    type: "What",
  },
  {
    label: "Quantity",
    keyword: "Hows",
    type: "How",
  },
  {
    label: "Time",
    keyword: "Whens",
    type: "When",
  },
  {
    label: "Location",
    keyword: "Wheres",
    type: "Where",
  },
];

const ExploreFullQueryComponent = (props: IExploreFullQuery ): JSX.Element => {
  const builder = props.builder!;
  const insightbuilder = props.insightbuilder!;
  const { analystPortal } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const availableCategoryOptions = type => Object.entries(builder.dimensions).filter(([_, v]) =>
    v.type === type && v.requirement !== "excluded"
  );

  const selectedCategoryOptions = type => Object.keys(builder.qsAllFilters).filter(x =>
    builder.dimensions[x]?.type === type
  );

  const availableSubCategoryOptions = categoryOption => builder.dimensions[categoryOption]?.values
    .filter(value => value.requirement !== "excluded")
    .map(value => value.name)
    || [];

  const selectedSubCategoryOptions = categoryOption => builder.qsAllFilters[categoryOption] ?? [];

  const isOpen = insightbuilder.showFullQuery || analystPortal;
  const openHeight = analystPortal ? "100%" : "70%";

  const queryJSON = {
    filters: builder.qsAllFilters,
    datasets: builder.datasetsToQuery.length > 0 ? builder.datasetsToQuery : undefined,
  };

  return (
    <div
      className="position-absolute left-0 bottom-0 w-100 text-white rounded-3 px-4 overflow-auto"
      style={{
        background: `rgba(0, 0, 0, ${analystPortal ? "0.85" : "0.8"}`, zIndex: 2, transition: "height 0.3s",
        // Show/Hide full query with height animation
        height: isOpen ? openHeight : 0,
      }}
    >
      {!analystPortal ? (
        <>
          <div className="d-flex align-items-center mt-3 float-right cursor-pointer" onClick={() => insightbuilder.toggleFullQuery()}>
            <p className="fs-1000 fw-700 mb-0 mr-1">Close</p>
            <Icon name="close" size="small" style={{ marginTop: 3, marginRight: 0 }} />
          </div>
          {queryItems.map(item => {
            const { label, keyword, type } = item;
            return (
              <>
                <div className="fs-1125 mt-4 mb-2">
                  <h5 className="d-inline">{label}</h5>
                  {
                    Object.keys(builder.qsAllFilters).length > 0 &&
                    <p className="d-inline">
                      {
                        keyword === "Hows" ?
                          ` (${selectedSubCategoryOptions("Measured quantity").length}/${
                            builder.dimensions["Measured quantity"] ? availableSubCategoryOptions("Measured quantity").length : 0
                          })` :
                          ` (${selectedCategoryOptions(type).length}/${availableCategoryOptions(type).length})`
                      }
                    </p>
                  }
                </div>
                {
                  // TODO: Fix this to reference new builder instead
                  getCategorySelectionState(insightbuilder, keyword) === "no options available" ?
                    <p className="fs-0875 text-grey">No options available</p> : (
                      // Selected options for 'Quantity'
                      label === "Quantity" ?
                        (
                          selectedSubCategoryOptions("Measured quantity").length !== 0 ?
                            selectedSubCategoryOptions("Measured quantity").map(subCategoryOption => (
                              <ExplorePillLabel
                                hasBorder={true}
                                label={subCategoryOption}
                              />
                            )) : <p className="fs-0875 text-grey">No options selected</p>
                        ) : (
                          // Selected options for 'Topic', 'Location' and 'Time'
                          selectedCategoryOptions(type).length !== 0 ?
                            selectedCategoryOptions(type).map(categoryOption => (
                              <div key={categoryOption} className="mb-3">
                                <ExplorePillLabel
                                  hasBorder={true}
                                  label={
                                    `${categoryOption}
                              (${selectedSubCategoryOptions(categoryOption).length}/${availableSubCategoryOptions(categoryOption).length})`
                                  }
                                />
                                {
                                  selectedSubCategoryOptions(categoryOption).map(subCategoryOption => (
                                    <ExplorePillLabel
                                      label={subCategoryOption}
                                    />
                                  ))
                                }
                              </div>
                            )) : <p className="fs-0875 text-grey">No options selected</p>
                        )
                    )
                }
              </>
            );
          })}
          {/* Selected dataset */}
          <div className="mb-4">
            <div className="fs-1125 mt-4 mb-2">
              <h5 className="d-inline">Dataset</h5>
              {Object.keys(builder.qsAllFilters).length > 0 && (
                <p className="d-inline">
                  {` (${builder.userSelectedDatasets.length}/${builder.datasetsToQuery.length})`}
                </p>
              )}
            </div>
            {builder.userSelectedDatasets.length > 0 ? (
              <ExplorePillLabel
                hasBorder={true}
                label={builder.getDatasetName(builder.userSelectedDatasets[0])}
              />
            ) : (
              <p className="fs-0875 text-grey">No options selected</p>
            )}
          </div>
        </>
      ) : (
        <div className="mt-3">
          <h4>API Query Body</h4>
          <p className="fs-1000">
            For any variable, to select all the possible options for it and receive the latest data (eg. Year Month),
            you can replace the variable contents with the string "__ALL__" instead of a list of items.<br/><br/>
            Example:<br/><br/>
            "Year Month": [ "2021 May", "2021 Apr", "2021 Mar" ]<br/>
            to:<br/>
            "Year Month": "__ALL__"
          </p>
          <Button onClick={() => copy(JSON.stringify(queryJSON))}>
            Copy query to clipboard <Icon name="copy outline" className="ml-2" />
          </Button>
          <pre>{JSON.stringify(queryJSON, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export const ExploreFullQuery = inject((stores: any) => ({
  insightbuilder: stores.store.insightbuilder,
  builder: stores.store.builder,
}))(observer(ExploreFullQueryComponent));
