import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { Button } from "component/UI/Button";
import { ListValidation, NumberValidation, RegExpValidation } from "common/store/dataset/urlUpload";
import { Grid, GridColumn, GridRow, Icon } from "semantic-ui-react";
import { ValidationEditor, VALIDATION_SCHEMA } from "./ValidationEditor";
import { COLORS } from "component/UI/common";
import { Popup } from "component/UI/Popup";
import { FORM_ERROR } from "final-form";

interface ValidationList {
  store?: Store;
}

const Component = (props: ValidationList) => {
  const { urlupload } = props.store!.dataset;
  const { staged } = urlupload;
  const columnOptions = staged![0]
    .map((col, _idx) => ({ value: col, label: col }));
  const [showCreateValidation, setShowCreateValidation] = React.useState(false);
  const [showEditValidation, setShowEditValidation] = React.useState(false);
  const [columnValidation, setColumnValidation] = React.useState({});

  const handleSave = (values) => {
    const { column, allowEmpty, type, subType, fromType, from, toType, to, list, regexp } = values;
    const validation = {};
    validation["allowEmpty"] = !!allowEmpty;
    if (type.value.includes("number")) {
      validation["type"] = type.value;
      validation["subType"] = subType;
      validation["fromType"] = fromType;
      if (from) {
        validation["from"] = +from;
      }
      validation["toType"] = toType;
      if (to) {
        validation["to"] = +to;
      }
    } else if (type.value === "list") {
      validation["type"] = "list";
      validation["list"] = list.split("\n");
    } else {
      validation["type"] = "regexp";
      try {
        if (typeof regexp === "object") {
          validation["regexp"] = regexp.value;
        } else {
          new RegExp(regexp); // Check if the string is a valid regular expression
          validation["regexp"] = regexp;
        }
      } catch (e) {
        console.log(e);
        return { [FORM_ERROR]: "Invalid regular expression" };
      }
    }
    urlupload.setColumnValidation(column.label, validation as NumberValidation | ListValidation | RegExpValidation);
    setShowCreateValidation(false);
    setShowEditValidation(false);
    return;
  };

  const handleDelete = (colName: string) => {
    urlupload.deleteColumnValidation(colName);
  };

  const handleEdit = (colName: string) => {
    const validation = urlupload.columnValidation[colName];
    const columnValidation = {
      column: colName,
      validation,
    };
    setColumnValidation(columnValidation);
    setShowEditValidation(true);
  };

  return (
    <>
      {showCreateValidation ? (
        <ValidationEditor
          columnOptions={columnOptions.filter((col) => !Object.keys(urlupload.columnValidation).includes(col.label))}
          onSave={handleSave}
          onClose={() => setShowCreateValidation(false)}
        />
      ) : (
        <>
          {showEditValidation ? (
            <ValidationEditor
              columnOptions={columnOptions}
              columnValidation={columnValidation}
              onSave={handleSave}
              onClose={() => setShowEditValidation(false)}
            />
          ) : (
            <>
              <Button colorConfig="purpleInverted" onClick={() => setShowCreateValidation(true)} fluid className="mb-4">
                Add validation <Icon name="plus" className="ml-2" />
              </Button>
              {Object.keys(urlupload.columnValidation).length > 0 ? (
                <Grid verticalAlign="middle" columns="equal" className="m-0 w-100">
                  {Object.keys(urlupload.columnValidation).map((col, cIdx) => {
                    const type = VALIDATION_SCHEMA.type.value.find(option => option.value === urlupload.columnValidation[col].type)?.label;
                    return (
                      <GridRow key={cIdx} className="pt-0">
                        <GridColumn>
                          <Popup
                            content={(
                              <>
                                {Object.keys(urlupload.columnValidation[col]).map((key, vIdx) => {
                                  const value = urlupload.columnValidation[col][key];
                                  const findValue = VALIDATION_SCHEMA[key].value?.find(option => option.value === value);
                                  const displayedValue = VALIDATION_SCHEMA[key].value && findValue
                                    ? VALIDATION_SCHEMA[key].value.find(option => option.value === value).label
                                    : value;
                                  return (
                                    <p key={vIdx} className="mb-0">{`${VALIDATION_SCHEMA[key].label}: ${displayedValue}`}</p>
                                  );
                                })}
                              </>
                            )}
                            trigger={<p className="fw-700 text-secondary my-0 cursor-pointer">{col}</p>}
                            position="bottom center"
                          />
                        </GridColumn>
                        <GridColumn width={1}>
                          <div style={{ width: 1, height: 18, backgroundColor: COLORS.grey600 }}></div>
                        </GridColumn>
                        <GridColumn width={8}>
                          <p className="fw-700 my-0" style={{ color: COLORS.grey700 }}>{type}</p>
                        </GridColumn>
                        <GridColumn width={4}>
                          <div className="d-flex gap-1">
                            <div style={{ padding: 8, backgroundColor: "white", borderRadius: 4 }} className="cursor-pointer" onClick={() => handleEdit(col)}>
                              <Icon name="edit outline" className="text-secondary" />
                            </div>
                            <div style={{ padding: 8, backgroundColor: "white", borderRadius: 4 }} className="cursor-pointer" onClick={() => handleDelete(col)}>
                              <Icon name="trash alternate outline" className="text-secondary" />
                            </div>
                          </div>
                        </GridColumn>
                      </GridRow>
                    );
                  })}
                </Grid>
              ) : (
                <>
                  <p>No validations added.</p>
                  <div className="d-flex">
                    <Icon name="info circle" className="mt-1 mr-1" />
                    <div>
                      <p className="mb-2">Adding validations can help control the quality of your data. For example, you can validate whether there are unwanted values or incorrect data types in the uploaded file by setting the expected values for each column.</p>
                      <p>If errors are flagged against your validation(s) upon clicking upload, you can edit your file accordingly before uploading again.</p>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export const ValidationList = inject("store")(observer(Component));
