import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Icon } from "semantic-ui-react";
import { AvatarGroup, AvatarItemProps } from "component/AvatarGroup";
import { CollaborateDropdown } from "./CollaborateDropdown";
import { StyleConfirm } from "component/Explore/includes/NewExplore.style";
import { AddAndEditAction } from "./AddAndEditAction";
import { ActionCard, StyledProgress, TextButton } from "./Collaborate.style";
import { COLORS } from "component/UI/common";
import { DashboardAction } from "common/store/dashboard";
import Store from "common/store";
import { ActionTaskInput } from "./ActionTaskInput";
import { EditContributorsModal } from "./EditContributorsModal";
import { getMixpanel } from "common/api";
import { formatDate } from "common/helpers/dashboard";
import { IconButton } from "./components";
import { StylePopup } from "component/insightBuilder/insightBuilderStyles/whereWhatWhen.style";

interface Props {
  dashboardID: number;
  action: DashboardAction;
  collaborators: any[];
  store?: Store;
}

const Component = (props: Props): JSX.Element => {
  const { dashboardID, action, collaborators, store } = props;
  const dashboardStore = store!.dashboard;
  const { id, user_id, first_name, last_name, avatar, action_contributors, title, description, outcome, action_tasks, resolved_at, created_at, due_date, dashboard_reference } = action;

  const [showTasks, setShowTasks] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showEditAction, setShowEditAction] = React.useState(false);
  const [showAddTaskInput, setShowAddTaskInput] = React.useState(false);
  const [showEditContributorsModal, setShowEditContributorsModal] = React.useState(false);
  const [foldCard, setFoldCard] = React.useState(resolved_at ? true : false);
  const [showActionButtons, setShowActionButtons] = React.useState(false);

  let progressPercent;
  let progressBackground = COLORS.aqua200;
  let progressForeground = COLORS.aqua500;
  if (due_date) {
    const startTime = Date.parse(created_at);
    const endTime = Date.parse(due_date);
    const currentTime = Date.now();
    const totalDays = Math.ceil((endTime - startTime) / 86400000);
    const spentDays = Math.ceil((currentTime - startTime) / 86400000);
    if (totalDays === 1) {
      progressPercent = 99;
    } else {
      progressPercent = (spentDays / totalDays) * 100;
    }
    if (progressPercent >= 51 && progressPercent <= 75) {
      progressBackground = COLORS.yellow;
      progressForeground = COLORS.orange;
    } else if (progressPercent > 75) {
      progressBackground = COLORS.red200;
      progressForeground = COLORS.red500;
    }
  }

  const avatarUsers = [{ name: `${first_name} ${last_name}`, image: avatar }]
    .concat(action_contributors.map(contributor => ({
      name: `${contributor.first_name} ${contributor.last_name}`,
      image: contributor.avatar,
    })));

  const contributorsSelections = action_contributors?.filter(user => user.id !== store!.user!.id).map(contributor => ({
    id: contributor.user_id,
    label: `${contributor.first_name} ${contributor.last_name}`,
    avatar: contributor.avatar,
    value: contributor.user_id,
    contributorID: contributor.id,
  }));

  const updateAction = async (actionBody: Partial<DashboardAction>): Promise<void> => {
    getMixpanel(store!).track("Collaborate > Actions > Update Action", { "Dashboard ID": dashboardID, "Action ID": id });
    const success = await dashboardStore.updateDashboardAction(dashboardID, id, actionBody);
    if (success) {
      await dashboardStore.refreshDashboardActions(dashboardID);
      dashboardStore.setSelectionOverlay("highlight");
      setShowEditAction(false);
    }
    // TODO: show error messages to handle endpoint call failure
  };

  const deleteActionHandler = async (): Promise<void> => {
    getMixpanel(store!).track("Collaborate > Actions > Delete Action", { "Dashboard ID": dashboardID, "Action ID": id });
    const success = await dashboardStore.deleteDashboardAction(dashboardID, id);
    if (success) {
      await dashboardStore.refreshDashboardActions(dashboardID);
      dashboardStore.setDashboardSelection(null);
    }
    // TODO: show error messages to handle endpoint call failure
  };

  const resolveAction = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    getMixpanel(store!).track("Collaborate > Actions > Resolve Action", { "Dashboard ID": dashboardID, "Action ID": id, "Resolved": !resolved_at ? "Yes" : "No" });
    e.stopPropagation();
    const success = await dashboardStore.updateDashboardAction(dashboardID, id, { resolved_at: resolved_at ? null : new Date().toISOString() });
    if (success) {
      await dashboardStore.refreshDashboardActions(dashboardID);
    }
  };

  const createTask = async (name: string): Promise<void> => {
    getMixpanel(store!).track("Collaborate > Actions > Create Task", { "Dashboard ID": dashboardID, "Action ID": id, "Task Name": name });
    const success: any = await dashboardStore.createDashboardActionTask(dashboardID, id, name);
    if (success) {
      await dashboardStore.refreshDashboardActions(dashboardID);
      setShowTasks(true);
      setShowAddTaskInput(false);
    }
    // TODO: show error messages to handle endpoint call failure
  };

  const updateTask = async (taskID: number, name: string): Promise<void> => {
    getMixpanel(store!).track("Collaborate > Actions > Update Task", { "Dashboard ID": dashboardID, "Action ID": id, "Task ID": taskID });
    const success: any = await dashboardStore.updateDashboardActionTask(dashboardID, id, taskID, { name });
    if (success) {
      await dashboardStore.refreshDashboardActions(dashboardID);
    }
    // TODO: show error messages to handle endpoint call failure
  };

  const deleteTask = async (taskID: number): Promise<void> => {
    getMixpanel(store!).track("Collaborate > Actions > Delete Task", { "Dashboard ID": dashboardID, "Action ID": id, "Task ID": taskID });
    const success: any = await dashboardStore.deleteDashboardActionTask(dashboardID, id, taskID);
    if (success) {
      await dashboardStore.refreshDashboardActions(dashboardID);
    }
    // TODO: show error messages to handle endpoint call failure
  };

  const checkTask = async (taskID: number, value: boolean): Promise<void> => {
    getMixpanel(store!).track("Collaborate > Actions > Check Task", { "Dashboard ID": dashboardID, "Action ID": id, "Task ID": taskID, "Checked": value ? "Yes" : "No" });
    const success: any = await dashboardStore.updateDashboardActionTask(dashboardID, id, taskID, { resolved: value });
    if (success) {
      await dashboardStore.refreshDashboardActions(dashboardID);
    }
    // TODO: show error messages to handle endpoint call failure
  };

  const addContributors = async (selections): Promise<void> => {
    const addContributorIDs: number[] = selections.filter(selection => selection.action === "add").map(selection => selection.id);
    const removeContributorIDs: number[] = selections.filter(selection => selection.action === "remove").map(selection => selection.contributorID);
    let addSuccess = true;
    let removeSuccess = true;
    if (addContributorIDs.length > 0) {
      getMixpanel(store!).track("Collaborate > Actions > Add Contributors", { "Dashboard ID": dashboardID, "Action ID": id, "Contributor IDs": addContributorIDs });
      addSuccess = await dashboardStore.createDashboardActionContributors(dashboardID, id, addContributorIDs);
    }
    if (removeContributorIDs.length > 0) {
      getMixpanel(store!).track("Collaborate > Actions > Remove Contributors", { "Dashboard ID": dashboardID, "Action ID": id, "Contributor IDs": removeContributorIDs });
      removeSuccess = await dashboardStore.removeDashboardActionContributors(dashboardID, id, removeContributorIDs);
    }
    if (addSuccess && removeSuccess) {
      await dashboardStore.refreshDashboardActions(dashboardID);
      setShowEditContributorsModal(false);
    }
    // TODO: show error messages to handle endpoint call failure
  };

  const actionClickHandler = (): void => {
    dashboardStore.setDashboardSelection(dashboard_reference);
    if (dashboard_reference) {
      const element = document.getElementById((dashboard_reference?.content || dashboard_reference?.column || dashboard_reference?.row) as string);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
    if (resolved_at) {
      setFoldCard(false);
    }
  };

  const actinBlurHandler = (): void => {
    dashboardStore.setDashboardSelection(null);
    if (resolved_at) {
      setFoldCard(true);
    }
  };

  const actionDropdownOptions = [
    { text: "Edit", icon: "edit", onClick: () => setShowEditAction(true) },
    { text: "Delete", icon: "trash alternate", onClick: () => setShowDeleteModal(true) },
  ];

  return (
    <>
      {showEditAction ? (
        <AddAndEditAction
          userName={`${first_name} ${last_name}`}
          avatar={avatar || ""}
          action={action}
          onSave={(data) => updateAction(data)}
          onCancel={() => setShowEditAction(false)}
          enablePastDates={!!resolved_at}
        />
      ) : (
        <ActionCard
          tabIndex={0}
          onClick={actionClickHandler}
          onBlur={actinBlurHandler}
          id={`dashboard_action_${id}`}
          showleftborder={resolved_at && !foldCard}
          onMouseEnter={() => setShowActionButtons(true)}
          onMouseLeave={() => setShowActionButtons(false)}
        >
          <EditContributorsModal
            isOpen={showEditContributorsModal}
            contributors={contributorsSelections}
            name={title}
            users={collaborators?.filter(user => user.id !== user_id)}
            onSave={(selections) => addContributors(selections)}
            onClose={() => setShowEditContributorsModal(false)}
          />
          <StyleConfirm
            open={showDeleteModal}
            content={(
              <h3 className="text-primary m-4">Are you sure you want to delete this action?</h3>
            )}
            confirmButton="Delete Action"
            onCancel={() => setShowDeleteModal(false)}
            onConfirm={deleteActionHandler}
          />
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="d-flex align-items-center">
              <div className="d-flex mr-2">
                <AvatarGroup
                  items={avatarUsers as AvatarItemProps[]}
                  size={25}
                />
                </div>
              {!resolved_at && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowEditContributorsModal(true);
                  }}
                  iconColor={COLORS.grey600}
                  icon="plus"
                />
              )}
            </div>
            {showActionButtons && (
              <div className="d-flex align-items-center">
                <StylePopup
                  trigger={(
                    <IconButton onClick={!resolved_at ? (e) => resolveAction(e) : undefined} iconColor={resolved_at ? COLORS.aqua400 : COLORS.grey600} icon="check" />
                  )}
                  disabled={resolved_at}
                  content="Mark as completed"
                  position="top center"
                  size="mini"
                  inverted
                />
                <CollaborateDropdown options={actionDropdownOptions} direction="left" />
              </div>
            )}
          </div>
          <p className="fs-1000 fw-700 mb-3" style={{ color: COLORS.grey900 }}>{title}</p>
          <div className="mb-3">
            <div className="d-flex justify-content-between mb-1">
              {resolved_at ? (
                  <p className="fs-1000" style={{ color: COLORS.grey800 }}>{`${formatDate(created_at, "ccc MMM dd yyyy")}${due_date ? ` - ${formatDate(resolved_at, "ccc MMM dd yyyy")}` : ""}`}</p>
              ) : (
                <>
                  <div>
                    <span className="fs-1000 fw-700" style={{ color: COLORS.grey800 }}>Added: </span><span className="fs-1000" style={{ color: COLORS.grey800 }}>{formatDate(created_at, "ccc MMM dd yyyy")}</span>
                  </div>
                  {due_date && (
                    <div>
                      <span className="fs-1000 fw-700" style={{ color: COLORS.grey800 }}>Review: </span><span className="fs-1000" style={{ color: COLORS.grey800 }}>{formatDate(due_date, "ccc MMM dd yyyy")}</span>
                    </div>
                  )}
                </>
              )}
            </div>
            {due_date && !resolved_at && (
              <StyledProgress size="small" foreground={progressForeground} background={progressBackground} percent={progressPercent} />
            )}
          </div>
          {description && (
            <div className="mb-3">
              <p className="fs-1000 fw-700 text-secondary" style={{ color: COLORS.indigo600 }}>Description</p>
              <p className="fs-1000" style={{ color: COLORS.grey900 }}>{description}</p>
            </div>
          )}
          {!foldCard && (
            <>
              {outcome && (
                <div className="mb-3">
                  <p className="fs-1000 fw-700 text-secondary" style={{ color: COLORS.indigo600 }}>Target Outcome</p>
                  <p className="fs-1000" style={{ color: COLORS.grey900 }}>{outcome}</p>
                </div>
              )}
              {action_tasks.length > 0 && (
                <>
                  <div className="d-flex align-items-center">
                    <div className="bg-grey mr-2" style={{ height: 1, width: 30 }}></div>
                    <TextButton
                      color={COLORS.grey600}
                      hoverColor={COLORS.grey800}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowTasks(!showTasks);
                      }}
                    >
                      {`${showTasks ? "Hide" : "Show"} ${action_tasks.length} tasks`}
                    </TextButton>
                    <div className="bg-grey ml-2 flex-grow-1" style={{ height: 1, width: 30 }}></div>
                  </div>
                  {showTasks && (
                    <div className="mt-3">
                      {action_tasks.map((task, _idx) => {
                        const { id: taskID, name, resolved: taskResolved } = task;
                        return (
                          <ActionTaskInput
                            key={`${taskID}-${task.name}`}
                            disabled={!!resolved_at}
                            resolved={taskResolved}
                            value={name}
                            onSave={(value) => updateTask(taskID!, value as string)}
                            onDelete={() => deleteTask(taskID!)}
                            onCheck={(value) => checkTask(taskID!, value as boolean)}
                          />
                        );
                      })}
                    </div>
                  )}
                </>
              )}
              {((action_tasks.length === 0) || (action_tasks.length > 0 && showTasks)) && !showAddTaskInput && !resolved_at && (
                <Icon name="plus square outline" className="cursor-pointer d-block mb-3" style={{ marginLeft: -2 }} onClick={() => setShowAddTaskInput(!showAddTaskInput)} />
              )}
              {showAddTaskInput && (
                <ActionTaskInput
                  editMode
                  onCancel={() => setShowAddTaskInput(false)}
                  onSave={(value) => createTask(value)}
                />
              )}
            </>
          )}
        </ActionCard>
      )}
    </>
  );
};

export const Action = inject("store")(observer(Component));
