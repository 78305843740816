import * as React from "react";
import { observer } from "mobx-react";
import Store from "../../common/store";
import { database, getMixpanel } from "../../common/api";
import CropImage from "./CropImage";
import { Button, Icon } from "semantic-ui-react";

type UploadImage = (dataUrl: string) => Promise<void>;

@observer
export default class EditPicture extends React.Component<{ store: Store; type: string }> {
  store = this.props.store;
  type = this.props.type;
  user = this.store.user!;
  state = {
    src: null,
    showCropModal: false,
  };

  onSelectFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({ src: reader.result });
        this.setState({ showCropModal: true });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
    // Need to clear e.target.value to make onChange works (Chrome issue)
    e.target.value = "";
  };

  uploadImage: UploadImage = async (dataUrl?) => {
    this.setState({ showCropModal: false });
    const update: any = {};
    update[this.type === "groupBanner" ? "banner" : this.type] = dataUrl ? dataUrl : "";
    if (this.type === "avatar" || this.type === "banner") {
      const res: any = await database.put(`users/${this.store.user!.id}`, update, this.store.token!);
      if (this.type === "avatar") {
        getMixpanel(this.store).track("Upload Photo", { Page: "Settings > Account" });
      } else {
        getMixpanel(this.store).track("Upload Organisation Logo", { Page: "Settings > Organisation > Details" });
      }
      Object.assign(this.store.user || {}, res.body.data.user);
    } else {
      const res: any = await database.put(`groups/${this.store.user!.group.id}`, update, this.store.token!);
      Object.assign(this.store.user!.group, res.body.data.group);
    }
  };

  closeCropModal = (): void => {
    this.setState({ showCropModal: false });
  };

  render(): JSX.Element {
    const { src, showCropModal } = this.state;

    return (
      <>
        <Button className="mt-3" onClick={() => document.getElementById("avatarUpload")!.click()}>
          {this.type === "avatar" ? "Upload photo" : "Upload logo"}
          <Icon name={this.type === "avatar" ? "user" : "image"} className="ml-2" />
        </Button>
        <input
          id="avatarUpload"
          type="file"
          title="something"
          accept=".png, .jpeg, .jpg"
          style={{ marginBottom: "20px", display: "none" }}
          onChange={this.onSelectFile}
        />
        <CropImage src={src} vis={showCropModal} handleVis={this.closeCropModal} handleUpload={this.uploadImage} />
      </>
    );
  }
}
