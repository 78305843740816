import * as React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";

import { Heading, HR } from "common/styledComponents/elements";
import { Builder } from "common/store/builder";
import { MultiIndexTable } from "./table/MultiIndexTable";
import { ExploreMultiIndexChartResult } from "./Explore/ExploreMultiIndexChartResult";
import { Button, ButtonGroup, Icon } from "semantic-ui-react";

type InsightContentProps = RouteComponentProps<any> & {
  builder?: Builder;
  readonly?: boolean;
  forceChart?: boolean;
};

export const NewInsightContent = withRouter(
  inject((stores: any) => ({ builder: stores.store.builder }))(
    observer((props: InsightContentProps) => {
      const builder = props.builder!;

      const [contentToggle, setContentToggle] = React.useState("table");
      const insightCode = undefined; // TODO: Support insight embed codes

      const editInsight = () => {
        props.history.push(`/builder/updateInsight/${builder.parent.insight.result.id}`);
      };

      return (
        <>
          <HR />

          {insightCode ? (
            // IFrame Insight
            <>
              <Heading>Insight</Heading>
              <div id="resExport" className="p-4 mt-5 bg-white rounded shadow">
                <div className="w-100" dangerouslySetInnerHTML={{ __html: insightCode }} style={{ height: "600px" }} />
              </div>
            </>
          ) : (
            // Table & Chart Insight
            <>
              <div className="d-flex flex-column-reverse flex-sm-row justify-content-sm-between">
                <Heading className="mt-4 mt-sm-0">Insight</Heading>
                <div className="d-flex flex-column flex-sm-row">
                  {builder.tables.length > 0 && (
                    <ButtonGroup basic className="mb-3 mb-sm-0 mr-sm-3">
                      <Button active={contentToggle === "table"} onClick={() => setContentToggle("table")}>
                        Table view <Icon name="table" className="ml-1 mr-0" />
                      </Button>
                      <Button
                        id="userflow-element-insight-chart-view"
                        active={contentToggle === "chart"}
                        onClick={() => setContentToggle("chart")}
                      >
                        Chart view <Icon name="chart bar" className="ml-1 mr-0" />
                      </Button>
                    </ButtonGroup>
                  )}

                  {!props.readonly && (
                    <Button color="purple" onClick={editInsight}>
                      Edit insight <Icon name="edit" className="ml-1 mr-0" />
                    </Button>
                  )}
                </div>
              </div>

              <div id="resExport" className="p-2 mt-4 bg-white rounded shadow">
                {contentToggle === "table" && !props.forceChart ? (
                  // Table View
                  <MultiIndexTable editMode={false} />
                ) : (
                  // Chart View
                  <ExploreMultiIndexChartResult
                    insight={true}
                    editMode={false}
                    isAnimationDisabled={props.forceChart}
                    forceWidthToHeightRatioFallback // this is desired on insight page
                  />
                )}
              </div>
            </>
          )}
        </>
      );
    }),
  ),
);
