import styled from "styled-components";
import { Form, TextArea } from "semantic-ui-react";
import { COLORS } from "component/UI/common";

export const CForm = styled(Form)`
  &.ui.form textarea:focus {
    border-color: ${COLORS.indigo600};
  }
`;

export const CTextArea = styled(TextArea)`
  min-height: 65px;
  font-size: 18px !important;
  font-weight: 600;
  color: #2d2d2d !important;
  padding: 8px 20px 8px 10px !important;
`;

export const CDecimal = styled.button<{ selected: boolean; small?: boolean }>`
  width: 50%;
  height: 45px;
  display: inline-block;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px 0px 0px 4px;
  color: ${(p) => (p.selected ? COLORS.red500 : "#919191")};
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  ${(p) => p.small && "font-size: 13px;"}
`;

export const CPercent = styled(CDecimal)`
  border-radius: 0px 4px 4px 0px;
  margin-left: -1px;
`;

export const CButton = styled.button`
  width: 100%;
  height: 45px;
  background: #f5f5f5;
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
  color: #5f5f5f;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
`;

export const CButtonDark = styled(CButton)`
  background: #e0e1e2;
`;
