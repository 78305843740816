// dashboard page common helpers

export const getPageTitles = unlayer_config => {
  if (!unlayer_config) {
    return [];
  } else if (unlayer_config.navigation?.titles) {
    return unlayer_config.navigation.titles;
  }
  return unlayer_config.pages.map((_data, idx) => `Page ${idx + 1}`);
};

export type Spacing = number | { top: number; left: number; bottom: number; right: number };

export interface ItemsStyle {
  useBorder?: boolean;
  borderWidth?: number; // px width
  borderColor?: string; // hex code
  borderRadius?: number; // px width only, no percentages for now
  color?: string; // hex code
  background?: string; // hex code
}

export interface DashboardNavigationConfig {
  titles: string[];
  includedPages: boolean[];
  customNav: boolean;
  customNavConf: {
    showInbuiltControls: boolean;
    position: "relative" | "sticky" | "custom";
    styling: {
      layout: "horizontal" | "vertical";
      alignment: "left" | "center" | "right" | "flex between";
      padding: Spacing;
      background?: string; // hex code
      images: {
        default: string; // src
        hover?: string; // src - active/hover
      }[];
      imageWidth?: number; // px
      imageHeight?: number; // px
      imageWidthPercent?: number; // %
      imageWidthMax?: number; // px
      items: {
        padding: Spacing;
        margin: Spacing;
        font: {
          value: string;
          url: string;
          fontSize: number; // px
          fontWeight: number;
        };
        default: ItemsStyle;
        hover: ItemsStyle; // hover / active state
      };
    };
  };
}

export interface UnlayerConfig {
  pages: {
    json: any;
    html: string;
    id?: number;
  }[];
  navigation?: DashboardNavigationConfig;
}

