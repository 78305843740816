import * as React from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { composeValidators, mustBeNumber, required } from "common/helpers/finalForm";
import { Button, Form, Header, Icon } from "semantic-ui-react";
import { FieldInput } from "component/FinalFormFields/FieldInput";

interface Props {
  onSubmit: (values) => void;
}

export const DatasetRefresh = (props: Props): JSX.Element => {
  const { onSubmit } = props;
  return (
    <FinalForm
      onSubmit={onSubmit}
      render={(formRenderProps) => {
        const { handleSubmit } = formRenderProps;
        return (
          <Form onSubmit={handleSubmit}>
            <Header as="h3" color="purple" className="mb-5">
              Trigger Dataset refresh
            </Header>
            <Field
              name="dataset_id"
              label="Dataset ID"
              component={FieldInput}
              validate={composeValidators(required, mustBeNumber)}
              className="mb-5"
            />
            <Button color="purple">
              Save
              <Icon name="save" className="ml-1 mr-0" />
            </Button>
          </Form>
        );
      }}
    />
  );
};
