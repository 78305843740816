// @TODO memoize the component if it improves performance (will have to customize compare function to not be shallow)
import * as React from "react";
import { Button, Table, Icon, Label } from "semantic-ui-react";

import { Schema, getVariableCombinations, transformDataTo2DArray } from "common/helpers/dataset";
import { Collapsible } from "component/Collapsible";
import { Accordion } from "component/Accordion";
import { ObjectAny } from "common/helpers/types";
import { getAggregateSchema, getAggregateData } from "pages/DatasetTemplateTable/includes/helpers";
import {
  ColumnVariableHeadings,
  RowVariableHeadings,
  RowCategories,
  compileTableRenderProperties,
} from "pages/DatasetTemplateTable/includes/TableSchemaPreview";

interface Props {
  parentSchema: Schema;
  parentData: ObjectAny[];
}

export const AggregateDataPreviews = (props: Props): JSX.Element => {
  const { parentSchema, parentData } = props;
  const { aggregations } = parentSchema;
  return (
    <div>
      {!!aggregations?.length && (
        <div className="py-5">
          <h3>Aggregation data previews</h3>
          <p className="text-muted fs-1000">
            Note, these reflect the saved state of the data and are only updated after data is saved, meaning you won't see updates till you
            save changes.
          </p>
          <Collapsible
            render={({ isOpen, setIsOpen }) => (
              <div>
                <Button onClick={() => setIsOpen(!isOpen)}>
                  {isOpen ? "Hide" : "Show"} Previews <Icon className="ml-2" name={isOpen ? "eye slash" : "eye"} />
                </Button>
                {isOpen && (
                  <Accordion
                    className="my-3"
                    styled
                    fluid
                    items={aggregations.map((aggregation, idx) => {
                      // compile the aggregate schema and data
                      const aggSchema = getAggregateSchema(parentSchema, aggregation);
                      const aggData = aggSchema ? getAggregateData(parentSchema, parentData, aggregation) : null;
                      let content = <p className="text-danger">Invalid aggregation.</p>;
                      // if valid schema/data - set the content table
                      if (aggData && aggSchema) {
                        const { rows, columns } = aggSchema;
                        const { skipColumns, categoryColumns, categoryRows } = compileTableRenderProperties(aggSchema);
                        const rowCombos = getVariableCombinations(rows);
                        const colCombos = getVariableCombinations(columns);
                        const aggData2D = transformDataTo2DArray(aggData, rowCombos, colCombos);
                        content = (
                          <Table size="small" compact celled>
                            <Table.Body>
                              {/* Column Variables with skipped cells and Categories */}
                              <ColumnVariableHeadings columns={columns} skipColumns={skipColumns} categoryColumns={categoryColumns} />
                              {/* Row Variables with empty cells */}
                              <RowVariableHeadings rows={rows} categoryColumns={categoryColumns} />
                              {/* Sheet Rows including Row Categories including data cells and skipped first column if no rows set */}
                              {categoryRows.map((_, idx) => (
                                <Table.Row key={`tbody.tr.${idx}`}>
                                  <RowCategories idx={idx} rows={rows} />
                                  {/* Data cells */}
                                  {aggData2D[idx]?.map((value, cIdx) => (
                                    <Table.Cell key={`tbody.tr.td.data.${idx}.${cIdx}`}>{value}</Table.Cell>
                                  ))}
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                        );
                      }
                      return {
                        key: `${aggregation.variable}-${aggregation.aggVariable}-${aggregation.type}-${idx}`,
                        title: (
                          <>
                            {aggregation.variable}{" "}
                            <Label className="ml-2" basic size="small">
                              {aggregation.type.toUpperCase()}
                            </Label>
                          </>
                        ),
                        content,
                      };
                    })}
                  />
                )}
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
};
