import * as React from "react";
import { Confirm } from "semantic-ui-react";

interface IActionConfirm {
  open: boolean;
  paletteName: string;
  setConfirmOpen: (open: boolean) => void;
  confirmAction: () => void;
}

const ActionConfirmComponent = ({ open, paletteName, setConfirmOpen, confirmAction }: IActionConfirm): JSX.Element => {
  const handleConfirm = () => {
    setConfirmOpen(false);
    confirmAction();
  };

  return (
    <Confirm
      size="small"
      open={open}
      header="Are you sure you want to delete this color palette?"
      content={paletteName}
      onCancel={() => setConfirmOpen(false)}
      onConfirm={handleConfirm}
    />
  );
};

export const ActionConfirm = ActionConfirmComponent;
