import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Button, Header, Icon, Message, Modal } from "semantic-ui-react";
import { datasets as dataApi, getMixpanel } from "common/api";
import Store from "common/store";
import { DatasetAccessModal } from "./DatasetAccessModal";
import { DatasetAccessTable } from "./DatasetAccessTable";

interface IDatasetAccess {
  isOpen: boolean;
  datasetID: number;
  datasetName: string;
  closeModalHandler: () => void;
  datasetStore?: any;
  store?: Store;
}

const DatasetAccessComponent = ({
  isOpen,
  datasetID,
  datasetName,
  closeModalHandler,
  datasetStore,
  store,
}: IDatasetAccess): JSX.Element => {
  const [datasetAccess, setDatasetAccess] = React.useState<any>();
  const [datasetKey, setDatasetKey] = React.useState("");
  const [addGroupModalShow, setAddGroupModalShow] = React.useState<boolean>(false);
  const [updateGroupModalShow, setUpdateGroupModalShow] = React.useState<boolean>(false);
  const [updateGroupDatasetID, setUpdateGroupDatasetID] = React.useState(null);
  const [initialGroupAccessLimit, setInitialGroupAccessLimit] = React.useState<any>(null);
  const [addUserModalShow, setAddUserModalShow] = React.useState<boolean>(false);
  const [updateUserModalShow, setUpdateUserModalShow] = React.useState<boolean>(false);
  const [updateUserDatasetID, setUpdateUserDatasetID] = React.useState(null);
  const [initialUserAccessLimit, setInitialUserAccessLimit] = React.useState<any>(null);
  const [updateEntityName, setUpdateEntityName] = React.useState("");

  const organisationOptions = store!.group.allOrganisations.map((org) => ({
    id: org.id,
    label: org.name,
    value: org.name,
    isDisabled: datasetAccess?.groups?.find((group) => group.id === org.id), // Disable the group option if it's already exists in "datasetAccess.groups"
  }));
  // Generate all user options grouped by organisations
  const userOptions = store!.group.allOrganisations.map((org) => ({
    label: org.name,
    options: org.users.map((user) => ({
      id: user.id,
      label: `${user.first_name} ${user.last_name}`,
      value: user.id,
      isDisabled: datasetAccess?.users?.find((u) => u.id === user.id), // Disable the user option if it's already exists in "datasetAccess.users",
    })),
  }));

  const getDatasetAccess = async () => {
    const res: any = await datasetStore.getDatasetAccess(datasetID);
    if (res) {
      setDatasetAccess(res);
    }
  };

  // "dataset key" is used when sending request to `/qs` to only get specified dataset's variables and categories
  const getDatasetKey = async () => {
    const res: any = await await dataApi.post("v2/search", "", store!.token!);
    if (res.body) {
      const allDatasets = res.body.data.datasets;
      const currentDatasetKey = allDatasets.find((dataset) => dataset.id === datasetID)?.key;
      setDatasetKey(currentDatasetKey);
    }
  };

  const addDatasetGroupAccess = async (accessLimit, groupID, groupName): Promise<boolean> => {
    const success = await datasetStore.addDatasetGroupAccess(
      datasetID,
      groupID,
      accessLimit && Object.keys(accessLimit).length > 0 ? accessLimit : null,
    );
    if (success) {
      getMixpanel(store!).track("Add Dataset Group Access", {
        "Dataset Id": datasetID,
        "Dataset Name": datasetName,
        "Group Id": groupID,
        "Group Name": groupName,
        Access: accessLimit && Object.keys(accessLimit).length > 0 ? "Limited" : "Full",
      });
      getDatasetAccess();
    }
    return success;
  };

  const updateDatasetGroupAccess = async (accessLimit): Promise<boolean> => {
    const success = await datasetStore.updateDatasetGroupAccess(
      updateGroupDatasetID,
      datasetID,
      accessLimit && Object.keys(accessLimit).length > 0 ? accessLimit : null,
    );
    if (success) {
      getMixpanel(store!).track("Update Dataset Group Access", {
        "Dataset Id": datasetID,
        "Dataset Name": datasetName,
        "Group Id": updateGroupDatasetID,
        "Group Name": updateEntityName,
        Access: accessLimit && Object.keys(accessLimit).length > 0 ? "Limited" : "Full",
      });
      getDatasetAccess();
    }
    return success;
  };

  const deleteDatasetGroupAccess = async (): Promise<boolean> => {
    const success = await datasetStore.deleteDatasetGroupAccess(updateGroupDatasetID, datasetID);
    if (success) {
      getMixpanel(store!).track("Remove Dataset Group Access", {
        "Dataset Id": datasetID,
        "Dataset Name": datasetName,
        "Group Id": updateGroupDatasetID,
        "Group Name": updateEntityName,
      });
      getDatasetAccess();
    }
    return success;
  };

  const addDatasetUserAccess = async (accessLimit, userID, userName): Promise<boolean> => {
    const success = await datasetStore.addDatasetUserAccess(
      datasetID,
      userID,
      accessLimit && Object.keys(accessLimit).length > 0 ? accessLimit : null,
    );
    if (success) {
      getMixpanel(store!).track("Add Dataset User Access", {
        "Dataset Id": datasetID,
        "Dataset Name": datasetName,
        "User Id": userID,
        "User Name": userName,
        Access: accessLimit && Object.keys(accessLimit).length > 0 ? "Limited" : "Full",
      });
      getDatasetAccess();
    }
    return success;
  };

  const updateDatasetUserAccess = async (accessLimit): Promise<boolean> => {
    const success = await datasetStore.updateDatasetUserAccess(
      updateUserDatasetID,
      datasetID,
      accessLimit && Object.keys(accessLimit).length > 0 ? accessLimit : null,
    );
    if (success) {
      getMixpanel(store!).track("Update Dataset User Access", {
        "Dataset Id": datasetID,
        "Dataset Name": datasetName,
        "User Id": updateUserDatasetID,
        "User Name": updateEntityName,
        Access: accessLimit && Object.keys(accessLimit).length > 0 ? "Limited" : "Full",
      });
      getDatasetAccess();
    }
    return success;
  };

  const deleteDatasetUserAccess = async (): Promise<boolean> => {
    const success = await datasetStore.deleteDatasetUserAccess(updateUserDatasetID, datasetID);
    if (success) {
      getMixpanel(store!).track("Remove Dataset User Access", {
        "Dataset Id": datasetID,
        "Dataset Name": datasetName,
        "User Id": updateUserDatasetID,
        "User Name": updateEntityName,
      });
      getDatasetAccess();
    }
    return success;
  };

  React.useEffect(() => {
    getDatasetAccess();
    getDatasetKey();
  }, [datasetID]);

  // Reset "initialGroupAccessLimit"
  React.useEffect(() => {
    if (updateGroupDatasetID) {
      const newGroupAccessLimit = datasetAccess?.groups?.find((group) => group.dataset_group_id === updateGroupDatasetID)?.access_limit;
      setInitialGroupAccessLimit(newGroupAccessLimit);
    } else {
      setInitialGroupAccessLimit(null);
    }
  }, [updateGroupModalShow, updateGroupDatasetID]);

  // Reset "initialUserAccessLimit"
  React.useEffect(() => {
    if (updateUserDatasetID) {
      const newUserAccessLimit = datasetAccess?.users?.find((user) => user.dataset_user_id === updateUserDatasetID)?.access_limit;
      setInitialUserAccessLimit(newUserAccessLimit);
    } else {
      setInitialUserAccessLimit(null);
    }
  }, [updateUserModalShow, updateUserDatasetID]);

  return (
    <>
      <Modal closeIcon size="large" open={isOpen} onClose={closeModalHandler}>
        <Modal.Header>Dataset Access</Modal.Header>
        <Modal.Content>
          <Message compact size="small" color="blue">
            <Icon name="info circle" />
            Provide dataset access to organisations/users with optional access limits
          </Message>
          {/* Dataset Groups Access */}
          <div className="d-flex align-items-center justify-content-between my-4">
            <Header as="h4" color="purple" className="mb-0">
              Organisations
            </Header>
            <Button size="tiny" color="red" onClick={() => setAddGroupModalShow(true)}>
              Add Organisation
              <Icon className="plus ml-1 mr-0" />
            </Button>
          </div>
          {datasetAccess?.groups.length === 0 ? (
            <p className="fs-1125 fw-700 text-medium">No organisations have access to this dataset.</p>
          ) : (
            <DatasetAccessTable
              headerItems={["Logo", "Name", "Access Limit", "Actions"]}
              datasetAccess={datasetAccess?.groups}
              editClickHandler={(name, id) => {
                setUpdateEntityName(name);
                setUpdateGroupDatasetID(id);
                setUpdateGroupModalShow(true);
              }}
            />
          )}
          {/* Dataset Members Access */}
          <div className="d-flex align-items-center justify-content-between mb-4 mt-5">
            <Header as="h4" color="purple" className="text-secondary mb-0">
              Members
            </Header>
            <Button size="tiny" color="red" onClick={() => setAddUserModalShow(true)}>
              Add Member
              <Icon className="plus ml-1 mr-0" />
            </Button>
          </div>
          {datasetAccess?.users.length === 0 ? (
            <p className="fs-1125 fw-700 text-medium">No members have access to this dataset.</p>
          ) : (
            <DatasetAccessTable
              headerItems={["Name", "Email", "Organisation", "Access Limit", "Actions"]}
              datasetAccess={datasetAccess?.users}
              editClickHandler={(name, id) => {
                setUpdateEntityName(name);
                setUpdateUserDatasetID(id);
                setUpdateUserModalShow(true);
              }}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModalHandler}>Close</Button>
        </Modal.Actions>
      </Modal>
      {/* Add dataset group access */}
      <DatasetAccessModal
        isOpen={addGroupModalShow}
        entityType="Organisation"
        datasetKey={datasetKey}
        entityOptions={organisationOptions}
        closeClickHandler={() => setAddGroupModalShow(false)}
        saveClickHandler={addDatasetGroupAccess}
      />
      {/* Update dataset group access */}
      <DatasetAccessModal
        isOpen={updateGroupModalShow}
        entityType="Organisation"
        datasetKey={datasetKey}
        updateEntityName={updateEntityName}
        initialAccessLimit={initialGroupAccessLimit}
        closeClickHandler={() => {
          setUpdateEntityName("");
          setUpdateGroupDatasetID(null);
          setUpdateGroupModalShow(false);
        }}
        saveClickHandler={updateDatasetGroupAccess}
        deleteClickHandler={deleteDatasetGroupAccess}
      />
      {/* Add dataset user access */}
      <DatasetAccessModal
        isOpen={addUserModalShow}
        entityType="User"
        datasetKey={datasetKey}
        entityOptions={userOptions}
        closeClickHandler={() => setAddUserModalShow(false)}
        saveClickHandler={addDatasetUserAccess}
      />
      {/* Update dataset user access */}
      <DatasetAccessModal
        isOpen={updateUserModalShow}
        entityType="User"
        datasetKey={datasetKey}
        updateEntityName={updateEntityName}
        initialAccessLimit={initialUserAccessLimit}
        closeClickHandler={() => {
          setUpdateEntityName("");
          setUpdateUserDatasetID(null);
          setUpdateUserModalShow(false);
        }}
        saveClickHandler={updateDatasetUserAccess}
        deleteClickHandler={deleteDatasetUserAccess}
      />
    </>
  );
};

export const DatasetAccess = inject((stores: any) => ({
  store: stores.store,
  datasetStore: stores.store.dataset,
}))(observer(DatasetAccessComponent));
