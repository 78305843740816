import { Builder } from "common/store/builder";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Dropdown, DropdownProps } from "semantic-ui-react";

interface TableActionProps extends DropdownProps {
  tableId: number;
  deleteHandler: () => void;
  builderStore?: Builder;
  calculation?: boolean;
}

export const TableActionsDropdown = inject((stores: any) => ({ builderStore: stores.store.builder }))(
  observer(({ tableId, deleteHandler, builderStore, calculation, disabled }: TableActionProps) => (
    <Dropdown text="" disabled={builderStore!.calc.open || builderStore!.robot.open || disabled}>
      <Dropdown.Menu>
        <Dropdown.Header>{calculation ? "Calculation" : "Table"} Actions</Dropdown.Header>
        {!calculation && <Dropdown.Item text="Edit Query" icon="edit outline" onClick={() => builderStore!.setActiveTableId(tableId)} />}
        {calculation && (
          <Dropdown.Item text="Edit Calculation" icon="edit outline" onClick={() => builderStore!.editCalculation(tableId)} />
        )}
        {!calculation && <Dropdown.Item text="Duplicate" icon="copy outline" onClick={() => builderStore!.duplicateTable(tableId)} />}
        <Dropdown.Item text="Delete" icon="trash alternate outline" onClick={deleteHandler} />
      </Dropdown.Menu>
    </Dropdown>
  )),
);
