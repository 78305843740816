import React = require("react");
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Button, Icon, Input } from "semantic-ui-react";

import Store from "common/store";
import Member from "./includes/Member";
import PendingInvitations from "./includes/PendingInvites";
import { getMixpanel } from "common/api";

interface MembersProps {
  store?: Store;
}

export const Members = inject("store")(
  observer((props: MembersProps) => {
    const store = props.store!;
    const { breakpoint } = store!.ui;
    const isMobileScreen = breakpoint === "mobile";
    const [email, setEmail] = React.useState("");
    const [error, setError] = React.useState("");

    React.useEffect(() => {
      getMixpanel(store).track("Page view", { Page: "Settings > Organisation > Members" });
    }, []);

    return (
      <>
        <div className="d-flex mb-4">
          <Input
            aria-label="Invite email"
            fluid
            className="flex-grow-1 mr-2"
            id="invite-code"
            placeholder="E.g user@company.com"
            type="email"
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value || "");
              setError("");
            }}
          />
          <Button
            color="purple"
            onClick={() =>
              store.group.sendEmailInvite(email).then((error) => {
                if (error) {
                  setError(error);
                } else {
                  setEmail("");
                  getMixpanel(store).track("Invite Member", { Page: "Settings > Organisation > Members" });
                }
              })
            }
            disabled={!email}
          >
            {isMobileScreen ? "" : "Invite "}
            <Icon name="user plus" className="ml-sm-0 ml-1" />
          </Button>
        </div>
        {error && <p className="text-primary fw-700 fs-1000 mb-4">{error}</p>}
        <div>
          <PendingInvitations store={store} />
          <Member store={store} />
        </div>
      </>
    );
  }),
);

export default Members;
