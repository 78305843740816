import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Icon, Button, Accordion, SemanticICONS, Dropdown, Header, Confirm, Pagination } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import Store from "common/store";
import Protected from "component/protected";
import { sortByDate } from "common/helpers/dataset";
import { DatasetTable } from "./includes/DatasetTable";
import { StyledContainer, StyleTab } from "component/Suitcases/SuitcasesContent.style";
import { ShowLoaderGlobal } from "component/LoaderGlobal";
import { Breadcrumbs } from "component/Breadcrumbs/Breadcrumbs";
import { EmailMessagesModal } from "./includes/EmailMessagesModal";
import { TemplateContributorModal } from "./includes/TemplateContributorModal";
import { TableSchemaPreview } from "pages/DatasetTemplateTable/includes/TableSchemaPreview";
import { HeadingSection } from "./includes/HeadingSection";
import { TableMoveDuplicateModal } from "./includes/TableMoveDuplicateModal";
import { getMixpanel } from "common/api";
import { LatestDataPopup } from "./includes/LatestData";
import { EmptyStateSecondary, ListPageEmptyStateDescription } from "component/EmptyState/EmptyState";
import { Contributor, ListResponseData, IDataset, Template, TablesData } from "common/store/dataset";
import { ContentPlaceholder } from "component/ContentPlaceholder";

const PAGE_LIMIT = 10;

interface DatasetTemplateProps {
  store?: Store;
}

interface TableActionItem {
  text: string;
  icon: SemanticICONS;
  clickHandler: (e, id, name?) => void;
}

// loading container
const DatasetTemplatePageComponent = ({ store }: DatasetTemplateProps): JSX.Element => {
  const [dataset, setDataset] = React.useState<IDataset | null>(null);
  const [template, setTemplate] = React.useState<Template | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [reloadDatasetTemplateData, setReloadDatasetTemplateData] = React.useState(false);

  const { datasetID, templateID } = useParams<{ datasetID: string; templateID: string }>();

  const loadData = async () => {
    setLoading(true);
    const [datasetRes, templateRes] = await Promise.all([store!.dataset.getDataset(+datasetID), store!.dataset.getTemplate(+templateID)]);
    if (datasetRes.error || templateRes.error) {
      setError("This dataset/template doesn't exist on your account. Please check URL.");
    } else {
      setDataset(datasetRes.data as IDataset);
      setTemplate(templateRes.data as Template);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loadData();
    window.scrollTo(0, 0);
  }, [templateID]);

  React.useEffect(() => {
    if (reloadDatasetTemplateData) {
      loadData();
      setReloadDatasetTemplateData(false);
    }
  }, [reloadDatasetTemplateData]);

  if (error) {
    return (
      <Header as="h4" className="text-center">
        {error}
      </Header>
    );
  }

  return (
    <StyledContainer>
      <Helmet>
        <title>{`${template?.name} #${templateID} - ${dataset?.name} #${datasetID}`}</title>
      </Helmet>
      {loading ? (
        <ContentPlaceholder />
      ) : (
        <Content store={store!} dataset={dataset} template={template} setReloadDatasetTemplateData={setReloadDatasetTemplateData} />
      )}
    </StyledContainer>
  );
};

const Content = ({ store, dataset, template, setReloadDatasetTemplateData }) => {
  const [tablesData, setTablesData] = React.useState<ListResponseData<TablesData> | null>(null);
  const [activePage, setActivePage] = React.useState(1);
  const [reloadTableData, setReloadTableData] = React.useState(false);

  React.useEffect(() => {
    const loadTablesData = async () => {
      const skip = PAGE_LIMIT * (activePage - 1);
      const res = await store.dataset.listTables(+templateID, skip);
      if (res.error) {
        console.error(res.error);
      } else {
        setTablesData(res.data);
      }
    };
    loadTablesData();
  }, [activePage, reloadTableData]);

  const { datasetID, templateID } = useParams<{ datasetID: string; templateID: string }>();
  const history = useHistory();

  const { meta, data } = tablesData || {};
  const { tables } = data || {};
  const totalTables = meta?.total ?? 0;
  const totalPages = Math.ceil(totalTables / PAGE_LIMIT);
  const startNumber = (activePage - 1) * PAGE_LIMIT + 1;
  const endNumber = activePage * PAGE_LIMIT > totalTables ? totalTables : activePage * PAGE_LIMIT;

  const {
    name: datasetName,
    contributor_welcome_message: dataset_contributor_welcome_message,
    contributor_reminder_message: dataset_contributor_reminder_message,
    contributor_thank_you_message: dataset_contributor_thank_you_message,
    link,
  } = dataset || {};
  const { name, update_history, contributors, contributor_welcome_message, contributor_reminder_message, contributor_thank_you_message } =
    template || {};

  // reminder email manual send checkbox logic
  const [sendReminderLoading, setSendReminderLoading] = React.useState(false);
  const [showReminderCheckboxes, setShowReminderCheckboxes] = React.useState(false);
  const [showEmailMessagesModal, setShowEmailMessagesModal] = React.useState(false);
  const [showAddContributorModal, setShowAddContributorModal] = React.useState(false);
  const [showUpdateContributorModal, setShowUpdateContributorModal] = React.useState(false);
  const [showDeleteContributorConfirm, setShowDeleteContributorConfirm] = React.useState(false);
  const [showDuplicateToModal, setShowDuplicateToModal] = React.useState(false);
  const [showMoveToModal, setShowMoveToModal] = React.useState(false);
  const [checked, setChecked] = React.useState(contributors?.map(() => false));
  const [selectedContributorID, setSelectedContributorID] = React.useState(null);
  const [selectedContributorName, setSelectedContributorName] = React.useState(null);
  const [selectedContributorEmail, setSelectedContributorEmail] = React.useState(null);
  const [activeTableIndex, setActiveTableIndex] = React.useState(null);
  const [selectedTableID, setSelectedTableID] = React.useState(null);
  const [selectedTableName, setSelectedTableName] = React.useState("");
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = React.useState(false);
  const [showDeleteTableConfirmModal, setShowDeleteTableConfirmModal] = React.useState(false);
  const toggleReminderCheckboxes = () => {
    setShowReminderCheckboxes(!showReminderCheckboxes);
    setChecked(checked.map(() => false)); // reset on toggle
  };
  const toggleCheckbox = (idx) => {
    const next = [...checked];
    next[idx] = !next[idx];
    setChecked(next);
  };
  const sendReminders = async () => {
    setSendReminderLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setSendReminderLoading(false);
    toggleReminderCheckboxes();
    const ids = checked.map((yes, idx) => (yes ? contributors[idx].id : null)).filter((id) => !!id);
    const ok = await store.dataset.sendReminders(ids);
    getMixpanel(store!).track("Send Email Reminder Manually", {
      "Dataset Id": datasetID,
      "Dataset Name": datasetName,
      "Template Id": templateID,
      "Template Name": name,
      "Contributor Ids": ids,
    });
    alert(
      ok
        ? "Reminder emails sent successfully."
        : "Some reminder emails failed to send, please check over the reminder history after the page reloads.",
    );
    window.location.reload();
  };

  const saveEmailMessages = async (messages) => {
    const res: any = await store!.dataset.updateTemplate(templateID, {
      contributor_welcome_message: messages[0].content,
      contributor_reminder_message: messages[1].content,
      contributor_thank_you_message: messages[2].content,
    });
    if (res.success) {
      getMixpanel(store!).track("Configure Dataset Template Emails", {
        "Dataset Id": datasetID,
        "Dataset Name": datasetName,
        "Template Id": templateID,
        "Template Name": name,
      });
      setReloadDatasetTemplateData(true);
    }
  };

  const addTemplateContributor = async (contributor: Contributor): Promise<any> => {
    const res = await store!.dataset.addTemplateContributor(Number(templateID), contributor);
    getMixpanel(store).track("Add Template Contributor", {
      "Dataset Id": datasetID,
      "Dataset Name": datasetName,
      "Template Id": templateID,
      "Template Name": name,
    });
    if (res?.statusCode === 200) {
      setReloadDatasetTemplateData(true);
    }
    return res;
  };

  const updateTemplateContributor = async (contributor: Contributor): Promise<any> => {
    const res = await store!.dataset.updateTemplateContributor(contributor);
    getMixpanel(store).track("Update Template Contributor", {
      "Dataset Id": datasetID,
      "Dataset Name": datasetName,
      "Template Id": templateID,
      "Template Name": name,
      "Contributor Id": contributor.id,
      "Contributor Email": contributor.email,
    });
    if (res?.statusCode === 200) {
      setReloadDatasetTemplateData(true);
    }
    return res;
  };

  const updateClickHandler = (id) => {
    setSelectedContributorID(id);
    setShowUpdateContributorModal(true);
  };

  const deleteClickHandler = ({ id, name, email }) => {
    setShowDeleteContributorConfirm(true);
    setShowUpdateContributorModal(false);
    setSelectedContributorID(id);
    setSelectedContributorName(name);
    setSelectedContributorEmail(email);
  };

  const deleteContributor = async () => {
    const success = await store!.dataset.deleteTemplateContributor(selectedContributorID);
    getMixpanel(store).track("Remove Template Contributor", {
      "Dataset Id": datasetID,
      "Dataset Name": datasetName,
      "Template Id": templateID,
      "Template Name": name,
      "Contributor Id": selectedContributorID,
      "Contributor Email": selectedContributorEmail,
    });
    if (success) {
      setReloadDatasetTemplateData(true);
      setShowDeleteContributorConfirm(false);
      setShowUpdateContributorModal(false);
    }
  };

  const handleTableNameClick = (index) => {
    const newIndex = activeTableIndex === index ? -1 : index;
    setActiveTableIndex(newIndex);
  };

  const editTableClickHandler = (e, tableID) => {
    e.stopPropagation();
    history.push(`/datasets/${datasetID}/templates/${templateID}/tables/${tableID}`);
  };

  const duplicateToClickHandler = (e, clickedTableID, clickedTableName) => {
    e.stopPropagation();
    setSelectedTableID(clickedTableID);
    setSelectedTableName(clickedTableName);
    setShowDuplicateToModal(true);
  };

  const deleteTableClickHandler = (e, clickedTableID, clickedTableName) => {
    e.stopPropagation();
    setSelectedTableID(clickedTableID);
    setSelectedTableName(clickedTableName);
    setShowDeleteTableConfirmModal(true);
  };

  const deleteTable = async () => {
    const success = await store!.dataset.deleteDatasetTemplateTable(selectedTableID);
    if (success) {
      getMixpanel(store!).track("Delete Dataset Template Table", {
        "Dataset Id": datasetID,
        "Dataset Name": datasetName,
        "Template Id": templateID,
        "Template Name": name,
        "Table Id": selectedTableID,
        "Table Name": selectedTableName,
      });
      setReloadTableData((prev) => !prev);
      setShowDeleteTableConfirmModal(false);
    }
  };

  const duplicateTableTo = async (selectedTemplateID) => {
    const success = await store!.dataset.duplicateDatasetTemplateTable(selectedTableID, selectedTemplateID);
    setShowDuplicateToModal(false);
    if (success) {
      getMixpanel(store).track("Duplicate Dataset Template Table", {
        "Dataset Id": datasetID,
        "Dataset Name": datasetName,
        "Template Id": templateID,
        "Template Name": name,
        "Table Id": selectedTableID,
        "Table Name": selectedTableName,
      });
      setReloadTableData((prev) => !prev);
    }
  };

  const moveToClickHandler = (e, clickedTableID, clickedTableName) => {
    e.stopPropagation();
    setSelectedTableID(clickedTableID);
    setSelectedTableName(clickedTableName);
    setShowMoveToModal(true);
  };

  const moveTableTo = async (selectedTemplateID) => {
    const success = await store!.dataset.moveDatasetTemplateTable(selectedTableID, selectedTemplateID);
    setShowMoveToModal(false);
    if (success) {
      getMixpanel(store).track("Move Dataset Template Table", {
        "Dataset Id": datasetID,
        "Dataset Name": datasetName,
        "Template Id": templateID,
        "Template Name": name,
        "Table Id": selectedTableID,
        "Table Name": selectedTableName,
      });
      setReloadTableData((prev) => !prev);
    }
  };

  const editTableDataClickHandler = (e, selectedTableID) => {
    e.stopPropagation();
    history.push(`/datasets/${datasetID}/templates/${templateID}/tables/${selectedTableID}/data`);
  };

  const deleteTemplateClickHandler = async () => {
    const success = await store!.dataset.deleteTemplate(+templateID);
    if (success) {
      getMixpanel(store!).track("Delete Dataset Template");
      history.push(`/datasets/${datasetID}`);
    }
  };

  const duplicateTemplate = async () => {
    const newTemplateID = await store!.dataset.duplicateTemplate(templateID);
    getMixpanel(store!).track("Duplicate Dataset Template", {
      "Dataset Id": datasetID,
      "Dataset Name": datasetName,
      "Template Id": templateID,
      "Template Name": name,
    });
    if (newTemplateID) {
      history.push(`/datasets/${datasetID}/templates/${newTemplateID}`);
    }
  };

  const renameTemplate = async (nameStr: string): Promise<any> => {
    const name = (nameStr || "").trim();
    if (!name) {
      return "Template name is required";
    }
    const res = await store!.dataset.updateTemplate(+templateID, { name });
    if (res?.success) {
      setReloadDatasetTemplateData(true);
      getMixpanel(store!).track("Rename Dataset Template");
    } else {
      return res?.error;
    }
  };

  const tableActionItems: TableActionItem[] = [
    { text: "Edit Schema", icon: "table", clickHandler: editTableClickHandler },
    { text: "Edit Data", icon: "edit", clickHandler: editTableDataClickHandler },
  ];
  const dropdownActionItems: TableActionItem[] = [
    { text: "Duplicate to", icon: "copy", clickHandler: duplicateToClickHandler },
    { text: "Move to", icon: "sign in", clickHandler: moveToClickHandler },
    { text: "Delete", icon: "trash", clickHandler: deleteTableClickHandler },
  ];

  const tabItems = [
    {
      menuItem: "Tables",
      render: () => (
        <>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <p className="fs-1125 fw-600 text-muted mb-0">{`${tables?.length} table(s) created in this template`}</p>
            <Button
              id="userflow-element-create-table"
              size="tiny"
              className="bg-primary text-white"
              onClick={() => history.push(`/datasets/${datasetID}/templates/${templateID}/tables/new`)}
            >
              Create Table
              <Icon className="plus ml-2 mr-0" />
            </Button>
          </div>
          {tables?.length ? (
            <>
              <Accordion>
                {tables.map((table, index) => {
                  const { id, name, schema } = table;
                  return (
                    <div key={id}>
                      <Accordion.Title
                        active={activeTableIndex === index}
                        index={index}
                        onClick={() => handleTableNameClick(index)}
                        className="py-1"
                      >
                        <div className="d-flex align-items-start justify-content-between bg-lightgrey px-2">
                          <div className="fs-1125 fw-700 w-35 d-flex p-2">
                            <Icon name="dropdown" className="mt-1" />
                            <p>{name}</p>
                          </div>
                          <div className="d-flex text-secondary">
                            {tableActionItems.map((item) => {
                              const { text, icon, clickHandler } = item;
                              return (
                                <div key={text} className="cursor-pointer p-2" onClick={(e) => clickHandler(e, id)} role="button">
                                  <p className="fs-1000 fw-700 mb-0">
                                    {text} <Icon name={icon} className="ml-2" />
                                  </p>
                                </div>
                              );
                            })}
                            <LatestDataPopup
                              type="table"
                              data={{ ...table, dataset: dataset }}
                              triggerOverride={
                                <Button data-lastupdatetoggle="true" size="small" className="bg-none text-secondary p-2 mr-0">
                                  Latest Data <Icon name="history" className="ml-2 opacity-1" />
                                </Button>
                              }
                            />
                            <Dropdown text="More" icon="ellipsis vertical" className="fs-1000 p-2">
                              <Dropdown.Menu>
                                {dropdownActionItems.map((item) => {
                                  const { text, icon, clickHandler } = item;
                                  return <Dropdown.Item key={text} text={text} icon={icon} onClick={(e) => clickHandler(e, id, name)} />;
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </Accordion.Title>
                      <Accordion.Content active={activeTableIndex === index} className="pt-0">
                        <TableSchemaPreview schema={schema} />
                      </Accordion.Content>
                    </div>
                  );
                })}
              </Accordion>
              <div className="d-flex justify-content-between align-items-center mb-5">
                <span className="fs-1000 text-medium">{`Showing ${startNumber}${startNumber !== endNumber ? `-${endNumber}` : ""} of ${totalTables} tables`}</span>
                <Pagination
                  pointing
                  secondary
                  size="large"
                  activePage={activePage}
                  boundaryRange={1}
                  onPageChange={(_e, { activePage }) => setActivePage(activePage as number)}
                  siblingRange={2}
                  totalPages={totalPages}
                  ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                  firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                  lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                  prevItem={{ content: <Icon name="angle left" />, icon: true }}
                  nextItem={{ content: <Icon name="angle right" />, icon: true }}
                />
              </div>
            </>
          ) : (
            <EmptyStateSecondary
              heading="No Tables Created Yet"
              description={
                <ListPageEmptyStateDescription
                  description="Here is where you will find tables created for this dataset template."
                  link="https://knowledge.seerdata.ai/data-collection#data-table-concepts"
                  text="tables"
                />
              }
            />
          )}
        </>
      ),
    },
    {
      menuItem: "Update history",
      render: () => (
        <DatasetTable tableFor="Update history" tableHeader={["Name", "Email", "Update date"]} tableData={sortByDate(update_history)} />
      ),
    },
    {
      menuItem: "Contributors",
      render: () => (
        <>
          <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start  align-items-sm-center">
            {showReminderCheckboxes ? (
              <div>
                <p className="fs-1000">Check the boxes next to the contributors you wish to send out an email reminder to and hit Send</p>
                <Button size="tiny" onClick={toggleReminderCheckboxes}>
                  Cancel
                </Button>
                <Button size="tiny" color="purple" onClick={sendReminders} disabled={!checked.some((item) => !!item)}>
                  Send
                </Button>
              </div>
            ) : (
              <Button color="purple" basic size="tiny" onClick={toggleReminderCheckboxes}>
                Send out a reminder manually
              </Button>
            )}
            {!showReminderCheckboxes && (
              <Button color="red" size="tiny" onClick={() => setShowAddContributorModal(true)}>
                Add Contributor <Icon name="plus" className="ml-1 mr-0" />
              </Button>
            )}
          </div>
          <DatasetTable
            tableFor="Contributors"
            tableHeader={[
              ...(showReminderCheckboxes ? [""] : []),
              "Name",
              "Email",
              "Reminder start date",
              "Reminder interval",
              "Reminder history",
              "Actions",
            ]}
            tableData={contributors!}
            toggleCheckbox={showReminderCheckboxes ? toggleCheckbox : undefined}
            checked={showReminderCheckboxes ? checked : undefined}
            updateClickHandler={updateClickHandler}
          />
        </>
      ),
    },
  ];

  return (
    <>
      {sendReminderLoading && <ShowLoaderGlobal />}
      <TableMoveDuplicateModal
        action="Duplicate"
        isOpen={showDuplicateToModal}
        closeModal={() => setShowDuplicateToModal(false)}
        actionClickHandler={duplicateTableTo}
        datasetId={+datasetID}
        templateId={+templateID}
      />
      <TableMoveDuplicateModal
        action="Move"
        isOpen={showMoveToModal}
        closeModal={() => setShowMoveToModal(false)}
        actionClickHandler={moveTableTo}
        datasetId={+datasetID}
        templateId={+templateID}
      />
      {/* Delete Dataset Template Confirm Modal */}
      <Confirm
        open={showDeleteConfirmModal}
        header="Are you sure?"
        content={`Are you you want to delete - ${name}`}
        confirmButton="Delete"
        onCancel={() => setShowDeleteConfirmModal(false)}
        onConfirm={deleteTemplateClickHandler}
      />
      {/* Delete Dataset Template Table Confirm Modal */}
      <Confirm
        open={showDeleteTableConfirmModal}
        header="Are you sure?"
        content={`Are you you want to delete - ${tables?.find((table) => table.id === selectedTableID)?.name}`}
        confirmButton="Delete"
        onCancel={() => setShowDeleteTableConfirmModal(false)}
        onConfirm={deleteTable}
      />
      <Breadcrumbs
        items={[
          { label: "Datasets", pathname: "/datasets" },
          { label: datasetName, pathname: `/datasets/${datasetID}` },
          { label: name, pathname: `/datasets/${datasetID}/templates/${templateID}` },
        ]}
      />
      <HeadingSection
        name={name}
        onNameChange={renameTemplate}
        items={[
          // Only Maranguka templates have "link" and all their templates can't be duplicated. So only show "Duplicate" when "link" doesn't exist
          link
            ? { type: "link", label: "Template Link", icon: "external alternate", link }
            : { type: "button-secondary", label: "Duplicate", icon: "copy", action: () => duplicateTemplate() },
          { type: "button-secondary", label: "Configure Emails", icon: "mail", action: () => setShowEmailMessagesModal(true) },
        ]}
        actionsDropdown={[{ key: 1, text: "Delete", value: 1, onClick: () => setShowDeleteConfirmModal(true) }]}
      />
      {/* Edit template email messages */}
      <EmailMessagesModal
        subHeading={`Datasets > ${datasetName} > ${name}`}
        isOpen={showEmailMessagesModal}
        emailMessages={[
          { title: "Welcome Message", content: contributor_welcome_message, extraContent: dataset_contributor_welcome_message },
          { title: "Reminder Message", content: contributor_reminder_message, extraContent: dataset_contributor_reminder_message },
          { title: "Thank You Message", content: contributor_thank_you_message, extraContent: dataset_contributor_thank_you_message },
        ]}
        closeModal={() => setShowEmailMessagesModal(false)}
        saveModal={saveEmailMessages}
      />
      {/* Add template contributor modal */}
      <TemplateContributorModal
        isOpen={showAddContributorModal}
        closeModal={() => setShowAddContributorModal(false)}
        saveModal={addTemplateContributor}
        actionText="Add"
      />
      {/* Update template contributor modal */}
      <TemplateContributorModal
        isOpen={showUpdateContributorModal}
        contributorDetails={contributors.find((c) => c.id === selectedContributorID)}
        closeModal={() => setShowUpdateContributorModal(false)}
        saveModal={updateTemplateContributor}
        deleteClickHandler={deleteClickHandler}
        actionText="Update"
      />
      {/* Delete contributor confirm popup */}
      <Confirm
        open={showDeleteContributorConfirm}
        header="Are you sure you want to delete this contributor:?"
        content={`${selectedContributorName} (${selectedContributorEmail})`}
        confirmButton="Delete"
        onCancel={() => {
          setShowDeleteContributorConfirm(false);
          setShowUpdateContributorModal(true);
        }}
        onConfirm={() => deleteContributor()}
      />
      <StyleTab menu={{ secondary: true, pointing: true }} panes={tabItems} />
    </>
  );
};

export const DatasetTemplatePage = Protected(inject("store")(observer(DatasetTemplatePageComponent)));
