import { observable, action, computed } from "mobx";
import * as math from "mathjs";
import { datasets, database, getMixpanel } from "../api";
import Store from "../store";
import { find, cloneDeep } from "lodash";
import { makeDDF, makeNameTable, sortArray, customSort, wheres, whens, hows } from "common/helpers/data";
import { ls } from "common/helpers/storage";

// default values for sorted property data
const sortedDefaults: ISorted = {
  open: false,
  toggle: "Column",
  direction: true,
  tId: 0,
  rowName: "",
  colName: "",
  sColumn: false,
  column: "",
  up: false,
  sRow: false,
  right: false,
  tableId: 0,
  tableRow: "",
  order: [],
  custom: false,
};

interface IDimensions {
  [key: string]: IDimension;
}

interface IDimension {
  source?: any;
  type: "What" | "How" | "Where" | "When";
  requirement: "compulsory" | "optional" | "excluded" | "selected" | "comparable";
  values: IValue[];
}

interface IDropdown {
  name: string;
  text: string;
  value: string;
  key: string;
  description: string;
}

export interface IValue {
  name: string;
  requirement: string;
  source?: any;
  weight?: number;
}

interface IValues {
  [key: string]: number;
}

interface ISorted {
  open: boolean;
  // Temp stuff in the dropdown
  toggle: "Row" | "Column";
  direction: boolean; // True = Up / right
  tId: number; // Table id
  rowName: string; // row name
  colName: string;
  // Column stuff
  sColumn: boolean; // Whether column sort is active
  column: string;
  up: boolean; // Up or down sort
  // Row stuff
  sRow: boolean; // Whether row sort is active
  right: boolean; // Right or left sort
  tableId: number; // For row sort, which table and row to base sort off of
  tableRow: string;
  order: number[][]; // For row sort, column sort will be in each table
  custom: boolean; // for the server side custom sorting
}

interface ITable {
  name: string;
  id: number;
  colors: string[];
  type: "result" | "calc";
  // Result
  row?: string;
  rowValues?: string[];
  filters?: string[];
  filterValues?: string[];
  order?: number[]; // For sorting columns
  graph: boolean;
  results?: any;
  // Calc
  eq?: (IRow | string)[];
  format?: "Decimal" | "Percent";
  tables?: number[]; // Array of tables the calc relies on
  columns?: any;
}

interface IGraph {
  type: string;
  highlights: string[];
  percentage: boolean;
  xLabel: string;
  yLabel: string;
  angle: number | undefined;
  advanced: boolean;
  width: number;
  height: number;
  labelFont: number;
  labelLength: number;
}

interface IData {
  x: string;
  y: number[];
}

interface ICalc {
  open: boolean;
  type: "Decimal" | "Percent";
  eq: string[];
}

interface IRow {
  filters: string[];
  filterValues: string[];
  rowName: string;
  row: string;
}

export default class InsightBuilder {
  // copy of the table object to change the table.color value for color picker
  @observable tableToChangeColor: any = null;
  @observable showExport = false;
  @observable growHeight = false; // Exporting was cutting off bottom
  @observable datasets: IDropdown[] = [];
  @observable savedDatasets: IDropdown[] = [];
  @observable dataset = "";
  @observable guessedDatasets: string[] = [];
  @observable term = "";
  @observable dimensions: IDimensions = {};
  @observable measuredQuantities: any = {};
  @observable selectedFilters: any = {};
  @observable columns: string[] = [];
  @observable results: any = [];
  @observable row = "";
  @observable hasUnsavedInsight = true;
  @observable values: IValues = {};
  @observable sorted: ISorted = sortedDefaults;
  @observable tables: ITable[] = [];
  @observable counter = 1; // Table name counter
  @observable optional: any = {};
  @observable graph: IGraph = {
    type: "Vertical bar clustered",
    highlights: [],
    percentage: false,
    xLabel: "",
    yLabel: "",
    angle: undefined,
    advanced: false,
    width: 800,
    height: 600,
    labelFont: 0,
    labelLength: 0,
  };
  @observable calc: ICalc = {
    open: false,
    type: "Decimal",
    eq: [],
  };
  @observable sToggle: "Table" | "Chart" = "Table";
  @observable insightReady = false;
  // @TODO - should be able to remove "random", this is only used to force an update so some logic must be broken
  @observable random = 0; // to update component
  @observable selectAll: Record<string, boolean> = {};
  // loading state for each async action
  @observable loading = {
    searchDatasets: false,
    loadAllAvailableDatasets: false,
    selectOptions: false,
  };
  @observable showFullQuery = false;
  // saving canvas / selectedBrush for potential backend use in future
  canvas: string[] = [];
  selectedBrush = "cycle";

  parent: Store;

  constructor(parent: Store) {
    this.parent = parent;
  }

  @computed get qsQuery() {
    const columns = {};
    this.columns.forEach((col) => (columns[col] = this.getSelected(col)));
    const rows = this.row ? { [this.row]: this.getSelected(this.row) } : {};
    const filters = {};
    this.filters.forEach((f) => (filters[f] = this.getSelected(f)));

    return {
      columns,
      rows,
      filters,
      dataset: this.dataset,
    };
  }

  @computed get wheres(): any[] {
    // All where dims formatted for DD
    const dims = Object.keys(this.dimensions).filter(
      (dim) => this.dimensions[dim].type === "Where" && this.dimensions[dim].requirement !== "excluded",
    );
    return makeDDF(dims);
  }

  @computed get selectedWheres(): any[] {
    return Object.keys(this.dimensions).filter(
      (dim) =>
        this.dimensions[dim].type === "Where" &&
        (this.dimensions[dim].requirement === "selected" || (this.dataset && this.dimensions[dim].requirement === "compulsory")),
    );
  }

  @computed get whats(): any[] {
    // All what dims formatted for DD
    const dims = Object.keys(this.dimensions).filter(
      (dim) => this.dimensions[dim].type === "What" && this.dimensions[dim].requirement !== "excluded",
    );
    return makeDDF(dims);
  }

  @computed get selectedWhats(): any[] {
    return Object.keys(this.dimensions).filter(
      (dim) =>
        this.dimensions[dim].type === "What" &&
        (this.dimensions[dim].requirement === "selected" || (this.dataset && this.dimensions[dim].requirement === "compulsory")),
    );
  }

  @computed get whens(): any[] {
    // All when dims formatted for DD
    const dims = Object.keys(this.dimensions).filter(
      (dim) => this.dimensions[dim].type === "When" && this.dimensions[dim].requirement !== "excluded",
    );
    return makeDDF(dims);
  }

  @computed get selectedWhens(): any[] {
    return Object.keys(this.dimensions).filter(
      (dim) =>
        this.dimensions[dim].type === "When" &&
        (this.dimensions[dim].requirement === "selected" || (this.dataset && this.dimensions[dim].requirement === "compulsory")),
    );
  }

  @computed get hows(): any[] {
    // All when dims formatted for DD
    const dims = Object.keys(this.dimensions).filter(
      (dim) => this.dimensions[dim].type === "How" && this.dimensions[dim].requirement !== "excluded",
    );
    return makeDDF(dims);
  }

  @computed get selectedHows(): any[] {
    return Object.keys(this.dimensions).filter(
      (dim) =>
        this.dimensions[dim].type === "How" &&
        (this.dimensions[dim].requirement === "selected" || this.dimensions[dim].requirement === "compulsory"),
    );
  }

  @computed get filters(): any[] {
    // Every dimension minus the column dim and main row dim
    const dimensions = Object.keys(this.dimensions).filter(
      (dim) => this.dimensions[dim].requirement === "selected" || this.dimensions[dim].requirement === "compulsory",
    );
    return dimensions.filter((dim) => !this.columns.includes(dim) && dim !== this.row);
  }

  @computed get columnsArray(): any[] {
    let columnValues: any = [];
    for (const column of this.columns) {
      columnValues = [...columnValues, ...this.getSelected(column)];
    }
    return columnValues;
  }

  @computed get selectedTables(): any[] {
    // Selected tables for graph
    return this.tables.filter((table) => table.graph);
  }

  @computed get selectedRows(): any[] {
    // Selected rows for graph
    let arr: any[] = [];
    let iterator = 0;
    // clone observables to not cause side effects below where rowValues are modified for sort
    const selectedTables = cloneDeep(this.selectedTables);
    selectedTables.forEach((table) => {
      if (table.type === "result") {
        arr = arr.concat(
          table.rowValues!.map((val, i) => {
            // apply table sorting (ordering) to the chart items
            if (table?.order) {
              val = table?.rowValues![table?.order![i]];
            }
            return {
              val,
              name: table.name,
              color: table.colors[i],
              tableId: table.id,
              rowIndex: table?.order?.[i] ?? i,
              overallRowIndex: iterator + (table?.order?.[i] ?? i),
            };
          }),
        );

        iterator = iterator + table.rowValues!.length;
        return arr;
      } else {
        // table type === "calc"
        arr = arr.concat({
          val: "",
          name: table.name,
          color: table.colors[0],
          tableId: table.id,
          rowIndex: 0,
          overallRowIndex: iterator,
        });
        iterator = iterator + 1; // calc should only have one row
        return arr;
      }
    });

    return arr;
  }

  @computed get graphData(): IData[] {
    // Graph data
    const dataArr: IData[] = [];
    this.selectedTables.forEach((table, i) => {
      let columns: any = [];
      for (const column of this.columns) {
        columns = [...columns, ...this.getOrder(column, i, true)];
      }
      columns?.forEach((col) => {
        const data: IData = { x: col, y: [] };
        const colName = this.columns.filter((colName) => this.getSelected(colName).includes(col))[0];
        if (table.type === "result") {
          let rowValues = [...table.rowValues];
          if (table.order) {
            rowValues = table.order.map((num) => table.rowValues[num]);
          }
          data.y = data.y.concat(
            rowValues.map((row) => {
              const name = makeNameTable(colName, col, table.row!, row, table.filters!, table.filterValues!);
              return this.values[name] === undefined ? 0 : this.values[name];
            }),
          );
        } else {
          data.y = data.y.concat(this.getCNumber(table.eq!, colName, col));
        }
        dataArr.push(data);
      });
    });

    const newDataArr: IData[] = [];
    const insertYData = (arr: IData[], key: string, newItem: IData) => {
      const i: number = arr.findIndex((d) => d.x === key);

      if (i === -1) {
        arr.push(newItem);
      } else {
        arr[i].y.push(...newItem.y);
      }
    };

    dataArr.forEach((d) => {
      insertYData(newDataArr, d.x, d);
    });

    return newDataArr;
  }

  @computed get pieData(): any[] {
    // Different format from the rest
    const data: any[] = [];
    // avoid duplicating for loops with an array map
    const arrayMap = {
      "Donut one": [this.selectedRows, this.columnsArray],
      "Donut two": [this.columnsArray, this.selectedRows],
    };
    const [arr1, arr2] = arrayMap[this.graph.type] || [];
    if (arr1 && arr2) {
      arr1.forEach((arr1Item) => {
        data.push(
          arr2.map((arr2Item) => {
            // determine which is the row and which is column from array map based on graph type
            const row = this.graph.type === "Donut one" ? arr1Item : arr2Item;
            const col = this.graph.type === "Donut one" ? arr2Item : arr1Item;
            const table = this.tables.filter(
              (table) => (table.type === "result" && table.id === row.tableId) || (table.type === "calc" && table.name === row.name),
            )[0];
            const colName = this.columns.filter((colName) => this.getSelected(colName).includes(col))[0];
            if (table.type === "result") {
              const name = makeNameTable(colName, col, table.row!, row.val, table.filters!, table.filterValues!);
              return {
                value: this.values[name] === undefined ? 0 : this.values[name],
                name: col,
              };
            } else {
              return {
                value: this.getCNumber(table.eq!, colName, col),
                name: col,
              };
            }
          }),
        );
      });
    }
    return data;
  }

  @computed get pieType(): any {
    // Which type of pie chart
    return this.graph.type.includes("one") ? this.selectedRows : this.columnsArray;
  }

  @computed get calcPossible(): boolean {
    // Is the calculation valid
    const eq = this.calc.eq.map((str) => (str.includes("Row") ? "1" : str));
    try {
      math.evaluate(eq.join(""));
      return true;
    } catch {
      return false;
    }
  }

  @computed get sortTables(): any[] {
    // Possible tables for sort
    return makeDDF(this.tables.filter((table) => table.type === "result").map((table) => table.name));
  }

  @computed get sortOptions(): any[] {
    if (this.sorted.toggle === "Row") {
      return makeDDF(this.tables.filter((table) => table.id === this.sorted.tId)[0].rowValues!);
    } else {
      let arr: string[] = [];
      this.columns.forEach((col) => (arr = arr.concat(this.getSelected(col))));
      return makeDDF(arr);
    }
  }

  @action toggleFullQuery(): void {
    this.showFullQuery = !this.showFullQuery;
  }

  @action async loadAllAvailableDatasets(): Promise<any> {
    this.loading.loadAllAvailableDatasets = true;
    const response: any = await datasets.post("v2/qs", {}, this.parent.token ?? "");
    this.loading.loadAllAvailableDatasets = false;
    this.reloadData(response);
  }

  @action reloadData(res: any): void {
    const resDatasets = res.body.data.datasets ?? [res.body.data.dataset];
    const data = resDatasets.reduce(
      (acc, curr, i, src) => ({
        dimensions: [...acc.dimensions, ...curr.dimensions.map((d) => ({ key: src[i].key, ...d }))],
        results: [...acc.results, ...curr.results],
      }),
      { key: [], dimensions: [], results: [] },
    );
    this.dimensions = {};
    this.updateDimensions({ body: { data } });
  }

  @action async searchDatasets(): Promise<any> {
    this.loading.searchDatasets = true;
    await this.parent.builder.getAllDatasets();
    this.loading.searchDatasets = false;
    this.datasets = (this.parent.builder.allDatasets as any)
      .sort((a: IDropdown, b: IDropdown) => a.name.localeCompare(b.name))
      .map((d: IDropdown) => ({
        text: d.name,
        description: d.description,
        value: d.key,
        key: d.key,
      }));
    this.savedDatasets = this.datasets;
  }

  // this shouldn't happen anywhere anymore but updating to v2 just in case
  @action async selectDataset(dataset: string, clearFilters?: boolean): Promise<any> {
    this.dataset = dataset;
    this.resetData();
    this.datasets = [this.datasets.find((x) => x.key === dataset)!];
    const res: any = await datasets.post(
      "v2/qs",
      {
        filters: clearFilters ? {} : this.selectedFilters,
        datasets: [dataset],
      },
      this.parent.token !== null ? this.parent.token : "",
    );
    this.updateDimensions(res);
    // Force the re-render
    this.random = Math.random();
  }

  @action updateDimensions(res: any): void {
    const isNewInsightsPage =
      window.location.pathname.includes("insights/new") ||
      window.location.pathname.includes("analyst") ||
      window.location.pathname.includes("explore") ||
      window.location.pathname.includes("builder/new") ||
      window.location.pathname.includes("catalog");

    // Runs once dataset is selected, includes temporary sorting of w/w/w
    const data = res?.body?.data?.dataset ?? res?.body?.data;

    // don't do operations directly on observables to avoid performance issues
    const newDimensions = cloneDeep(this.dimensions);

    data.dimensions.map((d) => {
      const keys = newDimensions[d.name]?.source ?? {};
      const values = newDimensions[d.name]?.values ?? [];

      newDimensions[d.name] = {
        source: { [d.key]: true, ...keys },
        type: wheres.includes(d.name) ? "Where" : whens.includes(d.name) ? "When" : hows.includes(d.name) ? "How" : "What",
        requirement: d.requirement,
        values: !isNewInsightsPage ? (d.values ?? []) : this.concatValuesByKey(values, d.values ?? [], d.key),
      };
    });
    // overwrite one time, rather than multiple updates in mobx
    this.dimensions = { ...this.dimensions, ...newDimensions };
  }

  @action getCategorySelectionCount(which: string): string {
    const length = this[`selected${which}s`]?.length;
    return length > 0 ? "" : "Search or select";
  }

  @action getSubcategorySelectionCount(dim: string): string {
    const length = this.getSelected(dim)?.length;
    return length > 0 ? "" : "Search or select";
  }

  @action swap(): void {
    // Swaps row and col, can only happen with 1 column dim and no tables
    if (this.columns.length === 1 && this.tables.length <= 1 && this.row) {
      [this.row, this.columns] = [this.columns[0], [this.row]];
      this.formatOutput(false, "", true);
    }
  }

  @action pinTable(): void {
    // Pins table
    this.tables.push({
      name: `${this.row && `${this.row} - `}Untitled Result ${this.counter}`,
      id: this.counter,
      type: "result",
      colors: this.getSelected(this.row).map(
        () => `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`,
      ),
      row: this.row,
      rowValues: this.row === "" ? [""] : this.getSelected(this.row),
      filters: this.filters,
      filterValues: this.filters.map((filter) => this.getSelected(filter)[0]),
      graph: true,
    });
    getMixpanel(this.parent).track("Add to Insight", { Dataset: this.dataset });
    this.counter++;
    this.sort("Both");
  }

  @action async resetRows(): Promise<any> {
    this.row = "";
    const columns = {};
    this.columns.forEach((col) => (columns[col] = this.getSelected(col)));
    const res: any = await datasets.post(
      "v2/qs",
      {
        filters: {
          ...columns,
        },
        datasets: [this.dataset],
      },
      this.parent.token ?? "",
    );
    res.body.data.dataset.dimensions.forEach((d) => {
      this.dimensions[d.name].requirement = d.requirement;
      this.dimensions[d.name].values = d.values ? d.values : [];
    });
    this.random = Math.random();
  }

  @action getNumbers(rowName: string, row: string, filters: string[], filterValues: string[]): any[] {
    let arr: number[] = [];
    this.columns.forEach((col) => {
      this.getSelected(col).forEach((val) => {
        const name = makeNameTable(col, val, rowName, row, filters, filterValues);
        arr.push(this.values[name]);
      });
    });
    arr = arr.map((num) => (num === undefined ? 0 : num));
    return arr;
  }

  @action sort(type: "Row" | "Column" | "Both"): void {
    // Sorting the table
    if (type === "Row") {
      this.sorted.sRow = true;
      this.sorted.right = this.sorted.direction;
      Object.assign(this.sorted, {
        sRow: true,
        right: this.sorted.direction,
        tableId: this.sorted.tId,
        tableRow: this.sorted.rowName,
      });
    } else if (type === "Column") {
      Object.assign(this.sorted, {
        sColumn: true,
        up: this.sorted.direction,
        column: this.sorted.colName,
      });
    }
    Object.assign(this.sorted, {
      toggle: "Column",
      direction: true,
      tId: 0,
      rowName: "",
      colName: "",
    });
    if ((type === "Row" || type === "Both") && this.sorted.sRow) {
      const order: number[][] = [];
      this.columns.forEach((col) => {
        // Make number array for each column dim in that row
        let arr: number[] = []; // Contains the numbers for the row to be sorted
        this.getSelected(col).forEach((val) => {
          const table = this.tables.filter((table) => table.id === this.sorted.tableId)[0];
          const name = makeNameTable(col, val, table.row!, this.sorted.tableRow, table.filters!, table.filterValues!);
          arr.push(this.values[name]);
        });
        arr = arr.map((num) => (num === undefined ? 0 : num)); // Changes all undefined numbers to 0 so they can be sorted
        let sorted: number[] = sortArray(arr);
        this.sorted.right && (sorted = sorted.reverse()); // Reverse if needed
        order.push(sorted);
      });
      this.sorted.order = order;
    }
    if ((type === "Column" || type === "Both") && this.sorted.sColumn) {
      this.tables.forEach((table) => {
        if (table.type === "result") {
          // No need to sort calc tables
          if (table.rowValues!.length === 1) {
            // No need to sort, only 1 row
            table.order = [0];
          } else {
            let arr: number[] = [];
            table.rowValues!.forEach((row) => {
              const name = makeNameTable(
                this.columns.filter((colName) => this.getSelected(colName).includes(this.sorted.column))[0],
                this.sorted.column,
                table.row!,
                row,
                table.filters!,
                table.filterValues!,
              );
              arr.push(this.values[name]);
            });
            arr = arr.map((num) => (num === undefined ? 0 : num)); // Changes all undefined numbers to 0 so they can be sorted
            let sorted: number[] = sortArray(arr);
            this.sorted.up && (sorted = sorted.reverse()); // Reverse if needed
            table.order = sorted;
          }
        }
      });
    }
    this.sorted.open = false;
    this.random = Math.random(); // To updated component
  }

  @action getOrder(dim: string, i: number, header?: boolean) {
    const tables = this.tables[i];

    if (header && tables?.columns?.[dim] != null && this.sorted.custom) {
      return tables.columns[dim];
    } else if (header && ls.getItem("tables")) {
      // LocalStorage is a temporary fix.
      const tables = JSON.parse(ls.getItem("tables") ?? "[]")?.[i];
      if (tables?.columns?.[dim] != null && this.sorted.custom) {
        return tables.columns[dim];
      }
    }

    if (!this.sorted.sRow) {
      // If no sorting has been done yet
      return this.getSelected(dim);
    }
    const order = this.sorted.order[0];
    return order?.map((num) => this.getSelected(dim)[num]);
  }

  @action selectDimension(type: string, values: string[]): void {
    const pRemoved = this[`selected${type}s`].filter((dim) => !values.includes(dim)); // Removed dim

    if (pRemoved.length) {
      // If a dim was removed, check if it was col or row
      pRemoved.forEach((removed) => {
        if (this.optional[removed] == null) {
          this.optional[removed] = {};
        }
        this.dimensions[removed].requirement = "optional";
        if (this.columns.length === 1 && removed === this.columns[0]) {
          // Column was removed and there was only 1, set row to column
          this.tables = []; // All tables removed when column removed
          this.columns[0] = this.row;
          this.row = "";
        } else if (this.columns.includes(removed)) {
          // It was one of the columns
          this.columns.splice(this.columns.indexOf(removed), 1);
        } else if (removed === this.row) {
          // Row was removed
          this.row = "";
        }
      });
    } else {
      const added = values.filter((dim) => !this[`selected${type}s`].includes(dim)); // Added dim
      added.forEach((item) => {
        this.dimensions[item].requirement === "comparable" && this.columns.push(item);
        this.dimensions[item].requirement = "selected";
      });
    }
    this.formatOutput(true, "", true);
  }

  @action selectValue(values: string[], dim: string): void {
    const added = values.length > this.getSelected(dim).length; // Whether added or removed
    const valuesToUpdate = added
      ? values.filter((val) => !this.getSelected(dim).includes(val))
      : this.getSelected(dim).filter((val) => !values.includes(val));

    // Maintain ordering of previous and current selections when updating dimensions
    const updatedDimValues: IValue[] = [];
    if (values.length !== 0) {
      // if length is 0 this logic makes no sense to follow, skipping for 0
      const previouslySelectedDimValues = this.dimensions[dim].values.filter((v) => v.requirement === "selected");
      const otherDimValues = this.dimensions[dim].values.filter((v) => v.requirement !== "selected" && !valuesToUpdate.includes(v.name));
      updatedDimValues.push(...previouslySelectedDimValues);
      valuesToUpdate.map((valToUpdate) => {
        const index = this.dimensions[dim].values.findIndex((val) => val.name === valToUpdate);
        updatedDimValues.push({ ...this.dimensions[dim].values[index], requirement: added ? "selected" : "optional" });
      });
      updatedDimValues.push(...otherDimValues);
    }

    // Assign updated dimensions to store object
    this.dimensions[dim].values = updatedDimValues;

    if (!this.columns.includes(dim) && !(dim === this.row) && this.columns && this.row) {
      // If dim is not col / row
      if (this.getSelected(dim).length > 1) {
        // If any other selected, remove them
        this.getSelected(dim)
          .filter((val) => !valuesToUpdate.includes(val))
          .forEach((val) => (this.dimensions[dim].values.filter((obj) => obj.name === val)[0].requirement = "optional"));
      }
    }
    // Setting column / row
    if (values.length > 1) {
      if (!this.columns.length) {
        // If no column has been selected yet
        this.columns.push(dim);
      } else if (this.row === "" && !this.columns.includes(dim)) {
        // If no row has been selected yet
        this.row = dim;
      }
    }
    // Demoting column / row back to filter
    if (dim === this.row && values.length <= 1) {
      // Remove as row
      this.row = "";
    } else if (this.columns.length === 1 && dim === this.columns[0] && values.length <= 1) {
      // Set row to column and clear row
      this.tables = []; // Clear tables becuase new / no column
      this.columns = [];
      if (this.row) {
        this.columns.push(this.row);
        this.row = "";
      }
    }
    this.formatOutput(false, dim, true);
  }

  @action resetData(): void {
    this.dimensions = {};
    this.columns = [];
    this.row = "";
    this.values = {};
    this.tables = [];
    this.sorted = sortedDefaults;
  }

  @action resetSorted(): void {
    this.sorted = sortedDefaults;
  }

  @action makeName(colName: string, col: string, row: string, noRow?: boolean, noColumn?: boolean): string {
    // Makes the key for the value
    const dimVals = this.filters.map((f) => `${f}${this.getSelected(f).length ? this.getSelected(f)[0] : ""}`);
    !noRow && dimVals.push(`${this.row}${row}`);
    !noColumn && dimVals.push(`${colName}${col}`); // Need to pass in colName now due to multiple cols
    return dimVals.sort().join(" ");
  }

  @action deleteTable(id: number): void {
    this.sorted.tableId === id &&
      Object.assign(this.sorted, {
        sRow: false,
        tableId: 0,
        tableRow: "",
        order: [],
      });
    this.tables = this.tables.filter((table) => (table.type === "result" || !table.tables!.includes(id)) && table.id !== id);
    // Calc does not rely on this table and remove the table itself
  }

  @action async formatOutput(reloadData?: boolean, which?: string, editMode?: boolean): Promise<any> {
    this.random = Math.random();
    const columns = {};
    this.columns.forEach((col) => (columns[col] = this.getSelected(col)));
    const rows = this.row ? { [this.row]: this.getSelected(this.row) } : {};
    const filters = {};
    this.filters.forEach((f) => (filters[f] = this.getSelected(f)));
    this.loading.selectOptions = true;
    let res;
    if (editMode) {
      res = await datasets
        .post(
          "v2/qs",
          {
            filters: {
              ...(this.dataset ? columns : {}),
              ...(this.dataset ? rows : {}),
              ...(this.dataset ? filters : this.selectedFilters),
            },
            datasets: this.dataset ? [this.dataset] : this.guessedDatasets,
            optional: this.optional,
          },
          this.parent.token !== null ? this.parent.token : "",
        )
        .catch(() => {
          this.loading.selectOptions = false;
          return {};
        });

      if (res.statusCode === 200) {
        if (res.body.data.dataset?.results) {
          this.results = res.body.data.dataset?.results;
        }
      }

      this.optional = {};
      if (this.dataset.length === 0 && reloadData) {
        this.reloadData(res);
      }
      if (res.body.data) {
        const resDatasets = res.body.data.datasets ?? [res.body.data.dataset];
        // don't do operations directly on observables to avoid performance issues
        const dimensionsUpdates = cloneDeep(this.dimensions);
        resDatasets.forEach((dataset) => {
          dataset.dimensions.forEach((d) => {
            if (dimensionsUpdates[d.name]) {
              const values = dimensionsUpdates[d.name].values ?? [];
              const requirement = dimensionsUpdates[d.name].requirement;
              dimensionsUpdates[d.name].requirement = requirement !== "selected" ? d.requirement : requirement;
              dimensionsUpdates[d.name].values = d.values ? this.concatValues(d.values, values, which) : values;
            }
          });
        });
        // one time update instead of many in mobx
        this.dimensions = { ...this.dimensions, ...dimensionsUpdates };
      }
    } else {
      res = {
        body: {
          data: {
            dataset: {
              key: this.dataset,
              results: this.results,
            },
          },
        },
      };
    }
    this.setValues(res);

    this.tables.forEach(async (table) => {
      if (table.type === "result") {
        const rows = table.row ? { [table.row]: table.rowValues } : {};
        const filters = {};
        table.filters!.forEach((f, i) => (filters[f] = [table.filterValues![i]]));
        let res;
        if (editMode) {
          res = await datasets.post(
            "v2/qs",
            {
              filters: {
                ...columns,
                ...rows,
                ...filters,
              },
              datasets: [this.dataset],
            },
            this.parent.token !== null ? this.parent.token : "",
          );
          if (res.statusCode === 200) {
            if (res.body.data.dataset?.results) {
              table.results = res.body.data.dataset?.results;
            }
          }
        } else {
          res = {
            body: {
              data: {
                dataset: {
                  key: this.dataset,
                  results: table.results,
                },
              },
            },
          };
        }
        this.setValues(res);
      }
    });
    this.loading.selectOptions = false;
    this.sort("Both");
    this.random = Math.random();
  }

  @action setValues(res: any): void {
    if (res.body.data) {
      const resDatasets = res.body.data.datasets ?? [res.body.data.dataset];
      resDatasets.forEach((dataset) => {
        if (dataset.results?.length > 0) {
          const ZD = this.values[this.makeName("", "", "", true, true)]; // Zero D value
          // If ZD value already exists, delete it because one and only one ZD value can exist at a time.
          if (ZD) {
            delete this.values[this.makeName("", "", "", true, true)];
          }
          if (!window.location.pathname.includes("explore") || !window.location.pathname.includes("updateInsight")) {
            getMixpanel(this.parent).track("Data retrieve", { Dataset: this.dataset });
          }
        }
        dataset.results?.forEach((r) => {
          const dimVals: string[] = [];
          Object.keys(r).forEach((key) => {
            if (key !== "Value") {
              // Exclude the value from the name
              dimVals.push(`${key}${r[key]}`);
            }
          });
          const name = dimVals.sort().join(" ");
          this.values[name] = r.Value;
        });
      });
    }
  }

  @action saveTableName(table: ITable, name: string): void {
    table.name = name;
  }

  @action setGraphTables(names: string[]): void {
    this.tables.forEach((table) => (names.includes(table.name) ? (table.graph = true) : (table.graph = false)));
  }

  @action doCalc(): void {
    // Does calculation
    const tables: number[] = [];
    const table: ITable = {
      name: `Untitled calculation ${this.counter}`,
      id: this.counter,
      type: "calc",
      eq: this.calc.eq.map((str) => {
        const obj: IRow = {
          filters: [],
          filterValues: [],
          rowName: "",
          row: "",
        };
        if (str.includes("Row")) {
          const row: number = parseFloat(str.slice(3));
          let table: ITable | null = null; // Table the row is in
          let rowNumber = 0; // row number in that table
          let counter = 1; // Counter to find table / row
          this.tables.forEach((t) => {
            if (t.type === "result") {
              if (row >= counter && row < counter + t.rowValues!.length) {
                table = t;
                rowNumber = row - counter;
              }
              counter += t.rowValues!.length; // Only add if table is a result
            }
          });
          tables.push(table!.id);
          Object.assign(obj, {
            filters: table!.filters,
            filterValues: table!.filterValues,
            rowName: table!.row,
            row: table!.rowValues![rowNumber],
          });
        }
        return str.includes("Row") ? obj : str;
      }),
      colors: [`rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`],
      format: this.calc.type,
      graph: true,
    };
    table.tables = tables;
    this.tables.push(table);
    this.counter++;
    Object.assign(this.calc, { eq: [], open: false, type: "Decimal" });
  }

  @action getCNumber(eq: (IRow | string)[], colName: string, col: string): any {
    // Get a calculated number
    const formula: string[] = eq.map((s) => {
      if (typeof s === "string") {
        return s;
      } else {
        const name = makeNameTable(colName, col, s.rowName, s.row, s.filters, s.filterValues);
        return this.values[name] === undefined ? "0" : this.values[name].toString();
      }
    });
    return math.evaluate(formula.join(""));
  }

  @action async load(obj: any, editMode: boolean): Promise<any> {
    // LocalStorage is a temporary solution.
    ls.setItem("tables", JSON.stringify(obj?.tables ?? {}));

    await this.searchDatasets();

    const { columns, rows, filters, dataset } = obj;
    let res = {};
    if (editMode) {
      res = await datasets.post(
        "v2/qs",
        {
          filters: {
            ...columns,
            ...rows,
            ...filters,
          },
          datasets: [dataset],
        },
        this.parent.token !== null ? this.parent.token : "",
      );
      this.updateDimensions(res);
    } else {
      const rowsColumnsFilters = { ...rows, ...columns, ...filters };
      for (const dim of Object.keys(rowsColumnsFilters)) {
        const values: any = [];
        for (const val of rowsColumnsFilters[dim]) {
          values.push({
            name: val,
            requirement: "selected",
          });
        }
        this.dimensions[dim] = {
          source: { undefined: true },
          type: "What",
          requirement: "selected",
          values,
        };
      }
    }
    const cols = Object.keys(columns);
    const row = Object.keys(rows).length ? Object.keys(rows)[0] : "";
    obj.columns = cols;
    obj.row = row;

    delete obj.rows;
    delete obj.filters;
    obj.tables.forEach((table) => {
      if (table.type === "result") {
        delete table.columns;
        const rows = Object.keys(table.rows);
        table.row = rows.length ? rows[0] : "";

        table.rowValues = rows.length ? table.rows[rows[0]] : [""];
        delete table.rows;
        const filters: string[] = [];
        const filterValues: string[] = [];
        Object.keys(table.filters).forEach((f) => {
          filters.push(f);
          filterValues.push(table.filters[f][0]);
        });
        Object.assign(table, { filters, filterValues });
      }
    });

    // Delete percentage rules from returned insight JSON object before loading into store
    delete obj["percentTriggerExclusions"];
    delete obj["percentTriggers"];

    Object.assign(this, obj);
    this.graph.width = 798;
    await this.formatOutput(false, "", editMode);
    this.insightReady = true;
  }

  @action formatSave(): any {
    // Formats to save as insight
    let obj: any = { ...this };
    delete obj.parent;
    delete obj.showExport;
    obj = JSON.parse(JSON.stringify(obj));
    const columns = {};
    const filters = {};
    obj.rows = {};
    obj.columns.forEach((col) => (columns[col] = this.getSelected(col)));
    obj.row && (obj.rows[obj.row] = this.getSelected(obj.row));
    this.filters.forEach((filter) => (filters[filter] = this.getSelected(filter)));
    [
      "tableToChangeColor",
      "displayPopup",
      "calc",
      "datasets",
      "dimensions",
      "random",
      "row",
      "term",
      "values",
      "insightReady",
      "hasUnsavedInsight",
      "showExport",
      "growHeight",
      "toggle",
      "toggleActive",
    ].forEach((str) => delete obj[str]);
    Object.assign(obj, { columns, filters });
    obj.tables.forEach((table) => {
      if (table.type === "result") {
        const filters = {};
        table.columns = obj.columns;
        table.rows = {};
        table.row && (table.rows[table.row] = table.rowValues);
        table.filters.forEach((filter, i) => (filters[filter] = [table.filterValues[i]]));
        ["filterValues", "row", "rowValues"].forEach((str) => delete table[str]);
        table.filters = filters;
      }
    });
    return obj;
  }

  @action async updateInsight(imgBase64 = undefined): Promise<any> {
    const banner = imgBase64;
    const json = this.formatSave();
    json.date = new Date();
    json.title = "";
    json.userId = this.parent.user!.id;

    const requestBody = {
      json: JSON.stringify(json),
      create_version: true,
    };
    if (banner) {
      requestBody["banner"] = banner;
    }
    await database.put(`insights/${this.parent.insight.result.id}`, requestBody, this.parent.token!);
    this.parent.insight.load(this.parent.insight.result.id, true);
  }

  // sets creates a copy of the table object to change the table.color value for color picker
  @action setTableToChangeColor(table: any): void {
    this.tableToChangeColor = table;
  }

  @action setChangeColor(color: any): void {
    this.tableToChangeColor.color = color;
  }

  // resets default values
  @action resetDataset(reset: boolean): void {
    reset && (this.dataset = "");
  }

  concatValues(target: any[], source: any[], which?: string): any[] {
    if (this.dataset.length !== 0) {
      const result = [...target];
      source.forEach((sourceValue) => {
        const targetValue = find(target, { name: sourceValue.name });
        if (targetValue == null) {
          result.push(sourceValue);
        }
      });
      return result;
    }

    const result: any[] = [];
    source.forEach((sourceValue) => {
      const targetValue = find(target, { name: sourceValue.name }) ?? sourceValue;
      targetValue.source = { ...sourceValue.source };
      if (!which || !this.selectedFilters[which].includes(targetValue.name)) {
        targetValue.requirement =
          targetValue.requirement !== "selected"
            ? targetValue.requirement
            : this.dataset.length === 0
              ? "optional"
              : targetValue.requirement;
      }
      result.push(targetValue);
    });

    return result;
  }

  concatValuesByKey(target: any[], source: any[], key: string): any[] {
    source.forEach((val) => {
      const value = find(target, { name: val.name }) ?? val;
      const keys = value.source ?? {};
      value.source = { [key]: true, ...keys };
      if (Object.keys(this.selectedFilters).length === 0) {
        value.requirement =
          value.requirement !== "selected" ? value.requirement : this.dataset.length === 0 ? "optional" : value.requirement;
      }
      if (value === val) {
        target.push(value);
      }
    });
    return [...target];
  }

  getSelected(dim: string): any[] {
    // returns selected values for dimension
    if (dim === "") {
      // If string is empty its a single row table
      return [""];
    } else {
      const values: IValue[] = this.dimensions[dim]?.values ?? [];
      // filter first to process quicker
      const filtered = values.filter((val) => val.requirement === "selected");
      return customSort(filtered).map((val) => val.name);
    }
  }
}
