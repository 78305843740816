import { COLORS } from "component/UI/common";
import styled from "styled-components";

export const Container = styled.div`
  @media screen and (min-width: 1380px) and (max-width: 4000px) {
    position: relative;
  }
  & img {
    max-width: 100%;
  }
`;

export const Box = styled.div`
  display: flex;
`;

export const SuitcaseTitle = styled.div`
  color: ${COLORS.indigo600};
`;

export const CommentsHeading = styled.h1`
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 30px;
  line-height: 41px;
  color: ${COLORS.indigo600};
  display: flex;
  margin-bottom: 15px;
`;
