import * as React from "react";
import { MapContainer as Map, TileLayer, LayersControl } from "react-leaflet";
import { useLeafletContext } from "@react-leaflet/core";
import { Helmet } from "react-helmet";
import { VectorTileLayer } from "component/LeafletCustom/VectorTileLayer";
import { FullScreenControl } from "component/LeafletCustom/FullScreenControl";
import { GeoSearch } from "component/LeafletCustom/GeoSearch";
import { BottomLeftContent, ContentBox } from "pages/Maps/includes/Layout";
import { brushInterpolate } from "common/helpers/palette";

const proximityCanvas = ["#e37700", "#ffed50", "#236A4D"];
const proximityColors = brushInterpolate(proximityCanvas, [101])[0]; // need 101 colors for value between 0-100%

export const ComTasParks = (): JSX.Element => {
  const DEFAULT_ZOOM = 7;
  const DEFAULT_MAP_CENTRE: any = [-42.13655, 146.223918];
  const MAP_BOUNDS: any = [
    [-38, 140],
    [-45, 152],
  ];
  const DEFAULT_OPACITY = 0.7;
  const DEFAULT_LW = 1;
  const HOVER_OPACITY = 0.7; // no change desired..
  const HOVER_LW = 2;
  const [visibleLayers, setVisibleLayers] = React.useState(["sa1_21_map"]);

  const pickQuintilleColor = (property) => {
    const { percent_in } = property;
    const proximityValue = Math.floor(percent_in * 100);
    return proximityColors[proximityValue];
  };
  const tasAOIColor = "#2aa850";
  const legends = {
    sa1_21_map: {
      colors: proximityColors,
      label: "SA1 - Population Near Parks",
    },
    aoi_gdf_map: {
      color: tasAOIColor,
      label: "Play & Park Areas",
    },
  };

  const defaultStyle = (properties) => ({
    stroke: false,
    weight: DEFAULT_LW,
    fillOpacity: DEFAULT_OPACITY,
    opacity: 1, // line opacity
    fillColor: pickQuintilleColor(properties),
    fill: pickQuintilleColor(properties),
  });
  const defaultHighlightStyleFn = () => ({
    stroke: true,
    color: "#ffffff", // stroke colour
    weight: HOVER_LW,
    fillOpacity: HOVER_OPACITY,
  });
  const renderFieldValue = (field, value) => {
    if (field === "percent_in") {
      const number = Number(value);
      return isNaN(number) ? "-" : `${(number * 100).toFixed()}%`;
    }
    return value;
  };
  const tooltipRenderer = (fields, aliases) => (properties) => `
    <div>
      ${fields
        .map((field, idx) => {
          const wrapper = idx === 0 ? "b" : "span";
          return `
          <p style="margin: 0; font-size: 1rem;">
            <${wrapper}>${aliases[idx]}: ${renderFieldValue(field, properties[field])}</${wrapper}>
          </p>
        `;
        })
        .join("")}
    </div>
  `;
  const vectorLayersConfig = [
    {
      key: "sa1_21_map",
      name: "SA1 - Population Near Parks",
      defaultChecked: true,
      url: "https://seer-maps.s3.ap-southeast-2.amazonaws.com/cta/parks/sa1_21_map/{z}/{x}/{y}.pbf",
      vectorTileLayerStyles: { sa1_21_map: defaultStyle },
      highlightStyleFn: defaultHighlightStyleFn,
      maxNativeZoom: 16,
      idProperty: "SA1_CODE21",
      interactive: true,
      tooltipContent: tooltipRenderer(
        ["percent_in", "SA2_NAME21", "SA1_CODE21", "population_total", "population_0_2", "population_0_5"],
        [
          "Percent population near parks",
          "SA2 NAME",
          "SA1 CODE",
          "SA1 Total Population",
          "SA1 - Population Aged 0-2 years",
          "SA1 - Population Aged 0-5 years",
        ],
      ),
    },
    {
      key: "aoi_gdf_map",
      name: "Play & Park Areas",
      defaultChecked: true,
      url: "https://seer-maps.s3.ap-southeast-2.amazonaws.com/cta/aoi_gdf_map/{z}/{x}/{y}.pbf",
      vectorTileLayerStyles: {
        aoi_gdf_map: () => ({
          weight: DEFAULT_LW,
          fillOpacity: DEFAULT_OPACITY,
          opacity: 1, // line opacity
          fillColor: tasAOIColor,
          fill: tasAOIColor,
          color: tasAOIColor,
        }),
      },
      highlightStyleFn: defaultHighlightStyleFn,
      maxNativeZoom: 16,
      idProperty: "OBJECTID",
      interactive: true,
      tooltipContent: tooltipRenderer(["NAME", "INFTY2_TXT"], ["Name", "Type"]),
    },
  ];

  // custom component purely for watching for map events as eventHandlers not working on some components in this version of react-leaflet
  const vectorNameKeyMap = vectorLayersConfig.reduce((prev, next) => ({ ...prev, [next.name]: next.key }), {});
  const vectorKeyOrder = vectorLayersConfig.map((config) => config.key);
  const WatchLayerChanges = () => {
    const map = useLeafletContext().map;
    React.useEffect(() => {
      const overlayaddListener = (e) => {
        const newValue = [...visibleLayers, vectorNameKeyMap[e.name]];
        newValue.sort((a, b) => vectorKeyOrder.indexOf(a) - vectorKeyOrder.indexOf(b)); // sort same as config order rather than appended
        setVisibleLayers(newValue);
      };
      const overlayremoveListener = (e) => setVisibleLayers(visibleLayers.filter((key) => key !== vectorNameKeyMap[e.name]));
      map.on({
        overlayadd: overlayaddListener,
        overlayremove: overlayremoveListener,
      });
      return () => {
        map.off({
          overlayadd: overlayaddListener,
          overlayremove: overlayremoveListener,
        });
      };
    }, []);
    return null;
  };

  return (
    <>
      <Helmet>
        <body className="hide-header-and-hubspot" />
        <title>Communities of Tasmania Parks Map</title>
      </Helmet>

      <Map
        center={DEFAULT_MAP_CENTRE}
        zoom={DEFAULT_ZOOM}
        minZoom={DEFAULT_ZOOM}
        maxZoom={16}
        maxBounds={MAP_BOUNDS}
        // style={{ background: colorPalette.background }}
        className="m-0 w-100 h-100"
      >
        <FullScreenControl />
        <GeoSearch provider={null} showMarker={false} autoClose position="topright" style="button" />
        <BottomLeftContent>
          {/* legend content */}
          {!!visibleLayers.length && (
            <ContentBox>
              {/* for open layers, map out the legend*/}
              {visibleLayers.map((layer_name, idx) => {
                const legendConfig = legends[layer_name];
                const multiColor = !!legendConfig.colors;
                return (
                  <div key={layer_name}>
                    {multiColor ? (
                      <>
                        <p className="mb-1">{legendConfig.label}</p>
                        <div className="d-flex mb-1">
                          {legendConfig.colors.map((color) => (
                            <div style={{ height: 15, width: 1, background: color }} key={color.replace(/\W/, "")} />
                          ))}
                        </div>
                        <p className="m-0">Low to high</p>
                      </>
                    ) : (
                      <div className="d-flex">
                        <div className="mr-2" style={{ height: 15, width: 15, background: legendConfig.color }} />
                        <p className="m-0">{legendConfig.label}</p>
                      </div>
                    )}
                    {idx !== visibleLayers.length - 1 && <hr />}
                  </div>
                );
              })}
            </ContentBox>
          )}
        </BottomLeftContent>
        <WatchLayerChanges />

        {/* only one base tile map */}
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
          attribution={
            '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>'
          }
        />

        {/* layers */}
        <LayersControl position="bottomright">
          {/* base tile maps - no longer required just kept in for now for urls in case we need them elsewhere */}
          {/* <LayersControl.BaseLayer name="Satellite">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Terrain">
            <TileLayer
              url="https://stamen-tiles.a.ssl.fastly.net/terrain/{z}/{x}/{y}.jpg"
              attribution={'Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>.'}
            />
          </LayersControl.BaseLayer> */}

          {/* vector tiles */}
          {vectorLayersConfig.map((config) => {
            const { name, key, defaultChecked, ...rest } = config;
            return (
              <LayersControl.Overlay name={name} checked={defaultChecked} key={key}>
                <VectorTileLayer {...rest} />
              </LayersControl.Overlay>
            );
          })}
        </LayersControl>
      </Map>
    </>
  );
};
