import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Card, Form, Grid } from "semantic-ui-react";
import { Field, Form as FinalForm } from "react-final-form";
import { includes } from "lodash";
import Store from "common/store";
import { collator, sortDimensionValues, transformResToDims } from "common/helpers/data";
import { datasets, getMixpanel } from "common/api";
import { ls } from "common/helpers/storage";
import { FieldRcSlider } from "./FieldRcSlider";
import { FieldReactSelect } from "./FieldReactSelect";
import { ShowLoaderGlobal } from "component/LoaderGlobal";
import { SMART_INSIGHTS_VARIABLE_MAP } from "pages/SmartInsights/includes/smartInsights";
import { SeerButton } from "pages/PartnerOrganisations/includes/PartnerOrganisationsButtons";

interface ISelectWidget {
  store?: Store;
  builder?: any;
  onSubmit: (values) => Promise<void>;
}

const insightNumberOptions = [
  { label: 2, value: 2 },
  { label: 4, value: 4 },
  { label: 6, value: 6 },
  { label: 8, value: 8 },
  { label: 10, value: 10 },
];

const initialStateValue = {
  variable: null,
  category: null,
  interestingness: 100,
  numberOfInsights: { label: 6, value: 6 },
};

const SelectWidgetComponent = ({ store, builder, onSubmit }: ISelectWidget): JSX.Element => {
  const [categoryOptions, setCategoryOptions] = React.useState([]);
  const [isCategoryLoading, setIsCategoryLoading] = React.useState(false);
  const [stateValue, setStateValue] = React.useState(initialStateValue);
  const [dataLoading, setDataLoading] = React.useState(false);

  const { initialiseGlobalDimensions, getAllDatasets, reloadQuickStat } = builder.loading;
  const isInitLoading = initialiseGlobalDimensions || getAllDatasets;

  const getWhereDimensions = (sourceDimensions): any =>
    Object.fromEntries(Object.entries(sourceDimensions).filter(([, value]: [any, any]) => value.type === "Where"));
  // Use `builder.globalDimensions` for variables options to make sure we always show all possible dimensions to users all the time
  const variableDimensions = getWhereDimensions(builder.globalDimensions);
  const variableOptions = Object.keys(variableDimensions)
    .filter((dim) => Object.keys(SMART_INSIGHTS_VARIABLE_MAP).indexOf(dim) >= 0)
    .sort((a, b) => collator.compare(a, b))
    .map((dimension) => ({
      label: dimension,
      value: dimension,
      source: variableDimensions[dimension].source,
    }));

  const getCategoryOptions = async (variable) => {
    setIsCategoryLoading(true);
    const params = {
      filters: { [variable.value]: [] },
      datasets: builder.datasetsToQuery.filter((dataset) => includes(Object.keys(variable.source), dataset)),
    };
    const res: any = await datasets.post("v2/qs", params, builder.parent.token ?? "");
    const newDimensions = sortDimensionValues(transformResToDims(res));
    const categoryOptions = getWhereDimensions(newDimensions)[variable.value]?.values.map((value) => ({
      label: value.name,
      value: value.name,
    }));
    setIsCategoryLoading(false);
    return categoryOptions;
  };

  const initCategoryOptions = async (variableOption) => {
    const categoryOptions = await getCategoryOptions(variableOption);
    setCategoryOptions(categoryOptions);
  };

  React.useEffect(() => {
    const smartInsightsQuery = JSON.parse(ls.getItem("smartInsightsQuery"));
    if (smartInsightsQuery) {
      setStateValue(smartInsightsQuery);
      if (smartInsightsQuery.variable) {
        initCategoryOptions(smartInsightsQuery.variable);
      }
    }
  }, []);

  return (
    <>
      {isInitLoading && <ShowLoaderGlobal />}
      <FinalForm
        onSubmit={async (values) => {
          setDataLoading(true);
          await onSubmit(values);
          setDataLoading(false);
        }}
        initialValues={stateValue}
        keepDirtyOnReinitialize
        render={({ handleSubmit, form }) => {
          const { getFieldState, change, reset, setConfig } = form;
          const variableMeta: any = getFieldState("variable");
          const categoryMeta: any = getFieldState("category");
          return (
            <Card className="w-100 p-4">
              <Form onSubmit={handleSubmit}>
                <Grid columns={3} className="mb-2">
                  <Grid.Row>
                    <Grid.Column>
                      <Field
                        name="variable"
                        label="Your Location"
                        placeholder="Search and select"
                        isDisabled={reloadQuickStat}
                        isLoading={reloadQuickStat}
                        isClearable
                        options={variableOptions}
                        component={FieldReactSelect}
                        onChangeCallback={async (variableOption) => {
                          // Clear selected "categories" dropdown when "variable" dropdown changes
                          change("category", null);
                          if (variableOption) {
                            await initCategoryOptions(variableOption);
                          } else {
                            setCategoryOptions([]);
                          }
                        }}
                      />
                      <Field
                        name="category"
                        placeholder="Search and select"
                        isDisabled={!variableMeta?.value || isCategoryLoading}
                        isLoading={isCategoryLoading}
                        isClearable
                        options={categoryOptions}
                        component={FieldReactSelect}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field name="interestingness" label="Interestingness" component={FieldRcSlider} />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        name="numberOfInsights"
                        label="Number of Insights"
                        options={insightNumberOptions}
                        component={FieldReactSelect}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <div className="d-flex justify-content-end">
                  <SeerButton
                    category="tertiary"
                    label="Reset"
                    onClick={() => {
                      // Workaround to "reset" to initial values but still keep values after submit
                      setConfig("keepDirtyOnReinitialize", false);
                      reset();
                      setConfig("keepDirtyOnReinitialize", true);
                      getMixpanel(store!).track("Smart Insights > Reset");
                    }}
                    style={{ marginRight: 20 }}
                    type="button"
                  />
                  <SeerButton
                    category="primary"
                    label="Generate"
                    icon="magic"
                    disabled={!variableMeta?.value || !categoryMeta?.value || reloadQuickStat || isCategoryLoading || dataLoading}
                    onClick={handleSubmit}
                  />
                </div>
              </Form>
            </Card>
          );
        }}
      />
    </>
  );
};

export const SelectWidget = inject((stores: any) => ({
  store: stores.store,
  builder: stores.store.builder,
}))(observer(SelectWidgetComponent));
