import * as React from "react";
// see https://github.com/facebook/react/issues/10231#issuecomment-316644950
import { unstable_batchedUpdates } from "react-dom"; // @TODO - remove once we upgrade to react 17+, current version doesn't support batched state updates in async
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";
import { Prompt, useHistory, useLocation, useParams } from "react-router-dom";
import * as qs from "qs";
import * as cheerio from "cheerio";
import { Menu, Dropdown, Icon, Button, Confirm, ButtonGroup } from "semantic-ui-react";

import Editable from "component/Editable";
import { getMixpanel, database } from "common/api";
import { DashboardUnlayerEditor } from "component/Unlayer/DashboardUnlayerEditor";
import { ShowLoaderGlobal } from "component/LoaderGlobal";
import { ContentPlaceholder } from "component/ContentPlaceholder";
import { ManageAccessModal } from "component/ManageAccess/ManageAccessModal";
import { Breadcrumbs } from "component/Breadcrumbs/Breadcrumbs";
import { getSortedDashboardGroups, getSortedDashboardUsers } from "common/helpers/dashboard";
import Store from "common/store";
import { DashboardHtml } from "./includes/DashboardHtml";
import { Collaborate } from "pages/Dashboard/includes/DashboardCollaborate/Collaborate";
import { DashboardWrapper } from "./includes/Dashboard.style";
import { getDashboardCollaborators } from "./includes/DashboardCollaborate/includes/helper";
import { dashboardAccessLevel } from "common/store/dashboard";
import { DashboardSettingsModal } from "./includes/DashboardCollaborate/includes/DashboardSettingsModal";
import { MetaInfoBar } from "component/MetaInfoBar/MetaInfoBar";
import { DashboardEmptyState } from "./includes/DashboardEmptyState";
import { DashboardPopup, StandardPopupContent } from "./includes/DashboardPopup";
import { COLORS } from "component/UI/common";
import { ModalFormsTOS } from "./includes/ModalFormsTOS";
import { ModalFormsDownload } from "./includes/ModalFormsDownload";
import { LockBanner } from "./includes/LockBanner";
import { getMaxImageWidthPercent, getPageTitles } from "./includes/helpers";
import { ModalNavConfig } from "./includes/ModalNavConfig";
import { imageUploadHandler } from "component/Unlayer/CustomImageLibrary";

interface IDashboard {
  store?: Store;
  dashboardStore?: any;
}

const ACTIONS_DROPDOWN_OPTIONS = {
  owner: [
    { key: 1, text: "Manage access", value: "Manage access" },
    { key: 3, text: "Duplicate", value: "Duplicate", id: "userflow-element-dashboard-actions-duplicate" },
    { key: 2, text: "Delete", value: "Delete" },
  ],
  write: [
    { key: 1, text: "Manage access", value: "Manage access" },
    { key: 2, text: "Duplicate", value: "Duplicate", id: "userflow-element-dashboard-actions-duplicate" },
  ],
  read: [{ key: 1, text: "Duplicate", value: "Duplicate", id: "userflow-element-dashboard-actions-duplicate" }],
  curated: [{ key: 1, text: "Duplicate", value: "Duplicate", id: "userflow-element-dashboard-actions-duplicate" }],
};

const ifOwnerOrEditor = (user_access: dashboardAccessLevel): boolean => user_access === "owner" || user_access === "write";

const curatedDashboard = (user_access: dashboardAccessLevel): boolean => user_access === null;

// modify the embed insights src and return the dashboard html
const getEmbedInsightsModifiedHTML = (html: string, urlParams: { key: string; value: boolean }[]): string => {
  const $ = cheerio.load(html as string);
  $("iframe.seer-dashboards-insight").each((_index, embedInsight) => {
    const src = $(embedInsight).attr("src")!;
    const path = src.slice(0, src.indexOf("?"));
    const queryStr = src.slice(src.indexOf("?") + 1);
    const query = qs.parse(queryStr);
    for (const urlParam of urlParams) {
      if (urlParam.value) {
        query[urlParam.key] = urlParam.value.toString();
      } else {
        delete query[urlParam.key];
      }
    }
    $(embedInsight).attr("src", `${path}?${qs.stringify(query)}`);
  });
  return $.root().html()!;
};

// for when someone tries to close tab / browser during editing
const closeTabConfirm = (e) => e.preventDefault();

const DashboardComponent = (props: IDashboard): JSX.Element => {
  const dashboardStore = props.dashboardStore!;
  const { selectionOverlay, dashboardSelection } = dashboardStore;
  const store = props.store!;
  const { dashboardID, publicKey } = useParams<{ dashboardID: string; publicKey: string }>();
  const { userPlan, user } = store;
  const canUseDashboardFunctions =
    userPlan === "Plus" || userPlan === "Unlimited" || user?.group.add_ons.map((addOn) => addOn.name).includes("Dashboards");
  const location = useLocation();
  const { search } = location;
  const { openUnlayer } = qs.parse(search.slice(1) || "");

  const [data, setData] = React.useState<any>(null);
  const [dashboardUsers, setDashboardUsers] = React.useState<any[]>([]); // Users who have full, write or read access
  const [showUnlayerEditor, setShowUnlayerEditor] = React.useState(false);
  const [deleteDashboardConfirmModal, setDeleteDashboardConfirmModal] = React.useState(false);
  const [cancelEditConfirmModal, setCancelEditConfirmModal] = React.useState(false);
  const [showManageAccessModal, setShowManageAccessModal] = React.useState(false);
  const [showDashboardSettingsModal, setShowDashboardSettingsModal] = React.useState(false);
  const [showNavigationSettingsModal, setShowNavigationSettingsModal] = React.useState(false);
  const [formsTOSModalOpen, setFormsTOSModalOpen] = React.useState(false);
  const [formsDownloadModalOpen, setFormsDownloadModalOpen] = React.useState(false);
  const [showCollaborate, setShowCollaborate] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [error, setError] = React.useState("");
  const [lockId] = React.useState(`${Date.now()}`);
  const [lockData, setLockData] = React.useState<any>();
  const [refreshLockTracking, setRefreshLockTracking] = React.useState(false); // bool for are we tracking mouse movement
  const [pageIdx, setPageIdx] = React.useState(0);
  const [modifiedUnlayerConfig, setModifiedUnlayerConfig] = React.useState<any>(undefined);
  const [unlayerReady, setUnlayerReady] = React.useState(false); // is unlayer design loaded so we can enable editing buttons

  const history = useHistory();
  const {
    name,
    unlayer_config,
    link_share,
    key,
    user_access,
    groups,
    users,
    read_only_duplicate,
    embed_insight_click_through,
    allow_insight_downloads,
    creator,
    updated_at,
  } = data || {};
  const activePageData = (showUnlayerEditor ? modifiedUnlayerConfig : unlayer_config)?.pages?.[pageIdx] || undefined;
  const pageTitles = getPageTitles(showUnlayerEditor ? modifiedUnlayerConfig : unlayer_config);
  const sortedGroups = getSortedDashboardGroups(groups || [], store!.user?.group?.id);
  const sortedUsers = getSortedDashboardUsers(users || []);
  const embedInsightsURLParams = [
    { key: "enableInsightClickThrough", value: embed_insight_click_through },
    { key: "allowInsightDownloads", value: allow_insight_downloads },
  ];
  const modifiedHTML = activePageData?.html
    ? getEmbedInsightsModifiedHTML(activePageData?.html as string, embedInsightsURLParams)
    : activePageData?.html;

  const getActionsDropdownOptions = (): any[] => {
    if (curatedDashboard(user_access)) {
      return ACTIONS_DROPDOWN_OPTIONS["curated"]; // Curated Dashboard
    } else {
      if (canUseDashboardFunctions) {
        if (ifOwnerOrEditor(user_access)) {
          const actions = [...ACTIONS_DROPDOWN_OPTIONS[user_access!]];
          if (user_access === "owner") {
            actions.push({
              key: 1001,
              text: `${user!.form_terms_accepted ? "Download" : "Enable"} Form Submissions`,
              value: "Forms Download",
            });
            return actions;
          }
          return ACTIONS_DROPDOWN_OPTIONS[user_access!];
        } else {
          if (read_only_duplicate) {
            return ACTIONS_DROPDOWN_OPTIONS["read"];
          } else {
            return [];
          }
        }
      } else {
        return [];
      }
    }
  };
  const actionsDropdownOptions = getActionsDropdownOptions();

  const unlayerRef = React.useRef<any>();
  const refreshLockTimeoutRef = React.useRef<any>(); // for timing lock refreshing during editing
  const checkLockTimeoutRef = React.useRef<any>(); // for refreshing lock data at existing lock expiry regardless of editing status

  // for errors during dashboard locking that require a page refresh to continue
  const lockError = (msg) => {
    setError(msg);
    if (refreshLockTimeoutRef.current) {
      clearTimeout(refreshLockTimeoutRef.current);
    }
    if (checkLockTimeoutRef.current) {
      clearTimeout(checkLockTimeoutRef.current);
    }
    setRefreshLockTracking(false);
    setShowUnlayerEditor(false);
    setShowNavigationSettingsModal(false);
  };

  const editDashboardNameHandler = async (name) => {
    const success = await dashboardStore.updateDashboard(+dashboardID, { name });
    if (success) {
      getMixpanel(store).track("Change Dashboard Name", { Page: "Dashboard" });
      await loadDashboard();
    }
  };

  const openUnlayerClickHandler = async () => {
    // if user has not actioned forms terms, open the modal first to force an answer
    if (typeof user!.form_terms_accepted !== "boolean") {
      return setFormsTOSModalOpen(true);
    }
    // if existing lock is being overridden, confirm with user
    let confirmOverride = false;
    if (lockData && lockData.user_id === store.user?.id) {
      confirmOverride = confirm("Confirm you wish to override your changes from a separate tab/device.");
      if (!confirmOverride) {
        return;
      }
    }
    setShowLoader(true);
    // check no new locks are in place by different users
    const lockRes: any = await database.get(`dashboards/${dashboardID}/lock`, null, store.token!);
    const lock = lockRes?.body?.data?.lock;
    if (lock) {
      let abort = false;
      if (lock.user_id !== store.user?.id) {
        abort = true;
      } else if (lock.lock_id !== lockId && !confirmOverride) {
        abort = !confirm("Confirm you wish to override your changes from a separate tab/device.");
      }
      if (abort) {
        setShowLoader(false);
        return setLockData(lock);
      }
    }
    // refresh dashboard data before edit
    const dashRes: any = await dashboardStore.getDashboard(+dashboardID, publicKey);
    if (dashRes?.dashboard) {
      setData(dashRes.dashboard);
      setModifiedUnlayerConfig(dashRes.dashboard.unlayer_config);
    }
    // set new lock
    const ack: any = await new Promise((resolve) => {
      store.socket.emit("lockDashboard", { user_id: store.user?.id, dashboard_id: dashboardID, lock_id: lockId }, (res) => resolve(res));
    });
    if (ack.ok) {
      setLockData({ user_id: store.user?.id, lock_id: lockId, lock_ends_at: ack.lock_ends_at });
    } else {
      setShowLoader(false);
      return alert("Something went wrong, please refresh the page and try again.");
    }
    setShowUnlayerEditor(true);
    getMixpanel(store).track("Open Unlayer", { Page: "Dashboard" });
    setShowLoader(false);
    setRefreshLockTracking(true);
  };

  const setEmbedInsightClickThrough = async (value: boolean): Promise<void> => {
    const success = await dashboardStore.updateDashboard(+dashboardID, { embed_insight_click_through: value });
    if (success) {
      await loadDashboard();
      getMixpanel(store).track("Enable Embed Insight Click Through", { Enabled: value ? "Yes" : "No" });
    }
  };

  const setAllowInsightDownloads = async (value: boolean): Promise<void> => {
    const success = await dashboardStore.updateDashboard(+dashboardID, { allow_insight_downloads: value });
    if (success) {
      await loadDashboard();
      getMixpanel(store).track("Allow Insight Downloads", { Enabled: value ? "Yes" : "No" });
    }
  };

  const closeUnlayerClickHandler = () => {
    setCancelEditConfirmModal(false);
    setRefreshLockTracking(false);
    store.socket.emit("unlockDashboard", { user_id: store.user?.id, dashboard_id: dashboardID, lock_id: lockId });
    setLockData(undefined);
    setShowUnlayerEditor(false);
    setPageIdx(unlayer_config.navigation?.includedPages ? unlayer_config.navigation.includedPages.indexOf(true) : 0); // set page to first visible
    getMixpanel(store).track("Close Unlayer", { Page: "Dashboard" });
  };

  // Export Unlayer Data for active page (json and html), note this does not include page id etc
  const exportPageData = async () => {
    const activePageData = await new Promise((resolve) => {
      unlayerRef.current.editor.exportHtml(
        (data: any) => {
          let dashboardConfig: any = {};
          let dashboardHtml: any = "";
          // Check all rows and all columns in each row to see if there is content
          if (!data.design.body.rows.every((row) => row.columns.every((column) => column.contents.length === 0))) {
            dashboardConfig = data.design; // For editing in Unlayer
            const { backgroundColor, backgroundImage, fontFamily, fontWeight = "normal" } = dashboardConfig.body.values;
            const { url, size, repeat, position } = backgroundImage;
            const dashboardContainerID = "dashboardContainer";
            dashboardHtml = `
            <div id=${dashboardContainerID}>
              ${data.chunks.body}
            </div>
            <style>${data.chunks.css}</style>
            <style>.u_body {min-height: auto !important; background-color: ${backgroundColor}; background-image: url(${url}); background-position: ${position.replace("-", " ")}; background-size: ${size}; background-repeat: ${repeat}}</style>
            <style>a {text-decoration: none} a:hover {text-decoration: none}</style>
            <style>#${dashboardContainerID}, #${dashboardContainerID} h1, #${dashboardContainerID} h2, #${dashboardContainerID} h3, #${dashboardContainerID} h4 { font-family: ${fontFamily.value}; font-weight: ${fontWeight} }</style>
            ${
              !!data.chunks.fonts.length &&
              data.chunks.fonts
                .filter((font) => font.url)
                .map((font) => `<link href="${font.url}" rel="stylesheet" />`)
                .join("\n")
            }
          `; // For displaying in Summary section
          }
          resolve({ json: dashboardConfig, html: dashboardHtml });
        },
        {
          cleanup: true,
        },
      );
    });
    return activePageData;
  };

  const saveDashboardHandler = async () => {
    setRefreshLockTracking(false);
    setShowLoader(true);
    const ack: any = await new Promise((resolve) => {
      store.socket.emit("unlockDashboard", { user_id: store.user?.id, dashboard_id: dashboardID, lock_id: lockId }, (res) => resolve(res));
    });
    // this should never occur but is here as a safe-guard if anything goes wrong with the lock refresh mechanism in the timeout set during editing
    if (ack && !ack.ok) {
      return lockError(
        "You have been locked out of edit mode due to inactivity and have lost unsaved edits on this dashboard. Another user is currently editing. Please refresh the page.",
      );
    }
    const activePageData = await exportPageData();
    const payload = { unlayer_config: modifiedUnlayerConfig };
    payload.unlayer_config.pages[pageIdx] = { ...payload.unlayer_config.pages[pageIdx], ...activePageData! };
    setModifiedUnlayerConfig(payload.unlayer_config);
    const success = await dashboardStore.updateDashboard(+dashboardID, payload);
    if (success) {
      getMixpanel(store).track("Save Unlayer", { Page: "Dashboard" });
      await loadDashboard();
      // wait till dashboard data is reloaded before displaying html again as this can interfere with filter mounting
      setShowUnlayerEditor(false);
      setPageIdx(unlayer_config.navigation?.includedPages ? unlayer_config.navigation.includedPages.indexOf(true) : 0); // set page to first visible
      setShowLoader(false);
    }
  };

  const saveDashNavConfig = async (navigation) => {
    setRefreshLockTracking(false);
    setShowLoader(true);
    const ack: any = await new Promise((resolve) => {
      store.socket.emit("unlockDashboard", { user_id: store.user?.id, dashboard_id: dashboardID, lock_id: lockId }, (res) => resolve(res));
    });
    // this should never occur but is here as a safe-guard if anything goes wrong with the lock refresh mechanism in the timeout set during editing
    if (ack && !ack.ok) {
      return lockError(
        "You have been locked out of edit mode due to inactivity and have lost unsaved edits on this dashboard. Another user is currently editing. Please refresh the page.",
      );
    }

    const payload = { unlayer_config: { ...unlayer_config, navigation } };
    const success = await dashboardStore.updateDashboard(+dashboardID, payload);
    if (success) {
      getMixpanel(store).track("Update Dashboard Pages Navigation", {
        "Dashboard Id": dashboardID,
        "Dashboard Name": name,
        "Custom Nav": navigation.customNav,
      });
      if (
        (navigation?.customNav && navigation?.customNavConf?.position === "custom") ||
        (unlayer_config.navigation.customNav && unlayer_config.navigation.customNavConf?.position === "custom")
      ) {
        // reload page instead when custom positioned nav was or is coming into effect to render update immediately
        window.location.reload();
        return;
      }
      await loadDashboard();
      setShowNavigationSettingsModal(false);
      setPageIdx(navigation.includedPages.indexOf(true)); // set page to first visible
      setShowLoader(false);
    }
  };

  const shareClickHandler = () => {
    setShowManageAccessModal(true);
    getMixpanel(store).track("Open Manage Access", { Page: "Dashboard", Button: "Share" });
  };

  const collaborateClickHandler = () => {
    setShowCollaborate(true);
    getMixpanel(store).track("Open Collaborate", {
      Page: "Dashboard",
      Button: "Collaborate",
      "Dashboard ID": dashboardID,
      "Dashboard Name": name,
    });
  };

  const actionDropdownHandler = async (_, e) => {
    const { value } = e;
    if (value === "Delete") {
      setDeleteDashboardConfirmModal(true);
    } else if (value === "Manage access") {
      getMixpanel(store).track("Open Manage Access", { Page: "Dashboard", Button: "Actions" });
      setShowManageAccessModal(true);
    } else if (value === "Duplicate") {
      const dashboardConfig = {
        name: `Copy of ${name}`,
        unlayer_config,
      };
      const res = await dashboardStore.createDashboard(dashboardConfig);
      if (res?.dashboard?.id) {
        getMixpanel(store).track("Duplicate Dashboard", { Page: "Dashboard", Button: "Actions" });
        history.push(`/dashboards/${res.dashboard.id}`);
      }
    } else if (value === "Forms Download") {
      const { form_terms_accepted } = user!;
      if (!form_terms_accepted) {
        setFormsTOSModalOpen(true);
      } else {
        setFormsDownloadModalOpen(true);
      }
    }
  };

  const publicLinkChangeHandler = async (value) => {
    const success = await dashboardStore.updateDashboard(+dashboardID, { link_share: value });
    if (success) {
      getMixpanel(store).track("Update Dashboard Public Share", { "Link Share": value });
      await loadDashboard();
    }
  };

  const accessChangeHandler = async (type, id, accessLevel) => {
    if (accessLevel === "remove") {
      const success = await dashboardStore.deleteDashboardAccess(+dashboardID, type, id);
      if (success) {
        getMixpanel(store).track("Delete Dashboard Access", { Type: type === "groups" ? "Organisations" : "Members" });
        await loadDashboard();
        return true;
      } else {
        return false;
      }
    } else {
      const success = await dashboardStore.updateDashboardAccess(+dashboardID, type, id, accessLevel);
      if (success) {
        getMixpanel(store).track("Update Dashboard Access Level", {
          Type: type === "groups" ? "Organisations" : "Members",
          "Access Level": accessLevel,
        });
        await loadDashboard();
        return true;
      } else {
        return false;
      }
    }
  };

  const accessAddHandler = async (type, ids, accessLevel) => {
    const success = await dashboardStore.addDashboardAccess(+dashboardID, type, ids, accessLevel);
    if (success) {
      getMixpanel(store).track("Add Dashboard Access", {
        Type: type === "groups" ? "Organisations" : "Members",
        "Access Level": accessLevel,
      });
      await loadDashboard();
      return true;
    } else {
      return false;
    }
  };

  const readOnlyDuplicateChangeHandler = async (value: boolean): Promise<void> => {
    const success = await dashboardStore.updateDashboard(+dashboardID, { read_only_duplicate: value });
    if (success) {
      getMixpanel(store).track("Update Dashboard Read-only Duplicate", { "Read-only Duplicate": value });
      await loadDashboard();
    }
  };

  const closeManageAccessModalHandler = () => {
    getMixpanel(store).track("Close Manage Access", { Page: "Dashboard" });
    setShowManageAccessModal(false);
  };

  const deleteDashboardHandler = async () => {
    const success = await dashboardStore.deleteDashboard(+dashboardID);
    if (success) {
      getMixpanel(store).track("Delete Dashboard");
      history.push("/dashboards");
    }
  };

  const loadDashboard = async (forceCheckURL = false) => {
    setShowLoader(true);
    let loadLock = false;
    const res: any = await dashboardStore.getDashboard(+dashboardID, publicKey);
    if (res?.dashboard) {
      if (!data || data.id !== res.dashboard.id) {
        getMixpanel(store).track("Page view", {
          Page: "Dashboard",
          "Dashboard Name": res.dashboard.name,
          "Dashboard ID": res.dashboard.id,
        });
      }
      const { users, groups, comments, actions, user_access } = res.dashboard;
      setData(res.dashboard);
      setDashboardUsers(getDashboardCollaborators(user!, (users as any[]) || [], (groups as any[]) || []));
      dashboardStore.setDashboardComments(comments);
      dashboardStore.setDashboardActions(actions);
      if (forceCheckURL && openUnlayer && ifOwnerOrEditor(user_access as dashboardAccessLevel)) {
        await openUnlayerClickHandler();
      }
      if (ifOwnerOrEditor(user_access as dashboardAccessLevel)) {
        loadLock = true;
      }
    } else {
      setError("You do not have access to this Dashboard or it doesn't exist.");
    }
    if (loadLock) {
      const lockRes: any = await database.get(`dashboards/${dashboardID}/lock`, null, store.token!);
      setLockData(lockRes?.body?.data?.lock);
    }
    setShowLoader(false);
  };

  const unlockOnUnmount = () => {
    if (store.user?.id) {
      // we don't care if the user has access or not here, this is a safeguard in case user leaves the dashboard abruptly, it will fail if not applicable
      store.socket.emit("unlockDashboard", { user_id: store.user?.id, dashboard_id: dashboardID, lock_id: lockId });
    }
  };

  const openNavSettingsModal = async () => {
    // if existing lock is being overridden, confirm with user
    let confirmOverride = false;
    if (lockData && lockData.user_id === store.user?.id) {
      confirmOverride = confirm("Confirm you wish to override your changes from a separate tab/device.");
      if (!confirmOverride) {
        return;
      }
    }
    setShowLoader(true);
    // check no new locks are in place by different users
    const lockRes: any = await database.get(`dashboards/${dashboardID}/lock`, null, store.token!);
    const lock = lockRes?.body?.data?.lock;
    if (lock) {
      let abort = false;
      if (lock.user_id !== store.user?.id) {
        abort = true;
      } else if (lock.lock_id !== lockId && !confirmOverride) {
        abort = !confirm("Confirm you wish to override your changes from a separate tab/device.");
      }
      if (abort) {
        setShowLoader(false);
        return setLockData(lock);
      }
    }
    // refresh dashboard data before edit
    const dashRes: any = await dashboardStore.getDashboard(+dashboardID, publicKey);
    if (dashRes?.dashboard) {
      setData(dashRes.dashboard);
    }
    // set new lock
    const ack: any = await new Promise((resolve) => {
      store.socket.emit("lockDashboard", { user_id: store.user?.id, dashboard_id: dashboardID, lock_id: lockId }, (res) => resolve(res));
    });
    if (ack.ok) {
      setLockData({ user_id: store.user?.id, lock_id: lockId, lock_ends_at: ack.lock_ends_at });
    } else {
      setShowLoader(false);
      return alert("Something went wrong, please refresh the page and try again.");
    }
    setShowNavigationSettingsModal(true);
    setShowLoader(false);
    setRefreshLockTracking(true);
  };

  const closeNavSettingsModal = () => {
    setRefreshLockTracking(false);
    store.socket.emit("unlockDashboard", { user_id: store.user?.id, dashboard_id: dashboardID, lock_id: lockId });
    setLockData(undefined);
    setShowNavigationSettingsModal(false);
  };

  // refresh lock logic during editing
  React.useEffect(() => {
    const refreshLockHandler = async () => {
      if (!refreshLockTimeoutRef.current) {
        // if lock is to expire in less than 9 mins (user inactive) or already expired, try refresh immediately, otherwise wait 10 seconds to avoid too many calls during active editing
        const timeoutDuration = new Date(lockData?.lock_ends_at || 0).getTime() < Date.now() + 1000 * 60 * 9 ? 1 : 1000 * 10;
        refreshLockTimeoutRef.current = setTimeout(async () => {
          if (new Date(lockData?.lock_ends_at || 0).getTime() < Date.now()) {
            // if old lock expires, we have to check if dashboard has been modified and if so the user has lost their changes
            const res: any = await dashboardStore.getDashboard(+dashboardID, publicKey);
            if (res?.dashboard && new Date(res.dashboard.updated_at).getTime() > new Date(updated_at).getTime()) {
              return lockError(
                "You have been locked out of edit mode due to inactivity and have lost unsaved edits on this dashboard due to another user making edits. Please refresh the page.",
              );
            } else {
              // for now, we will assume ok if the request should fail, but ideally we would keep trying and have some loading state until it works
            }
          }
          // attempt to refresh lock
          const ack: any = await new Promise((resolve) => {
            store.socket.emit(
              "lockDashboard",
              { user_id: store.user?.id, dashboard_id: dashboardID, lock_id: lockId, refresh_lock: true },
              (res) => resolve(res),
            );
          });
          if (!ack) {
            return; // this should only happen if the socket connection has failed for some reason, don't assume failure and try again later
          }
          if (!ack.ok) {
            return lockError(
              "You have been locked out of edit mode due to inactivity and have lost unsaved edits on this dashboard. Another user is currently editing. Please refresh the page.",
            );
          } else {
            setLockData({ user_id: store.user?.id, lock_id: lockId, lock_ends_at: ack.lock_ends_at });
          }
          refreshLockTimeoutRef.current = null;
        }, timeoutDuration);
      }
    };
    // we also have to capture mousemove within the unlayer editor frame
    const frameMousemoveHandler = (event: MessageEvent) => {
      if (event.origin === "https://editor.unlayer.com" && event.data?.frameEvent === "mousemove") {
        refreshLockHandler();
      }
    };

    if (refreshLockTracking) {
      window.addEventListener("mousemove", refreshLockHandler);
      window.addEventListener("message", frameMousemoveHandler, false);
    } else {
      window.removeEventListener("mousemove", refreshLockHandler);
      window.removeEventListener("message", frameMousemoveHandler, false);
      if (refreshLockTimeoutRef.current) {
        clearTimeout(refreshLockTimeoutRef.current);
        refreshLockTimeoutRef.current = null;
      }
    }

    return () => {
      window.removeEventListener("mousemove", refreshLockHandler);
      window.removeEventListener("message", frameMousemoveHandler, false);
      if (refreshLockTimeoutRef.current) {
        clearTimeout(refreshLockTimeoutRef.current);
        refreshLockTimeoutRef.current = null;
      }
    };
  }, [refreshLockTracking, lockData]);

  React.useEffect(() => {
    // message handler for update embedded insight & embedded completeness measures heights
    const messageHandler = (event: MessageEvent) => {
      if (event.origin === window.origin && event.data?.iframeHeight) {
        const embedIframes: any = document.querySelectorAll("iframe.seer-dashboards-insight, iframe.seer-dashboard-completeness-measure");
        for (const frame of embedIframes) {
          if (event.source === frame.contentWindow) {
            frame.parentElement.style.height = event.data.iframeHeight + "px";
            break;
          }
        }
      }
    };
    window.addEventListener("message", messageHandler, false);
    window.addEventListener("pagehide", unlockOnUnmount); // pagehide is important here as beforeunload is already hijacked by the react-router prompt
    return () => {
      window.removeEventListener("message", messageHandler, false); // remove event listener on unmount
      window.removeEventListener("pagehide", unlockOnUnmount);
      unlockOnUnmount();
    };
  }, []);

  React.useEffect(() => {
    if (refreshLockTimeoutRef.current) {
      clearTimeout(refreshLockTimeoutRef.current);
    }
    if (checkLockTimeoutRef.current) {
      clearTimeout(checkLockTimeoutRef.current);
    }
    setRefreshLockTracking(false);
    setShowUnlayerEditor(false);
    setShowNavigationSettingsModal(false);
    setModifiedUnlayerConfig(undefined);
    setPageIdx(0);
    loadDashboard(true);
    return () => {
      unlockOnUnmount();
    };
  }, [dashboardID, publicKey]);

  React.useEffect(() => {
    if (showUnlayerEditor) {
      window.addEventListener("beforeunload", closeTabConfirm);
    } else {
      window.removeEventListener("beforeunload", closeTabConfirm);
      setModifiedUnlayerConfig(undefined); // reset edit state on close editor
      setUnlayerReady(false);
    }
  }, [showUnlayerEditor]);

  // refresh dashboard lock when it is scheduled to end
  React.useEffect(() => {
    if (lockData) {
      const refreshTimeoutMs = new Date(lockData.lock_ends_at).getTime() - Date.now();
      checkLockTimeoutRef.current = setTimeout(async () => {
        const lockRes: any = await database.get(`dashboards/${dashboardID}/lock`, null, store.token!);
        const nextLock = lockRes?.body?.data?.lock;
        setLockData(nextLock);
        // if lock expired while user was away check if any changes were made to dash in interim, this should result in loss of current changes
        if (!nextLock && showUnlayerEditor) {
          const res: any = await dashboardStore.getDashboard(+dashboardID, publicKey);
          if (res?.dashboard && new Date(res.dashboard.updated_at).getTime() > new Date(updated_at).getTime()) {
            return lockError(
              "You have been locked out of edit mode due to inactivity and have lost unsaved edits on this dashboard due to another user making edits. Please refresh the page.",
            );
          }
        }
      }, refreshTimeoutMs);
    }
    return () => {
      if (checkLockTimeoutRef.current) {
        clearTimeout(checkLockTimeoutRef.current);
      }
    };
  }, [lockData]);

  React.useEffect(() => {
    if (unlayer_config) {
      let nextPgIdx = 0;
      const includedPages = unlayer_config.navigation?.includedPages;
      if (includedPages) {
        const { page } = qs.parse(search.slice(1) || "");
        if (page !== undefined && includedPages[+page]) {
          // update page index to "page" param if it exists and valid
          nextPgIdx = +page;
        } else {
          // update page index to the first available page, when we load/reload dash
          nextPgIdx = includedPages.indexOf(true);
        }
      }
      if (nextPgIdx !== pageIdx) {
        setPageIdx(nextPgIdx);
      }
    }
  }, [unlayer_config]);

  const lockedEdits = () => lockData && lockData.user_id !== store.user?.id;

  return (
    <>
      {showLoader && <ShowLoaderGlobal />}
      {error ? (
        <h4 className="text-center">{error}</h4>
      ) : (
        <>
          {data && (
            <>
              <Helmet>
                <title>{`Dashboard - ${name}`}</title>
              </Helmet>
              <Prompt when={showUnlayerEditor} message="Are you sure you want to leave? You may have unsaved changes." />
              <Confirm
                open={deleteDashboardConfirmModal}
                header="Are you sure?"
                content={`Are you sure you want to delete - ${name}`}
                confirmButton="Yes, delete"
                onCancel={() => setDeleteDashboardConfirmModal(false)}
                onConfirm={deleteDashboardHandler}
              />
              {!publicKey && (
                <ManageAccessModal
                  usedBy="dashboards"
                  isOpen={showManageAccessModal}
                  closeModalHandler={closeManageAccessModalHandler}
                  publicLinkItem={{ id: +dashboardID, linkShare: link_share, key }}
                  publicLinkChangeHandler={publicLinkChangeHandler}
                  accessOrganisations={sortedGroups}
                  accessUsers={sortedUsers}
                  accessChangeHandler={accessChangeHandler}
                  accessAddHandler={accessAddHandler}
                  canReadOnlyDuplicate={read_only_duplicate}
                  readOnlyDuplicateChangeHandler={readOnlyDuplicateChangeHandler}
                />
              )}
              <DashboardSettingsModal
                isOpen={showDashboardSettingsModal}
                onClose={() => setShowDashboardSettingsModal(false)}
                canEmbedInsightClickThrough={embed_insight_click_through}
                setEmbedInsightClickThrough={(value) => setEmbedInsightClickThrough(value)}
                allowInsightDownloads={allow_insight_downloads}
                setAllowInsightDownloads={(value) => setAllowInsightDownloads(value)}
              />
              {formsTOSModalOpen && <ModalFormsTOS closeHandler={() => setFormsTOSModalOpen(false)} />}
              {formsDownloadModalOpen && (
                <ModalFormsDownload dashboardID={dashboardID} closeHandler={() => setFormsDownloadModalOpen(false)} />
              )}
              {showNavigationSettingsModal && (
                <ModalNavConfig
                  id={+dashboardID}
                  publicKey={key}
                  unlayer_config={unlayer_config}
                  imageUploadHandler={imageUploadHandler(store.token!)}
                  cancel={() => closeNavSettingsModal()}
                  save={saveDashNavConfig}
                />
              )}
              <Confirm
                open={cancelEditConfirmModal}
                header="Are you sure you want to cancel editing?"
                content={"All your changes will be discarded."}
                cancelButton="Continue Editing"
                confirmButton="Discard Changes"
                onCancel={() => setCancelEditConfirmModal(false)}
                onConfirm={closeUnlayerClickHandler}
              />

              <div className="d-flex h-100" style={{ marginBottom: -20 }}>
                <div
                  className="flex-grow-1 w-100 d-flex flex-column"
                  style={showCollaborate ? { overflowY: "auto", overflowX: "hidden", height: "calc(100vh - 90px)" } : {}}
                >
                  <div style={{ margin: "0 20px" }}>
                    <div className="d-lg-flex align-items-center justify-content-between">
                      <Breadcrumbs
                        items={
                          publicKey
                            ? []
                            : [
                                { pathname: "/dashboards", label: "Dashboards" },
                                { pathname: "", label: name || "..." },
                              ]
                        }
                      />
                      {!publicKey && <MetaInfoBar updated={updated_at} roleLabel="CREATOR" custodian={creator} />}
                    </div>
                    {/* Dashboard Name and buttons section */}
                    {!publicKey && (
                      <div
                        className="d-lg-flex flex-md-row align-items-start justify-content-between mb-4"
                        id="userflow-element-dashboard-menu-bar"
                      >
                        <div className="mb-3 mb-lg-0 mr-lg-3 w-100">
                          <Editable
                            readOnly={user_access === "read" || curatedDashboard(user_access) || lockedEdits() || showUnlayerEditor}
                            id="dashboardName"
                            text={name}
                            save={(name) => editDashboardNameHandler(name)}
                            style={{
                              color: `${name.includes("Untitled") ? "#919191" : COLORS.indigo600}`,
                              fontWeight: "bold",
                              fontSize: "2.25rem",
                              borderColor: "#C3C3C3",
                              marginRight: 20,
                            }}
                          />
                        </div>
                        {!showCollaborate && (
                          <div className="d-sm-flex flex-shrink-0 mt-3 align-items-start">
                            {showUnlayerEditor ? (
                              <>
                                <div className="mr-6 mb-3 mb-md-0">
                                  <Button
                                    basic
                                    icon="angle left"
                                    disabled={pageIdx === 0 || !unlayerReady}
                                    onClick={async () => {
                                      const activePageData = await exportPageData();
                                      const nextModifiedConfig = { ...modifiedUnlayerConfig, pages: [...modifiedUnlayerConfig.pages] };
                                      nextModifiedConfig.pages[pageIdx] = { ...nextModifiedConfig.pages[pageIdx], ...activePageData! };
                                      unstable_batchedUpdates(() => {
                                        setModifiedUnlayerConfig(nextModifiedConfig);
                                        setPageIdx(pageIdx - 1);
                                        setUnlayerReady(false);
                                      });
                                    }}
                                  />
                                  <Button
                                    basic
                                    icon="angle right"
                                    disabled={pageIdx === modifiedUnlayerConfig!.pages.length - 1 || !unlayerReady}
                                    onClick={async () => {
                                      const activePageData = await exportPageData();
                                      const nextModifiedConfig = { ...modifiedUnlayerConfig, pages: [...modifiedUnlayerConfig.pages] };
                                      nextModifiedConfig.pages[pageIdx] = { ...nextModifiedConfig.pages[pageIdx], ...activePageData! };
                                      unstable_batchedUpdates(() => {
                                        setModifiedUnlayerConfig(nextModifiedConfig);
                                        setPageIdx(pageIdx + 1);
                                        setUnlayerReady(false);
                                      });
                                    }}
                                  />
                                  <Menu compact>
                                    <Dropdown
                                      compact
                                      text={`(Page ${pageIdx + 1}/${modifiedUnlayerConfig!.pages.length}) ${pageTitles[pageIdx]}`}
                                      style={{ padding: "10px 1rem" }}
                                      disabled={!unlayerReady}
                                    >
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={async () => {
                                            const activePageData = await exportPageData();
                                            const nextModifiedConfig = {
                                              ...modifiedUnlayerConfig,
                                              pages: [...modifiedUnlayerConfig.pages],
                                            };
                                            nextModifiedConfig.pages[pageIdx] = {
                                              ...nextModifiedConfig.pages[pageIdx],
                                              ...activePageData!,
                                            };
                                            nextModifiedConfig.pages.splice(pageIdx + 1, 0, { json: {}, html: "", id: Date.now() }); // insert empty page
                                            // update nav config to suit
                                            if (nextModifiedConfig.navigation) {
                                              nextModifiedConfig.navigation.titles.splice(
                                                pageIdx + 1,
                                                0,
                                                `Page ${nextModifiedConfig.pages.length}`,
                                              );
                                              nextModifiedConfig.navigation.includedPages.splice(pageIdx + 1, 0, true);
                                              nextModifiedConfig.navigation.customNavConf.styling.images.splice(pageIdx + 1, 0, {
                                                default: "",
                                                hover: "",
                                              });
                                              // update imageWidthPercent to suit if imageWidthPercent is already set
                                              const imageWidthPercent =
                                                nextModifiedConfig?.navigation?.customNavConf?.styling?.imageWidthPercent;
                                              if (imageWidthPercent) {
                                                const maxImageWidthPercent = getMaxImageWidthPercent(nextModifiedConfig.navigation);
                                                nextModifiedConfig.navigation.customNavConf.styling.imageWidthPercent = Math.min(
                                                  imageWidthPercent,
                                                  maxImageWidthPercent,
                                                );
                                              }
                                            }
                                            unstable_batchedUpdates(() => {
                                              setModifiedUnlayerConfig(nextModifiedConfig);
                                              setPageIdx(pageIdx + 1);
                                              setUnlayerReady(false);
                                            });
                                            getMixpanel(store).track("Add New Dashboard Page", {
                                              "Dashboard Id": dashboardID,
                                              "Dashboard Name": name,
                                            });
                                          }}
                                        >
                                          <Icon name="file outline" /> Add new page
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={async () => {
                                            const activePageData = await exportPageData();
                                            const nextModifiedConfig = {
                                              ...modifiedUnlayerConfig,
                                              pages: [...modifiedUnlayerConfig.pages],
                                            };
                                            nextModifiedConfig.pages[pageIdx] = {
                                              ...nextModifiedConfig.pages[pageIdx],
                                              ...activePageData!,
                                            };
                                            nextModifiedConfig.pages.splice(pageIdx + 1, 0, { ...activePageData!, id: Date.now() }); // insert duplicate
                                            // update nav config to suit
                                            if (nextModifiedConfig.navigation) {
                                              nextModifiedConfig.navigation.titles.splice(
                                                pageIdx + 1,
                                                0,
                                                `Copy of ${nextModifiedConfig.navigation.titles[pageIdx]}`,
                                              );
                                              nextModifiedConfig.navigation.includedPages.splice(pageIdx + 1, 0, true);
                                              nextModifiedConfig.navigation.customNavConf.styling.images.splice(pageIdx + 1, 0, {
                                                default: "",
                                                hover: "",
                                              });
                                              // update imageWidthPercent to suit if imageWidthPercent is already set
                                              const imageWidthPercent =
                                                nextModifiedConfig?.navigation?.customNavConf?.styling?.imageWidthPercent;
                                              if (imageWidthPercent) {
                                                const maxImageWidthPercent = getMaxImageWidthPercent(nextModifiedConfig.navigation);
                                                nextModifiedConfig.navigation.customNavConf.styling.imageWidthPercent = Math.min(
                                                  imageWidthPercent,
                                                  maxImageWidthPercent,
                                                );
                                              }
                                            }
                                            unstable_batchedUpdates(() => {
                                              setModifiedUnlayerConfig(nextModifiedConfig);
                                              setPageIdx(pageIdx + 1);
                                              setUnlayerReady(false);
                                            });
                                            getMixpanel(store).track("Duplicate Dashboard Page", {
                                              "Dashboard Id": dashboardID,
                                              "Dashboard Name": name,
                                            });
                                          }}
                                        >
                                          <Icon name="copy outline" /> Duplicate page
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          disabled={modifiedUnlayerConfig!.pages.length < 2}
                                          onClick={async () => {
                                            const ok = confirm("Confirm you wish to delete the current page.");
                                            if (!ok) {
                                              return;
                                            }
                                            const nextModifiedConfig = {
                                              ...modifiedUnlayerConfig,
                                              pages: [...modifiedUnlayerConfig.pages],
                                            };
                                            nextModifiedConfig.pages.splice(pageIdx, 1); // delete page
                                            // update nav config to suit
                                            if (nextModifiedConfig.navigation) {
                                              nextModifiedConfig.navigation.titles.splice(pageIdx, 1);
                                              nextModifiedConfig.navigation.includedPages.splice(pageIdx, 1);
                                              nextModifiedConfig.navigation.customNavConf.styling.images.splice(pageIdx, 1);
                                            }
                                            unstable_batchedUpdates(() => {
                                              setModifiedUnlayerConfig(nextModifiedConfig);
                                              setPageIdx(Math.max(pageIdx - 1, 0));
                                              setUnlayerReady(false);
                                            });
                                            getMixpanel(store).track("Delete Dashboard Page", {
                                              "Dashboard Id": dashboardID,
                                              "Dashboard Name": name,
                                            });
                                          }}
                                        >
                                          <Icon name="trash alternate outline" /> Delete page
                                        </Dropdown.Item>
                                        <Dropdown item text="Move page">
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              disabled={modifiedUnlayerConfig!.pages.length < 2 || pageIdx === 0}
                                              onClick={async () => {
                                                const activePageData = await exportPageData();
                                                const nextModifiedConfig = {
                                                  ...modifiedUnlayerConfig,
                                                  pages: [...modifiedUnlayerConfig.pages],
                                                };
                                                nextModifiedConfig.pages[pageIdx] = {
                                                  ...nextModifiedConfig.pages[pageIdx],
                                                  ...activePageData!,
                                                };
                                                const [movedPage] = nextModifiedConfig.pages.splice(pageIdx, 1);
                                                nextModifiedConfig.pages.splice(pageIdx - 1, 0, movedPage); // reinsert before
                                                // update nav config to suit
                                                if (nextModifiedConfig.navigation) {
                                                  for (const processArray of [
                                                    nextModifiedConfig.navigation.titles,
                                                    nextModifiedConfig.navigation.includedPages,
                                                    nextModifiedConfig.navigation.customNavConf.styling.images,
                                                  ]) {
                                                    const [movedItem] = processArray.splice(pageIdx, 1);
                                                    processArray.splice(pageIdx - 1, 0, movedItem);
                                                  }
                                                }
                                                unstable_batchedUpdates(() => {
                                                  setModifiedUnlayerConfig(nextModifiedConfig);
                                                  setPageIdx(pageIdx - 1);
                                                });
                                                getMixpanel(store).track("Move Dashboard Page", {
                                                  "Dashboard Id": dashboardID,
                                                  "Dashboard Name": name,
                                                });
                                              }}
                                            >
                                              <Icon name="arrow up" /> Move page up
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              disabled={
                                                modifiedUnlayerConfig!.pages.length < 2 ||
                                                pageIdx === modifiedUnlayerConfig!.pages.length - 1
                                              }
                                              onClick={async () => {
                                                const activePageData = await exportPageData();
                                                const nextModifiedConfig = {
                                                  ...modifiedUnlayerConfig,
                                                  pages: [...modifiedUnlayerConfig.pages],
                                                };
                                                nextModifiedConfig.pages[pageIdx] = {
                                                  ...nextModifiedConfig.pages[pageIdx],
                                                  ...activePageData!,
                                                };
                                                const [movedPage] = nextModifiedConfig.pages.splice(pageIdx, 1);
                                                nextModifiedConfig.pages.splice(pageIdx + 1, 0, movedPage); // reinsert after
                                                // update nav config to suit
                                                if (nextModifiedConfig.navigation) {
                                                  for (const processArray of [
                                                    nextModifiedConfig.navigation.titles,
                                                    nextModifiedConfig.navigation.includedPages,
                                                    nextModifiedConfig.navigation.customNavConf.styling.images,
                                                  ]) {
                                                    const [movedItem] = processArray.splice(pageIdx, 1);
                                                    processArray.splice(pageIdx + 1, 0, movedItem);
                                                  }
                                                }
                                                unstable_batchedUpdates(() => {
                                                  setModifiedUnlayerConfig(nextModifiedConfig);
                                                  setPageIdx(pageIdx + 1);
                                                });
                                                getMixpanel(store).track("Move Dashboard Page", {
                                                  "Dashboard Id": dashboardID,
                                                  "Dashboard Name": name,
                                                });
                                              }}
                                            >
                                              <Icon name="arrow down" />
                                              Move page down
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                        <Dropdown item text="Edit different page" disabled={modifiedUnlayerConfig!.pages.length < 2}>
                                          <Dropdown.Menu>
                                            {modifiedUnlayerConfig!.pages.map((_page, idx) => (
                                              <Dropdown.Item
                                                key={`pg_${idx}_${pageTitles[idx] || "unnamed"}`}
                                                text={`${idx + 1}. ${pageTitles[idx] || "Unnamed"}`}
                                                disabled={idx === pageIdx}
                                                onClick={async () => {
                                                  const activePageData = await exportPageData();
                                                  const nextModifiedConfig = {
                                                    ...modifiedUnlayerConfig,
                                                    pages: [...modifiedUnlayerConfig.pages],
                                                  };
                                                  nextModifiedConfig.pages[pageIdx] = {
                                                    ...nextModifiedConfig.pages[pageIdx],
                                                    ...activePageData!,
                                                  };
                                                  unstable_batchedUpdates(() => {
                                                    setModifiedUnlayerConfig(nextModifiedConfig);
                                                    setPageIdx(idx);
                                                    setUnlayerReady(false);
                                                  });
                                                }}
                                              />
                                            ))}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Menu>
                                </div>
                                <Button className="mr-2" onClick={() => setCancelEditConfirmModal(true)} disabled={!unlayerReady}>
                                  Cancel
                                </Button>
                                <Button color="purple" onClick={saveDashboardHandler} disabled={!unlayerReady}>
                                  Save <Icon name="save outline" className="ml-1 mr-0" />
                                </Button>
                              </>
                            ) : (
                              <>
                                <div className="d-flex mb-2 mb-sm-0 justify-content-between">
                                  {ifOwnerOrEditor(user_access as dashboardAccessLevel) && (
                                    <DashboardPopup
                                      disabled={canUseDashboardFunctions}
                                      content={StandardPopupContent}
                                      position="bottom center"
                                      className="flex-grow-1 flex-grow-sm-0 mr-2"
                                    >
                                      <ButtonGroup color="red" id="userflow-element-dashboard-edit-button">
                                        <Button onClick={openUnlayerClickHandler}>
                                          Edit <Icon name="edit outline" className="ml-1 mr-0" />
                                        </Button>
                                        <Dropdown floating className="button icon" trigger={<></>}>
                                          <Dropdown.Menu>
                                            <Dropdown.Item text="Page navigation" icon="compass outline" onClick={openNavSettingsModal} />
                                            <Dropdown.Item
                                              text="Settings"
                                              icon="setting"
                                              onClick={() => setShowDashboardSettingsModal(true)}
                                            />
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </ButtonGroup>
                                    </DashboardPopup>
                                  )}
                                  {!curatedDashboard(user_access) && (
                                    <Button
                                      color="red"
                                      onClick={collaborateClickHandler}
                                      disabled={!canUseDashboardFunctions}
                                      className="mr-0 mr-sm-2 flex-shrink-0 flex-grow-1 flex-grow-sm-0"
                                    >
                                      Collaborate <Icon name="comment outline" className="ml-1 mr-0" />
                                    </Button>
                                  )}
                                </div>
                                <div className="d-flex justify-content-end justify-content-sm-start">
                                  {ifOwnerOrEditor(user_access as dashboardAccessLevel) && (
                                    <DashboardPopup
                                      content={canUseDashboardFunctions ? "Share Dashboard" : StandardPopupContent}
                                      position="bottom center"
                                    >
                                      <Button
                                        basic
                                        color="red"
                                        onClick={shareClickHandler}
                                        disabled={!canUseDashboardFunctions}
                                        className="mr-2"
                                      >
                                        <Icon name="share square" className="mr-0" />
                                      </Button>
                                    </DashboardPopup>
                                  )}
                                  <DashboardPopup content="Present" position="bottom center">
                                    <Button
                                      basic
                                      color="red"
                                      onClick={() => document.getElementById("dashboardContainer")?.requestFullscreen()}
                                      className="mr-2"
                                    >
                                      <Icon name="tv" className="mr-0" />
                                    </Button>
                                  </DashboardPopup>
                                  {actionsDropdownOptions.length > 0 && (
                                    <Dropdown
                                      id="userflow-element-dashboard-actions"
                                      selectOnBlur={false}
                                      text={"Actions"}
                                      value=""
                                      fluid
                                      selection
                                      options={actionsDropdownOptions}
                                      onChange={(_, e) => actionDropdownHandler(_, e)}
                                      className="mt-0"
                                    />
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {lockData && <LockBanner lockData={lockData} lockId={lockId} />}
                  {/* Dashboard Unlayer and HTML */}
                  <DashboardWrapper
                    style={{ marginBottom: showUnlayerEditor ? -20 : 0 }}
                    className={selectionOverlay ? `${selectionOverlay}-overlay` : ""}
                    selectedContent={dashboardSelection?.content || undefined}
                    selectedColumn={dashboardSelection?.column || undefined}
                    selectedRow={dashboardSelection?.row || undefined}
                    onClick={(e) => {
                      if (selectionOverlay === "select") {
                        const { clientX, clientY } = e.nativeEvent as PointerEvent;
                        const elements = document.elementsFromPoint(clientX, clientY);
                        const selection: any = {};
                        for (const element of elements) {
                          if (element.id.startsWith("u_content")) {
                            selection.content = element.id;
                            break;
                          }
                        }
                        dashboardStore.setDashboardSelection(selection);
                      }
                    }}
                  >
                    {showUnlayerEditor ? (
                      <DashboardUnlayerEditor
                        key={`pg_${activePageData.id || "0"}`}
                        ref={unlayerRef}
                        config={activePageData}
                        token={store.token!}
                        user={store!.user}
                        dashID={dashboardID}
                        onReady={() => setUnlayerReady(true)}
                      />
                    ) : (
                      <>
                        {modifiedHTML || unlayer_config.pages.length > 2 ? (
                          <DashboardHtml
                            key={`page${pageIdx}`} // forces a remount on page changes to ensure custom components mount correctly
                            html={modifiedHTML}
                            dashboardID={dashboardID}
                            setPageIdx={(idx) => setPageIdx(idx)}
                            pageIdx={pageIdx}
                            unlayer_config={unlayer_config}
                          />
                        ) : (
                          <>
                            {modifiedHTML === "" && ifOwnerOrEditor(user_access as dashboardAccessLevel) && !publicKey ? (
                              <DashboardEmptyState
                                disabled={!canUseDashboardFunctions || lockedEdits()}
                                onClickHandler={openUnlayerClickHandler}
                              />
                            ) : (
                              <>{modifiedHTML === undefined ? <ContentPlaceholder /> : null}</>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </DashboardWrapper>
                </div>
                <Collaborate
                  open={showCollaborate}
                  collaborators={dashboardUsers}
                  onClose={() => {
                    setShowCollaborate(false);
                    dashboardStore.setSelectionOverlay(null);
                    dashboardStore.setDashboardSelection(null);
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export const Dashboard = inject((stores: any) => ({
  store: stores.store,
  dashboardStore: stores.store.dashboard,
}))(observer(DashboardComponent));
