import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Icon } from "semantic-ui-react";
import { Builder } from "common/store/builder";

interface IExplorePreviewTab {
  builder?: Builder;
}

const ExplorePreviewTabComponent = ({ builder }: IExplorePreviewTab): JSX.Element => {
  const { leftTabFolded } = builder!.ui;

  const explorePreviewTips = [
    "Preview how your insight will look here before you save.",
    "Click on the title to edit your Insight name.",
    "Click on the summary to use the editor and further describe your Insight.",
  ];

  return (
    <>
      {/* Tab container */}
      <div style={{ minHeight: 485, margin: 20 }}>
        {!leftTabFolded && <h5 className="ff-primary text-secondary">Insight tips</h5>}
        {explorePreviewTips.map((tip, index) =>
          leftTabFolded ? (
            <div
              key={index}
              className="mb-4 bg-light rounded-3 cursor-pointer d-flex align-items-center justify-content-center"
              style={{ height: 40 }}
              onClick={() => builder!.ui.setLeftTabFolded(!leftTabFolded)}
            >
              <Icon name="info circle" color="grey" className="mr-0" style={{ marginTop: -5 }} />
            </div>
          ) : (
            <div key={index} className="d-flex bg-light ff-primary rounded-3 p-3 mb-4">
              <Icon name="info circle" size="small" color="grey" className="m-1" />
              <p className="fs-1000 text-muted ml-1">{tip}</p>
            </div>
          ),
        )}
      </div>
    </>
  );
};

export const ExplorePreviewTab = inject((stores: any) => ({
  builder: stores.store.builder,
}))(observer(ExplorePreviewTabComponent));
