import { getMixpanel } from "common/api";
import Store from "common/store";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { PlatformGeneralError } from "./PlatformGeneralError";

// Taken from https://reactjs.org/docs/error-boundaries.html
class ErrorBoundaryClass extends React.Component<{ store?: Store; errorContent?: any }, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    getMixpanel(this.props.store!).track("Error Boundary", { error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.errorContent ? this.props.errorContent : <PlatformGeneralError />;
    }

    return this.props.children;
  }
}

export default inject("store")(observer(ErrorBoundaryClass));
