import * as React from "react";
import { Button, Modal } from "semantic-ui-react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { NewDataSourceTypeCustom } from "./NewDataSourceTypeCustom";
import { NewDataSourceOptions } from "./NewDataSourceOptions";
import { NewDataSourceExcelUpload } from "../NewDataSourceExcelUpload";
import { getMixpanel } from "common/api";

export const DATA_SOURCE_STEPS: IDataSourceSteps = {
  dataSourceSelection: {
    content: NewDataSourceOptions,
  },
  dataSourceCustomSelected: {
    content: NewDataSourceTypeCustom,
  },
  dataSourceExcelUpload: {
    content: NewDataSourceExcelUpload,
  },
};

interface IDataSourceSteps {
  [key: string]: IDataSourceStep;
}

interface IDataSourceStep {
  content: React.FunctionComponent<any>;
}

interface INewDataSourceModal {
  store?: Store;
  isOpen: boolean;
  datasetName: string;
  closeModal: () => void;
}

export const NewDataSourceModal = inject("store")(
  observer(({ store, isOpen, datasetName, closeModal }: INewDataSourceModal): JSX.Element => {
    const currentStep = store!.dataset.newDataModalStep;

    React.useEffect(() => {
      getMixpanel(store!).track("Page view", { Page: "Collect Data > New Data Source" });
    }, []);

    const handleClose = (): any => {
      store?.dataset.setNewDataModalStep(DATA_SOURCE_STEPS.dataSourceSelection);
      closeModal();
    };

    return (
      <Modal open={isOpen} onClose={closeModal} closeOnDimmerClick={false}>
        <Modal.Header>Add New Data Source</Modal.Header>
        <Modal.Content>
          <currentStep.content stepHandler={store?.dataset.setNewDataModalStep} modalCloseHandler={handleClose} datasetName={datasetName} />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Actions>
      </Modal>
    );
  }),
);
