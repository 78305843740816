import { observable, action } from "mobx";
import Store from "../store";
import { database } from "common/api";
import { ObjectAny } from "common/helpers/types";
import { defaultNavConfig } from "pages/Dashboard/includes/ModalNavConfig";

// TODO: Currently using "null" for checking if the dashboard is "curated". Backend update required to signify this in the response.
export type dashboardAccessLevel = "owner" | "write" | "read" | null;

export interface DashboardReference {
  content?: string;
  row?: string;
  column?: string;
}

export interface DashboardComment {
  id: number;
  avatar: string;
  first_name: string;
  last_name: string;
  created_at: string;
  updated_at: string;
  resolved: boolean;
  message: string;
}

export interface ActionContributor {
  id: number;
  action_id?: number;
  user_id: number;
  email: string;
  first_name: string;
  last_name: string;
  avatar: string | null;
}

export interface ActionTask {
  id?: number;
  action_id?: number;
  name: string;
  resolved: boolean;
}

export interface DashboardAction {
  id: number;
  dashboard_id: number;
  dashboard_reference: DashboardReference | null;
  user_id: number;
  email: string;
  first_name: string;
  last_name: string;
  avatar: string | null;
  title: string;
  description?: string;
  outcome: string;
  resolved_at: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  due_date: string;
  action_contributors: ActionContributor[];
  action_tasks: ActionTask[];
}

// Add different classes to "DashboardWrapper" based on "selectionOverlay"
// - select: highlight content when hover on content and highlight selected content
// - highlight: highlight linked content after clicking actions
type SelectionOverlay = null | "select" | "highlight";

export default class Dashboard {
  @observable dashboardComments: any[] = [];
  @observable dashboardActions: DashboardAction[] = [];
  @observable selectionOverlay: SelectionOverlay = null;
  @observable dashboardSelection: DashboardReference | null = null;

  parent: Store;

  constructor(parent: Store) {
    this.parent = parent;
  }

  @action setDashboardComments = (comments: any[]): void => {
    this.dashboardComments = comments;
  };

  @action setDashboardActions = (actions: DashboardAction[]): void => {
    this.dashboardActions = actions;
  };

  @action setSelectionOverlay = (value: SelectionOverlay): void => {
    this.selectionOverlay = value;
  };

  @action setDashboardSelection = (selection: DashboardReference | null): void => {
    this.dashboardSelection = selection;
  };

  getDashboards = async (
    limit: number,
    skip: number,
    type: "all" | "owned" | "shared" | "shared_with_me" | "seer",
    sort?: string,
  ): Promise<any> => {
    const url = `dashboards?${limit ? `$limit=${limit}` : ""}${skip ? `&$skip=${skip}` : ""}${type ? `&type=${type}` : ""}${sort ? `&sort=${sort}` : ""}`;
    const res: any = await database.get(url, "", this.parent.token!);
    return res?.body;
  };

  // Search Dashboards by dashboard name or by the owner's first and last name
  searchDashboards = async (searchString: string, limit: number, skip?: number): Promise<any> => {
    const url = `v2/search/dashboards?q=${encodeURIComponent(searchString)}${skip ? `&$skip=${skip}` : ""}&$limit=${limit}`;
    const res: any = await database.get(url, "", this.parent.token!);
    return res?.body;
  };

  // Create a new dashboard and duplicate an existing dashboard
  createDashboard = async (dashboardConfig: any = {}): Promise<any> => {
    const { name, unlayer_config } = dashboardConfig;
    const payload_config = unlayer_config || { pages: [{ json: {}, html: "", id: Date.now() }] };
    if (!payload_config.navigation) {
      payload_config.navigation = defaultNavConfig(payload_config);
    }
    const res: any = await database.post(
      "dashboards",
      {
        name: name || "Untitled Dashboard",
        unlayer_config: payload_config,
      },
      this.parent.token!,
    );
    return res?.body?.data;
  };

  getDashboard = async (id: number, key?: string): Promise<any> => {
    const res: any = await database.get(`dashboards/${id}${key ? `/${key}` : ""}`, "", key ? undefined : this.parent.token!);
    return res?.body?.data;
  };

  updateDashboard = async (id: number, body: ObjectAny): Promise<boolean> => {
    const res: any = await database.put(`dashboards/${id}`, body, this.parent.token!);
    return res?.statusCode === 200;
  };

  deleteDashboard = async (id: number): Promise<boolean> => {
    const res: any = await database.delete(`dashboards/${id}`, "", this.parent.token!);
    return res?.statusCode === 200;
  };

  addDashboardAccess = async (
    dashboardID: number,
    type: "groups" | "users",
    toBeAddedIDs: number[],
    accessLevel: "write" | "read",
  ): Promise<boolean> => {
    const accesses = toBeAddedIDs.map((id) => {
      const read_only = accessLevel === "read";
      if (type === "groups") {
        return { group_id: id, read_only };
      } else {
        return { user_id: id, read_only };
      }
    });
    const res: any = await database.post(`dashboards/${dashboardID}/${type}`, { accesses }, this.parent.token!);
    return res?.statusCode === 200;
  };

  updateDashboardAccess = async (
    dashboardID: number,
    type: "groups" | "users",
    toBeUpdatedID: number,
    accessLevel: "write" | "read",
  ): Promise<boolean> => {
    const access = {};
    const key = type === "groups" ? "group_id" : "user_id";
    access[key] = toBeUpdatedID;
    access["read_only"] = accessLevel === "read";
    const res: any = await database.post(`dashboards/${dashboardID}/${type}`, { accesses: [access] }, this.parent.token!);
    return res?.statusCode === 200;
  };

  deleteDashboardAccess = async (dashboardID: number, type: "groups" | "users", toBeRemovedID: number): Promise<boolean> => {
    const res: any = await database.delete(`dashboards/${dashboardID}/${type}/${toBeRemovedID}`, "", this.parent.token!);
    return res?.statusCode === 200;
  };

  refreshDashboardComments = async (dashboardID: number): Promise<void> => {
    const dashboardRes: any = await this.getDashboard(dashboardID);
    if (dashboardRes?.dashboard) {
      this.dashboardComments = dashboardRes?.dashboard.comments;
    }
  };

  refreshDashboardActions = async (dashboardID: number): Promise<void> => {
    const dashboardRes: any = await this.getDashboard(dashboardID);
    if (dashboardRes?.dashboard) {
      this.dashboardActions = dashboardRes?.dashboard.actions;
    }
  };

  createDashboardComment = async (
    dashboardID: number,
    message: string,
    reply_to: number | null,
    dashboardReference?: DashboardReference,
  ): Promise<boolean> => {
    const res: any = await database.post(
      `dashboards/${dashboardID}/comment`,
      {
        dashboard_reference: dashboardReference || null,
        reply_to,
        message,
        resolved: false,
      },
      this.parent.token!,
    );
    return res?.statusCode === 200;
  };

  deleteDashboardComment = async (dashboardID: number, commentID: number): Promise<boolean> => {
    const res: any = await database.delete(`dashboards/${dashboardID}/comment/${commentID}`, "", this.parent.token!);
    return res?.statusCode === 200;
  };

  updateDashboardComment = async (dashboardID: number, commentID: number, body: Partial<DashboardComment>): Promise<boolean> => {
    const res: any = await database.put(`dashboards/${dashboardID}/comment/${commentID}`, body, this.parent.token!);
    return res?.statusCode === 200;
  };

  createDashboardAction = async (dashboardID: number, body: Partial<DashboardAction>): Promise<DashboardAction | undefined> => {
    const res: any = await database.post(`dashboards/${dashboardID}/action`, body, this.parent.token!);
    if (res?.statusCode === 200) {
      return res.body.data.dashboard_action[0];
    } else {
      return undefined;
    }
  };

  updateDashboardAction = async (dashboardID: number, actionID: number, body: Partial<DashboardAction>): Promise<boolean> => {
    const res: any = await database.put(`dashboards/${dashboardID}/action/${actionID}`, body, this.parent.token!);
    return res?.statusCode === 200;
  };

  deleteDashboardAction = async (dashboardID: number, actionID: number): Promise<boolean> => {
    const res: any = await database.delete(`dashboards/${dashboardID}/action/${actionID}`, "", this.parent.token!);
    return res?.statusCode === 200;
  };

  createDashboardActionTask = async (dashboardID: number, actionID: number, name: string): Promise<boolean> => {
    const res: any = await database.post(`dashboards/${dashboardID}/action/${actionID}/task`, { name }, this.parent.token!);
    return res?.statusCode === 200;
  };

  updateDashboardActionTask = async (dashboardID: number, actionID: number, taskID: number, body): Promise<boolean> => {
    const res: any = await database.put(`dashboards/${dashboardID}/action/${actionID}/task/${taskID}`, body, this.parent.token!);
    return res?.statusCode === 200;
  };

  deleteDashboardActionTask = async (dashboardID: number, actionID: number, taskID: number): Promise<boolean> => {
    const res: any = await database.delete(`dashboards/${dashboardID}/action/${actionID}/task/${taskID}`, "", this.parent.token!);
    return res?.statusCode === 200;
  };

  createDashboardActionContributors = async (dashboardID: number, actionID: number, userIDs: number[]) => {
    const res: any = await database.post(
      `dashboards/${dashboardID}/action/${actionID}/contributor`,
      { user_ids: userIDs },
      this.parent.token!,
    );
    return res?.statusCode === 200;
  };

  removeDashboardActionContributors = async (dashboardID: number, actionID: number, userIDs: number[]) => {
    const res: any = await database.post(
      `dashboards/${dashboardID}/action/${actionID}/contributor/bulk-delete`,
      { dashboard_action_contributor_ids: userIDs },
      this.parent.token!,
    );
    return res?.statusCode === 200;
  };
}
