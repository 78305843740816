import * as React from "react";
import Select, { createFilter } from "react-select";
import { inject, observer } from "mobx-react";
import { Button, Divider, Icon, Label, SemanticICONS } from "semantic-ui-react";

import Store from "common/store";
import { RATToolQuery } from "common/store/builder";
import { datasets as requestDataApi, getMixpanel } from "common/api";
// import { sortDimensionValues, transformResToDims } from "common/helpers/data";
import { customReactSelectStyles } from "common/helpers/dataset";
import { getCombinedDims } from "common/helpers/data";
import { ShowLoaderGlobal } from "component/LoaderGlobal";
import { SeerButton } from "pages/PartnerOrganisations/includes/PartnerOrganisationsButtons";
import { MenuList } from "pages/SmartInsights/includes/FieldReactSelect";
import { StylePopup } from "component/insightBuilder/insightBuilderStyles/whereWhatWhen.style";

const findTypeOptions = ["Top", "Bottom"];
const findNumberOptions = [3, 5, 10, 20, 30];
const inVariableOptions = {
  "Statistical Area Level 2 (SA2)": ["State or Territory", "Employment Region"],
  "Local Government Area (LGA)": ["State or Territory", "Employment Region"],
};

interface Props {
  store?: Store;
}

interface State {
  findType: string;
  findNumber: number;
  findVariable: string;
  isUpdatingTables: boolean;
  inVariable: string;
  inCategoryOptions: any[];
  isCategoryOptionsLoading: boolean;
  inCategory: string;
}

const initialState = {
  findType: "Top",
  findNumber: 10,
  findVariable: "",
  isUpdatingTables: false,
  inVariable: "",
  inCategoryOptions: [],
  isCategoryOptionsLoading: false,
  inCategory: "",
};

class Component extends React.Component<Props, State> {
  state = initialState;

  getInCategoryOptions = async (variable) => {
    // @TODO - clean this up
    const stateOpts = [
      {
        name: "Australian Capital Territory",
        value: "Australian Capital Territory",
      },
      {
        name: "New South Wales",
        value: "New South Wales",
      },
      {
        name: "Northern Territory",
        value: "Northern Territory",
      },
      {
        name: "Queensland",
        value: "Queensland",
      },
      {
        name: "South Australia",
        value: "South Australia",
      },
      {
        name: "Tasmania",
        value: "Tasmania",
      },
      {
        name: "Victoria",
        value: "Victoria",
      },
      {
        name: "Western Australia",
        value: "Western Australia",
      },
    ];
    const empRegionOpts = [
      { name: "Bendigo", value: "Bendigo" },
      { name: "Brisbane South East", value: "Brisbane South East" },
      { name: "Murray and South East", value: "Murray and South East" },
      { name: "Capital Region", value: "Capital Region" },
      { name: "Far West Orana", value: "Far West Orana" },
      { name: "New England and North West", value: "New England and North West" },
      { name: "Western Melbourne", value: "Western Melbourne" },
      { name: "South Coast of Victoria", value: "South Coast of Victoria" },
      { name: "Townsville", value: "Townsville" },
      { name: "Cairns", value: "Cairns" },
      { name: "Somerset", value: "Somerset" },
      { name: "Barwon", value: "Barwon" },
      { name: "Mackay", value: "Mackay" },
      { name: "North Western Melbourne", value: "North Western Melbourne" },
      { name: "Sydney North and West", value: "Sydney North and West" },
      { name: "Illawarra South Coast", value: "Illawarra South Coast" },
      { name: "Great Southern - Wheatbelt", value: "Great Southern - Wheatbelt" },
      { name: "North Coast", value: "North Coast" },
      { name: "Darwin", value: "Darwin" },
      { name: "Mid North SA", value: "Mid North SA" },
      { name: "Gippsland", value: "Gippsland" },
      { name: "Hunter", value: "Hunter" },
      { name: "Perth North", value: "Perth North" },
      { name: "Central West", value: "Central West" },
      { name: "Inner Metropolitan Melbourne", value: "Inner Metropolitan Melbourne" },
      { name: "Ballarat", value: "Ballarat" },
      { name: "Fitzroy", value: "Fitzroy" },
      { name: "Norfolk Island", value: "Norfolk Island" },
      { name: "Esperance", value: "Esperance" },
      { name: "Goulburn/Murray", value: "Goulburn/Murray" },
      { name: "Perth South", value: "Perth South" },
      { name: "Darling Downs", value: "Darling Downs" },
      { name: "Hobart and Southern Tasmania", value: "Hobart and Southern Tasmania" },
      { name: "South West WA", value: "South West WA" },
      { name: "North and North Western Tasmania", value: "North and North Western Tasmania" },
      { name: "Gold Coast", value: "Gold Coast" },
      { name: "Wide Bay and Sunshine Coast", value: "Wide Bay and Sunshine Coast" },
      { name: "North West Country SA", value: "North West Country SA" },
      { name: "Wimmera Mallee", value: "Wimmera Mallee" },
      { name: "North Eastern Melbourne", value: "North Eastern Melbourne" },
      { name: "Adelaide South", value: "Adelaide South" },
      { name: "Murray Riverina", value: "Murray Riverina" },
      { name: "Broome", value: "Broome" },
      { name: "Mid North Coast", value: "Mid North Coast" },
      { name: "Wivenhoe", value: "Wivenhoe" },
      { name: "Adelaide North", value: "Adelaide North" },
      { name: "Geraldton", value: "Geraldton" },
      { name: "Sydney East Metro", value: "Sydney East Metro" },
      { name: "South Eastern Melbourne and Peninsula", value: "South Eastern Melbourne and Peninsula" },
      { name: "Sydney Greater West", value: "Sydney Greater West" },
      { name: "Sydney South West", value: "Sydney South West" },
      { name: "Kalgoorlie", value: "Kalgoorlie" },
    ];
    const options = {
      "State or Territory": stateOpts,
      "Employment Region": empRegionOpts,
    };
    this.setState({ inCategoryOptions: options[variable] || [] });

    // @TODO - remove if this is only going to work with fixed lists..
    // const requestBody = {
    //   filters: { [variable]: [] },
    //   datasets: this.props.store!.builder.tables
    //     .filter(table => table.result !== "calc")
    //     .reduce((pre, cur) => {
    //       cur.datasetsToQuery.forEach(dataset => pre.push(dataset));
    //       return pre;
    //     }, []),
    // };
    // this.setState({ isCategoryOptionsLoading: true });
    // const res: any = await requestDataApi.post("v2/qs", requestBody, this.props.store!.token ?? "");
    // if (res?.statusCode === 200) {
    // const inCategoryOptions = sortDimensionValues(transformResToDims(res))[variable].values;
    // this.setState({ inCategoryOptions });
    // }
    // this.setState({ isCategoryOptionsLoading: false });
  };

  analysisAndUpdateInsight = async (autoApply = false) => {
    const { filters: robotFilters, dataset, calc } = this.props.store!.builder.robot;
    const filters = {};
    for (const filter of robotFilters) {
      filters[filter.dimension] = filter.values;
    }
    const { findType, findNumber, findVariable, inVariable, inCategory } = this.state;
    const computeFindVariable = findVariable || this.props.store!.builder.columns[0]?.dimension;
    const requestBody = {
      rows: calc ? calc.rows : { row1: { filters, dataset } },
      formula: calc ? calc.formula : "row1",
      variable: computeFindVariable,
      find: findType.toLowerCase(),
      size: findNumber,
    };
    if (inVariable && inCategory) {
      requestBody["in"] = { [inVariable]: inCategory };
    }
    this.setState({ isUpdatingTables: true });
    const res: any = await requestDataApi.post("v2/robot-analysis", requestBody, this.props.store!.token ?? "");
    if (res?.body?.data?.results) {
      const { builder } = this.props.store!;
      builder.setRATAutoQuery(autoApply ? (requestBody as RATToolQuery) : undefined);
      const newColumnValues = res.body.data.results.map((res) => res[computeFindVariable]);
      const copyColumns = [...builder.columns];
      const findIndex = copyColumns.findIndex((col) => col.dimension === computeFindVariable);
      copyColumns[findIndex].values = newColumnValues;
      builder.setColumns(copyColumns);
      builder.toggleDynamicDim(computeFindVariable, false); // remove dynamic query on column if set
      for (const table of builder.tables.filter((table) => table?.type !== "calc")) {
        table.columns = copyColumns;
        await builder.setActiveTableId(table.id);
      }
      await builder.setActiveTableId(undefined);
      builder.toggleRobotTool();
      getMixpanel(this.props.store!).track("Robot Analysis Tool", {
        Filters: filters,
        Dataset: dataset,
        "Find Variable": computeFindVariable,
        Find: findType,
        Size: findNumber,
        "In Variable": inVariable,
        "In Category": inCategory,
        "Auto-Apply": autoApply,
      });
    }
    this.setState(initialState);
  };

  render() {
    const { findType, findNumber, findVariable, isUpdatingTables, inVariable, inCategoryOptions, isCategoryOptionsLoading, inCategory } =
      this.state;
    const { builder } = this.props.store!;
    const { open, filters, calc } = builder.robot;
    const computeFindVariable = findVariable || builder.columns[0]?.dimension;
    const showInSection = ["Statistical Area Level 2 (SA2)", "Local Government Area (LGA)"].includes(computeFindVariable);
    const isMultiIndexColTable = getCombinedDims(builder.columns).length > 1;

    if (!open) {
      return null;
    }
    return (
      <>
        {isUpdatingTables && <ShowLoaderGlobal />}
        <div className="h-100 position-relative">
          <div className="d-flex align-items-center justify-content-between px-3 my-3">
            <div className="d-flex align-items-center text-secondary">
              <Icon name={"robot" as SemanticICONS} className="mr-2" style={{ fontSize: 28 }} />
              <h4 className="my-0">Analysis Tool</h4>
            </div>
            <Button
              onClick={() => {
                builder.toggleRobotTool();
                this.setState(initialState); // reset state
              }}
              size="tiny"
              className="mr-0"
            >
              Close
              <Icon name="close" className="ml-2 mr-0" />
            </Button>
          </div>
          <Divider className="mb-0" />
          <div className="mb-4 p-3 overflow-auto" style={{ height: "calc(100% - 65px)" }}>
            <div className="fs-1000 fw-600 mb-3">
              <p className="mb-1">Find the top/bottom items for a piece of data (eg. top 5 LGAs for Average weekly income).</p>
              <a
                href="https://knowledge.seerdata.ai/how-to-use-the-analysis-tool"
                target="_blank"
                className="text-primary text-decoration-underline my-0"
              >
                Learn More
              </a>
            </div>
            {isMultiIndexColTable ? (
              <div className="fs-1000">
                <p className="mb-3">This Analysis function only works when a single variable is set in the table columns.</p>
                <SeerButton
                  category="primary"
                  label="Update table structure"
                  onClick={() => {
                    builder.toggleRobotTool();
                    this.setState(initialState); // reset state
                    builder.setActiveTableId(1);
                    builder.ui.setActiveTableTab("advanced");
                    builder.ui.setLeftTabFolded(false);
                  }}
                />
              </div>
            ) : (
              <>
                <div>
                  <p className="fs-1000">Select the data you want to analyse by clicking on a row number in your table.</p>
                  <div className="mb-3">
                    <h5 className="text-secondary">Find:</h5>
                    <Select
                      styles={customReactSelectStyles}
                      value={{ id: findType, value: findType, label: findType }}
                      options={findTypeOptions.map((opt) => ({ id: opt, value: opt, label: opt }))}
                      onChange={(option: any) => this.setState({ findType: option.value })}
                    />
                    <div style={{ height: 10 }}></div>
                    <Select
                      styles={customReactSelectStyles}
                      value={{ id: findNumber, value: findNumber, label: findNumber }}
                      options={findNumberOptions.map((opt) => ({ id: opt, value: opt, label: opt }))}
                      onChange={(option: any) => this.setState({ findNumber: option.value })}
                    />
                    <div style={{ height: 10 }}></div>
                    <Select
                      styles={customReactSelectStyles}
                      value={{ id: computeFindVariable, value: computeFindVariable, label: computeFindVariable }}
                      options={builder.columns.map((opt) => ({ id: opt.dimension, value: opt.dimension, label: opt.dimension }))}
                      onChange={(option: any) => this.setState({ findVariable: option.value, inVariable: "", inCategory: "" })}
                    />
                  </div>
                  {showInSection && (
                    <div className="mb-3">
                      <div className="d-flex">
                        <h5 className="text-secondary">In:</h5>
                        <StylePopup
                          trigger={<Icon name="info circle" className="text-medium ml-1 cursor-pointer" size="small" />}
                          content="(Optional) Narrow your search to a specific region for certain location-based variables."
                          position="right center"
                          size="mini"
                          inverted
                        />
                      </div>
                      <Select
                        styles={customReactSelectStyles}
                        isClearable
                        placeholder="Select or search..."
                        value={inVariable ? { id: inVariable, value: inVariable, label: inVariable } : null}
                        options={inVariableOptions[computeFindVariable].map((opt) => ({ id: opt, value: opt, label: opt }))}
                        onChange={(option: any) => {
                          if (option) {
                            this.setState({ inVariable: option.value, inCategory: "" });
                            this.getInCategoryOptions(option.value);
                          } else {
                            this.setState({ inVariable: "", inCategory: "" });
                          }
                        }}
                      />
                      <div style={{ height: 10 }}></div>
                      <Select
                        styles={customReactSelectStyles}
                        placeholder="Select or search..."
                        value={inCategory ? { id: inCategory, value: inCategory, label: inCategory } : null}
                        options={inCategoryOptions.map((opt: any) => ({ id: opt.name, value: opt.name, label: opt.name }))}
                        isDisabled={!inVariable || isCategoryOptionsLoading}
                        isLoading={isCategoryOptionsLoading}
                        onChange={(option: any) => {
                          this.setState({ inCategory: option.value });
                        }}
                        filterOption={createFilter({ ignoreAccents: false })}
                        components={{ MenuList }}
                      />
                    </div>
                  )}
                  <div className="mb-3">
                    <h5 className="text-secondary">By:</h5>
                    {!filters.length && !calc ? (
                      <div className="d-flex text-muted align-items-center">
                        <Icon name="info circle" size="small" className="mr-2" />
                        <p className="fs-1000">No row selected.</p>
                      </div>
                    ) : (
                      <>
                        {!!filters.length && (
                          <Label.Group>
                            {filters.map((filter) => (
                              <Label className="bg-light text-muted" key={filter.dimension}>
                                <b>{filter.dimension}:</b> {filter.values[0]}
                              </Label>
                            ))}
                          </Label.Group>
                        )}
                        {calc && <p>Calculation at Row{calc.rowNumber} selected.</p>}
                      </>
                    )}
                  </div>
                </div>
                <div className="d-flex w-100">
                  <SeerButton
                    category="primary"
                    label="Go"
                    disabled={!computeFindVariable || (!filters.length && !calc) || !!(inVariable && !inCategory)}
                    onClick={() => this.analysisAndUpdateInsight()}
                    className="w-100"
                  />
                  <StylePopup
                    trigger={
                      <SeerButton
                        category="primary"
                        label={
                          <span>
                            Auto Apply <Icon name="info circle" className="ml-1 cursor-pointer" size="small" />
                          </span>
                        }
                        disabled={!computeFindVariable || (!filters.length && !calc) || !!(inVariable && !inCategory)}
                        onClick={() => this.analysisAndUpdateInsight(true)}
                        className="w-100"
                      />
                    }
                    content="Your analysis query will automatically apply when used in conjunction with auto refreshing Insights."
                    position="right center"
                    size="mini"
                    inverted
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export const RobotAnalysisToolContent = inject("store")(observer(Component));
