import * as React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import {
  CHART_AXIS_COLOR,
  CHART_GRID_COLOR,
  CHART_TOOLTIP_FILL,
  PREVIEW_LABEL_FONT_SIZE,
  PREVIEW_LABEL_STYLING,
} from "common/helpers/chart";

interface IBarChart {
  data: any[];
  chartKey: any;
  chartColors: string[];
  type: string;
  graphWidth: number;
  graphHeight: number;
  maxXAxisHeight: number;
  maxYAxisWidth: number;
  minDomainValue: number | "auto";
  maxDomainValue: number | "auto";
  computedTicks: undefined | number[];
  formatter: (val: number, _index?: number, full?: boolean) => any;
  CustomAxisTick: (axis: "x" | "y") => (props: any) => JSX.Element;
  setTooltipDataKey: (value) => void;
  CustomToolTip: (props: any) => JSX.Element | null;
  allowDataOverflow: boolean;
  axisTickInterval?: number;
}

export const NewBuilderBarChart = ({
  data,
  chartKey,
  chartColors,
  type,
  graphWidth,
  graphHeight,
  minDomainValue,
  maxDomainValue,
  computedTicks,
  formatter,
  maxXAxisHeight,
  maxYAxisWidth,
  CustomAxisTick,
  setTooltipDataKey,
  CustomToolTip,
  allowDataOverflow,
  axisTickInterval,
}: IBarChart): JSX.Element => {
  const hasFewerBars = data.length <= 4;
  return (
    <BarChart
      data={data}
      // 'layout': The layout of bars in the chart. Default: "horizontal". Opposite of chart type - 'chart.type'
      width={graphWidth}
      height={graphHeight}
      layout={type.includes("Horizontal") ? "vertical" : "horizontal"}
      maxBarSize={hasFewerBars ? 80 / data.length : 24}
    >
      <CartesianGrid
        strokeDasharray="4 4"
        vertical={type.includes("Horizontal") ? true : false}
        horizontal={type.includes("Horizontal") ? false : true}
        stroke={CHART_GRID_COLOR}
      />
      {type.includes("Horizontal") ? (
        <XAxis
          type="number"
          tick={PREVIEW_LABEL_STYLING}
          tickFormatter={formatter}
          height={PREVIEW_LABEL_FONT_SIZE * 2}
          tickLine={false}
          axisLine={false}
          domain={[minDomainValue, maxDomainValue]}
          ticks={computedTicks}
          minTickGap={1}
          allowDataOverflow={allowDataOverflow}
        />
      ) : (
        <XAxis
          dataKey="label0"
          tick={CustomAxisTick("x")}
          textAnchor="end"
          interval={axisTickInterval || 0}
          height={maxXAxisHeight}
          tickLine={false}
          axisLine={{ stroke: CHART_AXIS_COLOR }}
        />
      )}
      {type.includes("Horizontal") ? (
        <YAxis
          dataKey="label0"
          type="category"
          tick={CustomAxisTick("y")}
          textAnchor="end"
          interval={axisTickInterval || 0}
          width={maxXAxisHeight}
          tickLine={false}
          axisLine={{ stroke: CHART_AXIS_COLOR }}
        />
      ) : (
        <YAxis
          type="number"
          tick={PREVIEW_LABEL_STYLING}
          tickFormatter={formatter}
          width={maxYAxisWidth}
          tickLine={false}
          axisLine={false}
          domain={[minDomainValue, maxDomainValue]}
          ticks={computedTicks}
          minTickGap={1}
          allowDataOverflow={allowDataOverflow}
        />
      )}
      <Tooltip offset={0} isAnimationActive={false} cursor={{ fill: CHART_TOOLTIP_FILL }} content={<CustomToolTip />} />

      {chartKey.values.map((dataKey, idx) => (
        <Bar
          key={`${dataKey} ${idx}`}
          stackId={type.includes("clustered") ? idx : "stacked"} // Use the same "stackId" for stacked bar charts
          dataKey={dataKey}
          fill={chartColors[idx]}
          radius={
            idx === chartKey.values.length - 1
              ? type.includes("Horizontal")
                ? [0, 2, 2, 0]
                : [2, 2, 0, 0]
              : type.includes("clustered")
                ? type.includes("Horizontal")
                  ? [0, 2, 2, 0]
                  : [2, 2, 0, 0]
                : [0, 0, 0, 0]
          }
          onMouseEnter={(props) => {
            const { tooltipPayload } = props;
            setTooltipDataKey(tooltipPayload[0].dataKey);
          }}
          onMouseLeave={() => {
            setTooltipDataKey(undefined);
          }}
        />
      ))}
    </BarChart>
  );
};
