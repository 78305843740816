import * as React from "react";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { CategoricalChartProps } from "recharts/types/chart/generateCategoricalChart";
import { CustomPieLabel, CustomPieLabelLine } from "./CustomCharts";
import { CHART_FONT_FAMILY } from "common/helpers/chart";

interface IPieChart extends CategoricalChartProps {
  chartColors: string[];
  size: "mini" | "small" | "big" | "huge";
  strokeWidth?: number;
  CustomToolTip: (props: any) => JSX.Element | null;
}

export const SimplePieChart = (props: IPieChart): JSX.Element => {
  const { data, chartColors, width, height, strokeWidth = 1, size, CustomToolTip } = props;
  let lineLength: number;
  let labelFontSize: number;
  let outerRadius = "85%";
  let lineMargin = 8;
  if (size === "mini") {
    lineLength = 4;
    labelFontSize = 10;
    outerRadius = "78%";
    lineMargin = 3;
  } else if (size === "small") {
    lineLength = 8;
    labelFontSize = 12;
    lineMargin = 3;
    outerRadius = "80%";
  } else if (size === "big") {
    lineLength = 12;
    labelFontSize = 14;
    lineMargin = 5;
  } else {
    lineLength = 16;
    labelFontSize = 16;
  }
  // Workaround to avoid the Pie charts labels being cut off: reduce the length of label lines
  const outerRadiusNumber = +outerRadius.replace("%", "") / 100;
  // Check if the top or bottom labels are possible to exceed the chart view bounds
  const topOrBottomLabelsExceedView: boolean = 2 * (lineLength + labelFontSize) + height! * outerRadiusNumber >= height!;
  // Check if the left or right labels are possible to exceed the chart view bounds
  const LARGEST_HORIZONTAL_LABEL_VALUE = "50%";
  const labelText = document.createElement("canvas").getContext("2d")!;
  labelText.font = `${labelFontSize}px ${CHART_FONT_FAMILY}`;
  const largestHorizontalLabelWidth = labelText.measureText(LARGEST_HORIZONTAL_LABEL_VALUE).width;
  const leftOrRightLabelsExceedView: boolean = 2 * (lineLength + largestHorizontalLabelWidth) + width! * outerRadiusNumber >= width!;
  if (topOrBottomLabelsExceedView || leftOrRightLabelsExceedView) {
    let baseDimension = height!;
    if (leftOrRightLabelsExceedView) {
      baseDimension = width!;
    }
    lineLength = Math.max((baseDimension * (1 - outerRadiusNumber) - labelFontSize * 2) / 2, 0);
  }
  return (
    <PieChart width={width} height={height}>
      <Pie
        dataKey="value"
        data={data}
        startAngle={90}
        endAngle={-270}
        labelLine={<CustomPieLabelLine lineLength={lineLength} sliceNumber={data?.length || 0} />}
        label={<CustomPieLabel labelFontSize={labelFontSize} lineLength={lineLength} lineMargin={lineMargin} sliceNumber={data?.length} />}
        outerRadius={outerRadius}
        isAnimationActive={false} // TODO: Existing recharts issue. Have to turn off animation to show custom label. https://github.com/recharts/recharts/issues/929
      >
        {data?.map((_entry, idx) => (
          <Cell key={`cell-${idx}`} className="cursor-pointer" strokeWidth={strokeWidth} fill={chartColors[idx] || chartColors[0]} />
        ))}
      </Pie>
      <Tooltip offset={0} isAnimationActive={false} content={<CustomToolTip />} />
    </PieChart>
  );
};
