import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Card, Grid, Icon, Label, Popup } from "semantic-ui-react";
import { fill } from "lodash";
import { indicator } from "ordinal";
import {
  generateTopBottomLabel,
  generateTopBottomNum,
  getPctlNum,
  SMART_INSIGHTS_VARIABLE_MAP,
  TOP_BOTTOM_LABEL_COLOR_MAP,
} from "pages/SmartInsights/includes/smartInsights";
import { isPercentage } from "common/helpers/data";
import { SeerButton } from "pages/PartnerOrganisations/includes/PartnerOrganisationsButtons";
import { ObjectAny } from "common/helpers/types";

const numberFormatter = new Intl.NumberFormat();

interface IInsightsList {
  builder?: any;
  favouriteStore?: any;
  insights: any[];
  globalLocationVariable?: string;
  clickInsightHandler: (insight) => void;
  saveClickHandler?: (param: any) => Promise<boolean>;
  unsaveClickHandler: (id: number) => Promise<void | boolean>;
}

const InsightsListComponent = (props: IInsightsList): JSX.Element => {
  const { builder, favouriteStore, insights, globalLocationVariable, clickInsightHandler, saveClickHandler, unsaveClickHandler } = props;
  const [clickSaveInsights, setClickSaveInsights] = React.useState<ObjectAny>({}); // State to store current insight idx (based on row and row number) and saved insight id (after save clicked)
  const forQueryTool = saveClickHandler ? true : false;
  const insightRows = Math.ceil(insights.length / 2);
  const insightRowsArray = fill(new Array(insightRows), 0);

  return (
    <Grid columns={2} className="mb-4">
      {insightRowsArray?.map((_, rowIdx) => (
        <Grid.Row stretched key={rowIdx}>
          {insights.slice(rowIdx * 2, rowIdx * 2 + 2)?.map((insight, insightIdx) => {
            const uniqueIdx = `${rowIdx}-${insightIdx}`;
            const { percentile, dataset, Value, id } = insight;
            const locationVariable =
              globalLocationVariable || Object.keys(insight).find((key) => Object.keys(SMART_INSIGHTS_VARIABLE_MAP).indexOf(key) >= 0);
            const isValuePercentage = isPercentage(insight["Measured quantity"]);
            const value = isValuePercentage
              ? (Value * 100).toFixed(2) // Check if the number if percentage
              : Value % 1 === 0
                ? Value // Check if the number is integer
                : Value.toFixed(2);
            const pctlNum = getPctlNum(percentile);
            const topBottomLabelColor = TOP_BOTTOM_LABEL_COLOR_MAP[generateTopBottomNum(pctlNum)];
            const topBottomLabel = generateTopBottomLabel(pctlNum);
            const isSavedSmartInsight = !!favouriteStore.favourites["smart-insight"]?.find(
              (insight) => insight.id === clickSaveInsights[uniqueIdx],
            );

            return (
              <Grid.Column key={`${dataset}-${uniqueIdx}`}>
                <Card className="w-100">
                  <Card.Content>
                    <div className="d-flex justify-content-between mb-3">
                      <div className="text-secondary">
                        <span className="fs-4000 fw-700 mr-2">{isValuePercentage ? value : numberFormatter.format(value as number)}</span>
                        {isValuePercentage && <span className="fs-2000">%</span>}
                      </div>
                      <Popup
                        trigger={
                          <Label
                            size="large"
                            className="cursor-pointer mt-3"
                            style={{ height: "fit-content", backgroundColor: topBottomLabelColor }}
                          >
                            {topBottomLabel}
                          </Label>
                        }
                        content={`${pctlNum}${indicator(pctlNum)} percentile out of all ${SMART_INSIGHTS_VARIABLE_MAP[locationVariable!]}s in Australia`}
                        position="top center"
                        size="small"
                        inverted
                      />
                    </div>
                    {Object.keys(insight).map((key) => {
                      if (key === "percentile" || key === "dataset" || key === "Value" || key === "id") {
                        return null;
                      } else {
                        return (
                          <div key={key} className="mb-3">
                            <p className="fs-0875 text-medium mb-1">{key}</p>
                            <p className="fs-1000 fw-600 text-muted">{insight[key]}</p>
                          </div>
                        );
                      }
                    })}
                  </Card.Content>
                  <Card.Content extra>
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="fs-0875 text-muted mb-0 mr-2" style={{ flex: 1 }}>
                        {builder.getDatasetName(dataset)}
                      </p>
                      <div className="d-flex align-items-center">
                        <SeerButton
                          category="secondary"
                          size="tiny"
                          label="Create insight"
                          onClick={() => clickInsightHandler(insight)}
                          className="mr-2"
                        />
                        <Popup
                          trigger={
                            <Icon
                              name="bookmark"
                              className={`cursor-pointer fs-1500
                                ${
                                  !forQueryTool
                                    ? "text-primary text-hover-medium" // Saved Insights tab > Unsave button
                                    : forQueryTool && isSavedSmartInsight
                                      ? "text-primary text-hover-medium" // Query Tool tab > Save button
                                      : "text-medium text-hover-primary"
                                }`} // Query Tool tab > Unsave button
                              onClick={async () => {
                                if (forQueryTool) {
                                  if (isSavedSmartInsight) {
                                    // Unsave
                                    const success = await unsaveClickHandler(clickSaveInsights[uniqueIdx]);
                                    if (success) {
                                      const newClickSaveInsights = { ...clickSaveInsights };
                                      delete newClickSaveInsights[uniqueIdx];
                                      setClickSaveInsights(newClickSaveInsights);
                                    }
                                  } else {
                                    // Save
                                    const newSavedInsight = saveClickHandler && (await saveClickHandler(insight));
                                    if (newSavedInsight) {
                                      // Save "Insight uniqueIdx" and "Saved id" key-value pair in "clickSaveInsights"
                                      setClickSaveInsights({ ...clickSaveInsights, [uniqueIdx]: newSavedInsight["id"] });
                                    }
                                  }
                                } else {
                                  // Unsave
                                  unsaveClickHandler(id);
                                }
                              }}
                            />
                          }
                          content={`${forQueryTool ? "Save" : "Unsave"} Insight`}
                          position="top center"
                          size="mini"
                          inverted
                        />
                      </div>
                    </div>
                  </Card.Content>
                </Card>
              </Grid.Column>
            );
          })}
        </Grid.Row>
      ))}
    </Grid>
  );
};

export const InsightsList = inject((stores: any) => ({
  builder: stores.store.builder,
  favouriteStore: stores.store.favourite,
}))(observer(InsightsListComponent));
