import * as React from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { composeValidators, mustBeNumber, required } from "common/helpers/finalForm";
import { FieldInput } from "component/FinalFormFields/FieldInput";
import { FieldReactSelect } from "component/FinalFormFields/FieldSelect";
import { Button } from "component/UI/Button";
import { Form, Icon } from "semantic-ui-react";

interface Props {
  type: "dataset" | "dashboard";
  onSubmit: (values) => void;
}

export const OrgAccess = (props: Props): JSX.Element => {
  const { type, onSubmit } = props;
  return (
    <FinalForm
      onSubmit={onSubmit}
      render={(formRenderProps) => {
        const { handleSubmit, values } = formRenderProps;
        const { access } = values;
        return (
          <Form onSubmit={handleSubmit}>
            <h2 className="text-secondary mb-5">{`Edit ${type} organisation access`}</h2>
            <div className="mb-5">
              <Field
                name={`${type}_id`}
                label={`${type.charAt(0).toUpperCase()}${type.slice(1)} ID`}
                component={FieldInput}
                validate={composeValidators(required, mustBeNumber)}
              />
              <Field name="group_id" label="Organisation ID" component={FieldInput} validate={composeValidators(required, mustBeNumber)} />
              <Field
                name="access"
                label="Access"
                component={FieldReactSelect}
                options={[
                  { label: "True", value: true },
                  { label: "False", value: false },
                ]}
                validate={composeValidators(required)}
              />
              {type !== "dataset" && access?.value && (
                <Field
                  name="read_only"
                  label="Read only"
                  component={FieldReactSelect}
                  options={[
                    { label: "True", value: true },
                    { label: "False", value: false },
                  ]}
                  validate={composeValidators(required)}
                />
              )}
            </div>
            <Button colorConfig="purpleInverted">
              Save
              <Icon name="save" className="ml-2" />
            </Button>
          </Form>
        );
      }}
    />
  );
};
