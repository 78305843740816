import * as React from "react";
import * as L from "leaflet";
import "leaflet.vectorgrid";
import { useLeafletContext } from "@react-leaflet/core";
import { ObjectAny } from "common/helpers/types";

/*
 * notes around other props (docs here https://leaflet.github.io/Leaflet.VectorGrid/vectorgrid-api-docs.html#vectorgrid-protobuf)
 *
 * maxNativeZoom: this should be set to the largest zoom layer generated for the vector tiles being used
 * setting it higher may result in no tiles being displayed when zooming beyond what is available
 * */

// extends ObjectAny because "leaflet.vectorgrid" doesn't export types, and we only want our props here and mandatory props
interface VectorTileLayerProps extends ObjectAny {
  url: string;
  idProperty: string;
  highlightStyleFn?: (properties: any, zoom: number) => ObjectAny; // only pass through what you wish to override on hover/highlight
  tooltipContent?: (properties: any) => string;
}

export const VectorTileLayer = (props: VectorTileLayerProps): null => {
  const { url, idProperty, highlightStyleFn, tooltipContent, ...vectorTileOptions } = props;
  const context = useLeafletContext();
  const container = context.layerContainer || context.map;

  React.useEffect(() => {
    const vectorGrid = new L["vectorGrid"].protobuf(url, {
      // note - don't use canvas in place of svg, only supports interactivity at the top most pane, more info here: https://github.com/Leaflet/Leaflet.VectorGrid/issues/158
      rendererFactory: L["svg"]["tile"],
      getFeatureId: (feature) => feature.properties[idProperty],
      ...vectorTileOptions,
    });
    let tooltip;
    vectorGrid.on("mouseover", (event) => {
      if (highlightStyleFn) {
        vectorGrid.setFeatureStyle(event?.layer?.properties[idProperty], {
          ...(event?.layer?.options || {}),
          ...highlightStyleFn(event.layer.properties, event.target._tileZoom),
        });
      }
      if (tooltipContent) {
        tooltip = tooltip || L.tooltip(); // reuse the same instance
        tooltip.setContent(tooltipContent(event.layer.properties)).setLatLng(event.latlng).openOn(context.map);
      }
    });
    const leaveHighlightFn = (event) => {
      vectorGrid.resetFeatureStyle(event.layer.properties[idProperty]);
      if (tooltipContent) {
        context.map.closeTooltip(tooltip);
      }
    };
    vectorGrid.on("mouseout", leaveHighlightFn);
    context.map.on("zoomend", leaveHighlightFn); // also rest on zoom change
    container.addLayer(vectorGrid);
    return () => {
      container.removeLayer(vectorGrid);
      // removing from layerContainer doesn't appear to remove it from map in this version of react-leaflet
      context.map.removeLayer(vectorGrid);
    };
  }, []);
  return null;
};
