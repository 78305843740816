import * as React from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { composeValidators, mustBeNumber, required } from "common/helpers/finalForm";
import { Button, Form, Header, Icon } from "semantic-ui-react";
import { FieldInput, FieldInputDate } from "component/FinalFormFields/FieldInput";

interface Props {
  onSubmit: (values) => void;
}

export const OrgExpiryDate = (props: Props): JSX.Element => {
  const { onSubmit } = props;
  return (
    <FinalForm
      onSubmit={onSubmit}
      render={(formRenderProps) => {
        const { handleSubmit } = formRenderProps;
        return (
          <Form onSubmit={handleSubmit}>
            <Header as="h3" color="purple" className="mb-5">
              Edit organisation plan expiry date
            </Header>
            <div className="mb-5">
              <Field name="group_id" label="Organisation ID" component={FieldInput} validate={composeValidators(required, mustBeNumber)} />
              <Field name="expiry" label="Expiry date" component={FieldInputDate} validate={composeValidators(required)} />
            </div>
            <Button color="purple">
              Save
              <Icon name="save" className="ml-1 mr-0" />
            </Button>
          </Form>
        );
      }}
    />
  );
};
