import * as React from "react";
import { Button, Divider, Icon, Label, Modal, Segment } from "semantic-ui-react";
import { inject, observer } from "mobx-react";
import { DateTime } from "luxon";
import AsyncSelect from "react-select/async";
import Store from "common/store";
import { webUrl } from "common/constants";
import { getMixpanel } from "common/api";
import { Avatar } from "component/Avatar";
import { ContentPlaceholder } from "component/ContentPlaceholder";
import { CustomMenu, CustomOption, CustomSingleValue, customSearchInsightStyles } from "./includes/InsightPickerCustomComponentsStyles";
import { NewBuilderChartPreview } from "component/Explore/includes/NewBuilderChartPreview";

interface ComponentProps {
  store?: Store;
  isOpen: boolean;
  data: any;
  close: () => any;
  editorRef: any;
}

interface State {
  loading: boolean;
  selectedInsightData: any;
}

class Component extends React.Component<ComponentProps> {
  state: State = {
    loading: false,
    selectedInsightData: undefined,
  };
  timeoutRef: any = React.createRef<any>();

  // If the user pastes insight URL, this function is trigged immediately with "insightID" passed
  // If the user searches insight name/id, this function is trigged by clicking "Save" button without "insightID" passed
  selectInsight = async (insightID?) => {
    const { editorRef, close, data } = this.props;
    let insightData;
    if (insightID) {
      insightData = await this.props.store!.insight.getInsightData(insightID);
    } else {
      insightData = this.state.selectedInsightData;
    }
    const { id, name, public_key: publicKey, suitcase_id } = insightData;
    editorRef.frame.iframe.contentWindow.postMessage(
      {
        insightTool: {
          nodeId: data.nodeId,
          data: {
            id,
            name,
            url: `${webUrl}/insights/${id}${publicKey ? `/${publicKey}` : ""}`,
            isPublic: !!publicKey,
            suitcase_id,
            webUrl,
          },
        },
      },
      "https://editor.unlayer.com",
    );
    getMixpanel(this.props.store!).track("Embed Insight to Dashboard", {
      "Insight ID": id,
      "Search Type": insightID ? "Paste Insight URL" : "Search Insight Name/ID",
    });
    close();
  };

  loadInsightPreview = async (selectedInsightID): Promise<void> => {
    this.setState({ loading: true });
    const insightData = await this.props.store!.insight.getInsightData(selectedInsightID);
    const suitcase = await this.props.store!.suitcase.getSuitcaseData(insightData.suitcase_id);
    insightData.suitcase_path = suitcase.breadcrumbs.map((b) => b.label).join(" > ");
    this.setState({ loading: false, selectedInsightData: insightData || {} });
  };

  componentDidUpdate(prevProps: ComponentProps): void {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ selectedInsightData: undefined });
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.timeoutRef.current);
  }

  render(): JSX.Element {
    const { loading, selectedInsightData } = this.state;
    const selectedInsightJSON = selectedInsightData ? JSON.parse(selectedInsightData.json) : null;
    const { isOpen, store, close } = this.props;

    const loadOptions = (inputValue: string, callback: (options: any) => void) => {
      clearTimeout(this.timeoutRef.current);
      this.timeoutRef.current = setTimeout(async () => {
        const res: any = await store!.insight.searchInsights(inputValue, 100);
        if (res?.data?.insights) {
          callback(
            res.data.insights.map((insight) => ({
              key: insight.id,
              value: insight.id,
              label: insight.name,
            })),
          );
        } else {
          callback([]);
        }
      }, 800);
    };

    return (
      <Modal open={isOpen} onClose={close}>
        <Modal.Header>Select Insight</Modal.Header>
        <Modal.Content>
          <p className="text-secondary fs-1250 fw-600 my-2">Search Insight name or paste Insight URL</p>
          <AsyncSelect
            autoFocus
            isClearable
            placeholder="Search name or paste URL"
            cacheOptions
            loadOptions={loadOptions}
            onInputChange={(value: string) => {
              const regex = /^https?:\/\/.+\/insights\/(\d+)/;
              const isURLValid = value.match(regex);
              if (isURLValid) {
                const insightID = isURLValid[1];
                this.selectInsight(insightID); // Embed the insight immediately if user searches by "Paste Insight URL"
                return;
              } else {
                return value;
              }
            }}
            onChange={(option: any) => {
              if (option) {
                // select insight
                this.loadInsightPreview(option.value);
              } else {
                // remove selected insight
                this.setState({ selectedInsightData: undefined });
              }
            }}
            isDisabled={loading}
            styles={customSearchInsightStyles}
            components={{
              Option: CustomOption,
              SingleValue: CustomSingleValue,
              Menu: CustomMenu,
            }}
            className="mb-4"
          />
          <p className="text-secondary fs-1250 fw-600 my-2">Insight Preview</p>
          {loading ? (
            <ContentPlaceholder color="#ffffff" />
          ) : (
            <>
              {selectedInsightData && selectedInsightJSON ? (
                <div className="d-flex justify-content-between align-items-start">
                  {/* Insight Preview */}
                  <Segment className="mr-4 shadow-none w-50">
                    <NewBuilderChartPreview insightJson={selectedInsightJSON} insightTitle={selectedInsightData.name} layout="block" />
                  </Segment>
                  {/* Insight Information */}
                  <div className="w-50">
                    <p className="fs-0875 fw-600 text-medium mb-1">Insight</p>
                    <Label className="fs-0750 mr-2">{`#${selectedInsightData.id}`}</Label>
                    <span className="fs-1000 fw-600 text-dark">{selectedInsightData.name}</span>
                    <Divider />
                    <p className="fs-0875 fw-600 text-medium mb-1">Suitcase</p>
                    <p className="fs-1000 fw-600 text-dark">{selectedInsightData.suitcase_path}</p>
                    <Divider />
                    <p className="fs-0875 fw-600 text-medium mb-1">Owner</p>
                    <div className="d-flex align-items-center">
                      <Avatar
                        style={{ marginRight: 10 }}
                        name={`${selectedInsightData.first_name} ${selectedInsightData.last_name}`}
                        image={selectedInsightData.avatar}
                      />{" "}
                      <p className="fs-1000 fw-600 text-dark">{`${selectedInsightData.first_name} ${selectedInsightData.last_name}`}</p>
                    </div>
                    <Divider />
                    <p className="fs-0875 fw-600 text-medium mb-1">Updated At</p>
                    <p className="fs-1000 fw-600 text-dark">
                      {DateTime.fromISO(selectedInsightData.updated_at).setZone("local").toFormat("h:mma DDD")}
                    </p>
                    <Divider />
                    <a href={`${webUrl}/insights/${selectedInsightData.id}`} target="_blank" className="text-secondary fw-600">
                      Open Insight in a new tab <Icon name="external alternate" />
                    </a>
                  </div>
                </div>
              ) : (
                <p className="fw-600 text-medium">No Insight selected</p>
              )}
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={loading} onClick={close}>
            Cancel
          </Button>
          <Button color="purple" disabled={loading || !selectedInsightData} onClick={() => this.selectInsight()}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export const InsightPickerModal = inject("store")(observer(Component));
