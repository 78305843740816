import * as React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import { Builder } from "common/store/builder";
import { Grid, Icon, SemanticICONS } from "semantic-ui-react";

import { CDecimal, CPercent, CButton, CTextArea, CForm, CButtonDark } from "./insightBuilderStyles/table.style";
import { COLORS } from "component/UI/common";
import { getMixpanel } from "common/api";

type PropsType = RouteComponentProps<any> & {
  store: Builder;
};

export const Calculator = withRouter(
  inject("store")(
    observer(
      class Calculator extends React.Component<PropsType> {
        store = this.props.store;

        render() {
          const { calc } = this.store;

          return (
            <div className="w-100">
              <CForm className="mb-3 position-relative">
                <CTextArea
                  value={calc.eqString}
                  rows={2}
                  placeholder="Type or click to start"
                  // remove any whitespace chars from the string before applying
                  onChange={(e) => (calc.eqString = (e.target.value || "").replace(/\s/g, ""))}
                />
                {calc.eqString && (
                  <Icon
                    name={this.store.calcPossible ? "check circle" : "times circle"}
                    size="small"
                    className={`position-absolute ${this.store.calcPossible ? "text-info" : "text-primary"}`}
                    style={{ top: 17, right: 5 }}
                  />
                )}
              </CForm>
              <div className="mb-3">
                <CDecimal selected={calc.type === "Decimal"} onClick={() => (calc.type = "Decimal")}>
                  0.0
                </CDecimal>
                <CPercent selected={calc.type === "Percent"} onClick={() => (calc.type = "Percent")}>
                  %
                </CPercent>
              </div>
              <Grid columns="equal" style={{ marginLeft: -7, marginRight: -7 }}>
                <Grid.Row style={{ paddingBottom: 0 }}>
                  <Grid.Column className="px-2">
                    <CButtonDark onClick={() => (calc.eqString = "")}>AC</CButtonDark>
                  </Grid.Column>
                  {["(", ")"].map((s) => (
                    <Grid.Column className="px-2">
                      <CButtonDark key={s} onClick={() => (calc.eqString += s)}>
                        {s}
                      </CButtonDark>
                    </Grid.Column>
                  ))}
                  <Grid.Column className="px-2">
                    <CButtonDark onClick={() => (calc.eqString += "/")}>
                      <Icon name={"divide" as SemanticICONS} />
                    </CButtonDark>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: 0 }}>
                  {["7", "8", "9"].map((s) => (
                    <Grid.Column className="px-2">
                      <CButton key={s} onClick={() => (calc.eqString += s)}>
                        {s}
                      </CButton>
                    </Grid.Column>
                  ))}
                  <Grid.Column className="px-2">
                    <CButtonDark onClick={() => (calc.eqString += "*")}>
                      <Icon name="times" />
                    </CButtonDark>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: 0 }}>
                  {["4", "5", "6"].map((s) => (
                    <Grid.Column className="px-2">
                      <CButton key={s} onClick={() => (calc.eqString += s)}>
                        {s}
                      </CButton>
                    </Grid.Column>
                  ))}
                  <Grid.Column className="px-2">
                    <CButtonDark onClick={() => (calc.eqString += "-")}>
                      <Icon name="minus" />
                    </CButtonDark>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: 0 }}>
                  {["1", "2", "3"].map((s) => (
                    <Grid.Column className="px-2">
                      <CButton key={s} onClick={() => (calc.eqString += s)}>
                        {s}
                      </CButton>
                    </Grid.Column>
                  ))}
                  <Grid.Column className="px-2">
                    <CButtonDark onClick={() => (calc.eqString += "+")}>
                      <Icon name="plus" />
                    </CButtonDark>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: 0 }}>
                  <Grid.Column className="px-2">
                    <CButton onClick={() => (calc.eqString = calc.eqString.slice(0, -1))}>
                      <Icon name="times rectangle" className="fs-1250" />
                    </CButton>
                  </Grid.Column>
                  {["0", "."].map((s) => (
                    <Grid.Column className="px-2">
                      <CButton key={s} onClick={() => (calc.eqString += s)}>
                        {s}
                      </CButton>
                    </Grid.Column>
                  ))}
                  <Grid.Column className="px-2">
                    <CButton
                      style={{
                        color: "white",
                        border: "none",
                        background: `${this.store.calcPossible ? COLORS.red500 : "#919191"}`,
                      }}
                      onClick={() => {
                        if (this.store.calcPossible) {
                          this.store.doCalc();
                          const insight = this.store.parent.insight.result;
                          getMixpanel(this.store.parent).track("Create Insight Calculation", {
                            "Insight Id": insight.id,
                            "Insight Name": insight.name,
                          });
                        }
                      }}
                    >
                      =
                    </CButton>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          );
        }
      },
    ),
  ),
);
