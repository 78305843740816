import * as React from "react";
import { Container, Tab, Icon, MenuItem, Menu, List } from "semantic-ui-react";
import { RouteComponentProps } from "react-router";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { uniqueId } from "lodash";
import Store from "common/store";
import { ROW_HEIGHT, RecordTable } from "component/RecordTable/RecordTable";
import {
  StyledTab,
  PaneMenu,
  PaneContent,
  PaneSidebar,
  PaneSidebarActions,
  SidebarTabPaneContent,
  PaneSidebarTab,
} from "pages/DatasetURLUpload/DatasetURLUpload.style";
import { ActionsList } from "./includes/ActionsList";
import { DataPrivacyEdit } from "./includes/DataPrivacyEdit";
import { DataCleaningEdit } from "./includes/DataCleaningEdit";
import { AggregationsList } from "./includes/AggregationsList";
import { ConfigReview } from "./includes/ConfigReview";
import { NotificationDot, StyleSelect } from "./includes/UIComponents";
import { FileMergeEdit } from "./includes/FileMergeEdit";
import { Button } from "component/UI/Button";
import { ValidationList } from "./includes/ValidationList";

const panes = [
  { name: "raw_data_view", label: "Raw Data View", icon: "check circle" },
  { name: "file_merge", label: "File Merge", icon: "columns" },
  { name: "data_privacy", label: "Data Privacy", icon: "eye" },
  { name: "data_cleaning", label: "Data Cleaning", icon: "edit outline" },
  { name: "aggregation", label: "Aggregation", icon: "compass outline" },
  { name: "validation", label: "Validation", icon: "check square outline" },
  { name: "config_view", label: "Configuration View", icon: "clipboard outline" },
];

interface Props extends RouteComponentProps<{ id: string }> {
  store?: Store;
}

export const Component = (props: Props) => {
  const store = props.store!;
  const datasetId = Number(props.match.params.id);
  const { windowHeight } = props.store!.ui;
  const rowsToDisplay = Math.floor((windowHeight - 340) / ROW_HEIGHT);
  const { urlupload } = store.dataset;
  const { raw, staged, mergeOps, transformOps, unmergedRawIdxs, dataset, redo, playbookConfig, skipToLastStep } = urlupload;
  const rawMerged = unmergedRawIdxs.length === 0; // are all merge operations completed - all raw merged

  const [activeRawTable, setActiveRawTable] = React.useState<any>({ value: 0, label: "Table 1" });
  const [activeTabIdx, setActiveTabIdx] = React.useState(playbookConfig?.length ? 5 : 0);

  if (skipToLastStep) {
    setActiveTabIdx(5);
    urlupload.setSkipToLastStep(false);
  }

  let selectTableOptions: any[] = [];
  if (raw) {
    selectTableOptions = raw.map((_t, idx) => ({ value: idx, label: `Table ${idx + 1}` }));
  }
  if (staged) {
    selectTableOptions.unshift({ value: -1, label: "Staged Table" });
  }

  React.useEffect(() => {
    urlupload.init(datasetId);
  }, []);

  // on changes to operations update the left and right table indexes accordingly so correct options can be displayed
  React.useEffect(() => {
    // @TODO: Check if this is still relevant
    // setCleanColSelectedOpt(null);
    // setProtectColSelectedOpts([]);

    // if user undoes an operation where they are no longer on a valid tab idx, update it to suit
    if (!rawMerged && activeTabIdx > 1) {
      setActiveTabIdx(1); // back to merge step
    }
  }, [mergeOps.length, transformOps.length, redo.length]);

  const getPaneContent = (pane, paneIdx) => {
    // if only 1 file uploaded, disable file merge pane and enable the rest
    // if not all files merged, can only access first 2 panes, otherwise can access all
    const menuItem = (
      <MenuItem disabled={rawMerged ? false : paneIdx > 1} key={pane.name} style={{ width: `calc(100% / ${panes.length})` }}>
        <Icon name={pane.icon} /> {pane.label}
      </MenuItem>
    );
    const render = () => (
      <Tab.Pane className="p-0 border-0 d-flex w-100" style={{ height: "calc(100% - 50px)" }}>
        <PaneContent>
          <PaneMenu>
            <div className="d-flex justify-content-between align-items-center">
              {!rawMerged ? (
                <StyleSelect
                  name="select_table"
                  className="mr-3"
                  value={activeRawTable}
                  placeholder="Select or search"
                  options={selectTableOptions}
                  onChange={(selection) => setActiveRawTable(selection)}
                />
              ) : (
                <span className="mx-3">{dataset?.name || "Staged Table"}</span>
              )}
              <div className="d-flex align-items-center">
                <Button.Group size="small" basic={false}>
                  <Button
                    icon="undo"
                    disabled={!(mergeOps.length || transformOps.length)}
                    onClick={async () => await urlupload.undoOperation()}
                  />
                  <Button icon="redo" disabled={!redo.length} onClick={async () => await urlupload.redoOperation()} />
                </Button.Group>
                <Button size="small" basic onClick={() => alert("todo")} icon="ellipsis horizontal" />
              </div>
            </div>
          </PaneMenu>
          {rawMerged || activeRawTable.value === -1 ? (
            <RecordTable
              key={uniqueId("staged_")} // need unique id for staged table as changes with each manipulation
              records={staged!}
              rowsToDisplay={Math.min(rowsToDisplay, staged!.length)}
            />
          ) : (
            <RecordTable
              key={`Table${activeRawTable.value}`}
              records={raw![activeRawTable.value]}
              rowsToDisplay={Math.min(rowsToDisplay, raw![activeRawTable.value].length)}
            />
          )}
        </PaneContent>
        <PaneSidebar>
          {pane.name === "config_view" ? (
            <PaneSidebarTab
              menu={{ secondary: true, pointing: true, fluid: true, widths: 1 }}
              panes={[
                {
                  menuItem: (
                    <Menu.Item>
                      Review Actions <NotificationDot ifShow={urlupload.transformOps.length > 0} />
                    </Menu.Item>
                  ),
                  render: () => (
                    <SidebarTabPaneContent>
                      <ConfigReview />
                    </SidebarTabPaneContent>
                  ),
                },
              ]}
            />
          ) : (
            <>
              <PaneSidebarActions>
                {pane.name === "raw_data_view" && (
                  <>
                    <h4 className="text-secondary">Welcome to Our New Unit Record Data Collection Feature</h4>
                    <p>Great work uploading your data files!</p>
                    <p className="mb-2">Here's where we will step through a few steps to make sure:</p>
                    <List as="ul" className="mt-0">
                      <List.Item as="li">the file has been suitably privacy-protected,</List.Item>
                      <List.Item as="li">unnecessary data has been removed, and</List.Item>
                      <List.Item as="li">the correct data types are being used.</List.Item>
                    </List>
                    <p>If you get stuck at any point, feel free to open our helpful chat in the bottom right corner to access support.</p>
                  </>
                )}
                {pane.name === "file_merge" && (
                  <PaneSidebarTab
                    menu={{ secondary: true, pointing: true, fluid: true, widths: 2 }}
                    panes={[
                      {
                        menuItem: <Menu.Item>Apply Actions</Menu.Item>,
                        render: () => (
                          <SidebarTabPaneContent>
                            <FileMergeEdit />
                          </SidebarTabPaneContent>
                        ),
                      },
                      {
                        menuItem: (
                          <Menu.Item>
                            View Configuration <NotificationDot ifShow={mergeOps.length > 0} />
                          </Menu.Item>
                        ),
                        render: () => (
                          <SidebarTabPaneContent>
                            <ActionsList actions={mergeOps} />
                          </SidebarTabPaneContent>
                        ),
                      },
                    ]}
                  />
                )}
                {pane.name === "data_privacy" && (
                  <PaneSidebarTab
                    menu={{ secondary: true, pointing: true, fluid: true, widths: 2 }}
                    panes={[
                      {
                        menuItem: <Menu.Item>Apply Actions</Menu.Item>,
                        render: () => (
                          <SidebarTabPaneContent>
                            <DataPrivacyEdit />
                          </SidebarTabPaneContent>
                        ),
                      },
                      {
                        menuItem: (
                          <Menu.Item>
                            View Configuration <NotificationDot ifShow={urlupload.transformOps.length > 0} />
                          </Menu.Item>
                        ),
                        render: () => (
                          <SidebarTabPaneContent>
                            <ActionsList actions={urlupload.transformOps} />
                          </SidebarTabPaneContent>
                        ),
                      },
                    ]}
                  />
                )}
                {pane.name === "data_cleaning" && (
                  <PaneSidebarTab
                    menu={{ secondary: true, pointing: true, fluid: true, widths: 2 }}
                    panes={[
                      {
                        menuItem: <Menu.Item>Apply Actions</Menu.Item>,
                        render: () => (
                          <SidebarTabPaneContent>
                            <DataCleaningEdit />
                          </SidebarTabPaneContent>
                        ),
                      },
                      {
                        menuItem: (
                          <Menu.Item>
                            View Configuration <NotificationDot ifShow={urlupload.transformOps.length > 0} />
                          </Menu.Item>
                        ),
                        render: () => (
                          <SidebarTabPaneContent>
                            <ActionsList actions={urlupload.transformOps} />
                          </SidebarTabPaneContent>
                        ),
                      },
                    ]}
                  />
                )}
                {pane.name === "aggregation" && (
                  <PaneSidebarTab
                    menu={{ secondary: true, pointing: true, fluid: true, widths: 1 }}
                    panes={[
                      {
                        menuItem: (
                          <Menu.Item>
                            View Configuration <NotificationDot ifShow={urlupload.aggregations.length > 0} />
                          </Menu.Item>
                        ),
                        render: () => (
                          <SidebarTabPaneContent>
                            <AggregationsList />
                          </SidebarTabPaneContent>
                        ),
                      },
                    ]}
                  />
                )}
                {pane.name === "validation" && (
                  <PaneSidebarTab
                    menu={{ secondary: true, pointing: true, fluid: true, widths: 1 }}
                    panes={[
                      {
                        menuItem: <Menu.Item>Add and view validations</Menu.Item>,
                        render: () => (
                          <SidebarTabPaneContent>
                            <ValidationList />
                          </SidebarTabPaneContent>
                        ),
                      },
                    ]}
                  />
                )}
              </PaneSidebarActions>
              <Button
                colorConfig="aqua"
                fluid
                onClick={() => setActiveTabIdx(paneIdx === 0 ? (staged ? 2 : 1) : ((paneIdx + 1) as number))}
                disabled={rawMerged ? false : paneIdx >= 1}
              >
                <p className="float-left">Proceed To Next Step</p>
                <Icon name="arrow right" className="float-right mt-1" />
              </Button>
            </>
          )}
        </PaneSidebar>
      </Tab.Pane>
    );
    return { menuItem, render };
  };
  return (
    <Container fluid className="w-98 p-4 bg-white rounded-4" style={{ minHeight: 500, height: "calc(100vh - 110px)" }}>
      <div className="d-flex justify-content-between mb-3">
        <h1 className="fs-2000 m-0 text-secondary">Data Configuration</h1>
        <Button colorConfig="red" onClick={() => props.history.push(`/datasets/${datasetId}`)}>
          Close
          <Icon name="times" className="ml-2 mr-0" />
        </Button>
      </div>
      {raw && (
        <StyledTab
          panes={panes.map((pane, paneIdx) => getPaneContent(pane, paneIdx))}
          onTabChange={(_e, { activeIndex }) => setActiveTabIdx(activeIndex as number)}
          activeIndex={activeTabIdx}
          menu={{ attached: false, secondary: true, pointing: true }}
        />
      )}
    </Container>
  );
};

export const DatasetURLUpload = inject("store")(observer(Component));
