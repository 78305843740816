import * as React from "react";
import { IIndicatorModuleData } from "component/Modules/IndicatorModule";
import { RestackingDashboardModule } from "component/Modules/RestackingDashboardModule";

export const RestackingDashboardECECByFive = () => {
  const indicatorData: IIndicatorModuleData[] = [
    {
      id: 1,
      heading: "Quantity: Are sufficient services available?",
      subheading: "Are sufficient services available?",
      description:
        "This relates to the physical access to local services in sufficient quantity. Quantity indicators help to determine the amount of resource and infrastructure needed to deliver the service for a given population at the right quality and dose.",
      indicators: [
        {
          indicatorID: 1,
          indicatorTitle: "Quantity Indicator: ECEC places per target population",
          chart1: {
            id: 102700,
            title: "Proportion of ECEC places per target population (2-5 years)",
          },
          chart2: {
            id: 102698,
            title: "Benchmarks for proportion of ECEC places per target population (2-5 years)",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/13438/d9e5ed52-0f9b-46a7-aee5-ae8de99b277abc74a6b6-ad21-4e98-bde4-885fcf4213d0",
          chart1Explainer: {
            shows:
              "The chart above shows the number of ECEC places for children aged 0-5 years available in the specified provider LGA catchment areas, relative to the population. The orange areas show the gap between places provided and those needed to ensure adequate opportunity for participation.",
            why: "To ensure adequate ECEC places for the population (2-5 years).",
          },
          chart2Explainer: {
            shows:
              "The chart above shows the number of children, aged 0-5 years, who do not have an approved place at an ECEC centre within their LGA catchment area.",
            why: "There should be at least one ECEC place for every child in the local population to ensure adequate opportunity for participation.",
          },
          why: "'Quantity' helps us determine the quantum of effort and infrastructure needed to deliver ECEC adequately for a given population. It takes into account a) infrastructure i.e. are their sufficient places for the defined population to attend for fifteen hours or more and b) workforce i.e. is there a sufficient workforce of qualified ECEC workers and teachers. There should be at least one ECEC place provided for every child in the local catchment area population to attend. This will ensure adequate opportunity for participation.",
        },
        {
          indicatorID: 2,
          indicatorTitle: "Quantity Indicator: Service Provider contribution to ECEC places per target population",
          chart1: {
            id: 102699,
            title: "Proportion of ECEC places per target population (2-5 years)",
          },
          chart2: {},
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/13438/d9e5ed52-0f9b-46a7-aee5-ae8de99b277abc74a6b6-ad21-4e98-bde4-885fcf4213d0",
          chart1Explainer: {
            shows:
              "The chart above shows the number of ECEC places for children aged 0-5 years available in the specified provider LGA catchment areas, relative to the population.",
            why: "To ensure adequate ECEC places for the population (2-5 years).",
          },
          why: "'Quantity' helps us determine the quantum of effort and infrastructure needed to deliver ECEC adequately for a given population. It takes into account a) infrastructure i.e. are their sufficient places for the defined population to attend for fifteen hours or more and b) workforce i.e. is there a sufficient workforce of qualified ECEC workers and teachers. There should be at least one ECEC place provided for every child in the local catchment area population to attend. This will ensure adequate opportunity for participation.",
        },
      ],
    },
    {
      id: 2,
      heading: "Quality: Are we delivering high-quality services?",
      subheading: "Are we delivering high quality services?",
      description:
        "ECEC service quality is associated with improved child outcomes, including cognitive/academic and social/emotional outcomes.",
      indicators: [
        {
          indicatorID: 1,
          indicatorTitle: "Quality Indicator: ECEC centres' RSTO Adjusted National Quality Standard (NQS) rating",
          chart1: {
            id: 102692,
            title:
              "Benchmarks: Proportion of ECEC services rated 'exceeding' the standard in quality areas 1, 4 and 5 and at least 'meeting' the standard in all other quality areas",
          },
          chart2: {
            id: 102690,
            title:
              "Benchmarks: Proportion of ECEC services rated 'exceeding' the standard in quality areas 1, 4 and 5 and at least 'meeting' the standard in all other quality areas",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/13431/d9e5ed52-0f9b-46a7-aee5-ae8de99b277abc74a6b6-ad21-4e98-bde4-885fcf4213d0",
          chart1Explainer: {
            shows:
              "The chart above shows the number of ECEC centres rated 'exceeding', 'meeting', and 'working towards' the National Quality Standard (NQS) in quality areas 1 through 7. \n\n *RSTO evidence indicates that ECEC centres should be 'exceeding' QA1, QA4, and QA5 and at least 'meeting' the standard in all other NQS quality areas.",
            why: "ECEC service quality is associated with improved child outcomes (cognitive/academic and social-emotional).",
          },
          chart2Explainer: {
            shows:
              "The chart above shows the proportion of ECEC centres rated 'exceeding' or greater than 'exceeding' the RSTO Adjusted NQS, plotted against benchmarks and RSTO Evidence. \n\n *RSTO evidence indicates that ECEC centres should be 'exceeding' QA1, QA4, and QA5 and at least 'meeting' the standard in all other NQS quality areas.",
            why: "ECEC service quality areas QA1, QA4 and QA5 are evidence-based indicators that are associated with improved child outcomes (cognitive/academic and social-emotional).",
          },
          why: "A strategy with 'quality' is one for which there is robust evidence showing it delivers the desired outcomes. A large number of research studies have explored aspects of this question (i.e., “What works?”). Quality Areas that had the most robust evidence relating to child development outcomes included QA1 Educational program and practice, QA4 Staffing arrangements, and QA5 Relationships with children. This forms the basis of the RSTO-adjusted National Quality Score (NQS) definition.",
        },
      ],
    },
  ];

  const dashboardData = {
    heading: "Wimmera Southern Mallee",
    subheading: "Early Childhood Education & Care (ECEC)",
    description:
      "Quality early childhood education and care (ECEC) provides valuable play-based opportunities for learning, developmental and social engagement for children before they begin school. Restacking The Odds has identified several evidence-based indicators in this area.",
    strategyLink: "https://www.rsto.org.au/",
    indicatorModuleData: indicatorData,
  };

  return <RestackingDashboardModule {...dashboardData} />;
};
