import { observable, action } from "mobx";
import { database } from "../api";
import Store from "../store";

interface IAlert {
  active: boolean;
  id: number;
  link: string;
  link_title: string;
  text: string;
  type: string;
}

export default class Alert {
  @observable alerts: IAlert[] = [];

  parent: Store;

  constructor(parent: Store) {
    this.parent = parent;
  }

  @action async getAlerts(): Promise<void> {
    const res: any = await database.get("alerts", "", this.parent.token!);

    if (res.body.data) {
      this.alerts = res.body.data.alerts;
    }
  }
}
