import * as React from "react";

interface ICollapsible {
  render: (any) => JSX.Element;
  defaultOpen?: boolean;
}

export const Collapsible = ({ render, defaultOpen = false }: ICollapsible): any => {
  const [isOpen, setIsOpen] = React.useState(defaultOpen);
  return render({ isOpen, setIsOpen });
};
