import * as React from "react";
import { Form } from "semantic-ui-react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components";

const StyledInputRSTO = styled(Form.Input)`
  .ui.input input {
    border: none !important;
  }
  &.error input {
    background: #fdeaea !important;
  }
  .ui.disabled.input {
    opacity: 1 !important;
  }
`;

interface Props extends FieldRenderProps<any> {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  helperText?: string;
}

export const FieldInputRSTO = ({ input, meta, label, placeholder, disabled, helperText, className }: Props): JSX.Element => (
  <Form.Field className={className}>
    {label && <label className={disabled ? "text-medium" : ""}>{label}</label>}
    <StyledInputRSTO
      {...input}
      className="mb-0"
      disabled={!!disabled}
      placeholder={placeholder || ""}
      error={(meta.error || meta.submitError) && meta.touched ? true : undefined}
    />
    {helperText && <small className="text-muted">{helperText}</small>}
    {(meta.error || meta.submitError) && meta.touched && <p className="text-danger fs-0875 mt-1 mb-0">{meta.error || meta.submitError}</p>}
  </Form.Field>
);
