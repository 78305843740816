import { action } from "mobx";
import Suitcase from "./suitcase";
import { database } from "../api";

export default class AddMember {
  parent: Suitcase;

  constructor(parent: Suitcase) {
    this.parent = parent;
  }

  @action async addMembersNew(user_ids: any) {
    if (user_ids.length > 0) {
      await database.post(`suitcases/${this.parent.active!.id}/users`, { accesses: [...user_ids] }, this.parent.parent.token!);
    }
    this.parent.getSuitcases(this.parent.active!.id);
  }

  // removes members from a suitcase using ids collected from the addMember modal
  @action async removeMembers(user_ids: any) {
    for (const user_id of user_ids) {
      await database.delete(`suitcases/${this.parent.active!.id}/users/${user_id}`, {}, this.parent.parent.token!);
    }
    this.parent.getSuitcases(this.parent.active!.id);
  }
}
