import * as React from "react";
import { Header, Icon, Popup } from "semantic-ui-react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store/insightbuilder";

interface IDropdownTitle {
  insightbuilder?: Store | undefined;
  forMultiStep?: boolean;
  heading?: string;
  title: string;
  tooltip: string;
}

const DropdownTitleComponent = ({ insightbuilder, forMultiStep, heading, title, tooltip }: IDropdownTitle): JSX.Element => {
  if (forMultiStep) {
    // Show sentence and description on Multi-step page
    return (
      <>
        <Header as="h3" color="purple">
          {heading}
        </Header>
        {tooltip === "" || <p className="ff-primary fw-400 text-dark fs-1125 mt-0 mb-4">{tooltip}</p>}
      </>
    );
  } else {
    // Show short title and info icon on Insight Builder page
    return (
      <div className="d-flex">
        <Header as="h5" color="purple" className="mb-2">
          {title}
        </Header>
        {tooltip === "" || (
          <Popup
            trigger={<Icon name="info circle" className="text-medium ml-1 cursor-pointer" size="small" />}
            content={tooltip}
            position="right center"
            size="mini"
            inverted
          />
        )}
        {/* Loader for selecting options (insightbuilder.formatOutput()) */}
        {insightbuilder!.loading.selectOptions ? (
          <div style={{ marginLeft: "auto" }}>
            <img src="/assets/small-loader.gif" style={{ height: 24 }} alt="Loading..." />
          </div>
        ) : null}
      </div>
    );
  }
};

export const DropdownTitle = inject((stores: any) => ({
  insightbuilder: stores.store.insightbuilder,
}))(observer(DropdownTitleComponent));
