import * as React from "react";
import { Form } from "semantic-ui-react";
import { FieldRenderProps } from "react-final-form";

interface Props extends FieldRenderProps<any> {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  helperText?: string;
}

export const FieldInput = ({ input, meta, label, placeholder, disabled, helperText }: Props): JSX.Element => (
  <Form.Field>
    {label && <label>{label}</label>}
    <Form.Input
      {...input}
      className="mb-0"
      disabled={!!disabled}
      placeholder={placeholder || ""}
      error={(meta.error || meta.submitError) && meta.touched ? { content: meta.error || meta.submitError, pointing: "above" } : undefined}
    />
    {helperText && <small className="text-muted">{helperText}</small>}
  </Form.Field>
);

export const FieldInputHidden = ({ input }: FieldRenderProps<any>): JSX.Element => <input {...input} className="d-none" />;
