import React = require("react");
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Dropdown, Image, Button, Icon, Grid, Checkbox, ButtonGroup } from "semantic-ui-react";

import Store from "common/store";
import { StyleLabel, StyleSmallText, StyleTerms, StyleInput } from "common/styledComponents/signUpForm.style";
import { StyleAddons, StyleAddonTile } from "./settings.style";
import { $border, $radius } from "common/styledComponents/_config.style";
import { getMixpanel, database } from "common/api";
import EditPicture from "component/popup/editPicture";
import { COLORS } from "component/UI/common";

const orgTypesOptions = [
  "Collective Impact Group",
  "Consultant",
  "Fundraiser",
  "Impact Investor or Business",
  "Local Government",
  "Local Organisation or Backbone",
  "Other",
  "Philanthropic or Government Funder",
  "Policy Maker or Advocate",
  "Service Provider",
  "Start-up or Incubator",
  "State or Federal Government",
].map((text) => ({ text, key: text, value: text }));

export const requestUrl = "https://seerdata-1.hubspotpagebuilder.com/learn-more-about-building-a-custom-plan";

const StyleDropdown = styled(Dropdown)`
  border: ${$border} !important;
  radius: ${$radius} !important;
  &.ui.fluid.dropdown {
    font-size: 15px;
    padding-left: 10px;
  }
  &.ui.search.selection.dropdown > input.search {
    padding-left: 10px !important;
  }
  &.ui.disabled.search {
    border: 1px solid #717171 !important;
  }
`;

const StyleImage = styled(Image)`
  width: 80px;
`;

const Component: React.FC<{ store: Store }> = ({ store }) => {
  const { group_types, user, userPlan, token } = store;
  const [name, setName] = React.useState<string>(user!.group.name);
  const [category, setCategory] = React.useState<string>(user!.group.category);
  const [mfaEnforced, setMfaEnforced] = React.useState<boolean>(user!.group.enforce_mfa);

  const saveButtonDisabled = !category && !name;
  const isOwner = user!.id === user!.group.user_id;
  const isAdmin = user!.group.users.find((u) => u.id === user!.id).admin;

  const planExpired = user!.group.expired;
  let daysUntilExpiration: string | number | undefined;
  if (userPlan !== "Standard" && !planExpired) {
    const expirationDate = new Date(user!.group.expiry);
    const daysDifference = Math.floor((expirationDate.getTime() - Date.now()) / (1000 * 60 * 60 * 24));
    if (daysDifference > 365) {
      daysUntilExpiration = "365+";
    } else {
      daysUntilExpiration = daysDifference;
    }
  }

  const changePlan = () => {
    store.handleUpgradeShow(true);
    getMixpanel(store).track("Settings > Organisation: Change Plan");
  };

  const trackAddonMixpanel = () => {
    getMixpanel(store).track("Settings > Organisation: Change Add-ons");
  };

  const saveOrgChanges = async () => {
    const body: any = { enforce_mfa: mfaEnforced };
    if (name) {
      body.name = name;
    }
    if (category) {
      body.category = category;
    }
    const res: any = await database.put(`groups/${user!.group.id}`, body, token!);
    if (res.statusCode === 200) {
      store!.reloginUserFromAuthToken();
    }
  };

  React.useEffect(() => {
    getMixpanel(store).track("Page view", { Page: "Settings > Organisation > Details" });
  }, []);

  return (
    <>
      <Grid className="mb-0">
        <Grid.Row columns={2} className="mt-0">
          <Grid.Column>
            <StyleLabel id="settingsModalOrgNameLabel">Organisation</StyleLabel>
            <StyleInput
              aria-labelledby="settingsModalOrgNameLabel"
              type="text"
              name="organisation_name"
              disabled={!isOwner}
              defaultValue={name ? name : user!.group.name}
              onChange={(e) => setName(e.target.value)}
            />
            <StyleLabel id="settingsModalOrgTypeDropdownLabel">Organisation type</StyleLabel>
            <StyleDropdown
              aria-labelledby="settingsModalOrgTypeDropdownLabel"
              fluid
              search
              selection
              disabled={!isOwner}
              defaultValue={category ? category : user!.group.category}
              options={orgTypesOptions}
              onChange={(_, e) => setCategory(e.target.value)}
            />
          </Grid.Column>
          <Grid.Column>
            <StyleLabel>Organisation logo</StyleLabel>
            <StyleImage
              bordered
              circular
              src={user!.group.logo || "/assets/seer-default-company-logo.png"}
              alt={user!.group.logo ? `${name ? name : user!.group.name} logo` : "Organisation logo not uploaded"}
            />
            {isOwner ? <EditPicture store={store} type="logo" /> : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className="d-flex align-items-center">
        <StyleLabel>Plan</StyleLabel>
        {planExpired ? (
          <p className="mb-0 fs-1000 ml-2 fw-700" style={{ color: COLORS.red500 }}>
            Expired
          </p>
        ) : (
          <>
            {daysUntilExpiration ? (
              <p className="mb-0 fs-1000 ml-2" style={{ color: COLORS.grey600 }}>
                Expires in {daysUntilExpiration} days
              </p>
            ) : null}
          </>
        )}
      </div>
      <ButtonGroup basic fluid color="purple">
        {[...group_types].reverse().map((group_type, i) => {
          return (
            <Button
              active={group_type.id === user!.group.group_type_id}
              disabled={group_type.id !== user!.group.group_type_id}
              key={i}
              className="cursor-default"
            >
              {group_type.name}
            </Button>
          );
        })}
      </ButtonGroup>
      <StyleSmallText>
        Discover other Seer Data Plans and options
        <StyleTerms onClick={changePlan}> here</StyleTerms>
      </StyleSmallText>
      <StyleLabel>Add-ons</StyleLabel>
      <StyleAddons>
        {" "}
        {user!.group.add_ons.map((addon, i) => (
          <StyleAddonTile key={i}>{addon.name}</StyleAddonTile>
        ))}
      </StyleAddons>
      <StyleSmallText>
        Learn more about Seer Data Add-ons
        <Link to={{ pathname: requestUrl }} target="_blank">
          <StyleTerms onClick={trackAddonMixpanel}> here</StyleTerms>
        </Link>
      </StyleSmallText>
      <div className="my-4">
        <StyleLabel>Enable MFA</StyleLabel>
        <StyleSmallText>When enabled, organisation members will be required to use a password to log in.</StyleSmallText>
        <Checkbox toggle checked={mfaEnforced} disabled={!(isOwner || isAdmin)} onChange={() => setMfaEnforced(!mfaEnforced)} />
      </div>
      {(isOwner || isAdmin) && (
        <Button
          color="purple"
          onClick={async () => {
            await saveOrgChanges();
            getMixpanel(store).track("Save Organisation Changes", {
              Page: "Settings > Organisation > Details",
              Name: name,
              Category: category,
              "Enable MFA": mfaEnforced,
            });
          }}
          disabled={saveButtonDisabled}
        >
          Save
          <Icon name="save" className="ml-1 mr-0" />
        </Button>
      )}
    </>
  );
};

export default inject("store")(observer(Component));
