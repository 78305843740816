import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Dropdown, Icon } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from "common/helpers/chart";
import { requestUrl } from "pages/Settings/includes/Organisations";
import { StyledHeaderDropdown } from "./includes/header.style";
import { PremiumIcon } from "./includes/PremiumIcon";
import { environment } from "common/constants";
import Store from "common/store";

interface Props {
  store?: Store;
}

const MoreFeaturePopupComponent = (props: Props) => {
  const store = props.store!;

  return (
    <StyledHeaderDropdown
      openOnFocus
      direction="left"
      icon={<></>}
      className="mr-3 d-none d-md-block"
      trigger={<Icon name="th" className="text-secondary cursor-pointer" />}
    >
      <Dropdown.Menu>
        {store.userPlan !== "Standard" && (
          <Dropdown.Item as={NavLink} to="/smart-insights" style={{ maxWidth: 220 }}>
            <div className="d-flex align-items-center">
              <p className="fs-1000 fw-600 mb-0" style={{ color: CHART_PRIMARY_COLOR }}>
                Smart Insights
              </p>
            </div>
            <p className="fs-0875 text-wrap" style={{ color: CHART_SECONDARY_COLOR }}>
              Automated insights for your location
            </p>
          </Dropdown.Item>
        )}
        {/* Workaround: add `target="_blank"` to get this link working */}
        <Dropdown.Item
          as={NavLink}
          target="_blank"
          to={store.hasGroupAddOn("Developer Portal") ? "/analyst-portal" : { pathname: requestUrl }}
          style={{ maxWidth: 220 }}
        >
          <div className="d-flex align-items-center">
            <p className="fs-1000 fw-600 mb-0" style={{ color: CHART_PRIMARY_COLOR }}>
              Developer Portal
            </p>
            {!store.hasGroupAddOn("Developer Portal") && <PremiumIcon className="ml-2" />}
          </div>
          <p className="fs-0875 text-wrap" style={{ color: CHART_SECONDARY_COLOR }}>
            Integrate your applications with our API
          </p>
        </Dropdown.Item>
        {/* BESPOKE Item for DPAC KCS Report Upload, only visible DPAC KCS teams and and two specific Seer team members: 820 and 1744 */}
        {([1243].includes(store.user?.group?.id) || [820, 1744].includes(store.user?.id as number) || environment !== "production") && (
          <Dropdown.Item as={NavLink} to="/bespoke/dpac/kcs-report-upload" style={{ maxWidth: 220 }}>
            <div className="d-flex align-items-center">
              <p className="fs-1000 fw-600 mb-0" style={{ color: CHART_PRIMARY_COLOR }}>
                DPAC KCS Report Upload
              </p>
            </div>
            <p className="fs-0875 text-wrap" style={{ color: CHART_SECONDARY_COLOR }}>
              Status report upload form
            </p>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </StyledHeaderDropdown>
  );
};

export const MoreFeaturePopup = inject("store")(observer(MoreFeaturePopupComponent));
