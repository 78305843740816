import * as React from "react";
import { Form } from "semantic-ui-react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components";
import { COLORS } from "component/UI/common";

interface Props extends FieldRenderProps<any> {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  helperText?: string;
}

// This styled component only used for overriding Semantic autocomplete styling
const StyledFormField = styled(Form.Field)`
  .field.seer_input .ui.input {
    input:autofill {
      border: 2px solid ${COLORS.grey300} !important;
      box-shadow: 0 0 0 100px white inset !important;
      background-color: #fff !important;
    }
  }
`;

const StyledFormInput = styled(Form.Input)`
  opacity: 1 !important;
  .ui.input input {
    border: 2px solid ${COLORS.grey300} !important;
  }
  .ui.disabled.input {
    opacity: 1 !important;
    input {
      background: ${COLORS.blue200} !important;
      border: 2px solid ${COLORS.blue200} !important;
    }
  }
  &.error .ui.input input {
    background: ${COLORS.red100} !important;
    border: 2px solid ${COLORS.red300} !important;
  }
`;

export const FieldInput = ({ input, meta, label, placeholder, disabled, helperText, className, id }: Props): JSX.Element => (
  <StyledFormField className={className}>
    {label && (
      <label id={id || ""} className="fs-1000 fw-700 mb-2" style={{ color: COLORS.grey900 }}>
        {label}
      </label>
    )}
    <StyledFormInput
      {...input}
      className="mb-2 seer_input" // Add class to increase selector specificity and override Semantic input autocomplete styling
      disabled={!!disabled}
      placeholder={placeholder || ""}
      error={(meta.error || meta.submitError) && meta.touched ? true : undefined}
      aria-labelledby={id || ""}
    />
    {helperText && (
      <p className="fs-1000 mb-2" style={{ color: COLORS.grey800 }}>
        {helperText}
      </p>
    )}
    {(meta.error || meta.submitError) && meta.touched && (
      <p role="alert" className="fs-1000 mb-0" style={{ color: COLORS.red500 }}>
        {meta.error || meta.submitError}
      </p>
    )}
  </StyledFormField>
);
