import { database } from "common/api";

const MIXPANEL_UTM_KEYWORDS = {
  utm_source: "UTM Source",
  utm_medium: "UTM Medium",
  utm_campaign: "UTM Campaign",
  utm_content: "UTM Content",
  utm_term: "UTM Term",
};

export const getSuitcaseDetail = async (suitcaseID: number, token: string): Promise<any> => {
  const res: any = await database.get(`suitcases/${suitcaseID}`, "", token);
  if (res.body) {
    const { name, owner, users } = res.body.data.suitcase;
    return {
      name,
      ownerName: `${owner.first_name} ${owner.last_name}`,
      ownerOrg: users.find((user) => user.id === owner.id)["org_name"],
    };
  } else {
    return {};
  }
};

export const getInsightDetail = async (insightID: number, token: string): Promise<any> => {
  const res: any = await database.get(`insights/${insightID}`, "", token);
  if (res.body) {
    const { suitcase_id, name, first_name, last_name } = res.body.data.insight;
    return {
      suitcaseID: suitcase_id,
      name,
      ownerName: `${first_name} ${last_name}`,
    };
  } else {
    return {};
  }
};

const getQueryParam = (url, param) => {
  // Expects a raw URL
  param = param.replace(/[[]/, "[").replace(/[]]/, "]");
  const regexS = "[?&]" + param + "=([^&#]*)";
  const regex = new RegExp(regexS);
  const results: any = regex.exec(url);
  if (results === null || (results && typeof results[1] !== "string" && results[1].length)) {
    return "";
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, " ");
  }
};

// Function to get params from the urls
export const getLastTouchUTMParams = (url: string): any => {
  const campaign_keywords = "utm_source utm_medium utm_campaign utm_content utm_term".split(" ");
  let kw = " ";
  const params = {};
  for (const keyword of campaign_keywords) {
    kw = getQueryParam(url, keyword);
    if (kw.length) {
      params[MIXPANEL_UTM_KEYWORDS[keyword] + " (Session)"] = kw;
    }
  }
  return params;
};
