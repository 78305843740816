import * as React from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { composeValidators, mustBeNumber, required } from "common/helpers/finalForm";
import { Button, Form, Icon } from "semantic-ui-react";
import { FieldInput } from "component/FinalFormFields/FieldInput";
import { FieldReactSelect } from "component/FinalFormFields/FieldSelect";

interface Props {
  onSubmit: (values) => void;
}

export const OrgPaidStatus = (props: Props): JSX.Element => {
  const { onSubmit } = props;
  return (
    <FinalForm
      onSubmit={onSubmit}
      render={(formRenderProps) => {
        const { handleSubmit } = formRenderProps;
        return (
          <Form onSubmit={handleSubmit}>
            <h2 className="text-secondary mb-5">Edit organisation plan paid status </h2>
            <div className="mb-5">
              <Field name="group_id" label="Organisation ID" component={FieldInput} validate={composeValidators(required, mustBeNumber)} />
              <Field
                name="paid"
                label="Paid status"
                component={FieldReactSelect}
                options={[
                  { label: "Paid", value: true },
                  { label: "Unpaid", value: false },
                ]}
                validate={composeValidators(required)}
              />
            </div>
            <Button colorConfig="purpleInverted">
              Save
              <Icon name="save" className="ml-2" />
            </Button>
          </Form>
        );
      }}
    />
  );
};
