import * as React from "react";
import { uniqueId } from "lodash";
import {
  MultiRowTable,
  MultiRowTableAddButton,
  MultiRowTableDeleteWrapper,
  MultiRowTableTextarea,
} from "./includes/CustomFormMultiRow.style";
import { Icon } from "semantic-ui-react";

const initialRows = new Array(3).fill(0).map(() => ({ id: uniqueId() }));

export const CustomFormMultiRow = (props): JSX.Element => {
  const { title } = props;
  const [rows, setRows] = React.useState(initialRows);

  const addRowClickHandler = () => {
    setRows([...rows, { id: uniqueId() }]);
  };

  const addRowPressHandler = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      addRowClickHandler();
      e.preventDefault();
    }
  };

  const removeRowClickHandler = (id: string) => {
    const nextRows = [...rows].filter((row) => row.id !== id);
    setRows(nextRows);
  };

  const removeRowPressHandler = (e, id) => {
    if (e.key === "Enter" || e.key === "") {
      removeRowClickHandler(id);
      e.preventDefault();
    }
  };

  return (
    <>
      <MultiRowTable title={title}>
        <thead>
          <tr>
            <th>Gender equality issue</th>
            <th>Impact</th>
            <th>Positive/Negative</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, idx) => (
            <tr key={row.id}>
              <td>
                <MultiRowTableTextarea
                  aria-label={`Add your response to Gender equality issue row${idx + 1}`}
                  placeholder="Your answer"
                  rows={1}
                ></MultiRowTableTextarea>
              </td>
              <td>
                <MultiRowTableTextarea
                  aria-label={`Add your response to Impact row${idx + 1}`}
                  placeholder="Your answer"
                  rows={1}
                ></MultiRowTableTextarea>
              </td>
              <td>
                <MultiRowTableTextarea
                  aria-label={`Add your response to Positive/Negative row${idx + 1}`}
                  placeholder="Your answer"
                  rows={1}
                ></MultiRowTableTextarea>
              </td>
              <td className="action-td">
                <MultiRowTableDeleteWrapper
                  tabIndex={0}
                  role="button"
                  aria-label={`Delete row${idx + 1}`}
                  onClick={() => removeRowClickHandler(row.id)}
                  onKeyDown={(e) => removeRowPressHandler(e, row.id)}
                  className="cursor-pointer"
                >
                  <Icon name="trash alternate outline" className="m-0 fs-1125" />
                </MultiRowTableDeleteWrapper>
              </td>
            </tr>
          ))}
        </tbody>
      </MultiRowTable>
      <MultiRowTableAddButton
        role="button"
        aria-labelledby="addNewRole"
        tabIndex={0}
        onClick={addRowClickHandler}
        onKeyDown={(e) => addRowPressHandler(e)}
      >
        <p id="addNewRole">+ Add another row</p>
      </MultiRowTableAddButton>
    </>
  );
};
