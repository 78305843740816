import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import { inject } from "mobx-react";
import { Container as SUIContainer } from "semantic-ui-react";
import { TemplateTable } from "./includes/TemplateTable";
import { Schema, compileTableCompleteness } from "common/helpers/dataset";
import Store from "common/store";
import Protected from "component/protected";
import { Breadcrumbs } from "component/Breadcrumbs/Breadcrumbs";
import { ShowLoaderGlobal } from "component/LoaderGlobal";
import { getMixpanel } from "common/api";

interface IDatasetTemplateTableModerator {
  store?: Store;
}

export const Container = (props: IDatasetTemplateTableModerator): JSX.Element => {
  const { store } = props;
  const { datasetID, templateID, tableID }: any = useParams();
  const history = useHistory();
  const [data, setData]: any = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const table = data?.dataset_template_table || null;
  const schema: Schema = table?.schema || null;

  const reloadData = async () => {
    setLoading(true);
    const next: any = await store!.dataset.getDatasetTemplateTable(tableID);
    setData(next);
    setLoading(false);
  };
  const updateData = async (jsonData) => {
    setLoading(true);
    const completeness = compileTableCompleteness(jsonData);
    const success: any = await store!.dataset.updateDatasetTemplateTable(tableID, { data: JSON.stringify(jsonData), completeness });
    setLoading(false);
    if (!success) {
      return alert("Failed to save data. Try again later.");
    }
    getMixpanel(store!).track("Update Dataset Template Table Data", {
      "Dataset Id": datasetID,
      "Template Id": templateID,
      "Table Id": tableID,
      "Table Name": table.name,
    });
    reloadData();
  };
  React.useEffect(() => {
    reloadData(); // reload data for table if tableID has changed
  }, [tableID]);

  return (
    <SUIContainer>
      {loading && <ShowLoaderGlobal />}
      {table && (
        <div>
          <Breadcrumbs items={data?.meta?.breadcrumbs || []} />
          <h1 className="mb-2">Editing Table Data</h1>
          <h5 className="mt-0">Table name: <span className="text-muted">{table.name}</span></h5>
          <TemplateTable
            schema={schema}
            rawData={table.data || null}
            handleSave={updateData}
            handleCancel={() => history.push(`/datasets/${datasetID}/templates/${templateID}`)}
          />
        </div>
      )}
    </SUIContainer>
  );
};

export const DatasetTemplateTableDataModerator = Protected(inject("store")(Container));
