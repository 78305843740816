import * as React from "react";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import Comments from "../Comments/comments";
import { RouteComponentProps } from "react-router";
import Editable from "../Editable";
import styled from "styled-components";
import Store from "common/store";
import { Container, SuitcaseTitle, Box } from "./overviewStyles/overview.style";
import { EditName } from "pages/Suitcase/suitcaseOverviewPage.styles";
import { getMixpanel } from "common/api";
import { Button, Dropdown, Header, Icon } from "semantic-ui-react";
import { Heading } from "common/styledComponents/elements";
import { getTinymce } from "@tinymce/tinymce-react/lib/cjs/main/ts/TinyMCE";
import { find } from "lodash";
import { MoveDuplicateModal } from "component/MoveDuplicateModal/MoveDuplicateModal";
import { NewInsightContent } from "component/NewInsightContent";
import { StyledDescription } from "pages/Suitcase/suitcaseOverviewPage";
import { ContentPlaceholder } from "component/ContentPlaceholder";
import { TinymceEditor } from "component/TinymceEditor";
import { Link, Prompt } from "react-router-dom";
import { downloadCsvFile } from "common/helpers/downloadCsvFile";
import { Breadcrumbs } from "component/Breadcrumbs/Breadcrumbs";
import { MetaInfoBar } from "component/MetaInfoBar/MetaInfoBar";
import { getReducedSuitcaseUsers } from "common/helpers/suitcases";
import { COLORS } from "component/UI/common";
import { ContentPageEmptyStateDescription, EmptyStateSecondary } from "component/EmptyState/EmptyState";
import * as copy from "copy-to-clipboard";
import { webUrl } from "common/constants";
import { downloadInsightChart } from "common/helpers/chart";
import { DescriptionWrapper } from "common/styledComponents/descriptionWrapper";

const Hr = styled.div`
  border-bottom: 1px solid #c3c3c3;
`;

const VerticalDash = styled.div`
  width: 2px;
  height: 30px;
  background-color: #c3c3c3;
  margin-left: 15px;
`;

type PropsType = RouteComponentProps<any> & {
  store: Store;
  publicUrlKey: boolean | string;
};

export default withRouter(
  inject("store")(
    observer(
      class InsightOverview extends React.Component<PropsType> {
        state = {
          showEditor: false,
          copyToOpen: false,
          moveToOpen: false,
          showEditorLoader: true,
          forceChart: false, // used to force chart view during NIB insight download
        };

        store = this.props.store;
        insight = this.store.insight;
        iB = this.store.insightbuilder;
        builder = this.store.builder;

        async doAction(type: string) {
          if (type === "Duplicate") {
            this.setState({ copyToOpen: true });
          } else if (type === "Move") {
            this.setState({ moveToOpen: true });
            getMixpanel(this.store).track("Move Insight");
          } else if (type === "Download") {
            // Switch view to chart and wait 250ms for the component to update in the DOM
            this.setState({ forceChart: true });
            await new Promise((r) => setTimeout(r, 500));
            const insightName = this.insight.result.name;
            try {
              // Download the chart png
              this.builder.setDownloadingChart(true);
              await downloadInsightChart(insightName);
              this.builder.setDownloadingChart(false);
              // Download the table csv
              this.setState({ forceChart: false });
              downloadCsvFile(this.builder, insightName);
            } catch (error) {
              console.error("Download Embedded Insight Fail", error);
            }
            getMixpanel(this.store).track("Download Insight");
          } else if (type === "Delete") {
            if (window.confirm("Are you sure you want to delete this insight?")) {
              this.store.suitcase.deleteInsight(this.store.insight.result.id);
              this.props.history.push(`/suitcases/${this.insight.result.suitcase_id}`);
              getMixpanel(this.store).track("Delete Insight");
            }
          } else if (type === "Copy Embed Code") {
            const { key } = this.store.suitcase.active || {};
            const id = this.store.insight.result?.id;
            copy(
              `<iframe src="${webUrl}/embed/insight?url=${webUrl}/insights/${id}/${key}&hideLegend=false" style="width:100%;height:100%;border:none;"></iframe>`,
            );
            alert("Insight Embed Code copied to clipboard.");
          }
          this.iB.showExport = false;
        }

        componentDidMount() {
          // @TODO - investigate why this component is being mounted twice each time for public links (once without insight being initialized)
          if (this.store.insight.result?.name) {
            const _active: any = this.store.suitcase.active;
            const org_name = _active && find(_active.users, (user) => user.id === _active.user_id).org_name;
            const { publicUrlKey } = this.props;
            getMixpanel(this.store).track("Page view", {
              Page: "Insight",
              "Insight ID": this.store.insight.result?.id || undefined,
              "Insight Name": this.store.insight.result?.name || undefined,
              "Insight Owner Name":
                `${this.store.insight.result?.first_name || ""} ${this.store.insight.result?.last_name || ""}`.trim() || undefined,
              "Suitcase ID": this.store.suitcase.active?.id || undefined,
              "Suitcase Name": this.store.suitcase.active?.name || undefined,
              "Suitcase Owner Organisation": org_name || undefined,
              "Public Link": publicUrlKey ? true : false,
            });
          }
        }

        onChange = (content) => content;

        onSubmit = (content) => {
          this.store.insight.updateInfo({ keywords: content });
          this.setEditorDisplay(false);
        };

        setEditorDisplay = (vis: boolean) => {
          this.setState({ showEditor: vis });
        };

        render() {
          const { publicUrlKey } = this.props;
          const isPublicUrl = !!this.props.publicUrlKey;
          const { insight, suitcase } = this.store;
          const activeSuitcase = suitcase.active;
          const readOnlyDuplicate = activeSuitcase?.read_only_duplicate;
          const owner = {
            id: insight.result.user_id,
            avatar: insight.result.avatar,
            first_name: insight.result.first_name,
            last_name: insight.result.last_name,
          };
          const reducedSuitcaseUsers = (activeSuitcase && getReducedSuitcaseUsers(activeSuitcase)) || [];

          const date = insight.result.updated_at;
          const isOwner = this.store.user?.id === activeSuitcase?.user_id;
          const hasFullAccess = isOwner || activeSuitcase?.user_access === "write";
          const readOnlyAccess = activeSuitcase?.user_access ? activeSuitcase.user_access === "read" : true;
          const readOnlyActionsAllowed = !!activeSuitcase?.read_only_duplicate;
          // whether to display actions
          const showActions = !isPublicUrl && activeSuitcase && (hasFullAccess || readOnlyActionsAllowed || activeSuitcase?.is_prepacked);

          // compile which actions to display
          const compileActions = () => {
            if (!showActions) {
              return false;
            }
            const actionsList: string[] = [];
            if (isOwner || hasFullAccess) {
              actionsList.push("Duplicate", "Move", "Download", "Delete");
            } else if (readOnlyDuplicate || readOnlyDuplicate === null) {
              actionsList.push("Duplicate", "Download");
            } else {
              actionsList.push("Download");
              return actionsList;
            }

            if (activeSuitcase?.link_share) {
              actionsList.push("Copy Embed Code");
            }
            return actionsList;
          };
          const actions = compileActions();

          // insight failed to load
          if (!insight.result?.json) {
            return (
              <Container>
                <Header as="h4" className="text-center mb-1">
                  Page is not accessible. Please check your URL.
                </Header>
              </Container>
            );
          }

          // For MoveAndDuplicateModal
          const insightToBeActioned = [
            {
              id: this.store.insight.result?.id,
              name: this.store.insight.result?.name,
            },
          ];

          return (
            <Container>
              {/* Duplicate insights */}
              <MoveDuplicateModal
                type="insight"
                isOpen={this.state.copyToOpen}
                closeModal={() => this.setState({ copyToOpen: false })}
                heading="Duplicate Insight to ..."
                actionText="Duplicate"
                itemsToBeActioned={insightToBeActioned}
                itemsParentSuitcaseID={this.store.insight.result.suitcase_id}
                actionClickHandler={async (parentID) => {
                  const newId = await this.store.insight.saveInsight(parentID, {
                    name: `Copy of ${this.store.insight.result.name}`,
                    code: this.store.insight.result.code,
                    subheading: "",
                    keywords: this.store.insight.result.keywords || "",
                    json: JSON.stringify({
                      ...this.store.builder.json, // copy data
                      date: new Date(),
                      userId: this.store.user!.id,
                    }),
                    ...(this.store.insight.result.banner ? { banner: this.store.insight.result.banner } : {}), // copy banner if exist
                  });
                  getMixpanel(this.store).track("Duplicate Insight");
                  this.setState({ copyToOpen: false });
                  await this.store.insight.load(newId, false);
                  this.props.history.push(`/insights/${newId}`);
                }}
              />
              {/* Move insights */}
              <MoveDuplicateModal
                type="insight"
                isOpen={this.state.moveToOpen}
                closeModal={() => this.setState({ moveToOpen: false })}
                itemsParentSuitcaseID={this.store.insight.result.suitcase_id}
                heading="Move Insight to ..."
                actionText="Move"
                itemsToBeActioned={insightToBeActioned}
                actionClickHandler={async (parentID) => {
                  const insight = this.store.insight.result;
                  if (insight.suitcase_id !== parentID) {
                    await this.store.suitcase.moveInsight(insight.id, insight.suitcase_id, parentID!);
                    insight.suitcase_id = parentID!;
                  }
                  this.setState({ moveToOpen: false });
                  // reload suitcase/s to Update active suitcase to get the the name of the suitcase that the insight moved to
                  this.store.suitcase.getSuitcases(parentID);
                }}
              />
              <Prompt when={this.state.showEditor} message="Are you sure you want to leave? You may have unsaved changes." />
              <div className="d-flex align-items-center">
                <div className="w-100">
                  <Breadcrumbs
                    items={(isPublicUrl ? [] : [{ pathname: "/suitcases", label: "Suitcases" }])
                      .concat(
                        suitcase.active!.breadcrumbs.map((bc) => ({
                          ...bc,
                          pathname: `${bc.pathname}${publicUrlKey ? `/${publicUrlKey}` : ""}`,
                        })),
                      )
                      .concat([{ label: insight.result?.name, pathname: "" }])}
                  />
                </div>
                {showActions && actions && (
                  <Dropdown
                    selectOnBlur={false}
                    text="Actions"
                    value=""
                    selection
                    options={actions.map((text) => ({ key: text, text, value: text }))}
                    onChange={(_, e) => this.doAction(e.value as string)}
                  />
                )}
              </div>
              <Box style={{ marginTop: "20px", marginBottom: "5px" }}>
                <div>
                  <Icon
                    name="suitcase"
                    className="fs-2500 text-left"
                    style={{ color: COLORS.indigo600, marginTop: "-10px", width: "60px", cursor: "pointer" }}
                    onClick={() =>
                      this.props.history.push(
                        isPublicUrl && publicUrlKey
                          ? `/suitcases/${insight.result.suitcase_id}/${publicUrlKey}`
                          : `/suitcases/${insight.result.suitcase_id}`,
                      )
                    }
                  />
                  <span
                    className="sr-only"
                    onClick={() =>
                      this.props.history.push(
                        isPublicUrl && publicUrlKey
                          ? `/suitcases/${insight.result.suitcase_id}/${publicUrlKey}`
                          : `/suitcases/${insight.result.suitcase_id}`,
                      )
                    }
                  >
                    Back to current suitcase
                  </span>
                </div>
                {activeSuitcase && (
                  <SuitcaseTitle>
                    <Link
                      className="text-secondary fw-600"
                      to={
                        isPublicUrl && publicUrlKey
                          ? `/suitcases/${insight.result.suitcase_id}/${publicUrlKey}`
                          : `/suitcases/${insight.result.suitcase_id}`
                      }
                    >
                      {activeSuitcase.id === insight.result.suitcase_id ? activeSuitcase.name : "Suitcase"}
                    </Link>
                  </SuitcaseTitle>
                )}
              </Box>
              <VerticalDash />
              <Box style={{ marginBottom: "20px" }}>
                <div>
                  <Icon
                    name="lightbulb outline"
                    className="fs-2500 text-left"
                    style={{ marginLeft: "5px", color: COLORS.indigo600, marginRight: "16px", width: "40px", lineHeight: "52px" }}
                  />
                  <span className="sr-only">Insight</span>
                </div>
                <div className="w-100">
                  <Editable
                    id="insightName"
                    text={insight.result.name}
                    save={(name) => insight.updateInfo({ name })}
                    readOnly={isPublicUrl || readOnlyAccess || activeSuitcase?.id !== this.store.insight.result.suitcase_id}
                    style={{
                      color: `${insight.result.name.includes("Untitled") ? "#919191" : COLORS.indigo600}`,
                      padding: "0",
                      fontWeight: "bold",
                      fontSize: "2.25rem",
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      borderColor: "#C3C3C3",
                    }}
                  />
                </div>
              </Box>
              {!isPublicUrl && (
                <>
                  <MetaInfoBar
                    updated={date}
                    roleLabel="OWNER"
                    custodian={owner}
                    usersSharedWith={reducedSuitcaseUsers.filter((user) => user.id !== owner.id)}
                  />
                  <Hr />
                </>
              )}

              {/* Insight Description */}
              {(!readOnlyAccess || insight.result.keywords?.length > 0) && (
                <EditName desc style={{ marginTop: 20, marginBottom: 40, width: "100%" }}>
                  <div className="pdf-hide mt-4 mb-4 d-sm-flex align-items-sm-center justify-content-sm-between">
                    <Heading>Summary</Heading>
                    {!isPublicUrl && !readOnlyAccess && (
                      <>
                        {this.state.showEditor ? (
                          <div>
                            <Button onClick={() => this.setEditorDisplay(false)}>
                              Cancel
                              <Icon name="archive" className="ml-2" />
                            </Button>
                            <Button color="purple" onClick={() => this.onSubmit(getTinymce(window)?.activeEditor?.getContent())}>
                              Save
                              <Icon name="save" className="ml-2" />
                            </Button>
                          </div>
                        ) : (
                          <>
                            {!insight.result.keywords ? (
                              <Button onClick={() => this.setEditorDisplay(true)}>
                                Write summary
                                <Icon name="edit" className="ml-2" />
                              </Button>
                            ) : (
                              <Button onClick={() => this.setEditorDisplay(true)}>
                                Edit summary
                                <Icon name="edit" className="ml-2" />
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  {this.state.showEditor ? (
                    <>
                      {this.state.showEditorLoader && <ContentPlaceholder />}
                      <TinymceEditor description={insight.result.keywords} initHandler={() => this.setState({ showEditorLoader: false })} />
                    </>
                  ) : (
                    <>
                      {activeSuitcase?.id !== this.store.insight.result.suitcase_id || insight.result.keywords ? (
                        <DescriptionWrapper>
                          <StyledDescription dangerouslySetInnerHTML={{ __html: this.insight.result.keywords }} />
                        </DescriptionWrapper>
                      ) : (
                        <EmptyStateSecondary
                          inverted
                          hideIcon
                          heading="No Insight Summary Yet"
                          description={<ContentPageEmptyStateDescription onClick={() => this.setEditorDisplay(true)} />}
                        />
                      )}
                    </>
                  )}
                </EditName>
              )}
              {/* End Insight Description */}

              {/* Insight Data Table & Chart */}
              <NewInsightContent readonly={readOnlyAccess || isPublicUrl} forceChart={this.state.forceChart} />

              {/* Comments */}
              <Hr style={{ margin: "50px 0 70px 0" }} />
              {!isPublicUrl && this.store.insight.comments.type === "insights" && (
                <>
                  <Header as="h3" color="purple">
                    Comments
                  </Header>
                  <Hr />
                  <Comments store={this.store} readonly={readOnlyAccess} />
                </>
              )}
            </Container>
          );
        }
      },
    ),
  ),
);
