import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { cloneDeep } from "lodash";
import Store from "common/store";
import { StyleSelect, ColumnLabelGroup } from "./UIComponents";
import { Button, Dropdown, Icon, Modal } from "semantic-ui-react";
import { ParsedRecords } from "common/helpers/file";

interface IFileMergeEdit {
  store?: Store;
}

const FileMergeEditComponent = (props: IFileMergeEdit): JSX.Element => {
  const { store } = props;
  const urlupload = store!.dataset.urlupload;
  const { raw, staged, mergeOps, transformOps, unmergedRawIdxs, redo } = urlupload;
  const rawMerged = unmergedRawIdxs.length === 0; // are all merge operations completed - all raw merged

  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const [mergeLeftIdx, setMergeLeftIdx] = React.useState<number | null>(0);
  const [mergeRightIdx, setMergeRightIdx] = React.useState<number>(1);
  const [mergeLeftColSelectedOpt, setmergeLeftColSelectedOpt] = React.useState<any | null>(null);
  const [mergeRightColSelectedOpt, setMergeRightColSelectedOpt] = React.useState<any | null>(null);
  const [joins, setJoins] = React.useState<[string, string][]>([]); // [<left col>, <right col>][] - join columns
  const leftJoinColumns = joins.map(([leftColName]) => leftColName);
  const rightJoinColumns = joins.map(([, rightColName]) => rightColName);

  let mergeLeftColOptions: any[] = [];
  let mergeRightColOptions: any[] = [];
  if (!rawMerged) {
    const leftTable = (typeof mergeLeftIdx === "number" ? raw![mergeLeftIdx] : staged) as ParsedRecords;
    const rightTable = raw![mergeRightIdx] as ParsedRecords;
    if (leftTable?.length && rightTable?.length) {
      mergeLeftColOptions = leftTable[0].filter((col) => !leftJoinColumns.includes(col)).map((col, idx) => ({ value: idx, label: col }));
      mergeRightColOptions = rightTable[0].filter((col) => !rightJoinColumns.includes(col)).map((col, idx) => ({ value: idx, label: col }));
    }
  }

  React.useEffect(() => {
    // reset form values
    const nextLeftIdx = staged ? null : 0;
    setMergeLeftIdx(nextLeftIdx);
    // find next unmerged idx for form (if any) otherwise fallback to initial value - idx 1
    const nextUnmergedRawIdx = unmergedRawIdxs.filter((idx: number) => ![nextLeftIdx, mergeRightIdx].includes(idx))[0] || 1;
    setMergeRightIdx(nextUnmergedRawIdx as number);
    setmergeLeftColSelectedOpt(null);
    setMergeRightColSelectedOpt(null);
    setJoins([]);
    setConfirmModalOpen(false);
  }, [mergeOps.length, transformOps.length, redo.length]);

  // when a user removes all join from within the modal, close it
  React.useEffect(() => {
    if (!joins.length && confirmModalOpen) {
      setConfirmModalOpen(false);
    }
  }, [joins.length, confirmModalOpen]);

  return (
    <>
      {rawMerged ? (
        <>All files have been merged.</>
      ) : (
        <>
          <p>
            Select the joining column from{" "}
            <Dropdown text={staged ? "Staged Table" : `Table ${mergeLeftIdx! + 1}`} disabled={!!staged || !!joins.length}>
              <Dropdown.Menu>
                <Dropdown.Item key="staged" disabled>
                  Staged Table
                </Dropdown.Item>
                {raw!.map((_, idx) => (
                  <Dropdown.Item
                    key={idx}
                    disabled={!unmergedRawIdxs.includes(idx) || idx === mergeRightIdx}
                    onClick={() => {
                      setMergeLeftIdx(idx);
                      setmergeLeftColSelectedOpt(null);
                    }}
                  >
                    Table {idx + 1}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </p>
          <div className="mb-3">
            <StyleSelect
              isClearable
              name="joining_col"
              value={mergeLeftColSelectedOpt}
              placeholder="Select or search"
              options={mergeLeftColOptions}
              onChange={(selection) => setmergeLeftColSelectedOpt(selection)}
            />
          </div>
          <p>
            Select the related column from{" "}
            <Dropdown text={`Table ${mergeRightIdx + 1}`} disabled={unmergedRawIdxs.length < 2 || !!joins.length}>
              <Dropdown.Menu>
                {unmergedRawIdxs.map((_, idx) => (
                  <Dropdown.Item
                    key={idx}
                    disabled={idx === mergeLeftIdx}
                    onClick={() => {
                      setMergeRightIdx(idx);
                      setMergeRightColSelectedOpt(null);
                    }}
                  >
                    Table {idx + 1}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </p>
          <div className="mb-3">
            <StyleSelect
              isClearable
              name="related_col"
              value={mergeRightColSelectedOpt}
              placeholder="Select or search"
              options={mergeRightColOptions}
              onChange={(selection) => setMergeRightColSelectedOpt(selection)}
            />
          </div>

          {!!joins.length && (
            <p className="fw-bold">
              {joins.length} join{joins.length > 1 ? "s" : ""} added *
            </p>
          )}

          <div className="d-flex justify-content-between">
            <Button
              disabled={!mergeLeftColSelectedOpt || !mergeRightColSelectedOpt}
              onClick={() => {
                setJoins([...joins, [mergeLeftColSelectedOpt.label, mergeRightColSelectedOpt.label]]);
                setmergeLeftColSelectedOpt(null);
                setMergeRightColSelectedOpt(null);
              }}
            >
              Add Join
              <Icon name="chain" className="ml-2 mr-0" />
            </Button>
            <Button
              disabled={!joins.length}
              onClick={() => {
                setConfirmModalOpen(true);
              }}
            >
              Merge Tables
              <Icon name="columns" className="ml-2 mr-0" />
            </Button>
          </div>

          <Modal open={confirmModalOpen} closeIcon={false} onClose={() => setConfirmModalOpen(false)}>
            <Modal.Header>Join Configuration</Modal.Header>
            <Modal.Content className="my-4">
              <div className="d-flex justify-content-between mb-2">
                <div className="text-center w-100">Table 1</div>
                <div className="text-center w-100">Table 2</div>
              </div>
              {joins.map((join, idx) => (
                <ColumnLabelGroup
                  // we only infer types on the staged table
                  items={join.map((col) => ({ type: "string", label: col }))}
                  onRemove={() => {
                    const newJoins = cloneDeep(joins);
                    newJoins.splice(idx, 1);
                    setJoins(newJoins);
                  }}
                  borderColor="##61C0C2"
                  backgroundColor="#E4F2F1"
                />
              ))}
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setConfirmModalOpen(false)}>Go back</Button>
              <Button
                color="purple"
                onClick={() => {
                  urlupload.applyMergeOpPreview({
                    left: mergeLeftIdx,
                    right: mergeRightIdx,
                    leftColNames: leftJoinColumns,
                    rightColNames: rightJoinColumns,
                    type: "left join",
                  });
                  setmergeLeftColSelectedOpt(null);
                  setMergeRightColSelectedOpt(null);
                  setJoins([]);
                  setConfirmModalOpen(false);
                }}
              >
                Proceed with merge <Icon name="arrow right" />
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )}
    </>
  );
};

export const FileMergeEdit = inject("store")(observer(FileMergeEditComponent));
