import * as React from "react";
import { Confirm } from "semantic-ui-react";
import styled from "styled-components";
import { COLORS } from "./UI/common";

interface IActionConfirm {
  open: boolean;
  paletteName: string;
  setConfirmOpen: (open: boolean) => void;
  confirmAction: () => void;
}

const StyleActionConfirm = styled(Confirm)`
  max-width: 600px;
  &.ui.modal > .header {
    font-size: 22px !important;
    color: ${COLORS.indigo600};
  }
  &.ui.modal > .content {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .ui.button {
    border: 1px solid ${COLORS.red500};
    background-color: #ffffff;
    color: ${COLORS.red500};
  }
  .ui.primary.button,
  .ui.primary.buttons .button {
    background-color: ${COLORS.red500};
    color: #ffffff;
  }
`;

const ActionConfirmComponent = ({ open, paletteName, setConfirmOpen, confirmAction }: IActionConfirm): JSX.Element => {
  const handleConfirm = () => {
    setConfirmOpen(false);
    confirmAction();
  };

  return (
    <StyleActionConfirm
      size="small"
      open={open}
      header="Are you sure you want to delete this color palette?"
      content={paletteName}
      onCancel={() => setConfirmOpen(false)}
      onConfirm={handleConfirm}
    />
  );
};

export const ActionConfirm = ActionConfirmComponent;
