import { observable, action, computed } from "mobx";
import Store from "../store";
import { database, getMixpanel } from "../api";
import AddMember from "./addMember";
import { IComment } from "./insight";
import { SUITCASES_TAB } from "common/helpers/suitcases";
import { ObjectAny } from "common/helpers/types";

// note this is the structure of the response from get suitcase, list suitcase response data is different, see below
export interface IProject {
  breadcrumbs: IBreadcrumb[];
  comments: IComment[];
  created_at: string;
  creator: IMember;
  creator_id: number;
  dashboard_config?: ObjectAny;
  deleted_at: string | null;
  description?: string | null;
  files: IFile[];
  highlight?: boolean;
  id: number;
  insight_order: string;
  insights: IInsight[];
  invitations: IMember[];
  is_prepacked?: boolean;
  is_shared?: boolean;
  key: string | null;
  link_share: boolean;
  name: string;
  number_child_suitcases?: number;
  number_descendent_suitcases?: number;
  number_insights_recursive?: number;
  owner?: IMember;
  parent_id: number;
  private: boolean;
  read_only_duplicate?: boolean | null;
  updated_at: string;
  user_access?: "owner" | "write" | "read";
  user_id: number;
  user_owns_ancestor?: boolean;
  users: IMember[];
  users_implied: IMember[];
  suitcase_org_id?: number;
}

// note this is a suitcase response from list suitcase endpoint, not get one single suitcase which has different data, see above
export interface IListProject {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  owner?: IMember;
  highlight?: boolean;
  is_shared?: boolean;
  is_prepacked?: boolean;
  key: string | null;
  link_share: boolean;
  number_child_suitcases: number;
  number_insights_recursive: number;
  parent_id: number | null;
  private: boolean;
  read_only_duplicate: boolean | null;
  user_access?: "owner" | "write" | "read";
  user_id: number;
}

interface IFile {
  banner: string | undefined;
  file: string; // URL
  name: string;
  id: number;
  created_at: string;
}

export interface IMember {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  avatar?: string;
  read_only?: boolean;
  org_id?: number;
  org_name?: string;
  deactivated?: boolean;
  subscribed?: boolean;
  organisation?: any;
}

interface IInsight {
  created_at: any;
  updated_at: string;
  id: number;
  key: string;
  keywords: string;
  name: string;
  subheading: string;
  user_id: number;
  banner: string;
}

interface IArrows {
  [key: string]: boolean;
}

interface IBreadcrumb {
  label: string;
  pathname: string;
}

export default class Suitcase {
  @observable activeSuitcasesTab: number = SUITCASES_TAB.SHARED;
  @observable projects: IProject[] = []; // Suitcases
  @observable prepackedprojects: IProject[] = []; // Suitcases
  @observable active?: IProject; // Active suitcase
  @observable arrows: IArrows = {
    about: true,
    data: true,
    private: true,
    public: true,
    other: true,
    members: true,
  }; // Collapse booleans
  @observable loading: boolean = false; // Get&Create suitcase loading
  @observable loadingModal: boolean = false;
  @observable mouseDown: boolean = false; // Mouse down for grabbing insights => turns into grab hand
  @observable files: boolean = false; // Whether files have been selected for uploading

  parent: Store;
  addMember: AddMember = new AddMember(this);

  constructor(parent: Store) {
    this.parent = parent;
  }

  @action setActiveSuitcasesTab(tab: number): void {
    this.activeSuitcasesTab = tab;
  }

  @action generateProject(project: IProject) {
    return project;
  }

  @action changeActiveProject(project: IProject) {
    this.active = this.generateProject(project);
    this.arrows = { ...this.arrows, about: true, data: true, blend: true };
  }
  // this function is getting available suitcases and populating the projects / prepacked projects array
  @action async getSuitcases(id?: any, clearState = false) {
    if (id === -1) {
      return; // @TODO - investigate why suitcase_id defaults to -1, or why load is ever called with -1, see src/app/common/store/insight.ts:80
    }
    this.loading = true;
    // use when loading /suitcases so no other suitcases displayed
    if (clearState) {
      this.active = undefined;
      this.projects = [];
      this.prepackedprojects = [];
    }

    // load suitcases
    const listRes: any = await database.get(`suitcases${id ? `?parentId=${id}` : ""}`, "", this.parent.token!)
      .catch((_) => this.loading = false);
    if (listRes.body.data) {
      const projects = listRes.body.data.suitcases;
      // Store private projects and non private projects separately
      const privateProjects = projects.filter(project => project.private === true);
      const nonPrivateProjects = projects.filter(project => project.private !== true);
      // Append 'Draft Insights' suitcase(private === true) to the start of all projects(suitcases)
      this.projects = [...privateProjects, ...nonPrivateProjects];
      const prepackedprojects = listRes.body.data.prepacked_suitcases;
      this.prepackedprojects = prepackedprojects;
      if (!this.projects.length) {
        this.parent.dataset.info.show = true;
      }
    }

    if (id) {
      const viewRes: any = await database.get(`suitcases/${id}`, "", this.parent.token!)
        .catch((_) => this.loading = false);
      if (viewRes.body.data) {
        this.active = this.generateProject(viewRes.body.data.suitcase);
      }
    }

    this.loading = false;
  }

  // public suitcase link load - return true for success
  @action async getPublicSuitcase(id: number, key: string) {
    this.loading = true;
    // first load suitcases for public key so we can also show child suitcases if exist on suitcase page, only needs to happen once
    if (!this.projects.length) {
      const resCases: any = await database.get(`suitcases?publicKey=${key}`);
      if (!resCases.body.data) {
        return false;
      }
      this.projects = resCases.body.data.suitcases.map(sc => ({
        // this endpoint doesn't append related fields to suitcase but most of our current frontend code is expecting it
        ...sc,
        comments: [],
        insights: [],
        users: [],
        users_implied: [],
      }));
    }

    const resCase: any = await database.get(`suitcases/${id}/${key}`);
    this.loading = false;
    if (resCase.body.data) {
      const project = resCase.body.data.suitcase;
      this.active = this.generateProject(project);
      return true;
    }
    return false;
  }

  @action async createSuitcase(name: string, description: string, users: any, parentSuitcaseId?: number) {
    this.loading = true;
    const reqBody = { name, description };
    if (parentSuitcaseId) {
      reqBody["parent_id"] = parentSuitcaseId;
    }
    const res: any = await database.post("suitcases", reqBody, this.parent.token!);
    const { suitcase } = res.body.data;
    if (users.length) {
      await database.post(
        `suitcases/${suitcase.id}/users`,
        { user_ids: users.map((user: any) => user.value) },
        this.parent.token!
      );
    }
    this.loading = false;
    this.parent.socket.emit("updateSuitcases", {
      ids: users.map((user: any) => user.value),
    });
    getMixpanel(this.parent).track("Create Suitcase");
    return suitcase.id;
  }

  @action async updateInfo(info: any, forceReload = false) {
    Object.keys(info).forEach(async key => {
      if (this.active![key] !== info[key]) {
        this.active![key] = info[key];
        await database.put(`suitcases/${this.active!.id}`, info, this.parent.token!);
        this.parent.socket.emit("updateSuitcases", {
          ids: this.active!.users.map(user => user.id).filter(id => id !== this.parent.user?.id),
        });
        if (forceReload) {
          await this.getSuitcases(this.active!.id);
        }
      }
    });
  }

  @action async deleteInsight(id: number) {
    await database.delete(`insights/${id}`, { id }, this.parent.token!);
    this.parent.socket.emit("updateSuitcases", {
      ids: this.active!.users.map(user => user.id).filter(id => id !== this.parent.user?.id),
    });
  }

  @action toggleArrow(type: string) {
    this.arrows[type] = !this.arrows[type];
  }

  @computed get sortedInsights() {
    if (this.active && this.active.insights.length > 0) {
      const insightOrder = JSON.parse(this.active.insight_order);
      const { insights } = this.active;

      // No ordering specified
      if (!insightOrder || insightOrder.length === 0) {
        return insights;
      }

      const orderedInsights: IInsight[] = [];
      insightOrder.forEach(ordered => {
        const insight = insights.find(insight => insight.id === ordered);
        if (insight) {
          orderedInsights.push(insight);
        }
      });
      const remainingInsights: IInsight[] = insights.filter(insight => !insightOrder.includes(insight.id));

      return orderedInsights.concat(remainingInsights);
    } else {
      return [];
    }
  }

  @action async deleteSuitcase(id: number) {
    const users = [...this.active!.users, ...this.active!.users_implied];
    await database.delete(`suitcases/${id}`, "", this.parent.token!);
    // this.active!.id === id ? this.getSuitcases() : this.getSuitcases(this.active!.id);
    this.parent.socket.emit("updateSuitcases", {
      ids: users.map(user => user.id).filter(id => id !== this.parent.user?.id),
    });
  }

  @action async moveInsight(insight_id: number, o_id: number, n_id: number) {
    // Moving an insight from one suitcase to another
    const [ oldSuitcase, newSuitcase ] =
      await Promise.all(
        [o_id, n_id].map(async id => {
          const res: any = await database.get(`suitcases/${id}`, "", this.parent.token!);
          return res?.body?.data?.suitcase;
        })
      );
    await database.put(`insights/${insight_id}`, { suitcase_id: n_id }, this.parent.token!);
    const oldUsers = oldSuitcase && [...oldSuitcase.users, ...oldSuitcase.users_implied] || [];
    const newUsers = newSuitcase && [...newSuitcase.users, ...newSuitcase.users_implied] || [];
    this.parent.socket.emit("updateSuitcases", {
      ids: oldUsers.map(user => user.id).filter(id => id !== this.parent.user?.id),
    });
    this.parent.socket.emit("updateSuitcases", {
      ids: newUsers.map(user => user.id).filter(id => id !== this.parent.user?.id),
    });
  }

  @action async moveSuitcase(suitcaseID: number, destination: number | null) {
    const res: any = await database.get(`suitcases/${suitcaseID}`, "", this.parent.token!);
    const suitcase = res?.body?.data?.suitcase;
    const users = suitcase && [...suitcase.users, ...suitcase.users_implied] || [];
    await database.put(`suitcases/${suitcaseID}`, {parent_id: destination}, this.parent.token!);
    this.getSuitcases(suitcaseID);
    this.parent.socket.emit("updateSuitcases", {
      ids: users.map(user => user.id).filter(id => id !== this.parent.user?.id),
    });
  }

  @action async bulkMoveSuitcases(suitcaseIds: number[], destination: number | null) {
    const suitcases = this.projects.filter(s => s.id in suitcaseIds);
    const users = suitcases.reduce((acc, s) => [...acc, ...s.users, ...s.users_implied], new Array<IMember>());
    await database.put("suitcases/batch", suitcaseIds.map(id => ({ id, parent_id: destination })), this.parent.token!);
    this.getSuitcases(this.active?.id);
    this.parent.socket.emit("updateSuitcases", {
      ids: users.map(user => user.id).filter(id => id !== this.parent.user?.id),
    });
  }

  @action toggleAllArrows(to: boolean, type: string) {
    let arrows = {};
    type === "top"
      ? (arrows = {
          about: to,
          private: to,
          public: to,
          other: to,
          members: to,
        })
      : (arrows = { data: to });
    Object.assign(this.arrows, arrows);
  }

  @action async deleteFile(id: number) {
    await database.delete(`files/${id}`, "", this.parent.token!);
    this.getSuitcases(this.active!.id);
  }

  @computed get memberIds() {
    return this.active!.users.map(user => user.id).filter(id => id !== this.parent.user!.id);
  }

  @computed get suitcaseTypes() {
    return [
      {
        type: "private",
        list: this.projects.filter(p => p.user_id === this.parent.user!.id && p.users.length === 1),
        title: "Your Private Suitcases",
      },
      {
        type: "public",
        list: this.projects.filter(p => p.user_id === this.parent.user!.id && p.users.length !== 1),
        title: "Your Suitcases that others are connected to",
      },
      {
        type: "other",
        list: this.projects.filter(p => p.user_id !== this.parent.user!.id),
        title: "Other's Suitcases that you are connected to",
      },
    ];
  }

  getSuitcaseData = async (id: number): Promise<any | undefined> => {
    const res: any = await database.get(`suitcases/${id}`, "", this.parent.token!);
    return res?.body?.data?.suitcase;
  };

  // Search Suitcases by suitcase name or by the owner's first and last name
  searchSuitcases = async (searchString: string, limit: number, skip?: number): Promise<any> => {
    const url = `v2/search/suitcases?q=${encodeURIComponent(searchString)}${skip ? `&$skip=${skip}` : ""}&$limit=${limit}`;
    const res: any = await database.get(url, "", this.parent.token!);
    return res?.body;
  };
}
