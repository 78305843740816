import * as React from "react";
import { Button, Modal } from "semantic-ui-react";
import { inject, observer } from "mobx-react";
import { uniqueId } from "lodash";
import Select from "react-select";
import Store from "common/store";
import { getMixpanel, database, datasets } from "common/api";
import { toolName as insightToolName } from "component/Unlayer/includes/customJsInsightTool";
import { sortDimensionValues, transformResToDims } from "common/helpers/data";

interface ComponentProps {
  store?: Store;
  isOpen: boolean;
  data: any;
  close: () => any;
  editorRef: any;
}

interface IState {
  loading: {
    variable: boolean;
    dataset: boolean;
    category: boolean;
    insight: boolean;
  };
  selectionData: any;
  allDatasets: any;
  variableOptionData: any;
  datasetOptions: any;
  categoryOptions: any;
  insightOptions: any;
}

class Component extends React.Component<ComponentProps, IState> {
  state: IState = {
    loading: {
      variable: false,
      dataset: false,
      category: false,
      insight: false,
    },
    selectionData: undefined,
    allDatasets: undefined,
    variableOptionData: undefined,
    datasetOptions: undefined,
    categoryOptions: undefined,
    insightOptions: undefined,
  };

  saveFilter = async () => {
    const { editorRef, close, data } = this.props;
    const { selectionData } = this.state;
    const { variable } = selectionData;
    editorRef.frame.iframe.contentWindow.postMessage(
      {
        ...data,
        data: selectionData,
      },
      "https://editor.unlayer.com",
    );
    getMixpanel(this.props.store!).track("Save Filter to Dashboard", { Variable: variable });
    close();
  };

  onSelectVariable = async (newValue: any) => {
    const { loading, allDatasets } = this.state;
    this.setState({
      loading: { ...loading, dataset: true },
      datasetOptions: undefined,
      categoryOptions: undefined,
    });
    const { store } = this.props;
    const nextSelectionData = newValue?.data;
    const { variable } = nextSelectionData;
    const { insightOptions } = this.state;
    // Get unique dataset keys from embedded insights that use the selected variable
    const embeddedInsightDatasetKeys = await Promise.all(
      insightOptions.map((insight) => store?.insight.getInsightData(insight.value)),
    ).then((insights) => {
      const datasetKeys = Array.from(
        new Set(
          insights.reduce((acc, cur) => {
            try {
              const insightJson = JSON.parse(cur.json);
              const currentDatasetKeys: string[] = [];
              insightJson.tables.forEach((table) => {
                const tableVariables = [
                  ...table.filters.map((filter) => filter.dimension),
                  ...table.columns.map((column) => column.dimension),
                  ...table.rows.map((row) => row.dimension),
                ];
                if (tableVariables.includes(variable)) {
                  currentDatasetKeys.push(table.userSelectedDatasets[0]);
                }
              });
              return [...acc, ...currentDatasetKeys];
            } catch (e) {
              return acc; // ignore when broken json
            }
          }, []),
        ),
      );
      return datasetKeys;
    });
    // Compile dataset options for the selected variable
    const datasetOptions = allDatasets
      .filter((dataset) => embeddedInsightDatasetKeys.includes(dataset.key))
      .map((dataset) => ({ value: dataset.key, label: dataset.name }));
    this.setState({
      loading: { ...loading, dataset: false },
      selectionData: nextSelectionData,
      datasetOptions,
    });
  };

  onSelectDataset = async (newValue: any) => {
    const { loading } = this.state;
    this.setState({ loading: { ...loading, category: true } });
    const { selectionData } = this.state;
    const nextSelectionData = { ...selectionData, dataset: newValue.value, categories: [] };
    // Get category options for the selected dataset and selected variable
    const { variable } = selectionData;
    const res: any = await datasets.post("v2/qs", { filters: { [variable]: [] }, dataset: newValue.value }, this.props.store!.token!);
    let categoryOptions: any[] = [];
    if (res?.body?.data?.dataset) {
      const dims = sortDimensionValues(transformResToDims(res));
      categoryOptions = dims[variable].values.map((value) => ({ value: value.name, label: value.name }));
    }
    this.setState({
      loading: { ...loading, category: false },
      selectionData: nextSelectionData,
      categoryOptions,
    });
  };

  onSelectCategories = (newValue: any) => {
    const { selectionData, categoryOptions } = this.state;
    const isSelectAll = newValue.some((opt) => opt.value === "Select All");
    const categories = isSelectAll ? categoryOptions.map((opt) => opt.value) : newValue.map((opt) => opt.value);
    this.setState({ selectionData: { ...selectionData, categories } });
  };

  onSelectInsights = (newValue: any) => {
    const { selectionData } = this.state;
    const nextSelectionData = { ...selectionData, insights: newValue.map((opt) => opt.value) };
    this.setState({ selectionData: nextSelectionData });
  };

  // loads variable options and embedded insights options
  init = async () => {
    const { loading } = this.state;
    this.setState({ loading: { ...loading, variable: true, insight: true } });
    const { editorRef, store } = this.props;
    const insights: any = await new Promise((resolve) => {
      editorRef.exportHtml(async (editorData) => {
        // get unique embedded insights: { id, name }
        const embeddedInsights = (editorData?.design?.body?.rows || [])
          .reduce((prev, next) => [...prev, ...(next.columns || [])], [])
          .reduce((prev, next) => [...prev, ...(next.contents || [])], [])
          .filter((content) => content.slug === insightToolName);
        const uniqueInsights = Array.from(
          new Set(
            embeddedInsights
              .map((config) => {
                let id = 0;
                let name = "";
                try {
                  const { id: parsedId, name: parsedName } = JSON.parse(config.values.insightData);
                  id = parsedId;
                  name = parsedName;
                } catch (e) {
                  // continue if broken json, id = 0 will be filtered out
                }
                return { id, name };
              })
              .filter((insight) => !!insight.id),
          ),
        );
        resolve(uniqueInsights);
      });
    });
    // load unique insights and compile option data
    const variableMap = {};
    for (const { id } of insights) {
      try {
        const res: any = await database.get(`insights/${id}`, null, store!.token!);
        const json = JSON.parse(res.body.data.insight.json);
        const filters = [
          ...(json?.columns || []),
          ...(json?.tables || []).reduce((prev, next) => [...prev, ...(next.rows || []), ...(next.filters || [])], []),
        ];
        for (const filter of filters) {
          variableMap[filter.dimension] = [...(variableMap[filter.dimension] || []), ...filter.values];
        }
      } catch (e) {
        // continue and ignore any broken insights
      }
    }
    const optionData: any = [];
    // filter down unique categories and sort them
    for (const variable of Object.keys(variableMap)) {
      optionData.push({ variable, filterId: uniqueId() });
    }
    // sort options by variable name before finishing
    optionData.sort((a, b) => a.variable.localeCompare(b.variable));
    // compile insight options
    const insightOptions = insights
      .map((insight) => ({ value: insight.id, label: insight.name }))
      .sort((a, b) => a.label.localeCompare(b.label));
    // get all datasets
    const datasetsRes: any = await datasets.get("v2/search", {}, store!.token!);
    let allDatasets = [];
    if (datasetsRes?.body?.data?.datasets) {
      allDatasets = datasetsRes?.body?.data?.datasets;
    }
    this.setState({
      loading: { ...loading, variable: false, insight: false },
      allDatasets,
      variableOptionData: optionData,
      insightOptions,
    });
  };

  componentDidUpdate(prevProps: ComponentProps): void {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ selectionData: undefined });
      if (this.props.isOpen) {
        this.init();
      }
    }
  }

  render(): JSX.Element {
    const { loading, selectionData, variableOptionData, categoryOptions, insightOptions, datasetOptions } = this.state;
    const { isOpen, close } = this.props;
    const variableOptions = (variableOptionData || []).map((opt) => ({
      value: opt.variable,
      label: opt.variable,
      data: opt,
    }));
    const selectedVariable = selectionData?.variable ? variableOptions.find((opt) => opt.value === selectionData.variable) : null;
    const selectedDataset = selectionData?.dataset ? datasetOptions?.find((opt) => opt.value === selectionData.dataset) : null;
    const prependSelectAllCategoryOptions = categoryOptions ? [{ value: "Select All", label: "Select All" }, ...categoryOptions] : [];
    const selectedCategories = selectionData?.categories
      ? categoryOptions?.filter((opt) => selectionData.categories.includes(opt.value))
      : [];
    const selectedInsights = selectionData?.insights ? insightOptions.filter((opt) => selectionData.insights.includes(opt.value)) : [];
    const isLoading = Object.keys(loading).some((key) => loading[key]);

    return (
      <Modal open={isOpen} onClose={close}>
        <Modal.Header>Select Filter</Modal.Header>
        <Modal.Content>
          <p className="text-secondary fs-1250 fw-600 my-2">Select a variable to filter on</p>
          <Select
            autoFocus
            placeholder="Select a variable to filter on"
            options={variableOptions}
            onChange={this.onSelectVariable}
            isDisabled={loading.variable || !variableOptionData}
            isLoading={loading.variable}
            value={selectedVariable}
            className="mb-4"
          />
          <p className="text-secondary fs-1250 fw-600 my-2">Select a Dataset</p>
          <Select
            placeholder="Select a Dataset"
            options={datasetOptions}
            onChange={this.onSelectDataset}
            isDisabled={loading.dataset || !datasetOptions}
            isLoading={loading.dataset}
            value={selectedDataset}
            className="mb-4"
          />
          <p className="text-secondary fs-1250 fw-600 my-2">Select categories to make available</p>
          <Select
            isMulti
            placeholder="Select categories to make available"
            options={prependSelectAllCategoryOptions}
            value={selectedCategories}
            onChange={this.onSelectCategories}
            isDisabled={loading.category || !categoryOptions}
            isLoading={loading.category}
            className="mb-4"
          />
          <p className="text-secondary fs-1250 fw-600 my-2">Select Insights to filter on</p>
          <Select
            isMulti
            placeholder="Select Insights to filter on"
            options={insightOptions}
            onChange={this.onSelectInsights}
            isDisabled={loading.insight || !insightOptions}
            isLoading={loading.insight}
            value={selectedInsights}
          />
          <p className="fs-0875">If no Insights are selected, the filter will apply to all insights.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button className="mr-2" disabled={isLoading} onClick={close}>
            Cancel
          </Button>
          <Button color="purple" disabled={isLoading || !selectionData?.categories?.length} onClick={this.saveFilter}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export const FilterPickerModal = inject("store")(observer(Component));
