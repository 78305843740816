/* script related helpers */

// inserts a script dynamically to document head, ensuring only one copy is inserted based on an identifier
// example usage: await insertScript("unlayer", { type: "text/javascript", src: "https://editor.unlayer.com/embed.js" });
export const insertScript = async (identifier: string, attributes: Record<string, any>): Promise<void> => {
  await new Promise<void>((resolve) => {
    if (!window[`seerScriptInsert_${identifier}`]) {
      window[`seerScriptInsert_${identifier}`] = true; // don't insert twice for each unique identifier
      const script = document.createElement("script");
      Object.keys(attributes).map((attribute) => (script[attribute] = attributes[attribute])); // set attributes (src, async etc)
      script.addEventListener("load", () => resolve());
      document.head.appendChild(script);
    } else {
      resolve(); // script already inserted
    }
  });
};
