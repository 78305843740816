import styled from "styled-components";

export const Main = styled.div`
  width: 940px;
  max-width: 100%;
  padding: 0 10px;
  padding-bottom: 20px;
  margin: 0 auto;
  position: relative;
`;
