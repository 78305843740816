import * as React from "react";
import { Button, Checkbox, Divider, Form, Grid, Header, Modal, Tab } from "semantic-ui-react";
import { inject, observer } from "mobx-react";
import { cloneDeep, set, uniqueId } from "lodash";
import Select from "react-select";
import Store from "common/store";
import { getMixpanel, database, datasets } from "common/api";
import { toolName as insightToolName } from "component/Unlayer/includes/customJsInsightTool";
import { sortDimensionValues, transformResToDims } from "common/helpers/data";
import styled from "styled-components";
import { DashFilterOptions, fontWeightSemanticOpts, navFonts, navFontsSemanticOpts } from "pages/Dashboard/includes/helpers";
import { ColorPicker } from "pages/Dashboard/includes/ModalNavConfig";

interface ComponentProps {
  store?: Store;
  isOpen: boolean;
  data: any;
  close: () => any;
  editorRef: any;
  dashFilterOptions?: any;
}

interface IState {
  loading: {
    general: boolean; // Ensures all elements in the Settings tab are loaded before allowing tab switching
    variable: boolean;
    dataset: boolean;
    category: boolean;
    insight: boolean;
  };
  selectionData: any;
  allDatasets: any;
  variableOptionData: any;
  datasetOptions: any;
  categoryOptions: any;
  insightOptions: any;
  filterOptions: DashFilterOptions;
  activeIndex: number;
}

const StyledTabPane = styled(Tab.Pane)`
  border: none !important;
  box-shadow: none !important;
`;

const defaultDashFilterOptions = {
  styling: {
    label: {
      fontFamily: "Open Sans",
      fontWeight: 400,
      fontSize: 14,
      fontUrl: "none",
    },
    padding: 14,
    border: {
      width: 1,
      radius: 4,
      color: "#dadada",
      colorHover: "#022CC3",
    },
    tag: {
      colorText: "#3E3E3E",
      colorBg: "#EEEEEE",
    },
  },
};

class Component extends React.Component<ComponentProps, IState> {
  state: IState = {
    loading: {
      general: false,
      variable: false,
      dataset: false,
      category: false,
      insight: false,
    },
    selectionData: undefined,
    allDatasets: undefined,
    variableOptionData: undefined,
    datasetOptions: undefined,
    categoryOptions: undefined,
    insightOptions: undefined,
    filterOptions: defaultDashFilterOptions,
    activeIndex: 0,
  };

  updateFilterOptions = (updates) => {
    const nextFilterOptions = cloneDeep(this.state.filterOptions);
    for (const update of updates) {
      const [path, value] = update;
      set(nextFilterOptions, path, value);
    }
    this.setState({ filterOptions: nextFilterOptions });
  };

  saveFilter = async () => {
    const { editorRef, close, data } = this.props;
    const { selectionData, filterOptions } = this.state;
    const { variable } = selectionData;
    editorRef.frame.iframe.contentWindow.postMessage(
      {
        ...data,
        data: {
          ...selectionData,
          ...filterOptions,
        },
      },
      "https://editor.unlayer.com",
    );
    getMixpanel(this.props.store!).track("Save Filter to Dashboard", { Variable: variable });
    close();
  };

  onSelectVariable = async (newValue: any) => {
    const { allDatasets } = this.state;
    this.setState((prevState) => ({
      loading: { ...prevState.loading, dataset: true },
      datasetOptions: undefined,
      categoryOptions: undefined,
    }));
    const { store } = this.props;
    const nextSelectionData = newValue?.data;
    const { variable } = nextSelectionData;
    const { insightOptions } = this.state;
    // Get unique dataset keys from embedded insights that use the selected variable
    const embeddedInsightDatasetKeys = await Promise.all(
      insightOptions.map((insight) => store?.insight.getInsightData(insight.value)),
    ).then((insights) => {
      const datasetKeys = Array.from(
        new Set(
          insights.reduce((acc, cur) => {
            try {
              const insightJson = JSON.parse(cur.json);
              const currentDatasetKeys: string[] = [];
              insightJson.tables.forEach((table) => {
                const tableVariables = [
                  ...table.filters.map((filter) => filter.dimension),
                  ...table.columns.map((column) => column.dimension),
                  ...table.rows.map((row) => row.dimension),
                ];
                if (tableVariables.includes(variable)) {
                  currentDatasetKeys.push(table.userSelectedDatasets[0]);
                }
              });
              return [...acc, ...currentDatasetKeys];
            } catch (e) {
              return acc; // ignore when broken json
            }
          }, []),
        ),
      );
      return datasetKeys;
    });
    // Compile dataset options for the selected variable
    const datasetOptions = allDatasets
      .filter((dataset) => embeddedInsightDatasetKeys.includes(dataset.key))
      .map((dataset) => ({ value: dataset.key, label: dataset.name }));
    this.setState((prevState) => ({
      loading: { ...prevState.loading, dataset: false },
      selectionData: nextSelectionData,
      datasetOptions,
    }));
  };

  onSelectDataset = async (newValue: any) => {
    this.setState((prevState) => ({ loading: { ...prevState.loading, category: true } }));
    const { selectionData } = this.state;
    const nextSelectionData = { ...selectionData, dataset: newValue.value, categories: [] };
    // Get category options for the selected dataset and selected variable
    const { variable } = selectionData;
    const res: any = await datasets.post("v2/qs", { filters: { [variable]: [] }, dataset: newValue.value }, this.props.store!.token!);
    let categoryOptions: any[] = [];
    if (res?.body?.data?.dataset) {
      const dims = sortDimensionValues(transformResToDims(res));
      categoryOptions = dims[variable].values.map((value) => ({ value: value.name, label: value.name }));
    }
    this.setState((prevState) => ({
      loading: { ...prevState.loading, category: false },
      selectionData: nextSelectionData,
      categoryOptions,
    }));
  };

  onSelectCategories = (newValue: any) => {
    const { selectionData, categoryOptions } = this.state;
    const isSelectAll = newValue.some((opt) => opt.value === "Select All");
    const categories = isSelectAll ? categoryOptions.map((opt) => opt.value) : newValue.map((opt) => opt.value);
    this.setState({ selectionData: { ...selectionData, categories } });
  };

  onSelectInsights = (newValue: any) => {
    const { selectionData } = this.state;
    const nextSelectionData = { ...selectionData, insights: newValue.map((opt) => opt.value) };
    this.setState({ selectionData: nextSelectionData });
  };

  // loads variable options and embedded insights options
  init = async () => {
    this.setState((prevState) => ({ loading: { ...prevState.loading, variable: true, insight: true } }));
    const { editorRef, store } = this.props;
    const insights: any = await new Promise((resolve) => {
      editorRef.exportHtml(async (editorData) => {
        // get unique embedded insights: { id, name }
        const embeddedInsights = (editorData?.design?.body?.rows || [])
          .reduce((prev, next) => [...prev, ...(next.columns || [])], [])
          .reduce((prev, next) => [...prev, ...(next.contents || [])], [])
          .filter((content) => content.slug === insightToolName);
        const uniqueInsights = Array.from(
          new Set(
            embeddedInsights
              .map((config) => {
                let id = 0;
                let name = "";
                try {
                  const { id: parsedId, name: parsedName } = JSON.parse(config.values.insightData);
                  id = parsedId;
                  name = parsedName;
                } catch (e) {
                  // continue if broken json, id = 0 will be filtered out
                }
                return { id, name };
              })
              .filter((insight) => !!insight.id),
          ),
        );
        resolve(uniqueInsights);
      });
    });
    // load unique insights and compile option data
    const variableMap = {};
    for (const { id } of insights) {
      try {
        const res: any = await database.get(`insights/${id}`, null, store!.token!);
        const json = JSON.parse(res.body.data.insight.json);
        const filters = [
          ...(json?.columns || []),
          ...(json?.tables || []).reduce((prev, next) => [...prev, ...(next.rows || []), ...(next.filters || [])], []),
        ];
        for (const filter of filters) {
          variableMap[filter.dimension] = [...(variableMap[filter.dimension] || []), ...filter.values];
        }
      } catch (e) {
        // continue and ignore any broken insights
      }
    }
    const optionData: any = [];
    // filter down unique categories and sort them
    for (const variable of Object.keys(variableMap)) {
      optionData.push({ variable, filterId: uniqueId() });
    }
    // sort options by variable name before finishing
    optionData.sort((a, b) => a.variable.localeCompare(b.variable));
    // compile insight options
    const insightOptions = insights
      .map((insight) => ({ value: insight.id, label: insight.name }))
      .sort((a, b) => a.label.localeCompare(b.label));
    // get all datasets
    const datasetsRes: any = await datasets.get("v2/search", {}, store!.token!);
    let allDatasets = [];
    if (datasetsRes?.body?.data?.datasets) {
      allDatasets = datasetsRes?.body?.data?.datasets;
    }
    this.setState((prevState) => ({
      loading: { ...prevState.loading, variable: false, insight: false },
      allDatasets,
      variableOptionData: optionData,
      insightOptions,
    }));
  };

  async componentDidUpdate(prevProps: ComponentProps): Promise<void> {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ selectionData: undefined, filterOptions: defaultDashFilterOptions, activeIndex: 0 });
      if (this.props.isOpen) {
        this.setState((prevState) => ({ loading: { ...prevState.loading, general: true } }));
        await this.init();
        // pre-populate filter options when editing filters;
        if (this.props.dashFilterOptions) {
          const { styling, filterId, variable, dataset, categories, insights } = this.props.dashFilterOptions;
          await this.onSelectVariable({ label: variable, value: variable, data: { filterId, variable } });
          const nextSelectionData: any = { filterId, variable };
          if (dataset) {
            await this.onSelectDataset({ label: dataset, value: dataset });
            nextSelectionData.dataset = dataset;
            nextSelectionData.categories = categories;
          }
          if (insights) {
            nextSelectionData.insights = insights;
          }
          const nextFilterOptions = styling ? { styling } : defaultDashFilterOptions;
          this.setState({
            selectionData: nextSelectionData,
            filterOptions: nextFilterOptions,
          });
        }
        this.setState((prevState) => ({ loading: { ...prevState.loading, general: false } }));
      }
    }
  }

  render(): JSX.Element {
    const { loading, selectionData, variableOptionData, categoryOptions, insightOptions, datasetOptions, filterOptions, activeIndex } =
      this.state;
    const { isOpen, close } = this.props;
    const variableOptions = (variableOptionData || []).map((opt) => ({
      value: opt.variable,
      label: opt.variable,
      data: opt,
    }));
    const selectedVariable = selectionData?.variable ? variableOptions.find((opt) => opt.value === selectionData.variable) : null;
    const selectedDataset = selectionData?.dataset ? datasetOptions?.find((opt) => opt.value === selectionData.dataset) : null;
    const prependSelectAllCategoryOptions = categoryOptions ? [{ value: "Select All", label: "Select All" }, ...categoryOptions] : [];
    const selectedCategories = selectionData?.categories
      ? categoryOptions?.filter((opt) => selectionData.categories.includes(opt.value))
      : [];
    const selectedInsights = selectionData?.insights ? insightOptions.filter((opt) => selectionData.insights.includes(opt.value)) : [];
    const isLoading = Object.keys(loading).some((key) => loading[key]);
    const { styling } = filterOptions;

    return (
      <Modal open={isOpen} onClose={close}>
        <Modal.Header>Filter configuration</Modal.Header>
        <Modal.Content>
          <Tab
            activeIndex={activeIndex}
            onTabChange={loading.general ? undefined : (_, { activeIndex }) => this.setState({ activeIndex: activeIndex as number })} // Prevent users from switching tabs while loading data for Settings tab
            menu={{ secondary: true, pointing: true, widths: 2 }}
            panes={[
              {
                menuItem: "Settings",
                render: () => (
                  <StyledTabPane>
                    <p className="text-secondary fs-1250 fw-600 my-2">Select a variable to filter on</p>
                    <Select
                      autoFocus
                      placeholder="Select a variable to filter on"
                      options={variableOptions}
                      onChange={this.onSelectVariable}
                      isDisabled={loading.variable || !variableOptionData}
                      isLoading={loading.variable}
                      value={selectedVariable}
                      className="mb-4"
                    />
                    <p className="text-secondary fs-1250 fw-600 my-2">Select a Dataset</p>
                    <Select
                      placeholder="Select a Dataset"
                      options={datasetOptions}
                      onChange={this.onSelectDataset}
                      isDisabled={loading.dataset || !datasetOptions}
                      isLoading={loading.dataset}
                      value={selectedDataset}
                      className="mb-4"
                    />
                    <p className="text-secondary fs-1250 fw-600 my-2">Select categories to make available</p>
                    <Select
                      isMulti
                      placeholder="Select categories to make available"
                      options={prependSelectAllCategoryOptions}
                      value={selectedCategories}
                      onChange={this.onSelectCategories}
                      isDisabled={loading.category || !categoryOptions}
                      isLoading={loading.category}
                      className="mb-4"
                    />
                    <p className="text-secondary fs-1250 fw-600 my-2">Select Insights to filter on</p>
                    <Select
                      isMulti
                      placeholder="Select Insights to filter on"
                      options={insightOptions}
                      onChange={this.onSelectInsights}
                      isDisabled={loading.insight || !insightOptions}
                      isLoading={loading.insight}
                      value={selectedInsights}
                    />
                    <p className="fs-0875">If no Insights are selected, the filter will apply to all insights.</p>
                  </StyledTabPane>
                ),
              },
              {
                menuItem: "Dropdown styling",
                render: () => (
                  <StyledTabPane>
                    <Header as="h4">Dropdown text label</Header>
                    <Grid columns={2}>
                      <Grid.Column>
                        <Form className="w-100 w-md-50">
                          <Form.Select
                            label="Font family"
                            options={navFontsSemanticOpts}
                            value={styling.label.fontFamily}
                            onChange={(_, { value }) => {
                              const nextFont = navFonts.find((font) => font.value === value)!;
                              this.updateFilterOptions([
                                ["styling.label.fontFamily", nextFont.value],
                                ["styling.label.fontUrl", nextFont.url],
                              ]);
                            }}
                          />
                          <Form.Select
                            label="Font weight"
                            options={fontWeightSemanticOpts}
                            value={styling.label.fontWeight}
                            onChange={(_, { value }) => {
                              this.updateFilterOptions([["styling.label.fontWeight", value]]);
                            }}
                          />
                          <Form.Input
                            label="Font size (px)"
                            type="number"
                            value={styling.label.fontSize}
                            onChange={(_, { value }) => {
                              this.updateFilterOptions([["styling.label.fontSize", value ? Number(value) : 1]]);
                            }}
                            min={1}
                          />
                        </Form>
                      </Grid.Column>
                    </Grid>
                    <Divider />
                    <Header as="h4">Dropdown styling</Header>
                    <Grid>
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Header as="h5">Border colour</Header>
                        <ColorPicker
                          color={styling.border.color}
                          updateColor={(color) => {
                            this.updateFilterOptions([["styling.border.color", color]]);
                          }}
                        />
                        <Header as="h5">Border hover color</Header>
                        <ColorPicker
                          color={styling.border.colorHover}
                          updateColor={(color) => {
                            this.updateFilterOptions([["styling.border.colorHover", color]]);
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <div className="flex-column flex-md-row d-flex justify-content-between mb-3 mb-md-0">
                          <Header as="h5">Padding (px)</Header>
                          <Checkbox
                            toggle
                            label="More options"
                            checked={typeof styling.padding !== "number"}
                            onClick={() => {
                              if (typeof styling.padding !== "number") {
                                this.updateFilterOptions([["styling.padding", styling.padding.top]]);
                              } else {
                                const value = styling.padding;
                                this.updateFilterOptions([
                                  [
                                    "styling.padding",
                                    {
                                      top: value,
                                      left: value,
                                      bottom: value,
                                      right: value,
                                    },
                                  ],
                                ]);
                              }
                            }}
                          />
                        </div>
                        <Form className="mb-4">
                          {typeof styling.padding === "number" ? (
                            <Form.Input
                              label="All sides"
                              type="number"
                              value={styling.padding}
                              onChange={(_e, { value }) => {
                                this.updateFilterOptions([["styling.padding", value ? Number(value) : 0]]);
                              }}
                              min={0}
                            />
                          ) : (
                            <>
                              <Form.Group>
                                <Form.Input
                                  label="Top"
                                  type="number"
                                  value={styling.padding.top}
                                  onChange={(_e, { value }) => {
                                    this.updateFilterOptions([["styling.padding.top", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                  className="w-50"
                                />
                                <Form.Input
                                  label="Right"
                                  type="number"
                                  value={styling.padding.right}
                                  onChange={(_e, { value }) => {
                                    this.updateFilterOptions([["styling.padding.right", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                  className="w-50"
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Input
                                  label="Bottom"
                                  type="number"
                                  value={styling.padding.bottom}
                                  onChange={(_e, { value }) => {
                                    this.updateFilterOptions([["styling.padding.bottom", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                  className="w-50"
                                />
                                <Form.Input
                                  label="Left"
                                  type="number"
                                  value={styling.padding.left}
                                  onChange={(_e, { value }) => {
                                    this.updateFilterOptions([["styling.padding.left", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                  className="w-50"
                                />
                              </Form.Group>
                            </>
                          )}
                        </Form>
                        <div className="flex-column flex-md-row d-flex justify-content-between mb-3 mb-md-0">
                          <Header as="h5">Border width (px)</Header>
                          <Checkbox
                            toggle
                            label="More options"
                            checked={typeof styling.border.width !== "number"}
                            onClick={() => {
                              if (typeof styling.border.width !== "number") {
                                this.updateFilterOptions([["styling.border.width", styling.border.width.top]]);
                              } else {
                                const value = styling.border.width;
                                this.updateFilterOptions([
                                  [
                                    "styling.border.width",
                                    {
                                      top: value,
                                      left: value,
                                      bottom: value,
                                      right: value,
                                    },
                                  ],
                                ]);
                              }
                            }}
                          />
                        </div>
                        <Form className="mb-4">
                          {typeof styling.border.width === "number" ? (
                            <Form.Input
                              label="All sides"
                              type="number"
                              value={styling.border.width}
                              onChange={(_e, { value }) => {
                                this.updateFilterOptions([["styling.border.width", value ? Number(value) : 0]]);
                              }}
                              min={0}
                            />
                          ) : (
                            <>
                              <Form.Group>
                                <Form.Input
                                  label="Top"
                                  type="number"
                                  value={styling.border.width.top}
                                  onChange={(_e, { value }) => {
                                    this.updateFilterOptions([["styling.border.width.top", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                  className="w-50"
                                />
                                <Form.Input
                                  label="Right"
                                  type="number"
                                  value={styling.border.width.right}
                                  onChange={(_e, { value }) => {
                                    this.updateFilterOptions([["styling.border.width.right", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                  className="w-50"
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Input
                                  label="Bottom"
                                  type="number"
                                  value={styling.border.width.bottom}
                                  onChange={(_e, { value }) => {
                                    this.updateFilterOptions([["styling.border.width.bottom", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                  className="w-50"
                                />
                                <Form.Input
                                  label="Left"
                                  type="number"
                                  value={styling.border.width.left}
                                  onChange={(_e, { value }) => {
                                    this.updateFilterOptions([["styling.border.width.left", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                  className="w-50"
                                />
                              </Form.Group>
                            </>
                          )}
                        </Form>
                        <div className="flex-column flex-md-row d-flex justify-content-between mb-3 mb-md-0">
                          <Header as="h5">Border radius (px)</Header>
                          <Checkbox
                            toggle
                            label="More options"
                            checked={typeof styling.border.radius !== "number"}
                            onClick={() => {
                              if (typeof styling.border.radius !== "number") {
                                this.updateFilterOptions([["styling.border.radius", styling.border.radius.top]]);
                              } else {
                                const value = styling.border.radius;
                                this.updateFilterOptions([
                                  [
                                    "styling.border.radius",
                                    {
                                      top: value, // Stands for top left for radius
                                      right: value, // Stands for top right for radius
                                      bottom: value, // Stands for bottom right for radius
                                      left: value, // Stands for bottom left for radius
                                    },
                                  ],
                                ]);
                              }
                            }}
                          />
                        </div>
                        <Form className="mb-4">
                          {typeof styling.border.radius === "number" ? (
                            <Form.Input
                              label="All corners"
                              type="number"
                              value={styling.border.radius}
                              onChange={(_e, { value }) => {
                                this.updateFilterOptions([["styling.border.radius", value ? Number(value) : 0]]);
                              }}
                              min={0}
                            />
                          ) : (
                            <>
                              <Form.Group>
                                <Form.Input
                                  label="Top left"
                                  type="number"
                                  value={styling.border.radius.top}
                                  onChange={(_e, { value }) => {
                                    this.updateFilterOptions([["styling.border.radius.top", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                  className="w-50"
                                />
                                <Form.Input
                                  label="Top right"
                                  type="number"
                                  value={styling.border.radius.right}
                                  onChange={(_e, { value }) => {
                                    this.updateFilterOptions([["styling.border.radius.right", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                  className="w-50"
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Input
                                  label="Bottom right"
                                  type="number"
                                  value={styling.border.radius.bottom}
                                  onChange={(_e, { value }) => {
                                    this.updateFilterOptions([["styling.border.radius.bottom", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                  className="w-50"
                                />
                                <Form.Input
                                  label="Bottom left"
                                  type="number"
                                  value={styling.border.radius.left}
                                  onChange={(_e, { value }) => {
                                    this.updateFilterOptions([["styling.border.radius.left", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                  className="w-50"
                                />
                              </Form.Group>
                            </>
                          )}
                        </Form>
                      </Grid.Column>
                    </Grid>
                    <Divider />
                    <Header as="h4">Selected category tag styling</Header>
                    <Header as="h5">Text colour</Header>
                    <ColorPicker
                      color={styling.tag.colorText}
                      updateColor={(color) => {
                        this.updateFilterOptions([["styling.tag.colorText", color]]);
                      }}
                    />
                    <Header as="h5">Background colour</Header>
                    <ColorPicker
                      color={styling.tag.colorBg}
                      updateColor={(color) => {
                        this.updateFilterOptions([["styling.tag.colorBg", color]]);
                      }}
                    />
                  </StyledTabPane>
                ),
              },
            ]}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button className="mr-2" disabled={isLoading} onClick={close}>
            Cancel
          </Button>
          <Button
            color="purple"
            disabled={isLoading || !selectionData?.variable || !selectionData?.dataset || !selectionData?.categories?.length}
            onClick={this.saveFilter}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export const FilterPickerModal = inject("store")(observer(Component));
