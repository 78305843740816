import * as React from "react";
import { COLORS } from "component/UI/common";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { SeerButton } from "pages/PartnerOrganisations/includes/PartnerOrganisationsButtons";
import { DASHED_LINE_STYLE } from "component/EmptyState/EmptyState";

interface DashboardEmptyStateProps {
  disabled: boolean;
  onClickHandler: () => void;
}

export const DashboardEmptyState = (props: DashboardEmptyStateProps): JSX.Element => {
  const { disabled, onClickHandler } = props;
  return (
    <div
      className="bg-white py-5 px-2"
      style={{ margin: "0px 20px 40px", backgroundImage: `url("${DASHED_LINE_STYLE}")`, borderRadius: 8 }}
    >
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex flex-column align-items-center" style={{ marginBottom: 80, maxWidth: 500 }}>
          <div
            className="d-flex align-items-center justify-content-center mb-3"
            style={{ backgroundColor: COLORS.indigo100, height: 50, width: 50, borderRadius: "50%" }}
          >
            <Icon name="file outline" className="text-secondary mr-0" />
          </div>
          <p className="fs-1500 fw-700 mb-3 text-center" style={{ color: COLORS.grey800 }}>
            No Dashboard Content Yet
          </p>
          <p className="fs-1125 mb-4 text-center" style={{ color: COLORS.grey800 }}>
            Once you have saved some content, your dashboard will appear here. Start building your dashboard by clicking edit dashboard.
          </p>
          <SeerButton
            category="primary"
            label="Edit dashboard"
            icon="edit outline"
            onClick={onClickHandler}
            disabled={disabled}
            className="mr-2"
          />
        </div>
        <p className="fs-1000 mb-3 text-primary fw-700">Looking for examples?</p>
        <Link to="/dashboards?active_type=seer" target="_blank">
          <div className="d-flex align-items-center">
            <p className="fs-1125 fw-700 mr-1 mb-0" style={{ color: COLORS.grey800 }}>
              View Seer Data Curated Dashboards
            </p>
            <Icon name="angle right" style={{ color: COLORS.grey800, marginTop: -5 }} />
          </div>
        </Link>
      </div>
    </div>
  );
};
