import * as React from "react";

export const PageContainerWide = (props: { children: any }): React.ReactElement => {
  const { children } = props;

  return (
    <div className="h-100" style={{ maxWidth: "1080px", margin: "0 auto", overflow: "auto" }}>
      {children}
    </div>
  );
};
