// @TODO - replace as much as we can with semantic-ui so we can get rid of most of this code
import styled, { css } from "styled-components";
import { COLORS } from "component/UI/common";

export const Heading = styled.h3`
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: #504f5c;
`;

export const HR = styled.div`
  width: 100%;
  border-bottom: 1px solid #c3c3c3;
  margin: 40px 0px;
`;

export const Button = styled.button`
  font-size: 14px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  background-color: ${(props) => props.theme.color.heading};
  color: #ffffff;
  border: 0;
  padding: 12px 20px;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  outline: none;
  &:focus {
    box-shadow: 0 0 0 0.5pt ${(props) => props.theme.color.focus};
  }
`;

export const InvertedButton = styled(Button)`
  font-family: "Open Sans";
  height: 40px;
  color: ${COLORS.red500};
  background: #f5f5f5;
  border: 1px solid #cccccc;
  box-shadow: none;
  outline: none;
  &:hover {
    background: white;
  }
`;

export const Container = css`
  margin: 30px 0;
  background: #fff;
  border-radius: 5px;
  font-size: 12px;
`;
