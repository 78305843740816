import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm, Field } from "react-final-form";
import { FieldInput } from "component/FinalFormFields/FieldInput";
import { Button, Form } from "semantic-ui-react";
import { composeValidators, required, validPassword, valuesMustMatch } from "common/helpers/finalForm";
import { database, getMixpanel } from "common/api";
import { FORM_ERROR } from "final-form";
import { ResetPasswordModal } from "component/ResetPasswordModal/ResetPasswordModal";
import Store from "common/store";

interface Props {
  store?: Store;
}

const Component = (props: Props): JSX.Element => {
  const store = props.store!;

  const onSubmit = async (values) => {
    getMixpanel(store).track("Change password", { "User ID": store!.user!.id });
    const res: any = await database.put(
      `users/${store!.user!.id}/password`,
      {
        password: values.password,
        password_current: values.current_password,
      },
      store!.token!,
    );
    if (res.statusCode === 200) {
      store.logout();
      return alert("Your password has been updated successfully! Please log in again using your new password.");
    } else {
      return { [FORM_ERROR]: "The current password is incorrect. If you've forgotten it, please reset your password." };
    }
  };

  return (
    <FinalForm
      onSubmit={onSubmit}
      render={(formRenderProps) => {
        const { handleSubmit, submitError } = formRenderProps;
        return (
          <Form onSubmit={handleSubmit}>
            <Field
              name="current_password"
              label="Current password"
              type="password"
              component={FieldInput}
              validate={composeValidators(required)}
            />
            <Field
              name="password"
              label="New password"
              type="password"
              component={FieldInput}
              validate={composeValidators(required, validPassword)}
            />
            <Field
              name="confirm_password"
              label="Confirm password"
              type="password"
              component={FieldInput}
              validate={composeValidators(required, valuesMustMatch("password", "Passwords do not match"))}
            />
            <Button color="purple" type="submit">
              Update
            </Button>
            <ResetPasswordModal />
            {submitError && <p className="text-danger fs-0875 mt-1 mb-0">{submitError}</p>}
          </Form>
        );
      }}
    />
  );
};

export const ChangePassword = inject("store")(observer(Component));
