import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Dataset, { ListResponseData, TemplatesData } from "common/store/dataset";
import { Button, Icon, Label, List, ListItem, Modal, Pagination, Segment } from "semantic-ui-react";
import { CaseItem, Description, Title } from "component/MoveDuplicateModal/includes/MoveDuplicateModal.style";
import { ContentPlaceholder } from "component/ContentPlaceholder";

const PAGE_LIMIT = 10;

interface TableMoveDuplicateProps {
  datasetStore?: Dataset;
  action: "Duplicate" | "Move";
  isOpen: boolean;
  closeModal: () => void;
  actionClickHandler: (templateID: undefined | number) => void;
  datasetId: number;
  templateId: number;
}

const Component = (props: TableMoveDuplicateProps): JSX.Element => {
  const { datasetStore, action, isOpen, closeModal, actionClickHandler, templateId, datasetId } = props;
  const [selectedTemplateID, setSelectedTemplateID] = React.useState<any>(undefined);
  const [templatesData, setTemplatesData] = React.useState<ListResponseData<TemplatesData> | null>(null);
  const [activePage, setActivePage] = React.useState(1);
  const [loading, setLoading] = React.useState(true);

  const { meta, data } = templatesData || {};
  const { templates } = data || {};
  const totalPages = Math.ceil((meta?.total ?? 0) / PAGE_LIMIT);

  // Disabled the current template when moving tables
  const isDisabled = (currentId: number) => templateId === currentId && action === "Move";

  React.useEffect(() => {
    const loadTemplates = async () => {
      setLoading(true);
      const skip = PAGE_LIMIT * (activePage - 1);
      const res: any = await datasetStore!.listTemplates(datasetId, skip);
      if (res.error) {
        console.log(res.error);
      } else {
        setTemplatesData(res.data);
      }
      setLoading(false);
    };
    if (isOpen) {
      loadTemplates();
      setSelectedTemplateID(templateId);
    }
  }, [isOpen, activePage]);

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Modal.Header>{`${action} table to...`}</Modal.Header>
      <Modal.Content>
        {loading ? (
          <ContentPlaceholder />
        ) : (
          <>
            <Segment className="p-0 shadow-none overflow-auto" style={{ maxHeight: 375 }}>
              <List selection divided verticalAlign="middle" size="huge">
                {templates &&
                  templates?.length > 0 &&
                  templates?.map((template) => {
                    const { id, name, contributors, num_tables } = template;
                    return (
                      <ListItem
                        key={id}
                        className={`p-0 ${isDisabled(id) ? "" : "cursor-pointer"}`}
                        onClick={isDisabled(id) ? undefined : () => setSelectedTemplateID(id)}
                      >
                        <CaseItem active={selectedTemplateID === id} disabled={isDisabled(id)} className="px-4 py-2">
                          <div>
                            <Title>
                              {name}
                              {templateId === id && (
                                <Label className="ml-2" size="tiny">
                                  Current Template
                                </Label>
                              )}
                            </Title>
                            <Description>
                              {num_tables} tables | {contributors?.length} contributors
                            </Description>
                          </div>
                        </CaseItem>
                      </ListItem>
                    );
                  })}
              </List>
            </Segment>
            <Pagination
              pointing
              secondary
              size="large"
              activePage={activePage}
              boundaryRange={1}
              onPageChange={(_e, { activePage }) => setActivePage(activePage as number)}
              siblingRange={2}
              totalPages={totalPages}
              ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
              firstItem={{ content: <Icon name="angle double left" />, icon: true }}
              lastItem={{ content: <Icon name="angle double right" />, icon: true }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button className="mr-2" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={selectedTemplateID === undefined ? true : false}
          color="purple"
          onClick={() => actionClickHandler(selectedTemplateID === templateId ? undefined : selectedTemplateID)} // If duplicate to the current template, pass "undefined" as "templateID" to the backend
        >
          {action}
          <Icon name={action === "Duplicate" ? "copy" : "sign in"} className="ml-1 mr-0" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const TableMoveDuplicateModal = inject((stores: any) => ({
  datasetStore: stores.store.dataset,
}))(observer(Component));
