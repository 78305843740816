import * as React from "react";
import { Icon } from "semantic-ui-react";
import { StylePopup, Title } from "./insightBuilderStyles/whereWhatWhen.style";

interface IDropdownTitle {
  forMultiStep?: boolean;
  heading: string;
  title: string;
  tooltip?: string;
}

export const DropdownTitle = ({ forMultiStep, heading, title, tooltip }: IDropdownTitle): JSX.Element => {
  if (forMultiStep) {
    // Show sentence and description on Multi-step page
    return (
      <>
        <h1 className="ff-primary fw-800 text-secondary fs-2000 mb-3">{heading}</h1>
        {tooltip === "" || <h4 className="ff-primary fw-400 text-dark fs-1125 mt-0 mb-4">{tooltip}</h4>}
      </>
    );
  } else {
    // Show short title and info icon on Insight Builder page
    return (
      <div className="d-flex">
        <Title>{title}</Title>
        {tooltip === "" || (
          <StylePopup
            trigger={<Icon name="info circle" className="text-medium ml-1 cursor-pointer" size="small" />}
            content={tooltip}
            position="right center"
            size="mini"
            inverted
          />
        )}
      </div>
    );
  }
};
