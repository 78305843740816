import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Message } from "semantic-ui-react";
import { InvitePartnerModal } from "./includes/InvitePartnerModal";
import { groupPlan } from "common/helpers/group";
import { PartnerInvitationsList } from "./includes/PartnerInvitationsList";
import { SeerButton } from "./includes/PartnerOrganisationsButtons";
import { PartnershipConfirmModal } from "./includes/PartnershipConfirmModal";
import { getMixpanel } from "common/api";

interface IPartnerPendingInvitations {
  store?: any;
}

const PartnerPendingInvitationsComponent = ({ store }: IPartnerPendingInvitations): JSX.Element => {
  const [showInvitePartnerModal, setShowInvitePartnerModal] = React.useState(false);
  const [showCancelInvitationModal, setShowCancelInvitationModal] = React.useState(false);
  const [invitationID, setInvitationID] = React.useState(null);

  const { user } = store;
  const isStandardPlan = groupPlan[user.group.group_type_id] === "Standard";

  const { sentPartnerInvitations, receivedPartnerInvitations } = store.group;

  React.useEffect(() => {
    getMixpanel(store).track("Page view", { Page: "Settings > Organisation > Invitations" });
  }, []);

  const acceptInvitation = (invitationID) => {
    store.group.groupAcceptInvitation(user.group.id, invitationID);
  };

  const declineInvitation = (invitationID) => {
    store.group.groupDeleteInvitation(invitationID);
    getMixpanel(store).track("Decline Invitation", { Page: "Settings > Organisation > Invitations" });
  };

  const cancelInvitation = (invitationID) => {
    store.group.groupDeleteInvitation(invitationID);
    getMixpanel(store).track("Cancel Invitation", { Page: "Settings > Organisation > Invitations" });
    setShowCancelInvitationModal(false);
  };

  return (
    <>
      <InvitePartnerModal isOpen={showInvitePartnerModal} closeModal={() => setShowInvitePartnerModal(false)} />
      <PartnershipConfirmModal
        isOpen={showCancelInvitationModal}
        closeModal={() => setShowCancelInvitationModal(false)}
        confirmModal={() => cancelInvitation(invitationID)}
        header="Withdraw Invitation"
        content="Are you sure you want to withdraw this invitation?"
        confirmButtonText="Withdraw Invitation"
      />
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
        <h5 className="mb-0">Received Invitations</h5>
      </div>
      <PartnerInvitationsList
        showUserRole="sender_user"
        partnerInvitations={receivedPartnerInvitations}
        cancelLabel="Decline"
        cancelClickHandler={(invitationID) => declineInvitation(invitationID)}
        confirmLabel="Accept"
        confirmClickHandler={(invitationID) => acceptInvitation(invitationID)}
      />
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
        <h5 className="mb-0">Sent Invitations</h5>
        <SeerButton
          category="primary"
          label="Invite Organisations"
          icon="envelope"
          disabled={isStandardPlan}
          onClick={() => setShowInvitePartnerModal(true)}
        />
      </div>
      {isStandardPlan && (
        <Message size="tiny" style={{ background: "#eaffff", color: "#2dc3c3", border: "1px solid #2dc3c3" }}>
          <Message.Header>Upgrade your plan to invite partner organisations</Message.Header>
          <p>
            Your plan is <b>Standard</b>. So your organisation is not able to invite partner organisations. Upgrade your plan to share data
            and collaborate with your partner organisations.
          </p>
        </Message>
      )}
      <PartnerInvitationsList
        showUserRole="receiver_user"
        partnerInvitations={sentPartnerInvitations}
        cancelLabel="Withdraw"
        cancelClickHandler={(invitationID) => {
          setInvitationID(invitationID);
          setShowCancelInvitationModal(true);
        }}
      />
    </>
  );
};

export const PartnerPendingInvitations = inject((stores: any) => ({
  store: stores.store,
}))(observer(PartnerPendingInvitationsComponent));
