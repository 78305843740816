// Dimensions Helpers

import { uniq } from "lodash";

// Input Arrays: [[1,2], [3,4], [5,6,7], [8,9,10]];
// Output Arrays: [[1, 3, 5, 8], [1,3,5,9], .. [2,3,5,8] ... ]
const buildArrayCombinationsRecursive = (loopNum, tempAcc, acc, arr) => {
  if (arr.length > 0) {
    if (arr.length === 1) {
      arr[0].map((x) => {
        acc.push([...tempAcc, x]);
      });
      tempAcc = [];
    } else {
      arr[0].map((x) => {
        buildArrayCombinationsRecursive(loopNum + 1, [...tempAcc, x], acc, arr.slice(1));
      });
    }
  }

  if (loopNum === 0) {
    return acc;
  }
};

export const buildArrayCombos = (arr) => buildArrayCombinationsRecursive(0, [], [], arr);

export const getAllValuesForDimension = (dimension: string, tables) => {
  const dimValues: Array<string> = [];

  tables.forEach((t) => {
    const foundDim =
      t.rows.find((x) => x.dimension === dimension) ||
      t.columns.find((x) => x.dimension === dimension) ||
      t.filters.find((x) => x.dimension === dimension);

    if (foundDim) {
      dimValues.push(...foundDim.values);
    }
  });

  return { dimension, values: uniq(dimValues) };
};
