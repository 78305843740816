import * as React from "react";
import Select from "react-select";
import { Checkbox } from "semantic-ui-react";
import { IType } from "../ManageAccessModal";
import { customReactSelectStyles } from "common/helpers/dataset";
import { SeerButton } from "pages/PartnerOrganisations/includes/PartnerOrganisationsButtons";

interface IAddAccessWidget {
  type: IType;
  onClose: () => void;
  options: any[];
  onConfirm: (ids, accessLevel) => void;
}

export const AddAccessWidget = ({ type, onClose, options, onConfirm }: IAddAccessWidget): JSX.Element => {
  const [selectedIDs, setSelectedIDs] = React.useState<any[]>([]);
  const [accessLevel, setAccessLevel] = React.useState<any>("write");

  return (
    <>
      <p className="text-medium fw-600 cursor-pointer" onClick={onClose}>&lt; Back to Access List</p>
      <h3 className="text-secondary mt-4">{`Add ${type} access`}</h3>
      <h4 className="my-3 text-dark">{`1. Select ${type}s`}</h4>
      <Select
        isMulti
        isClearable={true}
        styles={customReactSelectStyles}
        placeholder={`Search and select ${type.toLowerCase()}s`}
        options={options}
        onChange={(selectedOptions) => {
          if (selectedOptions.length > 0) {
            setSelectedIDs(selectedOptions.map(o => o.id));
          } else {
            setSelectedIDs([]);
          }
        }}
      />
      <h4 className="my-3 text-dark">2. Set access level</h4>
      <Checkbox
        radio
        label="Full Access"
        name="checkboxRadioGroup"
        value="write"
        checked={accessLevel === "write"}
        onChange={(_e, data) => setAccessLevel(data.value)}
        style={{ marginRight: 20 }}
      />
      <Checkbox
        radio
        label="Read Only"
        name="checkboxRadioGroup"
        value="read"
        checked={accessLevel === "read"}
        onChange={(_e, data) => setAccessLevel(data.value)}
      />
      <div className="d-flex mt-5">
        <SeerButton
          category="secondary"
          label="Back"
          onClick={onClose}
          style={{ marginRight: 20 }}
        />
        <SeerButton
          category="primary"
          label={`Add ${type}s`}
          disabled={selectedIDs.length === 0}
          onClick={() => onConfirm(selectedIDs, accessLevel)}
        />
      </div>
    </>
  );
};
