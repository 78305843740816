import { cloneDeep, flattenDeep, includes } from "lodash";
import { ObjectAny } from "common/helpers/types";
import { isPercentage } from "common/helpers/data";

export const ifChartCanSetAxisValue = (type: string): boolean =>
  (type.includes("bar") || type === "Line" || type.includes("Line ")) && !includes(type, "proportion") && !includes(type, "percentage");

export const ifChartCanSetAxisIncrements = (type: string): boolean => type.includes("bar") || type.includes("Line");

export const isProportionsOrPercentageChart = (type: string): boolean => includes(type, "proportion") || includes(type, "percentage");

export const ifCanShowBarLabel = (type: string): boolean => type.includes("Vertical bar") && !includes(type, "proportion");

export const ifFormattedAsPercentage = (percentage: boolean, type: string, chartTables: any[]): boolean => {
  const ifChartTypePercentage = includes(type, "proportion") || includes(type, "percentage");
  const ifAllTablesCalc = chartTables.every((table) => table.type === "calc");
  const ifAllCalcTablesPercentage = chartTables.filter((table) => table.type === "calc").every((t) => t.format === "Percent");
  const ifAllTablesResult = chartTables.every((table) => table.type === "result");
  const ifAllResultTablesPercentage = chartTables
    .filter((table) => table.type === "result")
    .reduce((prev, curr) => [...prev, ...(curr.results?.map((res: ObjectAny[]) => res["Measured quantity"]) || [])], [])
    .every((mq: string) => isPercentage(mq));
  return (
    percentage ||
    ifChartTypePercentage ||
    (ifAllTablesCalc && ifAllCalcTablesPercentage) ||
    (ifAllTablesResult && ifAllResultTablesPercentage) ||
    (ifAllCalcTablesPercentage && ifAllResultTablesPercentage)
  );
};

interface IMinAndMaxChartData {
  dataMin: number;
  dataMax: number;
}

export const getMinAndMaxChartData = (chartData: ObjectAny[]): IMinAndMaxChartData => {
  const flattenedData: ObjectAny[] = flattenDeep(cloneDeep(chartData) as ObjectAny[]);
  flattenedData.forEach((obj) => {
    // Only filter out label and only keep key&value pairs of data
    Object.keys(obj).forEach((key) => {
      if (key.startsWith("label") || !obj[key]) {
        delete obj[key];
      }
    });
  });
  const allData: any = flattenedData.reduce((pre, curr) => pre.concat(Object.values(curr)), []); // Put all chart data into an array
  const dataMin = Math.min(...(allData as number[]));
  const dataMax = Math.max(...(allData as number[]));
  return { dataMin, dataMax };
};

// Only set "domain" min & max when the chart type is valid and userDefinedMaxValue or userDefinedMinValue is provided
// Otherwise return 0 as default min value and "auto" as default max value
export const getDomainMinAndMaxValue = (
  userDefinedMinValue: number | undefined,
  userDefinedMaxValue: number | undefined,
  dataMin: number,
  dataMax: number,
  type: string,
): (number | "auto")[] => {
  let minDomainValue = 0;
  let maxDomainValue: number | "auto" = "auto";
  if (ifChartCanSetAxisValue(type)) {
    if (typeof userDefinedMinValue === "number") {
      minDomainValue = userDefinedMinValue <= dataMin ? userDefinedMinValue : dataMin;
    }
    if (typeof userDefinedMaxValue === "number") {
      maxDomainValue = userDefinedMaxValue >= dataMax ? userDefinedMaxValue : dataMax;
    }
  }
  return [minDomainValue, maxDomainValue];
};

export const getComputedTicks = (
  userDefinedIncrements: undefined | number,
  userDefinedMinValue: undefined | number,
  userDefinedMaxValue: undefined | number,
  type: string,
): undefined | number[] => {
  let computedTicks: undefined | number[];
  if (userDefinedIncrements && ifValidIncrement(userDefinedIncrements, userDefinedMinValue, userDefinedMaxValue, type)) {
    computedTicks = [];
    if (isProportionsOrPercentageChart(type)) {
      // Ticks for Proportion/Percentage chats: [0, ... , 1]
      const tickCount = Math.ceil(1 / userDefinedIncrements) + 1;
      for (let i = 0; i < tickCount; i++) {
        if (i === tickCount - 1) {
          computedTicks.push(1);
        } else {
          computedTicks.push(userDefinedIncrements * i);
        }
      }
    } else if (typeof userDefinedMinValue === "number" && typeof userDefinedMaxValue === "number") {
      // Ticks for other charts when both max and min values are provided
      const minTick = userDefinedMinValue;
      const maxTick = userDefinedMaxValue;
      const tickCount = Math.ceil((maxTick - minTick) / userDefinedIncrements) + 1;
      for (let i = 0; i < tickCount; i++) {
        if (i === 0) {
          computedTicks.push(minTick);
        } else if (i === tickCount - 1) {
          computedTicks.push(maxTick);
        } else {
          computedTicks.push(minTick + userDefinedIncrements * i);
        }
      }
    }
  }
  return computedTicks;
};

const MAX_TICK_COUNT = 20;
const PROPORTION_OR_PERCENTAGE_CHART_MIN_INCREMENT = 0.05;
const PROPORTION_OR_PERCENTAGE_CHART_MAX_INCREMENT = 1;

export const getMinAndMaxIncrements = (
  userDefinedMinValue: number | undefined,
  userDefinedMaxValue: number | undefined,
  type: string,
): { minIncrement: number | undefined; maxIncrement: number | undefined } => {
  let minIncrement: number | undefined;
  let maxIncrement: number | undefined;
  if (isProportionsOrPercentageChart(type)) {
    minIncrement = PROPORTION_OR_PERCENTAGE_CHART_MIN_INCREMENT;
    maxIncrement = PROPORTION_OR_PERCENTAGE_CHART_MAX_INCREMENT;
  } else if (typeof userDefinedMinValue === "number" && typeof userDefinedMaxValue === "number") {
    minIncrement = (userDefinedMaxValue - userDefinedMinValue) / MAX_TICK_COUNT;
    maxIncrement = userDefinedMaxValue - userDefinedMinValue;
  }
  return { minIncrement, maxIncrement };
};

export const ifValidIncrement = (
  userDefinedIncrements: number,
  userDefinedMinValue: number | undefined,
  userDefinedMaxValue: number | undefined,
  type: string,
): boolean => {
  const { minIncrement, maxIncrement } = getMinAndMaxIncrements(userDefinedMinValue, userDefinedMaxValue, type);
  if (userDefinedIncrements >= minIncrement! && userDefinedIncrements < maxIncrement! && userDefinedIncrements > 0) {
    return true;
  } else {
    return false;
  }
};

// old insights were using this logic to determine this ratio, this function serves to ensure they continue displaying in the old manner until modified
export const widthToHeightRatioFallback = (chartType: string, chartLayout: number) => {
  if (chartType.includes("Pie")) {
    return 1.3;
  } else if (chartLayout === 1) {
    return 2;
  } else if (chartLayout === 2) {
    return 1.3;
  }
  return 1;
};
