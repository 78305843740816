import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import styled from "styled-components";
import { database, getMixpanel } from "common/api";
import { useHistory } from "react-router-dom";
import { Container } from "pages/Homepage/includes/Homepage.style";
import { SectionHeader } from "pages/Homepage/includes/SectionHeader";
import { COLORS } from "component/UI/common";
import { Button } from "component/UI/Button";
import { Icon } from "semantic-ui-react";

const FeaturesContainer = styled.div`
  display: flex;
  border-radius: 5px;
  gap: 48px;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    gap: 24px;
  }
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

const FeatureCard = styled.div`
  padding: 16px;
  flex: 1;
  font-family: Helvetica, sans-serif;
  img {
    width: 100%;
    height: auto;
  }
`;

const FEATURES = [
  {
    url: "/assets/onboarding/onboarding_carousel_build_your_data_collection.png",
    heading: "Build your data collection",
    description: "Collect and add private data from multiple contributors in our secure data sharing environment.",
  },
  {
    url: "/assets/onboarding/onboarding_carousel_analyse_insights.png",
    heading: "Share your data story",
    description: "Combine charts, narrative and media content to build beautiful shareable dashboards.",
  },
  {
    url: "/assets/onboarding/onboarding_carousel_share_community_story.png",
    heading: "Collaborate with Partners",
    description: "Invite partner organisations and manage access for shared learning and action.",
  },
];

interface ErrorMessageProps {
  children: React.ReactNode;
}

const ErrorMessage = ({ children }: ErrorMessageProps): JSX.Element => (
  <div className="d-flex align-items-start mb-4" style={{ color: COLORS.red500 }}>
    <Icon name="info circle" className="mr-2" style={{ marginTop: -2 }} />
    <p className="mb-0 fs-1000">{children}</p>
  </div>
);

interface Props {
  store?: Store;
}

const Component = (props: Props): JSX.Element => {
  const store = props.store!;
  const { user, userPlan } = store;
  const isOwnerOrAdmin = user!.group.users.find((u) => u.id === user!.id).admin;
  const canStartTrial = !user!.group.trial_end_date && userPlan === "Standard";

  const history = useHistory();
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const sendOwnerEmail = () => {
    const owner = user!.group.users.find((u) => u.id === user!.group.user_id);
    window.open(`mailto:${owner.email}`);
    getMixpanel(store).track("Contact Owner to start trial", { Page: "Start Plus Trial", "Owner Email": owner.email });
  };

  const startPlusTrial = async () => {
    getMixpanel(store).track("Start Plus Trial", { Page: "Start Plus Trial" });
    setLoading(true);
    const res: any = await database.put(`groups/${user!.group.id}?start_trial=true`, {}, store.token!);
    if (res.statusCode === 200) {
      store.reloginUserFromAuthToken();
      history.replace("/");
    } else {
      setError("Error, please try again later.");
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getMixpanel(store).track("Page view", { Page: "Start Plus Trial", "Owner or Admin": isOwnerOrAdmin, Plan: store.userPlan });
  }, []);

  return (
    <Container>
      <SectionHeader heading="Enjoy Plus Features 30 Days Free" />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {!canStartTrial ? (
        <ErrorMessage>
          <b>Your account is not eligible for the free trial.</b> You may be on a current Seer Data Plus or Dataspace plan, or your
          Organisation has already accessed this one time only free trial.
        </ErrorMessage>
      ) : (
        <>
          {!isOwnerOrAdmin ? (
            <ErrorMessage>
              Contact your Organisation's owner or an admin to unlock your Organisation's free trial.{" "}
              <span className="cursor-pointer" style={{ color: COLORS.blue }} onClick={sendOwnerEmail}>
                Click here
              </span>{" "}
              to <b>email</b> your Organisation's owner.
            </ErrorMessage>
          ) : null}
        </>
      )}
      <FeaturesContainer>
        {FEATURES.map((item) => {
          const { url, heading, description } = item;
          return (
            <FeatureCard key={heading}>
              <img src={url} alt={heading} />
              <div className="text-left text-lg-center">
                <p className="fw-700 mb-2" style={{ color: COLORS.grey800, fontSize: 20 }}>
                  {heading}
                </p>
                <p className="fw-400" style={{ color: COLORS.grey800, fontSize: 16 }}>
                  {description}
                </p>
              </div>
            </FeatureCard>
          );
        })}
      </FeaturesContainer>
      {isOwnerOrAdmin && canStartTrial && (
        <div className="d-flex justify-content-center" style={{ marginBottom: 40 }}>
          <Button
            colorConfig="purple"
            className="mr-3"
            onClick={() => {
              window.open("https://seerdata.ai/contact/", "_blank");
              getMixpanel(store).track("Click Contact Us", {
                Page: "Start Plus Trial",
                "Owner or Admin": isOwnerOrAdmin,
                Plan: store.userPlan,
              });
            }}
          >
            Contact Us
          </Button>
          <Button colorConfig="purpleInverted" onClick={startPlusTrial} disabled={loading}>
            Start Free Trial
            <Icon name="arrow right" className="mr-0" />
          </Button>
        </div>
      )}
      {canStartTrial ? (
        <div className="d-flex flex-column flex-sm-row fs-1000" style={{ gap: 16, color: COLORS.grey700 }}>
          <div className="w-100 w-sm-50">
            <p>
              <b>Your organisation's 30 day Seer Data Plus trial</b> includes access to advanced features for data analysis, collaboration
              and storytelling. On expiry of your trial, you will retain access to all Insights, Suitcases and Datasets that you have
              created or been shared during the trial, but you will no longer be able to access Dashboards.
            </p>
          </div>
          <div className="w-100 w-sm-50">
            <p>Features available on your Seer Data Plus trial:</p>
            <ul className="pl-4">
              <li>
                <b>Self-service Dataset Collection</b>
              </li>
              <li>
                <b>Data Storytelling Dashboards</b>
              </li>
              <li>
                <b>Invite Partner Organisations</b>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center w-100">
          <Button
            colorConfig="purpleInverted"
            onClick={() => {
              window.open("https://seerdata.ai/contact/", "_blank");
              getMixpanel(store).track("Click Contact Us", {
                Page: "Start Plus Trial",
                "Owner or Admin": isOwnerOrAdmin,
                Plan: store.userPlan,
              });
            }}
          >
            Contact us to learn more
          </Button>
        </div>
      )}
    </Container>
  );
};

export const StartPlusTrialSubmission = inject("store")(observer(Component));
