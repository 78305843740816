import * as React from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { Field, Form as FinalForm } from "react-final-form";
import { FieldInput } from "pages/DatasetTemplateTable/includes/FieldInput";
import { FORM_ERROR } from "final-form";

interface ICreateModal {
  name?: string;
  heading: string;
  isOpen: boolean;
  closeModal: () => void;
  saveModal: (name) => any;
}

export const CreateModal = ({ name: currentName, heading, isOpen, closeModal, saveModal }: ICreateModal): JSX.Element => {
  const onSubmit = async ({ name }): Promise<any> => {
    const error = await saveModal(name);
    if (error) {
      return { [FORM_ERROR]: error };
    } else {
      closeModal();
    }
  };

  return (
    <Modal open={isOpen} onClose={closeModal} closeOnDimmerClick={false} size="small">
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit, submitError, form }) => {
          const { getFieldState } = form;
          const fieldMeta: any = getFieldState("name");
          return (
            <>
              <Modal.Header>{`${currentName ? "Update" : "Create"} ${heading}`}</Modal.Header>
              <Modal.Content>
                <Form onSubmit={handleSubmit}>
                  <div id={!currentName ? `userflow-element-create-${heading.toLowerCase()}-name` : undefined}>
                    <Field name="name" label={`${heading} Name`} component={FieldInput} defaultValue={currentName || null} />
                  </div>
                </Form>
                {submitError && !fieldMeta?.modifiedSinceLastSubmit && <div className="text-primary mt-2">{submitError}</div>}
              </Modal.Content>
              <Modal.Actions>
                <Button className="mr-2" onClick={closeModal}>
                  Cancel
                </Button>
                <Button
                  id={!currentName ? `userflow-element-create-${heading.toLowerCase()}-confirm` : undefined}
                  color="purple"
                  onClick={handleSubmit}
                >
                  {currentName ? "Update" : "Create"}
                  <Icon name={currentName ? "edit" : "plus"} className="ml-1 mr-0" />
                </Button>
              </Modal.Actions>
            </>
          );
        }}
      />
    </Modal>
  );
};
