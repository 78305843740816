import { COLORS } from "component/UI/common";
import * as React from "react";
import { Button, Icon, Popup } from "semantic-ui-react";
import styled from "styled-components";

const tooltipContent = {
  "Measured quantity":
    '"Measured quantity" categories are the metrics by which a numeric value is recorded. It must be set with at least one category by which to measure.',
  How: '"Quantity" is synonymous with "Measured quantity". "Measured quantity" categories are the metrics by which a numeric value is recorded. It must be set with at least one category by which to measure.',
  What: 'A "Topic" variable is one that the Seer Data Platform does not recognise as either a "Time" or "Location" variable.',
  When: 'A "Time" variable is recognised by the Seer Data Platform as a time based variable.',
  Where: 'A "Location" variable is recognised by the Seer Data Platform as a location based variable.',
};

export const TypeTooltip = (props: { type: string; icon?: any; className?: string }): JSX.Element => {
  const { type, icon, className } = props;
  const content = tooltipContent[type];
  return <Popup trigger={<Icon name={icon || "info circle"} className={className} />} content={content} position="top center" inverted />;
};

export const StyleButtonGroup = styled(({ active, ...rest }) => <Button.Group data-active={active} {...rest} />)`
  .button {
    background-color: ${(props) => (props.active ? COLORS.indigo600 : "#e0e1e1")} !important;
    color: ${(props) => (props.active ? "#ffffff" : "rgba(0, 0, 0, 0.7)")} !important;
    text-align: left !important;
  }
  .ui.button:disabled {
    opacity: 1 !important;
    color: ${(props) => (props.active ? "#956995" : "#bebebe")} !important;
  }
`;

export const oldToNewVariableTypeTranslator = {
  Where: "Location",
  When: "Time",
  How: "Quantity",
  What: "Topic",
};
