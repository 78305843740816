import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Icon, List } from "semantic-ui-react";
import Store from "common/store";
import { useHistory, useParams } from "react-router-dom";
import { FileUploader } from "./includes/FileUploader";
import { COLOURS } from "pages/DatasetURLUpload/DatasetURLUpload.style";
import { getMixpanel } from "common/api";

interface Props {
  store?: Store;
  stepHandler?: (arg0: any) => any;
  datasetName: string;
}

export const Component = (props: Props) => {
  const { store, datasetName } = props;
  const history = useHistory();
  const { datasetID } = useParams<{ datasetID: string }>();
  const [error, setError] = React.useState("");

  const handleFileUpload = async (files) => {
    setError("");
    if (files.length > 2) {
      setError("You can only upload a maximum of 2 files.");
    } else if (files.length) {
      store!.dataset.urlupload.files = files;
      getMixpanel(store!).track("Upload URL Files", { "Dataset Id": datasetID, "Dataset Name": datasetName });
      history.push(`/url-dataset-contribution/${datasetID}`);
    }
  };

  return (
    <>
      <p className="fs-1000 fw-700">Collect New Data &gt; Upload Excel File</p>

      <div className="py-4">
        <div className="d-flex border rounded-3 p-3 mb-4">
          <Icon name="info circle" className="text-primary mr-2" size="large" />
          <div style={{ color: COLOURS.greyText800 }}>
            <h5 className="mb-2">Data Pre-processing Format Requirements</h5>
            <List as="ol" className="mt-0">
              <List.Item as="li">First row must contain the name of each column.</List.Item>
              <List.Item as="li">Column headers must be unique.</List.Item>
              <List.Item as="li">No additional surrounding text (i.e. no footers).</List.Item>
              <List.Item as="li">A maximum of 2 files can be uploaded.</List.Item>
            </List>
          </div>
        </div>
        <FileUploader fileHandler={handleFileUpload} />
        {error && <p className="text-primary text-center mt-3 fw-600">{error}</p>}
      </div>
    </>
  );
};

export const NewDataSourceExcelUpload = inject("store")(observer(Component));
