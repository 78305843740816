import * as React from "react";
import { Button, Menu, Dropdown } from "semantic-ui-react";
import { getPageTitles } from "./helpers";

interface Props {
  setPageIdx: (idx: number) => void;
  pageIdx: number;
  unlayer_config: Record<string, any>;
}

export const NavDefault = (props: Props) => {
  const { setPageIdx, pageIdx, unlayer_config } = props;
  const includedPages = unlayer_config.navigation?.includedPages || unlayer_config.pages.map(() => true);
  const includedPageIdxs: number[] = includedPages.map((showPg, idx) => showPg ? idx : -1).filter(idx => idx !== -1);
  const includedPageIdx = includedPageIdxs.indexOf(pageIdx);
  const totalPages = includedPageIdxs.length;
  const pageTitles = getPageTitles(unlayer_config);
  return (
    <div className="p-3 d-flex">
      <Button
        basic
        icon="angle left"
        disabled={includedPageIdx === 0}
        onClick={() => setPageIdx(includedPageIdxs[includedPageIdx - 1])}
        aria-label="Show previous page"
      />
      <Button
        basic
        icon="angle right"
        disabled={includedPageIdx === totalPages - 1}
        onClick={() => setPageIdx(includedPageIdxs[includedPageIdx + 1])}
        aria-label="Show next page"
      />
      <Menu compact>
        <Dropdown
          compact
          text={`(Page ${includedPageIdx + 1}/${totalPages}) ${pageTitles[pageIdx]}`}
          style={{ padding: "10px 1rem" }}
          aria-label="Currently displayed page"
        >
          <Dropdown.Menu>
            {includedPageIdxs.map((idx, arrIdx) => {
              return (
                <Dropdown.Item
                  key={`pg_${includedPageIdx}_${pageTitles[idx] || "unnamed"}`}
                  text={`(Page ${arrIdx + 1}/${totalPages}) ${pageTitles[idx]}`}
                  disabled={idx === pageIdx}
                  onClick={() => setPageIdx(idx)}
                />
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
    </div>
  );
};
