import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { webUrl } from "common/constants";
import { database, getMixpanel } from "common/api";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { composeValidators, mustBeValidEmail, required } from "common/helpers/finalForm";
import { Form, Icon } from "semantic-ui-react";
import { Button } from "component/UI/Button";
import { FieldInputRSTO } from "./FieldInput";

interface IProps {
  store?: Store;
  email: string;
  groupName: string;
  token: string | null;
}

const Component = (props: IProps): JSX.Element => {
  const { store, email, groupName, token } = props;

  const onSubmit = async (values) => {
    const { email, first_name, last_name, group_name } = values;
    getMixpanel(store!).track("RSTO Member Signup", {
      Email: email,
      "User Name": `${first_name} ${last_name}`,
      "Organisation Name": group_name,
    });
    const errors: any = {};
    const body = { ...values, invitation_token: token };
    const res: any = await database.post("users", body);
    if (res.statusCode === 200) {
      const token = res.body.data.user.token;
      const id = res.body.data.user.id;
      const redirect = "/custom/rsto/survey";
      window.location.href = `${webUrl}/login?token=${token}&id=${id}&redirect=${redirect}`;
    } else {
      errors[FORM_ERROR] = "Something went wrong. Please try again later.";
    }
    return errors;
  };

  return (
    <>
      <div className="mb-4">
        <h3 className="text-muted mb-2">Accept Invitation</h3>
        <p className="fs-1000 text-muted">Please complete the form below to accept the invitation.</p>
      </div>
      <FinalForm
        onSubmit={onSubmit}
        initialValues={{
          email,
          group_name: groupName,
        }}
        render={(formRenderProps) => {
          const { submitting, handleSubmit, submitError, modifiedSinceLastSubmit } = formRenderProps;
          return (
            <>
              <Form onSubmit={handleSubmit} id="login-ssdc-contributor" className="mb-2">
                <Field
                  label="Organisation Name"
                  name="group_name"
                  component={FieldInputRSTO}
                  validate={composeValidators(required)}
                  disabled
                />
                <div style={{ height: 10 }}></div>
                <Field
                  label="Email"
                  name="email"
                  component={FieldInputRSTO}
                  validate={composeValidators(required, mustBeValidEmail)}
                  disabled
                />
                <div style={{ height: 10 }}></div>
                <div className="d-flex flex-column flex-xl-row justify-content-between">
                  <div>
                    <Field label="First Name" name="first_name" component={FieldInputRSTO} validate={composeValidators(required)} />
                    <div style={{ height: 10 }}></div>
                  </div>
                  <div>
                    <Field label="Last Name" name="last_name" component={FieldInputRSTO} validate={composeValidators(required)} />
                    <div style={{ height: 10 }}></div>
                  </div>
                </div>
                {submitError && !modifiedSinceLastSubmit && <p className="text-danger fs-1000">{submitError}</p>}
              </Form>
              <div className="mb-1">
                <Button disabled={submitting} onClick={handleSubmit} icon fluid labelPosition="left" colorConfig="aquaCTA">
                  <Icon name="users" className="ml-2 mr-0" />
                  JOIN ORGANISATION
                </Button>
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export const MemberInviteForm = inject("store")(observer(Component));
