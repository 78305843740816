/* Simple generally applicable custom marker icons */
import * as L from "leaflet";
import "leaflet-extra-markers";
import { ObjectAny } from "common/helpers/types";

export const baseIconOptions = {
  icon: "circle",
  prefix: "icon",
  svg: true,
  extraClasses: "seer-map-small", // nicer size override for the circle icon
};

export const blueIcon = L["ExtraMarkers"].icon({ ...baseIconOptions, markerColor: "#4597d0" } as ObjectAny);
export const greenIcon = L["ExtraMarkers"].icon({ ...baseIconOptions, markerColor: "#5FAD56" } as ObjectAny);
export const yellowIcon = L["ExtraMarkers"].icon({ ...baseIconOptions, markerColor: "#F2C14E" } as ObjectAny);
export const orangeIcon = L["ExtraMarkers"].icon({ ...baseIconOptions, markerColor: "#F78154" } as ObjectAny);
export const purpleIcon = L["ExtraMarkers"].icon({ ...baseIconOptions, markerColor: "#B4436C" } as ObjectAny);
export const redIcon = L["ExtraMarkers"].icon({ ...baseIconOptions, markerColor: "#BF1A2F" } as ObjectAny);
