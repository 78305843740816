import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Container, Header, Icon, Pagination } from "semantic-ui-react";
import Store from "common/store";
import Protected from "component/protected";
import { DatasetTemplateList } from "./includes/DatasetTemplateList";
import { CreateModal } from "./includes/CreateModal";
import { EmptyStatePrimary } from "component/EmptyState/EmptyState";
import { getMixpanel } from "common/api";
import { COLORS } from "component/UI/common";
import { ContentPlaceholder } from "component/ContentPlaceholder";
import { DatasetsData, ListResponseData } from "common/store/dataset";

const PAGE_LIMIT = 10;

interface DatasetsProps {
  store?: Store;
}

const datasetsHeaderItems = [
  {
    name: "Name",
    sortColumn: "name",
    className: "",
  },
  {
    name: "Templates",
    className: "w-15",
  },
  {
    name: "Link",
    className: "w-15 cursor-default",
  },
  {
    name: "Latest Data",
    className: "w-15",
  },
];

const datasetEmptyStateDescription = (
  <p className="fs-1125 mb-5" style={{ color: COLORS.grey800 }}>
    <span>
      Collect internal or external data in a single place. Ingested automatically into your Self-service Data Collection dataset. Explore,
      analyse and create Insights from the collected data.{" "}
    </span>
    <span>
      <a
        target="_blank"
        href="https://knowledge.seerdata.ai/what-is-self-service-data-collection"
        className="text-decoration-underline"
        style={{ color: COLORS.blue }}
      >
        Click here
      </a>
    </span>
    <span> to learn more about Self-service Data Collection.</span>
  </p>
);

const DatasetsComponent = ({ store }: DatasetsProps): JSX.Element => {
  const [loading, setLoading] = React.useState(true);
  const [datasetData, setDatasetData] = React.useState<ListResponseData<DatasetsData> | null>(null);
  const [activePage, setActivePage] = React.useState(1);
  const [sort, setSort] = React.useState("name");
  const [showCreateDatasetModal, setShowCreateDatasetModal] = React.useState(false);
  const history = useHistory();
  const hasAccessToDatasets =
    store!.hasGroupAddOn("Self-service Data Ingestion") || store!.userPlan === "Plus" || store!.userPlan === "Unlimited";

  const { data, meta } = datasetData || {};
  const { datasets } = data || {};
  const totalDatasets = meta?.total ?? 0;
  const totalPages = Math.ceil(totalDatasets / PAGE_LIMIT);
  const startNumber = (activePage - 1) * PAGE_LIMIT + 1;
  const endNumber = activePage * PAGE_LIMIT > totalDatasets ? totalDatasets : activePage * PAGE_LIMIT;

  React.useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const skip = PAGE_LIMIT * (activePage - 1);
      const res: any = await store!.dataset.listDatasets(skip, sort);
      if (res.error) {
        console.error(res.error);
      } else {
        setDatasetData(res.data);
      }
      setLoading(false);
    };
    loadData();
    window.scrollTo(0, 0);
  }, [activePage, sort]);

  const createDataset = async (nameStr: string): Promise<string | void> => {
    const name = (nameStr || "").trim();
    if (!name) {
      return "Dataset name is required";
    }
    const res = await store!.dataset.createDataset(name);
    if (res.error) {
      console.error(res.error);
    } else {
      getMixpanel(store!).track("Create Dataset", { "Dataset Name": name, "Dataset Id": res.id });
      history.push(`/datasets/${res.id}`);
    }
  };

  const upgradePlan = () => {
    getMixpanel(store!).track("Click Upgrade Plan", { Page: "Datasets" });
    window.open("https://seerdata.ai/plus-plan/", "_blank");
  };

  return (
    <Container>
      <Helmet>
        <title>Datasets</title>
      </Helmet>
      {/* Create Dataset Modal */}
      <CreateModal
        heading="Dataset"
        isOpen={showCreateDatasetModal}
        closeModal={() => setShowCreateDatasetModal(false)}
        saveModal={createDataset}
      />
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Header as="h2" color="purple" className="mb-0">
          Datasets
        </Header>
        {hasAccessToDatasets && (
          <Button id="userflow-element-create-dataset" color="red" onClick={() => setShowCreateDatasetModal(true)}>
            Create Dataset
            <Icon name="plus" className="ml-1 mr-0" />
          </Button>
        )}
      </div>
      {loading ? (
        <ContentPlaceholder />
      ) : (
        <>
          {hasAccessToDatasets ? (
            <>
              {datasets?.length ? (
                <>
                  <DatasetTemplateList
                    headerItems={datasetsHeaderItems}
                    datasets={datasets}
                    applySort={(sort) => setSort(sort)}
                    currentSort={sort}
                  />
                  <div className="d-flex justify-content-between align-items-center mb-5">
                    <span className="fs-1000 text-medium">{`Showing ${startNumber}${startNumber !== endNumber ? `-${endNumber}` : ""} of ${totalDatasets} datasets`}</span>
                    <Pagination
                      pointing
                      secondary
                      size="large"
                      activePage={activePage}
                      boundaryRange={1}
                      onPageChange={(_e, { activePage }) => setActivePage(activePage as number)}
                      siblingRange={2}
                      totalPages={totalPages}
                      ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                      firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                      lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                      prevItem={{ content: <Icon name="angle left" />, icon: true }}
                      nextItem={{ content: <Icon name="angle right" />, icon: true }}
                    />
                  </div>
                </>
              ) : (
                <EmptyStatePrimary
                  heading="No Datasets Available"
                  description={datasetEmptyStateDescription}
                  image="/assets/dashboard_empty_state_image.png"
                  primaryButton={{
                    label: "Create Dataset",
                    icon: "plus",
                    onClick: () => setShowCreateDatasetModal(true),
                  }}
                />
              )}
            </>
          ) : (
            <EmptyStatePrimary
              heading="Self-service Data Collection is an Add On for organisations that have a Seer Data Plus plan"
              description={datasetEmptyStateDescription}
              image="/assets/dashboard_empty_state_image.png"
              primaryButton={{
                label: "Upgrade Your Plan",
                icon: "arrow circle up",
                onClick: upgradePlan,
              }}
              secondaryButton={{
                label: "Lean more about your plans",
                icon: "external alternate",
                onClick: () => store!.handleUpgradeShow(true),
              }}
            />
          )}
        </>
      )}
    </Container>
  );
};

export const Datasets = Protected(inject("store")(observer(DatasetsComponent)));
