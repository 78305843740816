import * as React from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { Button, Form, Header, Icon } from "semantic-ui-react";
import { composeValidators, mustBeNumber, required } from "common/helpers/finalForm";
import { FieldInput } from "component/FinalFormFields/FieldInput";
import { FieldReactSelect } from "component/FinalFormFields/FieldSelect";

interface Props {
  type: "dataset" | "dashboard" | "suitcase";
  onSubmit: (values) => void;
}

export const UserAccess = (props: Props): JSX.Element => {
  const { type, onSubmit } = props;
  return (
    <FinalForm
      onSubmit={onSubmit}
      render={(formRenderProps) => {
        const { handleSubmit, values } = formRenderProps;
        const { access } = values;
        return (
          <Form onSubmit={handleSubmit}>
            <Header as="h3" color="purple" className="mb-5">{`Edit ${type} user access`}</Header>
            <div className="mb-5">
              <Field
                name={`${type}_id`}
                label={`${type.charAt(0).toUpperCase()}${type.slice(1)} ID`}
                component={FieldInput}
                validate={composeValidators(required, mustBeNumber)}
              />
              <Field name="user_id" label="User ID" component={FieldInput} validate={composeValidators(required, mustBeNumber)} />
              <Field
                name="access"
                label="Access"
                component={FieldReactSelect}
                options={[
                  { label: "True", value: true },
                  { label: "False", value: false },
                ]}
                validate={composeValidators(required)}
              />
              {access?.value && (
                <>
                  {type === "dataset" ? (
                    <Field
                      name="admin"
                      label="Admin"
                      component={FieldReactSelect}
                      options={[
                        { label: "True", value: true },
                        { label: "False", value: false },
                      ]}
                      validate={composeValidators(required)}
                    />
                  ) : (
                    <Field
                      name="read_only"
                      label="Read only"
                      component={FieldReactSelect}
                      options={[
                        { label: "True", value: true },
                        { label: "False", value: false },
                      ]}
                      validate={composeValidators(required)}
                    />
                  )}
                </>
              )}
            </div>
            <Button color="purple">
              Save
              <Icon name="save" className="ml-1 mr-0" />
            </Button>
          </Form>
        );
      }}
    />
  );
};
