/* everything in here is hardcoded for RSTO gowrie for now */
import * as React from "react";
// import { useParams, Link } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Icon, Button, Message, Container, Form, Modal, Tab } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import { Field, Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import Store from "common/store";
import { FieldInput, FieldInputFile } from "component/FinalFormFields/FieldInput";
import { composeValidators, required } from "common/helpers/finalForm";
import { database } from "common/api";
import { parseInputFile, processRecords, parsedRecordsToFile } from "./includes/helpers";
import { ShowLoaderGlobal } from "component/LoaderGlobal";
import { backendUrl } from "common/constants";
import { Heading } from "common/styledComponents/elements";
import { ProcessedPreview } from "./includes/ProcessedPreview";

interface IContainer {
  store: Store;
}

const fileFieldValidator = (value) => {
  let error;
  if (!value?.[0]) {
    error = "Required";
  }
  return error;
};

const Component = (props: IContainer): JSX.Element => {
  const [loginStep, setLoginStep] = React.useState(1);
  const [data, setData] = React.useState<any>(null); // dataset data
  const [uploadData, setUploadData] = React.useState<any>(null); // upload data
  const [loading, setLoading] = React.useState(false); // loader for data get/post
  const [success, setSuccess] = React.useState<any>(false); // upload success
  const [error, setError] = React.useState<any>(false); // upload error
  const { store } = props;
  const { contributor_token } = store!;

  // login / email check handler
  const onSubmitEmailForm = async (values) => {
    if (loginStep === 1) {
      const res: any = await database.post("dataset-gowrie-temp?action=email_check", { email: values?.email });
      if (res?.statusCode === 200) {
        return setLoginStep(2);
      }
      return { email: "Email invalid or not found. Please check the entered email." };
    } else {
      const ok = await store!.dataset.contributorLogin(values?.email, values?.verification_code);
      if (!ok) {
        return { verification_code: "Please check the log in code has been entered correctly." };
      }
      // do nothing - login success will trigger rerender with template flow
    }
  };

  // load template data
  const loadData = async () => {
    setLoading(true);
    let nextData: any;
    const res: any = await database.post("dataset-gowrie-temp?action=load_dataset", {}, contributor_token!);
    if (res?.statusCode === 200) {
      // note, for now this is very specific to this particular form currently for gowrie with 2 datasets at once instead of 1
      nextData = res?.body?.data?.datasets;
    }
    setData(nextData);
    setLoading(false);
  };

  React.useEffect(() => {
    if (contributor_token && !data) {
      loadData();
    }
  }, [contributor_token]);

  if (!contributor_token) {
    return (
      <Container style={{ width: 320 }}>
        <Helmet>
          <title>Contributor Log In</title>
        </Helmet>
        <h3 className="text-secondary">Contributor Log in</h3>
        <FinalForm
          onSubmit={onSubmitEmailForm}
          render={({ handleSubmit, submitting }) => (
            <Form onSubmit={handleSubmit}>
              <Field
                name="email"
                label="Email"
                component={FieldInput}
                validate={composeValidators(required)}
                disabled={loginStep === 2 || submitting}
              />
              {loginStep === 2 ? (
                <>
                  <Field
                    name="verification_code"
                    label="Log in code"
                    component={FieldInput}
                    validate={composeValidators(required)}
                    disabled={submitting}
                  />
                  <p className="fs-0875 fw-bold">We just sent you a temporary log in code. Please check your inbox.</p>
                </>
              ) : (
                <p className="fs-0875 fw-bold">We’ll email you a temporary log in code to log in.</p>
              )}
              <Button type="submit" className="bg-primary text-white bg-hover-red" fluid disabled={submitting}>
                {loginStep === 1 ? "Continue" : "Log in"}
                <Icon className="ml-2" name={loginStep === 1 ? "magic" : "sign-in alternate"} style={{ opacity: 1 }} />
              </Button>
            </Form>
          )}
        />
      </Container>
    );
  }

  return (
    <Container>
      <Helmet>
        <title>Dataset Contributor</title>
      </Helmet>
      <h1 className="text-secondary">Dataset Contributor</h1>
      {loading && <ShowLoaderGlobal />}
      {!data ? (
        <>{!loading && <Message color="red">Dataset configuration could not be loaded.</Message>}</>
      ) : (
        <Modal open={true} size="large">
          <Modal.Header>
            <Heading>Data Upload - Gowrie</Heading>
          </Modal.Header>
          <Modal.Content>
            {success ? (
              <div className="text-center">
                <Icon name="check circle" size="massive" />
                <h1>Upload Complete</h1>
                <p>Thank you! We will get back to you within 3 business days with your updated dashboard link.</p>
                <p>You can now close this window.</p>
              </div>
            ) : (
              <>
                <p>Attach and load the data files for the {data.length} configured inputs below as .xls, xlsx, or .csv formats.</p>
                <p>Pre-configured transformations will then be applied to:</p>
                <ul>
                  <li>Remove columns that are not needed</li>
                  <li>Hash personally-identifying data to protect privacy</li>
                  <li>Rename columns to be consistent with other datasets</li>
                </ul>
                <p className="mb-5">
                  Please ensure the files uploaded are the correct files. All efforts are made to safely ensure that personally-identifying
                  data is loaded and transformed on your device itself prior to storage on Seer Data's Australian servers.
                </p>
                <hr />
                {!uploadData ? (
                  <FinalForm
                    onSubmit={async (values) => {
                      const { file1, file2 } = values;
                      const inputFile1: File = file1[0];
                      const inputFile2: File = file2[0];

                      const dataset1 = await parseInputFile(inputFile1);
                      const dataset2 = await parseInputFile(inputFile2);
                      if (!dataset1) {
                        return { [FORM_ERROR]: "Failed to parse File 1" };
                      } else if (!dataset2) {
                        return { [FORM_ERROR]: "Failed to parse File 2" };
                      }
                      setUploadData([dataset1, dataset2]);
                      return;
                    }}
                    render={({ handleSubmit, submitError }) => (
                      <Form onSubmit={handleSubmit} className="text-align-center mt-5">
                        <div className="d-flex justify-content-evenly">
                          <label className="file position-relative">
                            <Field
                              name="file1"
                              label={`File 1 - ${data[0].name}`}
                              component={FieldInputFile}
                              accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                              validate={fileFieldValidator}
                            />
                            <span />
                          </label>
                          <Field
                            name="file2"
                            label={`File 2 - ${data[1].name}`}
                            component={FieldInputFile}
                            accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                            validate={fileFieldValidator}
                          />
                        </div>
                        {submitError && <p className="text-danger">{submitError}</p>}
                        <div className="d-flex justify-content-evenly mt-4">
                          <Button type="submit" className="bg-primary text-white bg-hover-red" disabled={loading}>
                            Load Files
                          </Button>
                        </div>
                      </Form>
                    )}
                  />
                ) : (
                  <Form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      setLoading(true);
                      setError(false);
                      // process csv data
                      const processedUpload1 = await processRecords(uploadData[0], data[0].ssdc_config);
                      const processedUpload2 = await processRecords(uploadData[1], data[1].ssdc_config);
                      // convert data back into a File
                      const outputFile1 = await parsedRecordsToFile(processedUpload1, "dataset1.csv");
                      const outputFile2 = await parsedRecordsToFile(processedUpload2, "dataset2.csv");
                      // upload data and updated config
                      const formData = new FormData();
                      formData.append("file1", outputFile1);
                      formData.append("file2", outputFile2);
                      formData.append(
                        "ssdc_config",
                        JSON.stringify({
                          [data[0].id]: data[0].ssdc_config,
                          [data[1].id]: data[1].ssdc_config,
                        }),
                      );
                      const ok = await fetch(`${backendUrl}/dataset-gowrie-temp?action=upload_dataset`, {
                        method: "POST",
                        headers: {
                          Accept: "application/json",
                          "X-Token": contributor_token,
                        },
                        body: formData,
                      })
                        .then((res) => res.ok)
                        .catch(() => false);
                      setLoading(false);
                      setSuccess(ok);
                      setError(!ok);
                    }}
                  >
                    {/* @TODO - this form is likely throwaway, once we build a proper interface for setting ssdc_config then use final form */}
                    <p>Thanks for attaching the files!</p>
                    <p>
                      Here is a preview of the data that will be uploaded and stored on Seer Data servers along with a list of the
                      processing actions that are applied:
                    </p>

                    <Tab
                      panes={data.map((item, idx) => ({
                        menuItem: item.name,
                        render: () => (
                          <Tab.Pane>
                            <ProcessedPreview key={item.id} rawData={uploadData[idx]} config={item.ssdc_config} />
                          </Tab.Pane>
                        ),
                      }))}
                    />
                    {error && (
                      <>
                        <p className="text-danger">Upload failed.</p>
                        <p>Please try again in a few minutes and get in touch if you are still experiencing issues.</p>
                      </>
                    )}
                    <div className="d-flex justify-content-evenly mt-3">
                      <Button type="submit" className="bg-primary text-white bg-hover-red" disabled={loading}>
                        Upload Data
                      </Button>
                    </div>
                  </Form>
                )}
              </>
            )}
          </Modal.Content>
        </Modal>
      )}
    </Container>
  );
};

export const DatasetDataUpload = inject("store")(observer(Component));
