import { buildArrayCombos } from "common/helpers/dimensions";
import {
  DEFAULT_COLOR_PALETTE,
  getCustomChartColorNumber,
  getChartTables,
  getChartKey,
  getChartData,
  getChartConfiguration,
  generateColorsWithPalette,
} from "common/helpers/chart";
import { backendUrl } from "common/constants";
import { brushes } from "component/ColorPaletteWidgets/withColorApplicator";
import { getCalcTableColourNumber, getMissingFallback, getResultTableColourNumber } from "common/helpers/explore";

export type EmbedInsightFilter = Record<string, string[]>;

// insight param = res.body.data.insight
// updates the insight.json string with an object of variable filters, inapplicable filters are ignored
// this is a simple filter operation that works with the existing data set on the insight
export const filterInsight = (insight: any, filter: EmbedInsightFilter): any => {
  if (!filter) {
    return insight;
  }
  let json;
  try {
    json = JSON.parse(insight.json);
    const removeColourIdx = new Set(); // colour indexes to filter out from customChartColors
    for (const filterVariable of Object.keys(filter)) {
      for (const item of json.columns) {
        if (item.dimension === filterVariable) {
          item.values = item.values.filter((value) => filter[filterVariable].includes(value));
          break;
        }
      }
      // check tables
      for (const table of json.tables) {
        if (table.type === "result") {
          for (const filterType of ["columns", "rows", "filters"]) {
            for (const item of table[filterType]) {
              if (item.dimension === filterVariable) {
                item.values = item.values.filter((value) => filter[filterVariable].includes(value));
                break;
              }
            }
          }
          // For "calc" tables, retain the "Row" type _eq that has selected categories and replace those that without selected categories with "0". Update the "tables" array accordingly
        } else if (table.type === "calc") {
          const replaceEqIdx = new Set(); // eq indexes to be replaced with "0"
          let counter = 0; // Store the idx to match eq indexes and table(tables in calc table) indexes
          const removeTableIdx = new Set(); // table(tables in calc table) indexes to be removed
          for (let eqIdx = 0; eqIdx < table.eq.length; eqIdx++) {
            const currentEq = table.eq[eqIdx];
            if (currentEq.type === "Row") {
              for (let vIdx = 0; vIdx <= currentEq.filters.length; vIdx++) {
                if (currentEq.filters[vIdx] === filterVariable && !filter[filterVariable].includes(currentEq.filterValues[vIdx])) {
                  replaceEqIdx.add(eqIdx);
                  removeTableIdx.add(counter);
                }
              }
              counter++;
            } else if (currentEq.type === "Table" || currentEq === "calc") {
              counter++;
            }
          }
          if (replaceEqIdx.size) {
            // TODO: revisit and determine whether a calculation should work in these instances
            table.eq = table.eq.map((_eq, idx) => (replaceEqIdx.has(idx) ? "0" : _eq));
          }
          if (removeTableIdx.size) {
            table.tables = table.tables.filter((_, idx) => !removeTableIdx.has(idx));
          }
        }
      }
      // update table results for Metric chart
      if (json.chart.type === "Metric") {
        for (const table of json.tables.filter((table) => table.type === "result")) {
          table.results = table.results.filter((res) => !res[filterVariable] || filter[filterVariable].includes(res[filterVariable]));
        }
      }
      // check userDefinedCharts
      if (json.userDefinedCharts) {
        for (const type of ["legend", "series", "xAxis"]) {
          const typeFilters = json.userDefinedCharts[type];
          const indexLengths = typeFilters.map((_filter) => _filter.values.length); // for colour mapping
          const indexMultipliers = indexLengths.map((_, idx) =>
            Math.max(
              indexLengths.slice(idx + 1).reduce((prev, next) => prev * next, 1),
              1,
            ),
          );
          const indexCategoryIndexes = indexLengths.map((length, iIdx) => new Array(length).fill(0).map((_, cIdx) => ({ iIdx, cIdx })));
          // @TODO consider targeting es6 in typescript config so we can use [].entries() with for ... of
          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          for (let iIdx = 0; iIdx < typeFilters.length; iIdx++) {
            const item = typeFilters[iIdx];
            const comparable = item.dimension.startsWith("!");
            if (!comparable && item.dimension !== filterVariable) {
              continue;
            }
            const filterCategories = filter[filterVariable].map((cat) => (comparable ? `${filterVariable}:::${cat}` : cat));
            const cIdxRemoved: number[] = []; // track removed column indexes for customChartColors updates
            item.values = item.values.filter((cat, cIdx) => {
              const include = (comparable && !cat.startsWith(`${filterVariable}:::`)) || filterCategories.includes(cat);
              if (!include) {
                cIdxRemoved.push(cIdx);
              }
              return include;
            });
            // if variable in "legend", remove unused colours so the original ones are used for the remaining categories
            if (json.customChartColors && type === "legend" && cIdxRemoved.length) {
              // compile array of combinations excluding the current iIdx/cIdx for multi indexed legend
              const indexCatCombos = buildArrayCombos(indexCategoryIndexes.filter((_, idx) => idx !== iIdx));
              for (const cIdx of cIdxRemoved) {
                if (typeFilters.length === 1) {
                  // single index legend
                  removeColourIdx.add(cIdx);
                } else {
                  // multi index legend
                  for (const combination of indexCatCombos) {
                    removeColourIdx.add(
                      combination.reduce((prev, next) => prev + next.cIdx * indexMultipliers[next.iIdx], cIdx * indexMultipliers[iIdx]),
                    );
                  }
                }
              }
            }
          }
        }
      }
    }
    if (removeColourIdx.size && json.chart.type !== "Metric") {
      // Not removing colors from customChartColors for Metric charts for now
      json.customChartColors = json.customChartColors.filter((_, idx) => !removeColourIdx.has(idx));
    }
    insight.json = JSON.stringify(json);
  } catch (e) {
    console.error(e);
  }
  return insight;
};

export const filterInsightDynamically = async (insight: any, filter: EmbedInsightFilter, userToken = "") => {
  let json;
  try {
    json = JSON.parse(insight.json as string);
    for (const filterVariable of Object.keys(filter)) {
      for (const item of json.columns) {
        if (item.dimension === filterVariable) {
          item.values = filter[filterVariable];
          break;
        }
      }
      // check tables
      for (const table of json.tables) {
        if (table.type === "result") {
          for (const filterType of ["columns", "rows", "filters"]) {
            for (const item of table[filterType]) {
              if (item.dimension === filterVariable) {
                if (filterType === "filters" && filter[filterVariable].length !== 1) {
                  continue; // only apply to filters if one category was selected otherwise invalid
                }
                item.values = filter[filterVariable];
                break;
              }
            }
          }
        } else if (table.type === "calc" && filter[filterVariable].length === 1) {
          for (const _eq of table.eq) {
            if (typeof _eq === "string") {
              // no modification of string eq parts
            } else if (_eq.type === "Row") {
              for (let vIdx = 0; vIdx <= _eq.filters.length; vIdx++) {
                if (_eq.filters[vIdx] === filterVariable) {
                  _eq.filterValues[vIdx] = filter[filterVariable][0];
                }
              }
            }
            // _eq.type === "Table" requires no changes
          }
        }
      }
      // check userDefinedCharts
      if (json.userDefinedCharts) {
        for (const type of ["legend", "series", "xAxis"]) {
          const typeFilters: any[] = json.userDefinedCharts[type];
          for (const item of typeFilters) {
            const comparable = item.dimension.startsWith("!");
            if (!comparable && item.dimension !== filterVariable) {
              continue;
            }
            const filterCategories = filter[filterVariable].map((cat) => (comparable ? `${filterVariable}:::${cat}` : cat));
            if (comparable) {
              // if is a matching comparable filter out the existing categories for filterVariable, then insert the new ones back at the position of the first existing occurrence
              const firstOccurrence = item.values.findIndex((cat) => cat.startsWith(`${filterVariable}:::`));
              if (firstOccurrence !== -1) {
                item.values = item.values.filter((cat) => !cat.startsWith(`${filterVariable}:::`));
                item.values.splice(firstOccurrence === -1 ? item.values.length - 1 : firstOccurrence, 0, ...filterCategories);
              }
            } else {
              item.values = filterCategories;
            }
          }
        }
      }
    }
    // update results now that tables are all updated
    for (const table of json.tables.filter((table) => table.type === "result")) {
      // refresh table results
      const res = await fetch(`${backendUrl}/insights/${insight.id}/qs-proxy`, {
        headers: { "X-Token": userToken, "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({
          filters: [...(table.filters || []), ...(table.rows || []), ...(table.columns || [])].reduce(
            (obj, item) => ({ ...obj, [item.dimension]: item.values }),
            {},
          ),
          datasets: table.datasetsToQuery,
        }),
      })
        .then((res) => res.json())
        .catch(() => null);
      const newResults = res?.data?.datasets?.[0]?.results;
      if (newResults) {
        table.results = newResults;
      }
    }
    // update colours for each table
    for (const table of json.tables) {
      if (table.type === "result") {
        table.colors = generateColorsWithPalette(getResultTableColourNumber(table.rows, table.columns));
      } else {
        table.colors = generateColorsWithPalette(getCalcTableColourNumber(json.columns));
      }
    }
    // regenerate colours
    const chartTables = getChartTables(json.tables);
    const { legend, xAxis, series } = getChartConfiguration(json.userDefinedCharts, chartTables, json.columns);
    const { calcMissingFallback, calcMissingFallbackValue } = json;
    const chartKey = getChartKey(
      getChartData(xAxis, legend, series, json.columns, json.tables, getMissingFallback(calcMissingFallback, calcMissingFallbackValue)),
    );
    const totalColors = getCustomChartColorNumber(json.chart.type, xAxis, chartKey, chartTables, json.columns, series, legend);
    const selectedBrush = json.selectedBrush || "cycle";
    const canvas = json.canvas?.length ? json.canvas : DEFAULT_COLOR_PALETTE;
    json.customChartColors = brushes[selectedBrush].brush(canvas, [totalColors]).flat();
    insight.json = JSON.stringify(json);
  } catch (e) {
    console.error(e);
  }
  return insight;
};

// insight param = res.body.data.insight
// determines if filter is dynamic or not, i.e. has variable categories that don't currently exist
export const isFilterDynamic = (insight: any, filter: EmbedInsightFilter): boolean => {
  if (!filter) {
    return false;
  }
  let json;
  try {
    json = JSON.parse(insight.json);
    for (const filterVariable of Object.keys(filter)) {
      for (const column of json.columns) {
        if (column.dimension === filterVariable && filter[filterVariable].filter((item) => !column.values.includes(item)).length) {
          return true; // filter has categories that are not set on insight
        }
      }
      for (const table of json.tables.filter((table) => table.type === "result")) {
        for (const filterType of ["columns", "rows", "filters"]) {
          for (const item of table[filterType]) {
            if (
              item.dimension === filterVariable &&
              filter[filterVariable].filter((filterItem) => !item.values.includes(filterItem)).length
            ) {
              return true; // filter has categories that are not set on insight
            }
          }
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
  return false;
};

// insight param = res.body.data.insight
// determines if filter is valid or not, i.e. all variables in filter are set on insight
export const isFilterValid = (insight: any, filter: EmbedInsightFilter): boolean => {
  if (!filter) {
    return false;
  }
  let json;
  try {
    json = JSON.parse(insight.json);
    const filterVariables = Object.keys(filter);
    const insightVariables = new Set();
    for (const column of json.columns) {
      insightVariables.add(column.dimension);
    }
    for (const table of json.tables.filter((table) => table.type === "result")) {
      for (const filterType of ["columns", "rows", "filters"]) {
        for (const item of table[filterType]) {
          insightVariables.add(item.dimension);
        }
      }
    }
    // only valid if all filterVariables are in insightVariables
    return !filterVariables.filter((variable) => !insightVariables.has(variable)).length;
  } catch (e) {
    console.error(e);
  }
  return false;
};

// insight param = res.body.data.insight
// removes any variables that are not set on the insight from the filter
export const stripInvalidFilterVariables = (insight: any, filter: EmbedInsightFilter): EmbedInsightFilter => {
  let json;
  try {
    // @TODO - split out repeated code for pulling out insight variables from json
    json = JSON.parse(insight.json);
    const insightVariables = new Set();
    for (const column of json.columns) {
      insightVariables.add(column.dimension);
    }
    for (const table of json.tables.filter((table) => table.type === "result")) {
      for (const filterType of ["columns", "rows", "filters"]) {
        for (const item of table[filterType]) {
          insightVariables.add(item.dimension);
        }
      }
    }
    // return filter with only valid variables set
    return Object.keys(filter)
      .filter((variable) => insightVariables.has(variable))
      .reduce((prev, next) => ({ ...prev, [next]: filter[next] }), {});
  } catch (e) {
    console.error(e);
    return {};
  }
};
