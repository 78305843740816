/* eslint-disable */
export const toolName = "seer_unlayer_customTool"; // WARNING - DO NOT RENAME - WILL BREAK OLD DASHBOARDS

// add this to unlayer init tools config
export const insightToolConfig = {
  [`custom#${toolName}`]: {
    properties: {
      insightColumns: {
        editor: {
          data: {
            options: [
              { label: "1", value: "1" },
              { label: "2", value: "2" },
              { label: "3", value: "3" },
            ],
          },
        },
      },
    },
  },
};

// adds event lister to editor instance for checking if row columns was updated to force refresh embedded insights for height update in editor
export const designUpdateInsightRefreshHandler = (editorInstance) => {
  editorInstance.addEventListener("design:updated", (data) => {
    // unlayer does not fire design:updated on column resize
    if (data?.type === "row:modified" && !data.changes) {
      // when number of columns changes the changes property is absent, so we can use this to avoid firing on every row property change
      editorInstance.frame.iframe.contentWindow.postMessage({ refreshEmbeddedInsights: true }, "https://editor.unlayer.com");
    }
  });
};

export default () => {
  return `
    var toolName = "${toolName}";

    if (window["unlayer"] && !window[toolName]) {
      console.log("registering seer_unlayer_customTool");

      window.customInsightToolNodeCount = 0; // to keep track of node ids for messaging between iframe and seer app

      unlayer.registerPropertyEditor({
        name: toolName,
        layout: 'bottom',
        Widget: unlayer.createWidget({
          render(value, updateValue, data) {
            const insightData = value ? JSON.parse(value) : null;
            const { webUrl, suitcase_id, id } = insightData || {};
            const suitcaseLink = (webUrl || "https://seerplatform.com") + "/suitcases/" + (suitcase_id ? suitcase_id : "");
            const insightLink = (webUrl || "https://seerplatform.com") + "/insights/" + (id ? id : "");
            return \`
              $\{!insightData ? (
                \`<p>No Insight selected.</p>\`
              ) : (\`
                <div style="margin-bottom: 10px">
                  <p style="font-size: 12px; color: #8F9699; font-weight: 600; margin-bottom: 5px">Selected Insight:</p>
                  <a href="$\{insightLink}" target="_blank" style="color: #5f2d5f; font-weight: bold">
                    $\{insightData.name}
                    <svg style="fill: #5f2d5f; margin-bottom:3px !important" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" class="svg-inline--fa fa-external-link-alt fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"/></svg>
                  </a>
                  $\{!!insightData.isPublic ? (\`\`) : (\`
                    <p style="color: #5f2d5f">
                      Access is currently limited to users who have been given access to the suitcase.
                      To share your dashboard publicly, please enable Public Link Sharing on your
                      <b>
                        <a href="$\{suitcaseLink}" target="_blank" style="color: #5f2d5f">
                          Suitcase
                          <svg style="fill: #5f2d5f; margin-bottom:3px !important" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" class="svg-inline--fa fa-external-link-alt fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"/></svg>
                        </a>
                      </b>.
                      Learn more on how to enable this
                      <b>
                        <a href="https://knowledge.seerdata.ai/how-do-i-share-a-suitcase-via-public-link-or-invite" target="_blank" style="color: #5f2d5f">
                          here
                          <svg style="fill: #5f2d5f; margin-bottom:3px !important" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" class="svg-inline--fa fa-external-link-alt fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"/></svg>
                        </a>
                      </b>.
                    </p>
                  \`)}
                </div>
              \`)}
              <button type="button" class="picker btn btn-primary btn-sm">
                $\{!insightData ? "Select" : "Change"} Insight
              </button>
            \`;
          },
          mount(node, value, updateValue, data) {
            // set nodeId and update window count
            var nodeId = window.customInsightToolNodeCount + 1;
            window.customInsightToolNodeCount = window.customInsightToolNodeCount + 1;

            // add listener for insight modal event updates from embed parent window
            window.addEventListener("message", (event) => {
              if (event.origin === "${window.origin}" && event.data?.insightTool?.nodeId === nodeId) {
                updateValue(JSON.stringify(event.data.insightTool.data));
              }
            }, false);

            // send message back to parent window to trigger insight modal
            var pickerBtn = node.querySelector('.picker');
            pickerBtn.onclick = function () {
              window.parent.postMessage({ insightTool: { nodeId } }, "${window.origin}");
            };
          },
        }),
      });

      unlayer.registerTool({
        name: toolName,
        label: 'Insight',
        icon: 'fa-chart-bar',
        supportedDisplayModes: ['web', 'email'],
        options: {
          default: {
            title: null,
          },
          insight: {
            title: 'Insight',
            position: 1,
            options: {
              insightData: {
                label: 'No Label',
                defaultValue: '',
                widget: toolName, // custom property editor
              },
              insightColumns: {
                label: 'Charts Per Row (Multi-Chart Only)',
                defaultValue: '1',
                widget: "dropdown",
              },
              hideLegend: {
                label: 'Hide Chart Legend',
                defaultValue: true,
                widget: "toggle",
              },
            },
          },
        },
        values: {},
        renderer: {
          Viewer: unlayer.createViewer({
            render(values) {
              const { insightData, insightColumns, hideLegend } = values;
              const data = insightData ? JSON.parse(insightData) : null;
              const title = data?.name.replace(/\\W/g, " ") || "";
              if (!data) {
                return \`<div style="border: 1px dashed #5f2d5f; color: #5f2d5f; height: 150px; display: flex; align-items: center; justify-content: center; background: rgba(205, 146, 205, 0.2); font-size: 13px; font-family: 'Open Sans'; padding: 20px">No Insight selected. Click here and Select Insight from right panel.</div>\`;
              }
              return \`
                <div style="height: 500px;">
                  <iframe loading="lazy" class="seer-dashboards-insight" title="$\{title}" src="${window.origin}/embed/insight?url=$\{data.url}&columns=$\{insightColumns}&hideLegend=$\{hideLegend}" style="width:100%;height:100%;border:none;"></iframe>
                </div>
              \`;
            },
          }),
          exporters: {
            web: function (values) {
              const { insightData, insightColumns, hideLegend } = values;
              const data = insightData ? JSON.parse(insightData) : null;
              const title = data?.name.replace(/\\W/g, " ") || "";
              if (!data) {
                return \`<div style="border: 1px solid #5f2d5f; color: #5f2d5f; height: 150px; display: flex; align-items: center; justify-content: center; background: rgba(205, 146, 205, 0.2)">No Insight selected.</div>\`;
              }
              return \`
                <div style="height: 500px;">
                  <iframe loading="lazy" class="seer-dashboards-insight" title="$\{title}" src="${window.origin}/embed/insight?url=$\{data.url}&columns=$\{insightColumns}&hideLegend=$\{hideLegend}" style="width:100%;height:100%;border:none;"></iframe>
                </div>
              \`;
            },
            email: function (values) {
              const { insightData, insightColumns, hideLegend } = values;
              const data = insightData ? JSON.parse(insightData) : null;
              const title = data?.name.replace(/\\W/g, " ") || "";
              if (!data) {
                return \`<div style="border: 1px solid #5f2d5f; color: #5f2d5f; height: 150px; display: flex; align-items: center; justify-content: center; background: rgba(205, 146, 205, 0.2)">No Insight selected.</div>\`;
              }
              return \`
                <div style="height: 500px;">
                  <iframe loading="lazy" class="seer-dashboards-insight" title="$\{title}" src="${window.origin}/embed/insight?url=$\{data.url}&columns=$\{insightColumns}&hideLegend=$\{hideLegend}" style="width:100%;height:100%;border:none;"></iframe>
                </div>
              \`;
            },
          },
          head: {
            css: function (values) {},
            js: function (values) {},
          },
        },
      });
      window[toolName] = true; // so we don't register more than once per load

      window.addEventListener("message", (event) => {
        // handle resize of embeded insights, inside unlayer embed
        if (event.origin === "${window.origin}" && event.data?.iframeHeight && event.data?.src) {
          // now find the correct source iframe and update the height
          const insightIframes = document.querySelectorAll("iframe.seer-dashboards-insight");
          for (const frame of insightIframes) {
            if (frame.src === event.data.src) {
              frame.parentElement.style.height = event.data.iframeHeight + "px";
              break;
            }
          }
        }
        // handle resize of embeded insights on unlayer row config changes
        if (event.origin === "${window.origin}" && event.data?.refreshEmbeddedInsights) {
          const insightIframes = document.querySelectorAll("iframe.seer-dashboards-insight");
          for (const frame of insightIframes) {
            frame.src = frame.src; // overwrite src to force reload
          }
        }
      }, false);
    }
  `;
};
