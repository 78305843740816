import styled from "styled-components";

export const FakeTable = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.25rem;
`;

const BaseCell = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5rem;
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  font-size: 1rem;
  user-select: none;
`;

export const FakeTH = styled(BaseCell)`
  display: inline-block;
  vertical-align: bottom;
  background-color: #f9fafb;
  font-size: 1.125rem;
  font-weight: bold;
  &:last-child {
    border-left: none;
    border-right: none;
  }
`;

export const FakeTD = styled(BaseCell)`
  background-color: #fff;
  cursor: pointer;

  &[data-even="true"] {
    background-color: #f9fafb;
  }

  &[data-highlight="col_row"] {
    background-color: #e1e1e1;
  }

  &[data-highlight="cell"] {
    background-color: #979797;
    color: #fff;
  }
`;
