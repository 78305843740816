import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import {
  StyleTable,
  StyleTableCellHeading,
  StyleTableHeader,
  StyleTableHeaderCell,
  StyleTableRow,
} from "component/Suitcases/includes/SuitcasesList.style";
import { Button, Dropdown, Icon, Placeholder, Table } from "semantic-ui-react";
import { DateTime } from "luxon";
import { sortBy } from "lodash";
import { CompletenessMeasureType } from "common/store/completenessMeasure";
import { CompletenessMeasureVisual } from "./CompletenessMeasureVisual";
import { StyleConfirm } from "component/Explore/includes/NewExplore.style";
import { COLORS } from "component/UI/common";
import { Popup } from "component/UI/Popup";

interface Props {
  completenessMeasures: CompletenessMeasureType[];
  editHandler: (id: number) => void;
  deleteHandler: (id: number) => Promise<void>;
  copyHandler: (id: number, e: React.MouseEvent<HTMLElement>) => void;
}

const headerItems = [
  {
    name: "Name",
    sortColumn: "name",
    className: "",
  },
  {
    name: "Updated at",
    sortColumn: "updated_at",
    className: "w-15 d-none d-md-table-cell",
  },
  {
    name: "Completeness",
    sortColumn: "completeness",
    className: "w-25",
  },
  {
    name: "Embed Code",
    className: "w-15",
  },
];

const StyledPlaceholder = styled(Placeholder)`
  height: 30px;
  flex-grow: 1;
  &.ui.placeholder .line {
    height: 0px !important;
  }
`;

const MeasurePlaceholder = () => (
  <StyledPlaceholder>
    <Placeholder.Line />
  </StyledPlaceholder>
);

// TODO: refactor to DRY the Semantic Sortable Table code for Datasets/Dashboards/Suitcases list views
const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_SORT":
      if (state.column === action.column) {
        return {
          ...state,
          direction: state.direction === "ascending" ? "descending" : "ascending",
        };
      } else {
        return {
          column: action.column,
          direction: "ascending",
        };
      }
    default:
      throw new Error();
  }
};

const Component = (props: Props) => {
  const { completenessMeasures, editHandler, deleteHandler, copyHandler } = props;
  const [state, dispatch] = React.useReducer(reducer, { column: null, direction: null });
  const { column, direction } = state;
  // determine sort based on reducer state
  const sortedColumn = column ? sortBy([...completenessMeasures!], [column]) : completenessMeasures;
  const sortedDirection: any = direction === "descending" ? [...sortedColumn!].reverse() : sortedColumn;
  // Show Sort icon to let users know that the list is sortable. After sorting hide Sort icon.
  const [showSortIcon, setShowSortIcon] = React.useState(true);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = React.useState(false);
  const [deleteID, setDeleteID] = React.useState<number | null>(null);

  const dropdownActionItems = [
    { text: "Edit", onClick: (id: number) => editHandler(id) },
    {
      text: "Delete",
      onClick: (id: number) => {
        setShowDeleteConfirmModal(true);
        setDeleteID(id);
      },
    },
  ];

  return (
    <>
      <StyleTable sortable celled unstackable>
        <StyleTableHeader>
          <StyleTableRow>
            {headerItems.map((item, index) => {
              const { name, sortColumn, className } = item;
              return (
                <StyleTableHeaderCell
                  sorted={column === sortColumn ? direction : null}
                  onClick={
                    sortColumn
                      ? () => {
                          dispatch({ type: "CHANGE_SORT", column: sortColumn });
                          setShowSortIcon(false);
                        }
                      : null
                  }
                  className={className}
                  key={`${name}-${index}`}
                >
                  {name}
                  {showSortIcon && sortColumn && <Icon name="sort" className="ml-1" />}
                </StyleTableHeaderCell>
              );
            })}
          </StyleTableRow>
        </StyleTableHeader>
        <Table.Body>
          {sortedDirection?.map((measure) => {
            const { id, name, value, updated_at, refresh_triggered_at } = measure;
            const refreshTriggerTime = new Date(refresh_triggered_at as string);
            const updatedAtTime = new Date(updated_at as string);
            const isMeasureLoading = updated_at && refreshTriggerTime.getTime() > updatedAtTime.getTime();
            return (
              <StyleTableRow key={id} onClick={() => editHandler(id as number)}>
                <StyleTableCellHeading>{name}</StyleTableCellHeading>
                <Table.Cell className="d-none d-md-table-cell fs-1000 ff-secondary" style={{ color: COLORS.grey600 }}>
                  {isMeasureLoading ? (
                    <MeasurePlaceholder />
                  ) : (
                    DateTime.fromISO((updated_at || refresh_triggered_at) as string)
                      .setZone("local")
                      .toFormat("DD")
                  )}
                </Table.Cell>
                <Table.Cell>
                  {isMeasureLoading ? (
                    <div className="d-flex align-items-center">
                      <MeasurePlaceholder />
                      <Popup
                        content={<p className="fs-0875">Loading...</p>}
                        trigger={<Icon className="ml-1 fs-0875 text-dark" name="info circle" />}
                        position="bottom center"
                      />
                    </div>
                  ) : (
                    <CompletenessMeasureVisual value={value} type="bar" />
                  )}
                </Table.Cell>
                <Table.Cell>
                  <div className="d-flex align-items-center">
                    <Popup
                      content={<p className="fs-0875">Copy embed code to paste into Dashboard html content block</p>}
                      trigger={
                        <Button
                          disabled={value === null || isMeasureLoading}
                          className="flex-shrink-0 px-3"
                          onClick={(e: React.MouseEvent<HTMLElement>) => copyHandler(id as number, e)}
                        >
                          Copy <Icon name="clone outline" className="mr-0 ml-1" />
                        </Button>
                      }
                      position="bottom center"
                      size="tiny"
                      inverted
                      hoverable
                    />
                    {!isMeasureLoading && (
                      <Dropdown icon="ellipsis vertical" direction="left" className="text-muted">
                        <Dropdown.Menu>
                          {dropdownActionItems.map((item, idx) => {
                            const { text, onClick } = item;
                            return <Dropdown.Item key={`${text}-${idx}`} text={text} onClick={() => onClick(id as number)} />;
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                </Table.Cell>
              </StyleTableRow>
            );
          })}
        </Table.Body>
      </StyleTable>
      <StyleConfirm
        open={showDeleteConfirmModal}
        header="Are you sure?"
        content="Are you you want to delete this completeness measure?"
        confirmButton="Delete"
        onCancel={() => setShowDeleteConfirmModal(false)}
        onConfirm={() => {
          setShowDeleteConfirmModal(false);
          deleteHandler(deleteID!);
        }}
      />
    </>
  );
};

export const CompletenessMeasuresList = inject("store")(observer(Component));
