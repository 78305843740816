import styled from "styled-components";
import { Grid } from "semantic-ui-react";
import { $white, $opensans, $radius } from "common/styledComponents/_config.style";
import { COLORS } from "component/UI/common";

// General elements
export const StyleSettingsGrid = styled(Grid)`
  padding-bottom: 15px;
  &.ui.grid > .row > .column {
    margin-bottom: 15px;
  }
  &.ui.grid > .row {
    padding-top: 0px;
  }
`;

export const StyleSettingsGridRow = styled(Grid.Row)`
  padding-top: 0px;
`;

// Organisation elements
export const StyleTab = styled.button`
  cursor: default;
  font-family: ${$opensans};
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: #919191;
  background: #f5f5f5;
  width: 50%;
  height: 40px;
  border: 1px solid #c3c3c3;
  margin: 0;
  border-radius: ${$radius};
`;

export const StyleAddons = styled.div`
  padding: 5px 5px 0 5px;
  width: 100%;
  min-height: 40px;
  background: #ffffff;
  border: 1px solid #c3c3c3;
  box-sizing: border-box;
  border-radius: ${$radius};
`;

export const StyleAddonTile = styled.span`
  color: ${$white};
  font-family: ${$opensans};
  font-weight: normal;
  font-size: 15px;
  padding: 5px 10px;
  display: inline-block;
  height: 30px;
  width: auto;
  background: ${COLORS.red500};
  border-radius: ${$radius};
  margin: 0 5px 5px 0;
`;
