import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Button, Checkbox, Divider, Header, Icon, Modal, Popup } from "semantic-ui-react";
import Select from "react-select";
import Store from "common/store";
import { datasets as dataApi } from "common/api";
import { DatasetAccessCategory } from "./DatasetAccessCategorySelect";
import { customReactSelectStyles } from "common/helpers/dataset";

interface IDatasetAccessModal {
  isOpen: boolean;
  entityType: "Organisation" | "User";
  datasetKey: string;
  entityOptions?: any[]; // all groups/users options
  updateEntityName?: string; // Updated group/user name
  initialAccessLimit?: any;
  closeClickHandler: () => void;
  saveClickHandler: (accessLimit, id?, name?) => Promise<boolean>;
  deleteClickHandler?: () => Promise<boolean>;
  store?: Store;
}

// This component is used for create/update groups/users access limit
const DatasetAccessModalComponent = ({
  isOpen,
  entityType,
  datasetKey,
  entityOptions,
  updateEntityName,
  initialAccessLimit,
  closeClickHandler,
  saveClickHandler,
  deleteClickHandler,
  store,
}: IDatasetAccessModal): JSX.Element => {
  const [optionID, setOptionID] = React.useState(null);
  const [optionLabel, setOptionLabel] = React.useState("");
  const [variableOptions, setVariableOptions] = React.useState<any[]>([]);
  const [isVariableOptionsLoading, setIsVariableOptionsLoading] = React.useState<boolean>(true);
  const [accessLimit, setAccessLimit] = React.useState<any>(null);

  // Convert one variable's access limit to React Select format
  const getInitialCategories = (variable) => {
    if (accessLimit[variable]) {
      return { ...accessLimit }[variable].map((category) => ({
        key: category,
        label: category,
        value: category,
      }));
    } else {
      return null;
    }
  };

  // Helper function to enable/disable variable options
  const getDisabledUpdatedVariables = (variableName, value: boolean) => {
    const newVariableOptions = [...variableOptions];
    const variableIndex = newVariableOptions.findIndex((option) => option.value === variableName);
    newVariableOptions[variableIndex].isDisabled = value;
    return newVariableOptions;
  };

  const removeVariable = (variable) => {
    // Remove variable from "selectedVariables"
    const newAccessLimit = { ...accessLimit };
    delete newAccessLimit[variable];
    if (Object.keys(newAccessLimit).length === 0) {
      // No variables selected: Full Access
      setAccessLimit(null);
    } else {
      setAccessLimit(newAccessLimit);
    }
    // Enable the removed variable in "variableOptions": set "isDisabled: false"
    setVariableOptions(getDisabledUpdatedVariables(variable, false));
  };

  // When variable categories are updated in the child component - "DatasetAccessCategorySelect", we need to update that variable in "accessLimit"
  const selectCategory = (accessLimitObj) => {
    const newAccessLimit = { ...accessLimit };
    newAccessLimit[Object.keys(accessLimitObj)[0]] = Object.values(accessLimitObj)[0];
    setAccessLimit(newAccessLimit);
  };

  const loadVariables = async () => {
    const res: any = await dataApi.post("v2/qs", { dataset: datasetKey }, store!.token!);
    if (res.body) {
      const variables = res.body.data.dataset.dimensions;
      const formattedDims = variables.map((variable) => ({
        label: variable.name,
        value: variable.name,
        isDisabled: initialAccessLimit && Object.keys(initialAccessLimit).indexOf(variable.name) >= 0 ? true : false,
      }));
      setVariableOptions(formattedDims);
      setIsVariableOptionsLoading(false);
    }
  };

  const modalSaveHandler = async () => {
    const success = await saveClickHandler(accessLimit, entityOptions ? optionID : null, optionLabel);
    if (success) {
      closeClickHandler();
    }
  };

  const modalDeleteHandler = async () => {
    if (deleteClickHandler) {
      const success = await deleteClickHandler();
      if (success) {
        closeClickHandler();
      }
    }
  };

  React.useEffect(() => {
    if (datasetKey) {
      loadVariables();
      setOptionID(null);
      setOptionLabel("");
    }
    if (initialAccessLimit) {
      setAccessLimit(initialAccessLimit);
    } else {
      setAccessLimit(null);
    }
  }, [datasetKey, isOpen, initialAccessLimit]);

  return (
    <Modal open={isOpen} onClose={closeClickHandler} closeOnDimmerClick={false}>
      <Modal.Header>{`${updateEntityName ? "Update" : "Add"} ${entityType} Dataset Access${updateEntityName ? `: ${updateEntityName}` : ""}`}</Modal.Header>
      <Modal.Content>
        {/* Only show group/user dropdown when add group/user access */}
        {entityOptions && (
          <>
            <Header as="h5" color="red">{`Select a${entityType === "User" ? "" : "n"} ${entityType.toLocaleLowerCase()}`}</Header>
            <Select
              isClearable={true}
              styles={customReactSelectStyles}
              placeholder={`Search and select ${entityType.toLocaleLowerCase()}`}
              options={entityOptions}
              onChange={(option) => {
                if (option) {
                  // Select an option
                  setOptionID(option.id);
                  setOptionLabel(option.label);
                } else {
                  // Remove an option
                  setOptionID(null);
                  setOptionLabel("");
                }
              }}
            />
            {optionID && <Divider style={{ marginTop: 30, marginBottom: 30 }} />}
          </>
        )}
        {/* Only show variable dropdown when group/user is selected or updating group/user */}
        {(optionID || updateEntityName) && (
          <>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <Header as="h5" color="red" className="mb-0">{`Set ${entityType.toLocaleLowerCase()} access limit`}</Header>
              <Popup
                trigger={
                  <div className="d-flex align-items-center">
                    <Checkbox
                      toggle
                      checked={!accessLimit}
                      className="mr-2"
                      size="small"
                      onChange={() => setAccessLimit(null)}
                      disabled={!accessLimit}
                    />
                    <p className="fs-1125 fw-600 text-muted" style={{ flex: 1 }}>
                      Full Access
                    </p>
                  </div>
                }
                content="All selected variables will be removed."
                position="bottom center"
                size="mini"
                inverted
              />
            </div>
            <div className="d-flex">
              <Header as="h6" className="mb-2">
                Select variables that you would like to set access limits to
              </Header>
              <Popup
                trigger={<Icon name="info circle" className="text-medium ml-1 cursor-pointer" size="small" />}
                content={`${entityType} will have full access if no variables are selected.`}
                position="bottom center"
                size="mini"
                inverted
              />
            </div>
            <Select
              isClearable={true}
              styles={customReactSelectStyles}
              placeholder="Search and select variables"
              options={variableOptions}
              value={null}
              isLoading={isVariableOptionsLoading}
              isDisabled={isVariableOptionsLoading}
              onChange={(option) => {
                // Add selected variable to "accessLimit" with an empty array
                setAccessLimit({ ...accessLimit, [option.value]: [] });
                // Disable the selected variable in "variableOptions": set "isDisabled: true"
                setVariableOptions(getDisabledUpdatedVariables(option.value, true));
              }}
            />
            <div className="d-flex mt-4">
              <Header as="h6" className="mb-2">
                Selected variables
              </Header>
              <Popup
                trigger={<Icon name="info circle" className="text-medium ml-1 cursor-pointer" size="small" />}
                content={`Select categories for each variable that will be accessible.
${entityType} will have no access to a variable if no categories are selected for it.`}
                position="bottom center"
                size="mini"
                inverted
                className="text-prewrap"
              />
            </div>
            {accessLimit ? (
              <>
                {Object.keys(accessLimit).map((variable) => (
                  <DatasetAccessCategory
                    key={variable}
                    variable={variable}
                    initialCategories={getInitialCategories(variable)}
                    datasetKey={datasetKey}
                    removeHandler={() => removeVariable(variable)}
                    selectHandler={selectCategory}
                  />
                ))}
              </>
            ) : (
              <p className="fw-700 text-medium">Full Access: No variables selected</p>
            )}
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        {/* Show "Delete" button when updating group/user */}
        {deleteClickHandler && (
          <Button color="red" className="float-left ml-0" onClick={modalDeleteHandler}>
            Delete
            <Icon name="trash alternate" className="ml-1 mr-0" />
          </Button>
        )}
        <Button className="mr-2" onClick={closeClickHandler}>
          Cancel
        </Button>
        <Button color="purple" onClick={modalSaveHandler} disabled={!updateEntityName && !optionID}>
          {updateEntityName ? "Update" : "Add"}
          <Icon name={updateEntityName ? "save" : "plus"} className="ml-1 mr-0" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const DatasetAccessModal = inject((stores: any) => ({
  store: stores.store,
}))(observer(DatasetAccessModalComponent));
