import styled from "styled-components";
import { LabelProps as SemanticLabelProps, Label as SemanticLabel } from "semantic-ui-react";
import { COLORS } from "./common";

type ColorConfig = "grey" | "purple" | "aqua";

// TODO: replace hex colors with variables when all colors are updated in "common.tsx"
const LABEL_COLORS: Record<ColorConfig, ColorProps> = {
  grey: {
    fg: "#484848",
    bg: COLORS.grey200,
  },
  purple: {
    fg: COLORS.indigo600,
    bg: COLORS.indigo200,
  },
  aqua: {
    fg: "#038386",
    bg: COLORS.aqua100,
  },
};

interface ColorProps {
  fg: string;
  bg: string;
}

interface LabelProps extends SemanticLabelProps {
  colorConfig?: ColorConfig;
}

export const Label = styled(SemanticLabel).attrs((props: LabelProps) => {
  const { size } = props;
  return {
    size: typeof size === "undefined" ? "medium" : size,
  };
})<LabelProps>`
  &.ui.label,
  .ui.labels .label {
    padding: 4px 6px;
    background: ${(props) => LABEL_COLORS[props.colorConfig || "grey"].bg} !important;
    color: ${(props) => LABEL_COLORS[props.colorConfig || "grey"].fg} !important;
    margin-right: 0;
  }
`;
