import * as React from "react";
import { DateTime } from "luxon";
import { Link, useHistory } from "react-router-dom";
import { Icon, Table } from "semantic-ui-react";
import {
  StyleTable,
  StyleTableCellHeading,
  StyleTableCellText,
  StyleTableHeader,
  StyleTableHeaderCell,
  StyleTableRow,
} from "component/Suitcases/includes/SuitcasesList.style";
import { getSortValues } from "common/helpers/general";

interface IHeaderItem {
  name: string;
  sortColumn?: string; // set only if sort is allowed
  className: string;
}

interface IDashboard {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  public_shared?: boolean;
  owner?: string;
}

interface IDashboardsList {
  headerItems: IHeaderItem[];
  dashboards: IDashboard[];
  applySort: (sort: string) => void;
  currentSort: string;
  id?: string;
}

export const DashboardsList = ({ headerItems, dashboards, applySort, currentSort, id }: IDashboardsList): JSX.Element => {
  const history = useHistory();
  const { column, direction } = getSortValues(currentSort);

  const handleRowClick = (dashboardID: number) => {
    history.push(`/dashboards/${dashboardID}`);
  };

  const handleNameClick = (e) => {
    e.stopPropagation();
  };

  return (
    <StyleTable sortable celled unstackable id={id || undefined}>
      <StyleTableHeader>
        <StyleTableRow>
          {headerItems.map((item, index) => {
            const { name, sortColumn, className } = item;
            return (
              <StyleTableHeaderCell
                sorted={column && column === sortColumn ? direction : null}
                onClick={() => {
                  if (sortColumn) {
                    if (column === sortColumn) {
                      applySort(`${direction === "descending" ? "" : "-"}${sortColumn}`);
                    } else {
                      applySort(sortColumn);
                    }
                  }
                }}
                className={className}
                key={`${name}-${index}`}
              >
                {name}
                {!currentSort && sortColumn && <Icon name="sort" className="ml-1" />}
              </StyleTableHeaderCell>
            );
          })}
        </StyleTableRow>
      </StyleTableHeader>
      <Table.Body>
        {dashboards?.map((data, idx) => {
          const { id: dashboardID, name, owner, public_shared, created_at, updated_at } = data;
          return (
            <StyleTableRow key={dashboardID} id={id && !idx ? `${id}-first-item` : undefined} onClick={() => handleRowClick(dashboardID)}>
              <StyleTableCellHeading>
                <Link to={`/dashboards/${dashboardID}`} className="text-secondary" onClick={(e) => handleNameClick(e)}>
                  {name}
                </Link>
              </StyleTableCellHeading>
              {public_shared !== undefined && <StyleTableCellText collapsing>{public_shared ? "Yes" : "No"}</StyleTableCellText>}
              {owner && <StyleTableCellText collapsing>{owner}</StyleTableCellText>}
              <StyleTableCellText className="d-none d-md-table-cell">
                {DateTime.fromISO(created_at).setZone("local").toFormat("DD")}
              </StyleTableCellText>
              <StyleTableCellText className="d-none d-md-table-cell">
                {DateTime.fromISO(updated_at).setZone("local").toFormat("DD")}
              </StyleTableCellText>
            </StyleTableRow>
          );
        })}
      </Table.Body>
    </StyleTable>
  );
};
