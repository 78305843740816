import styled from "styled-components";
import { Confirm, Radio } from "semantic-ui-react";
import { COLORS } from "component/UI/common";

export const StyleConfirm = styled(Confirm)`
  &.ui.modal > .header {
    font-size: 22px !important;
    color: ${COLORS.indigo600};
  }
  &.ui.modal > .content {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .ui.button {
    border: 1px solid ${COLORS.red500};
    background-color: #ffffff;
    color: ${COLORS.red500};
  }
  .ui.primary.button,
  .ui.primary.buttons .button {
    background-color: ${COLORS.red500};
    color: #ffffff;
  }
`;

export const StyleRadio = styled(Radio)`
  font-size: 1rem !important;
  label:after {
    background-color: ${COLORS.indigo600} !important;
  }
`;
