import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { Action } from "./includes/Action";
import { Icon } from "semantic-ui-react";
import { AddAndEditAction } from "./includes/AddAndEditAction";
import { Button } from "component/UI/Button";
import { getMixpanel } from "common/api";
import { DashboardAction } from "common/store/dashboard";
import { ActionsSideBarContentWrapper } from "./includes/Collaborate.style";
import { sortActionByDate } from "./includes/helper";
import { COLORS } from "component/UI/common";
import { CollaborateTopSection, FilterOption } from "./includes/components";

interface Props {
  dashboardID: number;
  collaborators: any[];
  onClose: () => void;
  fullscreen: boolean;
  store?: Store;
}

const Component = (props: Props): JSX.Element => {
  const { dashboardID, collaborators, onClose, fullscreen, store } = props;
  const { user, dashboard: dashboardStore } = store!;
  const [showActionWidget, setShowActionWidget] = React.useState(false);
  const [selectedFilter, setSelectedFilter] = React.useState<FilterOption>("unresolved");

  const actions = dashboardStore.dashboardActions;
  // Get "Ongoing" actions
  const unresolvedActions = actions?.filter((action) => !action.resolved_at);
  const sortedUnresolvedActions = [
    ...unresolvedActions.filter((action) => action.due_date).sort(sortActionByDate("due_date")),
    ...unresolvedActions.filter((action) => !action.due_date).sort(sortActionByDate("created_at")),
  ];
  // Get "Completed" actions
  const resolvedActions = [...actions?.filter((action) => action.resolved_at)].sort(sortActionByDate("resolved_at"));
  const groupedResolvedActions: { times: string[]; actions: DashboardAction[][] } = { times: [], actions: [] }; // example { time: [ "2023-Aug", "2023-Jul" ], actions: [DashboardAction[], DashboardAction[]]}
  resolvedActions.forEach((action) => {
    const { resolved_at } = action;
    const resolvedDate = new Date(resolved_at!);
    const time = `${resolvedDate.toLocaleString("default", { month: "long" })} ${resolvedDate.getFullYear()}`;
    const findTimeIndex = groupedResolvedActions.times.indexOf(time);
    if (findTimeIndex === -1) {
      groupedResolvedActions.times.push(time);
      groupedResolvedActions.actions.push([action]);
    } else {
      groupedResolvedActions.actions[findTimeIndex].push(action);
    }
  });

  const createAction = async (actionBody: Partial<DashboardAction>): Promise<void> => {
    getMixpanel(store!).track("Collaborate > Actions > Create Action", { "Dashboard ID": dashboardID, Title: actionBody.title });
    const createdAction = await dashboardStore.createDashboardAction(dashboardID, actionBody);
    if (createdAction) {
      await dashboardStore.refreshDashboardActions(dashboardID);
      setSelectedFilter("unresolved");
      dashboardStore.setSelectionOverlay("highlight");
      setShowActionWidget(false);
      const element = document.getElementById(`dashboard_action_${createdAction.id}`);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
    // TODO: show error messages to handle endpoint call failure
  };

  return (
    <>
      {showActionWidget ? (
        <AddAndEditAction
          userName={`${user!.first_name} ${user!.last_name}`}
          avatar={user!.avatar}
          onSave={(actionBody) => createAction(actionBody)}
          onCancel={() => setShowActionWidget(false)}
        />
      ) : (
        <>
          <CollaborateTopSection
            activeFilter={selectedFilter}
            filters={[
              { label: "Active", value: "unresolved" },
              { label: "Completed", value: "resolved" },
              { label: "All", value: "all" },
            ]}
            onSelect={(value) => setSelectedFilter(value)}
            fullscreen={fullscreen}
            dashboardID={dashboardID}
            collaborators={collaborators}
            onClose={onClose}
          />
          {actions.length === 0 || (selectedFilter === "unresolved" && sortedUnresolvedActions.length === 0) ? (
            <div className="bg-white rounded-3 p-4" style={{ border: "2px dashed #CACBCD" }}>
              <p className="fs-1000 fw-700 mb-2">Keep Track of Change</p>
              <p className="fs-1000">
                Are there any insights that stand out to you? Record actions your organisation can undertake that may result in improvement.
                Describe the action plan and what you predict will happen.
              </p>
              <img src="/assets/actions_image.png" alt="Conversation Image" />
              <Button icon labelPosition="left" colorConfig="aquaCTA" onClick={() => setShowActionWidget(true)} fluid>
                <Icon name="plus" className="ml-2 mr-0" />
                ADD YOUR FIRST ACTION
              </Button>
            </div>
          ) : (
            <div className="w-100 d-flex flex-column" style={{ height: "calc(100vh - 200px)" }}>
              <Button className="mb-4 flex-shrink-0" colorConfig="aqua" onClick={() => setShowActionWidget(true)} fluid>
                ADD ACTION <Icon name="plus" className="ml-2 mr-0" />
              </Button>
              <div className="overflow-hidden" style={{ margin: -5 }}>
                <ActionsSideBarContentWrapper>
                  {selectedFilter === "resolved" ? (
                    <>
                      {groupedResolvedActions.times.length === 0 ? (
                        <p className="fs-1000">No completed actions.</p>
                      ) : (
                        <>
                          {groupedResolvedActions.times.map((time, idx) => (
                            <div key={idx}>
                              <div className="d-flex align-items-center my-3">
                                <div className="mr-3" style={{ width: 1, height: 52, backgroundColor: COLORS.indigo500 }}></div>
                                <p className="fw-600 fs-1500" style={{ color: COLORS.indigo500 }}>
                                  {time}
                                </p>
                              </div>
                              {groupedResolvedActions.actions[idx].map((action) => {
                                const { id } = action;
                                return <Action key={id} dashboardID={dashboardID} collaborators={collaborators} action={action} />;
                              })}
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {(selectedFilter === "unresolved" ? sortedUnresolvedActions : [...actions].reverse()).map((action) => {
                        const { id } = action;
                        return <Action key={id} dashboardID={dashboardID} collaborators={collaborators} action={action} />;
                      })}
                    </>
                  )}
                </ActionsSideBarContentWrapper>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export const Actions = inject("store")(observer(Component));
