import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { database, getMixpanel } from "common/api";
import { Button } from "component/UI/Button";
import { Header, Icon } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import { ImageWrapper, SpotlightContainer, SpotlightContentContainer } from "./includes/Spotlight.style";
import { COLORS } from "component/UI/common";
import { ContentPlaceholder } from "component/ContentPlaceholder";
import { SpotlightVideoModal } from "./includes/SpotlightVideoModal";
import { webUrl } from "common/constants";

interface Props {
  store?: Store;
}

interface Spotlight {
  id: number;
  group_type_id: number;
  title?: string;
  description?: string;
  button_label?: string;
  button_link?: string;
  image: string;
  video?: string;
  created_at: string;
  paths: string[];
}

const Component = (props: Props): JSX.Element | null => {
  const { store } = props;
  const [loading, setLoading] = React.useState(true);
  const [spotlights, setSpotlights] = React.useState<Spotlight[]>([]);
  const [openVideoModal, setOpenVideoModal] = React.useState(false);

  const location = useLocation();
  const currentPath = location.pathname;
  const { user } = store!;

  // Filter to get spotlights that will be displayed on the current path
  let currentPathSpotlights = spotlights.filter((sl) => sl.paths.includes(currentPath));
  // Filter out closed spotlights for the current path
  const currentPathClosedSpotlights = user!.onboarding?.closedSpotlights.filter((sl) => sl.path === currentPath);
  if (currentPathClosedSpotlights) {
    currentPathSpotlights = currentPathSpotlights.filter((sl) => !currentPathClosedSpotlights.find((csl) => csl.id === sl.id));
  }
  // Show the 1st available spotlight
  const currentSpotlight = currentPathSpotlights[0] || {};

  const { id, title, description, button_label, button_link, image, video } = currentSpotlight;
  const hasPrimaryButton = button_label && button_link;

  const closeSpotlight = async (spotlightID: number) => {
    const userOnboarding = user!.onboarding || {};
    const closedSpotlight = { path: currentPath, id: spotlightID };
    if (userOnboarding.closedSpotlights) {
      userOnboarding.closedSpotlights.push(closedSpotlight);
    } else {
      userOnboarding.closedSpotlights = [closedSpotlight];
    }
    setLoading(true);
    await database.put(`users/${user!.id}`, { onboarding: userOnboarding }, store!.token!);
    await store!.reloginUserFromAuthToken();
    setLoading(false);
  };

  const primaryButtonOnClick = (spotlightID: number, link: string) => {
    window.open(`${webUrl}${link}`, "_blank");
    closeSpotlight(spotlightID);
    getMixpanel(store!).track("Open Spotlight", { Page: "Homepage", "Spotlight ID": spotlightID });
  };

  const secondaryButtonOnClick = (spotlightID: number) => {
    closeSpotlight(spotlightID);
    getMixpanel(store!).track("Close Spotlight", { Page: "Homepage", "Spotlight ID": spotlightID });
  };

  React.useEffect(() => {
    const getSpotlights = async () => {
      setLoading(true);
      const res: any = await database.get("spotlights", "", store!.token!);
      if (res.statusCode === 200) {
        setSpotlights(res.body?.data?.spotlights || []);
      }
      setLoading(false);
    };
    getSpotlights();
  }, []);

  if (!currentPathSpotlights.length) {
    return null;
  }

  return (
    <SpotlightContainer>
      {loading ? (
        <ContentPlaceholder className="w-100 h-100" color="#fff" />
      ) : (
        <>
          {hasPrimaryButton && video && (
            <SpotlightVideoModal
              open={openVideoModal}
              onClose={() => setOpenVideoModal(false)}
              videoLink={video}
              title={title}
              description={description}
              buttonLabel={button_label}
              buttonClick={() => {
                setOpenVideoModal(false);
                primaryButtonOnClick(id, button_link);
                getMixpanel(store!).track("Open Spotlight", { Page: "Homepage Video Modal", "Spotlight ID": id });
              }}
            />
          )}
          <ImageWrapper
            src={image}
            className={`${video ? "cursor-pointer" : ""}`}
            onClick={() => (video ? setOpenVideoModal(true) : null)}
          />
          <SpotlightContentContainer>
            <div className="mb-4">
              {title && (
                <Header as="h4" color="purple" className="mb-3">
                  {title}
                </Header>
              )}
              <p className="fs-1125 fw-400" style={{ color: COLORS.grey800 }}>
                {description}
              </p>
            </div>
            <div className="d-flex flex-column flex-sm-row">
              {hasPrimaryButton && (
                <Button
                  colorConfig="purpleInverted"
                  className="mr-0 mr-sm-3 mb-2 mb-sm-0"
                  onClick={() => primaryButtonOnClick(id, button_link)}
                >
                  {button_label}
                  <Icon name="arrow right" className="mr-0" />
                </Button>
              )}
              <Button onClick={() => secondaryButtonOnClick(id)}>OK, got it</Button>
            </div>
          </SpotlightContentContainer>
        </>
      )}
    </SpotlightContainer>
  );
};

export const Spotlight = inject("store")(observer(Component));
