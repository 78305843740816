// @TODO - most usage of this file should be easily migrated to use app theme, should only keep variables that are used as styled component props

// Seer Style Theme

// Fonts
export const $opensans = "Open Sans";

// Seer Colors
export const $darkestgrey = "#2d2d2d";
export const $darkgrey = "#919191";
export const $lightgrey = "#f5f5f5";
export const $black = "#2d2d2d";
export const $white = "#ffffff";
export const $warning = "#2dc3c3";

// Shadows
export const $shadow = "0px 2px 4px #c3c3c3";

// Boarder Variables
export const $border = "1px solid #c3c3c3";
export const $radius = "4px";

// @TODO - best to not use this and keep to the semantic device breakpoints (https://semantic-ui.com/elements/container.html) (same as bootstrap)
// Device Sizes
const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "450px",
  tablet: "650px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
  tabletHeight: " 860px",
};

// Media Queries
export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  tabletH: `(max-height: ${size.tabletHeight})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};
