import * as React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";

import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { Form } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { database, getMixpanel } from "common/api";
import { userExists } from "common/helpers/user";
import { createNewUser } from "common/helpers/signup";
import { FormHeading, FormWrapper } from "./includes/Components.style";
import { COLORS } from "component/UI/common";
import { composeValidators, requiredCustomMessage, required } from "common/helpers/finalForm";
import { BottomSection } from "./includes/BottomSection";
import { Button } from "component/UI/Button";
import { FieldCheck } from "component/FinalFormFields/FieldCheck";
import { FORM_ERROR } from "final-form";
import { ErrorMessage, ErrorMessageProps } from "./includes/ErrorMessage";
import { FieldInput } from "./includes/FieldInput";
import { ContentPlaceholder } from "component/ContentPlaceholder";

// Accept Invite Form Flow
// 1. Validate User Input
// 2. Send User Verification Code Via Email (ensures this is a real user and prevents typos)
// 3. Disable Other Fields and Validate User-Entered Verification Code
// 4. Create New Member

interface Props {
  store: Store;
}

interface FormValueProps {
  organisation: string;
  email: string;
  first_name: string;
  last_name: string;
  agree_to: boolean;
}

const Component = (props: Props): JSX.Element => {
  const { store } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const [pageError, setPageError] = useState<ErrorMessageProps>({ heading: "", description: "" });
  const [initialValues, setInitialValues] = useState<FormValueProps>({
    organisation: "",
    email: "",
    first_name: "",
    last_name: "",
    agree_to: false,
  });
  const [loading, setLoading] = useState(true);

  const onSubmit = async (values: FormValueProps) => {
    const { first_name, last_name, email } = values;
    const created = await createNewUser(first_name, last_name, email, token!);
    if (!created) {
      return { [FORM_ERROR]: "Something went wrong. Please try again later." };
    }
    return;
  };

  useEffect(() => {
    const validateToken = async (token: string) => {
      if (!token) {
        setPageError({
          heading: "No token provided",
          description: "Please check the URL found in your invitation email from Seer Data again.",
        });
        return;
      }

      const res: any = await database.get(`invitations/${token}`, "");
      if (res.statusCode === 200) {
        const emailAlreadyExists = await userExists(res.body.data.invitation.email);
        if (emailAlreadyExists) {
          setPageError({
            heading: "Email already registered",
            description:
              "The email address associated with this invitation is already registered.Please log in or ask your organisation owner to send you a new invitation.",
          });
          return;
        } else {
          const { name, email } = res.body.data.invitation;
          setInitialValues({ ...initialValues, organisation: name, email });
          getMixpanel(store).track("Page view", { Page: "Signup member" }); // only fire on mount
          return;
        }
      } else {
        setPageError({
          heading: "Invalid invitation link",
          description: "Please check the URL found in your invitation email from Seer Data again.",
        });
        return;
      }
    };
    validateToken(token || "");
    setLoading(false);
  }, []);

  return (
    <>
      <Helmet>
        <title>Accept Invitation</title>
      </Helmet>
      <FormWrapper>
        {loading ? (
          <ContentPlaceholder />
        ) : (
          <>
            {pageError.heading ? (
              <ErrorMessage heading={pageError.heading} description={pageError.description} />
            ) : (
              <FinalForm
                onSubmit={onSubmit}
                initialValues={initialValues}
                render={(formRenderProps) => {
                  const { handleSubmit, submitting, submitError } = formRenderProps;
                  return (
                    <Form onSubmit={handleSubmit} id="user-signup-existingorg-accept-invite">
                      <div className="mb-4 text-center text-md-left">
                        <FormHeading>Join Seer Data & Analytics</FormHeading>
                        <p
                          className="fs-1250 fw-600 my-0"
                          style={{ color: COLORS.grey800 }}
                        >{`You have been invited to join your organisation, ${initialValues.organisation} on Seer Data.`}</p>
                      </div>
                      <Field
                        id="invite-organisation-label"
                        label="Organisation"
                        name="organisation"
                        component={FieldInput}
                        className="mb-4"
                        disabled
                      />
                      <Field
                        id="invite-email-label"
                        label="Your work email"
                        name="email"
                        component={FieldInput}
                        placeholder="E.g user@company.com"
                        className="mb-4"
                        disabled
                      />
                      <Field
                        id="invite-first-name-label"
                        label="First name"
                        name="first_name"
                        component={FieldInput}
                        validate={composeValidators(required)}
                        className="mb-4"
                      />
                      <Field
                        id="invite-last-name-label"
                        label="Last name"
                        name="last_name"
                        component={FieldInput}
                        validate={composeValidators(required)}
                        className="mb-4"
                      />
                      <Field
                        id="invite-agree-to-label"
                        name="agree_to"
                        label="You agree to the Terms of Service and Privacy Policy"
                        component={FieldCheck}
                        validate={requiredCustomMessage(
                          "Please read over and agree to the Terms of Service and Privacy Policy to continue.",
                        )}
                      />
                      <Button fluid colorConfig="purpleInverted" type="submit" disabled={submitting}>
                        Join organisation
                      </Button>
                      {submitError && (
                        <p role="alert" style={{ color: COLORS.red500 }}>
                          <b>{submitError}</b>
                        </p>
                      )}
                    </Form>
                  );
                }}
              />
            )}
          </>
        )}
      </FormWrapper>
      <BottomSection text="Already have an account?" label="Login" link="/login" />
    </>
  );
};

export const AcceptInvite = inject("store")(observer(Component));
