import * as React from "react";
import { Button, Form, Grid, Icon, Popup } from "semantic-ui-react";
import { uniqueId } from "lodash";

import { ColorPaletteModal } from "component/insightBuilder/Chart/includes/ColorPaletteModal";
import { ColorPicker } from "component/ColorPicker";
import { ColorCircle } from "component/ColorCircle";
import { DraggableCanvasColor } from "component/insightBuilder/Chart/includes/DraggableCanvasColor";

interface ColorApplicatorUIProps {
  allColorPalettes: any[];
  applicator: any;
  onApplyBrush: (applicator) => void;
}

export const ColorApplicatorUI = (props: ColorApplicatorUIProps): JSX.Element => {
  const { allColorPalettes, applicator, onApplyBrush } = props;
  const {
    brushes,
    selectedPalette,
    updateSelectedPalette,
    selectedBrush,
    updateSelectedBrush,
    canvas,
    updateCanvas,
    colorPickerOpen,
    updateColorPickerOpen,
    paletteModalOpen,
    updatePaletteModalOpen,
    moveCanvasItem,
  } = applicator;
  const selectedPaletteData = allColorPalettes[selectedPalette];

  // reset selected on add / remove palettes @TODO - re-enable when we update mobx, annoying that it keeps breaking hooks
  // React.useEffect(() => {
  //   applicator.updateSelectedPalette(0);
  // }, [store.colorPalette.allColorPalettes.length]);

  return (
    <>
      <ColorPaletteModal isOpen={paletteModalOpen} close={() => updatePaletteModalOpen(false)} />
      <ColorPicker
        open={colorPickerOpen}
        setColorPickerOpen={(newState) => updateColorPickerOpen(newState)}
        onSave={(color) => updateCanvas([...canvas, { color, id: uniqueId() }])}
        headerText="Pick Colour"
        saveText={
          <>
            Pick <Icon name="hand point up outline" />
          </>
        }
      />
      <Form onSubmit={() => null}>
        <Grid>
          {allColorPalettes?.length > 0 && (
            <Grid.Row divided>
              <Grid.Column width={7}>
                <Form.Select
                  inline
                  fluid
                  label="Palette"
                  options={[
                    ...allColorPalettes.map((palette, idx) => ({
                      key: `${idx}${palette.name}${palette.id}`,
                      text: palette.name,
                      value: idx,
                    })),
                    {
                      key: "add-new",
                      text: (
                        <span className="text-primary">
                          <b>Create new palette</b>
                          <Icon name="plus" circular className="ml-1" />
                        </span>
                      ),
                      value: "add-new",
                    },
                  ]}
                  value={selectedPalette}
                  onChange={(_, { value }) => {
                    if (value === "add-new") {
                      updatePaletteModalOpen(true);
                    } else {
                      updateSelectedPalette(value);
                    }
                  }}
                  selectOnBlur={false}
                />
                {!!selectedPaletteData && (
                  <div className="bg-light pt-3 px-3 pb-0 rounded-3 mb-3">
                    <div className="d-flex flex-wrap">
                      {selectedPaletteData.colors.map((color, idx) => (
                        <ColorCircle
                          color={color}
                          colorIndex={idx}
                          selectable
                          handleCircleOnClick={() => updateCanvas([...canvas, { color, id: uniqueId() }])}
                        />
                      ))}
                    </div>
                    <div className="text-right">
                      <button
                        type="button"
                        className="btn-unstyled p-2 text-primary"
                        onClick={() => updateCanvas([...canvas, ...selectedPaletteData.colors.map((color) => ({ color, id: uniqueId() }))])}
                      >
                        <b>Add all</b>
                      </button>
                    </div>
                  </div>
                )}
              </Grid.Column>
              <Grid.Column width={5}>
                <Form.Field>
                  <label className="m-0">Canvas</label>
                  <input className="visibility-hidden" type="text" value="ignore" onChange={() => null} />
                </Form.Field>
                <div className="bg-light pt-3 px-3 pb-0 rounded-3 mb-3">
                  <div className="d-flex flex-wrap">
                    {canvas.length > 0 &&
                      canvas.map(({ color, id }, idx) => (
                        <DraggableCanvasColor moveCanvasItem={moveCanvasItem} index={idx} id={id}>
                          <ColorCircle
                            showIndex
                            disableTooltip
                            color={color}
                            colorIndex={idx}
                            handleCrossOnClick={() => {
                              const newCanvas = [...canvas];
                              newCanvas.splice(idx, 1);
                              updateCanvas(newCanvas);
                            }}
                          />
                        </DraggableCanvasColor>
                      ))}
                    <Popup
                      key="add-color"
                      content="Add"
                      position="top center"
                      size="mini"
                      trigger={
                        <Icon
                          key="add-color-icon"
                          onClick={() => updateColorPickerOpen(true)}
                          circular
                          name="plus"
                          color="grey"
                          className="mb-3 cursor-pointer"
                        />
                      }
                    />
                  </div>
                  {canvas.length > 0 && (
                    <div className="text-right">
                      <button type="button" className="btn-unstyled p-2 text-primary" onClick={() => updateCanvas([])}>
                        <b>Clear all</b>
                      </button>
                    </div>
                  )}
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                <Form.Select
                  inline
                  fluid
                  label="Brush"
                  options={Object.getOwnPropertyNames(brushes).map((brush) => ({
                    key: brush,
                    text: brushes[brush].label,
                    value: brush,
                  }))}
                  value={selectedBrush}
                  onChange={(_, { value }) => updateSelectedBrush(String(value))}
                />
                <Button
                  className="bg-primary text-white bg-hover-red"
                  onClick={() => onApplyBrush(applicator)}
                  disabled={canvas.length < 1}
                >
                  Apply <Icon name="paint brush" />
                </Button>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Form>
    </>
  );
};
