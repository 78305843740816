import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import { Divider, Grid, Icon, Label, Segment, MenuItem, Header, Confirm, Button, Pagination } from "semantic-ui-react";
import Protected from "component/protected";
import Store from "common/store";
import { sortByDate } from "common/helpers/dataset";
import { DatasetTemplateList } from "./includes/DatasetTemplateList";
import { Breadcrumbs } from "component/Breadcrumbs/Breadcrumbs";
import { EmailMessagesModal, Messages } from "./includes/EmailMessagesModal";
import { HeadingSection, IItem } from "./includes/HeadingSection";
import { StyleTab, StyledContainer } from "component/Suitcases/SuitcasesContent.style";
import { DatasetAccess } from "./includes/DatasetAccess";
import { DATA_SOURCE_STEPS, NewDataSourceModal } from "./includes/NewDataSourceModal";
import { getMixpanel } from "common/api";
import { CreateModal } from "./includes/CreateModal";
import { COLOURS } from "pages/DatasetURLUpload/DatasetURLUpload.style";
import { CompletenessMeasures } from "./includes/CompletenessMeasures";
import { DatasetIngestedSchema } from "./includes/IngestedSchema";
import { DatasetURLView } from "pages/DatasetURLView/DatasetURLView";
import { DateTime } from "luxon";
import { ContentPlaceholder } from "component/ContentPlaceholder";
import { ListResponseData, ListTemplate, IDataset, TemplatesData } from "common/store/dataset";
// TODO: Switch HeadingSection to use ActionsButton
// See: https://github.com/seer-data/seer-frontend/commit/82f93767ef393d6adfb924441345ec8c9716821a#diff-49462f1c9763b8fae64270aba5dbe742a55f9741747a5b95a7a8e1fa3afedf4b
// import { ActionsButton } from "component/UI/ActionsButton";

const PAGE_LIMIT = 10;

export const StyledMenuItem = styled(MenuItem)`
  font-weight: bold !important;
  font-size: 1.15rem !important;
  padding: 10px 15px !important;
  @media (min-width: 768px) {
    padding: 15px 20px !important;
    font-size: 1.25rem !important;
  }
  @media (min-width: 992px) {
    padding: 20px 25px !important;
    font-size: 1.425rem !important;
  }
`;

interface DatasetProps {
  store?: Store;
}

const templatesHeaderItems = [
  {
    name: "Name",
    sortColumn: "name",
    className: "",
  },
  {
    name: "Updated at",
    className: "",
  },
  {
    name: "Updated by",
    className: "",
  },
  {
    name: "Contributors",
    className: "",
  },
  {
    name: "Latest Data",
    className: "w-15",
  },
];

const DatasetPageComponent = ({ store }: DatasetProps): JSX.Element => {
  const [dataset, setDataset] = React.useState<IDataset | null>(null);
  const [templatesData, setTemplatesData] = React.useState<ListResponseData<TemplatesData> | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [activePage, setActivePage] = React.useState(1);
  const [sort, setSort] = React.useState("name");
  const [externalContributorsOnly, setExternalContributorsOnly] = React.useState(false);
  const [showEmailMessagesModal, setShowEmailMessagesModal] = React.useState(false);
  const [showNewDataSourceModal, setShowNewDataSourceModal] = React.useState(false);
  const [showManageAccessModal, setShowManageAccessModal] = React.useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = React.useState(false);

  const history = useHistory();

  const { breakpoint } = store!.ui;
  const isMobileScreen = breakpoint === "mobile";

  const datasetID = Number(useParams<{ datasetID: string }>().datasetID);

  const { datasetStatusLabels: datasetStatus } = store!.dataset;
  const {
    name = "",
    link,
    contributor_welcome_message = "",
    contributor_reminder_message = "",
    contributor_thank_you_message = "",
    updated_at,
    published,
    dataset_status_id,
    preprocessed,
    ssdc_config,
  } = dataset || {};

  const { meta, data } = templatesData || {};
  const { templates } = data || {};
  const totalTemplates = meta?.total ?? 0;
  const totalPages = Math.ceil(totalTemplates / PAGE_LIMIT);
  const startNumber = (activePage - 1) * PAGE_LIMIT + 1;
  const endNumber = activePage * PAGE_LIMIT > totalTemplates ? totalTemplates : activePage * PAGE_LIMIT;

  const isEmptyDataset = ssdc_config === null && templates?.length === 0;
  const isURLDataset = ssdc_config !== null;

  const loadDataset = async () => {
    const res: any = await store!.dataset.getDataset(datasetID);
    if (res.data) {
      setDataset(res.data);
    } else {
      setError("This dataset doesn't exist on your account. Please check URL.");
    }
  };

  const loadTemplates = async () => {
    const skip = PAGE_LIMIT * (activePage - 1);
    const res: any = await store!.dataset.listTemplates(datasetID, skip, sort);
    if (res.data) {
      setTemplatesData(res.data);
    } else {
      console.log(res.error);
    }
  };

  React.useEffect(() => {
    const init = async () => {
      setLoading(true);
      await loadDataset();
      await loadTemplates();
      if (!store!.dataset.datasetStatusLabels) {
        await store!.dataset.getDatasetStatusLabels();
      }
      setLoading(false);
    };
    init();
    window.scrollTo(0, 0);
    getMixpanel(store!).track("Page view", { Page: "Collect Data > Dataset" });
  }, [activePage, sort]);

  if (error) {
    return (
      <Header as="h4" className="text-center">
        {error}
      </Header>
    );
  }

  const saveEmailMessages = async (messages: Messages) => {
    const success = await store!.dataset.updateDatasetEmailMessages(datasetID, {
      contributor_welcome_message: messages[0].content,
      contributor_reminder_message: messages[1].content,
      contributor_thank_you_message: messages[2].content,
    });
    getMixpanel(store!).track("Configure Dataset Emails", { "Dataset Id": datasetID, "Dataset Name": name });
    if (success) {
      await loadDataset();
    }
  };

  const deleteDatasetClickHandler = async () => {
    const success = await store!.dataset.deleteDataset(datasetID);
    if (success) {
      getMixpanel(store!).track("Delete Dataset");
      history.push("/datasets");
    }
  };

  const createTemplate = async (nameStr: string): Promise<string | void> => {
    const name = (nameStr || "").trim();
    if (!name) {
      return "Template name is required";
    }
    const res = await store!.dataset.createTemplate(name, datasetID);
    if (res.error) {
      return res.error;
    } else {
      getMixpanel(store!).track("Create Dataset Template", { "Dataset Id": datasetID, "Dataset Name": name });
      await loadTemplates();
      // TODO: Consider whether to uncomment - currently commented so the user can see the impact of their action
      //       instead of auto-navigating them away
      // history.push(`/datasets/${datasetID}/templates/${res.id}`);
    }
  };

  const getDatasetStatusButtonLabel = () => {
    if (dataset_status_id && datasetStatus) {
      const label = datasetStatus.find((status) => status.id === dataset_status_id)!.label;
      return `${label.charAt(0).toUpperCase()}${label.slice(1)}`;
    }
    return "Queue for Ingestion";
  };

  const queueIngestionHandler = async () => {
    const success = await store!.dataset.queueIngestion(datasetID);
    getMixpanel(store!).track("Queue for Ingestion", { "Dataset Id": datasetID, "Dataset Name": name });
    if (!success) {
      window.alert("The dataset is already queued or being ingested.");
    }
    await loadDataset();
  };

  const getProcessedTemplates = (templates: ListTemplate[]) => {
    const filtered = externalContributorsOnly
      ? templates.map((template) => ({
          ...template,
          update_history: template.update_history.filter((history) => !history.email.toLowerCase().includes("@seerdata.com.au")),
        }))
      : templates;
    return filtered.map((template) => {
      const { contributors, update_history } = template;
      return {
        ...template,
        last_updated_at: update_history.length ? sortByDate(update_history)[0].datetime : null,
        last_updated_by: update_history.length ? sortByDate(update_history)[0].name : null,
        contributorsLength: contributors?.length || 0,
      };
    });
  };

  const tabItems = [
    {
      menuItem: <StyledMenuItem>{`${isMobileScreen ? "" : "Dataset "}Templates`}</StyledMenuItem>,
      render: () => (
        <>
          {templates && templates.length > 0 && (
            <Label
              className="cursor-pointer"
              color={externalContributorsOnly ? "purple" : undefined}
              onClick={() => setExternalContributorsOnly(!externalContributorsOnly)}
            >
              Only show activity from external contributors
            </Label>
          )}
          {isEmptyDataset ? (
            <Segment className={"bg-white border-0 shadow-none px-3 px-md-5 py-5 w-100"} style={{ marginTop: 40 }}>
              <Grid columns={2} stackable>
                <Grid.Column>
                  <div className="m-auto" style={{ maxWidth: 349 }}>
                    <Header as="h5" className="mb-4">
                      Import Existing Data
                    </Header>
                    <img
                      src="/assets/import_existing_data_image.png"
                      alt="Import existing Data"
                      className="w-100 h-auto"
                      style={{ marginBottom: 14 }}
                    />
                    <p className="ff-primary fw-400" style={{ color: COLOURS.greyText700, fontSize: 16, marginBottom: 24 }}>
                      Bring unit record data you have already collected into the platform for powerful querying and visualisation.
                    </p>
                    <Button
                      icon
                      fluid
                      labelPosition="left"
                      color="purple"
                      style={{ marginBottom: 32 }}
                      onClick={() => {
                        store?.dataset.setNewDataModalStep(DATA_SOURCE_STEPS.dataSourceExcelUpload);
                        setShowNewDataSourceModal(true);
                      }}
                    >
                      <Icon name="upload" />
                      Upload Data File
                    </Button>
                    <div className="d-flex">
                      <Icon name="info circle" style={{ color: COLOURS.greyText700 }} />
                      <p className="ff-primary fw-400" style={{ color: COLOURS.greyText700, fontSize: 14 }}>
                        For example, survey responses, individual attendance records.
                      </p>
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="m-auto" style={{ maxWidth: 349 }}>
                    <Header as="h5" className="mb-4">
                      Collect New Data
                    </Header>
                    <img
                      src="/assets/collect_new_data_image.png"
                      alt="Collect New Data"
                      className="w-100 h-auto"
                      style={{ marginBottom: 14 }}
                    />
                    <p className="ff-primary fw-400" style={{ color: COLOURS.greyText700, fontSize: 16, marginBottom: 24 }}>
                      Create custom data tables. This feature allows contributors to record aggregate data under specific column and row
                      headings.
                    </p>
                    <Button
                      id="userflow-element-create-template"
                      icon
                      fluid
                      labelPosition="left"
                      color="purple"
                      style={{ marginBottom: 32 }}
                      onClick={() => store!.dataset.setShowCreateTemplateModal(true)}
                    >
                      <Icon name="table" />
                      Create Table
                    </Button>
                    <div className="d-flex">
                      <Icon name="info circle" style={{ color: COLOURS.greyText700 }} />
                      <p className="ff-primary fw-400" style={{ color: COLOURS.greyText700, fontSize: 14 }}>
                        For example, monthly event participation tracking, outcomes measurement{" "}
                      </p>
                    </div>
                  </div>
                </Grid.Column>
              </Grid>
              <Divider vertical style={{ height: "35%", top: "44%" }} className="text-secondary d-none d-md-block">
                Or
              </Divider>
            </Segment>
          ) : isURLDataset ? (
            <DatasetURLView datasetID={datasetID} />
          ) : (
            <>
              <DatasetTemplateList
                id="userflow-element-datasets-templates-list"
                headerItems={templatesHeaderItems}
                // Pass re-formatted templates to child component to make sorting works
                templates={getProcessedTemplates(templates || [])}
                applySort={(sort) => setSort(sort)}
                currentSort={sort}
              />
              <div className="d-flex justify-content-between align-items-center mb-5">
                <span className="fs-1000 text-medium">{`Showing ${startNumber}${startNumber !== endNumber ? `-${endNumber}` : ""} of ${totalTemplates} templates`}</span>
                <Pagination
                  pointing
                  secondary
                  size="large"
                  activePage={activePage}
                  boundaryRange={1}
                  onPageChange={(_e, { activePage }) => setActivePage(activePage as number)}
                  siblingRange={2}
                  totalPages={totalPages}
                  ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                  firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                  lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                  prevItem={{ content: <Icon name="angle left" />, icon: true }}
                  nextItem={{ content: <Icon name="angle right" />, icon: true }}
                />
              </div>
            </>
          )}
        </>
      ),
    },
    {
      menuItem: <StyledMenuItem>{`Ingested ${isMobileScreen ? "" : "Data "}Schema`}</StyledMenuItem>,
      render: () => (isEmptyDataset ? <></> : <DatasetIngestedSchema datasetID={datasetID} />),
    },
    {
      menuItem: <StyledMenuItem>{`${isMobileScreen ? "" : "Completeness "}Measures`}</StyledMenuItem>,
      render: () => <CompletenessMeasures datasetID={datasetID} />,
    },
  ];

  const dropdownOptions = [
    { text: "Configure Emails", onClick: () => setShowEmailMessagesModal(true) },
    { text: "Delete", onClick: () => setShowDeleteConfirmModal(true) },
  ];
  if (link) {
    dropdownOptions.unshift({ text: "Dataset Link", onClick: () => window.open(link, "_blank") });
  }
  if (!isEmptyDataset) {
    dropdownOptions.splice(-1, 0, { text: "Manage Access", onClick: () => setShowManageAccessModal(true) });
  }

  const queueIngestionAvailable =
    dataset_status_id === null &&
    (templates?.some((template) => template.num_tables) || // SSDC with templates & tables
      (preprocessed === false && !!ssdc_config)); // SSDC URL

  const headerItems: IItem[] = [
    {
      type: "button-secondary",
      label: getDatasetStatusButtonLabel(),
      icon: "upload",
      action: queueIngestionHandler,
      disabled: !queueIngestionAvailable,
    },
    { type: "link", label: "Dataset Link", icon: "external alternate", link },
    { type: "button-secondary", label: "Configure Emails", icon: "mail", action: () => setShowEmailMessagesModal(true) },
  ];
  if (isURLDataset) {
    headerItems.push({
      type: "button-secondary",
      label: "Add Data",
      icon: "plus",
      action: () => {
        store?.dataset.setNewDataModalStep(DATA_SOURCE_STEPS.dataSourceExcelUpload);
        setShowNewDataSourceModal(true);
      },
    });
  }
  if (templates && templates.length > 0) {
    headerItems.push({
      type: "button-secondary",
      label: "Add Template",
      icon: "plus",
      action: () => {
        store!.dataset.setShowCreateTemplateModal(true);
      },
    });
  }

  return (
    <StyledContainer>
      <Helmet>
        <title>{`Dataset - ${name}`}</title>
      </Helmet>
      {loading ? (
        <ContentPlaceholder />
      ) : (
        <>
          <Breadcrumbs
            items={[
              { label: "Datasets", pathname: "/datasets" },
              { label: name, pathname: `/datasets/${datasetID}` },
            ]}
          />
          <HeadingSection name={name} items={headerItems} actionsDropdown={dropdownOptions} />
          <div className="d-flex" style={{ gap: 15 }}>
            <p>
              <span className="text-secondary fw-700">Updated at: </span>
              {updated_at ? DateTime.fromISO(updated_at).setZone("local").toFormat("FF") : "-"}
            </p>
            <p>
              <span className="text-secondary fw-700">Status: </span>
              {published ? "Published" : "Unpublished"}
            </p>
          </div>
          {/* New Data Source Modal */}
          <NewDataSourceModal datasetName={name} isOpen={showNewDataSourceModal} closeModal={() => setShowNewDataSourceModal(false)} />
          {/* Create Template Modal */}
          <CreateModal
            heading="Template"
            isOpen={store!.dataset.showCreateTemplateModal}
            closeModal={() => store!.dataset.setShowCreateTemplateModal(false)}
            saveModal={createTemplate}
          />
          {/* Dataset Access Modal */}
          {!isEmptyDataset && (
            <DatasetAccess
              isOpen={showManageAccessModal}
              datasetID={datasetID}
              datasetName={name}
              closeModalHandler={() => setShowManageAccessModal(false)}
            />
          )}
          {/* Edit dataset email messages */}
          <EmailMessagesModal
            subHeading={`Datasets > ${name}`}
            isOpen={showEmailMessagesModal}
            emailMessages={[
              { title: "Welcome Message", content: contributor_welcome_message },
              { title: "Reminder Message", content: contributor_reminder_message },
              { title: "Thank You Message", content: contributor_thank_you_message },
            ]}
            closeModal={() => setShowEmailMessagesModal(false)}
            saveModal={saveEmailMessages}
          />
          <Confirm
            open={showDeleteConfirmModal}
            header="Are you sure?"
            content={`Are you you want to delete - ${name}`}
            confirmButton="Delete"
            onCancel={() => setShowDeleteConfirmModal(false)}
            onConfirm={deleteDatasetClickHandler}
          />
          <StyleTab menu={{ secondary: true, pointing: true }} panes={tabItems} />
        </>
      )}
    </StyledContainer>
  );
};

export const DatasetPage = Protected(inject("store")(observer(DatasetPageComponent)));
