import * as React from "react";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { CategoricalChartProps } from "recharts/types/chart/generateCategoricalChart";
import { CustomMetricLabel, CustomMetricToolTip } from "./CustomCharts";
import { IFilter, TypeDecimalPoints } from "common/store/builder";
import { ObjectAny } from "common/helpers/types";

interface IMetricChart extends CategoricalChartProps {
  chartColors: string[];
  filteredChartTables: ObjectAny[];
  columns: IFilter[];
  decimalPoints: TypeDecimalPoints;
  bigLabelFontSize?: number | undefined;
  smallLabelFontSize?: number | undefined;
  labelOverride?: string;
}

// @TODO - need an in depth refactoring to take up the full space of the recharts container, we are currently limiting the size
export const MetricChart = (props: IMetricChart): JSX.Element => {
  const { data, chartColors, width, height, decimalPoints, filteredChartTables, columns, bigLabelFontSize, smallLabelFontSize, labelOverride } = props;
  const circleData = data![0];
  const circleWidth = 30 / data!.length;
  const circlePadding = 5;
  const centerCircleRadius = 95 - (circleWidth + circlePadding) * data!.length - 5;
  const circleBackgroundColor = "rgba(222, 222, 235, 0.4)";
  return (
    <PieChart
      width={width}
      height={height}
    >
      {/* Middle label */}
      <Pie
        dataKey="value"
        data={circleData}
        labelLine={false}
        label={CustomMetricLabel(decimalPoints, bigLabelFontSize, smallLabelFontSize, labelOverride)}
        outerRadius={`${centerCircleRadius}%`}
        strokeWidth={0}
        fill="none"
        isAnimationActive={false}
      />
      {/* Outer rings */}
      {data!.map((singleData, dataIdx) => (
        <Pie
          key={dataIdx}
          dataKey="value"
          data={singleData}
          labelLine={false}
          innerRadius={`${centerCircleRadius + 5 + circlePadding * (dataIdx + 1) + circleWidth * dataIdx}%`}
          outerRadius={`${centerCircleRadius + 5 + (circleWidth + circlePadding) * (dataIdx + 1)}%`}
          startAngle={90}
          endAngle={450}
          isAnimationActive={true}
        >
          {singleData?.map((_entry, singleDataIdx) => (
            <Cell
              key={`cell-${singleDataIdx}`}
              className={singleDataIdx === 0 ? "cursor-pointer" : ""}
              strokeWidth={0}
              fill={singleDataIdx === 0 ? chartColors[dataIdx] || chartColors[0] : circleBackgroundColor}
            />
          ))}
        </Pie>
      ))}
      <Tooltip
        offset={0}
        isAnimationActive={false}
        content={CustomMetricToolTip(filteredChartTables, columns, decimalPoints)}
      />
    </PieChart>
  );
};
