import * as React from "react";
import { Popup, Button, Table, Icon } from "semantic-ui-react";
import { inject, observer } from "mobx-react";

import Store from "common/store";

interface RootComponentProps {
  type: "dataset" | "template" | "table";
  data: any; // varies depending on type
  triggerOverride?: JSX.Element; // must have "data-lastupdatetoggle" attribute set if event propagation handlers are required
}

export const LatestDataPopup = ({ type, data, triggerOverride }: RootComponentProps): JSX.Element => (
  <Popup
    position="bottom right"
    style={{ minWidth: 400 }}
    trigger={
      triggerOverride || (
        <Button data-lastupdatetoggle="true" size="small">
          Show
        </Button>
      )
    }
    content={<LatestData type={type} data={data} />}
    on="click"
    onClose={(e) => {
      const target = e.target as Element;
      if (target.attributes?.hasOwnProperty("data-lastupdatetoggle")) {
        e.stopPropagation();
      }
    }}
    onOpen={(e) => e.stopPropagation()}
  />
);

interface ComponentProps extends RootComponentProps {
  store?: Store;
}

class Component extends React.Component<ComponentProps> {
  state = {
    loading: true,
    values: {},
  };

  componentDidMount() {
    this.getValues();
  }

  getValues = async () => {
    const { type, data } = this.props;
    const values = await this.props.store!.dataset.getLatestData(type, data);
    this.setState({ loading: false, values });
  };

  render() {
    const { loading, values } = this.state;
    const entries: [string, string][] = Object.entries(values);
    const hasEntries = entries.length;
    return (
      <div>
        {loading ? (
          <p>
            <Icon className="mr-2" loading name="cog" />
            Loading...
          </p>
        ) : (
          <div>
            {hasEntries ? (
              <Table celled striped compact>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Time Variable</Table.HeaderCell>
                    <Table.HeaderCell>Latest Available Category</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {entries.map(([variable, category]) => (
                    <Table.Row key={variable}>
                      <Table.Cell>{variable}</Table.Cell>
                      <Table.Cell>{category}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <div>No compatible Time based variables configured.</div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export const LatestData = inject("store")(observer(Component));
