import * as React from "react";
import { IIndicatorModuleData } from "component/Modules/IndicatorModule";
import { RestackingDashboardModule } from "component/Modules/RestackingDashboardModule";

export const BackupRestackingANCDashboardMDHS = () => {
  const indicatorData: IIndicatorModuleData[] = [
    {
      heading: "Participation: Who is accessing our services?",
      subheading: "How are our services being accessed?",
      description:
        "This refers to both who uses the service and how much (dosage). Pregnant Women need to attend at the right dosage levels for benefits to be realised.",
      indicators: [
        {
          indicatorTitle: "Universal indicator: Booking appointment in first trimester",
          chart1: {
            id: 84214,
            title: "",
          },
          chart2: {
            id: 84212,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10979",
          chart1Explainer: {
            shows:
              "The chart above shows the number of pregnant women who attend their booking appointment within the first trimester (12 weeks) of their pregnancy. The orange section indicates the number of pregnant women who attend their booking appointment later than the first trimester, that is, after 12 weeks of pregnancy.",
          },
          chart2Explainer: {
            shows:
              "The chart above shows the proportion of pregnant women who attend their booking appointment within the first trimester (12 weeks) of their pregnancy, plotted against RSTO Evidence.",
          },
          why: "Women attending antenatal care in the first trimester of pregnancy have lower maternal and perinatal mortality than women who attend late or not at all.",
        },
        {
          indicatorTitle: "Targeted indicator: Booking appointment in first trimester",
          chart1: {
            id: 84213,
            title: "",
          },
          chart2: {
            id: 84211,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10979",
          chart1Explainer: {
            shows:
              "The chart above shows the number of pregnant women recognised in a priority group who attend their booking appointment within 12 weeks 0 days gestation. Priority groups include: pregnant women experiencing vulnerability, refugees or asylum seeker populations, disability populations, Aboriginal and Torres Strait Islander populations, Health Care Card holders, children in out-of-home Care, and culturally and linguistically diverse (CALD) populations.",
          },
          chart2Explainer: {
            shows:
              "The chart above shows the proportion of all pregnant women recognised in a priority group who attend their booking appointment within 12 weeks 0 days gestation. Priority groups include: pregnant women experiencing vulnerability, refugees or asylum seeker populations, disability populations, Aboriginal and Torres Strait Islander populations, Health Care Card holders, children in out-of-home Care, and culturally and linguistically diverse (CALD) populations.",
          },
          why: "Pregnant women recognised in a priority group are at risk of poor maternal and perinatal outcomes. They are also more likely to have complex health needs and face multiple barriers accessing pregnancy care and navigating the healthcare system.",
        },
        {
          indicatorTitle: "Universal indicator: Antenatal visits in first trimester",
          chart1: {
            id: 91162,
            title: "",
          },
          chart2: {
            id: 91163,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10979",
          chart1Explainer: {
            shows:
              "The chart above shows the number of pregnant women who attend at least the recommended number of antenatal care appointments – 10 for 1st pregnancy, 7 for subsequent pregnancies.",
          },
          chart2Explainer: {
            shows:
              "The chart above shows the proportion of pregnant women who attend at least the recommended number of antenatal care appointments – 10 for 1st pregnancy, 7 for subsequent pregnancies.",
          },
          why: "Pregnant women experiencing their first pregnancy may require additional education and support e.g., what to expect at each stage of pregnancy.",
        },
        {
          indicatorTitle: "Targeted indicator: Antenatal visits in first trimester",
          chart1: {
            id: 91355,
            title: "",
          },
          chart2: {
            id: 91356,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10979",
          chart1Explainer: {
            shows:
              "The chart above shows the number of pregnant women recognised in a priority group who attend at least the recommended number of antenatal care appointments – 10 for 1st pregnancy, 7 for subsequent pregnancies. Priority groups include: pregnant women experiencing vulnerability, refugees or asylum seeker populations, disability populations, Aboriginal and Torres Strait Islander populations, Health Care Card holders, children in out-of-home Care, and culturally and linguistically diverse (CALD) populations.",
          },
          chart2Explainer: {
            shows:
              "The chart above shows the proportion of pregnant women recognised in a priority group who attend at least the recommended number of antenatal care appointments – 10 for 1st pregnancy, 7 for subsequent pregnancies. Priority groups include: pregnant women experiencing vulnerability, refugees or asylum seeker populations, disability populations, Aboriginal and Torres Strait Islander populations, Health Care Card holders, children in out-of-home Care, and culturally and linguistically diverse (CALD) populations.",
          },
          why: "There is some evidence that perinatal mortality may be increased with reduced visits in some vulnerable pregnant women. Pregnant women experiencing their first pregnancy may require additional education and support e.g. what to expect at each stage of pregnancy.",
        },
      ],
    },
    {
      heading: "Quality: Are we delivering high-quality services?",
      subheading: "Are we delivering high quality services?",
      description: "Services or programs with ‘quality’ are those where delivery aligns to the desired outcomes in the evidence-base.",
      indicators: [
        {
          indicatorTitle: "Universal indicator: Continuity of care",
          chart1: {
            id: 84221,
            title: "",
          },
          chart2: {
            id: 84227,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows:
              "The chart above shows the number of pregnant women who attended 5 or more appointments and attended 80% of their appointments with the same midwife. The grey areas indicate the number of women who attended 5 or more appointments, but the name of the midwife was not recorded for all appointments.",
            // why: "This can be used to ...",
          },
          chart2Explainer: {
            shows:
              "The chart above shows the proportion of pregnant women who attended 5 or more appointments and attended 80% of their appointments with the same midwife, plotted against RSTO Evidence.",
            // why: "Women who experience continuity of care led by a midwife experience better outcomes.",
          },
          why: "Women who experience continuity of care led by a midwife experience better outcomes.",
        },
        {
          indicatorTitle: "Universal indicator: BMI Recorded",
          chart1: {
            id: 84220,
            title: "",
          },
          chart2: {
            id: 84226,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows:
              "The chart above shows the number of pregnant women who attended antenatal appointments where BMI was calculated and recorded.",
            // why: "This can be used to ...",
          },
          chart2Explainer: {
            shows:
              "The chart above shows the proportion of pregnant women who attended antenatal appointments where BMI was calculated and recorded, plotted against RSTO Evidence.",
            // why: "A low BMI during pregnancy increases the risk of having a low birth weight baby and/or preterm birth. A high BMI during pregnancy increases the risk of baby being born preterm and/or low birth weight, gestational diabetes and hypertensive disorders, congenital anomalies and neural tube defects.",
          },
          why: "A low BMI during pregnancy increases the risk of having a low birth weight baby and/or preterm birth. A high BMI during pregnancy increases the risk of baby being born preterm and/or low birth weight, gestational diabetes and hypertensive disorders, congenital anomalies and neural tube defects.",
        },
        {
          indicatorTitle: "Universal indicator: Substance use",
          chart1: {
            id: 84225,
            title: "",
          },
          chart2: {
            id: 84219,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows:
              "The chart above shows the number of pregnant women who attended antenatal appointments where they are asked about their substance use, alcohol and tobacco included, with the answer documented.",
          },
          chart2Explainer: {
            shows:
              "The chart above shows the proportion of pregnant women who attended antenatal appointments where they are asked about their substance use, alcohol and tobacco included, with the answer documented, as plotted against RSTO Evidence.",
          },
          why: "Smoking in pregnancy increases the risk of ectopic pregnancy, preterm birth, miscarriage, reduced birth weight, small-for-gestational-age baby, stillbirth, fetal and infant mortality and sudden infant death syndrome.",
        },
        {
          indicatorTitle: "Universal indicator: Family violence",
          chart1: {
            id: 84217,
            title: "",
          },
          chart2: {
            id: 84224,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows:
              "The chart above shows the number of pregnant women who attended antenatal appointments where they are asked about family violence, with the answer documented.",
            why: "This can be used to ...",
          },
          chart2Explainer: {
            shows:
              "The chart above shows the proportion of pregnant women who attended antenatal appointments where they are asked about family violence, with the answer documented, plotted against RSTO Evidence.",
            // why: "Violence poses serious health risks to pregnant women and babies. Women exposed to violence during pregnancy are at risk of miscarriage, preterm birth, having a low birth weight baby, and are more likely to develop depression in the postnatal period.",
          },
          why: "Violence poses serious health risks to pregnant women and babies. Women exposed to violence during pregnancy are at risk of miscarriage, preterm birth, having a low birth weight baby, and are more likely to develop depression in the postnatal period.",
        },
        {
          indicatorTitle: "Universal indicator: Mental health history",
          chart1: {
            id: 84228,
            title: "",
          },
          chart2: {
            id: 84218,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows:
              "The chart above shows the number of pregnant women who attended antenatal appointments, where their mental health history is recorded.",
            // why: "This can be used to ...",
          },
          chart2Explainer: {
            shows:
              "The chart above shows the proportion of pregnant women who attended antenatal appointments, where their mental health history is recorded, plotted against RSTO Evidence.",
            // why: "Mental health conditions, particularly in their more severe form are often associated with impaired functioning e.g. a woman's ability to care for her infant and the formation of secure infant attachment, which may in turn be associated with poorer outcomes in the child.",
          },
          why: "Mental health conditions, particularly in their more severe form are often associated with impaired functioning e.g. a woman's ability to care for her infant and the formation of secure infant attachment, which may in turn be associated with poorer outcomes in the child.",
        },
        {
          indicatorTitle: "Universal indicator: Mental health screen",
          chart1: {
            id: 84216,
            title: "",
          },
          chart2: {
            id: 84223,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows: "The chart above shows the number of pregnant women with a mental health screen.",
            // why: "This can be used to ...",
          },
          chart2Explainer: {
            shows: "The chart above shows the proportion of pregnant women with a mental health screen, plotted against RSTO Evidence.",
            // why: "Pregnant women are more vulnerable to depression and anxiety or worsening of symptoms. Unmanaged mental health issues can result in adverse outcomes such as miscarriage, preterm birth and small for-gestational-age baby",
          },
          why: "Pregnant women are more vulnerable to depression and anxiety or worsening of symptoms. Unmanaged mental health issues can result in adverse outcomes such as miscarriage, preterm birth and small for-gestational-age baby.",
        },
        {
          indicatorTitle: "Universal indicator: Gestational diabetes",
          chart1: {
            id: 84215,
            title: "",
          },
          chart2: {
            id: 84222,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10981",
          chart1Explainer: {
            shows:
              "The chart above shows the number of pregnant women who complete testing for gestational diabetes at 24 weeks 0 days to 28 weeks 6 days (*the recommended point of pregnancy for diabetes screening) and have their test results available and acknowledged.",
          },
          chart2Explainer: {
            shows:
              "The chart above shows the proportion of pregnant women attend one or more antenatal appointments and who complete testing for gestational diabetes at 24 weeks 0 days to 28 weeks 6 days (*the recommended point of pregnancy for diabetes screening), and have their test results available and acknowledged.",
          },
          why: "Women with gestational diabetes have a higher risk of induced labour and are more likely to have  a preterm birth, high birth weight, caesarean birth, hypertension and longer hospital stay than women without diabetes, and their babies are at risk of poorer outcomes.",
        },
      ],
    },
    {
      heading: "Quantity: Are sufficient services available?",
      subheading: "Are sufficient services available?",
      description:
        "This relates to the physical access to local services in sufficient quantity. Quantity indicators help to determine the amount of resource and infrastructure needed to deliver the service for a given population at the right quality and dose.",
      indicators: [
        // {
        //   indicatorTitle: "Quantity Indicator: Number of facilities per 10,000 women",
        //   chart1: {
        //     // id: 84986,
        //     title: "",
        //   },
        //   chart2: {
        //     // id: 84981,
        //     title: "",
        //   },
        //   calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
        //   suitcaseLink: "https://seerplatform.com/suitcases/10980",
        //   // chart1Explainer: {
        //   //   shows: "The chart above shows ...",
        //   //   why: "This can be used to ...",
        //   // },
        //   // chart2Explainer: {
        //   //   shows: "The chart above shows ...",
        //   //   why: "This can be used to ...",
        //   // },
        // },
        {
          indicatorTitle:
            "Quantity indicator: Number of registered midwives working in the antenatal care facilities per 10,000 women of childbearing potential",
          chart1: {
            // id: 84213,
            title: "",
          },
          chart2: {
            // id: 84211,
            title: "",
          },
          calcLink: "https://www.rsto.org.au/media/yb3kbmz0/2306_rsto_indicatorguide.pdf",
          suitcaseLink: "https://seerplatform.com/suitcases/10980",
          // chart1Explainer: {
          //   shows: "The chart above shows ...",
          //   why: "This can be used to ...",
          // },
          // chart2Explainer: {
          //   shows: "The chart above shows ...",
          //   why: "This can be used to ...",
          // },
        },
      ],
    },
  ];

  const dashboardData = {
    heading: "Maryborough District Health Service",
    subheading: "Antenatal Care (ANC)",
    description:
      "Quality care during pregnancy monitors and supports the health and wellbeing of mothers and babies. The Restacking The Odds indicators in this area spans various topics like mental health, family violence, and gestational diabetes.",
    strategyLink: "https://www.rsto.org.au/",
    indicatorModuleData: indicatorData,
    hideSummary: true,
  };

  return <RestackingDashboardModule {...dashboardData} />;
};
