import styled from "styled-components";

export const StyleInput = styled.input<{ readOnly: boolean }>`
  color: #ffffff;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 4px;
  padding: 4px;
  ${(props) =>
    !props.readOnly &&
    `
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        cursor: pointer;
      };
      &:focus {
        color: #333333;
        border: 1px solid #c3c3c3;
        background-color: #ffffff;
      }
    `}
`;
