import * as React from "react";
import { Modal, Button, Icon, Form, Popup, Label } from "semantic-ui-react";
import { Field, Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";

import { required, composeValidators } from "common/helpers/finalForm";
import { Schema } from "common/helpers/dataset";
import {
  genSemanticSelectOptions,
  supportedAggregateVariables,
  getSchemaVariables,
  generateAggregationCategories,
  getAutoAggCategories,
  aggTypes,
} from "./helpers";
import { FieldSelect } from "./FieldSelect";

// for manually entered aggregation variable, to ensure the entered variable is not already on the schema which would be invalid
/* const uniqueVariableValidation = (schema: Schema) => (value: string): void | string => {
  if (getSchemaVariables(schema).map(str => str.toLowerCase()).includes(value.toLowerCase())) {
    return "Variable Name must be unique for schema";
  }
}; */

const hiddenAggTypeOptions = ["standard deviation", "mode", "median", "range"]; // not showing these to users yet
const aggTypeOptions = genSemanticSelectOptions(aggTypes)
  .map((opt) => ({ ...opt, text: opt.text.toUpperCase() }))
  .filter((opt) => !hiddenAggTypeOptions.includes(opt.value));

const aggregationOptions = {
  Date: genSemanticSelectOptions(["Year Month", "Year Quarter", "Year", "Financial Year"]),
  "Year Month": genSemanticSelectOptions(["Year Quarter", "Year", "Financial Year"]),
  "Year Quarter": genSemanticSelectOptions(["Year", "Financial Year"]),
};

interface Props {
  isOpen: boolean;
  close: () => void;
  onSubmit: (aggregation) => string | void;
  schema: Schema;
}

// NOTE - if we go down the path of manual aggregations, split into two forms, one for generators, one for manual entry which should use final-form for categories too.
// This component is currently built for Auto aggregations
export const AggregationModal = (props: Props): JSX.Element => {
  const { isOpen, close, onSubmit, schema } = props;
  const aggVariableOptions = genSemanticSelectOptions(
    getSchemaVariables(schema).filter((variable) => supportedAggregateVariables.includes(variable)),
  );
  const [categories, setCategories]: any = React.useState([]);
  const [selectedVariable, setSelectedVariable] = React.useState(); // last selected aggregation variable for generator trigger
  return (
    <Modal open={isOpen} onClose={close}>
      <Modal.Header className="fs-2000 fw-700 text-secondary">Create aggregation</Modal.Header>
      <FinalForm
        initialValues={{ type: "sum" }}
        onSubmit={(values): any => {
          const error = onSubmit({ ...values, categories });
          if (error) {
            return { [FORM_ERROR]: error };
          }
          close();
        }}
        render={({ handleSubmit, form, values, submitError }) => {
          const aggVariableState = form.getFieldState("aggVariable");
          const variableOptions =
            aggVariableState?.value && !(aggVariableState.error || aggVariableState?.submitError)
              ? aggregationOptions[aggVariableState.value] || []
              : [];
          if (values?.variable !== selectedVariable) {
            setSelectedVariable(values?.variable);
            setCategories(generateAggregationCategories(values.aggVariable, values.variable, schema));
          }
          return (
            <>
              <Modal.Content>
                <Form onSubmit={handleSubmit}>
                  <Field
                    name="type"
                    label="Aggregation type"
                    component={FieldSelect}
                    options={aggTypeOptions}
                    validate={composeValidators(required)}
                  />
                  <Field
                    name="aggVariable"
                    label="Schema Variable to aggregate on"
                    component={FieldSelect}
                    options={aggVariableOptions}
                    validate={composeValidators(required)}
                  />
                  <Field
                    disabled={!variableOptions.length}
                    name="variable"
                    label="Aggregate Variable to create"
                    component={FieldSelect}
                    options={variableOptions}
                    validate={composeValidators(required)}
                  />
                </Form>
                <h5>Aggregation Categories</h5>
                {!categories.length ? (
                  <p className="text-muted">Select a Schema Variable to aggregate on and the Aggregate Variable to create to continue.</p>
                ) : (
                  <div>
                    <p className="text-muted">
                      Click a <b>{values.variable}</b> category to inspect the <b>{values.aggVariable}</b> categories it aggregates on
                    </p>
                    {categories.map((cat) => (
                      <Popup
                        on={["click"]}
                        key={cat.name}
                        trigger={
                          <Button className="mr-2 mb-2">
                            <Icon name="eye" /> {cat.name}
                          </Button>
                        }
                        content={
                          <div>
                            <p>
                              <b>
                                {values.variable} - {cat.name}
                              </b>
                            </p>
                            <p className="text-muted">List of {values.aggVariable} categories aggregated on</p>
                            <div className="overflow-auto" style={{ maxHeight: 300, width: 500 }}>
                              <Label.Group>
                                {getAutoAggCategories(cat.aggAuto, values.aggVariable, values.variable, schema).map((aggCat) => (
                                  <Label circular key={aggCat}>
                                    {aggCat}
                                  </Label>
                                ))}
                              </Label.Group>
                            </div>
                          </div>
                        }
                      />
                    ))}
                  </div>
                )}
                {submitError && (
                  <p className="text-primary">
                    <b>{submitError}</b>
                  </p>
                )}
              </Modal.Content>
              <Modal.Actions>
                <Button type="button" onClick={close}>
                  Cancel
                </Button>
                <Button type="submit" className="bg-primary text-white bg-hover-red" onClick={handleSubmit}>
                  Create aggregation <Icon name="plus" className="ml-2 mr-0" />
                </Button>
              </Modal.Actions>
            </>
          );
        }}
      />
    </Modal>
  );
};
