import * as React from "react";
import styled from "styled-components";
import { Button, Icon, SemanticICONS, ButtonProps } from "semantic-ui-react";
import { COLORS } from "component/UI/common";

interface ISeerButton extends ButtonProps {
  category: "primary" | "secondary" | "tertiary";
  icon?: SemanticICONS;
}

const PrimaryButton = styled(Button)`
  min-height: 40px !important;
  &.ui.button {
    color: #ffffff;
    background: ${COLORS.red500} !important;
    font-size: 1rem;
    font-family: "Open Sans" !important;
  }
  &.ui.button:hover {
    background: ${COLORS.red600} !important;
  }
  &.ui.button > .icon:not(.button) {
    opacity: 1;
    font-size: 1rem;
  }
`;

const SecondaryButton = styled(Button)`
  min-height: 40px !important;
  &.ui.basic.button {
    color: ${COLORS.red500} !important;
    box-shadow: none;
    font-weight: 700;
    font-size: 1rem;
    font-family: "Open Sans" !important;
  }
  &.ui.button {
    border: 1px solid ${COLORS.red500};
  }
  &.ui.button:hover {
    background: ${COLORS.red100} !important;
  }
  &.ui.button > .icon:not(.button) {
    opacity: 1;
    font-size: 1rem;
  }
`;

const TertiaryButton = styled(SecondaryButton)`
  &.ui.basic.button {
    color: ${COLORS.red500} !important;
  }
  &.ui.button {
    border: none;
  }
  &.ui.button:hover {
    background: ${COLORS.red100} !important;
  }
`;

export const SeerButton = (props: ISeerButton): JSX.Element => {
  const { label, icon, category, size = "small", ...rest } = props;
  const ButtonComponent = category === "primary" ? PrimaryButton : category === "secondary" ? SecondaryButton : TertiaryButton;
  return (
    <ButtonComponent size={size} basic={category === "primary" ? false : true} {...rest}>
      {label}
      {icon && <Icon name={icon} className={`${label ? "ml-2" : "ml-0"} mr-0`} />}
    </ButtonComponent>
  );
};
