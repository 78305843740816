import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { database } from "common/api";
import Store from "common/store";
import { SearchWrapper, StyledSearch, StyledSearchDropdown } from "./Components";

interface Props {
  store?: Store;
  onSelect: (selected) => void;
}

export type SearchType = "users" | "groups" | "datasets";

const DROPDOWN_OPTIONS = [
  { key: "user", text: "Users", value: "users" },
  { key: "groups", text: "Organisations", value: "groups" },
  { key: "datasets", text: "Datasets", value: "datasets" },
];

const resultRenderer = (type: SearchType) => (props): JSX.Element => {
  const { first_name, last_name, name } = props;
  return (
    <p className="my-0">{type === "users" ? `${first_name} ${last_name}` : name}</p>
  );
};

const Component = (props: Props): JSX.Element => {
  const { store, onSelect } = props;
  const [loading, setLoading] = React.useState(false);
  const [searchType, setSearchType] = React.useState<SearchType>("users");
  const [searchValue, setSearchValue] = React.useState("");
  const [results, setResults] = React.useState<any>(null);

  const timeoutRef: any = React.useRef();

  const handleSearchChange = (_, data) => {
    const value = data.value;
    setResults(null);
    setSearchValue(value);
    if (value.trim().length <= 1) { // Start searching when there are more than 1 character in search field
      return;
    }
    setLoading(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      const res: any = await database.post(`platform-admin/${searchType}`, { keyword: value.trim() }, store!.token!);
      let results: any[] = [];
      if (res?.body?.data[searchType]) {
        results = [...res.body.data[searchType]];
      }
      setLoading(false);
      setResults(results);
    }, 800);
  };

  return (
    <SearchWrapper>
      <StyledSearch
        loading={loading}
        value={searchValue}
        results={results}
        onResultSelect={(_, { result }) => onSelect({ type: searchType, ...result })}
        onSearchChange={handleSearchChange}
        resultRenderer={resultRenderer(searchType)}
        showNoResults={loading ? false : true}
        minCharacters={2}
        size="mini"
        aligned="left"
        placeholder={"Type to search..."}
        input={{ icon: "search", iconPosition: "left" }}
      />
      <StyledSearchDropdown
        value={searchType}
        options={DROPDOWN_OPTIONS}
        direction="left"
        onChange={(_, { value }) => {
          setSearchType(value);
          setResults(null);
          setSearchValue("");
        }}
      />
    </SearchWrapper>
  );
};

export const AdminSearch = inject("store")(observer(Component));
