import * as React from "react";
import styled from "styled-components";
import { UnlayerConfig, Spacing, ItemsStyle } from "./helpers";

const Container = styled.nav`
  &.layout {
    ol {
      padding: 0;
      margin: 0;
      display: flex;
      width: 100%;
    }

    &.vertical {
      ol {
        flex-direction: column;
        &.left {
          align-items: start;
        }
        &.center {
          align-items: center;
        }
        &.right {
          align-items: end;
        }
        &.flex.between {
          align-items: center;
        }
      }
    }
    &.horizontal {
      ol {
        align-items: center;
        &.left {
          justify-content: left;
        }
        &.center {
          justify-content: center;
        }
        &.right {
          justify-content: right;
        }
        &.flex.between {
          justify-content: space-between;
        }
      }
    }

    li {
      display: block;
      img.hover {
        display: none;
      }
      &:hover,
      &:active,
      &:focus {
        img.hide-hover {
          display: none;
        }
        img.hover {
          display: inline;
        }
      }
    }
  }
`;

const NavButton = styled.button<{ $default: ItemsStyle; $hover: ItemsStyle }>`
  border: ${({ $default }) =>
    $default.useBorder ? `${$default.borderWidth || 0}px solid ${$default.borderColor || "transparent"}` : "none"};
  border-radius: ${({ $default }) => ($default.useBorder ? $default.borderRadius || 0 : 0)}px;
  color: ${({ $default }) => $default.color || "inherit"};
  background: ${({ $default }) => $default.background || "inherit"};
  &:hover,
  &.active,
  &:focus {
    ${({ $hover }) =>
      $hover.useBorder &&
      `
      border: ${$hover.borderWidth || 0}px solid ${$hover.borderColor || "transparent"};
      border-radius: ${$hover.borderRadius || 0}px;
    `}
    ${({ $hover }) =>
      $hover.background &&
      `
      background: ${$hover.background};
    `}
    ${({ $hover }) =>
      $hover.color &&
      `
      color: ${$hover.color};
    `}
  }
`;

const getSpacingString = (spacing: Spacing) =>
  typeof spacing === "number" ? spacing : `${spacing.top}px ${spacing.right}px ${spacing.bottom}px ${spacing.left}px`;

interface Props {
  setPageIdx: (idx: number) => void;
  pageIdx: number;
  unlayer_config: UnlayerConfig;
  embedded: boolean;
}

export const NavCustom = (props: Props) => {
  const { setPageIdx, pageIdx, unlayer_config, embedded } = props;
  const { titles, includedPages, customNavConf } = unlayer_config.navigation!;
  const { position, styling } = customNavConf;
  const includedPageIdxs: number[] = includedPages.map((showPg, idx) => (showPg ? idx : -1)).filter((idx) => idx !== -1);
  const imagesActive = styling.images.some((img) => !!img.default);
  const fontSrc = !imagesActive && styling.items.font.url !== "none" && styling.items.font.url;
  return (
    <>
      {fontSrc && <link rel="stylesheet" href={fontSrc} />}
      <Container
        aria-label="Dashboard pages"
        className={`layout ${styling.layout}`}
        style={{
          position,
          zIndex: 1,
          top: position !== "sticky" || embedded ? 0 : 55, // clear platform header bar for sticky
          background: styling.background,
          padding: getSpacingString(styling.padding),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: styling.maxWidth ? `${styling.maxWidth}${styling.maxWidthType}` : "100%" }}>
          <ol className={styling.alignment}>
            {includedPageIdxs.map((idx) => {
              const active = idx === pageIdx;
              return (
                <li key={`idx-${titles[idx]}`}>
                  {imagesActive ? (
                    <>
                      <img
                        className={`default ${styling.images[idx].hover ? "hide-hover" : ""}`}
                        src={active && styling.images[idx].hover ? styling.images[idx].hover : styling.images[idx].default}
                        alt={`Link to page: ${titles[idx]}`}
                        title={`Link to page: ${titles[idx]}`}
                        style={{
                          padding: getSpacingString(styling.items.padding),
                          margin: getSpacingString(styling.items.margin),
                          cursor: "pointer",
                          width: styling.imageWidthPercent ? `calc(${styling.imageWidthPercent}vw - 12px)` : undefined,
                          maxWidth: styling.imageWidthMax || undefined,
                          height: styling.imageWidthPercent ? "auto" : undefined,
                        }}
                        width={styling.imageWidth || undefined}
                        height={styling.imageHeight || undefined}
                        onClick={() => setPageIdx(idx)}
                        onKeyDown={(e) => {
                          if (["Enter", " "].includes(e.key)) {
                            setPageIdx(idx);
                          }
                        }}
                        aria-current={active ? "page" : undefined}
                        role="button"
                      />
                      {styling.images[idx].hover && (
                        <img
                          className="hover"
                          src={styling.images[idx].hover}
                          alt={`Link to page: ${titles[idx]}`}
                          title={`Link to page: ${titles[idx]}`}
                          style={{
                            padding: getSpacingString(styling.items.padding),
                            margin: getSpacingString(styling.items.margin),
                            cursor: "pointer",
                            width: styling.imageWidthPercent ? `calc(${styling.imageWidthPercent}vw - 12px)` : undefined,
                            maxWidth: styling.imageWidthMax || undefined,
                            height: styling.imageWidthPercent ? "auto" : undefined,
                          }}
                          width={styling.imageWidth || undefined}
                          height={styling.imageHeight || undefined}
                          onClick={() => setPageIdx(idx)}
                          onKeyDown={(e) => {
                            if (["Enter", " "].includes(e.key)) {
                              setPageIdx(idx);
                            }
                          }}
                          aria-current={active ? "page" : undefined}
                          role="button"
                        />
                      )}
                    </>
                  ) : (
                    <NavButton
                      style={{
                        fontWeight: active ? Math.min(styling.items.font.fontWeight + 300, 1000) : styling.items.font.fontWeight,
                        padding: getSpacingString(styling.items.padding),
                        margin: getSpacingString(styling.items.margin),
                        fontFamily: `"${styling.items.font.value}"`,
                        fontSize: styling.items.font.fontSize,
                      }}
                      $default={styling.items.default}
                      $hover={styling.items.hover}
                      className={active ? "active" : ""}
                      onClick={() => setPageIdx(idx)}
                      aria-current={active ? "page" : undefined}
                    >
                      {titles[idx]}
                    </NavButton>
                  )}
                </li>
              );
            })}
          </ol>
        </div>
      </Container>
    </>
  );
};
