import { COLORS } from "component/UI/common";

const transparentPurple = "rgba(205, 146, 205, 0.2)";

export const dashboardUnlayerCustomCSS = `
  .btn-primary.btn.btn-sm {
    background-color: ${COLORS.indigo600} !important;
    color: #fff;
    border: none;
    padding: 8px 10px;
    cursor: pointer;
    border-radius: 4px;
  }

  .blockbuilder-layer {
    --accent-color: ${COLORS.indigo600} !important;
  }

  .blockbuilder-placeholder .blockbuilder-placeholder-empty {
    outline: ${COLORS.indigo600} dashed 1px !important;
    color: ${COLORS.indigo600} !important;
    background-color: ${transparentPurple} !important;
  }

  .blockbuilder-placeholder a, .blockbuilder-placeholder a:hover {
    background-color: ${COLORS.indigo600} !important;
  }

  .blockbuilder-placeholder {
    --accent-color: ${COLORS.indigo600} !important;
  }

  .blockbuilder-layer-controls .blockbuilder-layer-control:hover {
    --accent-color: ${COLORS.indigo600} !important;
  }

  .tabs a.active {
    border-bottom: 1px solid ${COLORS.red500} !important;
  }

  .rc-slider .rc-slider-track {
    background-color: ${COLORS.indigo600} !important;
  }

  .rc-slider .rc-slider-handle {
    border-color: ${COLORS.indigo600} !important;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: ${COLORS.indigo600} !important;
  }

  .react-toggle--checked .react-toggle-thumb {
    border-color: ${COLORS.indigo600} !important;
  }

  .toggle-switch:has(:checked) {
    background-color: ${COLORS.indigo600};
  }

  .toggle-switch::after {
    background-color: ${COLORS.indigo600};
  }

  .segmented-control-item.selected {
    background-color: ${COLORS.indigo600} !important;
    box-shadow: none;
  }

  .blockbuilder-option-buttons > button {
    background-color: ${COLORS.indigo600};
    border: none;
  }

  .u_content_video img {
    width: 100% !important;
    height: auto !important;
    max-width: 100% !important;
  }

  .blockbuilder-preview.editor-desktop.enable-row-layer-outer-space {
    padding-bottom: 80px;
    overflow-x: hidden;
  }

  .actions-container {
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    z-index: 121; // Show action container above right panel
  }
`;
