import { Modal as SemanticModal, ModalProps as SemanticModalProps } from "semantic-ui-react";
import styled from "styled-components";
import { COLORS } from "component/UI/common";

export const Modal = styled(SemanticModal).attrs((): SemanticModalProps => ({}))<SemanticModalProps>`
  &.ui.modal {
    .header {
      color: ${COLORS.indigo600};
    }
    .actions {
      background: #fff;
    }
  }
`;
