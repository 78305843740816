import * as React from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { PageContainer } from "./PageContainer";
import { getMixpanel } from "common/api";
import { Button, Header } from "semantic-ui-react";
import { DataCatalogByDataset } from "component/DataCatalog/DataCatalogByDataset";
import { DataCatalogByVariable } from "component/DataCatalog/DataCatalogByVariable";
import Protected from "component/protected";

interface DataCatalogProps {
  store: Store;
}

export const DataCatalog: React.FC<DataCatalogProps> = Protected(
  inject("store")(
    observer(({ store }: DataCatalogProps) => {
      const { builder } = store;
      const [groupBy, setGroupBy] = React.useState("dataset");

      useEffect(() => {
        set();
        getMixpanel(store).track("Page view", { Page: "Data Catalog" }); // only fire on mount
      }, []);

      const set = async () => {
        await builder.getAllDatasets();
        await builder.newInsight();
      };

      return (
        <PageContainer>
          <Helmet>
            <title>Data Catalog</title>
          </Helmet>
          <div>
            <Header as="h2" color="purple" className="mb-4">
              Seer Data Catalog
            </Header>

            <div className="d-inline-block">
              <span>See: </span>
              <Button.Group basic>
                <Button active={groupBy === "dataset"} onClick={() => setGroupBy("dataset")}>
                  Variables grouped by dataset
                </Button>
                <Button active={groupBy === "variable"} onClick={() => setGroupBy("variable")}>
                  Datasets grouped by variable
                </Button>
              </Button.Group>
            </div>

            {groupBy === "dataset" ? <DataCatalogByDataset /> : <DataCatalogByVariable />}
          </div>
        </PageContainer>
      );
    }),
  ),
);
