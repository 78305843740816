import * as React from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import Store from "../../common/store";
import { seerLogo, Nav, StyleNavContainer } from "./includes/header.style";
import { Dropdown } from "semantic-ui-react";

interface IHeaderContributor {
  store?: Store;
}

const Component = (props: IHeaderContributor): JSX.Element => {
  const store = props.store!;
  return (
    <StyleNavContainer>
      <Nav className="bg-secondary">
        {/* Left Container */}
        <div style={{ float: "left", height: "100%", display: "flex", alignItems: "center" }}>
          {/* Logo */}
          <Link to="/" style={{ padding: "0 20px 0 20px", verticalAlign: "middle" }} onClick={() => window.scrollTo(0, 0)}>
            <img src={seerLogo} alt="Seer Data logo" height="30px" width="30px" />
          </Link>
        </div>

        {/* Right Nav Items */}
        <div className="float-right h-100 d-none d-sm-flex align-items-center">
          <div className="mr-3">
            <Dropdown icon="bars" pointing="top right" className="d-flex align-items-center text-white">
              <Dropdown.Menu className="mt-2">
                <Dropdown.Header content="Contributor Menu" />
                <Dropdown.Divider />
                <Dropdown.Item text="About Seer Data" as="a" href="https://seerdata.ai" target="_blank" />
                {store.contributor_token ? (
                  <Dropdown.Item text="Sign Out" icon="sign out alternate" onClick={() => store.logOutContributor()} />
                ) : (
                  <Dropdown.Item text="You are not signed in" disabled />
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Nav>
    </StyleNavContainer>
  );
};

export const HeaderContributor = inject("store")(observer(Component));
