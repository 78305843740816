import styled from "styled-components";
import { Button as SemanticButton, ButtonProps as SemanticButtonProps } from "semantic-ui-react";
import { COLORS } from "component/UI/common";

interface ColorConfig {
  fg: string;
  bg: string;
  iconBg?: string; // CTA buttons only
  hover?: {
    fg?: string;
    bg?: string;
  };
}

const BUTTON_COLORS: Record<string, ColorConfig> = {
  disabled: {
    fg: COLORS.grey400,
    bg: "#FFF",
  },
  // CTA & Inverted disabled style
  disabledAlt: {
    fg: "#FFF",
    bg: COLORS.grey400,
    iconBg: COLORS.grey400,
  },
  purple: {
    fg: COLORS.grey700,
    bg: "#FFF",
    hover: {
      bg: COLORS.indigo200,
      fg: COLORS.grey700,
    },
  },
  aqua: {
    fg: COLORS.aqua600,
    bg: "#FFF",
    hover: {
      fg: COLORS.aqua600,
      bg: COLORS.aqua200,
    },
  },
  red: {
    fg: COLORS.red500,
    bg: "#FFF",
    hover: {
      fg: COLORS.red600,
      bg: COLORS.red100,
    },
  },
  purpleCTA: {
    fg: COLORS.indigo600,
    bg: "#FFF",
    iconBg: COLORS.indigo200,
    hover: {
      fg: "#FFF",
      bg: COLORS.indigo600,
    },
  },
  aquaCTA: {
    fg: COLORS.aqua600,
    bg: "#FFF",
    iconBg: COLORS.aqua200,
    hover: {
      fg: "#FFF",
      bg: COLORS.aqua600,
    },
  },
  redCTA: {
    fg: COLORS.red500,
    bg: "#FFF",
    iconBg: COLORS.red100,
    hover: {
      fg: "#FFF",
      bg: COLORS.red500,
    },
  },
  purpleInverted: {
    fg: "#FFF",
    bg: COLORS.indigo500,
    hover: {
      fg: "#FFF",
      bg: COLORS.indigo600,
    },
  },
  aquaInverted: {
    fg: "#FFF",
    bg: COLORS.aqua500,
    hover: {
      fg: "#FFF",
      bg: COLORS.aqua600,
    },
  },
  redInverted: {
    fg: "#FFF",
    bg: COLORS.red500,
    hover: {
      fg: "#FFF",
      bg: COLORS.red600,
    },
  },
};

interface ButtonProps extends SemanticButtonProps {
  colorConfig?: string;
}

const getColorConfig = (props: ButtonProps) => {
  if (props.disabled) {
    return props.inverted || (props.icon && props.labelPosition) ? BUTTON_COLORS.disabledAlt : BUTTON_COLORS.disabled;
  }
  let seerColor = props.colorConfig || "purple";
  if (!props.colorConfig && props.icon && props.labelPosition) {
    seerColor = "purpleCTA"; // alt default style for CTA
  }
  if (!props.colorConfig && props.inverted) {
    seerColor = "purpleInverted"; // alt default style for inverted
  }
  return BUTTON_COLORS[seerColor];
};

export const Button = styled(SemanticButton).attrs((props: ButtonProps) => {
  const { basic } = props;
  return {
    basic: typeof basic === "undefined" ? true : basic, // basic style is our default unless overridden
  };
})<ButtonProps>`
  &.ui.basic.button,
  &.ui.basic.buttons .button {
    box-shadow: none !important;
    font-weight: bold;
    background: ${(props) => getColorConfig(props).bg} !important;
    color: ${(props) => getColorConfig(props).fg} !important;
    padding: 8px;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-size: 14px;
    height: 36px;

    &:hover {
      box-shadow: none !important;
      background: ${(props) => getColorConfig(props).hover?.bg || getColorConfig(props).bg} !important;
      color: ${(props) => getColorConfig(props).hover?.fg || getColorConfig(props).fg} !important;
    }

    &:disabled {
      background: ${(props) => getColorConfig(props).bg} !important;
      color: ${(props) => getColorConfig(props).fg} !important;
      opacity: 1 !important;
    }

    &.active {
      background: ${(props) => getColorConfig(props).hover?.bg || getColorConfig(props).bg} !important;
    }

    // overrides for CTA (semantic labeled icon) button
    &.labeled.icon,
    &.labeled.icon.buttons .button {
      transition:
        opacity 0.1s ease,
        background-color 0.1s ease,
        color 0.1s ease,
        box-shadow 0.1s ease,
        background 0.1s ease,
        -webkit-box-shadow 0.1s ease;
      height: 40px;
      min-width: 220px;
      text-transform: uppercase;
      .icon {
        transition:
          opacity 0.1s ease,
          background-color 0.1s ease,
          color 0.1s ease,
          box-shadow 0.1s ease,
          background 0.1s ease,
          -webkit-box-shadow 0.1s ease;
        background: ${(props) => getColorConfig(props).iconBg};
        opacity: 1;
      }
      &:not(:disabled) {
        border: 1px solid ${(props) => getColorConfig(props).fg};
        &.left .icon {
          border-right: 1px solid ${(props) => getColorConfig(props).fg};
        }
        &.right .icon {
          border-left: 1px solid ${(props) => getColorConfig(props).fg};
        }
      }

      &:hover {
        background: ${(props) => getColorConfig(props).hover?.bg} !important;
        color: ${(props) => getColorConfig(props).hover?.fg} !important;

        .icon {
          border-right: none !important;
          border-left: none !important;
          color: ${(props) => getColorConfig(props).hover?.fg} !important;
          background: ${(props) => getColorConfig(props).hover?.bg} !important;
        }
      }
    }
  }

  i.icon {
    font-size: 16px;
    opacity: 1 !important;
  }
`;
