import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Builder } from "common/store/builder";
import {
  CHART_FONT_FAMILY,
  CHART_PRIMARY_COLOR,
  generateMetricLegendItems,
  getFilteredChartTables,
  ifShowTableNames,
} from "common/helpers/chart";
import { Grid } from "semantic-ui-react";
import { getWrapperComponent, LegendLayout } from "./includes/LegendComponents";
import { withColorPicker } from "./withColorPicker";
import { ObjectAny } from "common/helpers/types";

interface IMetricChartLegend {
  builder?: Builder;
  edit?: boolean;
  showGridLayout?: boolean;
  colorSquareClickHandler: (tableID: number, colorIdxInTableColors: number, color: string, colorIdxInAllColors: number) => void;
  hideTableNames?: boolean;
  condensedLegend?: boolean; // modulates render to suit embedded insights
}

const MetricChartLegendComponent = (props: IMetricChartLegend): JSX.Element => {
  const { builder, edit, showGridLayout, colorSquareClickHandler, condensedLegend, hideTableNames } = props;
  const { chartTables, columns, chartColors, chartXAxisArray, chartSeries } = builder!;
  const filteredChartTables = getFilteredChartTables(chartTables, columns, chartXAxisArray, chartSeries);
  const copiedChartColors = [...chartColors];
  const legendItems = generateMetricLegendItems(filteredChartTables as ObjectAny[], columns);
  const WrapperComponent = getWrapperComponent(showGridLayout);

  return (
    <div className={`${condensedLegend ? "" : "mb-5"}`}>
      {ifShowTableNames(filteredChartTables) ? (
        // Split legend items across separate lines
        filteredChartTables.map((chartTable, idx) => {
          const gridColumns: any = Math.min(legendItems[idx].length, 4);
          const currentTableColors = copiedChartColors.splice(0, legendItems[idx].length);
          return (
            <div key={idx}>
              {ifShowTableNames([chartTable]) && !hideTableNames && (
                <p
                  className={`${condensedLegend ? "mb-1" : "mb-2"} fs-1000 fw-700`}
                  style={{ color: CHART_PRIMARY_COLOR, fontFamily: CHART_FONT_FAMILY }}
                >
                  {chartTable.name}
                </p>
              )}
              <Grid className={`${condensedLegend ? "" : "mb-4"}`} columns={gridColumns} padded doubling>
                <LegendLayout
                  legendItems={legendItems[idx]}
                  table={chartTable}
                  edit={edit}
                  colors={currentTableColors}
                  colorSquareClickHandler={colorSquareClickHandler}
                  Wrapper={WrapperComponent}
                  prevTablesColorsLength={legendItems.slice(0, idx).reduce((prev, curr) => prev + curr.length, 0)}
                />
              </Grid>
            </div>
          );
        })
      ) : (
        // Show legend items next to each other in a grid
        <Grid
          className="mb-4"
          columns={
            Math.min(
              legendItems.reduce((currentCount, row) => currentCount + row.length, 0),
              4,
            ) as any
          }
          padded
          doubling
        >
          {filteredChartTables.map((chartTable, idx) => {
            const currentTableColors = copiedChartColors.splice(0, legendItems[idx].length);
            return (
              <LegendLayout
                legendItems={legendItems[idx]}
                table={chartTable}
                edit={edit}
                colors={currentTableColors}
                colorSquareClickHandler={colorSquareClickHandler}
                Wrapper={WrapperComponent}
                prevTablesColorsLength={legendItems.slice(0, idx).reduce((prev, curr) => prev + curr.length, 0)}
              />
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export const MetricChartLegend = withColorPicker(
  inject((stores: any) => ({
    builder: stores.store.builder,
  }))(observer(MetricChartLegendComponent)),
);
