import * as React from "react";
import { useParams } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Icon, Message, Container, Form, Accordion, Segment, Dimmer, Loader, Placeholder, Sidebar, Menu } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import { Field, Form as FinalForm } from "react-final-form";
import Store from "common/store";
import { FieldInput } from "pages/DatasetTemplateTable/includes/FieldInput";
import { composeValidators, required } from "common/helpers/finalForm";
import { TemplateTable } from "pages/DatasetTemplateTableDataModerator/includes/TemplateTable";
import { compileTableCompleteness } from "common/helpers/dataset";
import { ObjectAny } from "common/helpers/types";
import { TableCompletenessChart } from "./includes/TableCompletenessChart";
import { Button } from "component/UI/Button";
import { getMixpanel } from "common/api";

interface IContainer {
  store: Store;
}

const Component = (props: IContainer): JSX.Element => {
  const [loginStep, setLoginStep] = React.useState<number>(1);
  const [templateData, setTemplateData] = React.useState<any>(null);
  const [tableData, setTableData] = React.useState<any>(null);
  const [activeIndex, setActiveIndex] = React.useState<null | number>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [sort, setSort] = React.useState<"default" | "alpha">("default");
  const [hideCompleted, setHideCompleted] = React.useState<boolean>(false);
  const [hideOptional, setHideOptional] = React.useState<boolean>(false);
  const [showContents, setShowContents] = React.useState<boolean>(false);
  const [tableComponentData, setTableComponentData] = React.useState<any>({});
  const { store } = props;
  const { contributor_token } = store!;

  const { templateID }: any = useParams();

  // login / email check handler
  const onSubmit = async (values: any) => {
    if (loginStep === 1) {
      const ok = await store!.dataset.contributorEmailCheck(values?.email?.trim());
      if (ok) {
        return setLoginStep(2);
      }
      return { email: "Email invalid or not found. Please check the entered email." };
    } else {
      const ok = await store!.dataset.contributorLogin(values?.email?.trim(), values?.verification_code);
      if (!ok) {
        return { verification_code: "Please check the log in code has been entered correctly." };
      }
      // do nothing - login success will trigger rerender with template flow
    }
  };

  // load template data
  const loadTemplateData = async () => {
    const nextData: any = await store.dataset.contributorDatasetTemplateGet(+templateID);
    setTemplateData(nextData);
  };

  React.useEffect(() => {
    if (contributor_token && !templateData) {
      loadTemplateData();
      store.dataset.getDatasets();
    }
  }, [contributor_token]);

  React.useEffect(() => {
    if (templateData) {
      getMixpanel(store).track("Page View", {
        Page: "Dataset Contributor Whole Template",
        "Template Id": templateID,
        "Template Name": templateData.template.name || "",
      });
    }
  }, [templateData]);

  if (!contributor_token) {
    return (
      <Container style={{ width: 320 }}>
        <Helmet>
          <title>Contributor Log In</title>
        </Helmet>
        <h3 className="text-secondary">Contributor Log in</h3>
        <FinalForm
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} id="login-ssdc-contributor">
              <Field name="email" label="Email" component={FieldInput} validate={composeValidators(required)} disabled={loginStep === 2} />
              {loginStep === 2 ? (
                <>
                  <Field name="verification_code" label="Log in code" component={FieldInput} validate={composeValidators(required)} />
                  <p className="fs-0875 fw-bold">We just sent you a temporary log in code. Please check your inbox.</p>
                </>
              ) : (
                <p className="fs-0875 fw-bold">We’ll email you a temporary log in code to log in.</p>
              )}
              <Button type="submit" colorConfig="redInverted" fluid>
                {loginStep === 1 ? "Continue" : "Log in"}
                <Icon className="ml-2" name={loginStep === 1 ? "magic" : "sign-in alternate"} style={{ opacity: 1 }} />
              </Button>
            </Form>
          )}
        />
      </Container>
    );
  }

  const loadTableData = async (id: number) => {
    const { dataset_template_table } = await store.dataset.contributorDatasetTemplateTableGet(id);
    return dataset_template_table;
  };

  const onGoToTableContent = async (id: number, e?: any) => {
    if (activeIndex && tableComponentData.hasChanges) {
      const ok = window.confirm("Warning. You will lose any unsaved data changes to the table currently being edited.");
      if (!ok) {
        e && e.preventDefault();
        return;
      }
    }
    getMixpanel(store).track("Dataset Contributor Go to Table", {
      "Template Id": templateID,
      "Template Name": templateData.template.name || "",
      "Table Id": id,
      "Table Name": templateData.template.tables.find((table) => table.id === id)?.name,
    });
    setShowContents(false);
    setLoading(true);
    setActiveIndex(activeIndex === id ? null : id);
    const nextData = await loadTableData(id);
    setTableData(nextData || null);
    setLoading(false);
  };

  const handleSaveTable = async (json: any) => {
    setLoading(true);
    const completeness = compileTableCompleteness(json as ObjectAny[]);
    const success: any = await store.dataset.contributorDatasetTemplateTableUpdate(activeIndex!, {
      data: JSON.stringify(json),
      completeness,
    });
    getMixpanel(store!).track("Dataset Contributor Update Data Whole Template", {
      "Template Id": templateID,
      "Table Id": activeIndex,
      "Table Name": tableData.name,
    });
    if (!success) {
      alert("Failed to save data. Try again later.");
    } else {
      const nextData = await loadTableData(activeIndex!);
      if (nextData) {
        setTableData(nextData);
      }
      await loadTemplateData();
    }
    setLoading(false);
  };

  const { error, template } = templateData || {};
  const tables = template?.tables;
  const sortedTables =
    tables && (sort === "default" ? [...tables].sort((a, b) => a.id - b.id) : [...tables].sort((a, b) => a.name.localeCompare(b.name)));
  const visibleTables =
    sortedTables &&
    sortedTables.filter((table) => {
      if (hideCompleted && table.completeness === 1) {
        return activeIndex === table.id; // we don't want to hide tables being actively edited until they are closed
      } else if (hideOptional && table.name.includes("(Optional)")) {
        return false;
      }
      return true;
    });
  const showHideOptionalButton = tables && tables.some((table) => table.name.includes("(Optional)"));
  const dataset =
    template &&
    store.dataset.datasets &&
    store.dataset.datasets.find((dataset) => dataset.templates.some((template) => template.id === +templateID));

  return (
    <Container>
      <Helmet>
        <title>Dataset Contributor</title>
      </Helmet>
      {error && <Message error content={error} />}
      {!error && template && (
        <>
          <h1 className="text-secondary">Dataset Contribution</h1>
          {dataset && <h4>{dataset.name}</h4>}
          <h5>{template.name}</h5>
          <div className="d-flex mb-2">
            <Button disabled={activeIndex} onClick={() => setSort(sort === "default" ? "alpha" : "default")} className="mr-2">
              Sort: {sort === "default" ? "Default" : "Alphanumeric"}
            </Button>
            <Button disabled={activeIndex} onClick={() => setHideCompleted(!hideCompleted)} className="mr-2">
              {hideCompleted ? "Show" : "Hide"} Completed
            </Button>
            {showHideOptionalButton && (
              <Button disabled={activeIndex} onClick={() => setHideOptional(!hideOptional)} className="mr-2">
                {hideOptional ? "Show" : "Hide"} Optional
              </Button>
            )}
          </div>
          {!!activeIndex && <p className="text-muted fs-1000">Filters can not be modified while a table is opened for editing.</p>}
          <Accordion styled fluid className="mt-4">
            {visibleTables?.map((table: any) => (
              <React.Fragment key={table.id}>
                <Accordion.Title
                  active={activeIndex === table.id}
                  index={table.id}
                  onClick={(e) => onGoToTableContent(table.id, e)}
                  id={`table${table.id}`}
                  style={{ scrollMargin: 55 }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div>{table.name}</div>
                    <div style={{ width: 60, fontSize: "0.875rem" }}>
                      <TableCompletenessChart completeness={table.completeness} />
                    </div>
                  </div>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === table.id}>
                  {loading ? (
                    <Segment>
                      <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                      </Dimmer>
                      <Placeholder>
                        <Placeholder.Paragraph>
                          <Placeholder.Line />
                          <Placeholder.Line />
                          <Placeholder.Line />
                          <Placeholder.Line />
                        </Placeholder.Paragraph>
                      </Placeholder>
                    </Segment>
                  ) : (
                    <>
                      {tableData ? (
                        <TemplateTable
                          schema={tableData.schema}
                          rawData={tableData.data || null}
                          handleSave={handleSaveTable}
                          handleCancel={() => onGoToTableContent(table.id as number)}
                          componentDataSubscriber={(data) => setTableComponentData(data)}
                        />
                      ) : (
                        <p>Could not load table</p>
                      )}
                    </>
                  )}
                </Accordion.Content>
              </React.Fragment>
            ))}
          </Accordion>
          <Sidebar
            as={Menu}
            animation="overlay"
            onHide={() => setShowContents(false)}
            inverted
            vertical
            visible={showContents}
            width="very wide"
            s
            style={{ zIndex: 1000 }}
            className="p-4"
          >
            <div className="d-flex align-item-center justify-content-between">
              <h5 className="text-white mb-0">Contents</h5>
              <div
                className="d-flex align-items-center justify-content-center cursor-pointer"
                style={{ height: 30, width: 30 }}
                onClick={() => setShowContents(false)}
              >
                <Icon name="close" className="text-white" />
              </div>
            </div>
            <div className="mb-5">
              {visibleTables?.map((table: any) => (
                <p key={table.id} className="m-0 mb-2">
                  <a
                    href={`#table${table.id}`}
                    onClick={(e) => (table.id === activeIndex ? null : onGoToTableContent(table.id, e))}
                    style={{ fontWeight: table.id === activeIndex ? "bold" : "initial" }}
                    className={`${table.id === activeIndex ? "text-medium" : "text-white"}`}
                  >
                    {table.name}
                    {table.id === activeIndex ? " (Editing)" : ""}
                  </a>
                </p>
              ))}
            </div>
          </Sidebar>
          <div
            className="bg-white d-flex align-items-center justify-content-center cursor-pointer position-fixed shadow-lg"
            style={{ height: 60, width: 60, borderRadius: "50%", left: 20, bottom: 20 }}
            onClick={() => setShowContents(true)}
          >
            <Icon name="list alternate outline" className="mr-0" />
          </div>
        </>
      )}
    </Container>
  );
};

export const ContributorDatasetWholeTemplate = inject("store")(observer(Component));
