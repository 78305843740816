import styled from "styled-components";
import { $border, $white } from "../../../common/styledComponents/_config.style";
import { COLORS } from "component/UI/common";

export const StyleUploadButton = styled.button`
  width: 160px;
  height: 40px;
  font-family: "Open Sans";

  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  outline: none;
  color: ${COLORS.red500};
  display: inline-block;
  cursor: pointer;
  margin: 15px 0 0 0;
  border: ${$border};
  background-color: ${$white};
`;
