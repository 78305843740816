import * as React from "react";
import { Header } from "semantic-ui-react";

interface IExploreCard {
  iconUrl: string;
  title: string;
  handleOnClick: () => void;
  id?: string;
}

export const ExploreCard = ({ iconUrl, title, handleOnClick, id }: IExploreCard): JSX.Element => (
  <div
    id={id || undefined}
    className="bg-white d-flex flex-column align-items-center justify-content-center rounded-3 shadow-md cursor-pointer"
    style={{ height: "300px", width: "300px" }}
    onClick={handleOnClick}
  >
    <img src={iconUrl} style={{ height: "140px", width: "160px" }} />
    <Header as="h4" color="purple">
      {title}
    </Header>
  </div>
);
