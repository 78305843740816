import * as React from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { composeValidators, mustBeNumber, required } from "common/helpers/finalForm";
import { Form, Icon } from "semantic-ui-react";
import { FieldInput } from "component/FinalFormFields/FieldInput";
import { Button } from "component/UI/Button";

interface Props {
  onSubmit: (values) => void;
}

export const DatasetRefresh = (props: Props): JSX.Element => {
  const { onSubmit } = props;
  return (
    <FinalForm
      onSubmit={onSubmit}
      render={(formRenderProps) => {
        const { handleSubmit } = formRenderProps;
        return (
          <Form onSubmit={handleSubmit}>
            <h2 className="text-secondary mb-5">Trigger Dataset refresh</h2>
            <Field
              name="dataset_id"
              label="Dataset ID"
              component={FieldInput}
              validate={composeValidators(required, mustBeNumber)}
              className="mb-5"
            />
            <Button colorConfig="purpleInverted">Save<Icon name="save" className="ml-2" /></Button>
          </Form>
        );
      }}
    />
  );
};
