import * as React from "react";
import { DateTime } from "luxon";
import { Avatar } from "component/Avatar";
import { AvatarGroup } from "component/AvatarGroup";
import { IUser } from "common/store";

interface MetaInfoBarProps {
  updated: string;
  roleLabel: "OWNER" | "CREATOR";
  custodian: Partial<IUser>;
  usersSharedWith?: Partial<IUser>[];
}

export const MetaInfoBar = (props: MetaInfoBarProps): JSX.Element => {
  const { updated, roleLabel, custodian, usersSharedWith } = props;

  return (
    <div className="d-md-flex align-items-md-center mt-4 mb-3">
      <div className="d-flex align-items-center text-nowrap mr-5 mb-1">
        <span className="text-secondary fw-700 fs-1000 mr-2">UPDATED</span>
        <span className="text-dark fs-1000">{DateTime.fromISO(updated).setZone("local").toFormat("h:mm a DDD")}</span>
      </div>
      <div className={`d-flex align-items-center text-nowrap mb-1 ${usersSharedWith ? "mr-5" : ""}`}>
        <span className="text-secondary fw-700 fs-1000 mr-2">{roleLabel}</span>
        <Avatar name={`${custodian.first_name} ${custodian.last_name}`} image={custodian.avatar || ""} size={30} />
      </div>
      {usersSharedWith && (
        <div className="d-flex align-items-center text-nowrap mb-1">
          <span className="text-secondary fw-700 fs-1000 mr-2">SHARED WITH</span>
          <AvatarGroup
            items={usersSharedWith.map((user) => ({ name: `${user.first_name} ${user.last_name}`, image: user.avatar || "" }))}
            size={30}
          />
        </div>
      )}
    </div>
  );
};
