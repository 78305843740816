import styled from "styled-components";
import { Container } from "../../common/styledComponents/elements";

export const CommentsContainer = styled.div`
  ${Container}
  padding: 0;
  border-radius: 4px;
  background-color: rgba(50, 46, 59, 0);
`;

export const AllComments = styled.div`
  margin: 20px 0 -10px 0;
`;

export const CommentContainer = styled.div`
  overflow: auto;
`;
export const InputContainer = styled.div`
  display: flex;
`;
