import * as React from "react";
import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { useWindowSize } from "@react-hook/window-size";

import { Suitcase } from "./pages/Suitcase/suitcaseOverviewPage";
import Suitcases from "./pages/suitcases";
import Insight from "./pages/Insight/insight";
import { Header as Nav } from "./component/Header/header";
import { HeaderContributor } from "component/Header/header_contributor";
import { Login } from "./pages/SignupLoginForms/Login";
import NotFound from "./pages/notfound";
import { Signup } from "./pages/SignupLoginForms/Signup";
import Notification from "./component/popup/notification";
import { Container, Header, Body, Main } from "./appContainer.style";
import { PageContainer } from "./pages/PageContainer";
import { NotificationFeedPage } from "./pages/NotificationFeedPage";
import { UnsubscribePage } from "./pages/UnsubscribePage";
import { SimilarityModel } from "pages/SimilarityModel";
import { AcceptInvite } from "pages/SignupLoginForms/AcceptInvite";
import Explore from "pages/Explore";
import NewExplore from "pages/NewExplore/NewExplore";
import { MapTest } from "pages/MapTest";
import { DatasetPage } from "pages/DatasetTemplates/DatasetPage";
import { DatasetTemplatePage } from "pages/DatasetTemplates/DatasetTemplatePage";
import { DataCatalog } from "pages/DataCatalog";
import { DatasetTemplateTable } from "pages/DatasetTemplateTable/DatasetTemplateTable";
import { Homepage } from "pages/Homepage/Homepage";
import { Datasets } from "pages/DatasetTemplates/Datasets";
import { DatasetTemplateTableDataModerator } from "pages/DatasetTemplateTableDataModerator/DatasetTemplateTableDataModerator";
import { InsightsRefreshStatus } from "pages/InsightsRefreshStatus";
import { Settings } from "pages/Settings/Settings";
import { Onboarding } from "pages/Onboarding/Onboarding";
import ErrorBoundary from "pages/ErrorBoundary";
import { PlatformGeneralError } from "pages/PlatformGeneralError";
import { ContributorDatasetTemplate } from "pages/ContributorDatasetTemplate/ContributorDatasetTemplate";
import { ContributorDatasetTemplateTable } from "pages/ContributorDatasetTemplateTable/ContributorDatasetTemplateTable";
import { ContributorDatasetWholeTemplate } from "pages/ContributorDatasetWholeTemplate/ContributorDatasetWholeTemplate";
import { EmbedInsight } from "pages/EmbedInsight/EmbedInsight";
import { Dashboard } from "pages/Dashboard/Dashboard";
import { Dashboards } from "pages/Dashboard/Dashboards";
import { SmartInsights } from "pages/SmartInsights/SmartInsights";
import { ComTasParks } from "pages/Maps/ComTasParks/ComTasParks";
import { ComTasServices } from "pages/Maps/ComTasServices/ComTasServices";
import { Footer } from "component/Footer";
import { EmbedDashboard } from "pages/EmbedDashboard/EmbedDashboard";
import { DatasetURLUpload } from "pages/DatasetURLUpload/DatasetURLUpload";
import { MapDataUpload } from "pages/MapDataUpload/MapDataUpload";
import { FeatureMap } from "pages/Maps/FeatureMap/FeatureMap";
import { RestackingDashboardECECGowrie } from "pages/CustomBuilds/RestackingDashboardECECGowrie";
import { RestackingANCDashboardMDHS } from "pages/CustomBuilds/RestackingANCDashboardMDHS";
import { DatasetURLView } from "pages/DatasetURLView/DatasetURLView";
import { IgwtLga2021 } from "pages/Maps/IgwtLga2021/IgwtLga2021";
import { BackupRestackingDashboardECECGowrie } from "pages/CustomBuilds/backups/BackupRestackingDashboardECECGowrie";
import { RestackingDashboardECECBDCS } from "pages/CustomBuilds/RestackingDashboardECECBDCS";
import { BackupRestackingANCDashboardMDHS } from "pages/CustomBuilds/backups/BackupRestackingANCDashboardMDHS";
import { RSTOInvite } from "pages/RSTOInvite/RSTOInvite";
import { RestackingANCDashboardHospitalA } from "pages/CustomBuilds/RestackingANCDashboardHospitalA";
import { insertScript } from "common/helpers/script";
import { hubspotScriptSrc } from "common/constants";
import { Collaborate } from "pages/Dashboard/includes/DashboardCollaborate/Collaborate";
import { DemoRestackingDashboardECEC } from "pages/CustomBuilds/DemoRestackingDashboardECEC";
import { RestackingDashboardECECByFive } from "pages/CustomBuilds/RestackingDashboardECECByFive";
import { RestackingSurvey } from "pages/CustomBuilds/RestackingSurvey";
import { EmbedCompletenessMeasure } from "pages/EmbedCompletenessMeasure/EmbedCompletenessMeasure";
import { CompletenessMeasureForm } from "pages/DatasetCompletenessMeasureForm/CompletenessMeasureForm";
import { RestackingDashboardECECGowrieSouth } from "pages/CustomBuilds/RestackingDashboardECECGowrieSouth";
import { SimpleLogoHeader } from "component/Header/LogoHeader";
import { DashboardFormSubmission } from "pages/DashboardFormSubmission/DashboardFormSubmission";
import { StartPlusTrialSubmission } from "pages/StartPlusTrialSubmission/StartPlusTrialSubmission";
import { DPACKCSReportUpload } from "pages/bespoke/dpac/kcs/DPACKCSReportUpload/DPACKCSReportUpload";
import { DPACKCSReportSearch } from "pages/bespoke/dpac/kcs/DPACKCSSearch/DPACKCSSearch";
import { DPACKCSSearchHelp } from "pages/bespoke/dpac/kcs/DPACKCSSearchHelp/DPACKCSSearchHelp";
import { ServiceUptimeReport } from "pages/ServiceUptimeReport/ServieUptimeReport";
import { AdminActions } from "pages/AdminActions/AdminActions";
import { ResetPassword } from "pages/ResetPassword/ResetPassword";

// app boot is resource intensive, some routes don't depend on this, temporary workaround while we clean up how we load the app
const skipBootRoutes = [
  "/embed/insight",
  "/embed/dataset-completeness-measure",
  "/bespoke/dpac/kcs-search",
  "/bespoke/dpac/kcs-search/help",
  "/embed/restackingbdcs-ks62ov",
  "/embed/restackingbyfive-xo7em",
  "/embed/restackinggowriesouth-ns96mj",
  "/embed/restackinggowrie-aq45st",
  "/embed/restackingmdhs-m3d4pn",
  "/embed/restackinggowrie-aq45st-backup",
  "/embed/restacking-anc-demo-sample",
  "/embed/restacking-ecec-demo",
];

export const AppContainer = inject("store")(
  observer((props: any) => {
    const store = props.store!;
    const location = useLocation();
    const isMapRoute = useRouteMatch("/map/:id")?.isExact === true;

    // update window width/height in ui store variables
    const size = useWindowSize();
    React.useEffect(() => {
      store.ui.setWindowSize(size);
    }, [size]);

    React.useEffect(() => {
      insertScript("hubspot", { type: "text/javascript", src: hubspotScriptSrc, id: "hs-script-loader", async: true, defer: true });
    }, []);

    if (location.pathname === "/embed/insight") {
      return (
        <Switch location={location}>
          <Route exact path="/embed/insight" component={EmbedInsight} />
        </Switch>
      );
    }

    if (location.pathname.includes("/embed/dashboards")) {
      return (
        <Switch location={location}>
          <Route exact path="/embed/dashboards/:dashboardID/:publicKey" component={EmbedDashboard} />
        </Switch>
      );
    }

    if (skipBootRoutes.includes(location.pathname) || isMapRoute) {
      return (
        <Container>
          <Body role="main">
            <main
              style={{
                flex: "2 0px",
                backgroundColor: "white",
                margin: "0 auto",
                maxWidth: "100%",
                padding: "90px 0 20px",
              }}
            >
              <Switch location={location}>
                <Route path="/bespoke/dpac/kcs-search" component={DPACKCSReportSearch} exact />
                <Route path="/bespoke/dpac/kcs-search/help" component={DPACKCSSearchHelp} exact />
                <Route exact path="/embed/dataset-completeness-measure" component={EmbedCompletenessMeasure} />
                <Route path="/map/cta-parks" exact component={ComTasParks} />
                <Route path="/map/cta-services" exact component={ComTasServices} />
                <Route path="/map/igwt-lga-2021" exact component={IgwtLga2021} />
                <Route path="/map/:id" exact component={FeatureMap} />
                <Route path="/embed/restacking-ecec-demo" component={DemoRestackingDashboardECEC} />
                <Route path="/embed/restackingbdcs-ks62ov" component={RestackingDashboardECECBDCS} />
                {/* "By Five" is replaced with "Wimmera Southern Mallee" in the component */}
                <Route path="/embed/restackingbyfive-xo7em" component={RestackingDashboardECECByFive} />
                <Route path="/embed/restackinggowriesouth-ns96mj" component={RestackingDashboardECECGowrieSouth} />
                <Route path="/embed/restackinggowrie-aq45st" component={RestackingDashboardECECGowrie} />
                <Route path="/embed/restackinggowrie-aq45st-backup" component={BackupRestackingDashboardECECGowrie} />
                <Route path="/embed/restackingmdhs-m3d4pn" component={RestackingANCDashboardMDHS} />
                <Route path="/embed/restackingmdhs-m3d4pn-backup" component={BackupRestackingANCDashboardMDHS} />
                <Route path="/embed/restacking-anc-demo-sample" component={RestackingANCDashboardHospitalA} />
              </Switch>
            </main>
          </Body>
        </Container>
      );
    }

    store.getGroupTypes();
    store.boot();

    return (
      <Container>
        <Header id="header" role="navigation">
          <Switch location={location}>
            <Route path="/analyst-portal" component={(props) => <Nav analystPortal={true} {...props} />} />
            <Route path="/embed" component={() => <></>} />
            <Route path="/welcome" component={SimpleLogoHeader} />
            <Route path="/login" component={SimpleLogoHeader} />
            <Route path="/signup" component={SimpleLogoHeader} />
            <Route path="/invite" component={SimpleLogoHeader} />
            <Route path="/onboarding/rsto" component={() => <></>} />
            <Route path="/dataset-contribution" component={HeaderContributor} />
            <Route path="/url-dataset-contribution" component={HeaderContributor} />
            <Route path="/dashboards/collaborate/:dashboardID" component={() => <></>} />
            <Nav />
          </Switch>
        </Header>
        <Body role="main">
          <Main>
            {store.ready && (
              <>
                <Switch location={location}>
                  <Route exact path="/" component={Homepage} />

                  <Route exact path="/welcome" component={Onboarding} />

                  <Route exact path="/error" component={PlatformGeneralError} />

                  <Route exact path="/builder/new">
                    <ErrorBoundary>
                      <NewExplore />
                    </ErrorBoundary>
                  </Route>
                  <Route path="/suitcases/:suitcaseId/builder/new" component={NewExplore} />
                  <Route path="/builder/updateInsight/:id" component={NewExplore} />

                  <Route path="/analyst-portal/query-viewer">
                    <NewExplore analystPortal />
                  </Route>

                  <Route exact path="/explore" component={Explore} />
                  <Route path="/suitcases/:suitcaseId/explore" component={Explore} />
                  <Route path="/explore/updateInsight/:id" component={Explore} />

                  <Route path="/datasets/:datasetID/templates/:templateID/tables/new" component={DatasetTemplateTable} exact />
                  <Route path="/datasets/:datasetID/templates/:templateID/tables/:tableID" component={DatasetTemplateTable} exact />
                  <Route
                    path="/datasets/:datasetID/templates/:templateID/tables/:tableID/data"
                    component={DatasetTemplateTableDataModerator}
                    exact
                  />
                  <Route path="/datasets/:datasetID/templates/:templateID" component={DatasetTemplatePage} exact />
                  <Route path="/datasets/:datasetID" component={DatasetPage} exact />
                  <Route path="/datasets/:datasetID/url-table-view" component={DatasetURLView} exact />
                  <Route path="/datasets" component={Datasets} exact />
                  <Route path="/dataset-contribution/:templateID" component={ContributorDatasetTemplate} exact />
                  <Route path="/dataset-contribution/:templateID/:tableID" component={ContributorDatasetTemplateTable} exact />
                  <Route path="/dataset-contribution-whole-template/:templateID" component={ContributorDatasetWholeTemplate} exact />
                  <Route path="/url-dataset-contribution/:id" component={DatasetURLUpload} exact />
                  <Route path="/datasets/:datasetID/completeness-measures/new" component={CompletenessMeasureForm} exact />
                  <Route path="/datasets/:datasetID/completeness-measures/:measureID" component={CompletenessMeasureForm} exact />

                  <Route path="/map/:id/upload" component={MapDataUpload} exact />

                  <Redirect from="/data-catalog" to="/catalog" />
                  <Route path="/catalog" component={DataCatalog} />

                  <Route path="/suitcases/:suitcaseId/:publicKey" exact component={Suitcase} />
                  <Route path="/suitcases/:suitcaseId" component={Suitcase} />
                  <Route path="/suitcases" component={Suitcases} />
                  <Route path="/insights/:id/:key" component={Insight} exact />
                  <Route path="/insights/:id">
                    <ErrorBoundary>
                      <PageContainer>
                        <Insight />
                      </PageContainer>
                    </ErrorBoundary>
                  </Route>

                  <Route path="/dashboards/collaborate/:dashboardID" component={Collaborate} exact />
                  <Route path="/dashboards/:dashboardID/:publicKey" component={Dashboard} exact />
                  <Route path="/dashboards/:dashboardID" component={Dashboard} exact />
                  <Route path="/dashboards" component={Dashboards} exact />
                  <Route path="/form-submission/dashboard/:dashboardID" component={DashboardFormSubmission} exact />

                  <Route path="/smart-insights" component={SmartInsights} exact />

                  <Route path="/login" component={Login} />
                  <Route path="/signup/rsto" component={RSTOInvite} />
                  <Route path="/signup" component={Signup} />
                  <Route path="/invite" component={AcceptInvite} />
                  <Route path="/settings/org/partners/:settingsItemID" component={Settings} />
                  <Route path="/settings/org/:settingsItemID" component={Settings} />
                  <Route path="/settings/:settingsItemID" component={Settings} />
                  <Route path="/password-reset" component={ResetPassword} />
                  <Route path="/start-plus-trial" component={StartPlusTrialSubmission} />
                  <Route path="/notifications">
                    <PageContainer>
                      <NotificationFeedPage />
                    </PageContainer>
                  </Route>
                  <Route path="/unsubscribe/:token">
                    {({ match }) => (
                      <PageContainer>
                        <UnsubscribePage token={match?.params.token} />
                      </PageContainer>
                    )}
                  </Route>
                  <Route path="/similarity-model" component={SimilarityModel} />
                  <Route path="/platform-uptime" component={ServiceUptimeReport} />

                  {/* Embeddable pages that won't have our nav bar */}
                  <Route path="/embed/map/:mapKey+" component={MapTest} />

                  {/* <Route path="/embed/indicator" component={IndicatorModule}/> */}
                  <Route path="/insights-refresh-status" component={InsightsRefreshStatus} />

                  {/* BESPOKE routes for all custom client work */}
                  <Route path="/bespoke/dpac/kcs-report-upload" component={DPACKCSReportUpload} />
                  {/* RSTO Survey for Onboarding and Other Collection Flows */}
                  <Route exact path="/custom/rsto/survey" component={RestackingSurvey} />

                  <Route path="/admin-actions" component={AdminActions} />

                  <Route component={NotFound} />
                </Switch>
              </>
            )}
            {store.notification.show && (
              <Notification store={store} close>
                {store.notification.message}
              </Notification>
            )}
          </Main>
        </Body>

        <Header id="footer" role="navigation">
          <Footer />
        </Header>
      </Container>
    );
  }),
);
