import * as React from "react";
import styled from "styled-components";
import useScrollPosition from "@react-hook/window-scroll";
import { Icon } from "semantic-ui-react";

const DPACCharcoal = "#333132";

const StyledButton = styled.button`
  appearance: initial;
  box-sizing: initial;
  padding-block: initial;
  padding-inline: initial;
  border-width: initial;
  border-style: initial;
  border-color: initial;
  cursor: pointer;
  font-size: 18px;
  color: #ffffff;
  background-color: ${DPACCharcoal};
  padding: 6px 18px;
  border-radius: 12px 12px 0 0;
  &:focus,
  &:active {
    text-decoration: underline;
  }
`;

const StyledWrapper = styled.div`
  position: fixed;
  bottom: 0;
  transform: translateX(-50%);
  left: 50%;
  z-index: 100;
`;

export const BackToTop = () => {
  const [showButton, setShowButton] = React.useState(false);
  const scrollY = useScrollPosition(3);
  React.useEffect(() => {
    // we only want to display it if the content is larger than double the screen height, and the user has scrolled far enough
    if (document.body.scrollHeight > 2 * window.innerHeight && scrollY > window.innerHeight) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [scrollY]);
  if (!showButton) {
    return null;
  }
  return (
    <StyledWrapper>
      <StyledButton tabIndex={1} onClick={() => window.scrollTo(0, 0)}>
        <Icon name="angle double up" /> To top
      </StyledButton>
    </StyledWrapper>
  );
};
