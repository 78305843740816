import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider as MobXProvider } from "mobx-react";
import { ThemeProvider, StyleSheetManager } from "styled-components";
import { DndProvider as DragAndDropProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Helmet } from "react-helmet";

import { AppContainer } from "./appContainer";
import { LoaderGlobal } from "component/LoaderGlobal";
import { Theme } from "./common/styledComponents/theme";

import { BrowserRouter as Router } from "react-router-dom";

import Store from "common/store";

import "./theme/app.scss";

// @TODO - migrate to using transient props for styled-components as recommended by v6, then remove StyleSheetManager and use default configuration
const shouldForwardProp = () => true;

const App = () => {
  const store = new Store();

  return (
    <div role="application" id="application">
      <Helmet titleTemplate="%s - Seer Data" defaultTitle="Seer Data" />
      <MobXProvider store={store}>
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <ThemeProvider theme={Theme}>
            <DragAndDropProvider backend={HTML5Backend}>
              <Router>
                <LoaderGlobal />
                <AppContainer />
              </Router>
            </DragAndDropProvider>
          </ThemeProvider>
        </StyleSheetManager>
      </MobXProvider>
    </div>
  );
};

const container = document.createElement("div");
document.body.appendChild(container);
ReactDOM.render(<App />, container);
