import styled from "styled-components";
import { Dropdown } from "semantic-ui-react";
import { CHART_PRIMARY_COLOR } from "common/helpers/chart";
import { COLORS } from "component/UI/common";

export const purpleBorder = "#EAE6EA";
export const premiumIconColor = "#fbbe28";
export const purpleBoxShadow = "0 1px 4px 1px rgba(99, 78, 99, 0.14)";
export const scrollBarStyle = `
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: ${purpleBorder};
  }
  ::-webkit-scrollbar-thumb {
    background: ${CHART_PRIMARY_COLOR};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${CHART_PRIMARY_COLOR};
}`;

export const linkStyling = `
  max-height: 100%;
  text-decoration: none;
  color: ${COLORS.indigo600};
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  font-size: ${(props) => props.theme.font.size.small};
  display: inline-block;
  padding: 5px 14px;
  margin: 0 5px;
  cursor: pointer;
  &:hover {
    color: ${COLORS.indigo600};
    background-color: ${COLORS.indigo200};
    border-radius: 30px;
    transition: 0.6s;
  }
  &.active {
    background-color: ${COLORS.indigo200};
    border-radius: 30px;
    transition: 0.6s;
  }
  @media screen and (max-width: 1070px) {
    padding: 5px 10px;
  }
`;

export const seerLogo = "/assets/seer-logo.svg";
export const loginLogo = "/assets/seer-logo.svg";
export const seerColorLogo = "/assets/seer-logo-color.svg";

export const StyleSeerLogo = styled.img`
  position: absolute;
  height: 60px;
  width: 100px !important;
  top: 15px;
  left: 30px;
`;

export const StyleAlertBar = styled.nav`
  width: 100%;
  height: 56px !important;
  text-align: center;
`;

export const StyleAlertOffset = styled.nav`
  width: 100%;
  height: 56px !important;
  z-index: -999;
`;

export const StyleNavContainer = styled.nav`
  width: 100%;
  height: auto;
  position: fixed;
  z-index: 999;
`;

export const StyleAlertText = styled.span`
  color: white;
  font-family: "Open Sans";

  font-weight: bold;
  font-size: 15px;
  line-height: 56px;
`;

export const StyleOkButton = styled.span`
  width: auto;
  height: 40px;
  color: #ffffff;
  padding: 0 15px;
  float: right;
  line-height: 40px;
  margin-top: 8px;
  margin-right: 10px;
  border: 1px solid white;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
`;

export const Nav = styled.nav`
  background-color: ${(props) => props.theme.color.label};
  width: 100%;
  height: 56px !important;
  position: fixed;
  z-index: 999;
`;

export const StyledHeaderDropdown = styled(Dropdown)`
  &.ui.dropdown > .menu {
    margin-top: 15px;
    padding: 15px;
    border: 0;
    box-shadow: ${purpleBoxShadow} !important;
  }
  &.ui.dropdown .menu > .item {
    padding: 10px 15px !important;
    border-radius: 4px;
    &:hover {
      background: ${COLORS.indigo100} !important;
    }
  }
`;
