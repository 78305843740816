/* everything in here is hardcoded for WNAC map for now */
import * as React from "react";
import * as Excel from "exceljs/dist/exceljs.min.js";
import { parse } from "csv-parse/browser/esm";
import { RouteComponentProps } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Container } from "semantic-ui-react";
import Store from "common/store";
import { backendUrl } from "common/constants";

export const stubMapConfigWNAC = {
  title: "WNAC Map - Closing the Gap",
  defaultZoom: 10,
  defaultMapCenter: [-32.75328831226853, 151.5834811893607],
  mapBounds: [
    [-50, 50],
    [4, 200],
  ], // unsure if needed
  cssOverrides: `
    .leaflet-container, .leaflet-container .leaflet-interactive, .leaflet-container .leaflet-popup-content-wrapper, .leaflet-container .leaflet-popup-content-wrapper * {
      cursor: url('/assets/images/wnac-eagle.png') 66 10, auto;
    }
    .leaflet-popup-content-wrapper {
      border: 2px solid #e49323;
      border-radius: 12px;
      box-shadow: none;
    }
    .leaflet-popup-tip-container {
      overflow: visible;
      margin-top: 1px;
    }
    .leaflet-popup-tip {
      box-shadow: none;
      border: 2px solid #e49323;
      border-top: none;
      border-left: none;
    }
    .leaflet-popup-content p {
      margin: 0px 0 10px 0;
      line-height: 1.4;
    }
    a.wnac-link {
      border: 2px solid #e49323;
      border-radius: 10px;
      color: #000;
      padding: 4px 12px;
    }
  `,
  baseMaps: [
    {
      checked: true, // only one basemap can be checked just as an FYI for later
      name: "Google Maps",
      url: "https://mt0.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
      // subdomains: ["mt0","mt1","mt2","mt3"], // not working for some reason so forcing mt0 for now
      attribution: "&copy; Google Maps",
    },
    // @TODO may get asked for terrain-labels too on top of watercolor, this will need looking into as can't combine basemap's currently
    // url: "https://stamen-tiles-{s}.a.ssl.fastly.net/terrain-labels/{z}/{x}/{y}.jpg",
    {
      name: "Watercolor",
      url: "https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg",
      attribution:
        '<a href="../">Map tiles</a> by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>.',
    },
    {
      name: "Light",
      url: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>',
    },
    {
      name: "Terrain",
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      attribution:
        "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
    },
  ],
  layers: [
    {
      name: "lga_boundary",
      label: "Implementation Area",
      checked: true,
      defaultStyle: {
        weight: 3,
        color: "#c0272d",
        fillOpacity: 0,
        stroke: true,
      },
      properties: [],
    },
    {
      name: "education",
      label: "Education",
      checked: true,
      onEachFeature: true,
      pointToLayer: true,
      customizations: "wnac",
      properties: [
        { name: "name", label: "Name", type: "string" },
        { name: "data_metric", label: "Metric", type: "number" },
        { name: "data_text", label: "Text", type: "string" },
        { name: "text", label: "Summary", type: "string" },
        { name: "insight_link", label: "Insight Link", type: "string" },
        { name: "rap_link", label: "RAP Link", type: "string" },
      ],
    },
    {
      name: "employment",
      label: "Employment",
      checked: false,
      onEachFeature: true,
      pointToLayer: true,
      customizations: "wnac",
      properties: [
        { name: "name", label: "Name", type: "string" },
        { name: "data_metric", label: "Metric", type: "number" },
        { name: "data_text", label: "Text", type: "string" },
        { name: "text", label: "Summary", type: "string" },
        { name: "insight_link", label: "Insight Link", type: "string" },
        { name: "rap_link", label: "RAP Link", type: "string" },
      ],
    },
    {
      name: "organisations",
      label: "RAP Holding Organisations",
      checked: false,
      onEachFeature: true,
      pointToLayer: true,
      customizations: "wnac",
      properties: [
        { name: "name", label: "Name", type: "string" },
        // { name: "data_metric", label: "Metric", type: "number" },
        // { name: "data_text", label: "Text", type: "string" },
        { name: "text", label: "Summary", type: "string" },
        // { name: "insight_link", label: "Insight Link", type: "string" },
        { name: "rap_link", label: "RAP Link", type: "string" },
      ],
    },
  ],
};

const dataTypeTransforms = {
  string: (value) => value,
  number: (value) => Number(value) || null,
};

type ParsedRecords = string[][];
interface ParsedSheets {
  name: string;
  records: ParsedRecords;
}

const parseMapDataXLSX = async (file: File): Promise<ParsedSheets[]> => {
  const result: ParsedSheets[] = [];
  const workbook = new Excel.Workbook();
  const inBuffer = await file.arrayBuffer();
  await workbook.xlsx.load(inBuffer);
  const sheetNames: string[] = [];
  workbook.eachSheet((worksheet: any) => {
    sheetNames.push(worksheet.name as string);
  });
  for (const sheetName of sheetNames) {
    const buffer = await workbook.csv.writeBuffer({ sheetName });
    const csvString: string = buffer.toString();
    const records: ParsedRecords = await new Promise((resolve) => {
      parse(csvString, (err, records) => {
        if (err) {
          console.error(err);
          resolve([]);
        } else {
          resolve(records as ParsedRecords);
        }
      });
    });
    result.push({ name: sheetName, records });
  }
  return result;
};

interface Props extends RouteComponentProps {
  store?: Store;
}

const Component = (props: Props) => {
  const store = props.store!;
  console.log(props); // @TODO - once this is a feature we will expect to load the mapconfig for +props.match.params.id, and upload data for the map id
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <Container>
      <h1>WNAC Map Updates (INTERNAL)</h1>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          const file = inputRef.current?.files?.[0];
          if (!file) {
            alert("No file attached");
            return;
          }
          // can attach the raw file as is to the request body
          const formData = new FormData();
          formData.append("raw_data", file);
          // parse the file into separate sheets (layers for the GeoJSON)
          const layers = await parseMapDataXLSX(file);
          // compile each layer into a GeoJSON object
          const geoJSON: any = []; // GeoJSON data for each layer
          for (const layer of layers) {
            const { name, records } = layer;
            const config = stubMapConfigWNAC.layers.find((item) => item.name === name);
            if (!config) {
              continue; // sheet is not part of schema
            }
            const nextGeoJSON: any = {
              type: "FeatureCollection",
              features: [],
            };
            // fall back to string for unknown properties, columns 0 and 1 are always geography_type and geography_coords that are always processed the same way
            const columnConfig = records[0].map(
              (propKey) => config.properties.find((item) => item.name === propKey) || { type: "string", name: propKey, label: propKey },
            );
            const data = records.slice(1);
            for (const row of data) {
              try {
                const geometry = { type: row[0], coordinates: JSON.parse(row[1]) };
                const properties = row.reduce((prev, curr, currIdx) => {
                  if (currIdx < 2) {
                    return prev;
                  }
                  return { ...prev, [columnConfig[currIdx].name]: dataTypeTransforms[columnConfig[currIdx].type](curr) };
                }, {});
                nextGeoJSON.features.push({ type: "Feature", geometry, properties });
              } catch (e) {
                console.error(e); // invalid rows will just be skipped
              }
            }
            geoJSON.push({ layer: name, data: nextGeoJSON });
          }
          // prepare the GeoJSON files for upload and attach to request body
          const encoder = new TextEncoder();
          for (const _geoJSON of geoJSON) {
            const nextFile = new File([encoder.encode(JSON.stringify(_geoJSON.data))], `${_geoJSON.layer}.json`, {
              type: "application/json",
            });
            formData.append(_geoJSON.layer as string, nextFile);
          }
          // upload original data + each generated GeoJSON
          const ok = await fetch(`${backendUrl}/maps/wnac/upload`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "X-Token": store.token || "",
            },
            body: formData,
          })
            .then((res) => res.ok)
            .catch(() => false);
          alert(ok ? "Upload OK" : "Upload Failed");
        }}
      >
        <input
          ref={inputRef}
          type="file"
          name="xlsx"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        />
        <button>upload</button>
      </form>
    </Container>
  );
};

export const MapDataUpload = inject("store")(observer(Component));
