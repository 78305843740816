import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Select from "react-select";
import { uniq } from "lodash";
import { Checkbox, Divider, Form, Icon, Popup } from "semantic-ui-react";
import Store from "common/store";
import { datasets as dataApi } from "common/api";
import { customReactSelectStyles } from "common/helpers/dataset";

interface IVariableAndCategoryDropdown {
  variableOptions: any[];
  initialValue?: any;
  insightsDimensions: any;
  changeLocalisationsHandler: (data) => void;
  store?: Store;
}

const VariableAndCategoryDropdownComponent = ({
  variableOptions,
  initialValue,
  insightsDimensions,
  changeLocalisationsHandler,
  store,
}: IVariableAndCategoryDropdown): JSX.Element => {
  const [showCategoryLoader, setShowCategoryLoader] = React.useState(false);
  const [categoryOptions, setCategoryOptions] = React.useState<any[]>([]);
  const [selectedVariable, setSelectedVariable] = React.useState<any>(null);
  const [selectedCategory, setSelectedCategory] = React.useState<any>(null);
  const [isReplaceChecked, setIsReplaceChecked] = React.useState(false);
  const [selectedReplaceCategory, setSelectedReplaceCategory] = React.useState<any>(null);

  // Set category options "isDisabled" true if the option has been selected (exists in "selectedCategory")
  const replaceCategoryOptions = selectedVariable
    ? insightsDimensions[selectedVariable.value].map((category) => ({
        key: category,
        label: category,
        value: category,
        isDisabled: selectedCategory.map((cat) => cat.value).includes(category),
      }))
    : [];
  const isVariableAndCategorySelected = selectedVariable && selectedCategory;

  const loadCategories = async (variable) => {
    setShowCategoryLoader(true);
    const res: any = await dataApi.post(
      "v2/qs",
      {
        filters: { [variable]: [] },
      },
      store!.token!,
    );
    if (res.body) {
      const allAvailableCategoryOptions: string[] = [];
      res.body.data.datasets.map((dataset) => {
        const findDimension = dataset.dimensions.find((dim) => dim.name === variable);
        if (findDimension) {
          allAvailableCategoryOptions.push(...findDimension.values.map((value) => value.name));
        }
      });
      const formattedCategories = uniq(allAvailableCategoryOptions).map((category) => ({
        key: category,
        label: category,
        value: category,
        isDisabled: false,
      }));
      setCategoryOptions(formattedCategories);
    }
    setShowCategoryLoader(false);
  };

  React.useEffect(() => {
    // Update selections
    if (initialValue) {
      if (initialValue.variable) {
        setSelectedVariable({
          key: initialValue.variable,
          label: initialValue.variable,
          value: initialValue.variable,
        });
      } else {
        setSelectedVariable(null);
      }
      if (initialValue.category) {
        setSelectedCategory(
          initialValue.category.map((cat) => ({
            key: cat,
            label: cat,
            value: cat,
          })),
        );
      } else {
        setSelectedCategory([]);
      }
      if (initialValue.replaceCategory) {
        setSelectedReplaceCategory({
          key: initialValue.replaceCategory,
          label: initialValue.replaceCategory,
          value: initialValue.replaceCategory,
        });
      } else {
        setSelectedReplaceCategory(null);
      }
    }
  }, [initialValue]);

  // Re-load variable options when "selectedVariable" changes
  React.useEffect(() => {
    if (selectedVariable) {
      loadCategories(selectedVariable.value);
    }
  }, [selectedVariable]);

  return (
    <Form>
      <Form.Group widths={2}>
        <Form.Field>
          <h5 className="my-2">
            Variable
            <Popup
              trigger={<Icon className="ml-2" name="info circle" color="grey" size="small" />}
              inverted
              size="mini"
              position="top center"
              content="This dropdown contains all variables that are set on the insights contained in this suitcase"
            />
          </h5>
          <Select
            styles={customReactSelectStyles}
            placeholder="Select or search variable"
            value={selectedVariable}
            options={variableOptions}
            onChange={(variable: any) => {
              if (selectedVariable) {
                // Replace the old "variable" with a new "variable"
                changeLocalisationsHandler({
                  oldVariable: selectedVariable.value,
                  variable: variable.value,
                });
              } else {
                // Add a new "variable"
                changeLocalisationsHandler({
                  variable: variable.value,
                });
              }
              setSelectedVariable(variable);
              setSelectedCategory(null);
            }}
          />
        </Form.Field>
        <Form.Field>
          <h5 className="my-2">
            Category
            <Popup
              trigger={<Icon className="ml-2" name="info circle" color="grey" size="small" />}
              inverted
              size="mini"
              position="top center"
              content="This dropdown contains all categories you have access to that are available to the selected variable"
            />
          </h5>
          <div className="d-flex align-items-center">
            <Select
              className="w-100"
              styles={customReactSelectStyles}
              placeholder="Select or search category"
              value={selectedCategory}
              options={categoryOptions}
              isLoading={showCategoryLoader}
              isDisabled={showCategoryLoader || !selectedVariable}
              onChange={(category) => {
                // change "category"
                changeLocalisationsHandler({
                  variable: selectedVariable.value,
                  category: category.map((cat) => cat.value),
                });
                setSelectedCategory(category);
                setIsReplaceChecked(false);
                setSelectedReplaceCategory(null);
              }}
              isMulti
            />
            {/* Remove item from parent's "localisations" */}
            <Popup
              trigger={
                <Icon
                  name="trash"
                  className={`ml-3 mr-0 ${isVariableAndCategorySelected ? "text-primary cursor-pointer" : "text-grey"}`}
                  style={{ marginTop: -5 }}
                  onClick={() =>
                    isVariableAndCategorySelected
                      ? changeLocalisationsHandler({
                          variable: selectedVariable!.value,
                          remove: true,
                        })
                      : null
                  }
                />
              }
              content="Remove variable"
              position="bottom center"
              size="mini"
              inverted
            />
          </div>
        </Form.Field>
      </Form.Group>
      <Form.Group widths={2}>
        <Form.Field>
          {isVariableAndCategorySelected && (
            <div className="d-flex align-items-start">
              <Popup
                trigger={
                  <Checkbox
                    disabled={selectedCategory.length > 1}
                    className="mr-2"
                    checked={isReplaceChecked}
                    onChange={(_e, data) => {
                      setIsReplaceChecked(data.checked!);
                      setSelectedReplaceCategory(null);
                      // Always remove "replaceCategory" when Replace Checkbox changes
                      changeLocalisationsHandler({
                        variable: selectedVariable!.value,
                      });
                    }}
                  />
                }
                content="Not available with multiple categories selected"
                position="top center"
                size="mini"
                inverted
                disabled={selectedCategory.length < 2}
              />
              <div>
                <h5 className="mb-1 text-secondary">Replace single category</h5>
                <p className="text-muted">
                  Check to specify a category to replace where there are multiple categories selected for the selected variable
                </p>
              </div>
            </div>
          )}
        </Form.Field>
        <Form.Field>
          {isReplaceChecked && (
            <>
              <h3 className="mt-0 mb-2">Replace Category</h3>
              <Select
                styles={customReactSelectStyles}
                placeholder="Select or search category to replace"
                value={selectedReplaceCategory}
                options={replaceCategoryOptions}
                isLoading={showCategoryLoader}
                isDisabled={showCategoryLoader || !selectedVariable}
                onChange={(replaceCategory) => {
                  setSelectedReplaceCategory(replaceCategory);
                  // Update "replaceCategory"
                  changeLocalisationsHandler({
                    variable: selectedVariable!.value,
                    replaceCategory: replaceCategory.value,
                  });
                }}
              />
            </>
          )}
        </Form.Field>
      </Form.Group>
      <Divider className="my-3" />
    </Form>
  );
};

export const VariableAndCategoryDropdown = inject((stores: any) => ({
  store: stores.store,
}))(observer(VariableAndCategoryDropdownComponent));
