// String Helpers

export const shortenString = (originalString: string, maxWords: number): string => {
  const nameArray = originalString.split(" ");
  const shorterString =
    nameArray.length > maxWords
      ? `${nameArray.slice(0, maxWords - 1).join(" ")} ... ${nameArray.slice(-1).join(" ")}`
      : nameArray.join(" ");
  return shorterString;
};

export const numberFormatter = (num: number, digits: number): string => {
  const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "t" },
    { value: 1e15, symbol: "q" },
    { value: 1e18, symbol: "Q" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i: number;
  for (i = si.length - 1; i > 0; i--) {
    if (Math.abs(num) >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
// eslint-disable-next-line
export const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string;

// returns a sha512 digest hash as hex string for a passed variable text string
export const hashSHA512 = async (str: string): Promise<string> => {
  if (window.isSecureContext) {
    const hashBuffer = await crypto.subtle.digest("SHA-512", new TextEncoder().encode(str));
    return Array.from(new Uint8Array(hashBuffer))
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
  }
  // return original string if not on (https or localhost)
  return str;
};

// integer padding function returning a <char> padded string if string length is less than pad length
export const pad =
  (length: number, char = "0") =>
  (num: number | string): string =>
    `${num}`.padStart(length, char);

// generates lowercase alphanumeric string of 11-12 chars (see https://stackoverflow.com/questions/10726909/random-alpha-numeric-string-in-javascript)
export const generateSimpleSalt = (): string => Math.random().toString(36).slice(2);
