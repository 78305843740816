import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Button, Form, Modal, ModalActions, ModalContent, ModalHeader } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { FieldInput } from "component/FinalFormFields/FieldInput";
import { composeValidators, mustBeValidEmail, required } from "common/helpers/finalForm";
import { database, getMixpanel } from "common/api";
import { FORM_ERROR } from "final-form";
import { checkUserEmail, CheckUserEmailResponse } from "common/helpers/user";
import Store from "common/store";

interface Props {
  store?: Store;
}

const Component = (props: Props): JSX.Element => {
  const store = props.store!;
  const [open, setOpen] = React.useState(false);

  const onSubmit = async (values) => {
    getMixpanel(store).track("Send password reset email", { Email: values.email });
    const checkEmailRes: CheckUserEmailResponse = await checkUserEmail(values.email);
    if (!checkEmailRes.userExists) {
      return { [FORM_ERROR]: "Email does not exist" };
    } else {
      const res: any = await database.post("emails/password-reset", { email: values.email });
      if (res.statusCode === 200) {
        setOpen(false);
        return alert("The password reset email has been sent successfully!");
      } else {
        return { [FORM_ERROR]: "Server error. Please try again later." };
      }
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<a className="text-decoration-underline cursor-pointer d-block fs-0875 fw-600 my-3">Set or reset your password here</a>}
    >
      <FinalForm
        onSubmit={onSubmit}
        render={(formRenderProps) => {
          const { handleSubmit, submitError } = formRenderProps;
          return (
            <>
              <ModalHeader>Send reset password email</ModalHeader>
              <ModalContent>
                <Form onSubmit={handleSubmit}>
                  <Field name="email" label="Email" component={FieldInput} validate={composeValidators(required, mustBeValidEmail)} />
                  {submitError && <p className="text-danger fs-0875 mt-1 mb-0">{submitError}</p>}
                </Form>
              </ModalContent>
              <ModalActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={handleSubmit} color="purple">
                  Send email
                </Button>
              </ModalActions>
            </>
          );
        }}
      />
    </Modal>
  );
};

export const ResetPasswordModal = inject("store")(observer(Component));
