import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { useLocation, useParams } from "react-router-dom";
import * as qs from "qs";
import Store from "common/store";
import { Icon } from "semantic-ui-react";
import { Conversations } from "./Conversations";
import { Actions } from "./Actions";
import { Button } from "component/UI/Button";
import { StyledTab } from "./includes/Collaborate.style";
import { getDashboardCollaborators } from "./includes/helper";
import { ContentPlaceholder } from "component/ContentPlaceholder";
import { Breadcrumbs } from "component/Breadcrumbs/Breadcrumbs";
import { COLORS } from "component/UI/common";

interface Props {
  open: boolean;
  collaborators: any[];
  onClose: () => void;
  store?: Store;
}

const Component = (props: Props): JSX.Element | null => {
  const { open, onClose, collaborators, store } = props;
  const { dashboard: dashboardStore, user } = store!;
  const [dashboardCollaborators, setDashboardCollaborators] = React.useState<any[]>([]);
  const [dashboardName, setDashboardName] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const { dashboardID } = useParams<{ dashboardID: string }>();
  const location = useLocation();
  const { search } = location;
  const { fullscreen } = qs.parse(search.slice(1) || "");
  const showFullscreen = fullscreen === "true" && open === undefined;

  const panes = [
    {
      menuItem: "Conversations",
      render: () => (
        <Conversations dashboardID={+dashboardID} collaborators={dashboardCollaborators} onClose={onClose} fullscreen={showFullscreen} />
      ),
    },
    {
      menuItem: "Actions",
      render: () => (
        <Actions dashboardID={+dashboardID} collaborators={dashboardCollaborators} onClose={onClose} fullscreen={showFullscreen} />
      ),
    },
  ];

  const changeTab = (_e, { activeIndex }) => {
    if (activeIndex === 1) {
      // Click on "Actions"
      dashboardStore.setSelectionOverlay("highlight");
    } else {
      dashboardStore.setSelectionOverlay(null);
    }
  };

  React.useEffect(() => {
    if (collaborators?.length > 0) {
      setDashboardCollaborators(collaborators);
    }
  }, [collaborators]);

  React.useEffect(() => {
    const loadCollaborateData = async () => {
      setLoading(true);
      const res: any = await dashboardStore.getDashboard(+dashboardID);
      if (res?.dashboard) {
        setDashboardName(res.dashboard.name);
        setDashboardCollaborators(
          getDashboardCollaborators(user!, (res.dashboard.users as any[]) || [], (res.dashboard.groups as any[]) || []),
        );
        dashboardStore.setDashboardComments(res.dashboard.comments);
        dashboardStore.setDashboardActions(res.dashboard.actions);
      }
      setLoading(false);
    };
    if (showFullscreen) {
      loadCollaborateData();
    }
  }, [dashboardID]);

  return (
    <>
      {open || showFullscreen ? (
        <div
          className={`flex-shrink-0 text-muted p-4 ${showFullscreen ? "d-flex align-items-center flex-column" : ""}`}
          style={{
            background: "none",
            width: showFullscreen ? "100%" : 440,
            height: "calc(100vh - 56px)",
            marginTop: showFullscreen ? -70 : -34,
            borderLeft: `1px solid ${COLORS.grey300}`,
          }}
        >
          {loading ? (
            <ContentPlaceholder />
          ) : (
            <>
              <div className={`d-flex align-items-center w-100 mb-2 justify-content-${showFullscreen ? "between" : "end"}`}>
                {showFullscreen && (
                  <Breadcrumbs
                    items={[
                      { pathname: "/dashboards", label: "Dashboards" },
                      { pathname: `/dashboards/${dashboardID}`, label: dashboardName },
                      { pathname: "", label: "Collaborate" },
                    ]}
                  />
                )}
                <Button colorConfig="redInverted" className="flex-shrink-0" onClick={showFullscreen ? () => window.close() : onClose}>
                  Close <Icon name="close" className="mr-0" />
                </Button>
              </div>
              <StyledTab menu={{ secondary: true, pointing: true }} panes={panes} fullscreen={showFullscreen} onTabChange={changeTab} />
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export const Collaborate = inject("store")(observer(Component));
