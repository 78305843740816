import * as React from "react";
import * as ReactDOM from "react-dom";
import { CustomFormMultiRow } from "./CustomFormMultiRow";
import { insertScript } from "common/helpers/script";
import { ObjectAny } from "common/helpers/types";
import { CustomFormDropdown } from "./CustomFormDropdown";
import { CustomFormAccordion } from "./CustomFormAccordion";
import { ls } from "common/helpers/storage";
import { CustomFormDropdownNew } from "./CustomFormDropdownNew";
import { CustomFormMultiRowNew } from "./CustomFormMultiRowNew";

interface Submission {
  title: string;
  value: string | ObjectAny[];
}

interface Checklist {
  title: string;
  items: ChecklistItem[];
}

interface ChecklistItem {
  checked: boolean;
  label: string;
}

// Class names for form fields and keys for saving/loading form state in Local Storage
const FORM_TEXTAREA_CLASSES = [
  "giat-project-title-textarea",
  "giat-division-textarea",
  "giat-question-one-a-textarea",
  "giat-question-one-b-textarea",
  "giat-question-one-c-textarea",
  "giat-question-five-textarea",
]; // Textarea fields: Ensure each textarea element has the corresponding class
const FORM_AGENCY_DEPARTMENT_WRAPPER = "giat-agency-department-wrapper"; // For "Government Agency/Department" question: NEED to add class to the wrapper div
const FORM_AGENCY_DEPARTMENT_DROPDOWN = "giat-agency-department-dropdown"; // For "Government Agency/Department" dropdown: NO NEED to add class to the select
const FORM_AGENCY_DEPARTMENT_TEXTAREA = "giat-agency-department-textarea"; // For "Government Agency/Department" textarea (When select "Other" for dropdown): NO NEED to add class to custom html
const FORM_MULTI_ROW_TABLE = "giat-question-table"; // For multi-row tables: NEED to add class to the wrapper div of multi-row table
const FORM_MULTI_ROW_TABLE_CLASSES = [
  "giat-question-two-table",
  "giat-question-three-table",
  "giat-question-four-table",
  "giat-question-six-table",
  "giat-question-seven-table",
]; // Multi-row table fields: Ensure each multi-row table element has the corresponding class
// End of form field class names and Local Storage keys

const FILE_NAME = "DPAC GIAT Response";
const FILE_TITLE = "Applying a gender lens and making changes";
const TASMANIAN_GOVERNMENT_LOGO_URL = "https://seer-media.s3.ap-southeast-2.amazonaws.com/images/7bdde403-149b-4f04-913e-24f0173f5564.png";

// Need to specify "crossorigin" when drawing data that was loaded from another origin into a canvas
const loadImage = () =>
  new Promise((resolve) => {
    const img = new Image() as HTMLImageElement;
    img.onload = () => resolve(img);
    img.setAttribute("crossorigin", "anonymous");
    img.src = TASMANIAN_GOVERNMENT_LOGO_URL;
  });

const CustomFormDropdownInjection = () => {
  const dropdownElement = document.querySelector(".seer-custom-form-dropdown");
  if (dropdownElement) {
    const title = dropdownElement.getAttribute("title");
    ReactDOM.render(<CustomFormDropdown title={title} />, dropdownElement);
  }
};

const CustomFormDropdownInjectionNew = () => {
  const dropdownElement = document.querySelector(`.${FORM_AGENCY_DEPARTMENT_WRAPPER}`);
  if (dropdownElement) {
    const title = dropdownElement.getAttribute("title");
    const dropdownSavedValue = ls.getItem(FORM_AGENCY_DEPARTMENT_DROPDOWN) || "";
    const textareaSavedValue = ls.getItem(FORM_AGENCY_DEPARTMENT_TEXTAREA) || "";
    ReactDOM.render(
      <CustomFormDropdownNew
        title={title}
        dropdownLSKey={FORM_AGENCY_DEPARTMENT_DROPDOWN}
        dropdownSavedValue={dropdownSavedValue}
        textareaLSKey={FORM_AGENCY_DEPARTMENT_TEXTAREA}
        textareaSavedValue={textareaSavedValue}
      />,
      dropdownElement,
    );
  }
};

const CustomFormMultiRowInjection = () => {
  const multiRowElement = document.querySelector(".seer-custom-form-multi-row");
  if (multiRowElement) {
    const title = multiRowElement.getAttribute("title");
    ReactDOM.render(<CustomFormMultiRow title={title} />, multiRowElement);
  }
};

const CustomFormMultiRowInjectionNew = () => {
  const multiRowElements = document.querySelectorAll(`.${FORM_MULTI_ROW_TABLE}`);
  if (multiRowElements.length) {
    multiRowElements.forEach((multiRowElement) => {
      const lsKey = multiRowElement.getAttribute("id");
      const savedValues = ls.getItem(lsKey);
      // Get columns and tooltips from data attributes:
      // - data-columns: A string of column names separated by " * " (e.g. "Column 1 * Column 2 * Column 3")
      const columns = multiRowElement.getAttribute("data-columns")?.split(" * ") || [];
      // - data-tooltips: A string of column tooltips separated by " * " (e.g. "Tooltip 1 * Tooltip 2 * Tooltip 3")
      const tooltips = multiRowElement.getAttribute("data-tooltips")?.split(" * ") || [];
      ReactDOM.render(
        <CustomFormMultiRowNew lsKey={lsKey} savedValues={savedValues} columns={columns} tooltips={tooltips} />,
        multiRowElement,
      );
    });
  }
};

const CustomFormAccordionInjection = () => {
  const accordionElements = document.querySelectorAll(".seer-custom-form-accordion");
  if (accordionElements.length) {
    accordionElements.forEach((accordionNode, accordionIdx) => {
      const items: any = [];
      const accordionItems = accordionNode.querySelectorAll(".seer-custom-form-accordion-item");
      accordionItems.forEach((itemNode) => {
        const titleNode = itemNode.querySelector(".seer-custom-form-accordion-title") as HTMLElement;
        const contentNode = itemNode.querySelector(".seer-custom-form-accordion-content");
        items.push({
          title: titleNode?.innerText,
          content: contentNode?.innerHTML,
        });
      });
      ReactDOM.render(<CustomFormAccordion items={items} accordionIdx={accordionIdx} />, accordionNode);
    });
  }
};

// print PDF of question responses
const printToPDF = async (submission, imageBase64) => {
  try {
    await insertScript("PDFKit", {
      type: "text/javascript",
      src: "https://cdn.jsdelivr.net/npm/pdfkit@0.15.0/js/pdfkit.standalone.min.js",
    });
    await insertScript("blob-stream", {
      type: "text/javascript",
      src: "https://github.com/devongovett/blob-stream/releases/download/v0.1.3/blob-stream.js",
    });
    const doc = new window["PDFDocument"]();
    const stream = doc.pipe(window["blobStream"]());
    doc.image(imageBase64, 50, 50, { width: 150 });
    doc.fontSize(22);
    doc.font("Helvetica-Bold").text(FILE_TITLE, 50, 160).moveDown();
    doc.fontSize(14);
    submission.forEach((s, sIdx) => {
      doc.font("Helvetica-Bold").text(s.title).moveDown(0.4);
      if (Array.isArray(s.value)) {
        s.value.forEach((v, idx) => {
          if (sIdx !== 3) {
            doc
              .font("Helvetica-Bold")
              .text(`Answer ${idx + 1}`)
              .moveDown(0.2);
          }
          Object.keys(v).forEach((key) => {
            doc.font("Helvetica").text(key, { underline: true, continued: true }).text(`: ${v[key]}`, { underline: false }).moveDown(0.2);
          });
          doc.moveDown(0.4);
        });
      } else {
        doc.font("Helvetica").text(s.value);
      }
      doc.moveDown();
    });
    doc.end();
    stream.on("finish", () => {
      const url = stream.toBlobURL("application/pdf");
      const a = document.createElement("a");
      a.href = url;
      a.download = `${FILE_NAME}.pdf`;
      a.click();
    });
  } catch (e) {
    console.error("Download response PDF failed!", e);
  }
};

// print docx of question responses
const printToDocx = async (submission, imageBase64) => {
  try {
    await insertScript("docx", { type: "text/javascript", src: "https://cdn.jsdelivr.net/npm/docx@8.5.0/build/index.umd.min.js" });
    const docx = window["docx"];
    const { Document, Paragraph, ImageRun, TextRun, Packer, SectionType, HeadingLevel, UnderlineType } = docx;
    const styles = {
      default: {
        heading1: {
          run: {
            size: "22pt",
            bold: true,
          },
          paragraph: {
            spacing: {
              after: 600,
            },
          },
        },
        heading2: {
          run: {
            size: "14pt",
            bold: true,
          },
          paragraph: {
            spacing: {
              after: 80,
            },
          },
        },
        document: {
          run: {
            size: "14pt",
            font: "Helvetica",
            color: "#000000",
          },
        },
      },
    };
    const sections: any[] = [];
    // Add logo
    sections.push({
      children: [
        new Paragraph({
          children: [
            new ImageRun({
              type: "png",
              data: imageBase64,
              transformation: {
                width: 200,
                height: 75,
              },
            }),
          ],
          spacing: {
            after: 1000,
          },
        }),
      ],
      properties: {
        type: SectionType.CONTINUOUS,
        page: {
          margin: {
            top: "50pt",
            left: "50pt",
            right: "50pt",
          },
        },
      },
    });
    // Add file title
    sections.push({
      children: [
        new Paragraph({
          text: FILE_TITLE,
          heading: HeadingLevel.HEADING_1,
        }),
      ],
      properties: {
        type: SectionType.CONTINUOUS,
        page: {
          margin: {
            left: "50pt",
            right: "50pt",
          },
        },
      },
    });
    // Add all submission
    submission.forEach((s, sIdx) => {
      const { title, value } = s;
      const children: any[] = [];
      children.push(
        new Paragraph({
          text: title,
          heading: HeadingLevel.HEADING_2,
        }),
      );
      if (Array.isArray(value)) {
        value.forEach((v, idx) => {
          if (sIdx !== 3) {
            children.push(
              new Paragraph({
                text: `Answer ${idx + 1}`,
                heading: HeadingLevel.HEADING_2,
                spacing: {
                  after: 40,
                },
              }),
            );
          }
          Object.keys(v).forEach((key, keyIdx) => {
            let spacingAfter = 30;
            if (keyIdx === Object.keys(v).length - 1) {
              if (idx === value.length - 1) {
                spacingAfter = 320; // Final line of the whole question
              } else {
                spacingAfter = 120; // Final line of each sub-question
              }
            }
            children.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: key,
                    underline: {
                      type: UnderlineType.SINGLE,
                    },
                  }),
                  new TextRun(`: ${v[key]}`),
                ],
                spacing: {
                  after: spacingAfter,
                },
              }),
            );
          });
        });
      } else {
        children.push(
          new Paragraph({
            children: [new TextRun(value)],
            spacing: {
              after: 320,
            },
          }),
        );
      }
      sections.push({
        children,
        properties: {
          type: SectionType.CONTINUOUS,
          page: {
            margin: {
              left: "50pt",
              right: "50pt",
            },
          },
        },
      });
    });
    const doc = new Document({ styles, sections });
    Packer.toBlob(doc).then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${FILE_NAME}.docx`;
      a.click();
      URL.revokeObjectURL(url);
    });
  } catch (e) {
    console.error("Download response docx failed!", e);
  }
};

const PrintResponseEventInjection = (dashboardID: number) => {
  const submitButtons = document.querySelectorAll(".seer-custom-form-submit");
  if (submitButtons.length) {
    submitButtons.forEach((submitButton) => {
      const doPrint = submitButton.classList.contains("seer-custom-form-print");
      submitButton.addEventListener("click", async () => {
        // attach loader style during submit (see src/app/component/LoaderGlobal.tsx)
        const loader = document.createElement("style");
        document.head.appendChild(loader);
        loader.appendChild(
          document.createTextNode(`
          body #loader-global { display: block !important; }
          body #loader-global-backdrop { display: block !important; }
        `),
        );
        // Construct form submission
        const submission: Submission[] = [];
        // - Get responses from all textarea fields (including the textarea of "Government Agency/Department" dropdown on OLD GIAT Dashboard)
        const fieldList = document.querySelectorAll(".seer-custom-form-field");
        fieldList.forEach((node) => {
          submission.push({
            title: node.getAttribute("title") || "",
            value: (node as HTMLTextAreaElement).value,
          });
        });
        // - Get response for "Government Agency/Department" question (dropdown or textarea if dropdown is "Other")
        const agencyDepartmentDropdown = document.querySelector(`.${FORM_AGENCY_DEPARTMENT_DROPDOWN}`);
        if (agencyDepartmentDropdown) {
          const agencyDepartmentDropdownValue = (agencyDepartmentDropdown as HTMLSelectElement).value || "";
          const agencyDepartmentTextarea = document.querySelector(`.${FORM_AGENCY_DEPARTMENT_TEXTAREA}`);
          const agencyDepartmentTextareaValue = (agencyDepartmentTextarea as HTMLTextAreaElement).value || "";
          const agencyDepartmentValue =
            agencyDepartmentDropdownValue === "Other" ? agencyDepartmentTextareaValue : agencyDepartmentDropdownValue;
          const agencyDepartmentResponse: Submission = {
            title: agencyDepartmentDropdown?.getAttribute("title") || "",
            value: agencyDepartmentValue,
          };
          submission.splice(2, 0, agencyDepartmentResponse);
        }
        // - Get response from multi row table (2nd question)
        const multiRowTableContainer = document.querySelector(".seer-custom-form-multi-row");
        const multiRowTableResponse: Submission = { title: multiRowTableContainer?.getAttribute("title") || "", value: [] };
        const multiRowTable = multiRowTableContainer?.firstChild as HTMLTableElement;
        const multiRowTableHeader = multiRowTable?.rows[0];
        for (let i = 1; i < multiRowTable.rows.length; i++) {
          const currentRow = {};
          for (let j = 0; j < multiRowTableHeader.cells.length - 1; j++) {
            const currentCell = multiRowTable.rows[i].cells[j].firstChild as HTMLTextAreaElement;
            currentRow[multiRowTableHeader.cells[j].innerText] = currentCell.value;
          }
          (multiRowTableResponse.value as ObjectAny[]).push(currentRow);
        }
        submission.splice(4, 0, multiRowTableResponse);
        if (doPrint) {
          // Convert image data to base64
          const imageBase64 = await loadImage().then((img: any) => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx!.drawImage(img, 0, 0);
            return canvas.toDataURL("image/png");
          });
          await Promise.all([printToPDF(submission, imageBase64), printToDocx(submission, imageBase64)]);
        }
        // Submit question responses
        let urlParam = "?";
        submission.forEach((s, idx) => {
          if (idx === 0) {
            urlParam = urlParam.concat(`${s.title}=${s.value}`);
          } else if (Array.isArray(s.value)) {
            const paramArray = (s.value as ObjectAny[]).map((v) => `${s.title}[]=${JSON.stringify(v)}`);
            urlParam = urlParam.concat(`&${paramArray.join("&")}`);
          } else {
            urlParam = urlParam.concat(`&${s.title}=${s.value}`);
          }
        });
        // Delete saved state from LS
        [...FORM_TEXTAREA_CLASSES, FORM_AGENCY_DEPARTMENT_DROPDOWN, FORM_AGENCY_DEPARTMENT_TEXTAREA, FORM_MULTI_ROW_TABLE].forEach(
          (key) => {
            ls.removeItem(key);
          },
        );
        window.open(`/form-submission/dashboard/${dashboardID}${urlParam}`, "_blank");
        loader.remove(); // end loader
      });
    });
  }
};

const PrintResponseEventInjectionNew = (dashboardID: number) => {
  const submitButtons = document.querySelectorAll(".seer-custom-form-submit-new");
  if (submitButtons.length) {
    submitButtons.forEach((submitButton) => {
      submitButton.addEventListener("click", async () => {
        // attach loader style during submit (see src/app/component/LoaderGlobal.tsx)
        const loader = document.createElement("style");
        document.head.appendChild(loader);
        loader.appendChild(
          document.createTextNode(`
          body #loader-global { display: block !important; }
          body #loader-global-backdrop { display: block !important; }
        `),
        );
        // START: Construct form submission
        const submission: Submission[] = [];
        // - Get responses for "Title of Project/Policy being assessed"
        const titleField = document.querySelector(".giat-project-title-textarea");
        if (titleField) {
          submission.push({
            title: titleField.getAttribute("title") || "",
            value: (titleField as HTMLTextAreaElement).value,
          });
        }
        // - Get responses for "Division"
        const divisionField = document.querySelector(".giat-division-textarea");
        if (divisionField) {
          submission.push({
            title: divisionField.getAttribute("title") || "",
            value: (divisionField as HTMLTextAreaElement).value,
          });
        }
        // - Get response for "Government Agency/Department" question (dropdown or textarea if dropdown is "Other")
        const agencyDepartmentDropdown = document.querySelector(`.${FORM_AGENCY_DEPARTMENT_DROPDOWN}`);
        if (agencyDepartmentDropdown) {
          const agencyDepartmentDropdownValue = (agencyDepartmentDropdown as HTMLSelectElement).value || "";
          const agencyDepartmentTextarea = document.querySelector(`.${FORM_AGENCY_DEPARTMENT_TEXTAREA}`);
          const agencyDepartmentTextareaValue = (agencyDepartmentTextarea as HTMLTextAreaElement).value || "";
          const agencyDepartmentValue =
            agencyDepartmentDropdownValue === "Other" ? agencyDepartmentTextareaValue : agencyDepartmentDropdownValue;
          const agencyDepartmentResponse: Submission = {
            title: agencyDepartmentDropdown?.getAttribute("title") || "",
            value: agencyDepartmentValue,
          };
          submission.push(agencyDepartmentResponse);
        }
        // - Get response for Question 1 (a, b, c)
        const questionOneFields = document.querySelectorAll(".giat-question-one-textarea");
        const questionOneResponse: Submission = { title: questionOneFields[0].getAttribute("title")?.split(" * ")[0] || "", value: [] };
        const questionOneResponseValues: ObjectAny[] = [];
        questionOneFields.forEach((node) => {
          const value: ObjectAny = {};
          const title = node.getAttribute("title");
          if (title) {
            value[title.split(" * ")[1]] = (node as HTMLTextAreaElement).value;
          }
          questionOneResponseValues.push(value);
        });
        questionOneResponse.value = questionOneResponseValues;
        submission.push(questionOneResponse);
        // - Get response for Question 2/3/4/6/7 (multi-row tables)
        const multiRowTables = document.querySelectorAll(`.${FORM_MULTI_ROW_TABLE}`);
        multiRowTables.forEach((table) => {
          const multiRowTableResponse: Submission = { title: table.getAttribute("title") || "", value: [] };
          const multiRowTable = table.firstChild as HTMLTableElement;
          const multiRowTableHeader = multiRowTable?.rows[0];
          for (let i = 1; i < multiRowTable.rows.length; i++) {
            const currentRow = {};
            for (let j = 0; j < multiRowTableHeader.cells.length - 1; j++) {
              const currentCell = multiRowTable.rows[i].cells[j].firstChild as HTMLTextAreaElement;
              currentRow[multiRowTableHeader.cells[j].innerText] = currentCell.value;
            }
            (multiRowTableResponse.value as ObjectAny[]).push(currentRow);
          }
          submission.push(multiRowTableResponse);
        });
        // - Get response for Question 5
        const questionFiveField = document.querySelector(".giat-question-five-textarea");
        if (questionFiveField) {
          const questionFiveResponse: Submission = {
            title: questionFiveField.getAttribute("title") || "",
            value: (questionFiveField as HTMLTextAreaElement).value,
          };
          submission.splice(7, 0, questionFiveResponse);
        }
        // END: Construct form submission
        const imageBase64 = await loadImage().then((img: any) => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx!.drawImage(img, 0, 0);
          return canvas.toDataURL("image/png");
        });
        await Promise.all([printToPDF(submission, imageBase64), printToDocx(submission, imageBase64)]);
        // Submit question responses
        let urlParam = "?";
        submission.forEach((s, idx) => {
          if (idx === 0) {
            urlParam = urlParam.concat(`${s.title}=${s.value}`);
          } else if (Array.isArray(s.value)) {
            const paramArray = (s.value as ObjectAny[]).map((v) => `${s.title}[]=${JSON.stringify(v)}`);
            urlParam = urlParam.concat(`&${paramArray.join("&")}`);
          } else {
            urlParam = urlParam.concat(`&${s.title}=${s.value}`);
          }
        });
        // Delete saved state from LS
        [
          ...FORM_TEXTAREA_CLASSES,
          FORM_AGENCY_DEPARTMENT_DROPDOWN,
          FORM_AGENCY_DEPARTMENT_TEXTAREA,
          ...FORM_MULTI_ROW_TABLE_CLASSES,
        ].forEach((key) => {
          ls.removeItem(key);
        });
        window.open(`/form-submission/dashboard/${dashboardID}${urlParam}`, "_blank");
        loader.remove(); // end loader
      });
    });
  }
};

const PrintChecklistsInjection = () => {
  const downloadChecklistsButtons = document.querySelectorAll(".seer-custom-form-checklists-download");
  if (downloadChecklistsButtons.length) {
    downloadChecklistsButtons.forEach((button) => {
      button.addEventListener("click", async () => {
        // attach loader style during submit (see src/app/component/LoaderGlobal.tsx)
        const loader = document.createElement("style");
        document.head.appendChild(loader);
        loader.appendChild(
          document.createTextNode(`
          body #loader-global { display: block !important; }
          body #loader-global-backdrop { display: block !important; }
        `),
        );
        // Construct checklists
        const checklists: Checklist[] = [];
        const checklistNodes = document.querySelectorAll(".seer-custom-checklist");
        if (checklistNodes.length) {
          checklistNodes.forEach((node) => {
            const checklist = {} as Checklist;
            checklist["title"] = node.getAttribute("title") || "";
            const checklistItemNodes = Array.from(node.children);
            if (checklistItemNodes.length) {
              const items: ChecklistItem[] = [];
              checklistItemNodes.forEach((itemNode) => {
                const itemChildrenNodes = Array.from(itemNode.children);
                const checkboxNode = itemChildrenNodes.find((child) => child.classList.contains("seer-checkbox")) as HTMLInputElement;
                const checked = checkboxNode.checked;
                const labelNode = itemChildrenNodes.find((child) => child.classList.contains("seer-checkbox-label")) as HTMLElement;
                const label = labelNode?.innerText;
                items.push({ label, checked });
              });
              checklist["items"] = items;
            }
            checklists.push(checklist);
          });
        }
        // print checklists to pdf
        try {
          await insertScript("PDFKit", {
            type: "text/javascript",
            src: "https://cdn.jsdelivr.net/npm/pdfkit@0.15.0/js/pdfkit.standalone.min.js",
          });
          await insertScript("blob-stream", {
            type: "text/javascript",
            src: "https://github.com/devongovett/blob-stream/releases/download/v0.1.3/blob-stream.js",
          });
          const doc = new window["PDFDocument"]();
          const stream = doc.pipe(window["blobStream"]());
          doc.initForm();
          doc.fontSize(26);
          doc.font("Helvetica-Bold").text("Checklist of actions", 70).moveDown(0.4);
          doc.fontSize(14);
          doc
            .font("Helvetica")
            .text(
              "The following checklist can be used as a starting point to consider actions that could be applied to your policy, program or service to improve gender equality. This is in no way an exhaustive list and you will need to consider actions in the context of the work that you are doing.",
              70,
            )
            .moveDown(1);
          checklists.forEach((checklist, checklistIdx) => {
            const { title, items } = checklist;
            doc.fontSize(18);
            doc.font("Helvetica-Bold").text(title, 70).moveDown(0.5);
            doc.fontSize(14);
            items.forEach((item, itemIdex) => {
              const { checked, label } = item;
              doc.formCheckbox(`checkbox-${checklistIdx}-${itemIdex}`, 70, doc.y, 14, 14, {
                backgroundColor: "#cfcfcf",
                readOnly: true,
              });
              if (checked) {
                // Workaround: Display another checkbox inside the current one when it is checked, as PDFKit lacks support for checkbox states in the document.
                doc.formCheckbox(`checkbox-${checklistIdx}-${itemIdex}`, 73, doc.y + 3, 8, 8, {
                  backgroundColor: "#000000",
                  readOnly: true,
                });
              }
              doc.font("Helvetica").text(label, 90, doc.y).moveDown(0.5);
            });
            doc.moveDown(1);
          });
          doc.end();
          stream.on("finish", () => {
            const url = stream.toBlobURL("application/pdf");
            const a = document.createElement("a");
            a.href = url;
            a.download = "Checklist of actions.pdf";
            a.click();
          });
        } catch (e) {
          console.error("Download checklists PDF failed", e);
        }
        loader.remove(); // end loader
      });
    });
  }
};

const SaveFormTextareaStateInjection = () => {
  FORM_TEXTAREA_CLASSES.forEach((textareaClass) => {
    const textareaNodes = document.querySelectorAll(`.${textareaClass}`);
    if (textareaNodes.length) {
      textareaNodes.forEach((node) => {
        node.addEventListener("change", () => {
          const value = (node as HTMLTextAreaElement).value;
          ls.setItem(textareaClass, value);
        });
      });
    }
  });
};

const LoadFormTextareaStateInjection = () => {
  FORM_TEXTAREA_CLASSES.forEach((textareaClass) => {
    const value = ls.getItem(textareaClass);
    if (value) {
      const textareaNodes = document.querySelectorAll(`.${textareaClass}`);
      if (textareaNodes.length) {
        textareaNodes.forEach((node) => {
          (node as HTMLTextAreaElement).value = value;
        });
      }
    }
  });
};

export const injectDPACCustomizations = (dashboardID: number) => {
  CustomFormDropdownInjection(); // TODO: remove this once we switch to the multi-page dashboard
  CustomFormDropdownInjectionNew();
  CustomFormMultiRowInjection(); // TODO: remove this once we switch to the multi-page dashboard
  CustomFormMultiRowInjectionNew();
  CustomFormAccordionInjection();
  PrintResponseEventInjection(dashboardID); // TODO: remove this once we switch to the multi-page dashboard
  PrintResponseEventInjectionNew(dashboardID);
  PrintChecklistsInjection();
  SaveFormTextareaStateInjection();
  LoadFormTextareaStateInjection();
};
