import React = require("react");
import { inject, observer } from "mobx-react";
import Store from "common/store";
import { Avatar } from "component/Avatar";
import styled from "styled-components";

import { StyleUserInfo, StyleOrganisation, StyleTile } from "./addMember.style";
import { database, getMixpanel } from "common/api";
import { Dropdown } from "semantic-ui-react";
import { Label } from "component/UI/Label";
import { seconds } from "common/helpers/date";
import { COLORS } from "component/UI/common";

export const AvatarWrapper = styled.div`
  margin-right: 20px;
  @media only screen and (max-width: 768px) {
    margin-right: 10px;
  }
`;

const Member: React.FC<{ store: Store }> = ({ store }) => {
  const setAdminStatus = async (user_id: number, toAdmin: boolean) => {
    const confirmUpdate = confirm(`Are you sure you want to make this user ${toAdmin ? "an admin" : "a member"}?`);
    if (confirmUpdate) {
      await database.put(`groups/${store.user!.group.id}/users/${user_id}`, { admin: toAdmin }, store.token!);
      store.reloginUserFromAuthToken(); // Refreshes user's group and member details so that the updates are reflected in the UI
    }
  };

  const handleActionClick = (value, user_id: number) => {
    let mixpanelAction;
    if (value === "remove") {
      store.group.removeMember(user_id);
      mixpanelAction = "Remove Member";
    } else if (value === "owner") {
      store.group.setNewOwner(user_id);
      mixpanelAction = "Make Owner";
    } else if (value === "admin") {
      setAdminStatus(user_id, true);
      mixpanelAction = "Make Admin";
    } else {
      setAdminStatus(user_id, false);
      mixpanelAction = "Make Member";
    }
    getMixpanel(store).track(mixpanelAction, { Page: "Settings > Organisation > Members" });
  };

  const { group } = store.user!;
  const groupOwner = group.users.find((user) => user.id === group.user_id);
  // Remove the owner from the members list
  const groupMembers = [...group.users].filter((member) => member.id !== group.user_id);
  // Show the owner at the top of the list
  const groupUsers: any = [groupOwner, ...groupMembers];

  const { breakpoint } = store!.ui;
  const isMobileScreen = breakpoint === "mobile";

  return groupUsers.map((member, i) => {
    let actionButtonOptions: any = [];
    // You can only either make someone an admin or a member, you can't have both options at once
    const makeAdminOption = { key: 1, text: "Make admin", value: "admin" };
    const makeMemberOption = { key: 1, text: "Make member", value: "member" };
    const makeOwnerOption = { key: 2, text: "Make owner", value: "owner" };
    const removeOption = { key: 3, text: "Remove", value: "remove" };
    const thisMemberIsOwner = member.id === store.user!.group.user_id;
    const thisMemberIsAdmin = member.admin === true;
    const thisMemberIsYou = member.id === store.user!.id;
    const youAreOwner = store.user!.id === store.user!.group.user_id;
    const youAreAdmin = groupUsers.find((u) => u.id === store.user!.id)?.admin === true;

    // Show "New Member" tag if joins within 7 days
    const currentTime = Date.now();
    const joinDate = new Date(member.created_at || null);
    const showNewMemberTag = (currentTime - joinDate.getTime()) / 1000 < seconds.week;

    if (thisMemberIsYou) {
      // Also means thisMemberIsOwner
      if (youAreOwner) {
        // You won't have any potions
      } else {
        // You can remove yourself
        actionButtonOptions = [removeOption];
      }
    }

    // If this member is NOT you
    else {
      if (thisMemberIsOwner) {
        // No options
      }
      // If this member is not the owner
      else {
        if (youAreOwner) {
          // You can make this member an admin/member, owner or remove
          actionButtonOptions = [thisMemberIsAdmin ? makeMemberOption : makeAdminOption, makeOwnerOption, removeOption];
        } else if (youAreAdmin && !thisMemberIsAdmin) {
          // You can make this member an admin or remove
          actionButtonOptions = [makeAdminOption, removeOption];
        } else {
          // You have no action options
          actionButtonOptions = [];
        }
      }
    }
    return (
      <StyleTile key={i}>
        <StyleUserInfo>
          <div className="d-flex align-items-center">
            <AvatarWrapper>
              <Avatar name={`${member.first_name} ${member.last_name}`} image={member.avatar} size={isMobileScreen ? 30 : 40} />
            </AvatarWrapper>
            <div>
              <div className="d-flex align-items-center">
                <p className="text-secondary fw-700 fs-1375 mb-0 mr-3">{`${member.first_name} ${member.last_name}`}</p>
                {thisMemberIsOwner ? (
                  <Label colorConfig="grey">OWNER</Label>
                ) : thisMemberIsAdmin ? (
                  <Label colorConfig="purple">ADMIN</Label>
                ) : showNewMemberTag ? (
                  <Label colorConfig="aqua">NEW MEMBER</Label>
                ) : (
                  <></>
                )}
              </div>
              <StyleOrganisation>{member.email}</StyleOrganisation>
            </div>
          </div>
          <div>
            {actionButtonOptions.length > 0 && (
              <Dropdown icon="ellipsis vertical" direction="left" style={{ color: COLORS.grey900, fontSize: 18 }}>
                <Dropdown.Menu>
                  {actionButtonOptions.map((item, idx) => {
                    const { text, value } = item;
                    return (
                      <Dropdown.Item key={`${text}-${idx}`} text={text} onClick={() => handleActionClick(value, member.id as number)} />
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </StyleUserInfo>
      </StyleTile>
    );
  });
};

export default inject("store")(observer(Member));
