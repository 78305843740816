// Helper function to get the sort column and direction based on the sort string. Used for Semantic sort table with pagination endpoints.
export const getSortValues = (sort: string) => {
  const values: any = { column: null, direction: null };
  if (!sort) {
    // no sort applied
  } else if (sort[0] === "-") {
    values.direction = "descending";
    values.column = sort.slice(1);
  } else {
    values.direction = "ascending";
    values.column = sort;
  }
  return values;
};
