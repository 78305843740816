import * as React from "react";
import { webUrl } from "common/constants";
import { Button, Dropdown, Header, Icon, Input, Popup } from "semantic-ui-react";

interface IPublicLink {
  type: "suitcases" | "dashboards";
  item: any;
  onChange: (value) => void;
}

const accessOptions = [
  { key: 1, text: "No access", value: "false" },
  { key: 2, text: "Can View", value: "true" },
];

export const PublicLink = ({ type, item, onChange }: IPublicLink): JSX.Element => {
  const [inputType, setInputType] = React.useState<"public_link" | "embed_code">("public_link");
  const { id, linkShare, key } = item;
  const shareKey = linkShare && key;

  const copyClickHandler = () => {
    const elem: HTMLInputElement | null = document.querySelector('input[name="copy_input"]');
    if (elem) {
      try {
        elem.focus();
        elem.select();
        document.execCommand("copy");
      } catch (err) {
        console.error("Failed to copy", err);
      }
    }
  };

  let dashboardContainerHeight = 600;
  if (inputType === "embed_code" && shareKey) {
    const dashboardContainer = document.querySelector("div#dashboardContainer"); // div "id" is set by ourselves when save Dashboard in Dashboard.tsx
    if (dashboardContainer) {
      dashboardContainerHeight = dashboardContainer.scrollHeight + 150;
    }
  }

  const inputContent =
    inputType === "public_link"
      ? `${webUrl}/${type}/${id}/${key}`
      : `<div style="height: ${dashboardContainerHeight}px"><iframe style="width: 100%; height: 100%;" src="${webUrl}/embed/${type}/${id}/${key}" allow="fullscreen"></iframe></div>`;

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="text-secondary d-flex align-items-center">
          <div className="d-flex">
            <Icon name="linkify" size="large" />
            <div className="d-flex">
              <Header as="h4" color="purple" className="mb-0">
                Anyone with the link
              </Header>
              <Popup
                trigger={<Icon name="info circle" className="text-medium ml-1 cursor-pointer" />}
                position="right center"
                size="small"
                inverted
                hoverable
              >
                <p>Public link sharing and embed</p>
                <a
                  className="fw-600 text-white cursor-pointer text-decoration-underline"
                  href="https://knowledge.seerdata.ai/how-do-i-share-my-dashboard"
                  target="_blank"
                >
                  Learn More
                </a>
              </Popup>
            </div>
          </div>
        </div>
        <Dropdown
          options={accessOptions}
          defaultValue={!!shareKey ? "true" : "false"}
          selectOnBlur={false}
          selection
          onChange={(_, { value }) => onChange(value)}
        />
      </div>
      {shareKey && (
        <>
          <div className="d-flex align-items-center justify-content-start mb-2">
            <Button.Group basic size="small">
              <Button active={inputType === "public_link"} onClick={() => setInputType("public_link")}>
                <Icon name="linkify" />
                Public Link
              </Button>
              <Button active={inputType === "embed_code"} onClick={() => setInputType("embed_code")}>
                <Icon name="code" />
                HTML Embed Code
              </Button>
            </Button.Group>
          </div>
          <div className="d-flex mb-3">
            <Input
              name="copy_input"
              type="text"
              onChange={() => null}
              value={inputContent}
              style={{ width: "100%", marginRight: 10, flex: 1 }}
            />
            <Button basic color="purple" onClick={copyClickHandler}>
              Copy
            </Button>
          </div>
        </>
      )}
    </>
  );
};
