import * as React from "react";
import { RouteComponentProps } from "react-router";
import * as qs from "qs";
import { database } from "common/api";
import { Helmet } from "react-helmet";
import { Button, Container, Header, Icon } from "semantic-ui-react";

const errMessage = "Something went wrong with your submission. Please close the tab, check your form entries and try again.";

export const DashboardFormSubmission = (props: RouteComponentProps<{ dashboardID: string }>) => {
  const { dashboardID } = props.match.params;
  const submission = qs.parse(props.location.search.slice(1));
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const handleSubmission = async () => {
    try {
      const res: any = await database.post(`dashboards/${dashboardID}/form-submissions`, { submission });
      if (!res?.originalResponse?.ok) {
        setError(errMessage);
      }
    } catch (e) {
      setError(errMessage);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    handleSubmission();
  }, []);
  return (
    <Container className="text-center py-6">
      <Helmet>
        <title>Dashboard Form Submission</title>
      </Helmet>
      {loading ? (
        <>Submitting...</>
      ) : (
        <>
          <Icon size="huge" name={error ? "exclamation circle" : "check circle"} color={error ? "red" : "green"} />
          <Header as="h4">{error ? error : "Your form submission was received successfully!"}</Header>
          <Button onClick={() => window.close()}>Close Tab</Button>
        </>
      )}
    </Container>
  );
};
