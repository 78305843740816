import * as React from "react";
import { Form } from "semantic-ui-react";
import Select, { createFilter } from "react-select";
import { FixedSizeList as List } from "react-window";
import { customReactSelectStyles } from "common/helpers/dataset";

const ITEM_HEIGHT = 40;

export const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * ITEM_HEIGHT;

  return (
    <List
      height={Math.min(maxHeight, children.length * ITEM_HEIGHT)}
      itemCount={children.length}
      itemSize={ITEM_HEIGHT}
      initialScrollOffset={initialOffset}
      width="100%"
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

export const FieldReactSelect = (props): JSX.Element => {
  const { input, meta, label, placeholder, isDisabled, isClearable, isLoading, isMulti, options, onChangeCallback } = props;
  const error = meta.error || meta.submitError;
  const showError = error && meta.touched;
  return (
    <Form.Field>
      {label && <label className="text-secondary mb-2 fs-1000">{label}</label>}
      <Select
        {...input}
        onChange={(option) => {
          input.onChange(option || null);
          onChangeCallback && onChangeCallback(option);
        }}
        isMulti={isMulti}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        styles={customReactSelectStyles}
        placeholder={placeholder || ""}
        options={options}
        filterOption={createFilter({ ignoreAccents: false })}
        components={{ MenuList }}
      />
      {showError && (
        <p className="text-danger fs-0875">
          <b>{error}</b>
        </p>
      )}
    </Form.Field>
  );
};
