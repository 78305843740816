import styled from "styled-components";
import { COLORS } from "component/UI/common";

export const Container = styled.div`
  max-width: 940px;
  margin: 0 auto;
  padding-top: 30px;
  @media screen and (max-width: 940px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Heading = styled.h2`
  font-family: "Open Sans", Helvetica, sans-serif;
  color: ${COLORS.indigo600};
  font-weight: 700;
  font-size: 56px;
  line-height: 72px;
  margin-bottom: 48px;
  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px;
  }
`;
