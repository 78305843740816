import * as React from "react";
import { DateTime } from "luxon";
import { Link, useHistory } from "react-router-dom";
import { Icon, Label, Popup, Table } from "semantic-ui-react";
import {
  StyleTable,
  StyleTableCellHeading,
  StyleTableCellText,
  StyleTableHeader,
  StyleTableHeaderCell,
  StyleTableRow,
} from "component/Suitcases/includes/SuitcasesList.style";
import { LatestDataPopup } from "./LatestData";
import { ListDataset, ListTemplate } from "common/store/dataset";
import { getSortValues } from "common/helpers/general";

interface HeaderItems {
  name: string;
  sortColumn?: string;
  className: string;
}

interface DatasetTemplate extends ListTemplate {
  last_updated_at: string | null;
  last_updated_by: string | null;
  contributorsLength: number;
}

interface DatasetTemplateListProps {
  headerItems: HeaderItems[];
  datasets?: ListDataset[];
  templates?: DatasetTemplate[];
  id?: string;
  applySort: (sort: string) => void;
  currentSort: string;
}

// This component is used for both rendering dataset lists and dataset template list
export const DatasetTemplateList = ({
  id,
  headerItems,
  datasets,
  templates,
  applySort,
  currentSort,
}: DatasetTemplateListProps): JSX.Element => {
  const history = useHistory();
  const { column, direction } = getSortValues(currentSort);
  const tableData = datasets ? datasets : templates;

  const handleRowClick = (datasetID: number, templateID?: number) => {
    history.push(`/datasets/${datasetID}${templateID ? `/templates/${templateID}` : ""}`);
  };

  const handleNameClick = (e) => {
    e.stopPropagation();
  };

  return (
    <StyleTable sortable celled fixed id={id || undefined}>
      <StyleTableHeader>
        <StyleTableRow>
          {headerItems.map((item, index) => {
            const { name, sortColumn, className } = item;

            return (
              <StyleTableHeaderCell
                sorted={column === sortColumn ? direction : null}
                onClick={() => {
                  if (sortColumn) {
                    if (column === sortColumn) {
                      applySort(`${direction === "descending" ? "" : "-"}${sortColumn}`);
                    } else {
                      applySort(sortColumn);
                    }
                  }
                }}
                className={className}
                key={index}
              >
                {name}
              </StyleTableHeaderCell>
            );
          })}
        </StyleTableRow>
      </StyleTableHeader>
      <Table.Body>
        {tableData?.map((data, index) => {
          // For "/datasets" page list
          if (datasets) {
            const { id: datasetID, name, num_templates, link } = data;

            return (
              <StyleTableRow key={datasetID} onClick={() => handleRowClick(datasetID)}>
                <StyleTableCellHeading>
                  <Link to={`/datasets/${datasetID}`} className="text-secondary" onClick={(e) => handleNameClick(e)}>
                    {name}
                  </Link>
                </StyleTableCellHeading>
                <StyleTableCellText>{num_templates}</StyleTableCellText>
                <StyleTableCellText>
                  {link ? (
                    <a href={link} target="_blank" onClick={(e) => handleNameClick(e)}>
                      <Icon name="external alternate" className="text-grey text-hover-secondary" />
                    </a>
                  ) : (
                    "-"
                  )}
                </StyleTableCellText>
                <StyleTableCellText>
                  <LatestDataPopup type="dataset" data={data} />
                </StyleTableCellText>
              </StyleTableRow>
            );
            // For "/datasets/:datasetID" page list
          } else {
            const { id: templateID, dataset_id: datasetID, name, last_updated_at, last_updated_by, contributors } = data;

            return (
              <StyleTableRow
                id={id && !index ? `${id}-first-item` : undefined}
                key={templateID}
                onClick={() => handleRowClick(datasetID, templateID)}
              >
                <StyleTableCellHeading>
                  <Link
                    to={`/datasets/${datasetID}/templates/${templateID}`}
                    className="text-secondary"
                    onClick={(e) => handleNameClick(e)}
                  >
                    {name}
                  </Link>
                </StyleTableCellHeading>
                <StyleTableCellText>
                  {last_updated_at ? DateTime.fromISO(last_updated_at).setZone("local").toFormat("DD") : "-"}
                </StyleTableCellText>
                <StyleTableCellText>{last_updated_by || "-"}</StyleTableCellText>
                <StyleTableCellText>
                  <div className="d-flex align-items-center">
                    <div>{contributors.length > 0 ? `${contributors[0].first_name} ${contributors[0].last_name}` : "-"}</div>
                    {contributors.length > 1 && (
                      <Popup
                        trigger={
                          <Label circular className="ml-1 text-medium">
                            {`+${contributors.length - 1}`}
                          </Label>
                        }
                        content={contributors.map((c) => `${c.first_name} ${c.last_name}`).join(", ")}
                        position="top center"
                        size="mini"
                        inverted
                      />
                    )}
                  </div>
                </StyleTableCellText>
                <StyleTableCellText>
                  <LatestDataPopup type="template" data={data} />
                </StyleTableCellText>
              </StyleTableRow>
            );
          }
        })}
      </Table.Body>
    </StyleTable>
  );
};
