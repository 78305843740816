import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Dropdown } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import Store from "common/store";
import { Avatar } from "component/Avatar";
import { StyledHeaderDropdown } from "./includes/header.style";
import { getMixpanel } from "common/api";
import { CHART_PRIMARY_COLOR } from "common/helpers/chart";
import { COLORS } from "component/UI/common";
import { environment } from "common/constants";

interface IAvatarDropdown {
  store?: Store;
}

const MenuItem = (props) => {
  const { text, to, target, onClick, ...rest } = props;
  if (onClick) {
    return (
      <Dropdown.Item {...rest} className="fs-1000 fw-600 mb-0" text={text} style={{ color: CHART_PRIMARY_COLOR }} onClick={onClick} />
    );
  }
  return (
    <Dropdown.Item {...rest} className="fs-1000 fw-600 mb-0" text={text} style={{ color: CHART_PRIMARY_COLOR }} as={NavLink} to={to} target={target || "_self"} />
  );
};

const AvatarDropdownComponent = (props: IAvatarDropdown): JSX.Element => {
  const { store } = props;
  const showAdminActions = environment !== "production" || store!.user!.group.id === 102;

  return (
    <StyledHeaderDropdown
      id="userflow-element-avatar-menu"
      openOnFocus
      icon={<></>}
      direction="left"
      className="d-flex align-items-center d-none d-md-block"
      trigger={(
        <Avatar
          className="cursor-pointer"
          color={COLORS.indigo500}
          image={store!.user!.avatar}
          name={`${store!.user!.first_name} ${store!.user!.last_name}`}
          size={30}
        />
      )}
    >
      <Dropdown.Menu className="mt-2">
        <MenuItem id="userflow-element-avatar-menu-account" text="Account" to="/settings/account" />
        <MenuItem text="Organisation" to="/settings/org" />
        {showAdminActions && <MenuItem text="Admin actions" to="/admin-actions" />}
        <Dropdown.Divider />
        {/* <Dropdown.Item text="Group Training" as={NavLink} target="_blank" to={{ pathname: "https://seerdata.ai/platform_training" }} /> */}
        <MenuItem text="Support" target="_blank" to={{ pathname: "https://knowledge.seerdata.ai" }} />
        <MenuItem text="What's New" target="_blank" to={{ pathname: "https://seerdata.ai/february-2024-new-features-and-improvements/" }} />
        <MenuItem text="Upgrade Plan" onClick={() => {
          store!.handleUpgradeShow(true);
          getMixpanel(store!).track("Upgrade Plan Click");
        }} />
        <Dropdown.Divider />
        <MenuItem text="Sign Out" onClick={() => store!.logout()} />
      </Dropdown.Menu>
    </StyledHeaderDropdown>
  );
};

export const AvatarDropdown = inject("store")(observer(AvatarDropdownComponent));
