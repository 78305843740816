import * as React from "react";
import { toJS } from "mobx";
import { insertScript } from "common/helpers/script";
import { CustomImageLibrary, imageUploadHandler } from "./CustomImageLibrary";
import { InsightPickerModal } from "./InsightPickerModal";
import { FilterPickerModal } from "./FilterPickerModal";
import { ObjectAny } from "common/helpers/types";
import insightTool, { insightToolConfig, designUpdateInsightRefreshHandler } from "./includes/customJsInsightTool";
import filterTool, { toolName as filterToolName } from "./includes/customJsFilterTool";
import customJsPostEvents from "./includes/customJsPostEvents";
import { webUrl } from "common/constants";
import { dashboardUnlayerCustomCSS } from "./includes/dashboardUnlayerCustomCSS";
import { COLORS } from "component/UI/common";
import { customFonts } from "./includes/customFonts";

interface ComponentProps {
  config: ObjectAny | undefined;
  token: string;
  user?: ObjectAny;
  dashID: string;
  onReady?: () => any;
}

export class DashboardUnlayerEditor extends React.Component<ComponentProps> {
  editor: any = null;

  state: any = {
    isCustomImageLibraryOpen: false,
    unlayerImageDoneFn: null,
    insightModalOpen: false,
    insightModalEventData: null,
    filterModalOpen: false,
    filterModalEventData: null,
    filterModalOptions: null,
  };

  customToolMessageHandler = (event: MessageEvent): any => {
    if (event.origin === "https://editor.unlayer.com" && event.data?.insightTool) {
      this.setState({ insightModalOpen: true, insightModalEventData: event.data?.insightTool });
    } else if (event.origin === "https://editor.unlayer.com" && event.data?.toolName === filterToolName) {
      const { nodeId, toolName, filterData } = event.data;
      this.setState({
        filterModalOpen: true,
        filterModalEventData: { nodeId, toolName },
        filterModalOptions: filterData || null,
      });
    }
  };

  unLayerInit = async (): Promise<void> => {
    const { user, dashID } = this.props;
    // const isSeerUser = environment !== "production" || user?.group.id === 102;
    const userAddOns = (user?.group.add_ons || []).map((add_on) => add_on.name);
    const enabledTools = [insightTool()];
    if (userAddOns.includes("Dashboard Filters")) {
      enabledTools.push(filterTool());
    }
    const customJS = [...enabledTools, customJsPostEvents];
    // insert unlayer on mount
    await insertScript("unlayer", { type: "text/javascript", src: "https://editor.unlayer.com/embed.js" });
    this.editor = window["unlayer"].createEditor({
      id: "dashboard-unlayer-editor",
      projectId: 69944,
      displayMode: "web",
      customJS,
      customCSS: [dashboardUnlayerCustomCSS],
      tabs: {
        blocks: {
          enabled: false,
        },
        images: {
          enabled: false,
        },
      },
      features: {
        stockImages: { enabled: false },
        userUploads: false,
        textEditor: {
          tables: true,
          inlineFontControls: true,
          spellChecker: true,
        },
        pageAnchors: true,
      },
      tools: {
        ...insightToolConfig,
        form: {
          enabled: !!user?.form_terms_accepted,
          properties: {
            fields: {
              editor: {
                data: {
                  allowAddNewField: true,
                },
              },
            },
            action: {
              editor: {
                data: {
                  allowCustomUrl: true,
                  actions: [
                    {
                      label: "Seer Data Forms",
                      method: "GET",
                      target: "_blank",
                      url: `${webUrl}/form-submission/dashboard/${dashID}`,
                    },
                  ],
                },
              },
            },
          },
        },
        button: {
          properties: {
            buttonColors: {
              value: {
                backgroundColor: COLORS.indigo500,
                hoverBackgroundColor: COLORS.indigo600,
              },
            },
          },
        },
        image: {
          properties: {
            src: {
              value: {
                url: `${webUrl}/assets/unlayer_placeholder.jpg`,
              },
            },
          },
        },
        carousel: {
          enabled: false,
        },
      },
      fonts: {
        showDefaultFonts: true, // set to true (default), just in case a URL is set / non browser font is added by Unlayer embed
        customFonts,
      },
    });
    this.editor.addEventListener("editor:ready", () => {
      if (this.props.config) {
        this.editor.loadDesign(toJS(this.props.config.json));
      } else {
        this.editor!.loadBlank({
          backgroundColor: "#ffffff",
        });
      }
    });
    this.editor!.addEventListener("design:loaded", () => {
      if (this.props.onReady) {
        this.props.onReady();
      }
      if (!this.props.config) {
        this.editor!.setBodyValues({
          contentWidth: "100%",
        });
      }
    });
    this.editor!.registerCallback("selectImage", (_data, done) => {
      this.setState({ isCustomImageLibraryOpen: true });
      this.setState({ unlayerImageDoneFn: (args) => done(args) });
    });
    designUpdateInsightRefreshHandler(this.editor!);
    // register event listener to pick up custom tool messages from iframe
    window.addEventListener("message", this.customToolMessageHandler, false);
  };

  componentDidMount(): void {
    this.unLayerInit();
  }

  componentWillUnmount(): void {
    window.removeEventListener("message", this.customToolMessageHandler, false);
  }

  render(): JSX.Element {
    const {
      isCustomImageLibraryOpen,
      unlayerImageDoneFn,
      insightModalOpen,
      insightModalEventData,
      filterModalOpen,
      filterModalEventData,
      filterModalOptions,
    } = this.state;
    return (
      <div className="h-100">
        {/* Unlayer Custom Image Upload Modal */}
        <CustomImageLibrary
          isOpen={isCustomImageLibraryOpen}
          done={unlayerImageDoneFn}
          close={() => this.setState({ isCustomImageLibraryOpen: false })}
          handleUpload={imageUploadHandler(this.props.token)}
        />
        <InsightPickerModal
          editorRef={this.editor}
          isOpen={insightModalOpen}
          data={insightModalEventData}
          close={() => this.setState({ insightModalOpen: false, insightModalEventData: null })}
        />
        <FilterPickerModal
          editorRef={this.editor}
          isOpen={filterModalOpen}
          data={filterModalEventData}
          dashFilterOptions={filterModalOptions}
          close={() => this.setState({ filterModalOpen: false, filterModalEventData: null, filterModalOptions: null })} // close modal
        />
        <div id="dashboard-unlayer-editor" className="h-100" />
      </div>
    );
  }
}
