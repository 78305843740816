import * as React from "react";
import { Label, SemanticCOLORS } from "semantic-ui-react";

export interface NavItemProps {
  datasetCode: string;
  datasetName: string;
  datasetDimensions: [string];
}

export type TypedVars = Record<string, { variables: any; color: SemanticCOLORS }>;

export const DatasetDisplay = ({ datasetName, datasetDimensions }) => {
  const topics = datasetDimensions.filter(v => v.type === "What");
  const quantities = datasetDimensions.filter(v => v.type === "How");
  const locations = datasetDimensions.filter(v => v.type === "Where");
  const durations = datasetDimensions.filter(v => v.type === "When");

  const typedVars: TypedVars = {
    Topics: { variables: topics, color: "olive" },
    "Measured Quantities": { variables: quantities, color: "green" },
    Locations: { variables: locations, color: "teal" },
    Durations: { variables: durations, color: "blue" },
  };

  return (
    <>
      <h4>{datasetName}</h4>

      {Object.entries(typedVars).map(([type, vSet]) =>
        vSet.variables.length > 0 ? (
          <>
            <Label size="small" color={vSet.color} className="mt-3 mb-3">
              {type}
            </Label>

            {vSet.variables.map(v => (
              <p className="fs-0750">{v.variable}</p>
            ))}
          </>
        ) : (
          <></>
        )
      )}
    </>
  );
};
