import * as React from "react";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import Store from "../common/store";
import { isPublicURL } from "common/helpers/route";

export default function Protected(Component: any): any {
  @inject("store")
  @observer
  class AuthenticatedComponent extends React.Component<{ store: Store; location: any }, any> {
    store = this.props.store;

    render() {
      const isPublic = isPublicURL();

      return (
        <div>
          {this.store.user || isPublic ? (
            <Component {...this.props} />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />
          )}
        </div>
      );
    }
  }

  return AuthenticatedComponent;
}
