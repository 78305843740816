import * as React from "react";
import { Button, Modal } from "semantic-ui-react";

interface IPartnershipConfirmModal {
  isOpen: boolean;
  closeModal: () => void;
  confirmModal: () => void;
  header: string;
  content: string;
  confirmButtonText: string;
}

export const PartnershipConfirmModal = ({
  isOpen,
  closeModal,
  confirmModal,
  header,
  content,
  confirmButtonText,
}: IPartnershipConfirmModal): JSX.Element => {
  const confirmHandler = () => {
    confirmModal();
    closeModal();
  };

  return (
    <Modal open={isOpen} onClose={closeModal} closeOnDimmerClick={false} size="small">
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <p className="fs-1250">{content}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal}>Cancel</Button>
        <Button color="purple" onClick={confirmHandler}>
          {confirmButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
