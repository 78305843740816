// dashboard page common helpers

import { customFonts } from "component/Unlayer/includes/customFonts";

export const getPageTitles = (unlayer_config) => {
  if (!unlayer_config) {
    return [];
  } else if (unlayer_config.navigation?.titles) {
    return unlayer_config.navigation.titles;
  }
  return unlayer_config.pages.map((_data, idx) => `Page ${idx + 1}`);
};

export type Spacing = number | { top: number; left: number; bottom: number; right: number };

// Get the max width percentage based on the number of visible pages
export const getMaxImageWidthPercent = (navigation: DashboardNavigationConfig): number =>
  Math.floor(100 / (navigation.includedPages.filter((page) => page).length || 1));

export interface ItemsStyle {
  useBorder?: boolean;
  borderWidth?: number; // px width
  borderColor?: string; // hex code
  borderRadius?: number; // px width only, no percentages for now
  color?: string; // hex code
  background?: string; // hex code
}

export interface DashboardNavigationConfig {
  titles: string[];
  includedPages: boolean[];
  customNav: boolean;
  customNavConf: {
    showInbuiltControls: boolean;
    position: "relative" | "sticky" | "custom";
    styling: {
      layout: "horizontal" | "vertical";
      alignment: "left" | "center" | "right" | "flex between";
      padding: Spacing;
      background?: string; // hex code
      images: {
        default: string; // src
        hover?: string; // src - active/hover
      }[];
      imageWidth?: number; // px
      imageHeight?: number; // px
      imageWidthPercent?: number; // %
      imageWidthMax?: number; // px
      items: {
        padding: Spacing;
        margin: Spacing;
        font: {
          value: string;
          url: string;
          fontSize: number; // px
          fontWeight: number;
        };
        default: ItemsStyle;
        hover: ItemsStyle; // hover / active state
      };
      maxWidthType?: "px" | "%";
      maxWidth?: number;
    };
  };
}

export interface UnlayerConfig {
  pages: {
    json: any;
    html: string;
    id?: number;
  }[];
  navigation?: DashboardNavigationConfig;
}

export interface DashFilterOptions {
  styling: {
    label: {
      fontFamily: string;
      fontWeight: number;
      fontSize: number;
      fontUrl: string;
    };
    padding: Spacing;
    border: {
      width: Spacing;
      radius: Spacing;
      color: string;
      colorHover?: string;
    };
    tag: {
      colorText: string;
      colorBg: string;
    };
  };
}

export const navFonts = [{ label: "Open Sans", value: "Open Sans", url: "none" }, ...customFonts];
export const navFontsSemanticOpts = navFonts.map((font) => ({ ...font, text: font.label, label: undefined }));

export const fontWeightSemanticOpts = [
  { text: "Light", value: 300 },
  { text: "Normal", value: 400 },
  { text: "Bold", value: 700 },
  { text: "Extra Bold", value: 800 },
];

export const getSpacingString = (spacing: Spacing) =>
  typeof spacing === "number" ? `${spacing}px` : `${spacing.top}px ${spacing.right}px ${spacing.bottom}px ${spacing.left}px`;
