import styled from "styled-components";

export const BottomLeftContent = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 1000;

  > *:nth-child(n + 2) {
    margin-left: 0.5rem;
  }
`;

export const ContentBox = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-clip: padding-box;
  background-color: #fff;
  padding: 0.5rem;
`;
