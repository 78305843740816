// https://styled-components.com/docs/advanced
// for use with theme provider
// @TODO - check over whether we can use this better once we get most components using semantic ui
export const Theme = {
  color: {
    heading: "#D71D4B",
    label: "#5F2D5F",
    background: "rgba(51, 51, 51, 0.05)",
    aside: "#2D2D2D",
    text: "#322e3b",
    hr: "#35313d",
    hover: "#923459",
    list: "#333333",
    graph: "#716f76",
    focus: "#2684FF",
    comment: "#f5f5f5",
    rectangles: {
      default: "#5F2D5F",
      selected: "#c32d2d",
      green: "#16a095",
      pink: "#e585ab",
      grey: "#8b9cb7",
    },
  },
  font: {
    weight: {
      light: "300",
      semiBold: "600",
      extraBold: "700",
    },
    family: "'Open Sans', sans-serif",
    size: {
      xxSmall: "12px",
      xSmall: "13px",
      small: "15px",
      medium: "18px",
      large: "20px",
      mLarge: "30px",
      xLarge: "40px",
      xxLarge: "60px",
    },
    lineHeight: {
      small: "1.1",
      medium: "1.3",
    },
  },
};
