import styled from "styled-components";

export const VTitle = styled.div`
  background: #919191;
  border-left: 1px solid #c3c3c3 !important;
  border-right: 1px solid #c3c3c3 !important;
  width: 100%;
  color: white;
  padding: 10px 10px 10px 12.5px;
  line-height: 15px;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  z-index: -1;
`;
