import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Select from "react-select";
import Store from "common/store";
import { datasets as dataApi } from "common/api";
import { Icon } from "semantic-ui-react";
import { StylePopup } from "component/insightBuilder/insightBuilderStyles/whereWhatWhen.style";
import { customReactSelectStyles } from "common/helpers/dataset";

interface IDatasetAccessCategory {
  key: string;
  variable: any;
  initialCategories?: any;
  datasetKey: string;
  removeHandler: () => void;
  selectHandler: (accessLimitObj) => void;
  store?: Store;
}

const DatasetAccessCategoryComponent = ({ key, variable, initialCategories, datasetKey, removeHandler, selectHandler, store }: IDatasetAccessCategory): JSX.Element => {
  const [categoryOptions, setCategoryOptions] = React.useState([]);
  const [isCategoryOptionsLoading, setIsCategoryOptionsLoading] = React.useState<boolean>(true);
  const [selectedCategories, setSelectedCategories] = React.useState<any>([]);

  const loadCategories = async () => {
    const res: any = await dataApi.post("v2/qs", {
      dataset: datasetKey,
      filters: { [variable]: [] },
    }, store!.token!);
    if (res.body) {
      const categories = res.body.data.dataset.dimensions
        .find(dim => dim.name === variable).values
        .map(value => value.name);
      const formattedCategories = categories.map(category => ({
        key: category,
        label: category,
        value: category,
      }));
      setCategoryOptions(formattedCategories);
      setIsCategoryOptionsLoading(false);
    }
  };

  React.useEffect(() => {
    loadCategories();
    setSelectedCategories(initialCategories);
  }, [variable, initialCategories]);

  return (
    <div key={key} className="mb-3">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h5 className="mb-0 text-secondary">{variable}</h5>
        <StylePopup
          trigger={
            <div className="d-flex text-primary align-items-center cursor-pointer" onClick={removeHandler}>
              <p className="fs-0875 mt-2 mb-0 fw-700">Remove variable</p><Icon name="trash" className="ml-1 mr-0" />
            </div>
          }
          content="Removing this variable will make it fully accessible again."
          position="bottom center" size="mini" inverted
        />
      </div>
      <Select
        isClearable={true}
        styles={customReactSelectStyles}
        placeholder="Search and select categories"
        options={categoryOptions}
        isMulti
        isLoading={isCategoryOptionsLoading}
        isDisabled={isCategoryOptionsLoading}
        defaultValue={initialCategories || null}
        onChange={(option) => {
          let selectedCategoryOptions: any[] = [];
          if (option && option.length > 0) {
            selectedCategoryOptions = option.map((cat: any) => cat.value);
          }
          const variableAccessLimit = { [variable]: selectedCategoryOptions };
          selectHandler(variableAccessLimit);
          setSelectedCategories(variableAccessLimit);
        }}
      />
      {selectedCategories.length === 0 && <p className="mt-1 text-dark">No access to this variable</p>}
    </div>
  );
};

export const DatasetAccessCategory = inject((stores: any) => ({
  store: stores.store,
}))(observer(DatasetAccessCategoryComponent));
