import * as React from "react";
import { observer } from "mobx-react";
import ReactCrop, { Crop } from "react-image-crop";
import styled from "styled-components";
import { Button, Icon, Modal } from "semantic-ui-react";

const StyleCropModal = styled(Modal)`
  @media only screen and (max-width: 767px) {
    &.ui.modal > .actions {
      padding: 14px !important;
    }
  }
`;

interface IState {
  crop: Crop;
  croppedImageDataUrl: any;
}

@observer
export default class CropImage extends React.Component<{
  src: any;
  vis: boolean;
  handleVis: () => void;
  handleUpload: (dataUrl?) => void;
}> {
  state: IState = {
    crop: {
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      unit: "px",
      aspect: 0,
    },
    croppedImageDataUrl: null,
  };
  imageRef: any = null;

  // Center crop based on the loaded image
  onImageLoaded = (image: HTMLImageElement): boolean => {
    this.imageRef = image;
    const { width, height } = image;

    const aspect = 1 / 1;
    const widthPercent = width / aspect < height * aspect ? 1 : (height * aspect) / width;
    const heightPercent = width / aspect > height * aspect ? 1 : width / aspect / height;
    const yPercent = (1 - heightPercent) / 2;
    const xPercent = (1 - widthPercent) / 2;
    // Need to pass "px" unit crop to onCropComplete function to get the correct cropped image
    this.setState(
      {
        crop: {
          width: width * widthPercent,
          height: height * heightPercent,
          x: width * xPercent,
          y: height * yPercent,
          unit: "px",
          aspect,
        },
      },
      () => {
        this.onCropComplete(this.state.crop);
      },
    );

    return false;
  };

  onCropComplete = (crop: Crop): void => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop: Crop): void => {
    this.setState({ crop });
  };

  async makeClientCrop(crop: Crop): Promise<void> {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageDataUrl = await this.getCroppedImg(this.imageRef, crop);
      this.setState({ croppedImageDataUrl });
    }
  }

  getCroppedImg(image: HTMLImageElement, crop: Crop): string {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx!.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

    return canvas.toDataURL("image/jpeg");
  }

  render(): JSX.Element {
    const { crop, croppedImageDataUrl } = this.state;
    const { src, vis, handleVis, handleUpload } = this.props;
    return (
      <StyleCropModal closeIcon onClose={handleVis} open={vis}>
        <Modal.Content scrolling>
          <div className="d-flex align-items-center justify-content-center">
            <ReactCrop
              src={src}
              crop={crop}
              minWidth={160}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleVis}>
            Cancel <Icon name="close" className="ml-1 mr-0" />
          </Button>
          <Button color="purple" onClick={() => handleUpload(croppedImageDataUrl)}>
            Save <Icon name="save" className="ml-1 mr-0" />
          </Button>
        </Modal.Actions>
      </StyleCropModal>
    );
  }
}
