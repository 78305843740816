import * as React from "react";
import { observer } from "mobx-react-lite";
// import Store from "common/store/insightbuilder";
import { ExploreTabItem } from "./includes/ExploreTabItem";
import { ExploreChartType } from "./includes/ExploreChartType";
import { ExploreChartBasic } from "./includes/ExploreChartBasic";
import { ExploreChartAdvanced } from "./includes/ExploreChartAdvanced";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { Builder } from "common/store/builder";
import { getMixpanel } from "common/api";
import { inject } from "mobx-react";
import { withColorApplicator } from "component/ColorPaletteWidgets/withColorApplicator";
import { uniqueId } from "lodash";
import { ExploreColorApplicatorUI } from "./includes/ExploreColorApplicatorUI";
import { Legend } from "recharts";
import { MetricChartLegend } from "component/Charts/Legend/MetricChartLegend";
import { GeneralChartLegend } from "component/Charts/Legend/GeneralChartLegend";
import { getCustomChartColorNumber } from "common/helpers/chart";

interface IExploreChartTab {
  builder: Builder;
  colorPalette?: any;
  applicator: any;
}

interface IChartTabItems {
  icon: SemanticICONS;
  label: string;
  active: boolean;
  position?: "top" | "middle" | "bottom";
  showIndicator?: boolean;
  folded?: boolean;
  disabled?: boolean;
}

const ExploreChartTabComponent = ({ builder, colorPalette, applicator }: IExploreChartTab): JSX.Element => {
  const { leftTabFolded } = builder.ui;
  const { chart, chartXAxisArray, chartKey, chartTables, columns, chartSeries, chartLegend } = builder;
  const isMetricChart = chart.type.includes("Metric");
  const [currentTab, setCurrentTab] = React.useState("chart");

  React.useEffect(() => {
    // const { applicator } = this.props;
    applicator.updateCanvas((builder.canvas || []).map((color) => ({ color, id: uniqueId() })));
    applicator.updateSelectedBrush(builder.selectedBrush || "cycle");
  }, []);

  // @TODO - re-enable when we update mobx, annoying that it keeps breaking hooks
  // React.useEffect(() => {
  //   // reset selected palette on settings palette create/delete
  //   applicator.updateSelectedPalette(0);
  // }, [colorPalette.allColorPalettes.length]);

  React.useEffect(() => {
    builder.canvas = applicator.getCanvasColors();
  }, [applicator.getCanvasColors()]);

  React.useEffect(() => {
    builder.selectedBrush = applicator.selectedBrush;
  }, [applicator.selectedBrush]);

  const handleChangeTab = (value) => {
    setCurrentTab(value);
    builder.ui.setLeftTabFolded(false);
  };

  const applyBrush = () => {
    if (builder.chartKey.values.length > 0) {
      // Update customChartColors
      const colorNumber = getCustomChartColorNumber(chart.type, chartXAxisArray, chartKey, chartTables, columns, chartSeries, chartLegend);
      const colors = applicator.generateColors([colorNumber]);
      builder.setCustomChartColors(colors.reduce((pre, cur) => pre.concat(cur), []));
    }
    getMixpanel(builder.parent).track("Chart > Applied Colours");
  };

  const chartTabItems: IChartTabItems[] = [
    {
      icon: "cogs",
      label: "chart",
      active: currentTab === "chart",
      position: "top",
      folded: leftTabFolded,
    },
    {
      icon: "pie graph",
      label: "type",
      active: currentTab === "type",
    },
    {
      icon: "text height",
      label: "custom",
      active: currentTab === "custom",
    },
    {
      icon: "paint brush",
      label: "colour",
      active: currentTab === "colour",
    },
    {
      icon: "list",
      label: "legend",
      active: currentTab === "legend",
    },
  ];

  return (
    <>
      {/* Tab container */}
      <div className="d-flex align-items-stretch" style={{ height: "calc(100vh - 157px)", minHeight: 485 }}>
        {/* Tab items */}
        <div
          id="userflow-element-explore-data-chart-tabs"
          className="position-relative bg-hover-grey"
          style={{
            minWidth: 82,
            borderRight: leftTabFolded ? "1px solid rgba(0, 0, 0, 0)" : "1px solid #E5E5E5",
          }}
        >
          {chartTabItems.map((item, index) => {
            const { icon, label, active, position, folded, disabled } = item;
            return (
              <ExploreTabItem
                key={index}
                icon={icon}
                label={label}
                active={active}
                position={position && position}
                folded={folded && folded}
                onClick={() => handleChangeTab(label)}
                disabled={disabled}
              />
            );
          })}
        </div>
        {/* Tab content */}
        {leftTabFolded ? null : (
          <div className="flex-sm-grow-1 overflow-auto" style={{ padding: 20, minWidth: 317 }}>
            {currentTab === "chart" && <ExploreChartAdvanced />}
            {currentTab === "type" && <ExploreChartType />}
            {currentTab === "custom" && <ExploreChartBasic />}
            {currentTab === "colour" && (
              <ExploreColorApplicatorUI
                allColorPalettes={colorPalette.allColorPalettes}
                applicator={applicator}
                onApplyBrush={applyBrush}
              />
            )}
            {currentTab === "legend" && (
              <Legend
                align="left"
                wrapperStyle={{ position: "static" }}
                content={isMetricChart ? <MetricChartLegend edit={true} /> : <GeneralChartLegend edit={true} />}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export const ExploreChartTab = withColorApplicator(
  inject((stores: any) => ({
    builder: stores.store.builder,
    colorPalette: stores.store.colorPalette,
  }))(observer(ExploreChartTabComponent)),
);
