import styled from "styled-components";
import { Modal, Card, List, Grid } from "semantic-ui-react";
import { $white, $shadow, $darkgrey, $warning, $opensans, $radius } from "../../../common/styledComponents/_config.style";
import { COLORS } from "component/UI/common";

export const StyleUpgradeModal = styled(Modal)`
  padding: 40px;
  @media (max-width: 1200px) {
    i.close.icon::before {
      cursor: pointer;
      position: absolute;
      color: ${$darkgrey};
      left: -30px;
      top: 50px;
    }
  }
  @media (max-width: 992px) {
    i.close.icon::before {
      left: 20px;
      top: -5px;
    }
  }
  @media (max-width: 768px) {
    padding: 20px;
    i.close.icon::before {
      left: 15px;
      top: 5px;
    }
  }
`;

export const StyleUpgradeContent = styled(Modal.Content)`
  height: auto !important;
  overflow-y: auto !important;
`;

export const StylePlanCard = styled(Card)`
  padding: 20px !important;
  min-height: 440px !important;
  width: 100% !important;
  @media (max-width: 960px) {
    min-height: auto !important;
  }
`;

export const StylePlanCardContent = styled(Card.Content)``;

export const StylePlanCardHeader = styled(Card.Header)`
  font-family: ${$opensans} !important;
  font-weight: bold !important;
  font-size: 26px !important;
  color: ${COLORS.indigo600} !important;
`;

export const StylePlanCardDescription = styled(Card.Description)`
  font-family: ${$opensans} !important;
  font-size: 16px !important;
  color: ${COLORS.indigo600} !important;
`;

export const StyleGrid = styled(Grid)`
  @media (max-width: 960px) {
    &.ui.grid > [class*="three column"].row > .column {
      width: 100% !important;
    }
  }
`;

export const StyleGridRow = styled(Grid.Row)`
  @media (max-width: 960px) {
    flex-direction: column !important;
    gap: 40px;
  }
  @media (max-width: 768px) {
    gap: 20px;
  }
`;

export const StyleGridColumn = styled(Grid.Column)``;

export const StyleTagCurrent = styled.div`
  font-family: ${$opensans} !important;
  font-size: 12px !important;
  background-color: #ababab;
  padding-left: 8px;
  padding-right: 8px;
  display: inline-box !important;
  height: 28px;
  border-radius: 4px;
  line-height: 28px;
  margin-left: 10px;
  color: ${$white} !important;
  vertical-align: middle;
`;

export const StyleTagRecommended = styled(StyleTagCurrent)`
  background-color: ${$warning};
`;

export const StyleList = styled(List)`
  font-family: ${$opensans} !important;
  font-size: 14px !important;
  color: #444444 !important;
  margin-bottom: 50px !important;
  @media (max-width: 768px) {
    margin-top: 0px !important;
  }
`;

export const StyleListItem = styled(List.Item)`
  padding: 5px 0 !important;
  .icon {
    color: ${$warning};
  }
`;

export const StyleButton = styled.button`
  cursor: pointer;
  width: auto;
  height: 40px;
  border-radius: ${$radius};
  font-family: ${$opensans};
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: ${$white};
  background: ${COLORS.red500};
  box-shadow: ${$shadow};
  border: none;
  position: absolute;
  bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const StyleCTAWrapper = styled.div`
  margin: 40px 0;
  text-align: center;
`;

export const StyleCTAHeading = styled.div`
  font-family: ${$opensans} !important;
  font-weight: bold !important;
  font-size: 22px !important;
  color: ${COLORS.indigo600} !important;
  padding: 10px 0 !important;
  display: inline-block;
  margin-right: 20px;
`;

export const StyleCTAButton = styled(StyleButton)`
  background: ${COLORS.indigo600};
  position: static;
  display: inline-block !important;
`;

export const StyleLogoWrapper = styled.div`
  text-align: center;
`;
