import * as React from "react";
import { components } from "react-select";
import { Label } from "semantic-ui-react";
import { COLORS } from "component/UI/common";

export const customSearchInsightStyles = {
  option: (base, state) => ({
    ...base,
    background: state.isSelected ? "#e5e5e5" : state.isFocused ? "#f2f2f2" : "white",
    color: "#2d2d2d",
  }),
  control: (base, state) => ({
    ...base,
    border: `1px solid ${state.isFocused ? COLORS.indigo600 : "#cccccc"}`,
    boxShadow: "none",
    "&:hover": {
      border: `1px solid ${COLORS.indigo600}`,
    },
  }),
};

export const CustomSingleValue = (props): JSX.Element => {
  const { key, label } = props.getValue()[0];
  return (
    <components.SingleValue {...props}>
      <Label className="mr-2">{`#${key}`}</Label>
      <span>{label}</span>
    </components.SingleValue>
  );
};

export const CustomOption = (props): JSX.Element => {
  const { key, label } = props.data;
  return (
    <components.Option {...props}>
      <Label className="mr-2">{`#${key}`}</Label>
      <span>{label}</span>
    </components.Option>
  );
};

export const CustomMenu = (props): JSX.Element | null => {
  const { hasValue, options, selectProps } = props;
  // Hide option container when:
  // - an insight is selected
  // - or the input is focused without any letters being typed
  if (hasValue || (!options.length && !selectProps.inputValue)) {
    return null;
  }
  return <components.Menu {...props} />;
};
