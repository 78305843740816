import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import SmoothCollapse from "react-smooth-collapse";
import { Header, Icon, Label, Table } from "semantic-ui-react";
import { uniq } from "lodash";
import { dropdownTransition } from "common/helpers/style";
import { Collapsible } from "component/Collapsible";
import { calcToFormula, getMissingFallback } from "common/helpers/explore";
import styled from "styled-components";

interface ITableSource {
  builder?: any;
}

const StyledTable = styled(Table)`
  background: none !important;
  thead > tr {
    background-color: rgba(0, 0, 0, 0.05) !important;
  }
  th,
  tr,
  td {
    background: none !important;
  }
`;

// Used for table rows grouped by column/row/filter
const SourceTableRows = ({ variables, rowTitle }): JSX.Element => (
  <>
    {variables.map((variable, index) => {
      const { dimension, values } = variable;
      return (
        <Table.Row>
          {index === 0 && (
            <Table.Cell className="text-secondary fw-700" rowSpan={variables.length}>
              {rowTitle}
            </Table.Cell>
          )}
          <Table.Cell className="fw-600">{dimension}</Table.Cell>
          <Table.Cell>
            {values.map((value) => (
              <Label key={value} className="mb-1 ml-0 mr-1 fw-600 bg-light text-dark" circular>
                {value}
              </Label>
            ))}
          </Table.Cell>
        </Table.Row>
      );
    })}
  </>
);

interface ITableFormula {
  formulaItems: string[];
  inverted?: boolean;
}

export const TableFormula = (props: ITableFormula): JSX.Element => {
  const { formulaItems, inverted } = props;
  return (
    <div className="d-flex align-items-center flex-wrap">
      {formulaItems.map((item, idx) => {
        if (item.startsWith("R")) {
          return (
            <Label
              key={`${idx}-${item}`}
              className={`ml-0 mr-1 fw-600 mb-1 ${inverted ? "bg-muted text-light" : "bg-light text-dark"} `}
              circular
            >
              {item}
            </Label>
          );
        } else {
          return (
            <p key={`${idx}-${item}`} className={`${inverted ? "text-light" : "text-dark"} fw-600 mr-1 mb-1`}>
              {item}
            </p>
          );
        }
      })}
    </div>
  );
};

const TableSourceComponent = ({ builder }: ITableSource): JSX.Element => {
  const { allDatasets, tables, downloadingChart, calcMissingFallback, calcMissingFallbackValue } = builder!;
  const resultTables = tables.filter((table) => table.type === "result");
  const tableDatasetKeys = uniq(resultTables.reduce((prev, curr) => prev.concat(...curr.userSelectedDatasets), []));
  // Get table datasets info
  const tableDatasets = allDatasets
    .filter((dataset) => tableDatasetKeys.indexOf(dataset.key) >= 0)
    .map((dataset) => ({ key: dataset.key, name: dataset.name }));

  React.useEffect(() => {
    if (allDatasets.length === 0) {
      builder!.getAllDatasets();
    }
  }, []);

  return (
    <Collapsible
      render={({ isOpen, setIsOpen }) => (
        <div className="fs-0875 mb-4 text-left mt-4">
          <div
            className="d-flex align-items-center cursor-pointer mb-2"
            style={{ width: "fit-content" }}
            onClick={() => {
              if (downloadingChart) {
                builder.setDownloadingChart(false);
              } else {
                setIsOpen(!isOpen);
              }
            }}
          >
            <p className="mb-0 mr-2 fs-1000 fw-600">Source</p>
            <Icon name={downloadingChart || isOpen ? "angle up" : "angle down"} style={{ marginTop: 2 }} />
          </div>
          <SmoothCollapse expanded={downloadingChart || isOpen} heightTransition={dropdownTransition}>
            {tableDatasets.map((dataset) => {
              const { key, name } = dataset;
              return (
                <div key={key} className="mb-3">
                  <Header as="h5" color="purple" className="my-1">
                    Dataset:{" "}
                  </Header>
                  <p className="text-dark mb-2 fw-600">{name}</p>
                  <Header as="h5" color="purple" className="my-1">
                    Dataset Key:{" "}
                  </Header>
                  <p className="text-dark mb-0 fw-600">{key}</p>
                </div>
              );
            })}
            {tables.map((table) => {
              if (table.type === "result") {
                const { name, id, columns, rows, filters } = table;
                return (
                  <div className="mb-4" key={`${id}-${name}`}>
                    <Header as="h5" color="purple" className="mt-0">
                      {name}
                    </Header>
                    <StyledTable celled structured>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell />
                          <Table.HeaderCell className="text-secondary">Variables</Table.HeaderCell>
                          <Table.HeaderCell className="text-secondary">Categories</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <SourceTableRows variables={columns} rowTitle="Columns" />
                        <SourceTableRows variables={rows} rowTitle="Rows" />
                        <SourceTableRows variables={filters} rowTitle="Filters" />
                      </Table.Body>
                    </StyledTable>
                  </div>
                );
              } else {
                const { name, id } = table;
                return (
                  <div className="mb-4" key={`${id}-${name}`}>
                    <Header as="h5" color="purple" className="mt-0">
                      {name}
                    </Header>
                    <TableFormula formulaItems={calcToFormula(table, tables)} />
                  </div>
                );
              }
            })}
            {typeof getMissingFallback(calcMissingFallback, calcMissingFallbackValue) === "number" && (
              <div className="d-flex">
                <Icon name="info circle" className="mr-2 mt-1" />
                <p className="fs-1125">
                  <span>The calculations in this Insight rely on a fallback value of </span>
                  <span>
                    <b>{calcMissingFallbackValue}</b>
                  </span>
                  <span> which has been applied to missing or null data points in this Insight.</span>
                </p>
              </div>
            )}
          </SmoothCollapse>
        </div>
      )}
    />
  );
};

export const TableSource = inject((stores: any) => ({
  builder: stores.store.builder,
}))(observer(TableSourceComponent));
