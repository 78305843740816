export const customFonts = [
  {
    label: "BeatLight",
    value: "Beatlight",
    url: "https://seer-media.s3.ap-southeast-2.amazonaws.com/content-assets/fonts/beatlight.css",
  },
  {
    label: "Barlow",
    value: "Barlow",
    url: "https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap",
  },
  {
    label: "Jost",
    value: "Jost",
    url: "https://fonts.googleapis.com/css2?family=Jost:wght@500;700&display=swap",
  },
  {
    label: "Alber New",
    value: "alber-new-web",
    url: "https://use.typekit.net/ikw1ykh.css",
  },
  {
    label: "Verveine",
    value: "verveine",
    url: "https://use.typekit.net/ygg2glg.css",
  },
  {
    label: "Motley Forces",
    value: "Motley Forces",
    url: "https://seer-media.s3.ap-southeast-2.amazonaws.com/content-assets/fonts/motley_forces.css",
  },
  {
    label: "Ropa Sans",
    value: "Ropa Sans",
    url: "https://fonts.googleapis.com/css2?family=Ropa+Sans:ital@0;1&display=swap",
  },
  {
    label: "Caveat Brush",
    value: "Caveat Brush",
    url: "https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap",
  },
  {
    label: "Poppins",
    value: "Poppins",
    url: "https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap",
  },
  {
    label: "Cardo",
    value: "Cardo",
    url: "https://fonts.googleapis.com/css2?family=Cardo:wght@400;700&display=swap",
  },
  {
    label: "Roboto",
    value: "Roboto",
    url: "https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap",
  },
  {
    label: "Fira Sans",
    value: "Fira Sans",
    url: "https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;400&display=swap",
  },
  {
    label: "DM Sans",
    value: "DM Sans",
    url: "https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&display=swap",
  },
];
