import * as React from "react";
import { Icon, Popup, SemanticICONS } from "semantic-ui-react";

interface IDraftName {
  name: string;
}

interface IAccessPopup {
  access: string | undefined;
}

interface INumberOnFolderIcon {
  subSuitcases: number;
}

const accessLabels = { owner: "Owner", write: "Full Access", read: "Read Only" };

export const DraftName = ({ name }: IDraftName): JSX.Element => (
  <>
    <Icon name={"pencil ruler" as SemanticICONS} className="mr-2 fs-1125" />
    {name}
  </>
);

export const DraftInfo = (): JSX.Element => (
  <>
    <div className="d-flex align-items-center text-medium">
      <Icon name="info circle" />
      <p className="fs-1000 fw-600" style={{ marginTop: "3px" }}>
        Store all your draft insights here
      </p>
    </div>
  </>
);

export const DraftLink = (): JSX.Element => (
  <>
    <p className="fs-1000 fw-800 text-secondary mt-1">
      See all draft insights
      <Icon name="arrow right" className="ml-1" />
    </p>
  </>
);

export const AccessPopup = ({ access }: IAccessPopup): JSX.Element => (
  <>
    <Popup
      trigger={<Icon name={access === "owner" ? "user" : access === "write" ? "lock open" : "eye"} />}
      content={accessLabels[access || ""]}
      position="top center"
      size="mini"
      inverted
    />
    <span className="sr-only">{accessLabels[access || ""]}</span>
  </>
);

export const StackedGridStyle = (): JSX.Element => (
  <>
    <div className="position-absolute w-100 left-0 bottom-100 d-flex flex-column align-items-center justify-content-center">
      <div
        className="bg-white"
        style={{ height: 6, width: "85%", borderRadius: "4px 4px 0 0", boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.08)" }}
      ></div>
      <div
        className="bg-white"
        style={{ height: 6, width: "95%", borderRadius: "4px 4px 0 0", boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.08)" }}
      ></div>
    </div>
    <div
      className="position-absolute left-0 w-100"
      style={{ top: 1, height: 1, borderRadius: "4px 4px 0 0", boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.25)" }}
    ></div>
  </>
);

export const NumberOnFolderIcon = ({ subSuitcases }: INumberOnFolderIcon): JSX.Element | null => {
  if (subSuitcases === 0) {
    return null;
  }

  const label = `${subSuitcases} suitcase${subSuitcases > 1 ? "s" : ""}`;
  return (
    <>
      <Popup
        trigger={
          <div className="position-relative" style={{ marginLeft: -3 }}>
            <Icon name="folder" className="mr-0 mb-1 text-secondary" size="big" />
            <div
              className="position-absolute left-0 top-0 d-flex align-items-center justify-content-center"
              style={{ width: 38, height: 34 }}
            >
              <p className="text-white fs-0875 fw-600" style={{ left: 10, top: 9 }}>
                {subSuitcases < 100 ? subSuitcases : "99+"}
              </p>
            </div>
          </div>
        }
        content={label}
        position="top center"
        size="mini"
        inverted
        style={{ marginLeft: 8 }}
      />
      <span className="sr-only">{label}</span>
    </>
  );
};
