import * as React from "react";
import { Divider, Grid } from "semantic-ui-react";
import { CHART_FONT_FAMILY, CHART_PRIMARY_COLOR } from "common/helpers/chart";

interface ILegendLayout {
  legendItems: any[];
  table: any;
  colors: string[];
  edit: boolean | undefined;
  colorSquareClickHandler: (tableID: number, colorIdxInTableColors: number, color: string, colorIdxInAllColors: number) => void;
  Wrapper: any;
  prevTablesColorsLength: number;
}

export const ColorSquare = (props) => {
  const { edit, color, onClick } = props;
  return (
    <div
      onClick={onClick}
      className={`mr-2 ${edit ? "cursor-pointer" : ""}`}
      style={{ minHeight: 16, minWidth: 16, backgroundColor: color, borderRadius: 2 }}
    ></div>
  );
};

export const getWrapperComponent = (showGridLayout: boolean | undefined) => {
  if (showGridLayout) {
    return (
      props, // Used for showing legends under chart
    ) => (
      <Grid.Column key={props.labelIdx} className="pl-0 py-1 pr-1">
        {props.render()}
      </Grid.Column>
    );
  } else {
    return (
      props, // Used for showing legends in the left sidebar in Explore
    ) => <div className="d-flex align-items-start my-1 mr-2 pl-0">{props.render()}</div>;
  }
};

export const LegendLayout = (props: ILegendLayout) => {
  const { legendItems, table, colors, edit, colorSquareClickHandler, Wrapper, prevTablesColorsLength } = props;
  return (
    <>
      {legendItems.map((item, itemIdx) =>
        item.map((label, labelIdx) =>
          labelIdx === item.length - 1 ? (
            <Wrapper
              labelIdx={labelIdx}
              render={() => (
                <div className="d-flex align-items-start">
                  <ColorSquare
                    edit={edit}
                    color={colors[itemIdx] || colors[0]}
                    onClick={() =>
                      edit ? colorSquareClickHandler(table.id as number, itemIdx, colors[itemIdx], prevTablesColorsLength + itemIdx) : null
                    }
                  />
                  <p className="fs-0750 fw-500" style={{ color: CHART_PRIMARY_COLOR }}>
                    {label.toString().includes(":::") ? label.split(":::")[1] : label}
                  </p>
                </div>
              )}
            />
          ) : itemIdx > 0 && legendItems[itemIdx - 1][labelIdx] === label ? (
            <></>
          ) : (
            <Grid.Row className="py-1">
              <div className="w-100">
                {labelIdx === 0 && <Divider className="my-2" />}
                <p className="mt-0 fs-0750 fw-700" style={{ color: CHART_PRIMARY_COLOR, fontFamily: CHART_FONT_FAMILY }}>
                  {label.toString().includes(":::") ? label.split(":::")[1] : label}
                </p>
              </div>
            </Grid.Row>
          ),
        ),
      )}
    </>
  );
};
