import * as React from "react";
import * as qs from "qs";
import { inject, observer } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ExploreMultiIndexChartResult } from "component/Explore/ExploreMultiIndexChartResult";
import Store from "common/store";
import { database, getMixpanel } from "common/api";
import styled from "styled-components";
import { ShowLoaderGlobal } from "component/LoaderGlobal";
import { filterInsight, filterInsightDynamically, isFilterDynamic, stripInvalidFilterVariables } from "./includes/helpers";
import { Icon, Popup } from "semantic-ui-react";
import { COLORS } from "component/UI/common";
import { downloadCsvFile } from "common/helpers/downloadCsvFile";
import { downloadInsightChart } from "common/helpers/chart";

const ClickableInsightTitle = styled.a`
  &:focus-visible {
    outline: 2px solid ${COLORS.blue} !important;
    outline-offset: -2px;
  }
`;

const Title = styled.h3`
  color: #303030;
`;

export const stripURLData = (url) => {
  const data: { id?: string; key?: string } = {};
  if (url) {
    const [id, key] = url.replace(/.*:\/\/.*\/insights\//, "").split("/");
    if (id && !isNaN(id)) {
      data.id = id;
      data.key = key;
    }
  }
  return data;
};

interface ComponentProps extends RouteComponentProps {
  store: Store;
}

class EmbedInsightComponent extends React.Component<ComponentProps> {
  state = {
    error: "",
    loading: true,
    iframeHeight: 0,
    showDownload: false,
  };

  componentDidMount(): void {
    this.init();
  }

  componentDidUpdate(_, prevState): void {
    const chartContainer = document.querySelector("div#chartContainer");
    if (chartContainer) {
      const nextIFrameHeight = chartContainer.scrollHeight;
      if (prevState.iframeHeight !== nextIFrameHeight) {
        // must be * targetOrigin because can be embeded on dashboard, but also within unlayer iframe on different host
        window.parent?.postMessage(
          {
            iframeHeight: nextIFrameHeight + 100,
            src: window.location.href, // needed in unlayer to match correct iframe
          },
          "*",
        );
      }
    }
  }

  init = async () => {
    const { store } = this.props;
    const { search } = this.props.location;
    const { url, filter } = qs.parse(search.slice(1) || "");
    const { id, key } = stripURLData(url);
    if (id) {
      const res: any = await database.get(`insights/${id}${key ? `/${key}` : ""}`, "", key ? undefined : store.token || undefined);
      const insight = res.body?.data?.insight;
      if (!insight) {
        if (res?.body?.error?.type === "JWT Exception") {
          store.logout();
          setTimeout(() => window.location.reload(), 2000);
          return this.setState({ error: "Session expired. Page will refresh shortly.", loading: false });
        }
        return this.setState({ error: "The owner of this insight has not granted you permission to view it.", loading: false });
      }

      // handle filter operations before loading the insight into the required stores
      let filteredInsight = insight;
      // ignore invalid filters
      if (filter) {
        const strippedFilter: any = stripInvalidFilterVariables(insight, filter as any);
        // apply query param filters to the insight json before loading it into store
        if (isFilterDynamic(insight, strippedFilter)) {
          filteredInsight = await filterInsightDynamically(insight, strippedFilter, key ? undefined : store.token || undefined);
        } else {
          filteredInsight = filterInsight(insight, strippedFilter);
        }
      }

      // The charting component is relying on NIB insights to have the data loaded into builder store
      await this.props.store.builder.loadInsight(Number(id), false, key || "", filteredInsight);
      // we still have to load the insight data into insight store to get the full data for insight name etc
      await this.props.store.insight.load(Number(id), false, key || "", true, filteredInsight);

      // after load is complete, allow enough time for render to complete and send message to parent to update iframe heights
      setTimeout(() => {
        const chartContainer = document.querySelector("div#chartContainer");
        if (chartContainer) {
          this.setState({ iframeHeight: chartContainer.scrollHeight });
        }
      }, 100);
    } else {
      this.setState({ error: "The owner of this insight has not given you permission to view it." });
    }
    this.setState({ loading: false });
  };

  downloadInsight = async (insightId) => {
    try {
      const insightName = this.props.store.insight.result.name;
      const builder = this.props.store.builder;
      // Download the chart png
      builder.setDownloadingChart(true);
      await downloadInsightChart(insightName);
      builder.setDownloadingChart(false);
      // Download the table csv
      await builder.getAllDatasets();
      downloadCsvFile(builder, insightName);
      getMixpanel(this.props.store).track("Embed Insight Download", {
        "Insight Id": insightId,
        "Insight Name": insightName,
      });
    } catch (error) {
      console.error("Download Embedded Insight Fail", error);
    }
  };

  clickThroughMixpanelTracking = (insightId, insightName) => {
    getMixpanel(this.props.store).track("Embed Insight Click Through", {
      "Insight Id": insightId,
      "Insight Name": insightName,
    });
  };

  render() {
    const { store, location } = this.props;
    const { error, loading, showDownload } = this.state;
    if (store.loading || store.builder.loading.loadInsight || loading) {
      return <ShowLoaderGlobal />;
    }
    const { search } = location;
    // overrideMetricLabelSize is used to force the default auto font sizing on metric chart insights even if config for these is set
    const {
      url,
      columns,
      hideLegend,
      hideTitle,
      overrideMetricLabelSize,
      enableInsightClickThrough,
      allowInsightDownloads,
      showInsightSources,
    } = qs.parse(search.slice(1) || "");
    const { id, key } = stripURLData(url);
    const insightURLPath = `/insights/${id}${store.token ? "" : `/${key}`}`; // Show public insight links for non-logged-in users.

    const condensedEmbed = !!store.insight.data?.chart?.condensedEmbed;
    const insightName = store.insight.result.name;

    return (
      <div className="bg-white">
        {error ? (
          <div className="text-muted fs-1000 pt-3 px-4">{error}</div>
        ) : (
          <>
            {!!store.insight.result?.json ? (
              <div
                className="position-relative"
                onMouseEnter={() => this.setState({ showDownload: true })}
                onMouseLeave={() => this.setState({ showDownload: false })}
              >
                {hideTitle !== "true" && (
                  <>
                    {key && enableInsightClickThrough === "true" ? (
                      <Popup
                        trigger={
                          <ClickableInsightTitle
                            href={insightURLPath}
                            target="_blank"
                            className="d-inline-block"
                            onClick={() => this.clickThroughMixpanelTracking(id, insightName)}
                          >
                            <div className={`pr-5 ${condensedEmbed ? "mb-2" : "pl-4 pt-2"}`}>
                              <span className={`text-muted fs-1125 fw-600 mr-2 ${condensedEmbed ? "" : "mt-2"}`}>{insightName}</span>
                              <Icon
                                name="external alternate"
                                size="small"
                                className={"text-muted cursor-pointer"}
                                style={{ marginTop: -3 }}
                              />
                            </div>
                          </ClickableInsightTitle>
                        }
                        content="Open insight in new tab"
                        position="bottom center"
                        size="mini"
                        offset={[10, condensedEmbed ? -5 : 0]}
                        inverted
                      />
                    ) : (
                      <Title className={condensedEmbed ? "mb-2 fs-1125" : "px-4 pt-2 fs-1125"}>{insightName}</Title>
                    )}
                  </>
                )}
                <ExploreMultiIndexChartResult
                  insight
                  editMode={false}
                  chartLayoutOverride={Number(columns || "1")}
                  hideLayoutConfig
                  hideLegend={hideLegend !== "false"} // default to true if param not set
                  overrideMetricLabelSize={overrideMetricLabelSize === "true"}
                  condensedEmbed={condensedEmbed}
                  isAnimationDisabled={store.builder.downloadingChart}
                  showAllInsightsSources={showInsightSources === "true"}
                />
                {allowInsightDownloads && showDownload && (
                  <Icon
                    name="download"
                    className="position-absolute cursor-pointer text-muted"
                    style={{ right: 5, top: condensedEmbed ? 5 : 10 }}
                    onClick={() => this.downloadInsight(id)}
                  />
                )}
              </div>
            ) : (
              <div className="text-muted h-100 d-flex justify-content-center align-items-center">
                {!!store.user ? "You do not have access to this insight" : "This insight has not been publicly shared"}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export const EmbedInsight = withRouter(inject("store")(observer(EmbedInsightComponent)));
