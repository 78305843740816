import { CHART_AXIS_COLOR, CHART_GRID_COLOR, PREVIEW_LABEL_STYLING } from "common/helpers/chart";
import * as React from "react";
import { XAxis, YAxis, CartesianGrid, AreaChart, Area, Tooltip } from "recharts";

interface IAreaChart {
  id?: number; // Use Insight id as part of <linearGradient> id to make it unique on Suitcases preview page
  data: any[];
  chartKey: any;
  chartColors: string[];
  graphWidth: number;
  graphHeight: number;
  maxXAxisHeight: number;
  maxYAxisWidth: number;
  minDomainValue: number | "auto";
  maxDomainValue: number | "auto";
  computedTicks: undefined | number[];
  formatter: (val: number, _index?: number, full?: boolean) => any;
  CustomAxisTick: (axis: "x" | "y") => (props: any) => JSX.Element;
  setTooltipDataKey: (value) => void;
  CustomToolTip: (props: any) => JSX.Element | null;
  allowDataOverflow: boolean;
  axisTickInterval?: number;
}

export const NewBuilderAreaChart = ({ id, data, chartKey, chartColors, graphWidth, graphHeight, minDomainValue, maxDomainValue, computedTicks, formatter, maxXAxisHeight, maxYAxisWidth, CustomAxisTick, setTooltipDataKey, CustomToolTip, allowDataOverflow, axisTickInterval }: IAreaChart): JSX.Element => (
  <AreaChart
    data={data}
    width={graphWidth}
    height={graphHeight}
  >
    <CartesianGrid strokeDasharray="4 4" vertical={false} stroke={CHART_GRID_COLOR} />
    <XAxis
      dataKey="label0"
      textAnchor="end"
      interval={axisTickInterval || 0}
      tick={CustomAxisTick("x")}
      height={maxXAxisHeight}
      tickLine={false}
      axisLine={{ stroke: CHART_AXIS_COLOR }}
    />
    <YAxis
      type="number"
      tick={PREVIEW_LABEL_STYLING}
      tickFormatter={formatter}
      width={maxYAxisWidth}
      tickLine={false}
      axisLine={false}
      domain={[minDomainValue, maxDomainValue]}
      ticks={computedTicks}
      minTickGap={1}
      allowDataOverflow={allowDataOverflow}
    />
    <Tooltip
      offset={0}
      isAnimationActive={false}
      cursor={{ fill: "transparent", stroke: CHART_AXIS_COLOR, strokeWidth: 1 }}
      content={<CustomToolTip />}
    />
    <defs>
      {chartKey.values.map((dataKey, idx) => (
        <linearGradient id={`${idx}-${id}`} key={`${dataKey}-${idx}-${id}`} x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor={chartColors[idx]} stopOpacity={0.9} />
          <stop offset="100%" stopColor={chartColors[idx]} stopOpacity={0.8} />
        </linearGradient>
      ))}
    </defs>
    {chartKey.values.map((dataKey, idx) => (
      <Area
        key={`${dataKey} ${idx}`}
        type="monotone"
        dataKey={dataKey}
        stackId="stacked"
        strokeWidth={1}
        stroke={chartColors[idx]}
        fillOpacity={1}
        fill={`url('#${idx}-${id}')`}
        activeDot={{
          r: 4,
          strokeWidth: 0,
          onMouseEnter: () => {
            setTooltipDataKey(dataKey);
          },
          onMouseLeave: () => {
            setTooltipDataKey(undefined);
          },
        }}
        onMouseEnter={() => {
          setTooltipDataKey(dataKey);
        }}
        onMouseLeave={() => {
          setTooltipDataKey(undefined);
        }}
      />
    ))}
  </AreaChart>
);
