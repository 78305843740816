import * as React from "react";
import { TABLE_SELECTION_STATE_OPTIONS } from "common/helpers/explore";
import { Icon } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";

export interface IExploreTabItem {
  icon: SemanticICONS;
  label: string;
  active: boolean;
  position?: "top" | "middle" | "bottom";
  selectionState?: string;
  folded?: boolean;
  onClick?: (arg: any) => any;
  visible?: boolean;
  disabled?: boolean;
}

export const ExploreTabItem = ({
  icon,
  label,
  active,
  position = "middle",
  selectionState,
  folded = false,
  onClick,
  visible = true,
  disabled,
}: IExploreTabItem): JSX.Element | null => {
  const { GREY_LABEL, RED_DOT, GREEN_DOT, LOCK_ICON } = TABLE_SELECTION_STATE_OPTIONS;

  if (!visible) {
    return null;
  }

  return (
    <div
      className={`
          d-flex align-items-center justify-content-center
          ${disabled ? "cursor-default" : "cursor-pointer"}
          ${position === "bottom" && "position-absolute left-0 bottom-0 w-100"}
          ${
            active
              ? "bg-light text-secondary"
              : (!active && selectionState === GREY_LABEL) || disabled
                ? "bg-white text-grey"
                : "bg-white text-medium"
          }
        `}
      style={{
        height: 80,
        borderRadius:
          position === "top" && folded === false
            ? "4px 0 0 0"
            : position === "top" && folded === true
              ? "4px 4px 0 0"
              : position === "bottom" && folded === false
                ? "0 0 0 4px"
                : position === "bottom" && folded === true
                  ? "0 0 4px 4px"
                  : "none",
      }}
      onClick={disabled ? undefined : onClick}
    >
      <div className="d-flex flex-column align-items-center">
        <div className="position-relative">
          <Icon name={icon} size="large" className="mr-0 mb-1" />
          {[RED_DOT, GREEN_DOT, LOCK_ICON].includes(selectionState || "") && (
            <Icon
              name={selectionState === LOCK_ICON ? "lock" : "circle"}
              size={selectionState === LOCK_ICON ? "small" : "tiny"}
              className={`${
                selectionState === RED_DOT ? "text-primary" : selectionState === LOCK_ICON ? "text-muted" : "text-info"
              } position-absolute`}
              style={{ top: -4, right: -12 }}
            />
          )}
        </div>
        <p className="ff-primary fs-0875 fw-600 text-capitalize">{label}</p>
      </div>
    </div>
  );
};
