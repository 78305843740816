// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from "react";
import { components } from "react-select";
import { Icon } from "semantic-ui-react";

export const CustomDropdownIndicator = (props) => {
  const { options, getValue, isMulti } = props;
  const allOptionsCount = options.filter((option) => option.value !== "SEER_SELECT_ALL").length;
  const optionCount = isMulti ? allOptionsCount - getValue().length : allOptionsCount;

  return (
    <components.DropdownIndicator {...props}>
      <span className={`fs-1000 cursor-pointer ${optionCount === 0 ? "text-grey" : props.isFocused ? "text-secondary" : "text-muted"}`}>
        {optionCount}
        <Icon name="chevron down" className="ml-1" />
      </span>
    </components.DropdownIndicator>
  );
};
