import * as React from "react";
import { ChromePicker } from "react-color";
import { Button, Icon, Modal } from "semantic-ui-react";

const defaultColor = "#5f2d5f";

interface IColorPicker {
  open: boolean;
  setColorPickerOpen: (open: any) => void;
  onSave: (x: string) => void;
  initialColor?: string | null;
  headerText?: string;
  saveText?: JSX.Element | string;
}

export const ColorPicker = ({ open, initialColor, setColorPickerOpen, onSave, headerText, saveText }: IColorPicker): JSX.Element => {
  const [color, setColor] = React.useState(initialColor || defaultColor);

  // update current color if new initialColor passed
  React.useEffect(() => {
    setColor(initialColor || defaultColor);
  }, [initialColor]);

  return (
    <>
      <Modal size="small" onClose={() => setColorPickerOpen(false)} open={open}>
        <Modal.Header>{headerText || "Change Color"}</Modal.Header>
        <Modal.Content>
          <div className="w-100">
            <ChromePicker width="100%" disableAlpha={true} color={color} onChange={(e) => setColor(e.hex)} />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setColorPickerOpen(false)}>
            Cancel <Icon name="close" className="ml-1 mr-0" />
          </Button>
          <Button
            color="purple"
            onClick={() => {
              onSave(color);
              setColorPickerOpen(false);
            }}
          >
            {saveText || (
              <>
                Change <Icon name="exchange" className="ml-1 mr-0" />
              </>
            )}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
