import * as React from "react";
import { Divider, Dropdown, Grid, Icon, SemanticICONS } from "semantic-ui-react";
import { CreateModal } from "./CreateModal";

export interface IItem {
  type: "link" | "button-primary" | "button-secondary";
  label: string;
  icon: SemanticICONS;
  link?: string | null;
  action?: () => void;
  disabled?: boolean;
}

interface IHeaderSection {
  name: string;
  onNameChange?: (name) => void;
  items: IItem[];
  actionsDropdown?: any[];
}

// TODO: This component was used by both the 'Dataset' page and the 'Dataset Template' page but is not used in the redesign for the 'Dataset' page on Dec 2023 anymore. We need to consider whether we still need this component when we undertake the redesign of the 'Dataset Template' page.
export const HeadingSection = ({ name, onNameChange, items, actionsDropdown }: IHeaderSection): JSX.Element => {
  const [showEditNameModal, setShowEditNameModal] = React.useState(false);
  return (
    <>
      {/* Modal to update template name */}
      <CreateModal
        name={name}
        heading="Template"
        isOpen={showEditNameModal}
        closeModal={() => setShowEditNameModal(false)}
        saveModal={onNameChange!}
      />
      <Grid>
        <Grid.Row>
          <Grid.Column width={6}>
            <div className="d-flex align-items-center">
              <h1 className="ff-primary text-secondary my-0 mr-3" style={{ fontSize: 30 }}>
                {name}
              </h1>
              {onNameChange && <Icon name="edit" className="cursor-pointer text-secondary" onClick={() => setShowEditNameModal(true)} />}
            </div>
          </Grid.Column>
          <Grid.Column width={10}>
            <div className="d-flex align-items-center justify-content-end mt-3">
              {items.map((item, index) => {
                const { type, label, icon, link, action, disabled } = item;
                if (type === "link") {
                  return (
                    link && (
                      <a href={link} target="_blank" key={index}>
                        <p className="text-dark text-hover-secondary my-0 ml-3 fw-700 fs-1000">
                          <Icon name={icon} />
                          {label}
                        </p>
                      </a>
                    )
                  );
                } else {
                  let className = "my-0 ml-3 fw-700 fs-1000 ";
                  if (disabled) {
                    className = className.concat("text-grey");
                  } else if (type === "button-primary") {
                    className = className.concat("text-primary cursor-pointer");
                  } else {
                    className = className.concat("text-dark text-hover-secondary cursor-pointer");
                  }
                  return (
                    <p className={className} key={index} onClick={disabled ? undefined : action}>
                      <Icon name={icon} />
                      {label}
                    </p>
                  );
                }
              })}
              {actionsDropdown && (
                <Dropdown className="ml-3 fs-1125" icon="ellipsis vertical" text=" " options={actionsDropdown} pointing="top right" item />
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
    </>
  );
};
