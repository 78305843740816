import { DEFAULT_COLOR_PALETTE, generateColorsWithPalette, getCustomChartColorNumber, getNumColoursToGenerate } from "common/helpers/chart";
import Store from "common/store";
import { Builder, IFilter } from "common/store/builder";
import { brushes, withColorApplicator } from "component/ColorPaletteWidgets/withColorApplicator";
import { COLORS } from "component/UI/common";
import { DropdownTitle } from "component/insightBuilder/DropdownTitle";
import { uniqueId } from "lodash";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Function to help with reordering the result
const reorder = (list: IFilter[], startIndex, endIndex): IFilter[] => {
  const deepCopy = Array.from(list);
  const [removed] = deepCopy.splice(startIndex, 1);
  deepCopy.splice(endIndex, 0, removed);

  return deepCopy;
};

/**
 * Moves an item from one list to another list.
 */
const move = (sourceItems, destinationItems, source, destination, swap?): Record<string, IFilter[]> => {
  const sourceClone = Array.from(sourceItems);
  const destClone = Array.from(destinationItems);

  if (swap) {
    // Swap
    const sourceItem = sourceItems[source.index];
    const destIndex = destClone.length > destination.index ? destination.index : destination.index - 1;
    const [removedFromDestination] = destClone.splice(destIndex, 1, sourceItem);
    sourceClone.splice(source.index, 1, removedFromDestination);
  } else {
    // Move
    const [removedFromSource] = sourceClone.splice(source.index, 1);
    destClone.splice(destination.index, 0, removedFromSource);
  }

  const result = {};
  result[source.droppableId] = sourceClone;
  result[destination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, isFilter, isDisabled) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  background: isDragging ? COLORS.indigo600 : isFilter || isDisabled ? "#c3c3c3" : COLORS.red500,

  fontSize: "10px",
  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
  color: "#ffffff",
  border: 0,
  // padding: "12px 20px",
  fontWeight: "bold",
  borderRadius: "5px",
  textAlign: "center",
  outline: "none",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "#f5f5f5",
  padding: grid * 2,
  display: "flex",
  overflow: "auto",
  margin: "0 0 20px 0",
  borderRadius: "0.3rem",
});

interface PropsType {
  store?: Store;
  builder?: Builder;
  applicator: any;
  // rows?: string[];
  // columns?: string[];
}

export const ChartDraggableLists = withColorApplicator(
  inject((stores: any) => ({ store: stores.store!.insightbuilder, builder: stores.store!.builder }))(
    observer((props: PropsType) => {
      const builder = props.builder!;
      const applicator = props.applicator;

      const [legend, setLegend] = React.useState(builder.chartLegend);
      const [xAxis, setXAxis] = React.useState(builder.chartXAxisArray);
      const [series, setSeries] = React.useState(builder.chartSeries);

      React.useEffect(() => {
        applicator.updateCanvas((builder.canvas || []).map((color) => ({ color, id: uniqueId() })));
        applicator.updateSelectedBrush(builder.selectedBrush || "cycle");
      }, [builder.canvas, builder.selectedBrush]);

      React.useEffect(() => {
        setLegend(builder.chartLegend);
        setXAxis(builder.chartXAxisArray);
        setSeries(builder.chartSeries);
      }, [builder.chartLegend, builder.chartXAxisArray, builder.chartSeries]);

      /**
       * A semi-generic way to handle multiple lists. Matches
       * the IDs of the droppable container to the names of the
       * source arrays stored in the state.
       */
      const getList = (id) => {
        const id2List = {
          legend,
          xAxis,
          series,
        };
        return id2List[id];
      };

      const onDragEnd = (result) => {
        const { source, destination } = result;

        // Dropped outside the list
        if (!destination) {
          return;
        }

        const sourceItems = getList(source.droppableId);
        const destinationItems = getList(destination.droppableId);

        if (
          source.droppableId !== "series" &&
          destination.droppableId === "series" &&
          sourceItems.length === 1 &&
          destinationItems.length === 0
        ) {
          // Prevent empty Legend/XAxis: when drag the only variable from Legend/XAxis to empty Series
          return;
        } else if (source.droppableId === destination.droppableId) {
          // REORDER
          const items: IFilter[] = reorder(sourceItems, source.index, destination.index);

          const userDefinedSelection = {
            legend,
            xAxis,
            series,
          };
          userDefinedSelection[source.droppableId] = items;
          builder.setUserDefinedCharts(userDefinedSelection);

          source.droppableId === "legend" && setLegend(items);
          source.droppableId === "xAxis" && setXAxis(items);
          source.droppableId === "series" && setSeries(items);
        } else {
          // MOVE
          const result: Record<string, IFilter[]> = move(
            sourceItems,
            destinationItems,
            source,
            destination,
            (source.droppableId !== "series" && sourceItems.length === 1) || // Always keeps 1 item in Legend and XAxis
              (destination.droppableId === "series" && destinationItems.length === 1), // Limit series to 1 item for now, so do a swap
          );

          builder.setUserDefinedCharts({
            legend: result.legend || legend,
            xAxis: result.xAxis || xAxis,
            series: result.series || series,
          });

          result.legend && setLegend(result.legend);
          result.xAxis && setXAxis(result.xAxis);
          result.series && setSeries(result.series);
          result.series && builder.setChartLayout(2);

          const {
            chart,
            chartXAxisArray,
            chartKey,
            chartTables,
            columns,
            chartSeries,
            chartLegend,
            selectedBrush,
            canvas,
            customChartColors,
            hasCustomChartColors,
          } = builder;
          // Update "customChartColors" when it exists and the existing color number is not the same as the new color number.
          if (hasCustomChartColors) {
            const newCustomChartColorNumber = getCustomChartColorNumber(
              chart.type,
              chartXAxisArray,
              chartKey,
              chartTables,
              columns,
              chartSeries,
              chartLegend,
            );
            if (customChartColors.length !== newCustomChartColorNumber) {
              const colors = brushes[selectedBrush].brush(canvas.length ? canvas : DEFAULT_COLOR_PALETTE, [newCustomChartColorNumber]);
              builder.setCustomChartColors(colors.reduce((pre, cur) => pre.concat(cur), []));
            }
          }
          // Need to set "customChartColors" using "DEFAULT_COLOR_PALETTE" when the number of colors in all chart tables is less than the number of legends after drag&drop
          const newLegendNumber = getNumColoursToGenerate(chartTables, chartLegend);
          const allChartTableColorNumber = chartTables.reduce((prev, curr) => prev.concat(curr.colors), []).length;
          if (!hasCustomChartColors && newLegendNumber > allChartTableColorNumber) {
            const colors = generateColorsWithPalette(newLegendNumber, canvas.length ? canvas : DEFAULT_COLOR_PALETTE);
            builder.setCustomChartColors(colors);
          }
        }
      };

      // disable when:
      // - only calculations are being charted as they are all forced to x - axis currently
      // - OR there is only one multi-category variable, which must stay in x-axis
      // (see src / app / common / helpers / chart.ts::getChartData implementation)
      const isDisabled =
        !builder.chartTables.filter((t) => t.type === "result").length ||
        (builder.chartLegend.length === 0 && builder.chartSeries.length === 0 && builder.chartXAxisArray.length === 1);

      return (
        <DragDropContext onDragEnd={onDragEnd}>
          {/* TODO: hide some dropdown based on types and rename label if needed */}
          <DropdownTitle heading="" title="Legend" tooltip="" />
          <Droppable droppableId="legend" direction="horizontal" isDropDisabled={isDisabled}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {legend.map((item, index) =>
                  item.dimension === "" ? null : (
                    <Draggable key={item.dimension} draggableId={item.dimension} index={index} isDragDisabled={isDisabled}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, item.values?.length === 1, isDisabled)}
                        >
                          {item.dimension.replace(/^!/g, "")}
                        </div>
                      )}
                    </Draggable>
                  ),
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          {builder.chart.type.includes("Pie") ? (
            <DropdownTitle heading="" title="Pie Series" />
          ) : (
            <DropdownTitle heading="" title="Primary Axis" tooltip="Labels for the main non-numeric axis." />
          )}
          <Droppable droppableId="xAxis" direction="horizontal" isDropDisabled={isDisabled}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {xAxis.map((item, index) => (
                  <Draggable key={item.dimension} draggableId={item.dimension} index={index} isDragDisabled={isDisabled}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, item.values?.length === 1, isDisabled)}
                      >
                        {item.dimension.replace(/^!/g, "")}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          {!builder.chart.type.includes("Pie") && (
            <>
              <DropdownTitle heading="" title="Series" tooltip="Used to generate multiple charts. Currently limited to one variable." />
              {builder.uniqueMultiCategoryVariables.length > 2 ? (
                <Droppable droppableId="series" direction="horizontal" isDropDisabled={isDisabled}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                      {series.map((item, index) =>
                        item.dimension === "" ? null : (
                          <Draggable key={item.dimension} draggableId={item.dimension} index={index} isDragDisabled={isDisabled}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                  item.values?.length === 1,
                                  isDisabled,
                                )}
                              >
                                {item.dimension.replace(/^!/g, "")}
                              </div>
                            )}
                          </Draggable>
                        ),
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ) : (
                <p className="fs-1000">
                  Splitting your data into multiple charts will be possible when you have more than 2 variables with multiple categories
                  selected.
                </p>
              )}
            </>
          )}
        </DragDropContext>
      );
    }),
  ),
);
