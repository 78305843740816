import * as React from "react";
import { Header, Icon, Popup } from "semantic-ui-react";

interface IDropdownTitle {
  forMultiStep?: boolean;
  heading: string;
  title: string;
  tooltip?: string;
}

export const DropdownTitle = ({ forMultiStep, heading, title, tooltip }: IDropdownTitle): JSX.Element => {
  if (forMultiStep) {
    // Show sentence and description on Multi-step page
    return (
      <>
        <Header as="h3" color="purple">
          {heading}
        </Header>
        {tooltip === "" || <p className="ff-primary fw-400 text-dark fs-1125 mt-0 mb-4">{tooltip}</p>}
      </>
    );
  } else {
    // Show short title and info icon on Insight Builder page
    return (
      <div className="d-flex">
        <Header as="h5" color="purple" className="mb-2">
          {title}
        </Header>
        {tooltip === "" || (
          <Popup
            trigger={<Icon name="info circle" className="text-medium ml-1 cursor-pointer" size="small" />}
            content={tooltip}
            position="right center"
            size="mini"
            inverted
          />
        )}
      </div>
    );
  }
};
