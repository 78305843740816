import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  &:hover {
    font-weight: 700;
  }
`;

interface BreadCrumbItem {
  label: string;
  pathname: string;
}

interface BreadcrumbsProps {
  items: BreadCrumbItem[];
}

export const Breadcrumbs = ({ items }: BreadcrumbsProps): JSX.Element | null => {
  if ((items?.length || 0) < 2) {
    return null;
  }
  return (
    <div className="my-4 fs-1125">
      <p>
        {items.map((bc, idx) => {
          const first = idx === 0;
          const last = idx === items.length - 1;
          return (
            <span key={`${bc.label}-${idx}`} className="text-muted">
              {!first && " > "}
              {!last ? (
                <StyledLink to={bc.pathname} className="text-muted">
                  {bc.label}
                </StyledLink>
              ) : (
                <b>{bc.label}</b>
              )}
            </span>
          );
        })}
      </p>
    </div>
  );
};
