import * as React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

export const StyledButton = styled.button`
  appearance: initial;
  color: #455687;
  box-sizing: initial;
  background-color: initial;
  padding-block: initial;
  padding-inline: initial;
  border-width: initial;
  border-style: initial;
  border-color: initial;
  cursor: pointer;
  font-size: 18px;
  &:focus {
    text-decoration: underline;
  }
`;

const CollapsingContent = styled.div`
  overflow: clip;
  max-height: 84px;
  mask-image: linear-gradient(to bottom, black 50px, transparent 70px);
  &.expanded {
    overflow: initial;
    max-height: initial;
    mask-image: initial;
  }
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  action_detail: string;
  windowWidth: number;
}

export const CollapsibleContent = (props: Props) => {
  const { action_detail, windowWidth, ...rest } = props;
  const [expanded, setExpanded] = React.useState(false);
  const [showExpand, setShowExpand] = React.useState(false);
  const contentRef: any = React.useRef(null);
  if (!action_detail) {
    return null;
  }
  React.useEffect(() => {
    if (contentRef?.current) {
      if (contentRef.current.scrollHeight > 68) {
        setShowExpand(true);
      } else {
        setShowExpand(false);
      }
    }
  }, [windowWidth, contentRef]);
  return (
    <div {...rest} className="mb-4" style={{ fontSize: 18, fontWeight: "normal" }}>
      <CollapsingContent className={`mb-2 ${expanded ? "expanded" : ""}`}>
        <div ref={contentRef} dangerouslySetInnerHTML={{ __html: action_detail }} />
      </CollapsingContent>
      {showExpand && (
        <div className="text-right">
          <StyledButton type="button" onClick={() => setExpanded(!expanded)}>
            Click here to {expanded ? "collapse" : "read full"} text <Icon name={`chevron ${expanded ? "up" : "down"}`} />
          </StyledButton>
        </div>
      )}
    </div>
  );
};
