import * as React from "react";
import { Popup, PopupProps } from "semantic-ui-react";

export const DashboardPopup = (props: PopupProps): JSX.Element => {
  const { children, className, content, ...rest } = props;
  return (
    <Popup trigger={<div className={className}>{children}</div>} size="tiny" inverted hoverable {...rest}>
      {content}
    </Popup>
  );
};

export const StandardPopupContent = (
  <>
    Plus subscription only.{" "}
    <a
      className="text-white text-decoration-underline fw-bold"
      target="_blank"
      href="/dashboards/24/279ab91b-7d79-42c8-b805-5cdc7db3c187686df48a-aac9-417b-ad10-5582ecb034b2"
    >
      Learn more
    </a>
  </>
);
