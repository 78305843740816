import * as React from "react";

import { Icon, Dropdown } from "semantic-ui-react";
import { COLORS } from "./UI/common";

interface CardAction {
  name: string;
  callback(id: number): void;
}

interface ActionsDropdownProps {
  cardActions: CardAction[];
  id: number;
}

export function ActionsDropdown({ cardActions, id }: ActionsDropdownProps) {
  return (
    <Dropdown
      // inline
      className="pdf-hide"
      text="Actions"
      icon={
        <Icon
          name="caret down"
          // size="small"
          style={{ float: "right", color: COLORS.red500 }}
        />
      }
      style={{ float: "right", color: COLORS.red500, fontSize: "15px", fontWeight: 700 }}
      // floating
    >
      <Dropdown.Menu>
        {cardActions.map((action) => (
          <Dropdown.Item onClick={() => action.callback(id)} key={action.name}>
            {action.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
