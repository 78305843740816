import * as React from "react";

export const RSTOStyleAccordion = (props) => {
  const { id } = props;
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
          #${id} .seer-custom-tabs {
            margin-top: 2rem;
          }

          #${id} .seer-custom-tabs input {
            position: absolute;
            opacity: 0;
            z-index: -1;
          }

          #${id} .seer-custom-tabs .tabs {
            overflow: hidden;
          }

          #${id} .seer-custom-tabs .tab {
            width: 100%;
            color: white;
            overflow: hidden;
          }

          #${id} .seer-custom-tabs .tab-label {
            display: flex;
            padding: 0.75em 1rem;
            background: #f1a762;
            font-weight: bold;
            cursor: pointer;
            font-size: 1rem;
            font-weight: 600;
          }

          #${id} .seer-custom-tabs .tab-label::before {
            content: url("data:image/svg+xml; utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 256 512'%3E%3C!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cstyle%3Esvg%7Bfill:%23ffffff%7D%3C/style%3E%3Cpath d='M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z'/%3E%3C/svg%3E");
            width: 1em;
            height: 1em;
            text-align: center;
            margin-right: 0.5rem;
            transition: all 0.35s;
          }

          #${id} .seer-custom-tabs .tab-content {
            max-height: 0;
            padding: 0 1em;
            color: #000000;
            background: white;
            transition: all 0.35s;
            border: 1px solid #f1a762;
          }

          #${id} .seer-custom-tabs .tab-close {
            display: flex;
            justify-content: flex-end;
            padding: 1em;
            font-size: 0.75em;
            cursor: pointer;
          }

          #${id} .seer-custom-tabs input:checked+.tab-label::before {
            transform: rotate(90deg);
          }

          #${id} .seer-custom-tabs input:checked~.tab-content {
            max-height: 100vh;
            padding: 1em;
          }
        `,
      }}
    />
  );
};
