import * as React from "react";
import { MapContainer as Map, GeoJSON } from "react-leaflet";
import { useParams } from "react-router-dom";
import { Dropdown, Icon } from "semantic-ui-react";
import { cfaIgwtMapConfig, perpetualIgwtMapConfig, seerIgwtMapConfig } from "../common/helpers/igwt";
import { FullScreenControl } from "component/LeafletCustom/FullScreenControl";

// Not needed, but handy to cross check data
//
// const valueRanges = Object.fromEntries(Object.keys(originalVariables).map(
//   (v) => [v, { lowest: 999999999, highest: 0 }]
// ));
//
// data.features.forEach(feature => {
//   Object.keys(originalVariables).forEach(v => {
//     const value = feature.properties[v];
//     if (value < valueRanges[v].lowest) {
//       valueRanges[v] = { ...valueRanges[v], lowest: value };
//     }
//     value > valueRanges[v].highest ? valueRanges[v].highest = value : null;
//   });
// });
// console.log(valueRanges);

export const MapTest = (): JSX.Element => {
  const [variableA, setVariableA] = React.useState();
  const [variableB, setVariableB] = React.useState();
  const [colorPaletteID, setColorPaletteID] = React.useState(1);
  const [showMapDropdown, setShowMapDropdown] = React.useState(false);
  const [showColorPaletteDropdown, setShowColorPaletteDropdown] = React.useState(false);
  const [data, setData] = React.useState<any>(null);

  const { mapKey } = useParams<{ mapKey: string }>();
  const mapConfig =
    mapKey === "igwt/perpetual"
      ? perpetualIgwtMapConfig
      : mapKey === "igwt/cfa"
        ? cfaIgwtMapConfig
        : mapKey === "igwt"
          ? seerIgwtMapConfig
          : null;
  if (!mapConfig) {
    return <h4 className="text-center text-secondary">This map doesn't exist. Please check URL.</h4>;
  }

  const { colorPalettes, variables, initialSelectedVariables } = mapConfig;
  const selectedVariableA = variableA || initialSelectedVariables[0];
  const selectedVariableB = variableB || initialSelectedVariables[1];
  const colorPalette = colorPalettes.find((palette) => palette.id === colorPaletteID)!.palette;

  const DEFAULT_ZOOM = 4;
  const DEFAULT_MAP_CENTRE: any = [-26.698, 133.8807];
  const MAP_BOUNDS: any = [
    [-50, 50],
    [4, 200],
  ];

  const getColor = (a, b) => {
    const variableABin = variables.find((variable) => variable.id === selectedVariableA)!.bin;
    const variableBBin = variables.find((variable) => variable.id === selectedVariableB)!.bin;
    return a > variableABin[3] && b > variableBBin[3]
      ? colorPalette.legend[4]
      : a > variableABin[2] && b > variableBBin[2]
        ? colorPalette.legend[3]
        : a > variableABin[1] && b > variableBBin[1]
          ? colorPalette.legend[2]
          : a > variableABin[0] && b > variableBBin[0]
            ? colorPalette.legend[1]
            : colorPalette.legend[0];
  };

  const geoJSONStyle = (feature) => ({
    weight: 0.6,
    fillOpacity: 1,
    fillColor: getColor(feature.properties[selectedVariableA], feature.properties[selectedVariableB]),
    color: colorPalette.background,
  });

  const onEachFeature = (feature: any, layer: any) => {
    const tooltipContent = `
      <div>
        <p style="color: ${colorPalette.primary}; margin-bottom: 10px">
          <strong>${feature.properties["SA2_NAME16"]}</strong>
          <br/>
          ${feature.properties["STE_NAME16"]}
        </p>
        <p style="margin-top: 0">
          ${variables
            .map((variable) => `${variable.renamed}: <strong>${Math.round(feature.properties[variable.id] * 100) / 100}</strong></br/>`)
            .join("")}
        </p>
      </div>
    `;

    layer.bindTooltip(tooltipContent, { direction: "top", sticky: true });
    layer.on("mouseover", () => {
      layer.setStyle({ fillOpacity: 0.95 });
    });
    layer.on("mouseout", () => {
      layer.setStyle({ fillOpacity: 1 });
    });
  };

  const dropdownOptions = variables.map((variable) => ({
    key: variable.id,
    value: variable.id,
    text: variable.renamed,
  }));

  const colorPaletteDropdownOptions = colorPalettes.map((palette) => ({
    key: palette.id,
    value: palette.id,
    text: palette.name,
  }));

  const handleDropdownChange = (value, callFunc) => {
    callFunc(+value);
  };

  const handleColorPaletteDropdownChange = (value) => {
    setColorPaletteID(+value);
    setShowColorPaletteDropdown(false);
  };

  const initData = async () => {
    const json = await fetch("/assets/map_data/new_iwt_data.json")
      .then((res) => res.json())
      .catch(() => null);
    if (json) {
      setData(json);
    }
  };

  React.useEffect(() => {
    initData();
  }, []);

  return (
    <>
      <div className="text-center mb-3">
        <h1 style={{ color: colorPalette.primary }}>Wealth Transfer Explorer</h1>
        <Dropdown
          selection
          options={dropdownOptions.map((o) => (o.value !== selectedVariableB ? o : { ...o, disabled: true }))}
          value={selectedVariableA}
          onChange={(_, data) => handleDropdownChange(data.value, setVariableA)}
          className="z-index-max mr-5"
          style={{ color: colorPalette.primary }}
        />
        <Dropdown
          selection
          options={dropdownOptions.map((o) => (o.value !== selectedVariableA ? o : { ...o, disabled: true }))}
          value={selectedVariableB}
          onChange={(_, data) => handleDropdownChange(data.value, setVariableB)}
          className="z-index-max"
          style={{ color: colorPalette.primary }}
        />
      </div>

      {/* Main Map Area */}
      <Map
        center={DEFAULT_MAP_CENTRE}
        zoom={DEFAULT_ZOOM}
        minZoom={4}
        maxZoom={15}
        maxBounds={MAP_BOUNDS}
        style={{ background: colorPalette.background }}
      >
        <FullScreenControl onFullscreen={() => setShowMapDropdown(true)} onExitFullScreen={() => setShowMapDropdown(false)} />
        {/* Map dropdowns on fullscreen */}
        {showMapDropdown && (
          <div className="text-center position-absolute w-100 z-index-max" style={{ top: 15 }}>
            <select
              value={selectedVariableA}
              onChange={(e) => handleDropdownChange(e.target.value, setVariableA)}
              style={{ color: colorPalette.primary }}
              className="mr-4 fs-1250 p-2"
            >
              {dropdownOptions.map((o) => (
                <option key={o.value} value={o.value} disabled={o.value === selectedVariableB}>
                  {o.text}
                </option>
              ))}
            </select>
            <select
              value={selectedVariableB}
              onChange={(e) => handleDropdownChange(e.target.value, setVariableB)}
              style={{ color: colorPalette.primary }}
              className="fs-1250 p-2"
            >
              {dropdownOptions.map((o) => (
                <option key={o.value} value={o.value} disabled={o.value === selectedVariableA}>
                  {o.text}
                </option>
              ))}
            </select>
          </div>
        )}
        {data && (
          <GeoJSON
            // key workaround for this version of react-leaflet that doesn't rerender on style/onEachFeature prop changes
            key={colorPalette.background.replace(/\W/g, "").concat(variableA || "", variableB || "")}
            data={data.features}
            style={geoJSONStyle}
            onEachFeature={onEachFeature}
          />
        )}
        <div className="position-absolute rounded-1" style={{ left: 15, bottom: 15, zIndex: 1000 }}>
          {/* Map color palettes dropdown */}
          {colorPalettes.length > 1 && (
            <div className="p-3 mb-3 rounded-1" style={{ background: "rgba(255, 255, 255, 0.9)" }}>
              {showColorPaletteDropdown ? (
                <select
                  value={colorPaletteID}
                  onChange={(e) => handleColorPaletteDropdownChange(e.target.value)}
                  style={{ color: colorPalette.primary }}
                  className="fs-1000 p-1"
                >
                  {colorPaletteDropdownOptions.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.text}
                    </option>
                  ))}
                </select>
              ) : (
                <div
                  className="d-flex align-items-center cursor-pointer"
                  style={{ color: colorPalette.primary }}
                  onClick={() => setShowColorPaletteDropdown(true)}
                >
                  <Icon name="sync alternate" style={{ marginTop: -5 }} />
                  <p className="fs-1000 my-0">Change colours</p>
                </div>
              )}
            </div>
          )}
          {/* Map Legend */}
          <div className="p-3 rounded-1" style={{ background: "rgba(255, 255, 255, 0.9)" }}>
            <div className="d-flex mb-2">
              {colorPalette.legend.map((color, index) => (
                <div style={{ height: 16, width: 20, background: color }} key={index} />
              ))}
            </div>
            <p style={{ color: colorPalette.primary }}>Low to high</p>
          </div>
        </div>
      </Map>

      <div className="fs-0875 bg-white p-4 mt-3" style={{ width: "80%", margin: "0 auto" }}>
        <h4 style={{ color: colorPalette.primary }}>Full variable names used in the map: </h4>
        <p>
          {variables.map((variable, index) => (
            <span key={index}>
              <strong>{variable.renamed}</strong>: {variable.original}
              <br />
            </span>
          ))}
        </p>
      </div>
    </>
  );
};
