import * as React from "react";
import { Icon, Message, Table } from "semantic-ui-react";
import { fill } from "lodash";
import styled from "styled-components";
import { validateSchema } from "./helpers";
import { Schema, reduceCategoryCombinations } from "common/helpers/dataset";
import { COLORS } from "component/UI/common";

const VariableTableCell = styled(Table.Cell)`
  font-weight: 700;
  background-color: ${COLORS.indigo600};
  color: #fff;
`;
const CategoryTableCell = styled(Table.Cell)`
  font-weight: 600;
  background-color: #919191;
  color: #fff;
`;
const DataTableCell = styled(Table.Cell)`
  background-color: #e5e5e5;
`;
const IgnoreTableCell = styled(Table.Cell)`
  background-color: #4e4e4e;
`;

export const ColumnVariableHeadings = (props) => {
  const { columns, skipColumns, categoryColumns } = props;
  return columns.map((col, cIdx) => {
    const catLength = col.categories.length + (col.totals !== "none" ? 1 : 0);
    return (
      <Table.Row key={`thead.tr.${col.variable}`}>
        <VariableTableCell key="thead.tr.th.variable">{col.variable}</VariableTableCell>
        {skipColumns.map((_, idx) => (
          <VariableTableCell key={`thead.tr.th.skip.${idx}`} />
        ))}
        {categoryColumns.map((_, idx) => {
          const rightCombinations = reduceCategoryCombinations(columns.slice(cIdx + 1));
          const nextIndex = Math.floor(idx / rightCombinations) % catLength;
          const cat = col.categories[nextIndex] || "Total";
          return <CategoryTableCell key={`thead.tr.th.${cat}.${idx}`}>{cat}</CategoryTableCell>;
        })}
      </Table.Row>
    );
  });
};

export const RowVariableHeadings = (props) => {
  const { rows, categoryColumns } = props;
  return (
    <Table.Row>
      {!!rows.length && (
        <>
          {rows.map((row) => (
            <VariableTableCell key={`thead.tr.${row.variable}`}>{row.variable}</VariableTableCell>
          ))}
          {categoryColumns.map((_, idx) => (
            <IgnoreTableCell key={`thead.tr.td.skip.${idx}`} />
          ))}
        </>
      )}
    </Table.Row>
  );
};

export const RowCategories = (props) => {
  const { idx, rows } = props;
  return (
    <>
      {rows.map((row, rIdx) => {
        const rightCombinations = reduceCategoryCombinations(rows.slice(rIdx + 1));
        const catLength = row.categories.length + (row.totals !== "none" ? 1 : 0);
        const nextIndex = Math.floor(idx / rightCombinations) % catLength;
        const cat = row.categories[nextIndex] || "Total";
        return <CategoryTableCell key={`tbody.tr.th.category.${cat}.${rIdx}`}>{cat}</CategoryTableCell>;
      })}
      {!rows.length && <IgnoreTableCell key={`tbody.tr.td.data.skip.${idx}`} />}
    </>
  );
};

export const compileTableRenderProperties = (schema: Schema) => {
  const { rows, columns } = schema;
  const columnsCategoryHeaderColumns = reduceCategoryCombinations(columns);
  const rowsVariableHeaderColumns = rows.length;
  const rowsCategoryHeaderRows = reduceCategoryCombinations(rows);
  const skipColumns = fill(new Array(Math.max(0, rowsVariableHeaderColumns - 1)), 0);
  const categoryColumns = fill(new Array(columnsCategoryHeaderColumns), 0);
  const categoryRows = fill(new Array(rowsCategoryHeaderRows), 0);
  return { skipColumns, categoryColumns, categoryRows };
};

export const TableSchemaPreview = (props: { schema: Schema }): JSX.Element => {
  const { schema } = props;
  const { rows, columns } = schema;
  const { skipColumns, categoryColumns, categoryRows } = compileTableRenderProperties(schema);
  const error = validateSchema(schema);

  if (error) {
    return (
      <Message className="py-2 px-3 mt-0" size="mini" compact error>
        <Icon name="info circle" />
        {error}
      </Message>
    );
  }

  return (
    <div className="overflow-auto fs-1125" style={{ maxHeight: 700 }}>
      <Table size="small" compact celled>
        <Table.Body>
          {/* Column Variables with skipped cells and Categories */}
          <ColumnVariableHeadings columns={columns} skipColumns={skipColumns} categoryColumns={categoryColumns} />
          {/* Row Variables with empty cells */}
          <RowVariableHeadings rows={rows} categoryColumns={categoryColumns} />
          {/* Sheet Rows including Row Categories including data cells and skipped first column if no rows set */}
          {categoryRows.map((_, idx) => (
            <Table.Row key={`tbody.tr.${idx}`}>
              <RowCategories idx={idx} rows={rows} />
              {categoryColumns.map((_, idx) => (
                <DataTableCell key={`tbody.tr.td.data.${idx}`} />
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};
