import * as React from "react";
import { DateTime } from "luxon";
import { List, Table, Checkbox, Icon } from "semantic-ui-react";
import { formatCronString } from "common/helpers/dataset";

interface IDatasetTable {
  tableFor: "Update history" | "Contributors";
  tableHeader: any[];
  tableData: any[];
  toggleCheckbox?: (idx) => void;
  checked?: boolean[];
  updateClickHandler?: (id) => void;
}

export const DatasetTable = ({
  tableHeader,
  tableData,
  tableFor,
  toggleCheckbox,
  checked,
  updateClickHandler,
}: IDatasetTable): JSX.Element => (
  <Table celled striped selectable>
    <Table.Header>
      <Table.Row>
        {tableHeader.map((item, index) => (
          <Table.HeaderCell key={index} className="fs-1125 text-secondary">
            {item}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {tableData[0] &&
        tableData?.map((item, index) => {
          const { id, name, email, datetime, first_name, last_name, reminder_start_date, reminder_interval, sent_history } = item;

          return (
            <Table.Row verticalAlign="top" key={index} className="fs-1000 text-dark fw-500">
              {tableFor === "Update history" && (
                <>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{email}</Table.Cell>
                  <Table.Cell>{DateTime.fromISO(datetime).setZone("local").toFormat("h:mm a DD")}</Table.Cell>
                </>
              )}
              {tableFor === "Contributors" && (
                <>
                  {toggleCheckbox && checked && (
                    <Table.Cell>
                      <Checkbox checked={checked[index]} onChange={() => toggleCheckbox(index)} />
                    </Table.Cell>
                  )}
                  <Table.Cell>{`${first_name} ${last_name}`}</Table.Cell>
                  <Table.Cell>{email}</Table.Cell>
                  <Table.Cell>
                    {reminder_start_date ? DateTime.fromISO(reminder_start_date).setZone("local").toFormat("DD") : "-"}
                  </Table.Cell>
                  <Table.Cell>{reminder_interval ? formatCronString(reminder_interval) : "-"}</Table.Cell>
                  <Table.Cell>
                    <List>
                      {sent_history
                        ? sent_history.map((date, index) => (
                            <List.Item key={index} className="pb-2">
                              {DateTime.fromISO(date).setZone("local").toFormat("h:mm a DD")}
                            </List.Item>
                          ))
                        : "-"}
                    </List>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <div
                      className="text-secondary d-flex align-items-center cursor-pointer"
                      onClick={() => {
                        updateClickHandler && updateClickHandler(id);
                      }}
                    >
                      <p className="fs-1000 fw-700 mb-0 mt-1">Edit</p>
                      <Icon name="edit" className="ml-2 mt-0" />
                    </div>
                  </Table.Cell>
                </>
              )}
            </Table.Row>
          );
        })}
    </Table.Body>
  </Table>
);
