import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Icon, Button, Accordion, SemanticICONS, Dropdown } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import Store from "common/store";
import Protected from "component/protected";
import { sortByDate } from "common/helpers/dataset";
import { DatasetTable } from "./includes/DatasetTable";
import { StyleTab } from "component/Suitcases/SuitcasesContent.style";
import { ShowLoaderGlobal } from "component/LoaderGlobal";
import { Breadcrumbs } from "component/Breadcrumbs/Breadcrumbs";
import { StyleConfirm } from "component/Explore/includes/NewExplore.style";
import { EmailMessagesModal } from "./includes/EmailMessagesModal";
import { TemplateContributorModal } from "./includes/TemplateContributorModal";
import { TableSchemaPreview } from "pages/DatasetTemplateTable/includes/TableSchemaPreview";
import { HeadingSection } from "./includes/HeadingSection";
import { TableMoveDuplicateModal } from "./includes/TableMoveDuplicateModal";
import { getMixpanel } from "common/api";
import { LatestDataPopup } from "./includes/LatestData";
import { SeerButton } from "pages/PartnerOrganisations/includes/PartnerOrganisationsButtons";
import { EmptyStateSecondary, ListPageEmptyStateDescription } from "component/EmptyState/EmptyState";

interface IDatasetTemplatePage {
  store?: Store;
}

interface ITableActionItem {
  text: string;
  icon: SemanticICONS;
  clickHandler: (e, id, name?) => void;
}

// loading container
const DatasetTemplatePageComponent = ({ store }: IDatasetTemplatePage): JSX.Element | null => {
  React.useEffect(() => {
    store!.dataset.getDatasets();
    window.scrollTo(0, 0);
  }, []);

  const { datasets } = store!.dataset;

  if ((datasets?.length || 0) < 1) {
    return null;
  }
  return <Content store={store} />;
};

const Content = ({ store }) => {
  const { datasets } = store!.dataset;
  const { datasetID, templateID } = useParams<{ datasetID: string; templateID: string }>();
  const history = useHistory();
  const currentDataset = datasets!.find(dataset => dataset.id === Number(datasetID));
  if (!currentDataset) {
    return (
      <h4 className="text-center">This dataset/template doesn't exist on your account. Please check URL.</h4>
    );
  }
  const currentTemplate = currentDataset!.templates.find(template => template.id === Number(templateID));
  if (!currentTemplate) {
    return (
      <h4 className="text-center">This dataset/template doesn't exist on your account. Please check URL.</h4>
    );
  }

  const orderedTemplateTables = currentTemplate.tables.sort((a, b) => a.name.localeCompare(b.name));

  const {name: datasetName, contributor_welcome_message: dataset_contributor_welcome_message, contributor_reminder_message: dataset_contributor_reminder_message, contributor_thank_you_message: dataset_contributor_thank_you_message, link, templates: currentDatasetTemplates } = currentDataset!;
  const { name, update_history, contributors, contributor_welcome_message, contributor_reminder_message, contributor_thank_you_message } = currentTemplate!;

  // reminder email manual send checkbox logic
  const [sendReminderLoading, setSendReminderLoading] = React.useState(false);
  const [showReminderCheckboxes, setShowReminderCheckboxes] = React.useState(false);
  const [showEmailMessagesModal, setShowEmailMessagesModal] = React.useState(false);
  const [showAddContributorModal, setShowAddContributorModal] = React.useState(false);
  const [showUpdateContributorModal, setShowUpdateContributorModal] = React.useState(false);
  const [showDeleteContributorConfirm, setShowDeleteContributorConfirm] = React.useState(false);
  const [showDuplicateToModal, setShowDuplicateToModal] = React.useState(false);
  const [showMoveToModal, setShowMoveToModal] = React.useState(false);
  const [checked, setChecked] = React.useState(contributors.map(() => false));
  const [selectedContributorID, setSelectedContributorID] = React.useState(null);
  const [selectedContributorName, setSelectedContributorName] = React.useState(null);
  const [selectedContributorEmail, setSelectedContributorEmail] = React.useState(null);
  const [activeTableIndex, setActiveTableIndex] = React.useState(null);
  const [selectedTableID, setSelectedTableID] = React.useState(null);
  const [selectedTableName, setSelectedTableName] = React.useState("");
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = React.useState(false);
  const [showDeleteTableConfirmModal, setShowDeleteTableConfirmModal] = React.useState(false);
  const toggleReminderCheckboxes = () => {
    setShowReminderCheckboxes(!showReminderCheckboxes);
    setChecked(checked.map(() => false)); // reset on toggle
  };
  const toggleCheckbox = idx => {
    const next = [...checked];
    next[idx] = !next[idx];
    setChecked(next);
  };
  const sendReminders = async () => {
    setSendReminderLoading(true);
    await new Promise(resolve => setTimeout(resolve, 1000));
    setSendReminderLoading(false);
    toggleReminderCheckboxes();
    const ids = checked.map((yes, idx) => yes ? contributors[idx].id : null).filter(id => !!id);
    const ok = await store.dataset.sendReminders(ids);
    getMixpanel(store!).track("Send Email Reminder Manually", {
      "Dataset Id": datasetID,
      "Dataset Name": datasetName,
      "Template Id": templateID,
      "Template Name": name,
      "Contributor Ids": ids,
    });
    alert(ok ? "Reminder emails sent successfully." : "Some reminder emails failed to send, please check over the reminder history after the page reloads.");
    window.location.reload();
  };

  const saveEmailMessages = async (messages) => {
    const success = await store!.dataset.updateTemplate(templateID, {
      contributor_welcome_message: messages[0].content,
      contributor_reminder_message: messages[1].content,
      contributor_thank_you_message: messages[2].content,
    });
    if (success) {
      getMixpanel(store!).track("Configure Dataset Template Emails", {
        "Dataset Id": datasetID,
        "Dataset Name": datasetName,
        "Template Id": templateID,
        "Template Name": name,
      });
      await store!.dataset.getDatasets();
    }
  };

  const addTemplateContributor = (contributor) => {
    store!.dataset.addTemplateContributor(Number(templateID), contributor);
    getMixpanel(store).track("Add Template Contributor", {
      "Dataset Id": datasetID,
      "Dataset Name": datasetName,
      "Template Id": templateID,
      "Template Name": name,
    });
  };

  const updateTemplateContributor = (contributor) => {
    store!.dataset.updateTemplateContributor(contributor);
    getMixpanel(store).track("Update Template Contributor", {
      "Dataset Id": datasetID,
      "Dataset Name": datasetName,
      "Template Id": templateID,
      "Template Name": name,
      "Contributor Id": contributor.id,
      "Contributor Email": contributor.email,
    });
  };

  const updateClickHandler = (id) => {
    setSelectedContributorID(id);
    setShowUpdateContributorModal(true);
  };

  const deleteClickHandler = ({id, name, email}) => {
    setShowDeleteContributorConfirm(true);
    setShowUpdateContributorModal(false);
    setSelectedContributorID(id);
    setSelectedContributorName(name);
    setSelectedContributorEmail(email);
  };

  const deleteContributor = () => {
    store!.dataset.deleteTemplateContributor(selectedContributorID);
    getMixpanel(store).track("Remove Template Contributor", {
      "Dataset Id": datasetID,
      "Dataset Name": datasetName,
      "Template Id": templateID,
      "Template Name": name,
      "Contributor Id": selectedContributorID,
      "Contributor Email": selectedContributorEmail,
    });
    setShowDeleteContributorConfirm(false);
    setShowUpdateContributorModal(false);
  };

  const handleTableNameClick = (index) => {
    const newIndex = activeTableIndex === index ? -1 : index;
    setActiveTableIndex(newIndex);
  };

  const editTableClickHandler = (e, tableID) => {
    e.stopPropagation();
    history.push(`/datasets/${datasetID}/templates/${templateID}/tables/${tableID}`);
  };

  const duplicateToClickHandler = (e, clickedTableID, clickedTableName) => {
    e.stopPropagation();
    setSelectedTableID(clickedTableID);
    setSelectedTableName(clickedTableName);
    setShowDuplicateToModal(true);
  };

  const deleteTableClickHandler = (e, clickedTableID, clickedTableName) => {
    e.stopPropagation();
    setSelectedTableID(clickedTableID);
    setSelectedTableName(clickedTableName);
    setShowDeleteTableConfirmModal(true);
  };

  const deleteTable = async () => {
    const success = await store!.dataset.deleteDatasetTemplateTable(selectedTableID);
    if (success) {
      getMixpanel(store!).track("Delete Dataset Template Table", {
        "Dataset Id": datasetID,
        "Dataset Name": datasetName,
        "Template Id": templateID,
        "Template Name": name,
        "Table Id": selectedTableID,
        "Table Name": selectedTableName,
      });
      await store!.dataset.getDatasets();
      setShowDeleteTableConfirmModal(false);
    }
  };

  const duplicateTableTo = async (selectedTemplateID) => {
    const success = await store!.dataset.duplicateDatasetTemplateTable(selectedTableID, selectedTemplateID);
    setShowDuplicateToModal(false);
    if (success) {
      getMixpanel(store).track("Duplicate Dataset Template Table", {
        "Dataset Id": datasetID,
        "Dataset Name": datasetName,
        "Template Id": templateID,
        "Template Name": name,
        "Table Id": selectedTableID,
        "Table Name": selectedTableName,
      });
      store!.dataset.getDatasets();
    }
  };

  const moveToClickHandler = (e, clickedTableID, clickedTableName) => {
    e.stopPropagation();
    setSelectedTableID(clickedTableID);
    setSelectedTableName(clickedTableName);
    setShowMoveToModal(true);
  };

  const moveTableTo = async (selectedTemplateID) => {
    const success = await store!.dataset.moveDatasetTemplateTable(selectedTableID, selectedTemplateID);
    setShowMoveToModal(false);
    if (success) {
      getMixpanel(store).track("Move Dataset Template Table", {
        "Dataset Id": datasetID,
        "Dataset Name": datasetName,
        "Template Id": templateID,
        "Template Name": name,
        "Table Id": selectedTableID,
        "Table Name": selectedTableName,
      });
      store!.dataset.getDatasets();
    }
  };

  const editTableDataClickHandler = (e, selectedTableID) => {
    e.stopPropagation();
    history.push(`/datasets/${datasetID}/templates/${templateID}/tables/${selectedTableID}/data`);
  };

  const deleteTemplateClickHandler = async () => {
    const success = await store!.dataset.deleteTemplate(+templateID);
    if (success) {
      await store!.dataset.getDatasets();
      getMixpanel(store!).track("Delete Dataset Template");
      history.push(`/datasets/${datasetID}`);
    }
  };

  const duplicateTemplate = async () => {
    const newTemplateID = await store!.dataset.duplicateTemplate(templateID);
    getMixpanel(store!).track("Duplicate Dataset Template", {
      "Dataset Id": datasetID,
      "Dataset Name": datasetName,
      "Template Id": templateID,
      "Template Name": name,
    });
    if (newTemplateID) {
      history.push(`/datasets/${datasetID}/templates/${newTemplateID}`);
    }
  };

  const renameTemplate = async (nameStr: string): Promise<any> => {
    const name = (nameStr || "").trim();
    if (!name) {
      return "Template name is required";
    }
    if (currentDataset?.templates.filter(template => template.id !== +templateID).find(template => template.name === name)) {
      // @TODO - this part of the validation should be moved to the backend
      return "This template name already exists in the dataset";
    }
    const success = await store!.dataset.updateTemplate(+templateID, { name });
    if (success) {
      await store!.dataset.getDatasets();
      getMixpanel(store!).track("Rename Dataset Template");
    }
  };

  const tableActionItems: ITableActionItem[] = [
    { text: "Edit Schema", icon: "table", clickHandler: editTableClickHandler },
    { text: "Edit Data", icon: "edit", clickHandler: editTableDataClickHandler },
  ];
  const dropdownActionItems: ITableActionItem[] = [
    { text: "Duplicate to", icon: "copy", clickHandler: duplicateToClickHandler },
    { text: "Move to", icon: "sign in", clickHandler: moveToClickHandler },
    { text: "Delete", icon: "trash", clickHandler: deleteTableClickHandler },
  ];

  const tabItems = [
    {
      menuItem: "Tables",
      render: () => (
        <>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <p className="fs-1125 fw-600 text-muted mb-0">
              {`${orderedTemplateTables.length} table(s) created in this template`}
            </p>
            <Button
              id="userflow-element-create-table"
              size="tiny"
              className="bg-primary text-white"
              onClick={() => history.push(`/datasets/${datasetID}/templates/${templateID}/tables/new`)}
            >
              Create Table<Icon className="plus ml-2 mr-0" />
            </Button>
          </div>
          {orderedTemplateTables.length ? (
            <Accordion>
              {orderedTemplateTables.map((table, index) => {
                const { id, name, schema } = table;
                return (
                  <div key={id}>
                    <Accordion.Title
                      active={activeTableIndex === index}
                      index={index}
                      onClick={() => handleTableNameClick(index)}
                      className="py-1"
                    >
                      <div className="d-flex align-items-start justify-content-between bg-lightgrey px-2">
                        <div className="fs-1125 fw-700 w-35 d-flex p-2">
                          <Icon name="dropdown" className="mt-1" /><p>{name}</p>
                        </div>
                        <div className="d-flex text-secondary">
                          {
                            tableActionItems.map(item => {
                              const { text, icon, clickHandler } = item;
                              return (
                                <div key={text} className="cursor-pointer p-2" onClick={(e) => clickHandler(e, id)} role="button">
                                  <p className="fs-1000 fw-700 mb-0">{text} <Icon name={icon} className="ml-2" /></p>
                                </div>
                              );
                            })
                          }
                          <LatestDataPopup
                            type="table"
                            data={{ ...table, dataset: currentDataset }}
                            triggerOverride={(
                              <Button data-lastupdatetoggle="true" size="small" className="bg-none text-secondary p-2 mr-0">
                                Latest Data <Icon name="history" className="ml-2 opacity-1" />
                              </Button>
                            )}
                          />
                          <Dropdown text="More" icon="ellipsis vertical" className="fs-1000 p-2">
                            <Dropdown.Menu>
                              {dropdownActionItems.map(item => {
                                const { text, icon, clickHandler } = item;
                                return <Dropdown.Item key={text} text={text} icon={icon} onClick={(e) => clickHandler(e, id, name)} />;
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeTableIndex === index} className="pt-0">
                      <TableSchemaPreview schema={schema} />
                    </Accordion.Content>
                  </div>
                );
              })}
            </Accordion>
          ) : (
            <EmptyStateSecondary
              heading="No Tables Created Yet"
              description={(
                <ListPageEmptyStateDescription
                  description="Here is where you will find tables created for this dataset template."
                  link="https://knowledge.seerdata.ai/data-collection#data-table-concepts"
                  text="tables"
                />
              )}
            />
          )}
        </>
      ),
    },
    {
      menuItem: "Update history",
      render: () => (
        <DatasetTable
          tableFor="Update history"
          tableHeader={["Name", "Email", "Update date"]}
          tableData={sortByDate(update_history)}
        />
      ),
    },
    {
      menuItem: "Contributors",
      render: () => (
        <>
          <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start  align-items-sm-center">
            {showReminderCheckboxes ? (
              <div>
                <p className="fs-1000">Check the boxes next to the contributors you wish to send out an email reminder to and hit Send</p>
                <SeerButton category="secondary" basic size="tiny" onClick={toggleReminderCheckboxes} label="Cancel" />
                <SeerButton category="primary" size="tiny" onClick={sendReminders} disabled={!checked.some(item => !!item)} label="Send" />
              </div>
            ) : (
              <SeerButton category="secondary" basic size="tiny" onClick={toggleReminderCheckboxes} label="Send out a reminder manually" />
            )}
            {
              !showReminderCheckboxes &&
              <SeerButton category="primary" size="tiny" onClick={() => setShowAddContributorModal(true)} label="Add Contributor" icon="plus" />
            }
          </div>
          <DatasetTable
            tableFor="Contributors"
            tableHeader={[...(showReminderCheckboxes ? [""] : []), "Name", "Email", "Reminder start date", "Reminder interval", "Reminder history", "Actions"]}
            tableData={contributors!}
            toggleCheckbox={showReminderCheckboxes ? toggleCheckbox : undefined}
            checked={showReminderCheckboxes ? checked : undefined}
            updateClickHandler={updateClickHandler}
          />
        </>
      ),
    },
  ];

  return (
    <div style={{ maxWidth: "940px", margin: "0 auto" }}>
      {sendReminderLoading && <ShowLoaderGlobal />}
      <Helmet>
        <title>{`${name} #${templateID} - ${datasetName} #${datasetID}`}</title>
      </Helmet>
      <TableMoveDuplicateModal
        action="Duplicate"
        isOpen={showDuplicateToModal}
        closeModal={() => setShowDuplicateToModal(false)}
        actionClickHandler={duplicateTableTo}
        currentTemplateID={Number(templateID)}
        templateList={currentDatasetTemplates}
      />
      <TableMoveDuplicateModal
        action="Move"
        isOpen={showMoveToModal}
        closeModal={() => setShowMoveToModal(false)}
        actionClickHandler={moveTableTo}
        templateList={currentDatasetTemplates.filter(template => template.id !== Number(templateID))}
      />
      {/* Delete Dataset Template Confirm Modal */}
      <StyleConfirm
        open={showDeleteConfirmModal}
        header="Are you sure?"
        content={`Are you you want to delete - ${name}`}
        confirmButton="Delete"
        onCancel={() => setShowDeleteConfirmModal(false)}
        onConfirm={deleteTemplateClickHandler}
      />
      {/* Delete Dataset Template Table Confirm Modal */}
      <StyleConfirm
        open={showDeleteTableConfirmModal}
        header="Are you sure?"
        content={`Are you you want to delete - ${orderedTemplateTables.find(table => table.id === selectedTableID)?.name}`}
        confirmButton="Delete"
        onCancel={() => setShowDeleteTableConfirmModal(false)}
        onConfirm={deleteTable}
      />
      <Breadcrumbs
        items={[
          { label: "Datasets", pathname: "/datasets" },
          { label: datasetName, pathname: `/datasets/${datasetID}` },
          { label: name, pathname: `/datasets/${datasetID}/templates/${templateID}` },
        ]}
      />
      <HeadingSection
        name={name}
        onNameChange={renameTemplate}
        items={[
          // Only Maranguka templates have "link" and all their templates can't be duplicated. So only show "Duplicate" when "link" doesn't exist
          link ? { type: "link", label: "Template Link", icon: "external alternate", link } : { type: "button-secondary", label: "Duplicate", icon: "copy", action: () => duplicateTemplate() },
          { type: "button-secondary", label: "Configure Emails", icon: "mail", action: () => setShowEmailMessagesModal(true) },
        ]}
        actionsDropdown={[
          { key: 1, text: "Delete", value: 1, onClick: () => setShowDeleteConfirmModal(true) },
        ]}
      />
      {/* Edit template email messages */}
      <EmailMessagesModal
        subHeading={`Datasets > ${datasetName} > ${name}`}
        isOpen={showEmailMessagesModal}
        emailMessages={[
          { title: "Welcome Message", content: contributor_welcome_message, extraContent: dataset_contributor_welcome_message },
          { title: "Reminder Message", content: contributor_reminder_message, extraContent: dataset_contributor_reminder_message },
          { title: "Thank You Message", content: contributor_thank_you_message, extraContent: dataset_contributor_thank_you_message },
        ]}
        closeModal={() => setShowEmailMessagesModal(false)}
        saveModal={saveEmailMessages}
      />
      {/* Add template contributor modal */}
      <TemplateContributorModal
        isOpen={showAddContributorModal}
        closeModal={() => setShowAddContributorModal(false)}
        saveModal={addTemplateContributor}
        actionText="Add"
      />
      {/* Update template contributor modal */}
      <TemplateContributorModal
        isOpen={showUpdateContributorModal}
        contributorDetails={contributors.find(c => c.id === selectedContributorID)}
        closeModal={() => setShowUpdateContributorModal(false)}
        saveModal={updateTemplateContributor}
        deleteClickHandler={deleteClickHandler}
        actionText="Update"
      />
      {/* Delete contributor confirm popup */}
      <StyleConfirm
        open={showDeleteContributorConfirm}
        header="Are you sure you want to delete this contributor:?"
        content={`${selectedContributorName} (${selectedContributorEmail})`}
        confirmButton="Delete"
        onCancel={() => {
          setShowDeleteContributorConfirm(false);
          setShowUpdateContributorModal(true);
        }}
        onConfirm={() => deleteContributor()}
      />
      <StyleTab
        menu={{ secondary: true, pointing: true }}
        panes={tabItems}
      />
    </div>
  );
};

export const DatasetTemplatePage = Protected(inject("store")(observer(DatasetTemplatePageComponent)));
