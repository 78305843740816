import * as React from "react";
import { Icon } from "semantic-ui-react";
import { COLORS } from "component/UI/common";

interface SwitchProps {
  label: string;
  handleOnClick: () => void;
  className?: string;
};

export const ExploreSwitch = (props: SwitchProps): JSX.Element => {
  const { label, handleOnClick, className } = props;
  return (
    // TODO: update button component when we finalize Seer button design
    <div onClick={handleOnClick} className={`d-flex align-items-center text-secondary cursor-pointer fs-1000 rounded-2 ${className}`} style={{ border: `1px solid ${COLORS.indigo600}`, padding: "0.4rem 1rem" }}>
      <p className="fw-600 fs-1000 mr-1 mb-0">{label}</p>
      <Icon name="arrow right" style={{ fontSize: 16, marginRight: -2 }}/>
    </div>
  );
};
