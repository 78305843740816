import * as React from "react";
import { Icon, Button, Tab, Menu, Checkbox, Divider, Grid, Form, Popup, Label } from "semantic-ui-react";
import styled from "styled-components";
import { cloneDeep, set } from "lodash";
import * as copy from "copy-to-clipboard";
import { ChromePicker } from "react-color";

import { Modal } from "component/UI/Modal";
import { ColorCircle } from "component/ColorCircle";
import { customFonts } from "component/Unlayer/DashboardUnlayerEditor";
import { getPageTitles, DashboardNavigationConfig, UnlayerConfig } from "./helpers";
import { webUrl } from "common/constants";

const navFonts = [{ label: "Open Sans", value: "Open Sans", url: "none" }, ...customFonts];
const navFontsSemanticOpts = navFonts.map((font) => ({ ...font, text: font.label, label: undefined }));

const fontWeightSemanticOpts = [
  { text: "Light", value: 300 },
  { text: "Normal", value: 400 },
  { text: "Bold", value: 700 },
  { text: "Extra Bold", value: 800 },
];

const maxWidthTypeOpts = [
  { text: "px", value: "px" },
  { text: "%", value: "%" },
];

const StyledTabPane = styled(Tab.Pane)`
  border: none !important;
  box-shadow: none !important;
`;

interface ColorPickerProps {
  color?: string;
  updateColor: (color: string | undefined) => void;
}

const ColorPicker = (props: ColorPickerProps) => {
  const { color, updateColor } = props;
  const [open, setOpen] = React.useState(false);
  const [tempColor, setTempColor] = React.useState(color || "#000000");

  const onOpen = () => {
    setTempColor(color || "#000000");
    setOpen(true);
  };

  return (
    <Popup
      open={open}
      trigger={
        <div style={{ width: "fit-content" }}>
          {!color ? (
            <Icon name="plus" circular color="grey" className="cursor-pointer" />
          ) : (
            <ColorCircle color={color} colorIndex={0} handleCircleOnClick={onOpen} handleEditOnClick={onOpen} showOutline />
          )}
        </div>
      }
      on="click"
      onOpen={onOpen}
      onClose={() => setOpen(false)}
      content={
        <div>
          <ChromePicker color={tempColor} onChange={({ hex }) => setTempColor(hex)} disableAlpha />
          <div className="d-flex justify-content-between mt-3">
            <Button
              onClick={() => {
                setOpen(false);
              }}
              color="red"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                updateColor(undefined);
                setOpen(false);
              }}
              basic
            >
              Clear
            </Button>
            <Button
              onClick={() => {
                updateColor(tempColor);
                setOpen(false);
              }}
              basic
            >
              Save
            </Button>
          </div>
        </div>
      }
    />
  );
};

export const defaultNavConfig = (unlayer_config: UnlayerConfig): DashboardNavigationConfig => {
  const images = unlayer_config.pages.map(() => ({ default: "", hover: "" }));
  return {
    titles: getPageTitles(unlayer_config),
    includedPages: unlayer_config.pages.map(() => true),
    customNav: false,
    customNavConf: {
      showInbuiltControls: true,
      position: "relative",
      styling: {
        layout: "horizontal",
        alignment: "center",
        padding: 6,
        background: "#ffffff",
        images,
        items: {
          padding: 6,
          margin: 6,
          font: {
            value: "Open Sans",
            url: "none", // not needed for Open Sans as already loaded into site
            fontSize: 14,
            fontWeight: 400,
          },
          default: {
            useBorder: true,
            borderWidth: 1,
            borderRadius: 8,
            borderColor: "#000000",
            color: "#000000",
            background: "none",
          },
          hover: {
            useBorder: true,
            borderWidth: 1,
            borderRadius: 8,
          },
        },
        maxWidthType: "px",
      },
    },
  };
};

interface Props {
  id: number;
  publicKey: string | null;
  unlayer_config: UnlayerConfig;
  imageUploadHandler: (file: File) => Promise<Record<string, any>>;
  cancel: () => void;
  save: (updates: any) => void;
}

export const ModalNavConfig = (props: Props) => {
  const { id, publicKey, unlayer_config, imageUploadHandler, cancel, save } = props;
  const [config, setConfig] = React.useState<DashboardNavigationConfig>(unlayer_config.navigation || defaultNavConfig(unlayer_config));
  const imagesActive = config.customNavConf.styling.images.some((img) => !!img.default);

  const updateConfig = (updates: [path: string, value: any][]) => {
    const nextConfig = cloneDeep(config);
    for (const update of updates) {
      const [path, value] = update;
      set(nextConfig, path, value);
    }
    setConfig(nextConfig);
  };

  const copyPageLink = (pgIdx) => {
    const pageLink = `${webUrl}/dashboards/${id}${publicKey ? `/${publicKey}` : ""}?page=${pgIdx}`;
    try {
      navigator.clipboard.writeText(pageLink);
      alert(`Page link copied: ${pageLink}`);
    } catch (err) {
      console.error("Failed to copy", err);
    }
  };

  return (
    <Modal open>
      <Modal.Header>Dashboard pages navigation</Modal.Header>
      <Modal.Content>
        <div className="text-muted mb-3">
          <Icon name="info circle" /> If desired, copy HTML for use with custom navigation positioning. Read more about how to use this
          feature{" "}
          <a href="https://knowledge.seerdata.ai/working-with-dashboard-pages" target="_blank">
            here
          </a>
          .
        </div>
        <Button
          className="mb-3"
          color="teal"
          basic
          onClick={() => copy('<div id="seer-dash-nav-custom-position">Custom page navigation will be shown here</div>')}
        >
          Copy HTML
        </Button>
        <Tab
          menu={{ secondary: true, pointing: true, widths: 3 }}
          panes={[
            {
              menuItem: "Settings",
              render: () => (
                <StyledTabPane>
                  <h4>Customise navigation</h4>
                  <Checkbox
                    toggle
                    checked={config.customNav}
                    onClick={() => {
                      const on = !config.customNav;
                      const updates: any = [["customNav", on]];
                      if (!on) {
                        updates.push(["customNavConf.showInbuiltControls", true]);
                      }
                      updateConfig(updates);
                    }}
                  />
                  <Divider />
                  <h4>Default page controls</h4>
                  <Checkbox
                    className="mb-3"
                    toggle
                    checked={config.customNavConf.showInbuiltControls}
                    disabled={!config.customNav}
                    onClick={() => updateConfig([["customNavConf.showInbuiltControls", !config.customNavConf.showInbuiltControls]])}
                  />
                  <div className="text-muted mb-3">
                    <Icon name="info circle" /> This controls whether the default page controls display for viewers, this can be disabled
                    when custom navigation is in use.
                  </div>
                  <h4>Custom navigation positioning</h4>
                  <Button.Group basic fluid>
                    <Button
                      disabled={!config.customNav}
                      active={config.customNav && config.customNavConf.position === "relative"}
                      onClick={() => updateConfig([["customNavConf.position", "relative"]])}
                    >
                      Top
                    </Button>
                    <Button
                      disabled={!config.customNav}
                      active={config.customNav && config.customNavConf.position === "sticky"}
                      onClick={() => updateConfig([["customNavConf.position", "sticky"]])}
                    >
                      Sticky
                    </Button>
                    <Button
                      disabled={!config.customNav}
                      active={config.customNav && config.customNavConf.position === "custom"}
                      onClick={() => updateConfig([["customNavConf.position", "custom"]])}
                    >
                      Custom HTML
                    </Button>
                  </Button.Group>
                </StyledTabPane>
              ),
            },
            {
              menuItem: <Menu.Item disabled={!config.customNav}>Customisations</Menu.Item>,
              render: () => (
                <StyledTabPane>
                  <h4>Navigation bar design</h4>
                  <Grid columns={2}>
                    <Grid.Row>
                      <Grid.Column>
                        <h5>Orientation</h5>
                        <Button.Group basic fluid>
                          <Button
                            active={config.customNavConf.styling.layout === "horizontal"}
                            onClick={() => updateConfig([["customNavConf.styling.layout", "horizontal"]])}
                          >
                            Horizontal <Icon name="arrow circle right" />
                          </Button>
                          <Button
                            active={config.customNavConf.styling.layout === "vertical"}
                            onClick={() => updateConfig([["customNavConf.styling.layout", "vertical"]])}
                          >
                            Vertical <Icon name="arrow circle up" />
                          </Button>
                        </Button.Group>
                      </Grid.Column>
                      <Grid.Column>
                        <h5>Alignment and spacing</h5>
                        <Button.Group basic>
                          <Button
                            active={config.customNavConf.styling.alignment === "left"}
                            onClick={() => updateConfig([["customNavConf.styling.alignment", "left"]])}
                            className="py-1 px-3"
                          >
                            <img src="/assets/icons/icon-align-left.svg" alt="Align left" width={28} height={28} title="Align left" />
                          </Button>
                          <Button
                            active={config.customNavConf.styling.alignment === "center"}
                            onClick={() => updateConfig([["customNavConf.styling.alignment", "center"]])}
                            className="py-1 px-3"
                          >
                            <img src="/assets/icons/icon-align-center.svg" alt="Align center" width={28} height={28} title="Align center" />
                          </Button>
                          <Button
                            active={config.customNavConf.styling.alignment === "right"}
                            onClick={() => updateConfig([["customNavConf.styling.alignment", "right"]])}
                            className="py-1 px-3"
                          >
                            <img src="/assets/icons/icon-align-right.svg" alt="Align right" width={28} height={28} title="Align right" />
                          </Button>
                          <Button
                            active={config.customNavConf.styling.alignment === "flex between"}
                            onClick={() => updateConfig([["customNavConf.styling.alignment", "flex between"]])}
                            className="py-1 px-3"
                          >
                            <img
                              src="/assets/icons/icon-flex-between.svg"
                              alt="Align flex between"
                              width={28}
                              height={28}
                              title="Justify"
                            />
                          </Button>
                        </Button.Group>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <h5>Background colour</h5>
                        <ColorPicker
                          updateColor={(color) => updateConfig([["customNavConf.styling.background", color]])}
                          color={config.customNavConf.styling.background}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <div className="d-flex justify-content-between">
                          <h5>Padding (px)</h5>
                          <Checkbox
                            toggle
                            label="More options"
                            checked={typeof config.customNavConf.styling.padding !== "number"}
                            onClick={() => {
                              if (typeof config.customNavConf.styling.padding !== "number") {
                                updateConfig([["customNavConf.styling.padding", config.customNavConf.styling.padding.top]]);
                              } else {
                                const value = config.customNavConf.styling.padding;
                                updateConfig([["customNavConf.styling.padding", { top: value, left: value, bottom: value, right: value }]]);
                              }
                            }}
                          />
                        </div>
                        <Form>
                          {typeof config.customNavConf.styling.padding === "number" ? (
                            <Form.Group>
                              <Form.Input
                                label="All sides"
                                type="number"
                                value={config.customNavConf.styling.padding}
                                onChange={(_e, { value }) => {
                                  updateConfig([["customNavConf.styling.padding", value ? Number(value) : 0]]);
                                }}
                                min={0}
                              />
                            </Form.Group>
                          ) : (
                            <>
                              <Form.Group>
                                <Form.Input
                                  label="Top"
                                  type="number"
                                  value={config.customNavConf.styling.padding.top}
                                  onChange={(_e, { value }) => {
                                    updateConfig([["customNavConf.styling.padding.top", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                />
                                <Form.Input
                                  label="Right"
                                  type="number"
                                  value={config.customNavConf.styling.padding.right}
                                  onChange={(_e, { value }) => {
                                    updateConfig([["customNavConf.styling.padding.right", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Input
                                  label="Bottom"
                                  type="number"
                                  value={config.customNavConf.styling.padding.bottom}
                                  onChange={(_e, { value }) => {
                                    updateConfig([["customNavConf.styling.padding.bottom", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                />
                                <Form.Input
                                  label="Left"
                                  type="number"
                                  value={config.customNavConf.styling.padding.left}
                                  onChange={(_e, { value }) => {
                                    updateConfig([["customNavConf.styling.padding.left", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                />
                              </Form.Group>
                            </>
                          )}
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <h5>Max width</h5>
                        <Form>
                          <Form.Group>
                            <Form.Input
                              label="Value"
                              type="number"
                              className="w-50"
                              value={config.customNavConf.styling.maxWidth}
                              onChange={(_e, { value }) => {
                                updateConfig([["customNavConf.styling.maxWidth", value ? Number(value) : undefined]]);
                              }}
                              min={0}
                              max={config.customNavConf.styling.maxWidthType === "%" ? 100 : undefined}
                            />
                            <Form.Select
                              label="Type"
                              className="w-50"
                              options={maxWidthTypeOpts}
                              value={config.customNavConf.styling.maxWidthType}
                              onChange={(_e, { value }) => {
                                updateConfig([["customNavConf.styling.maxWidthType", value]]);
                              }}
                            />
                          </Form.Group>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Divider />
                  <h4>Text</h4>
                  <Grid columns={2}>
                    <Grid.Row>
                      <Grid.Column>
                        <h5>Text colour</h5>
                        <ColorPicker
                          updateColor={(color) => updateConfig([["customNavConf.styling.items.default.color", color]])}
                          color={config.customNavConf.styling.items.default.color}
                        />
                        <h5>Text hover colour</h5>
                        <ColorPicker
                          updateColor={(color) => updateConfig([["customNavConf.styling.items.hover.color", color]])}
                          color={config.customNavConf.styling.items.hover.color}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Form>
                          <Form.Select
                            label="Font family"
                            options={navFontsSemanticOpts}
                            value={config.customNavConf.styling.items.font.value}
                            onChange={(_e, { value }) => {
                              const nextFont = navFonts.find((font) => font.value === value)!;
                              updateConfig([
                                ["customNavConf.styling.items.font.value", nextFont.value],
                                ["customNavConf.styling.items.font.url", nextFont.url],
                              ]);
                            }}
                          />
                          <Form.Select
                            label="Font weight"
                            options={fontWeightSemanticOpts}
                            value={config.customNavConf.styling.items.font.fontWeight}
                            onChange={(_e, { value }) => {
                              updateConfig([["customNavConf.styling.items.font.fontWeight", value]]);
                            }}
                          />
                          <Form.Input
                            label="Font size (px)"
                            type="number"
                            value={config.customNavConf.styling.items.font.fontSize}
                            onChange={(_e, { value }) => {
                              updateConfig([["customNavConf.styling.items.font.fontSize", value ? Number(value) : 1]]);
                            }}
                            min={1}
                          />
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Divider />
                  <h4>
                    Page item design
                    <Popup
                      trigger={<Icon name="info circle" size="small" className="ml-2" />}
                      content={
                        <span>
                          Configure the colours and layout for each navigation item in your navigation bar. The colours you choose for the
                          hover state will also be used as the active state.
                        </span>
                      }
                    />
                  </h4>
                  <Grid columns={2}>
                    <Grid.Row>
                      <Grid.Column>
                        <h5>Item background colour</h5>
                        <ColorPicker
                          updateColor={(color) => updateConfig([["customNavConf.styling.items.default.background", color]])}
                          color={config.customNavConf.styling.items.default.background}
                        />
                        <h5>Item background hover colour</h5>
                        <ColorPicker
                          updateColor={(color) => updateConfig([["customNavConf.styling.items.hover.background", color]])}
                          color={config.customNavConf.styling.items.hover.background}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <div className="d-flex justify-content-between">
                          <h5>Item padding (px)</h5>
                          <Checkbox
                            toggle
                            label="More options"
                            checked={typeof config.customNavConf.styling.items.padding !== "number"}
                            onClick={() => {
                              if (typeof config.customNavConf.styling.items.padding !== "number") {
                                updateConfig([["customNavConf.styling.items.padding", config.customNavConf.styling.items.padding.top]]);
                              } else {
                                const value = config.customNavConf.styling.items.padding;
                                updateConfig([
                                  [
                                    "customNavConf.styling.items.padding",
                                    {
                                      top: value,
                                      left: value,
                                      bottom: value,
                                      right: value,
                                    },
                                  ],
                                ]);
                              }
                            }}
                          />
                        </div>
                        <Form>
                          {typeof config.customNavConf.styling.items.padding === "number" ? (
                            <Form.Group>
                              <Form.Input
                                label="All sides"
                                type="number"
                                value={config.customNavConf.styling.items.padding}
                                onChange={(_e, { value }) => {
                                  updateConfig([["customNavConf.styling.items.padding", value ? Number(value) : 0]]);
                                }}
                                min={0}
                              />
                            </Form.Group>
                          ) : (
                            <>
                              <Form.Group>
                                <Form.Input
                                  label="Top"
                                  type="number"
                                  value={config.customNavConf.styling.items.padding.top}
                                  onChange={(_e, { value }) => {
                                    updateConfig([["customNavConf.styling.items.padding.top", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                />
                                <Form.Input
                                  label="Right"
                                  type="number"
                                  value={config.customNavConf.styling.items.padding.right}
                                  onChange={(_e, { value }) => {
                                    updateConfig([["customNavConf.styling.items.padding.right", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Input
                                  label="Bottom"
                                  type="number"
                                  value={config.customNavConf.styling.items.padding.bottom}
                                  onChange={(_e, { value }) => {
                                    updateConfig([["customNavConf.styling.items.padding.bottom", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                />
                                <Form.Input
                                  label="Left"
                                  type="number"
                                  value={config.customNavConf.styling.items.padding.left}
                                  onChange={(_e, { value }) => {
                                    updateConfig([["customNavConf.styling.items.padding.left", value ? Number(value) : 0]]);
                                  }}
                                  min={0}
                                />
                              </Form.Group>
                            </>
                          )}
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <h5>Border colour</h5>
                        <ColorPicker
                          updateColor={(color) => updateConfig([["customNavConf.styling.items.default.borderColor", color]])}
                          color={config.customNavConf.styling.items.default.borderColor}
                        />
                        <h5>Border hover colour</h5>
                        <ColorPicker
                          updateColor={(color) => updateConfig([["customNavConf.styling.items.hover.borderColor", color]])}
                          color={config.customNavConf.styling.items.hover.borderColor}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <h5>Border width (px)</h5>
                        <Form>
                          <Form.Group>
                            <Form.Input
                              label="All sides"
                              type="number"
                              value={config.customNavConf.styling.items.default.borderWidth}
                              onChange={(_e, { value }) => {
                                updateConfig([
                                  ["customNavConf.styling.items.default.borderWidth", value ? Number(value) : 0],
                                  // note we are also updating the hover borderWidth as there is no UI config for this yet, but it needs to match default to avoid jitter on hover
                                  ["customNavConf.styling.items.hover.borderWidth", value ? Number(value) : 0],
                                ]);
                              }}
                              min={0}
                            />
                          </Form.Group>
                        </Form>
                        <h5>Border radius (px)</h5>
                        <Form>
                          <Form.Group>
                            <Form.Input
                              label="All sides"
                              type="number"
                              value={config.customNavConf.styling.items.default.borderRadius}
                              onChange={(_e, { value }) => {
                                updateConfig([
                                  ["customNavConf.styling.items.default.borderRadius", value ? Number(value) : 0],
                                  // note we are also updating the hover borderRadius as there is no UI config for this yet, match default for now
                                  ["customNavConf.styling.items.hover.borderRadius", value ? Number(value) : 0],
                                ]);
                              }}
                              min={0}
                            />
                          </Form.Group>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Divider />
                  <div className="d-flex justify-content-between mb-3">
                    <h4>
                      Images in place of links
                      <Popup
                        trigger={<Icon name="info circle" size="small" className="ml-2" />}
                        content={
                          <span>
                            When images are uploaded for navigation items, they will replace the customisations set in the panels above. To
                            restore your customisations, remove the images.
                          </span>
                        }
                      />
                    </h4>
                    {imagesActive ? (
                      <Label color="green" className="mb-4">
                        Active
                      </Label>
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </div>
                  <div>
                    <h5>
                      Image sizing (optional)
                      <Popup
                        trigger={<Icon name="info circle" size="small" className="ml-2" />}
                        content={
                          <ul>
                            <li>
                              Setting a default width and height will override the native image sizing and ensure all navigation images are
                              sized consistently
                            </li>
                            <li>Setting a percentage (%) width will limit the size of the image relative to the size of the screen</li>
                            <li>Setting a maximum pixel (px) width prevents oversized images when using % width</li>
                          </ul>
                        }
                      />
                    </h5>
                    <Form>
                      <Form.Group>
                        <Form.Input
                          label="Default width (px)"
                          type="number"
                          value={config.customNavConf.styling.imageWidth}
                          onChange={(_e, { value }) => {
                            updateConfig([["customNavConf.styling.imageWidth", value ? Number(value) : undefined]]);
                          }}
                          min={0}
                        />
                        <Form.Input
                          label="Default height (px)"
                          type="number"
                          value={config.customNavConf.styling.imageHeight}
                          onChange={(_e, { value }) => {
                            updateConfig([["customNavConf.styling.imageHeight", value ? Number(value) : undefined]]);
                          }}
                          min={0}
                        />
                        <Form.Input
                          label="Width (%)"
                          type="number"
                          value={config.customNavConf.styling.imageWidthPercent}
                          onChange={(_e, { value }) => {
                            updateConfig([["customNavConf.styling.imageWidthPercent", value ? Number(value) : undefined]]);
                          }}
                          min={0}
                        />
                        <Form.Input
                          label="Max width (px)"
                          type="number"
                          value={config.customNavConf.styling.imageWidthMax}
                          onChange={(_e, { value }) => {
                            updateConfig([["customNavConf.styling.imageWidthMax", value ? Number(value) : undefined]]);
                          }}
                          min={0}
                        />
                      </Form.Group>
                    </Form>
                  </div>
                  <h5>Images</h5>
                  <Tab
                    menu={{ vertical: true, tabular: true, fluid: true }}
                    panes={unlayer_config.pages.map((pg, idx) => {
                      const defaultImg = config.customNavConf.styling.images[idx].default;
                      const hoverImg = config.customNavConf.styling.images[idx].hover;
                      return {
                        menuItem: `Page ${idx + 1}`,
                        render: () => (
                          <StyledTabPane key={`pg${idx}-${pg.id || "NA"}`}>
                            <Grid columns={2}>
                              <Grid.Row>
                                <Grid.Column>
                                  <h5>Item default image</h5>
                                  {defaultImg ? (
                                    <Button
                                      color="red"
                                      onClick={() => updateConfig([[`customNavConf.styling.images[${idx}].default`, ""]])}
                                    >
                                      Remove image
                                    </Button>
                                  ) : (
                                    <Form>
                                      <Form.Field>
                                        <input
                                          type="file"
                                          accept="image/*"
                                          onChange={async (e) => {
                                            if (e.target.files && e.target.files.length > 0) {
                                              const res = await imageUploadHandler(e.target.files[0]);
                                              if (res && !res.error && res.location) {
                                                updateConfig([[`customNavConf.styling.images[${idx}].default`, res.location]]);
                                              } else {
                                                alert("Image failed to upload");
                                              }
                                            }
                                          }}
                                        />
                                      </Form.Field>
                                    </Form>
                                  )}
                                </Grid.Column>
                                <Grid.Column>
                                  {defaultImg && (
                                    <div style={{ width: 150, height: 150 }}>
                                      <img
                                        src={defaultImg}
                                        alt={`Default image for page ${idx + 1} link`}
                                        style={{ objectFit: "contain", width: "100%", height: "100%" }}
                                        title={defaultImg}
                                      />
                                    </div>
                                  )}
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row>
                                <Grid.Column>
                                  <h5>Item hover image</h5>
                                  {hoverImg ? (
                                    <Button color="red" onClick={() => updateConfig([[`customNavConf.styling.images[${idx}].hover`, ""]])}>
                                      Remove image
                                    </Button>
                                  ) : (
                                    <Form>
                                      <Form.Field>
                                        <input
                                          type="file"
                                          accept="image/*"
                                          onChange={async (e) => {
                                            if (e.target.files && e.target.files.length > 0) {
                                              const res = await imageUploadHandler(e.target.files[0]);
                                              if (res && !res.error && res.location) {
                                                updateConfig([[`customNavConf.styling.images[${idx}].hover`, res.location]]);
                                              } else {
                                                alert("Image failed to upload");
                                              }
                                            }
                                          }}
                                        />
                                      </Form.Field>
                                    </Form>
                                  )}
                                </Grid.Column>
                                <Grid.Column>
                                  {hoverImg && (
                                    <div style={{ width: 150, height: 150 }}>
                                      <img
                                        src={hoverImg}
                                        alt={`Hover image for page ${idx + 1} link`}
                                        style={{ objectFit: "contain", width: "100%", height: "100%" }}
                                        title={hoverImg}
                                      />
                                    </div>
                                  )}
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </StyledTabPane>
                        ),
                      };
                    })}
                  />
                </StyledTabPane>
              ),
            },
            {
              menuItem: "Pages",
              render: () => (
                <StyledTabPane>
                  <Grid stackable>
                    {unlayer_config.pages.map((pg, idx) => (
                      <Grid.Row key={`pg-${idx}-${pg.id || "no-id"}`} verticalAlign="middle">
                        <Grid.Column width={7}>
                          <Form>
                            <Form.Field>
                              <label>Page {idx + 1} title</label>
                              <input
                                type="text"
                                value={config.titles[idx]}
                                onChange={(e) => updateConfig([[`titles[${idx}]`, e.target.value || ""]])}
                                placeholder={`Page ${idx + 1}`}
                              />
                            </Form.Field>
                          </Form>
                        </Grid.Column>
                        <Grid.Column width={5}>
                          <Checkbox
                            toggle
                            label="Show page in navigation"
                            checked={config.includedPages[idx]}
                            onClick={() => updateConfig([[`includedPages[${idx}]`, !config.includedPages[idx]]])}
                          />
                        </Grid.Column>
                        <Grid.Column width={4}>
                          <Button onClick={() => copyPageLink(idx)} disabled={!config.includedPages[idx]}>
                            Copy direct link
                            <Popup
                              content="Note that if you change the page structure, the original link may not work as expected. Make sure to update links you've used elsewhere after making changes if you notice any issues."
                              trigger={<Icon name="info circle" size="small" className="ml-2" />}
                            />
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    ))}
                  </Grid>
                </StyledTabPane>
              ),
            },
          ]}
        />
      </Modal.Content>
      <Modal.Actions className="d-flex justify-content-between">
        <Button color="red" onClick={cancel}>
          Cancel
        </Button>
        <Button basic onClick={() => save(config)}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
