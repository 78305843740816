import * as React from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router";
import Store from "../../common/store";
import {
  seerColorLogo,
  Nav,
  StyleNavContainer,
  StyleAlertText,
  StyleAlertBar,
  StyleOkButton,
  StyleAlertOffset,
  StyleSeerLogo,
  purpleBorder,
  seerLogo,
} from "./includes/header.style";
import { getMixpanel } from "../../common/api";
import { NavItem, NavItemProps } from "./NavItem";
import { NotificationPopup } from "component/NotificationPopup/NotificationPopup";
import { HeaderSearch } from "../HeaderSearch/HeaderSearch";
import { Icon } from "semantic-ui-react";
import { ls } from "common/helpers/storage";
import { isPublicURL } from "common/helpers/route";
import { MoreFeaturePopup } from "./MoreFeatures";
import { Upgrade } from "component/popup/Upgrade";
import { BurgerMenu } from "component/Header/BurgerMenu";
import { DatasetsMegaMenu } from "./DatasetsMegaMenu";
import { AvatarDropdown } from "./AvatarDropdown";
import { getExplorePath } from "common/helpers/user";

type PropsType = RouteComponentProps<{}> & {
  store?: Store;
  analystPortal?: boolean;
};

interface NavigationItem extends NavItemProps {
  displayWhen: "authenticated" | "unauthenticated" | "always";
  position: "left" | "right";
  component?: any;
}

const analystPortalNavItems: NavigationItem[] = [
  {
    name: "API Docs",
    to: "#",
    position: "left",
    externalPath: "/analyst-portal/docs/query-data/query-data/index.html",
    displayWhen: "authenticated",
  },
  {
    name: "Query Viewer",
    to: "/analyst-portal/query-viewer",
    position: "left",
    displayWhen: "authenticated",
  },
  {
    name: "Seer Data Platform",
    to: { pathname: "https://seerplatform.com" },
    position: "right",
    displayWhen: "always",
  },
];

class HeaderComponent extends React.Component<PropsType> {
  store = this.props.store!;
  analystPortal = this.props.analystPortal;

  state = {
    showResponsiveSearch: false,
    showBurgerMenu: false,
  };
  history = this.props.history;

  links = [
    {
      title: "Getting started",
      to: "https://seerdata.zendesk.com/hc/en-us/sections/360005918612-Getting-Started",
    },
    {
      title: "FAQs",
      to: "https://seerdata.zendesk.com/hc/en-us/sections/360005918632-FAQ",
    },
    { title: "Ask for help", to: "https://seerdata.zendesk.com/hc/en-us/requests/new" },
  ];

  handleClose(id: any) {
    const closedAlertIds: any = JSON.parse(ls.getItem("closedAlertIds") || "[]");
    closedAlertIds.push(id);
    ls.setItem("closedAlertIds", JSON.stringify(closedAlertIds));
    document.getElementById(id)!.remove();
  }

  componentDidMount(): void {
    getMixpanel(this.store).track_links("#nav-help-link", "Link click", { Link: "Help" });
  }

  render() {
    const { showResponsiveSearch, showBurgerMenu } = this.state;
    const navItems: NavigationItem[] = [
      {
        name: "Seer Data & Analytics",
        to: { pathname: "https://seerdata.com.au" },
        displayWhen: "unauthenticated",
        position: "left",
      },
      {
        id: "userflow-element-header-datasets",
        to: "#",
        displayWhen: "authenticated",
        position: "left",
        className: "d-none d-md-block",
        component: <DatasetsMegaMenu />,
      },
      {
        id: "userflow-element-header-explore-data",
        name: "Explore Data",
        to: {
          pathname: getExplorePath(),
          state: { reload: true },
        },
        displayWhen: "authenticated",
        position: "left",
        className: "d-none d-md-block",
      },
      {
        id: "userflow-element-header-suitcases",
        name: "Suitcases",
        to: "/suitcases",
        displayWhen: "authenticated",
        position: "left",
        className: "d-none d-md-block",
      },
      {
        id: "userflow-element-header-dashboards",
        name: "Dashboards",
        to: "/dashboards",
        displayWhen: "authenticated",
        position: "left",
        showDiamond: !this.store!.hasGroupAddOn("Dashboards") && this.store!.userPlan === "Standard",
        className: "d-none d-md-block",
      },
      {
        to: "#",
        icon: "search",
        displayWhen: "authenticated",
        position: "right",
        component: (
          <div
            className="mr-3 d-block d-lg-none cursor-pointer"
            onClick={() => this.setState({ showResponsiveSearch: true })}
          >
            <Icon name="search" className="text-secondary" />
          </div>
        ),
      },
      {
        to: "#",
        displayWhen: "authenticated",
        position: "right",
        component: <MoreFeaturePopup />,
      },
      {
        to: "#",
        displayWhen: "authenticated",
        position: "right",
        component: <NotificationPopup />,
      },
      {
        to: "#",
        displayWhen: "authenticated",
        position: "right",
        component: (
          <div
            className="d-block d-md-none cursor-pointer"
            onClick={() => this.setState({ showBurgerMenu: true })}
          >
            <Icon name="bars" className="text-secondary" />
          </div>
        ),
      },
      {
        name: "Sign Up",
        to: "/signup",
        icon: "signup",
        displayWhen: "unauthenticated",
        position: "right",
      },
      {
        name: "Log In",
        to: "/login",
        icon: "sign-in",
        displayWhen: "unauthenticated",
        position: "right",
      },
    ];

    const checkIfShouldDisplay = displayWhen => {
      if (displayWhen === "authenticated") {
        return this.store.authenticated;
      } else if (displayWhen === "unauthenticated") {
        return !this.store.authenticated;
      } else if (displayWhen === "always") {
        return true;
      } else {
        return false;
      }
    };

    return (
      <>
        {window.location.href.includes("login") || (!this.store.authenticated && isPublicURL()) ? (
          <>
            {isPublicURL() ?
              <StyleNavContainer>
                <Nav className={this.analystPortal ? "bg-info" : "bg-secondary"}>
                  <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {/* Logo */}
                    <a href="#"
                      style={{ padding: "0 20px 0 20px", verticalAlign: "middle" }}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <img src={seerLogo} alt="Seer Data logo" height="30px" width="30px" />
                    </a>
                  </div>
                </Nav>
              </StyleNavContainer>
              :
              <a href="https://seerdata.com.au">
                <StyleSeerLogo style={{ width: "50px" }} src="/assets/loginlogo.png" alt="seer data logo" />
              </a>
            }
          </>
        ) : (
          <StyleNavContainer>
            {this.store.authenticated &&
              this.store.user!.alerts.map((alert) => (
                <StyleAlertBar
                  id={alert.id}
                  style={
                    JSON.parse(ls.getItem("closedAlertIds") || "[]").includes(alert.id)
                      ? { display: "none" }
                      : { display: "block", backgroundColor: `${this.store.alertColors[alert.type]}` }
                  }
                  key={alert.id}
                >
                  <StyleAlertText dangerouslySetInnerHTML={{ __html: alert.message }} />
                  <StyleOkButton onClick={() => this.handleClose(alert.id)}>
                    Ok<Icon name="check" className="ml-2" />
                  </StyleOkButton>
                </StyleAlertBar>
              ))}

            <Nav className={`d-flex align-items-center justify-content-between px-3 ${this.analystPortal ? "bg-info" : "bg-white"}`} style={{ borderBottom: `1px solid ${purpleBorder}`}}>
              {/* Left Container */}
              <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                {this.analystPortal ? (
                  <>
                    {/* Logo */}
                    <a href="/analyst-portal/index.html" style={{ padding: "0 24px 0 16px", verticalAlign: "middle" }}>
                      <img src={seerColorLogo} alt="Seer Data logo" height="30px" width="30px" />
                    </a>

                    {/* Left Nav Items */}
                    {analystPortalNavItems.filter(item => item.position === "left")
                      .map(item => checkIfShouldDisplay(item.displayWhen) && (
                        <NavItem key={item.name} name={item.name} to={item.to} position={item.position} externalPath={item.externalPath} tag={item.tag} />
                      ))}
                  </>
                ) : (
                  <>
                    {/* Logo */}
                    <Link to="/" className="mr-3" onClick={() => window.scrollTo(0, 0)}>
                      <img src={seerColorLogo} alt="Seer Data logo" height="30px" width="30px" />
                    </Link>

                    {/* Left Nav Items */}
                    {!showResponsiveSearch && (
                      <>
                        {navItems.filter(item => item.position === "left").map((item, idx) => {
                          if (!checkIfShouldDisplay(item.displayWhen)) {
                            return null;
                          } else if (item.component) {
                            return <div id={item.id || undefined} key={`${item.name}-${idx}`}>{item.component}</div>;
                          }
                          return (
                            <NavItem
                              id={item.id || undefined}
                              key={`${item.name}-${idx}`}
                              name={item.name}
                              to={item.to}
                              icon={item.icon}
                              position={item.position}
                              tag={item.tag}
                              showDiamond={item.showDiamond}
                              className={item.className}
                            />
                          )
                        })}
                      </>
                    )}

                    {/* HeaderSearch */}
                    {!this.analystPortal && this.store.authenticated && !showResponsiveSearch && <HeaderSearch className="d-none d-lg-inline-block" />}
                  </>
                )}
              </div>

              {/* Right Nav Items */}
              <div className="d-flex h-100 align-items-center justify-content-end" style={{ flexGrow: 1 }}>
                {this.analystPortal ? (
                  <>
                    {analystPortalNavItems.filter(item => item.position === "right")
                      .map((item, idx) => checkIfShouldDisplay(item.displayWhen) && (
                        <NavItem key={`${item.name}-${idx}`} name={item.name} to={item.to} position={item.position} tag={item.tag} />)
                      )}
                  </>
                ) : (
                  <>
                    {showResponsiveSearch ? (
                      <>
                        <HeaderSearch className="" />
                        <div className="ml-2 cursor-pointer" onClick={() => this.setState({ showResponsiveSearch: false })}>
                          <Icon name="close" className="text-secondary" />
                        </div>
                      </>
                    ) : (
                      <>
                        {navItems.filter(item => item.position === "right")
                          .map((item, idx) =>
                            checkIfShouldDisplay(item.displayWhen) && (
                              item.component ? <div key={`${item.name}-${idx}`}>{item.component}</div> : (
                                <NavItem key={`${item.name}-${idx}`} name={item.name} to={item.to} icon={item.icon} position={item.position} className={item.className} />
                            ))
                          )}
                      </>
                    )}
                  </>
                )}
                {(this.store.user && !showResponsiveSearch) && <AvatarDropdown />}
              </div>
            </Nav>
          </StyleNavContainer>
        )}

        <Upgrade />
        {showBurgerMenu && <BurgerMenu onCloseMenu={() => this.setState({ showBurgerMenu: false })} />}
        {this.store.authenticated &&
          this.store.user!.alerts.map((alert) => (
            <StyleAlertOffset key={alert.id} style={alert.id === ls.getItem("alertId") ? { display: "none" } : { display: "block" }}></StyleAlertOffset>
          ))}
      </>
    );
  }
}

export const Header = withRouter(inject("store")(observer(HeaderComponent)));
