import { COLORS } from "component/UI/common";
import styled from "styled-components";

export const FormWrapper = styled.div`
  width: 556px;
  margin: 0 auto;
  margin-top: -90px;
  padding: 20px;
  overflow: auto;
  height: calc(100vh - 180px);
  @media only screen and (max-width: 768px) {
    height: calc(100vh - 200px);
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;

export const FormHeading = styled.p`
  font-size: 32px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 1rem;
  color: ${COLORS.indigo600};
  @media only screen and (max-width: 576px) {
    font-size: 24px;
  }
`;
