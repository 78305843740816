import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Table } from "semantic-ui-react";
import { database, getMixpanel } from "common/api";
import { Button } from "component/UI/Button";
import Store from "common/store";
import { ParsedRecords, parsedRecordsToFile } from "pages/DatasetDataUpload/includes/helpers";
import { ShowLoaderGlobal } from "component/LoaderGlobal";
import { Modal } from "component/UI/Modal";

interface Props {
  dashboardID: number | string;
  closeHandler: () => any;
  store?: Store;
}

export const Component = (props: Props) => {
  const store = props.store!;
  const { closeHandler, dashboardID } = props;
  const { token } = store;
  const [submissionData, setSubmissionData] = React.useState<ParsedRecords[]>();
  const [loading, setLoading] = React.useState(true);

  const init = async () => {
    const res: any = await database.get(`dashboards/${dashboardID}/form-submissions`, null, token!).catch(() => null);
    const submissionsRows = res?.body?.data?.dashboard_form_submissions || [];
    const submissionRecords: Record<string, ParsedRecords> = {};
    const nextData: ParsedRecords[] = [];
    const order: string[] = [];
    for (const row of submissionsRows) {
      const { submission, created_at } = row;
      const formColumns: string[] = Object.keys(submission);
      const formValues: string[] = Object.values(submission);
      const key = formColumns.join(",");
      // add header row if not set
      if (!submissionRecords[key]) {
        submissionRecords[key] = [[...formColumns, "created_at"]];
        order.push(key);
      }
      // append the data, including a generated created_at column
      submissionRecords[key].push([...formValues, new Date(created_at as string).toLocaleString()]);
    }
    for (const key of order) {
      nextData.push(submissionRecords[key]);
    }
    setSubmissionData(nextData);
    setLoading(false);
  };
  const downloadCSVs = async () => {
    if (!submissionData) {
      return;
    }
    getMixpanel(store).track("Dashboard Forms Download");
    setLoading(true);
    for (let i = 0; i < submissionData.length!; i++) {
      const filename = `dashboard${dashboardID}_form${i + 1}.csv`;
      const file = await parsedRecordsToFile(submissionData[i], filename);
      const url = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    init();
  }, []);

  return (
    <Modal open>
      <Modal.Header>Export and download Form Submissions</Modal.Header>
      <Modal.Content>
        {loading && <ShowLoaderGlobal />}
        <h3>Submissions</h3>
        {!!submissionData?.length ? (
          <>
            <Table singleLine>
              <Table.Body>
                {submissionData.map((formEntries, formIdx) => (
                  <Table.Row key={formEntries[0].join(",")}>
                    <Table.Cell>Form {formIdx + 1}</Table.Cell>
                    <Table.Cell>{formEntries.length - 1} Submission/s</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <Button colorConfig="aquaInverted" inverted onClick={downloadCSVs}>
              Download CSV files
            </Button>
          </>
        ) : (
          <p>{loading ? "...Loading" : "No submissions to display"}</p>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button colorConfig="red" onClick={closeHandler} basic>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const ModalFormsDownload = inject("store")(observer(Component));
