import * as React from "react";
import { useHistory } from "react-router";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { DateTime } from "luxon";
import Store from "common/store";
import { database } from "common/api";
import { ActionsDropdown } from "./ActionsDropdown";
import { $darkgrey } from "../common/styledComponents/_config.style";
import { printPdf } from "common/constants";
import { Icon, Popup } from "semantic-ui-react";
import { NewBuilderChartPreview } from "./Explore/includes/NewBuilderChartPreview";
import { getLogicalChartDefaults } from "common/helpers/chart";
import { COLORS } from "./UI/common";

const UnstyledLink = styled.a`
  text-decoration: none;
  color: unset;
  &:hover {
    text-decoration: none;
    color: unset;
  }
`;

// @TODO - fix me, declare types when extending on default html element props
const StyledContainer = styled.div<any>`
  vertical-align: top;
  display: flex;
  flex-flow: column;
  width: ${(x) => x.widthValue};
  height: 400px;
  background: #ffffff;
  box-shadow: 0px 2px 4px #c3c3c3;
  border-radius: 4px;
  position: relative;
  margin-bottom: 2rem;
  // margin: 0 ${(x) => (x.i % 3 === 1 ? 0 : 20)}px 20px 0;
  &:hover {
    box-shadow: 4px 4px 8px #c3c3c3;
  }
`;

const NameContainer = styled(UnstyledLink)`
  display: flex;
  align-items: start;
  justify-content: space-between;
  color: ${COLORS.indigo600};
  font-weight: bold;
  padding: 20px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 5px;
  &:hover {
    color: ${COLORS.indigo600};
  }
`;

const ImageContainer = styled(UnstyledLink)`
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  align-self: center;
  overflow: auto;
`;

const CardFooterContainer = styled.div`
  font-size: 15px;
  vertical-align: middle;
  padding: 12px;
  border-top: 1px solid #eeeeee;
`;

interface CardAction {
  name: string;
  callback(id: number): void;
}

interface Item {
  id: number;
  name: string;
  itemUrl: string;
  imageUrl: string | undefined;
  type: string;
  created_at: string;
  onClickRedirect?: string;
}

interface CardViewProps {
  cardWidth: string;
  cardItem: Item;
  cardActions: CardAction[];
  index: number;
  type: "attachment" | "insight" | "suitcase";
  store?: Store;
  layout: "block" | "grid";
  publicKey?: string;
}

const CardViewComponent = ({ cardWidth, cardItem, cardActions, index, type, store, layout, publicKey }: CardViewProps): JSX.Element => {
  const [insightJson, setInsightJson] = React.useState<any>(null);
  const [displayedDate, setDisplayedDate] = React.useState("");
  const [commentCount, setCommentCount] = React.useState(0);

  const history = useHistory();

  const setStateData = async () => {
    if (cardItem.type === "insight") {
      const res: any = await database.get(
        `insights/${cardItem.id}${publicKey ? `/${publicKey}` : ""}`,
        "",
        !publicKey ? store!.token! : undefined,
      );
      window[printPdf.insightsLoadedVarName] = (window[printPdf.insightsLoadedVarName] || 0) + 1; // do not remove - used for pdf print
      const insightData = res?.body?.data?.insight || {};
      setInsightJson(JSON.parse(insightData.json || null));
      setDisplayedDate(insightData.updated_at || ""); // Set to insight's updated date
      setCommentCount(insightData.comments?.length || 0);
    } else {
      setDisplayedDate(cardItem.created_at); // Set to attachment's created date
    }
  };

  React.useEffect(() => {
    setStateData();
  }, []);

  React.useEffect(() => {
    setStateData();
  }, [cardItem]);

  const handleClick = (e, type) => {
    if (type === "insight") {
      e.preventDefault();
      e.stopPropagation();
      history.push(cardItem.itemUrl);
    }
  };

  return (
    <StyledContainer key={index} i={index} widthValue={cardWidth}>
      <NameContainer href={cardItem.itemUrl} onClick={(e) => handleClick(e, type)}>
        <div>
          <span style={{ wordBreak: "break-word" }}>{cardItem.name}</span>
          {insightJson &&
            insightJson.builderVersion === "2.0.0" &&
            // @NOTE - do not remove check for new builder version, the app will crash here if an old insight is loaded in
            // If "userDefinedCharts" exists, check "series" of "userDefinedCharts", otherwise check "series" of "getLogicalChartDefaults"
            ((insightJson.userDefinedCharts && insightJson.userDefinedCharts.series.length > 0) ||
              (!insightJson.userDefinedCharts &&
                getLogicalChartDefaults(
                  insightJson.tables.filter((table) => table.graph),
                  insightJson.columns,
                ).series.length > 0)) && (
              <Popup
                size="mini"
                position="bottom center"
                content={"This Insight contains multiple charts"}
                trigger={<Icon name="images outline" className="cursor-pointer text-secondary ml-2" />}
                inverted
              />
            )}
        </div>
      </NameContainer>
      <ImageContainer href={cardItem.itemUrl} onClick={(e) => handleClick(e, type)}>
        {cardItem.type === "insight" ? (
          <NewBuilderChartPreview
            insightJson={insightJson}
            insightTitle={cardItem.name} // For the calculation of graphHeight
            layout={layout}
            insightID={cardItem.id}
          />
        ) : cardItem.imageUrl ? (
          <img
            src={cardItem.imageUrl}
            style={{ width: "auto", height: "auto", maxWidth: "100%", maxHeight: 250, margin: "auto" }}
            alt={`Image of ${cardItem.name}`}
          />
        ) : (
          <span style={{ width: "inherit", color: $darkgrey }}>No preview available.</span>
        )}
      </ImageContainer>
      <CardFooterContainer>
        <span>{displayedDate && DateTime.fromISO(displayedDate).setZone("local").toFormat("h:mma DDD")}</span>
        {commentCount > 0 && (
          <Popup
            trigger={<span className="bg-medium rounded mx-2 px-2 text-white fw-700">{commentCount}</span>}
            content={`${commentCount} comments`}
            position="bottom center"
            inverted
          />
        )}
        {cardActions.length > 0 && <ActionsDropdown cardActions={cardActions} id={cardItem.id} />}
      </CardFooterContainer>
    </StyledContainer>
  );
};

export const CardView = inject("store")(observer(CardViewComponent));
