import { CHART_AXIS_COLOR, CHART_GRID_COLOR, PREVIEW_LABEL_STYLING } from "common/helpers/chart";
import * as React from "react";
import { XAxis, YAxis, CartesianGrid, LineChart, Line, Tooltip } from "recharts";

interface ILineChart {
  data: any[];
  chartKey: any;
  chartColors: string[];
  graphWidth: number;
  graphHeight: number;
  maxXAxisHeight: number;
  maxYAxisWidth: number;
  minDomainValue: number | "auto";
  maxDomainValue: number | "auto";
  computedTicks: undefined | number[];
  formatter: (val: number, _index?: number, full?: boolean) => any;
  CustomAxisTick: (axis: "x" | "y") => (props: any) => JSX.Element;
  setTooltipDataKey: (value) => void;
  CustomToolTip: (props: any) => JSX.Element | null;
  allowDataOverflow: boolean;
  axisTickInterval?: number;
}

export const NewBuilderLineChart = ({
  data,
  chartKey,
  chartColors,
  graphWidth,
  graphHeight,
  minDomainValue,
  maxDomainValue,
  computedTicks,
  formatter,
  maxXAxisHeight,
  maxYAxisWidth,
  CustomAxisTick,
  setTooltipDataKey,
  CustomToolTip,
  allowDataOverflow,
  axisTickInterval,
}: ILineChart): JSX.Element => (
  <LineChart data={data} width={graphWidth} height={graphHeight}>
    <CartesianGrid strokeDasharray="4 4" vertical={false} stroke={CHART_GRID_COLOR} />
    <XAxis
      dataKey="label0"
      textAnchor="end"
      interval={axisTickInterval || 0}
      tick={CustomAxisTick("x")}
      height={maxXAxisHeight}
      tickLine={false}
      axisLine={{ stroke: CHART_AXIS_COLOR }}
    />
    <YAxis
      type="number"
      tick={PREVIEW_LABEL_STYLING}
      tickFormatter={formatter}
      width={maxYAxisWidth}
      tickLine={false}
      axisLine={false}
      domain={[minDomainValue, maxDomainValue]}
      ticks={computedTicks}
      minTickGap={1}
      allowDataOverflow={allowDataOverflow}
    />
    <Tooltip
      offset={0}
      isAnimationActive={false}
      cursor={{ fill: "transparent", stroke: CHART_AXIS_COLOR, strokeWidth: 1 }}
      content={<CustomToolTip />}
    />
    {chartKey.values.map((dataKey, idx) => (
      <Line
        key={`${dataKey} ${idx}`}
        type="monotone"
        dataKey={dataKey}
        strokeWidth={2}
        stroke={chartColors[idx]}
        activeDot={{
          r: 4,
          strokeWidth: 0,
          onMouseEnter: () => {
            setTooltipDataKey(dataKey);
          },
          onMouseLeave: () => {
            setTooltipDataKey(undefined);
          },
        }}
        onMouseEnter={() => {
          setTooltipDataKey(dataKey);
        }}
        onMouseLeave={() => {
          setTooltipDataKey(undefined);
        }}
      />
    ))}
  </LineChart>
);
