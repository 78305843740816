import { action, observable } from "mobx";
import Store from "../store";
import { database, datasets } from "common/api";
import { ObjectAny } from "common/helpers/types";
import { backendUrl, environment } from "common/constants";
import { ls } from "common/helpers/storage";
import { sortDimensionValues, transformResToDims } from "common/helpers/data";
import { URLUpload, SSDCConfig } from "common/store/dataset/urlUpload";
import { DATA_SOURCE_STEPS } from "pages/DatasetTemplates/includes/NewDataSourceModal";
import { Schema } from "common/helpers/dataset";

// Prefix with "I" to avoid conflict with the "Dataset" class name.
export interface IDataset {
  id: number;
  name: string;
  description: string;
  custodian: string;
  link: string | null;
  key: string;
  notes: null | string;
  preprocessed: boolean;
  updated: boolean;
  published: boolean;
  rules: null | string;
  columns_map: ObjectAny;
  deleted_at: null | string;
  updated_at: null | string;
  ssdc_config: SSDCConfig;
  dataset_status_id: null | number;
  contributor_welcome_message: string | null;
  contributor_reminder_message: string | null;
  contributor_thank_you_message: string | null;
}

export interface ListDataset extends IDataset {
  num_templates: number;
}

export interface Template {
  id: number;
  name: string;
  dataset_id: number;
  url: string;
  deleted_at: null | string;
  contributor_link: null | string;
  update_history: UpdateHistory[];
  contributors: Contributor[];
  num_tables: number;
  contributor_welcome_message: string | null;
  contributor_reminder_message: string | null;
  contributor_thank_you_message: string | null;
}

export interface ListTemplate extends Template {
  num_tables: number;
}

export interface Table {
  id: number;
  name: string;
  dataset_template_id: number;
  schema: Schema;
  deleted_at: null | string;
  completeness: number;
  data: ObjectAny[];
}

export type ListTable = Omit<Table, "data">;

interface UpdateHistory {
  id: number;
  dataset_template_id: number;
  name: string;
  email: string;
  datetime: string;
}

export interface Contributor {
  id?: number;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  dataset_template_id?: number;
  reminder_start_date: string | null;
  reminder_interval: string | null;
  sent_history?: string[] | null;
  send_welcome_email?: boolean;
  deleted_at?: string | null;
}

type EmailMessages = Pick<IDataset, "contributor_welcome_message" | "contributor_reminder_message" | "contributor_thank_you_message">;

export interface DatasetsData {
  datasets: ListDataset[];
}
export interface TemplatesData {
  templates: ListTemplate[];
}
export interface TablesData {
  tables: ListTable[];
}

export interface ListResponseData<T> {
  data: T;
  meta: { total: number; limit: number; skip: number };
}

// key-value store for latest data found for dataset/template/table, cache key, value is an object with variables as properties and categories as values
type LatestData = Record<string, ObjectAny>;

interface IDataSourceStep {
  content: React.FunctionComponent<any>;
}

export default class Dataset {
  @observable info = {
    // Info for saveInsight => Save Insight / Export Insight / Move Insight
    show: false,
    iName: "",
    iSubheading: "",
    sName: "",
    sDescription: "",
    groupSelected: -1,
    loading: false,
  };
  @observable latestData: LatestData = {};
  @observable newDataModalStep: IDataSourceStep = DATA_SOURCE_STEPS.dataSourceSelection; // @TODO - revisit this - why are components living inside a mobx store???
  @observable showCreateTemplateModal = false;
  @observable showSessionExpired = false;
  @observable datasetStatusLabels: { id: number; label: "queued" | "ingesting" }[] | null = null;

  parent: Store;
  urlupload = new URLUpload(this);

  constructor(parent: Store) {
    this.parent = parent;
  }

  @action setShowCreateTemplateModal(show: boolean) {
    this.showCreateTemplateModal = show;
  }

  @action async getLatestData(type: "dataset" | "template" | "table", input: ObjectAny): Promise<Record<string, string>> {
    const cacheKey = `${environment === "production" ? "" : `${environment}/`}latestData/${type}/${input.id}`;
    // check if set
    if (this.latestData[cacheKey]) {
      return this.latestData[cacheKey];
    }
    // check if in ls
    const lsValue = ls.getItem(cacheKey);
    if (lsValue) {
      const data = JSON.parse(lsValue);
      this.latestData[cacheKey] = data;
      return data;
    }
    const cacheExpiry = Date.now() + 1000 * 60 * 60; // expire in 1 hour for now
    // compile fresh data
    const data = {};
    // generic handler for getting the latest time variable data for all SSDC levels [dataset | template | table]
    const updateData = async (data: Record<string, string>, datasetKey: string, baseFilters: ObjectAny) => {
      const res: any = await datasets.post("v2/qs", { datasets: [datasetKey], filters: { ...baseFilters } }, this.parent.token || "");
      const whens = Object.entries(transformResToDims(res))
        .filter((entry: any) => entry[1].type === "When")
        .map((entry) => entry[0]);
      for (const when of whens) {
        const res: any = await datasets.post(
          "v2/qs",
          { datasets: [datasetKey], filters: { ...baseFilters, [when]: [] } },
          this.parent.token || "",
        );
        let dims = transformResToDims(res);
        dims = { [when]: dims[when] }; // avoid sorting all returned dims, just the ones we want
        dims = sortDimensionValues(dims);
        data[when] = dims[when].values.slice(-1)[0]?.name || "";
      }
    };
    if (type === "dataset") {
      await updateData(data, input.key, {});
    } else if (type === "template") {
      const templateVariable = input.dataset.key === "maranguka" ? "Data Source" : "Template"; // "maranguka" uses "Data Source" instead of "Template", non SSDC dataset
      await updateData(data, input.dataset.key, { [templateVariable]: [input.name] });
    } else {
      await updateData(data, input.dataset.key, { Table: [input.name] });
    }
    ls.setItem(cacheKey, JSON.stringify(data), cacheExpiry);
    this.latestData[cacheKey] = data;
    return data;
  }

  @action async getDatasetStatusLabels(): Promise<void> {
    const res: any = await database.get("datasets/dataset-status", null);
    if (res?.body?.data?.dataset_status) {
      this.datasetStatusLabels = res.body.data.dataset_status;
    }
  }

  @action setShowSessionExpired(value: boolean): void {
    this.showSessionExpired = value;
  }

  @action setNewDataModalStep(step: IDataSourceStep) {
    this.newDataModalStep = step;
  }

  createDataset = async (name: string): Promise<{ id?: number; error?: string }> => {
    const res: any = await database.post("datasets", { name }, this.parent.token!);
    if (res?.statusCode === 200) {
      return { id: res?.body?.data?.new_dataset.id };
    } else {
      return { error: "Failed to create dataset." };
    }
  };

  updateDatasetEmailMessages = async (datasetID: number, messages: EmailMessages): Promise<boolean> => {
    const { contributor_welcome_message, contributor_reminder_message, contributor_thank_you_message } = messages;
    const res: any = await database.post(
      `datasets/${datasetID}`,
      {
        contributor_welcome_message,
        contributor_reminder_message,
        contributor_thank_you_message,
      },
      this.parent.token!,
    );
    return res?.statusCode === 200;
  };

  createTemplate = async (name: string, datasetID: number): Promise<{ id?: number; error?: string }> => {
    const res: any = await database.post("dataset-templates", { name, dataset_id: datasetID }, this.parent.token!);
    if (res?.statusCode === 200) {
      return { id: res?.body?.data?.new_template?.id };
    } else {
      return { error: res?.body?.error?.message || "Failed to create template." };
    }
  };

  duplicateTemplate = async (templateID: number): Promise<void | number> => {
    const res: any = await database.post(`dataset-templates/${templateID}/duplicate`, {}, this.parent.token!);
    if (res?.statusCode === 200) {
      return res?.body?.data?.duplicated_templates[0]?.id;
    }
  };

  addTemplateContributor = async (templateID: number, contributor: Contributor): Promise<any> => {
    const { first_name, last_name, email, reminder_start_date, reminder_interval, send_welcome_email } = contributor;
    const res: any = await database.post(
      `dataset-templates/${templateID}/contributors`,
      {
        first_name,
        last_name,
        email,
        reminder_start_date,
        reminder_interval,
        send_welcome_email,
      },
      this.parent.token!,
    );
    return res;
  };

  updateTemplateContributor = async (contributor: Contributor): Promise<any> => {
    const { id, first_name, last_name, email, reminder_start_date, reminder_interval } = contributor;
    const res: any = await database.put(
      `dataset-template-contributors/${id}`,
      {
        first_name,
        last_name,
        email,
        reminder_start_date,
        reminder_interval,
      },
      this.parent.token!,
    );
    return res;
  };

  deleteTemplateContributor = async (contributorID: number): Promise<boolean> => {
    const res: any = await database.delete(`dataset-template-contributors/${contributorID}`, {}, this.parent.token!);
    return res?.statusCode === 200;
  };

  listDatasets = async (skip: number, sort: string): Promise<{ data?: ListResponseData<DatasetsData>; error?: string }> => {
    const queryParams = { $skip: skip, sort };
    const res: any = await database.get("ssd/datasets", queryParams, this.parent.token!);
    if (res?.statusCode === 200) {
      return { data: res?.body };
    } else {
      return { error: res?.body?.error?.message || "Failed to list datasets." };
    }
  };

  getDataset = async (datasetID: number): Promise<{ data?: IDataset; error?: string }> => {
    const res: any = await database.get(`ssd/datasets/${datasetID}`, "", this.parent.token!);
    if (res?.statusCode === 200) {
      return { data: res?.body?.data?.dataset };
    } else {
      return { error: res?.body?.error?.message || "Failed to get dataset." };
    }
  };

  listTemplates = async (
    datasetID: number,
    skip: number,
    sort = "name",
  ): Promise<{ data?: ListResponseData<TemplatesData>; error?: string }> => {
    const queryParams = { dataset_id: datasetID, $skip: skip, sort };
    const res: any = await database.get("ssd/templates", queryParams, this.parent.token!);
    if (res?.statusCode === 200) {
      return { data: res?.body };
    } else {
      return { error: res?.body?.error?.message || "Failed to list templates." };
    }
  };

  getTemplate = async (templateID: number): Promise<{ data?: Template; error?: string }> => {
    const res: any = await database.get(`ssd/templates/${templateID}`, "", this.parent.token!);
    if (res?.statusCode === 200) {
      return { data: res?.body?.data?.template };
    } else {
      return { error: res?.body?.error?.message || "Failed to get template." };
    }
  };

  listTables = async (templateID: number, skip?: number): Promise<{ data?: ListResponseData<TablesData>; error?: string }> => {
    const queryParams = `?template_id=${templateID}${skip ? `&$skip=${skip}` : ""}`;
    const url = `ssd/tables${queryParams}`;
    const res: any = await database.get(url, "", this.parent.token!);
    if (res?.statusCode === 200) {
      return { data: res?.body };
    } else {
      return { error: res?.body?.error?.message || "Failed to list tables." };
    }
  };

  getTable = async (tableID: number): Promise<{ data?: Table; error?: string }> => {
    const res: any = await database.get(`ssd/tables/${tableID}`, "", this.parent.token!);
    if (res?.statusCode === 200) {
      return { data: res?.body?.data?.table };
    } else {
      return { error: res?.body?.error?.message || "Failed to get table." };
    }
  };

  getDatasetVariables = (datasetID: number): Promise<any> => database.get(`ssd/datasets/${datasetID}/variables`, "", this.parent.token!);

  queueIngestion = async (datasetID: number): Promise<boolean | void> => {
    const findQueueLabel = this.datasetStatusLabels?.find((s) => s.label === "queued");
    if (findQueueLabel) {
      const res: any = await database.post(
        `datasets/${datasetID}`,
        {
          dataset_status_id: findQueueLabel.id,
        },
        this.parent.token!,
      );
      return res.statusCode === 200;
    }
  };

  deleteDataset = async (datasetID: number): Promise<boolean> => {
    const res: any = await database.delete(`datasets/${datasetID}`, "", this.parent.token!);
    return res?.statusCode === 200;
  };

  deleteTemplate = async (templateID: number): Promise<boolean> => {
    const res: any = await database.delete(`dataset-templates/${templateID}`, "", this.parent.token!);
    return res?.statusCode === 200;
  };

  updateTemplate = async (templateID: number, payload: ObjectAny): Promise<{ success?: boolean; error?: string }> => {
    const res: any = await database.post(`dataset-templates/${templateID}`, payload, this.parent.token!);
    if (res?.statusCode === 200) {
      return { success: true };
    } else {
      return { error: res?.body?.error?.message || "Failed to create template." };
    }
  };

  getDatasetTemplateTable = async (id: string | number): Promise<any> => {
    const res: any = await database.get(`dataset-template-table/${id}`, "", this.parent.token!);
    return res?.body?.data;
  };

  createDatasetTemplateTable = async (body: ObjectAny): Promise<{ table?: Table; error?: string }> => {
    const res: any = await database.post("dataset-template-table", body, this.parent.token!);
    if (res?.statusCode === 200) {
      return { table: res?.body?.data?.dataset_template_table };
    } else {
      return { error: res?.body?.error?.message || "Request to create Dataset Template Table failed." };
    }
  };

  updateDatasetTemplateTable = async (id: number, body: ObjectAny): Promise<{ table?: Table; error?: string }> => {
    const res: any = await database.put(`dataset-template-table/${id}`, body, this.parent.token!);
    if (res?.statusCode === 200) {
      return { table: res?.body?.data?.dataset_template_table };
    } else {
      return { error: res?.body?.error?.message || "Request to update Dataset Template Table failed." };
    }
  };

  duplicateDatasetTemplateTable = async (tableID: number, templateID: number): Promise<boolean> => {
    const res: any = await database.post(
      `dataset-template-table/${tableID}/duplicate`,
      { dataset_template_id: templateID },
      this.parent.token!,
    );
    return res?.statusCode === 200;
  };

  moveDatasetTemplateTable = async (tableID: number, templateID: number): Promise<boolean> => {
    const res: any = await database.put(`dataset-template-table/${tableID}/move`, { dataset_template_id: templateID }, this.parent.token!);
    return res?.statusCode === 200;
  };

  deleteDatasetTemplateTable = async (id: number): Promise<boolean> => {
    const res: any = await database.delete(`dataset-template-table/${id}`, "", this.parent.token!);
    return res?.statusCode === 200;
  };

  // manually send contributor email reminders
  sendReminders = async (ids: number[]): Promise<boolean> => {
    const res: any = await database.post("dataset-templates/send-contributor-reminder", { ids }, this.parent.token!);
    return res?.statusCode === 200;
  };

  contributorEmailCheck = async (email: string): Promise<boolean> => {
    const res: any = await database.post("dataset-contributors/email-check", { email });
    return res?.statusCode === 200;
  };

  contributorLogin = async (email: string, verification_code: string): Promise<boolean> => {
    const res: any = await database.post("dataset-contributors/login", { email, verification_code });
    const token = res?.body?.data?.contributor_token;
    if (token) {
      this.parent.logInContributor(token);
    }
    return res?.statusCode === 200;
  };

  contributorDatasetTemplateGet = async (id: string | number): Promise<any> => {
    const res: any = await database.get(`dataset-contributors/dataset-template/${id}`, "", this.parent.contributor_token!);
    const template = res?.body?.data?.template;
    let error: null | string = null;
    if (!template) {
      if (res?.statusCode === 401 && res?.body?.error?.type === "JWT Exception") {
        this.setShowSessionExpired(true);
        this.parent.logOutContributor();
      } else {
        error = res?.statusCode === 401 ? "You are not authorised to view this template." : "Error loading data for template.";
      }
    }
    return { template, error };
  };

  contributorDatasetTemplateTableGet = async (id: string | number): Promise<any> => {
    const res: any = await database.get(`dataset-contributors/dataset-template-table/${id}`, "", this.parent.contributor_token!);
    const dataset_template_table = res?.body?.data?.template_table;
    let error: null | string = null;
    if (!dataset_template_table) {
      if (res?.statusCode === 401 && res?.body?.error?.type === "JWT Exception") {
        this.setShowSessionExpired(true);
        this.parent.logOutContributor();
      } else {
        error = res?.statusCode === 401 ? "You are not authorised to view this table." : "Error loading data for template.";
      }
    }
    return { dataset_template_table, error };
  };

  contributorDatasetTemplateTableUpdate = async (id: number, body: ObjectAny): Promise<boolean> => {
    const res: any = await database.put(`dataset-contributors/dataset-template-table/${id}`, body, this.parent.contributor_token!);
    return res?.statusCode === 200;
  };

  getDatasetAccess = async (datasetID: number): Promise<any> => {
    const res: any = await database.get(`dataset-access/${datasetID}`, "", this.parent.token!);
    if (res?.statusCode === 200) {
      return res?.body?.data?.datasetAccess;
    }
  };

  addDatasetGroupAccess = async (datasetID: number, groupID: number, accessLimit: any): Promise<boolean> => {
    const res: any = await database.post(
      "dataset-access/group",
      {
        dataset_id: datasetID,
        group_id: groupID,
        access_limit: accessLimit,
      },
      this.parent.token!,
    );
    return res?.statusCode === 200;
  };

  updateDatasetGroupAccess = async (datasetGroupID: number, datasetID: number, accessLimit: any): Promise<boolean> => {
    const res: any = await database.put(
      `dataset-access/group/${datasetGroupID}`,
      {
        dataset_id: datasetID,
        access_limit: accessLimit,
      },
      this.parent.token!,
    );
    return res?.statusCode === 200;
  };

  deleteDatasetGroupAccess = async (datasetGroupID: number, datasetID: number): Promise<boolean> => {
    const res: any = await fetch(`${backendUrl}/dataset-access/group/${datasetGroupID}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-Token": this.parent.token! || "-none-",
      },
      body: JSON.stringify({ dataset_id: datasetID }),
    });
    return res?.ok;
  };

  addDatasetUserAccess = async (datasetID: number, userID: number, accessLimit: any): Promise<boolean> => {
    const res: any = await database.post(
      "dataset-access/user",
      {
        dataset_id: datasetID,
        user_id: userID,
        access_limit: accessLimit,
        admin: false,
      },
      this.parent.token!,
    );
    return res?.statusCode === 200;
  };

  updateDatasetUserAccess = async (datasetUserID: number, datasetID: number, accessLimit: any): Promise<boolean> => {
    const res: any = await database.put(
      `dataset-access/user/${datasetUserID}`,
      {
        dataset_id: datasetID,
        access_limit: accessLimit,
      },
      this.parent.token!,
    );
    return res?.statusCode === 200;
  };

  deleteDatasetUserAccess = async (datasetUserID: number, datasetID: number): Promise<boolean> => {
    const res: any = await fetch(`${backendUrl}/dataset-access/user/${datasetUserID}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-Token": this.parent.token! || "-none-",
      },
      body: JSON.stringify({ dataset_id: datasetID }),
    });
    return res?.ok;
  };
}
