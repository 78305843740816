import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Dropdown, Icon } from "semantic-ui-react";
import Store from "common/store";
import { StyledCollaborateDropdown } from "./Collaborate.style";
import { COLORS } from "component/UI/common";

interface Props {
  store?: Store;
  options: any[];
  direction?: "left" | "right";
}

const Component = (props: Props): JSX.Element => {
  const { options, direction = "left" } = props;

  return (
    <StyledCollaborateDropdown
      openOnFocus
      direction={direction}
      icon={<></>}
      className="d-block"
      trigger={<Icon name="ellipsis horizontal" className="mr-0" size="small" style={{ color: COLORS.grey800 }} />}
    >
      <Dropdown.Menu>
        {options?.map((option, idx) => {
          const { text, icon, onClick } = option;
          return <Dropdown.Item key={idx} icon={icon} text={text} onClick={onClick} />;
        })}
      </Dropdown.Menu>
    </StyledCollaborateDropdown>
  );
};

export const CollaborateDropdown = inject("store")(observer(Component));
