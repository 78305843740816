import * as React from "react";
import { Button } from "component/UI/Button";
import { Form as FinalForm, Field } from "react-final-form";
import { composeValidators, notRequiredMustBeNumber, required } from "common/helpers/finalForm";
import { Form, Icon, Tab } from "semantic-ui-react";
import { FieldCheck } from "component/FinalFormFields/FieldCheck";
import { FieldRadio } from "component/FinalFormFields/FieldRadio";
import { FieldInput } from "component/FinalFormFields/FieldInput";
import { FieldTextarea } from "pages/DatasetTemplateTable/includes/FieldTextarea";
import { FieldReactSelect } from "component/FinalFormFields/FieldSelect";
import { COLORS } from "component/UI/common";

export const VALIDATION_SCHEMA = {
  allowEmpty: {
    label: "Allow empty",
    value: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
  },
  type: {
    label: "Type",
    value: [
      { value: "number", label: "Number" },
      { value: "whole_number", label: "Whole Number" },
      { value: "list", label: "List" },
      { value: "regexp", label: "Regular Expression" },
    ],
  },
  subType: {
    label: "Sub type",
    value: [
      { value: "valid_range", label: "Valid range" },
      { value: "invalid_range", label: "Invalid range" },
    ],
  },
  fromType: {
    label: "From type",
    value: [
      { value: "gt", label: "Greater than" },
      { value: "gte", label: "Greater than or equal to" },
    ],
  },
  from: {
    label: "From",
  },
  toType: {
    label: "To type",
    value: [
      { value: "lt", label: "Less than" },
      { value: "lte", label: "Less than or equal to" },
    ],
  },
  to: {
    label: "To",
  },
  list: {
    label: "List",
  },
  regexp: {
    label: "Regular expression",
    value: [
      { value: "^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}.\\d{3}Z$", label: "ISO DateTime (e.g. 2024-09-13T10:20:30.123Z)" },
      { value: "^\\d{4}\\s(January|February|March|April|May|June|July|August|September|October|November|December)$", label: "Year Month (e.g. 2023 August)" },
      { value: "^\\d{4}\\s(Jan-Mar|Apr-Jun|Jul-Sep|Oct-Dec)$", label: "Year Quarter (e.g. 2000 Apr-Jun)" },
      { value: "^\\d{4}$", label: "Year (e.g. 2021)" },
      { value: "^\\d{4}-\\d{2}$", label: "Financial year (e.g. 2022-2023)" },
    ],
  },
};

const DEFAULT_VALUES = {
  allowEmpty: false,
  subType: "valid_range",
  fromType: "gt",
  toType: "lt",
};

export const ValidationEditor = (props) => {
  const { columnOptions, columnValidation, onSave, onClose } = props;
  const [initialValues, setInitialValues] = React.useState<any>(DEFAULT_VALUES);
  const editing = !!columnValidation;

  React.useEffect(() => {
    if (editing) {
      const values = {
        column: { value: columnValidation.column, label: columnValidation.column },
      };
      // TODO: refactor the code to improve clarity and readability when time permits
      Object.keys(columnValidation.validation).forEach(key => {
        const value = columnValidation.validation[key];
        const findValue = VALIDATION_SCHEMA[key].value?.find(option => option.value === value);
        values[key] = (findValue && key !== "subType" && key !== "fromType" && key !== "toType" && key !== "allowEmpty")
          ? findValue // values for dropdowns
          : key === "list"
            ? value.join("\n")
            : value; // values for toggles and input fields
      });
      setInitialValues(values);
    }
  }, []);

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={onSave}
      render={(formRenderProps) => {
        const { handleSubmit, submitError, values, form } = formRenderProps;
        const { initialize, change, resetFieldState } = form;
        const { column, allowEmpty, type, regexp } = values;
        return (
          <Form onSubmit={handleSubmit}>
            <p className="fw-700 fs-1250 mt-0" style={{ color: COLORS.grey800 }}>{`${editing ? "Edit" : "Add"} validation`}</p>
            <Field
              name="column"
              label="Select a column"
              placeholder="Select or search"
              isClearable
              styles={{ menu: (base) => ({ ...base, zIndex: 4 }) }} // Ensure react-select dropdown appears above the Semantic toggle
              options={columnOptions}
              validate={composeValidators(required)}
              component={FieldReactSelect}
              isDisabled={editing}
            />
            <Field
              name="allowEmpty"
              label="Allow empty"
              toggle={true}
              component={FieldCheck}
            />
            <Field
              name="type"
              label="Select a validation type"
              placeholder="Select or search"
              isClearable
              options={VALIDATION_SCHEMA.type.value}
              validate={composeValidators(required)}
              component={FieldReactSelect}
              onChangeCallback={(selection) => {
                // Reset all other fields except for "column" and "allowEmpty" when "type" changes
                initialize({ ...DEFAULT_VALUES, column, allowEmpty, type: selection });
              }}
            />
            {type?.value.includes("number") && (
              <>
                <Field
                  name="subType"
                  label="Select a type"
                  placeholder="Select or search"
                  isClearable
                  items={VALIDATION_SCHEMA.subType.value}
                  component={FieldRadio}
                  className="mb-3"
                />
                <Field
                  name="fromType"
                  label="Select a from type"
                  placeholder="Select or search"
                  isClearable
                  items={VALIDATION_SCHEMA.fromType.value}
                  component={FieldRadio}
                />
                <Field
                  name="from"
                  label="From"
                  component={FieldInput}
                  validate={composeValidators(notRequiredMustBeNumber)}
                />
                <Field
                  name="toType"
                  label="Select a to type"
                  placeholder="Select or search"
                  isClearable
                  items={VALIDATION_SCHEMA.toType.value}
                  component={FieldRadio}
                />
                <Field
                  name="to"
                  label="To"
                  component={FieldInput}
                  validate={composeValidators(notRequiredMustBeNumber)}
                />
              </>
            )}
            {type?.value === "list" && (
              <Field
                name="list"
                label="List items (one per line)"
                component={FieldTextarea}
                validate={composeValidators(required)}
              />
            )}
            {type?.value === "regexp" && (
              <Tab
                menu={{ secondary: true }}
                panes={[
                  {
                    menuItem: "Select",
                    render: () => (
                      <Field
                        name="regexp"
                        placeholder="Select or search"
                        isClearable
                        options={VALIDATION_SCHEMA.regexp.value}
                        validate={composeValidators(required)}
                        component={FieldReactSelect}
                      />
                    ),
                  },
                  {
                    menuItem: "Custom",
                    render: () => (
                      <Field
                        name="regexp"
                        placeholder="Write your own"
                        validate={composeValidators(required)}
                        component={FieldInput}
                      />
                    ),
                  },
                ]}
                defaultActiveIndex={!regexp || typeof regexp === "object" ? 0 : 1}
                onTabChange={() => {
                  change("regexp", null);
                  resetFieldState("regexp");
                }}
                className="mb-3"
              />
            )}
            <div className="d-flex justify-content-between">
              <Button colorConfig="red" onClick={onClose}>
                Cancel
              </Button>
              <Button colorConfig="purpleInverted">
                {`${editing ? "Save" : "Add"} validation`} <Icon name="save" className="ml-2" />
              </Button>
            </div>
            {submitError && <p className="text-danger"><b>{submitError}</b></p>}
          </Form>
        );
      }}
    />
  );
};
