import styled from "styled-components";
import { Dropdown } from "semantic-ui-react";
import { $opensans, $black } from "../../../common/styledComponents/_config.style";
import { COLORS } from "component/UI/common";

// @TODO - fix me, declare types when extending on default html element props
export const StyleTileSuitcase = styled.div<any>`
  display: flex;
  border-bottom: 1px solid #c3c3c3;
  border-top: ${(props) => (props.firstItem ? "none" : "1px solid #C3C3C3")};
  min-height: 80px;
  margin-bottom: -1px;
  margin-left: ${(props) => (props.indent ? "40px" : "0px")};
`;

export const StyleUserInfoSuitcase = styled.div`
  flex: 1;
  width: 100%;
  min-height: 40px;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const StyleMemberContainer = styled.div`
  // overflow-y: hidden !important; overflow-x: hidden !important;
  font-size: 14px;
`;
export const StylePendingInviteContainer = styled.div`
  /* transform: translateY(-144px); */
`;
export const StyleTile = styled.div`
  display: flex;
  border-bottom: 1px solid #c3c3c3;
  border-top: 1px solid #c3c3c3;
  height: 80px;
  margin-bottom: -1.1px;
`;
export const StyleUserInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyleName = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: ${COLORS.indigo600};
  margin: -3px 0 0 0;
`;

export const StyleNameSuitcase = styled(StyleName)`
  color: ${COLORS.red500};
`;

export const StyleOrganisation = styled.div`
  font-family: ${$opensans};
  font-weight: normal;
  font-size: 15px;
  color: ${$black};
  word-break: break-word;
`;

export const AccessDropdown = styled(Dropdown)`
  &.org-access .menu .item:last-child {
    display: none;
  }
  align-self: center;
  width: 140px !important;
  height: 40px !important;
  padding-left: 23px !important;
  min-height: 40px !important;
  padding-top: -20px !important;
  background-color: rgba(0, 0, 0, 0) !important;
  &:focus {
    background-color: white !important;
  }
  & > .text {
    line-height: 46px !important;
  }
  .menu {
    .item {
      .text {
        padding-left: 6px !important;
        font-family: "Open Sans" !important;
        font-weight: normal !important;
        color: ${$black} !important;
        font-size: 15px !important;
      }
    }
  }
  .text {
    margin-top: -12px !important;
    font-family: "Open Sans" !important;
    font-weight: bold !important;
    font-size: 15px !important;
    color: ${COLORS.red500} !important;
    margin-left: -6px !important;
    line-height: 10px;
    transform: translateY(-5px) !important;
  }
  i.dropdown.icon {
    padding-left: 10px !important;
    top: 23% !important;
    height: 40px !important;
  }
  .dropdown {
    min-height: 40px !important;
  }
  .ui.selection.dropdown {
    line-height: 10px !important;
  }
`;
