import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Builder } from "common/store/builder";
import { Header, Icon, Label, Radio, SemanticICONS } from "semantic-ui-react";
import { ObjectAny } from "common/helpers/types";

interface IExploreChartType {
  builder?: Builder;
}

interface IChartTypeDropdown {
  name: string;
  icon: SemanticICONS;
  label?: JSX.Element;
  iconStyle: null | ObjectAny;
  children: any[];
  info?: string;
}

const chartTypeDropdownOptions: IChartTypeDropdown[] = [
  {
    name: "Vertical Bar",
    icon: "chart bar",
    iconStyle: null,
    children: [{ type: "Vertical bar clustered" }, { type: "Vertical bar stacked" }, { type: "Vertical bar stacked proportions" }],
  },
  {
    name: "Horizontal Bar",
    icon: "chart bar",
    iconStyle: { transform: "rotate(90deg) scaleX(-1)" },
    children: [{ type: "Horizontal bar clustered" }, { type: "Horizontal bar stacked" }, { type: "Horizontal bar stacked proportions" }],
    info: "Not supporting multiple X-Axises charts.",
  },
  {
    name: "Line",
    icon: "chartline" as "chart line", // bug in fomantic, chartline instead of chart line like semantic
    iconStyle: null,
    children: [{ type: "Line" }, { type: "Line stacked" }, { type: "Line stacked percentage" }],
  },
  {
    name: "Pie",
    icon: "chart pie",
    label: (
      <Label circular color="blue" className="ml-1" size="mini">
        New
      </Label>
    ),
    iconStyle: null,
    children: [{ type: "Pie" }],
  },
  {
    name: "Donut",
    icon: "bullseye",
    iconStyle: null,
    children: [
      // { type: "Donut one" },
      { type: "Donut" },
    ],
  },
  {
    name: "Metric",
    icon: "dot circle outline",
    label: (
      <Label circular color="blue" className="ml-1" size="mini">
        New
      </Label>
    ),
    iconStyle: null,
    children: [{ type: "Metric" }],
  },
];

const ExploreChartTypeComponent = ({ builder }: IExploreChartType): JSX.Element => (
  <>
    <Header as="h5" color="purple" className="mb-1">
      Chart type
    </Header>
    {chartTypeDropdownOptions.map((section) => (
      <React.Fragment key={`${section.name}-wrapper`}>
        <div
          key={section.name}
          className="d-flex align-items-center justify-content-between bg-lightgrey text-secondary px-2 mt-3"
          style={{ height: 30 }}
        >
          <div className="d-flex align-items-center">
            <p className="ff-primary fs-1000 fw-700 mb-0">{section.name}</p>
            {section.label || <></>}
          </div>
          <Icon name={section.icon} style={(section.iconStyle as object) ? section.iconStyle : { marginTop: -5, ...section.iconStyle }} />
        </div>
        {section.children.map((child) => (
          <div className="my-2" key={child.type}>
            <Radio
              label={child.type}
              value={child.type}
              className="fs-1000"
              disabled={
                // Disable "Horizontal" charts when there are multiple XAxises
                (builder!.chartXAxisArray.length > 1 && child.type.includes("Horizontal")) ||
                // Disable "Pie" chart
                (child.type.includes("Pie") &&
                  (builder!.chartLegend.length > 1 ||
                    builder!.chartXAxisArray.length > 1 ||
                    builder!.chartSeries.length > 0 ||
                    builder!.chartTables.length > 1))
              }
              checked={child.type === builder!.chart.type}
              onChange={() => {
                builder!.updateChart("type", child.type);
              }}
            />
          </div>
        ))}
        {section.info && <p className="fs-0875 text-medium">{section.info}</p>}
      </React.Fragment>
    ))}
  </>
);

export const ExploreChartType = inject((stores: any) => ({
  builder: stores.store.builder,
}))(observer(ExploreChartTypeComponent));
