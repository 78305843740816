import * as React from "react";
import Select from "react-select";
// import Props from "react-select";
import { customReactSelectStyles } from "common/helpers/dataset";
import { Icon, Label, SemanticICONS } from "semantic-ui-react";
import { CustomDropdownIndicator } from "component/Explore/includes/CustomDropdownIndicator";
import { CustomMultiValueLabel } from "component/Explore/includes/CustomMultiValueLabel";

export const StyleSelect = (props): JSX.Element => (
  <Select
    components={{ DropdownIndicator: CustomDropdownIndicator, MultiValueLabel: CustomMultiValueLabel }}
    styles={customReactSelectStyles}
    {...props}
  />
);

interface IColumnLabel {
  type: "number" | "string" | "date";
  label: string | number;
  textColor?: string;
  borderColor?: string;
  className?: string;
  style?: any;
}

export const ColumnLabel = (props: IColumnLabel): JSX.Element => {
  const { type, label, textColor = "#504F5B", borderColor = "#836B86", className, style } = props;
  const typeIcons = {
    number: "hashtag",
    string: "font",
    date: "calendar alternate outline",
  };
  return (
    <div
      className={`d-flex align-items-center rounded-2 p-1 bg-white fs-1000 ${className}`}
      style={{ ...style, border: `1px solid ${borderColor}` }}
    >
      <Icon name={typeIcons[type] as SemanticICONS} style={{ color: textColor, marginTop: -5 }} />
      <div style={{ width: 2, height: 16, backgroundColor: "#CACBCD", marginRight: 6 }}></div>
      <p style={{ color: textColor }}>{label}</p>
    </div>
  );
};

interface IColumnLabelGroup {
  items: IColumnLabel[];
  onRemove: () => any;
  textColor?: string;
  borderColor?: string;
  backgroundColor?: string;
  linkColor?: string;
  className?: string;
  style?: any;
}

export const ColumnLabelGroup = (props: IColumnLabelGroup): JSX.Element | null => {
  const {
    items,
    textColor = "#504F5B",
    borderColor = "#836B86",
    backgroundColor = "#F3E9F3",
    linkColor = "#0853BC",
    className = "",
    style = {},
    onRemove,
  } = props;
  if (items.length !== 2) {
    return null;
  }
  const [item1, item2] = items;
  return (
    <div
      className={`d-flex flex-md-row flex-column align-items-center justify-content-between rounded-2 py-3 px-4 ${className}`}
      style={{ ...style, backgroundColor }}
    >
      <ColumnLabel type={item1.type} label={item1.label} textColor={textColor} borderColor={borderColor} style={{ minWidth: 260 }} />
      <Icon size="large" name="linkify" className="mx-5 my-2 my-md-0" style={{ color: linkColor }} />
      <ColumnLabel type={item2.type} label={item2.label} textColor={textColor} borderColor={borderColor} style={{ minWidth: 260 }} />
      <Icon
        role="button"
        name="trash alternate outline"
        className="ml-5 my-2 my-md-0"
        style={{ color: textColor, marginTop: "-5px !important" }}
        onClick={() => onRemove()}
      />
    </div>
  );
};

export const NotificationDot = (props): JSX.Element => {
  const { ifShow } = props;
  return <Label className={`m-1 bg-secondary ${ifShow ? "" : "d-none"}`} attached="top right" size="mini" circular empty />;
};
