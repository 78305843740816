import * as React from "react";
import { Button, Header, Icon, Input, List, Modal, Segment } from "semantic-ui-react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { database } from "common/api";
import Store from "common/store";
import { ModalListItem } from "./includes/ModalListItem";
import { IProject } from "common/store/suitcase";

interface MoveDuplicateModal {
  type: "suitcase" | "insight";
  isOpen: boolean;
  closeModal: () => void;
  actionClickHandler: (parentID: number | null) => void;
  heading: string;
  actionText: string;
  itemsToBeActioned: any[];
  itemsParentSuitcaseID?: number;
  store?: Store;
  initialDestination?: number;
}

export interface SearchSuitcaseType extends Partial<IProject> {
  has_child_suitcases?: boolean;
}

interface Breadcrumb {
  label: string;
  suitcaseID: number;
}

const DEFAULT_ROOT_BREADCRUMBS: Breadcrumb[] = [{ label: "Suitcases", suitcaseID: 0 }]; // Use "0" for root level

// This component is for:
// - Moving suitcases on Suitcases page & Suitcases Overview Page
// - Duplicate Suitcase to the root level or other Suitcases
// - Moving & Duplicating insights on Insight Overview Page
const MoveDuplicateModalComponent = ({
  type,
  isOpen,
  closeModal,
  actionClickHandler,
  heading,
  actionText,
  itemsToBeActioned,
  itemsParentSuitcaseID,
  store,
  initialDestination = 0,
}: MoveDuplicateModal): JSX.Element => {
  const [destinationSuitcaseID, setDestinationSuitcaseID] = React.useState<number>(initialDestination); // "0" stands for root level
  const [modalSuitcasesData, setModalSuitcasesData] = React.useState<any>({}); // Store the current suitcase's sub-suitcases and access
  const [storedModalSuitcasesData, setStoredModalSuitcasesData] = React.useState({}); // Store modal suitcases to avoid sending requests to the backend
  const [modalLoader, setModalLoader] = React.useState<boolean>(true); // Loader for loading suitcases
  const [modalBreadcrumbs, setModalBreadcrumbs] = React.useState<Breadcrumb[]>(DEFAULT_ROOT_BREADCRUMBS);
  const [searchInput, setSearchInput] = React.useState("");
  const [searchResults, setSearchResults] = React.useState<SearchSuitcaseType[] | null>(null);

  const timeoutRef: any = React.useRef();

  // set "suitcaseIdsToBeMoved" to an empty array when move/duplicate insights & duplicate suitcase
  const suitcaseIdsToBeMoved = type === "suitcase" && actionText === "Move" ? itemsToBeActioned?.map((item) => item.id) : [];

  // Display "searchResults" when there is text in the search input box
  // and the users haven't clicked on the multi-level suitcases in the search results.
  const ifShowSearchResults = searchInput && modalBreadcrumbs.length === 1;
  const displayedModalSuitcases: SearchSuitcaseType[] = ifShowSearchResults ? searchResults : modalSuitcasesData.suitcases;

  const itemClickHandler = async (suitcaseID: number, multiLevelSuitcase: boolean): Promise<void> => {
    if (suitcaseID !== destinationSuitcaseID) {
      setDestinationSuitcaseID(suitcaseID);
      // Go to a deeper level if the user clicks on the multi-level suitcases.
      if (multiLevelSuitcase) {
        setModalLoader(true);
        await getModalSuitcasesData(suitcaseID);
        const itemRes: any = await database.get(`suitcases/${suitcaseID}`, "", store!.token!);
        if (itemRes?.body?.data) {
          setModalBreadcrumbs([
            ...modalBreadcrumbs,
            {
              label: itemRes.body.data.suitcase.name,
              suitcaseID: itemRes.body.data.suitcase.id,
            },
          ]);
        }
        setModalLoader(false);
      }
    } else {
      // Deselected suitcase, set the destination suitcase to the last item of modalBreadcrumbs
      setDestinationSuitcaseID(modalBreadcrumbs[modalBreadcrumbs.length - 1].suitcaseID || initialDestination);
    }
  };

  const breadcrumbsClickHandler = async (suitcaseID: number, index: number): Promise<void> => {
    setModalLoader(true);
    setDestinationSuitcaseID(suitcaseID || initialDestination);
    await getModalSuitcasesData(suitcaseID);
    setModalBreadcrumbs(modalBreadcrumbs.slice(0, index + 1));
    setModalLoader(false);
  };

  const getModalSuitcasesData = async (suitcaseID: number): Promise<void> => {
    const ifRoot = suitcaseID === 0;
    if (storedModalSuitcasesData[suitcaseID]) {
      setModalSuitcasesData(storedModalSuitcasesData[suitcaseID]);
    } else {
      const listRes: any = await database.get(`suitcases${suitcaseID && !ifRoot ? `?parentId=${suitcaseID}` : ""}`, "", store!.token!);
      if (listRes?.body?.data) {
        const suitcases = listRes.body.data.suitcases;
        const suitcaseData = { suitcases, access: "write" };
        if (suitcaseID && !ifRoot) {
          const res: any = await database.get(`suitcases/${suitcaseID}`, "", store!.token!);
          suitcaseData.access = res.body.data.suitcase.user_access;
        }
        setModalSuitcasesData(suitcaseData);
        setStoredModalSuitcasesData({ ...storedModalSuitcasesData, [suitcaseID]: suitcaseData });
      }
    }
  };

  const getModalBreadcrumbs = async () => {
    const breadcrumbs: any[] = [];
    const res: any = await database.get(`suitcases/${itemsParentSuitcaseID}`, "", store!.token!);
    if (res?.body?.data) {
      const suitcase = res?.body?.data.suitcase;
      suitcase.breadcrumbs.forEach((breadcrumb) => {
        const id = +breadcrumb.pathname.match(/\d+$/)[0];
        breadcrumbs.push({
          label: breadcrumb.label,
          suitcaseID: id,
        });
      });
    }
    setModalBreadcrumbs([...modalBreadcrumbs, ...breadcrumbs]);
  };

  const modalInit = async () => {
    if (isOpen) {
      setModalLoader(true);
      if (itemsParentSuitcaseID) {
        setDestinationSuitcaseID(itemsParentSuitcaseID);
        const hasAccessToParentSuitcase = !!(await store!.suitcase.getSuitcaseData(itemsParentSuitcaseID));
        // If "hasAccessToParentSuitcase" get the parent suitcase's sub-suitcases otherwise get the root level suitcases
        await getModalSuitcasesData(itemsParentSuitcaseID);
        // If "hasAccessToParentSuitcase" construct "breadcrumbs" to the current suitcase/insight path
        if (hasAccessToParentSuitcase) {
          await getModalBreadcrumbs();
        }
      } else {
        // If no "itemsParentSuitcaseID" get the root level suitcases
        await getModalSuitcasesData(0);
      }
      setModalLoader(false);
    } else {
      setDestinationSuitcaseID(initialDestination);
      setModalSuitcasesData({});
      setStoredModalSuitcasesData({});
      setModalBreadcrumbs(DEFAULT_ROOT_BREADCRUMBS);
      setSearchInput("");
    }
  };

  const handleSearchChange = (searchString: string) => {
    setSearchInput(searchString);
    setSearchResults(null);
    setModalLoader(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      const res = await store!.suitcase.searchSuitcases(searchString, 100);
      if (res?.data?.suitcases) {
        setSearchResults(res.data.suitcases as SearchSuitcaseType[]);
      }
      setModalLoader(false);
    }, 800);
  };

  React.useEffect(() => {
    setDestinationSuitcaseID(initialDestination);
    setModalSuitcasesData({});
    setModalBreadcrumbs(DEFAULT_ROOT_BREADCRUMBS);
    if (!searchInput) {
      // If searchInput is clear, go to the root level
      getModalSuitcasesData(0);
      setDestinationSuitcaseID(initialDestination);
    }
  }, [searchInput, initialDestination]);

  React.useEffect(() => {
    modalInit();
  }, [isOpen]);

  React.useEffect(() => () => clearTimeout(timeoutRef.current), []);

  // Disable the button when:
  // - Move suitcases/insights to the suitcase it already belongs to (exclude duplicate insight to the current suitcase)
  // - Move root level suitcases to root level
  // - Move insight to root level
  // - Users have "read" access to the current suitcase
  // - "destinationSuitcaseID" is undefined
  const actionDisabled = () => {
    if (destinationSuitcaseID === itemsParentSuitcaseID && actionText === "Move") {
      // this would do nothing
      return true;
    } else if (
      modalBreadcrumbs.length === 1 && // Root level suitcase
      destinationSuitcaseID === 0 && // destination is root
      ((!itemsParentSuitcaseID && actionText === "Move") || // this would do nothing
        type === "insight") // insight must live in a suitcase
    ) {
      return true;
    } else if (modalSuitcasesData.access === "read") {
      return true;
    } else if (
      ifShowSearchResults &&
      (!displayedModalSuitcases?.length || !displayedModalSuitcases.some((suitcase) => suitcase.id === destinationSuitcaseID))
    ) {
      return true; // search input but no results or results don't include the currently selected destinationSuitcaseID
    }
    return false;
  };

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Modal.Header>
        <div className="d-sm-flex align-items-center justify-content-between">
          <Header as="h5" className="mb-0">
            {heading}
          </Header>
          <Input
            name="suitcases_search_field"
            role="search"
            aria-label="Suitcases Search Field"
            className="mr-2"
            size="mini"
            placeholder="Search suitcases..."
            value={searchInput}
            onChange={(e) => handleSearchChange(e.target.value || "")}
            icon={<Icon name={searchInput ? "close" : "search"} link={!!searchInput} onClick={() => searchInput && setSearchInput("")} />}
          />
        </div>
        <List bulleted className="mt-3 mb-2">
          {itemsToBeActioned?.map((item) => (
            <List.Item className="fs-1125 text-dark" key={item.id}>
              {item.name}
            </List.Item>
          ))}
        </List>
      </Modal.Header>
      <Modal.Content>
        {modalBreadcrumbs.map((item, index) => {
          const { label, suitcaseID } = item;
          return (
            <span className="fs-1000 fw-600 text-medium" key={suitcaseID}>
              {index !== 0 && " > "}
              {index === modalBreadcrumbs.length - 1 ? (
                <span className="text-muted">{label}</span>
              ) : (
                <span className="cursor-pointer" onClick={() => breadcrumbsClickHandler(suitcaseID, index)}>
                  {label}
                </span>
              )}
            </span>
          );
        })}
        <Segment className="p-0 overflow-auto" style={{ maxHeight: 375, minHeight: 375 }}>
          {isOpen && modalLoader ? (
            <div className="p-3 text-muted">
              <Icon name="cog" className="loading" /> Loading Suitcases
            </div>
          ) : (
            <List selection divided verticalAlign="middle" size="huge">
              {displayedModalSuitcases?.length > 0 ? (
                displayedModalSuitcases?.map((suitcase) => {
                  const { id, private: isPrivate, number_child_suitcases, has_child_suitcases } = suitcase; // Rename "private" to "isPrivate" as "private" is reserved in Strict Mode.
                  const ifMultiLevelSuitcase = (number_child_suitcases && number_child_suitcases > 0) || has_child_suitcases;
                  return (
                    <ModalListItem
                      item={suitcase}
                      selectedID={destinationSuitcaseID!}
                      itemClickHandler={
                        suitcaseIdsToBeMoved.includes(id) || // Disable the suitcase that will be moved
                        (type === "suitcase" && isPrivate) || // Disable "Draft Insights" when move suitcases
                        (type === "insight" && actionText === "Move" && itemsParentSuitcaseID === suitcase.id && !!ifMultiLevelSuitcase) // Disable the suitcase when the suitcase is the parent suitcase of insights to be moved and the suitcase has no descendants
                          ? undefined
                          : () => itemClickHandler(suitcase.id as number, !!ifMultiLevelSuitcase)
                      }
                    />
                  );
                })
              ) : (
                <>{ifShowSearchResults && <p className="m-3 text-medium fs-1125">No search results.</p>}</>
              )}
            </List>
          )}
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <div className="d-flex flex-row-reverse align-items-center justify-content-between mb-3 mb-md-0">
          <div>
            <Button className="mr-2" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              id="userflow-element-move-duplicate-modal-action-button"
              disabled={actionDisabled()}
              color="purple"
              onClick={() => actionClickHandler(destinationSuitcaseID === 0 ? null : destinationSuitcaseID!)}
            >
              {`${actionText} here`}
              <Icon name={actionText === "Move" ? "move" : "copy"} className="ml-1 mr-0" />
            </Button>
          </div>
          {modalSuitcasesData.access === "read" && (
            <p className="mb-0 text-dark">
              <b>Read-only access:</b>
              {` not able to ${actionText.toLowerCase()} ${type}(s) here.`}
            </p>
          )}
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export const MoveDuplicateModal = inject("store")(observer(MoveDuplicateModalComponent));
