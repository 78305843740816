import * as React from "react";
import { Button, Modal } from "semantic-ui-react";
import { inject } from "mobx-react";

import { ColorPalettesList } from "component/ColorPalettesList";

export const ColorPaletteModal = inject("store")(({ store, isOpen, close }) => (
  <Modal open={isOpen} onClose={close}>
    <Modal.Header>Colour Palettes</Modal.Header>
    <Modal.Content>
      <ColorPalettesList store={store} />
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={close}>Close</Button>
    </Modal.Actions>
  </Modal>
));
