import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm, Field } from "react-final-form";
import { composeValidators, mustBeNumber, required, selectionRequired } from "common/helpers/finalForm";
import { Form, Icon } from "semantic-ui-react";
import { FieldInput } from "component/FinalFormFields/FieldInput";
import { database } from "common/api";
import { FieldReactSelect, FieldSelect } from "component/FinalFormFields/FieldSelect";
import { Button } from "component/UI/Button";
import Store from "common/store";

interface Props {
  onSubmit: (values) => void;
  store?: Store;
}

const Component = (props: Props): JSX.Element => {
  const { onSubmit, store } = props;
  const [options, setOptions] = React.useState([]);
  React.useEffect(() => {
    const init = async () => {
      const res: any = await database.get("add-ons", "", store!.token!);
      if (res?.body?.data?.add_ons) {
        setOptions(res.body.data.add_ons
          .filter(option => option.active)
          .map(option => ({ key: option.id, text: option.name, value: option.id }))
        );
      }
    };
    init();
  }, []);
  return (
    <FinalForm
      onSubmit={onSubmit}
      render={(formRenderProps) => {
        const { handleSubmit } = formRenderProps;
        return (
          <Form onSubmit={handleSubmit}>
            <h2 className="text-secondary mb-5">Edit organisation add-ons </h2>
            <div className="mb-5">
              <Field
                name="group_id"
                label="Organisation ID"
                component={FieldInput}
                validate={composeValidators(required, mustBeNumber)}
              />
              <Field
                name="access"
                label="Select the action"
                component={FieldReactSelect}
                options={[
                  { label: "Add", value: "add" },
                  { label: "Remove", value: "remove" },
                ]}
                validate={composeValidators(required)}
              />
              <Field
                name="add_on"
                label="Add-ons"
                component={FieldSelect}
                options={options}
                validate={composeValidators(selectionRequired)}
                multiple={true}
              />
            </div>
            <Button colorConfig="purpleInverted">Save<Icon name="save" className="ml-2" /></Button>
          </Form>
        );
      }}
    />
  );
};

export const OrgAddOn = inject("store")(observer(Component));
