import * as React from "react";
import * as qs from "qs";
import { withRouter, RouteComponentProps, Prompt } from "react-router";
import { inject, observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { Button, Dimmer, Icon, Loader, Message } from "semantic-ui-react";

import Store from "common/store";
import { getMixpanel } from "common/api";
import { ShowLoaderGlobal } from "component/LoaderGlobal";
import { ExploreStep } from "component/Explore/includes/ExploreStep";
import { ExploreTableTab } from "component/Explore/ExploreTableTab";
import { ExploreTableResult } from "component/Explore/ExploreTableResult";
import { ExploreChartTab } from "component/Explore/ExploreChartTab";
import { ExplorePreviewTab } from "component/Explore/ExplorePreviewTab";
import { ExplorePreviewResult } from "component/Explore/ExplorePreviewResult";
import { StyleConfirm } from "component/Explore/includes/NewExplore.style";
import { ExploreMultiIndexChartResult } from "component/Explore/ExploreMultiIndexChartResult";
import { Builder } from "common/store/builder";
import { Heading } from "common/styledComponents/elements";
import { DraggableLists } from "component/table/includes/DraggableLists";
import { getInsightDetail, getSuitcaseDetail } from "common/helpers/mixpanel";
import { Calculator } from "component/insightBuilder/CalculatorNIB";
import { RobotAnalysisToolContent } from "./includes/RobotAnalysisToolContent";
import { ls } from "common/helpers/storage";
import Insight from "common/store/insight";
import { MoveDuplicateModal } from "component/MoveDuplicateModal/MoveDuplicateModal";
import { isAnyFilterSelected } from "common/helpers/explore";

interface ComponentProps extends RouteComponentProps<{ id?: string; suitcaseId?: any }> {
  store?: Store;
  analystPortal?: boolean;
}

interface ComponentState {
  currentStep: string;
  clearAllConfirmationModalOpen: boolean;
  saving: boolean;
  forceTabSwitch: "topic" | null;
  goToGuidedExploreModalOpen: boolean;
  signupModalOpen: boolean;
  saveInsightModalOpen: boolean;
}

class NewExplore extends React.Component<ComponentProps> {
  state: ComponentState = {
    currentStep: "table",
    clearAllConfirmationModalOpen: false,
    saving: false,
    forceTabSwitch: null,
    goToGuidedExploreModalOpen: false,
    signupModalOpen: false,
    saveInsightModalOpen: false,
  };

  initialise = async () => {
    const store = this.props.store!;
    const { builder, insight: insightStore } = store;
    const { id: insight } = this.props.match.params;

    if (insight) {
      // Get query params
      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      const refreshData = params.get("refreshData") !== "false";

      // load insight and populate builder store with insight data
      await insightStore.load(parseInt(insight, 10), true);
      await builder.loadInsight(parseInt(insight, 10), refreshData, undefined, insightStore.result);
      await builder.initialiseGlobalDimensions();
    } else {
      store.insight = new Insight(store);
      await builder.newInsight();
    }
  };

  setCurrentStep = (step: string) => {
    this.setState({ currentStep: step });
    this.props.store!.builder.ui.setLeftTabFolded(false);
  };

  handleClearAll = (e): void => {
    e.preventDefault();
    this.setState({ clearAllConfirmationModalOpen: true });
  };

  // regular save insight flow
  handleSave = async (destinationID?: number): Promise<void> => {
    const store = this.props.store!;
    const { builder } = store;
    const { id, suitcaseId } = this.props.match.params;
    const update = !!id;
    const insightId = update ? Number(id) : undefined;
    this.setState({ ...this.state, saving: true });

    // MixPanel: Insight Builder > Save Insight
    let mixpanelData: any = {
      "Type": "Create",
      "Insight Name": store.insight.draftName || "",
      "Insight Owner Name": `${store.user!.first_name} ${store.user!.last_name}`,
    };
    if (destinationID) {
      const suitcase = await getSuitcaseDetail(destinationID, store.token!);
      mixpanelData = { ...mixpanelData, "Suitcase ID": destinationID, "Suitcase Name": suitcase.name, "Suitcase Owner Name": suitcase.ownerName, "Suitcase Owner Organisation": suitcase.ownerOrg };
    } else if (update) {
      const insight = await getInsightDetail(insightId!, store.token!);
      const suitcase = await getSuitcaseDetail(insight.suitcaseID, store.token!);
      mixpanelData = {
        ...mixpanelData,
        "Type": "Update",
        "Insight ID": insightId,
        "Insight Name": store.insight.draftName || insight.name,
        "Insight Owner Name": insight.ownerName,
        "Suitcase ID": insight.suitcaseID,
        "Suitcase Name": suitcase.name,
        "Suitcase Owner Name": suitcase.ownerName,
        "Suitcase Owner Organisation": suitcase.ownerOrg,
      };
    } else if (suitcaseId) {
      const suitcase = await getSuitcaseDetail(suitcaseId, store.token!);
      mixpanelData = { ...mixpanelData, "Suitcase ID": suitcaseId, "Suitcase Name": suitcase.name, "Suitcase Owner Name": suitcase.ownerName, "Suitcase Owner Organisation": suitcase.ownerOrg };
    }

    // Wait "mixpanel.track" finishes and then change "window.location" at the end of "saveInsight" as "mixpanel.track" is not async and can't await
    await new Promise(resolve => getMixpanel(builder!.parent).track("Insight Builder > Save Insight", mixpanelData, null, resolve));
    await builder.saveInsight(insightId, destinationID || Number(suitcaseId));

    this.setState({ ...this.state, saving: false, saveInsightModalOpen: false });
  };

  handleCancelEdit = () => {
    this.props.store!.builder.setActiveTableId(undefined);
    this.props.history.push(`/insights/${this.props.match.params.id!}`);
  };

  // Switch table tab to "Topic" after confirm "Reset all"
  handleResetTableTab = () => {
    this.setState({ forceTabSwitch: "topic" }, () => this.setState({ forceTabSwitch: null }));
  };

  confirmTableStructure = () => {
    const { builder } = this.props.store!;
    builder.saveTableQuery(builder.activeTableId);
    builder.setActiveTableId(undefined);
    builder.ui.setShowTableStructureConfirmation(false);
    builder.addTable();
  };

  async mixpanelTracking() {
    const store = this.props.store!;
    const { builder } = store;
    const id = Number(this.props.match.params.id!);

    const { suitcaseId } = this.props.match.params;
    let mixpanelData: any = {
      "Page": "Insight Builder",
      "Type": "Create",
      "Insight Owner Name": store.user ? `${store.user.first_name} ${store.user.last_name}` : "Guest",
    };
    if (suitcaseId) {
      const suitcase = await getSuitcaseDetail(suitcaseId, store.token!);
      mixpanelData = {...mixpanelData, "Suitcase ID": suitcaseId, "Suitcase Name": suitcase.name, "Suitcase Owner Name": suitcase.ownerName, "Suitcase Owner Organisation": suitcase.ownerOrg};
    } else if (id) {
      const insight = await getInsightDetail(id, store.token!);
      const suitcase = await getSuitcaseDetail(insight.suitcaseID, store.token!);
      mixpanelData = {"Type": "Update", "Insight ID": id, "Insight Name": insight.name, "Insight Owner Name": insight.ownerName, "Suitcase ID": insight.suitcaseID, "Suitcase Name": suitcase.name, "Suitcase Owner Name": suitcase.ownerName, "Suitcase Owner Organisation": suitcase.ownerOrg};
    }
    getMixpanel(builder.parent).track("Page view", mixpanelData);
  }

  clearSearchSelections = async (): Promise<void> => {
    const { location } = this.props;
    const { search } = location;
    const { from, flow, step } = qs.parse(search.slice(1) || "");
    if (from || flow || step) {
      window.history.replaceState(null, "", "/builder/new");
    }
    const store = this.props.store!;
    const { builder } = store;
    const id = Number(this.props.match.params.id!);
    if (id && !window.location.pathname.includes("builder/new")) {
      // TODO: Think about whether "Discard changes" should be global, or just apply to the current active table
      await builder.loadInsight(id, true);
    } else {
      Object.assign(builder, new Builder(store));
      await builder.newInsight();
      store.builder.getAllDatasets(); // getAllDatasets to show datasets name correctly in "Dataset" dropdown
    }

    this.setState({
      currentStep: "table",
      clearAllConfirmationModalOpen: false,
    });

    builder.ui.setLeftTabFolded(!builder.activeTableId);

    this.handleResetTableTab();
  };

  componentDidMount() {
    const { search } = this.props.location;
    const { source, from } = qs.parse(search.slice(1) || "");
    // Preserve all selections from "guided_explore"
    if (from === "guided_explore" && isAnyFilterSelected(this.props.store!.builder)) {
      return;
    }
    this.mixpanelTracking();
    this.props.store!.builder.getAllDatasets();
    this.initialise();

    // load suitcases if coming fresh to new explore so we can set draft suitcase as default location in save insight modal
    const { id, suitcaseId } = this.props.match.params;
    if (!id && !suitcaseId) {
      this.props.store!.suitcase.getSuitcases();
    }

    if (source === "smart_insights") {
      const smartInsight = ls.getItem("smartInsight");
      const { dataset, dimensions } = JSON.parse(smartInsight!);
      this.props.store!.builder.loadSmartInsight(dataset, dimensions);
    }
  }

  componentDidUpdate(prevProps: Readonly<ComponentProps>) {
    if (prevProps.location.search !== this.props.location.search) {
      // @TODO - do we need to reset insight builder before or as part of initialize
      this.initialise();
    }
    const locationState: any = this.props.history.location.state;
    if (locationState && locationState.reload) {
      delete locationState.reload;
      this.setState({
        currentStep: "table",
      });
      const store = this.props.store!;
      const { insight } = store;
      insight.setDraftName("");
      insight.setDraftKeywords("");
      store.insight = new Insight(store);
    }
  }

  toggleSaveInsightModal = () => this.setState({ saveInsightModalOpen: !this.state.saveInsightModalOpen });

  render() {
    const { currentStep, clearAllConfirmationModalOpen, saveInsightModalOpen } = this.state;
    const { analystPortal } = this.props;
    const store = this.props.store!;
    const { builder } = store;
    const { leftTabFolded, showTableStructureConfirmation } = builder.ui;
    const { id, suitcaseId } = this.props.match.params;
    const calcIsOpen = builder.calc.open;
    const { search } = this.props.location;
    const { from } = qs.parse(search.slice(1) || "");

    const showLoader =
      store.insight.loading.load ||
      builder.loading.getAllDatasets ||
      builder.loading.initialiseGlobalDimensions ||
      builder.loading.loadInsight ||
      builder.loading.reloadQuickStat;
    const errorLoadingInsight = false;

    if (store.user?.group?.expired && !window.location.pathname.includes("builder/new")) {
      return null; // @TODO - investigate this expiry logic, and document reasoning
    }

    let onSaveHandler;
    let initialDestination: number | undefined;
    if (store.user) {
      // Only show the prompt suitcase modal when create new insight by clicking "Explore" in the header
      onSaveHandler = id || suitcaseId ? this.handleSave : this.toggleSaveInsightModal;

      if (!(id || suitcaseId) && store.suitcase?.projects?.length) {
        initialDestination = store.suitcase.projects.find(item => item.private)?.id;
      }
    }

    return (
      <div style={{ overflow: "auto" }}>
        <Helmet>
          <title>Explore Data</title>
        </Helmet>
        {showLoader ? <ShowLoaderGlobal /> : null}
        <Dimmer active={this.state.saving}>
          <Loader>Saving...</Loader>
        </Dimmer>
        <Prompt
          when={(builder.selectedDimensions.length > 0 || builder.userSelectedDatasets.length > 0) && from !== "guided_explore"}
          message={() => "Are you sure you want to leave? You may have unsaved changes."}
        />
        <StyleConfirm
          open={clearAllConfirmationModalOpen}
          header="Are you sure?"
          content={
            `You may have unsaved changes. Are you sure you want to ${window.location.pathname.includes("builder/new")
              ? "clear all?"
              : "discard all unsaved changes?"}`
          }
          confirmButton={
            window.location.pathname.includes("builder/new")
              ? "Yes, clear all"
              : "Yes, discard unsaved changes"
          }
          onCancel={() => this.setState({ clearAllConfirmationModalOpen: false })}
          onConfirm={this.clearSearchSelections}
        />
        <MoveDuplicateModal
          type="insight"
          isOpen={saveInsightModalOpen}
          closeModal={() => this.toggleSaveInsightModal()}
          heading="Save Insight to ..."
          actionText="Save"
          itemsToBeActioned={[{ id: null, name: store.insight.draftName || "Untitled Insight" }]}
          actionClickHandler={(destinationID) => this.handleSave(destinationID!)}
          initialDestination={initialDestination}
        />

        {/* Insight Title */}
        {store!.insight.result?.name &&
          <div className="text-center mb-4 fs-1000 fw-600">
            Insight: {store!.insight.result?.name}
          </div>
        }

        {/* Step bar */}
        {!analystPortal && (
          <ExploreStep
            currentStep={currentStep}
            setCurrentStep={this.setCurrentStep}
            handleCancelEdit={id ? this.handleCancelEdit : null}
          />
        )}
        {/* Builder errors content */}
        {builder.errors.length > 0 && (
          <div className="px-4 mt-3">
            <Message negative>
              <Message.Header>Error/s occurred using the Explore Data tool.</Message.Header>
              <Message.List
                items={builder.errors.map(e => {
                  const { id, type, text, meta } = e;
                  return (
                    <span key={`${id}_${type}`}>
                      {meta?.table && (
                        <b>Table "{builder.tables.find(t => t.id === meta.table)?.name || "Unknown"}": </b>
                      )}
                      {text}
                    </span>
                  );
                })}
              />
            </Message>
          </div>
        )}
        {/* Step content */}
        <div
          className="d-flex align-items-stretch"
          style={{ height: "calc(100vh - 136px)", minHeight: 611, padding: "20px 20px 0px" }}
        >
          {/* Left column */}
          <div
            className="bg-white rounded-3 position-relative border"
            style={{ padding: 0, marginRight: 20, width: leftTabFolded ? 83 : 400 }}
          >

            {currentStep === "table" && (
              <>
                <RobotAnalysisToolContent />
                {calcIsOpen && (
                  <div className="h-100">
                    <div className="d-flex align-items-center justify-content-between" style={{ padding: "14px 15px" }}>
                      <div className="d-flex align-items-center text-secondary">
                        <Icon name="calculator" className="mr-2 mb-1" /><h5 className="my-0">Calculator</h5>
                      </div>
                      <Button onClick={() => builder.setCalcIsOpen(!calcIsOpen)} size="tiny" className="mr-0">
                        Close<Icon name="close" className="ml-2 mr-0" />
                      </Button>
                    </div>
                    <div className="bg-lightgrey" style={{ height: "1px" }}> </div>
                    <div style={{ padding: 15, height: "calc(100% - 60px)", overflowY: "auto", overflowX: "hidden" }}>
                      <div className="mb-4">
                        <p className="fs-1000 mb-1">Type in the formula or click on the Calculator/Tables to create calculations</p>
                        <a href="https://knowledge.seerdata.ai/how-to-use-the-calculate-tool" target="_blank" className="fs-0875 fw-600 text-primary text-decoration-underline my-0">
                          Learn More
                        </a>
                      </div>
                      <Calculator store={builder} />
                    </div>
                  </div>
                )}
                {showTableStructureConfirmation && (
                  <div className="p-5 h-100 overflow-auto">
                    <Heading className="fs-1250">Table Structure Confirmation</Heading>
                    <div className="mb-4 fs-1125">
                      <p>
                        Let's make sure your table is in the right structure to be compared and blended with other data.
                      </p>
                      <p>Your <strong>table columns</strong> are the most important - any new query table sections you add will need to have the same columns as your first table.</p>
                      <p>It looks like your current columns are as follows - are you happy to start your new query with the same columns pre-selected?</p>
                      <p>If not, feel free to drag and drop the items until you are happy with the table structure.</p>
                    </div>
                    <Button className="text-white bg-info" onClick={this.confirmTableStructure}>
                      Confirm Structure
                    </Button>
                    <DraggableLists
                    />
                  </div>
                )}
                {!builder.robot.open && !calcIsOpen && !showTableStructureConfirmation && (
                  <ExploreTableTab
                    forceTabSwitch={this.state.forceTabSwitch}
                    handleClearAll={this.handleClearAll}
                    analystPortal={!!analystPortal}
                  />
                )}
              </>
            )}
            {
              currentStep === "chart" &&
              <ExploreChartTab
                builder={builder}
              />
            }
            {currentStep === "preview" && <ExplorePreviewTab />}
            {/* Fold/Unfold arrow label */}
            {!calcIsOpen && !builder.robot.open && !builder.RATAutoQuery && (
              <div
                className="bg-white position-absolute d-flex align-items-center cursor-pointer border border-left-0"
                style={{ right: -15, top: "calc(50% - 30px)", width: 15, height: 60, borderRadius: "0 6px 6px 0" }}
                onClick={() => builder.ui.setLeftTabFolded(!leftTabFolded)}
              >
                <Icon
                  name={leftTabFolded ? "angle right" : "angle left"}
                  color="grey"
                  style={{ marginLeft: -5, marginTop: -3 }}
                />
              </div>
            )}
          </div>
          {/* Right Column */}
          <div className="bg-white rounded-3 flex-sm-grow-1 border position-relative">
            {
              errorLoadingInsight && (
                <div style={{ marginTop: "3rem" }}>
                  <Message negative>
                    <Message.Header>The Insight did not load correctly.</Message.Header>
                  </Message>
                </div>
              )
            }
            {
              (currentStep === "table" && !errorLoadingInsight) &&
              <ExploreTableResult
                update={!!id!}
                suitcaseId={suitcaseId}
                setCurrentStep={this.setCurrentStep}
                analystPortal={!!analystPortal}
              />
            }
            {
              currentStep === "chart" &&
              <ExploreMultiIndexChartResult
                insight={true}
                setCurrentStep={this.setCurrentStep}
              />
            }
            {
              currentStep === "preview" &&
              <ExplorePreviewResult
                update={!!id!}
                setCurrentStep={this.setCurrentStep}
                onSaveHandler={onSaveHandler}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(inject("store")(observer(NewExplore)));
