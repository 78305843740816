import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm, Field } from "react-final-form";
import { composeValidators, mustBeNumber, required } from "common/helpers/finalForm";
import { Button, Form, Header, Icon } from "semantic-ui-react";
import { FieldInput } from "component/FinalFormFields/FieldInput";
import { FieldReactSelect } from "component/FinalFormFields/FieldSelect";
import { database } from "common/api";
import Store from "common/store";

interface Props {
  store?: Store;
  onSubmit: (values) => void;
}

const Component = (props: Props): JSX.Element => {
  const { store, onSubmit } = props;
  const [options, setOptions] = React.useState([]);
  React.useEffect(() => {
    const init = async () => {
      const res: any = await database.get("group-types", "", store!.token!);
      if (res?.body?.data?.group_types) {
        setOptions(res.body.data.group_types.map((option) => ({ label: option.name, value: option.id })));
      }
    };
    init();
  }, []);
  return (
    <FinalForm
      onSubmit={onSubmit}
      render={(formRenderProps) => {
        const { handleSubmit } = formRenderProps;
        return (
          <Form onSubmit={handleSubmit}>
            <Header as="h3" color="purple" className="mb-5">
              Edit organisation plan
            </Header>
            <div className="mb-5">
              <Field name="group_id" label="Organisation ID" component={FieldInput} validate={composeValidators(required, mustBeNumber)} />
              <Field
                name="group_type_id"
                label="Subscription plan"
                component={FieldReactSelect}
                options={options}
                validate={composeValidators(required)}
              />
            </div>
            <Button color="purple">
              Save
              <Icon name="save" className="ml-1 mr-0" />
            </Button>
          </Form>
        );
      }}
    />
  );
};

export const OrgPlan = inject("store")(observer(Component));
