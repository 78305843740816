import * as React from "react";
import { Icon } from "semantic-ui-react";

interface ITableSortWidget {
  ascendingClickHandler: () => void;
  descendingClickHandler: () => void;
}

export const TableSortWidget = (props: ITableSortWidget): JSX.Element => {
  const { ascendingClickHandler, descendingClickHandler } = props;

  return (
    <div className="d-flex justify-content-between position-absolute top-0 right-0" style={{ width: 38 }}>
      <div
        className="d-flex justify-content-center align-items-center cursor-pointer"
        style={{ width: 18, height: 18, backgroundColor: "rgba(0, 0, 0, 0.1)" }}
        onClick={ascendingClickHandler}
      >
        <Icon name="caret up" />
      </div>
      <div
        className="d-flex justify-content-center align-items-center cursor-pointer"
        style={{ width: 18, height: 18, backgroundColor: "rgba(0, 0, 0, 0.1)" }}
        onClick={descendingClickHandler}
      >
        <Icon name="caret down" />
      </div>
    </div>
  );
};
