import * as React from "react";
import styled from "styled-components";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import Store from "common/store";
import { CompletenessMeasureType } from "common/store/completenessMeasure";
import { CompletenessMeasureVisual } from "../DatasetTemplates/includes/CompletenessMeasureVisual";
import qs = require("qs");
import { ShowLoaderGlobal } from "component/LoaderGlobal";

interface Props {
  store?: Store;
}

const CompletenessMeasureContainer = styled.div`
  overflow: hidden;
  width: 100%;
  scrollbar-gutter: stable; // For avoiding pie chart shiver bug
`;

const Component = (props: Props): JSX.Element => {
  const { store } = props;
  const { completenessMeasure: measureStore } = store!;
  const { search } = useLocation();
  const { id } = qs.parse(search.slice(1) || "");

  const [completenessMeasure, setCompletenessMeasure] = React.useState<CompletenessMeasureType | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");

  const init = async () => {
    if (id) {
      const res: any = await measureStore.getCompletenessMeasure(+id);
      const measure = res.data?.dataset_completeness_measure;
      if (measure) {
        setCompletenessMeasure(measure as CompletenessMeasureType);
      } else {
        setError("Failed to load data. Please try again later.");
      }
    }
    setLoading(false);
    // Send message to parent to update iframe heights
    const measureContainer = document.querySelector("div#completeness_measures_container");
    if (measureContainer) {
      window.parent.postMessage(
        {
          iframeHeight: measureContainer.scrollHeight,
        },
        "*",
      );
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  if (loading) {
    return <ShowLoaderGlobal />;
  }

  return (
    <div className="bg-white d-flex justify-content-center align-items-center" style={{ margin: "-90px 0 -20px" }}>
      {error ? (
        <p className="text-muted fs-1000">{error}</p>
      ) : (
        <CompletenessMeasureContainer id="completeness_measures_container">
          {completenessMeasure ? (
            <CompletenessMeasureVisual value={completenessMeasure?.value} label={completenessMeasure?.name} type="donut" />
          ) : (
            <p className="text-muted fs-1000">Error. Please try again later.</p>
          )}
        </CompletenessMeasureContainer>
      )}
    </div>
  );
};

export const EmbedCompletenessMeasure = inject("store")(observer(Component));
