import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";
import { Form as FinalForm, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { FORM_ERROR } from "final-form";
import { Link, useParams } from "react-router-dom";
import { Form, Grid, Icon } from "semantic-ui-react";
import { FieldInput } from "pages/DatasetTemplateTable/includes/FieldInput";
import { composeValidators, selectionRequired, mustBeNumber, required } from "common/helpers/finalForm";
import { Button } from "component/UI/Button";
import { FieldRadio } from "component/FinalFormFields/FieldRadio";
import { database, getMixpanel } from "common/api";
import { IDataset } from "common/store/dataset";
import { ObjectAny } from "common/helpers/types";
import { uniq } from "lodash";
import { FieldSelect } from "component/FinalFormFields/FieldSelect";
import { FieldCheck } from "component/FinalFormFields/FieldCheck";
import { FieldInputDate } from "component/FinalFormFields/FieldInput";
import { CompletenessMeasureAdvancedConfig, CompletenessMeasureConfig } from "common/store/completenessMeasure";
import { ShowLoaderGlobal } from "component/LoaderGlobal";
import { ISODateToLocalDate } from "common/helpers/dataset";
import { Breadcrumbs } from "component/Breadcrumbs/Breadcrumbs";
import { webUrl } from "common/constants";
import { getDimType } from "common/helpers/data";
import Store from "common/store";

interface CompletenessMeasureForm extends Partial<CompletenessMeasureAdvancedConfig> {
  name: string;
  type: "whole" | "advanced";
}

interface Props {
  store: Store;
  datasetID: number;
  measureID: number;
  variables: ObjectAny;
  measure: CompletenessMeasureForm | null;
}

const getDatasetVariables = (dataset: IDataset): ObjectAny => {
  const variables: ObjectAny = {};
  for (const template of dataset.templates) {
    for (const table of template.tables) {
      const { rows, columns } = table.schema;
      const tableVariables = [...rows, ...columns];
      for (const variable of tableVariables) {
        if (variables[variable.variable]) {
          variables[variable.variable] = uniq([...variables[variable.variable], ...variable.categories]);
        } else {
          variables[variable.variable] = [...variable.categories];
        }
      }
    }
  }
  return variables;
};

const toISODate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toISOString();
};

const defaultInitialValues: CompletenessMeasureForm = {
  name: "",
  type: "whole",
  measuredQuantities: [],
  limitCategories: false,
  categoryLimits: [{ variable: "", categories: [] }],
  timeLimit: false,
  timeType: "absolute",
  absoluteTime: { from: "", to: "" },
  relativeTime: { unit: "day", value: 1 },
};

const validTimeVariables = [
  "Year",
  "Year Month",
  "Year Quarter",
];

const ifValidVariable = (variable: string): boolean => getDimType(variable) === "When" ? validTimeVariables.includes(variable) : true;

const Container = ({ store }): JSX.Element => {
  const { datasetID, measureID } = useParams<{ datasetID: string; measureID: string }>();
  const { completenessMeasure: measureStore } = store!;
  const [dataset, setDataset] = React.useState<IDataset | null>(null);
  const [measureData, setMeasureData] = React.useState<CompletenessMeasureForm | null>(null);
  const [loading, setLoading] = React.useState(true);

  const variables = dataset ? getDatasetVariables(dataset) : {};

  const init = async () => {
    const res: any = await database.get("dataset-templates", "", store.token!);
    const datasets = res.body?.data?.datasets;
    if (datasets) {
      setDataset(datasets.find(dataset => dataset.id === +datasetID) as IDataset);
    }
    if (measureID) {
      const res: any = await measureStore.getCompletenessMeasure(+measureID);
      const measureData: any = res.data?.dataset_completeness_measure;
      if (measureData) {
        const { name, config } = measureData;
        const { type, advanced } = config;
        let measure: CompletenessMeasureForm = { ...defaultInitialValues, name, type };
        if (advanced) {
          measure = { ...measure, ...advanced };
          if (advanced.timeType === "absolute" && advanced.absoluteTime) {
            measure.absoluteTime = {
              from: ISODateToLocalDate(advanced.absoluteTime.from as string) || "",
              to: ISODateToLocalDate(advanced.absoluteTime.from as string) || "",
            };
          }
        } else {
          measure = { ...measure };
        }
        setMeasureData(measure);
      }
    }
    setLoading(false);
  };

  React.useEffect(() => {
    init();
    const mixpanelData = { "Type": measureID ? "Edit" : "Create", "Dataset Id": datasetID };
    if (measureID) {
      mixpanelData["Measure Id"] = measureID;
    }
    getMixpanel(store!).track("Completeness Measures Form", mixpanelData);
  }, []);

  if (loading) {
    return <ShowLoaderGlobal />;
  }

  return (
    <div className="my-0 mx-auto" style={{ width: "940px" }}>
      <Helmet><title>{`${measureID ? "Edit" : "Create"} Completeness Measure`}</title></Helmet>
      <Breadcrumbs
        items={[{ pathname: "/datasets", label: "Datasets" }, { pathname: `/datasets/${datasetID}`, label: dataset?.name || "" }, { pathname: "", label: `${measureID ? "Edit" : "Create"} Completeness Measure` }]}
      />
      <Component
        store={store}
        datasetID={+datasetID}
        measureID={+measureID}
        variables={variables}
        measure={measureData}
      />
    </div>
  );
};

const Component = (props: Props): JSX.Element => {
  const { store, datasetID, measureID, variables, measure } = props;
  const { completenessMeasure: measureStore } = store!;

  const measuredQuantitiesOptions = variables["Measured quantity"]?.map((category: string, idx: number) => ({ key: idx, value: category, text: category }));
  const variableOptions = Object.keys(variables as ObjectAny)
    .filter(variable => variable !== "Measured quantity")
    .filter(variable => ifValidVariable(variable))
    .map((variable: string, idx: number) => ({ key: idx, value: variable, text: variable }));
  const getCategoryOptions = (variable: string): string[] => variables[variable]?.map((category: string, idx: number) => ({ key: idx, value: category, text: category }));
  const relativeTimeUnitOptions = ["Day", "Week", "Month", "Year"].map((unit, idx) => ({ key: idx, value: unit.toLowerCase(), text: unit }));

  const handleSave = async (values): Promise<any> => {
    const { name, type, measuredQuantities, limitCategories, categoryLimits, timeLimit, timeType, absoluteTime, relativeTime } = values;
    const config: CompletenessMeasureConfig = { type: "whole" };
    if (type === "advanced") {
      config.type = "advanced";
      const advanced: CompletenessMeasureAdvancedConfig = { limitCategories: false, timeLimit: false };
      if (measuredQuantities.length) {
        advanced.measuredQuantities = [...measuredQuantities];
      }
      if (limitCategories) {
        advanced.limitCategories = true;
        advanced.categoryLimits = [...categoryLimits];
      }
      if (timeLimit) {
        advanced.timeLimit = true;
        advanced.timeType = timeType;
        if (timeType === "absolute") {
          advanced.absoluteTime = { from: toISODate(absoluteTime.from as string), to: toISODate(absoluteTime.to as string) };
        } else {
          advanced.relativeTime = { unit: relativeTime.unit, value: +relativeTime.value };
        }
      }
      config.advanced = advanced;
    }
    const body = { name, config };
    let success: boolean;
    if (measureID) {
      success = await measureStore.updateCompletenessMeasure(measureID, body);
      getMixpanel(store!).track("Completeness Measures Form > Update", { "Dataset Id": datasetID, "Measure Id": measureID });
    } else {
      success = await measureStore.createCompletenessMeasure(datasetID, body);
      getMixpanel(store!).track("Completeness Measures Form > Save", { "Dataset Id": datasetID });
    }
    if (!success) {
      return { [FORM_ERROR]: `Request to ${measureID ? "update" : "create"} Dataset Completeness Measure failed.` };
    } else {
      window.location.href = `${webUrl}/datasets/${datasetID}`;
    }
  };

  return (
    <>
      <FinalForm
        initialValues={measure || defaultInitialValues}
        onSubmit={handleSave}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, submitting, submitError, values, form }) => {
          const { type, limitCategories, categoryLimits, timeLimit, timeType, absoluteTime } = values;
          const { mutators: { push } } = form;
          return (
            <Form onSubmit={handleSubmit} className="mb-5">
              <div className="d-flex align-items-start justify-content-between">
                <div>
                  <h5>Name</h5>
                  <Field
                    name="name"
                    component={FieldInput}
                    validate={composeValidators(required)}
                  />
                </div>
                <div>
                  <Link to={`/datasets/${datasetID}`}>
                    <Button className="mr-2" type="button">Cancel</Button>
                  </Link>
                  <Button colorConfig="redInverted" type="submit" disabled={submitting}>Save<Icon className="save ml-2 mr-0" /></Button>
                  {submitError && <p className="text-danger"><b>{submitError}</b></p>}
                </div>
              </div>
              <h5>Select a measure type</h5>
              <Field
                name="type"
                items={[{ label: "Whole Dataset", value: "whole" }, { label: "Advanced", value: "advanced" }]}
                component={FieldRadio}
              />
              {type === "advanced" && (
                <>
                  <h5>Select the Measured Quantities to include</h5>
                  <Field
                    name="measuredQuantities"
                    options={measuredQuantitiesOptions}
                    component={FieldSelect}
                    validate={composeValidators(selectionRequired)}
                    multiple={true}
                  />
                  <h5>Limit to specific variable categories</h5>
                  <div className="d-flex align-items-center justify-content-between">
                    <Field
                      name="limitCategories"
                      toggle={true}
                      component={FieldCheck}
                    />
                    {limitCategories && (
                      <Button type="button" onClick={() => push("categoryLimits", { variable: "", categories: [] })}>Add Variable</Button>
                    )}
                  </div>
                  {limitCategories && (
                    <Grid columns={3}>
                      <FieldArray name="categoryLimits">
                        {(props) => {
                          const { fields } = props;
                          return (
                            fields.map((name, idx) => (
                              <Grid.Row key={idx}>
                                <Grid.Column>
                                  <Field
                                    name={`${name}.variable`}
                                    options={variableOptions}
                                    validate={composeValidators(required)}
                                    label="Variables"
                                    component={FieldSelect}
                                  />
                                </Grid.Column>
                                <Grid.Column>
                                  <Field
                                    name={`${name}.categories`}
                                    options={getCategoryOptions(categoryLimits[idx]?.variable as string) || []}
                                    label="Categories"
                                    component={FieldSelect}
                                    validate={composeValidators(selectionRequired)}
                                    multiple={true}
                                  />
                                </Grid.Column>
                                <Grid.Column>
                                  <Icon name="close" className="cursor-pointer mt-4" onClick={() => fields.remove(idx)} />
                                </Grid.Column>
                              </Grid.Row>
                            ))
                          );
                        }}
                      </FieldArray>
                    </Grid>
                  )}
                  <h5>Limit to specific period in time</h5>
                  <Field
                    name="timeLimit"
                    toggle={true}
                    component={FieldCheck}
                  />
                  {timeLimit && (
                    <>
                      <Field
                        name="timeType"
                        items={[{ label: "Absolute", value: "absolute" }, { label: "Relative", value: "relative" }]}
                        label="Time type"
                        component={FieldRadio}
                        className="mb-3"
                      />
                      <Grid columns={2}>
                        <Grid.Row>
                          {timeType === "absolute" ? (
                            <>
                              <Grid.Column>
                                <Field
                                  name="absoluteTime.from"
                                  component={FieldInputDate}
                                  label="From"
                                  validate={timeLimit && timeType === "absolute" ? composeValidators(required) : undefined}
                                />
                              </Grid.Column>
                              <Grid.Column>
                                <Field
                                  name="absoluteTime.to"
                                  component={FieldInputDate}
                                  min={absoluteTime?.from}
                                  label="To"
                                  validate={timeLimit && timeType === "absolute" ? composeValidators(required) : undefined}
                                />
                              </Grid.Column>
                            </>
                          ) : (
                            <>
                              <Grid.Column>
                                <Field
                                  name="relativeTime.unit"
                                  options={relativeTimeUnitOptions}
                                  label="Unit"
                                  component={FieldSelect}
                                />
                              </Grid.Column>
                              <Grid.Column>
                                <Field
                                  name="relativeTime.value"
                                  component={FieldInput}
                                  label="Value"
                                  validate={composeValidators(mustBeNumber)}
                                />
                              </Grid.Column>
                            </>
                          )}
                        </Grid.Row>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Form>
          );
        }}
      />
    </>
  );
};

export const CompletenessMeasureForm = inject("store")(observer(Container));
