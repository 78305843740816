import * as React from "react";
import { inject, observer } from "mobx-react";
import { Modal, ModalHeader, ModalActions, ModalContent, Button, Message } from "semantic-ui-react";
import { ColorApplicatorUI } from "component/ColorPaletteWidgets/ColorApplicatorUI";

const ColorApplicatorModalComponent = (props: any) => {
  const { isOpen, closeModal, store, applicator, onApplyBrush } = props;

  return (
    <>
      <Modal open={isOpen} onClose={closeModal}>
        <ModalHeader className="text-secondary">Suitcase Colour Applicator</ModalHeader>
        <ModalContent className="seer-default-styles">
          <Message color="purple">
            <p>Use the colour applicator brush to apply colours across all Insights in this Suitcase.</p>
            <p>After the brush is applied, the colours on your Insights charts will be updated immediately.</p>
            <p className="m-0">Refresh the page to see the changes.</p>
          </Message>
          <ColorApplicatorUI allColorPalettes={store.colorPalette.allColorPalettes} applicator={applicator} onApplyBrush={onApplyBrush} />
        </ModalContent>
        <ModalActions>
          <Button onClick={closeModal}>Close</Button>
        </ModalActions>
      </Modal>
    </>
  );
};

export const ColorApplicatorModal = inject("store")(observer(ColorApplicatorModalComponent));
