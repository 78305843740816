import * as React from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { Form, Icon } from "semantic-ui-react";
import { composeValidators, mustBeNumber, required } from "common/helpers/finalForm";
import { FieldInput } from "component/FinalFormFields/FieldInput";
import { Button } from "component/UI/Button";

interface Props {
  type: "dashboard" | "suitcase";
  onSubmit: (values) => void;
}

export const ChangeOwner = (props: Props): JSX.Element => {
  const { type, onSubmit } = props;
  return (
    <FinalForm
      onSubmit={onSubmit}
      render={(formRenderProps) => {
        const { handleSubmit } = formRenderProps;
        return (
          <Form onSubmit={handleSubmit}>
            <h2 className="text-secondary mb-5">{`Edit ${type.charAt(0).toUpperCase()}${type.slice(1)} owner`}</h2>
            <div className="mb-5">
              <Field
                name={`${type}_id`}
                label={`${type.charAt(0).toUpperCase()}${type.slice(1)} ID`}
                component={FieldInput}
                validate={composeValidators(required, mustBeNumber)}
              />
              <Field
                name="user_id"
                label="User ID"
                component={FieldInput}
                validate={composeValidators(required, mustBeNumber)}
              />
            </div>
            <Button colorConfig="purpleInverted">Save<Icon name="save" className="ml-2" /></Button>
          </Form>
        );
      }}
    />
  );
};
