import * as React from "react";
import { insertScript } from "common/helpers/script";
import { COLORS } from "component/UI/common";
import styled from "styled-components";

const StyledSwiperButton = styled.div`
  margin: 0 20px;
  color: ${COLORS.indigo400};
  :after {
    font-size: 30px;
  }
`;

const StyledProgressBar = styled.div`
  top: auto !important;
  bottom: 0;
  height: 8px !important;
  background: ${COLORS.indigo300};
  &.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: ${COLORS.indigo400};
  }
`;

const CAROUSEL_FEATURES = [
  {
    url: "/assets/onboarding/onboarding_carousel_build_your_data_collection.png",
    heading: "Build your data collection",
    description: "Collect data from multiple contributors in our secure cloud environment for shared learning and measurement.",
  },
  {
    url: "/assets/onboarding/onboarding_carousel_analyse_insights.png",
    heading: "Analyse Insights",
    description: "Explore open and private data to uncover insights, build community context, and drive change.",
  },
  {
    url: "/assets/onboarding/onboarding_carousel_share_community_story.png",
    heading: "Share your community's story",
    description: "Combine charts, narrative and media content to build beautiful dashboards, to share with your community.",
  },
];

export const FeatureCarousel = () => {
  let swiper;

  const init = async () => {
    await insertScript("swiper", { type: "text/javascript", src: "https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.js" });
    swiper = new window["Swiper"](".swiper", {
      effect: "slide",
      allowSlideNext: true,
      allowSlidePrev: true,
      centeredSlides: true,
      slidesPerView: "auto",
      allowTouchMove: true,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        type: "progressbar",
      },
      autoplay: {
        delay: 4000,
      },
    });
  };

  React.useEffect(() => {
    init();
  }, []);

  return (
    <div className="swiper h-100">
      <div className="swiper-wrapper">
        {CAROUSEL_FEATURES.map((feature, idx) => {
          const { url, heading, description } = feature;
          return (
            <div
              key={idx}
              className="swiper-slide d-flex flex-column flex-md-row flex-lg-column align-items-center justify-content-between p-5"
              style={{ cursor: "grab", backgroundColor: COLORS.indigo100, boxSizing: "border-box" }}
            >
              <img src={url} alt={heading} className="mt-0 mt-lg-5" style={{ width: 360, height: 360 }} />
              <div className="text-left text-lg-center">
                <p className="fs-1500 fw-700 mb-3" style={{ color: COLORS.grey800 }}>
                  {heading}
                </p>
                <p className="fs-1000" style={{ color: COLORS.grey800 }}>
                  {description}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <StyledSwiperButton
        className="swiper-button-prev"
        onClick={() => {
          swiper.slidePrev();
        }}
      ></StyledSwiperButton>
      <StyledSwiperButton
        className="swiper-button-next"
        onClick={() => {
          swiper.slideNext();
        }}
      ></StyledSwiperButton>
      <StyledProgressBar className="swiper-pagination"></StyledProgressBar>
    </div>
  );
};
