import * as React from "react";
import * as ReactDOM from "react-dom";
import { StyledDescription } from "pages/Suitcase/suitcaseOverviewPage";
import { DataFilter } from "./DataFilter";
import { useLocation, useRouteMatch } from "react-router-dom";
import { injectDPACCustomizations } from "component/DPACGIATCustomForm/InjectCustomFormMultiRow";
import { NavDefault } from "./NavDefault";
import { NavCustom } from "./NavCustom";
import { UnlayerConfig } from "./helpers";

interface ComponentProps {
  html: any;
  dashboardID: number | string;
  setPageIdx: (idx: number) => void;
  pageIdx: number;
  unlayer_config: UnlayerConfig;
}

const getNavType = (unlayer_config): "none" | "default" | "custom" | "custom_mounted" => {
  if (!unlayer_config.navigation?.customNav) {
    // default nav
    if (unlayer_config.pages.length < 2) {
      return "none";
    } else if (unlayer_config.navigation?.includedPages && unlayer_config.navigation.includedPages.filter((show) => show).length < 2) {
      return "none";
    }
    return "default"; // only display if there are more than 2 pages and at least 2 are shown
  } else {
    // custom nav
    return unlayer_config.navigation.customNavConf.position === "custom" ? "custom_mounted" : "custom";
  }
};

export const DashboardHtml = (props: ComponentProps): JSX.Element => {
  const { html, dashboardID, setPageIdx, pageIdx, unlayer_config } = props;
  const descriptionID = `dashboard_description_${dashboardID}`;
  const navType = getNavType(unlayer_config);
  // this state is used to ensure the parent div is mounted and mutation observer attached before adding the html child element
  const [mounted, setMounted] = React.useState(false);
  const location = useLocation();
  const route = useRouteMatch();

  // render components for custom data filter tool
  const addDataFilterComponents = () => {
    const targetList = document.querySelectorAll(`#${descriptionID} .custom-tool-data-filter`);
    targetList.forEach((node) => {
      const select = node.querySelector("select") as HTMLSelectElement;
      const variable = select.dataset.seerVariable!; // this will always be set
      const categories: { name: string; selected: boolean }[] = (Array.from(select.children) as HTMLOptionElement[]).map((opt) => ({
        name: opt.value,
        selected: opt.selected,
      }));
      let insightIds: number[] = [];
      if (select.dataset.seerInsights) {
        insightIds = select.dataset.seerInsights.split(",").map((id) => +id);
      }
      const dashFilterOptions = select.dataset.seerOptions ? JSON.parse(select.dataset.seerOptions) : undefined;
      ReactDOM.render(
        <DataFilter
          variable={variable}
          categories={categories}
          insightIds={insightIds}
          dashboardDescriptionID={descriptionID}
          dashFilterOptions={dashFilterOptions}
        />,
        node,
      );
    });
  };

  const mountCustomNav = () => {
    const mountPoint = document.getElementById("seer-dash-nav-custom-position");
    if (mountPoint) {
      ReactDOM.render(
        <NavCustom
          setPageIdx={setPageIdx}
          pageIdx={pageIdx}
          unlayer_config={unlayer_config}
          embedded={route.path.includes("/embed/dashboards/:dashboardID")}
        />,
        mountPoint,
      );
    }
  };

  React.useEffect(() => {
    const observer = new MutationObserver((mutations, _observer) => {
      for (const mutation of mutations) {
        const target = mutation.target;
        if (target instanceof HTMLDivElement && target.id === descriptionID && mutation.addedNodes.length) {
          // dangerouslySetInnerHTML is now mounted, and we can attach any desired listeners / components
          addDataFilterComponents();
          if (unlayer_config.navigation?.customNav && unlayer_config.navigation.customNavConf.position === "custom") {
            mountCustomNav();
          }
          injectDPACCustomizations(+dashboardID);
          _observer.disconnect();
          break;
        }
      }
    });
    const targetNode = document.getElementById(descriptionID);
    if (targetNode) {
      observer.observe(targetNode, { childList: true });
    }
    setMounted(true);
    return () => {
      observer.disconnect(); // doesn't matter if it's called multiple times
    };
  }, [html]);

  // Scroll to the anchored section when anchor link is set in URL after mounted
  React.useEffect(() => {
    if (mounted) {
      const { hash } = location;
      if (hash) {
        const findElement = document.querySelector(`a[name="${hash.slice(1)}"], ${hash}`);
        if (findElement) {
          findElement.scrollIntoView({ behavior: "auto" });
        }
      }
    }
  }, [mounted]);

  return (
    <div id={descriptionID}>
      {(navType === "default" || unlayer_config.navigation?.customNavConf?.showInbuiltControls) && (
        <NavDefault setPageIdx={setPageIdx} pageIdx={pageIdx} unlayer_config={unlayer_config} />
      )}
      {navType === "custom" && (
        <NavCustom
          setPageIdx={setPageIdx}
          pageIdx={pageIdx}
          unlayer_config={unlayer_config}
          embedded={route.path.includes("/embed/dashboards/:dashboardID")}
        />
      )}
      {mounted && <StyledDescription dangerouslySetInnerHTML={{ __html: html }} />}
    </div>
  );
};
