import { observable, action, computed } from "mobx";
import { includes, uniq, find, cloneDeep, findIndex, omit, flatten, entries, pick } from "lodash";
import _ = require("lodash");
import {
  collator,
  qsFilterTypes,
  transformResToDims,
  getResSelectedAndCompulsory,
  getNewQsFilters,
  sortDimensionValues,
  generateTableId,
  getDimType,
  comparableDimensions,
  getCombinedDims,
  sortSingleFilterValues,
  getUpdatedDims,
  RANGE_COMPATIBLE_VARIABLES,
  getDimensionMetadata,
} from "common/helpers/data";
import { datasets as requestDataApi, database as requestAdminAPI, getMixpanel } from "../api";
import Store from "../store";
import { BuilderUserInterface } from "./builderUserInterface";
import * as math from "mathjs";
import { buildArrayCombos } from "common/helpers/dimensions";
import {
  getChartData,
  getChartTables,
  getLogicalChartDefaults,
  generateColorsWithPalette,
  getChartKey,
  getChartColors,
  getCustomChartColorNumber,
  DEFAULT_COLOR_PALETTE,
} from "common/helpers/chart";
import {
  calcToFormula,
  eqStringToEq,
  getCalcTableColourNumber,
  getCNumber,
  getMissingFallback,
  getResultTableColourNumber,
  getTableRowTotals,
  isEqStringValid,
} from "common/helpers/explore";
import { brushes } from "component/ColorPaletteWidgets/withColorApplicator";

const DEFAULT_CHART_CONFIG = {
  type: "Vertical bar clustered",
  highlights: [],
  percentage: false,
  xLabel: "",
  yLabel: "",
  angle: undefined,
  labelFont: undefined,
  labelLength: 0,
  userDefinedMaxValue: undefined,
  userDefinedMinValue: undefined,
  userDefinedIncrements: undefined,
  showBarLabel: false,
  barLabelFontSize: 11,
  showRawValueInTooltip: false,
  condensedEmbed: true,
  showSource: false,
};

const defaultDynamicQueries = { columns: {}, tables: {} };

/* Type and Interface Declarations */
type ObjectAny = Record<string, any>;

type ChartLayout = 1 | 2 | 3;

export type Requirement = "compulsory" | "optional" | "excluded" | "selected" | "comparable";
export type Source = Record<string, boolean>;
export type Value = string | number;
export type IQSFilters = Record<string, Value[]>;

export interface IFilter {
  dimension: string;
  values: Value[];
  data?: IDimension;
}

export interface IAllDatasets {
  name: string;
  key: string;
  description: string;
  id: number;
  updated_at: string | null;
}

export interface IDimensionValue {
  name: Value;
  requirement: Requirement;
  source: Source;
}

export interface IDimension {
  source: Source;
  type: "What" | "How" | "Where" | "When";
  requirement: Requirement;
  values: IDimensionValue[];
  comparableTo?: string[];
}

export interface IGlobalDimension {
  source: Source;
  type: "What" | "How" | "Where" | "When";
  values: IDimensionValue[];
}

interface IChart {
  type: string;
  highlights: string[];
  percentage: boolean;
  xLabel: string;
  yLabel: string;
  angle: number | undefined;
  labelFont: number | undefined;
  labelLength: number;
  userDefinedMaxValue: number | undefined;
  userDefinedMinValue: number | undefined;
  userDefinedIncrements: number | undefined;
  width?: number;
  height?: number;
  metricBigLabelFontSize?: number | undefined;
  metricSmallLabelFontSize?: number | undefined;
  showBarLabel?: boolean;
  barLabelFontSize?: number;
  showRawValueInTooltip?: boolean;
  condensedLegend?: boolean;
  hideTableNames?: boolean;
  condensedEmbed?: boolean;
  showSource?: boolean;
  axisTickInterval?: number;
  metricLabelOverride?: string;
}

interface BuilderError {
  id: string;
  type: "general" | "dataset";
  text: string;
  meta?: any;
}

interface DynamicQueries {
  columns: Record<string, DynamicFilter>;
  tables: Record<string | number, Record<string, DynamicFilter>>; // e.g. { [tableId]: { [dim]: DynamicFilter } }
}

interface DynamicFilterBase {
  type?: "range" | "last";
  exclude?: (number | string)[];
}

interface DynamicFilterRange extends DynamicFilterBase {
  type?: "range";
  lte?: number | string;
  gte?: number | string;
}

interface DynamicFilterLast extends DynamicFilterBase {
  type?: "last";
  last?: number;
}

export type DynamicFilter = DynamicFilterRange | DynamicFilterLast;

export type TypeDecimalPoints = undefined | 0 | 1 | 2;

export interface RATToolQuery {
  rows: Record<string, { filters: IQSFilters; dataset: string }>;
  formula: string;
  variable: string;
  find: "Top" | "Bottom";
  size: number;
  in: IQSFilters;
}

export class Builder {
  // QuickStat filters
  @observable filters: IFilter[] = [];
  @observable rows: IFilter[] = [];
  @observable columns: IFilter[] = [];
  @observable dynamicQueries: DynamicQueries = defaultDynamicQueries;
  @observable userSelectedDatasets: string[] = []; // selected dataset filters

  @observable datasetsToQuery: string[] = []; // datasets to query from quickstat

  @observable allDatasets: IAllDatasets[] = []; // dataset information, names, descriptions etc

  @observable dimensions: Record<string, IDimension> = {}; // list of dimensions for current query; object keys are dimension (variable) names
  @observable globalDimensions: Record<string, IGlobalDimension> = {}; // full list of dimensions for all the datasets the user can access

  @observable results: ObjectAny[] = [];
  @observable tables: ObjectAny[] = []; // @TODO - update with expected format when known
  @observable activeTableId: number | undefined = undefined;

  @observable chartVariables: any[] = [];

  @observable chartData: any[] = [];
  @observable customChartColors: string[] = [];
  @observable chart: IChart = DEFAULT_CHART_CONFIG;
  @observable chartLayout: ChartLayout = 1;
  @observable userDefinedCharts: any = {};
  @observable autoRefresh = false;
  @observable decimalPoints: TypeDecimalPoints;
  @observable calcMissingFallback: boolean | undefined = false;
  @observable calcMissingFallbackValue: number | undefined = undefined;
  @observable RATAutoQuery: RATToolQuery | undefined = undefined;

  @observable loading = {
    loadInsight: false,
    getAllDatasets: false,
    initialiseGlobalDimensions: false,
    reloadQuickStat: false,
    loadSmartInsight: false,
  };
  @observable errors: BuilderError[] = []; // global errors for builder
  @observable downloadingChart = false;
  // robot analysis tool state
  @observable robot: any = {
    open: false,
    filters: [],
    dataset: null,
    calc: null,
  };
  @observable calc: any = {
    open: false,
    type: "Decimal",
    eqString: "",
    editIdx: -1,
  };

  ui = new BuilderUserInterface(this);

  // Store user's deselected dimensions to avoid keeps adding "compulsory" dimensions back that users intentionally deselected
  userDeselectedDimensions: any[] = [];
  /* class props */
  canvas: string[] = []; // saving canvas / selectedBrush for potential backend use in future
  selectedBrush = "cycle";

  parent: Store;

  constructor(parent: Store) {
    this.parent = parent;
  }

  @computed get calcPossible(): boolean {
    // Is the calculation valid
    if (!this.calc.eqString) {
      return false;
    }
    const lastRowIndex = getTableRowTotals(this.tables).reduce((prev, curr) => prev + curr, 0);
    const tableIds = this.tables.filter((t) => t.type === "result").map((t) => t.id);
    if (!isEqStringValid(this.calc.eqString, lastRowIndex, tableIds)) {
      return false;
    }
    const eq = eqStringToEq(this.calc.eqString);
    const formattedEq = eq.map((str) => {
      if (str.includes("Row")) {
        return "1";
      } else if (str.includes("Table")) {
        return "1,1";
      }
      return str;
    });
    try {
      math.evaluate(formattedEq.join(""));
      return true;
    } catch {
      return false;
    }
  }

  @computed get chartResults(): any[] {
    const allChartResults: any = this.tables
      .filter((table) => table.results && table.results.length > 0)
      .reduce((prev, curr) => prev.concat(curr.results), []);
    return _.uniqWith(allChartResults, _.isEqual);
  }

  @computed get logicalChartDefaults(): any {
    // Update chart logic, details are here: https://www.notion.so/seerdata/New-Builder-Bug-Time-and-Location-not-displaying-correctly-d9565309123d4b44a0990999fc7de08f
    // Helper function "getLogicalChartDefaults" in "chart.ts" used by New Builder and Charts Preview
    // Pass in "chartTables": so when users haven't drop&drop in the Chart Step, we are able to only show the available "Variables" based on the table toggles
    return getLogicalChartDefaults(this.chartTables, this.columns);
  }

  @computed get chartKey(): any {
    return getChartKey(this.newChartData);
  }

  @computed get chartLegend(): any {
    if (!this.userDefinedCharts.legend) {
      return this.logicalChartDefaults.legend;
    } else {
      return this.userDefinedCharts.legend;
    }
  }

  @computed get chartXAxisArray(): any {
    if (!this.userDefinedCharts.xAxis) {
      return this.logicalChartDefaults.xAxis;
    } else {
      return this.userDefinedCharts.xAxis;
    }
  }

  @computed get chartSeries(): any {
    if (!this.userDefinedCharts.series) {
      return this.logicalChartDefaults.series;
    } else {
      return this.userDefinedCharts.series;
    }
  }

  @computed get chartXAxisCombo(): any {
    return buildArrayCombos(this.chartXAxisArray.map((x) => x.values));
  }

  @computed get chartSeriesSingular(): any {
    return this.chartSeries[0];
    // return this.userDefinedCharts.series ? this.userDefinedCharts.series[0] : this.logicalChartDefaults.series[0];
  }

  @computed get newChartData(): any {
    return getChartData(
      this.chartXAxisArray,
      this.chartLegend,
      this.chartSeries,
      this.columns,
      this.tables,
      getMissingFallback(this.calcMissingFallback, this.calcMissingFallbackValue),
    );
  }

  @computed get chartTables(): any[] {
    return getChartTables(this.tables);
  }

  @computed get chartColors(): string[] {
    return getChartColors(this.customChartColors, this.chart.type, this.chartTables, this.columns, this.chartXAxisArray, this.chartSeries);
  }

  @computed get hasCustomChartColors(): boolean {
    return this.customChartColors.length > 0;
  }

  // transform filters to qs request format
  @computed get qsFilters(): IQSFilters {
    return this.filters.reduce((obj, item) => ({ ...obj, [item.dimension]: item.values }), {});
  }
  @computed get qsRows(): IQSFilters {
    return this.rows.reduce((obj, item) => ({ ...obj, [item.dimension]: item.values }), {});
  }
  @computed get qsColumns(): IQSFilters {
    return this.columns.reduce((obj, item) => ({ ...obj, [item.dimension]: item.values }), {});
  }
  @computed get qsAllFilters(): IQSFilters {
    return [...this.filters, ...this.rows, ...this.columns].reduce((obj, item) => ({ ...obj, [item.dimension]: item.values }), {});
  }
  // processed filters ensures any dynamic filters are applied instead of the regular category list
  @computed get qsAllFiltersProcessed(): IQSFilters {
    return [...this.filters, ...this.rows, ...this.columns].reduce((obj, item) => {
      const rawDynamicFilter = this.getDynamicDimFilter(item.dimension); // includes type property that we don't wish to pass
      const dynamicFilter = rawDynamicFilter ? { ...rawDynamicFilter, type: undefined } : undefined;
      // if no dynamic configuration is set, then should be set to empty [] not empty {}
      const dynamicValue = dynamicFilter && (Object.values(dynamicFilter).some((val) => !!val) ? dynamicFilter : []);
      return { ...obj, [item.dimension]: dynamicValue || item.values };
    }, {});
  }

  @computed get selectedDimensions(): IFilter[] {
    return [...this.filters, ...this.rows, ...this.columns];
  }

  @computed get selectedDimensionsByType(): Record<string, IFilter[]> {
    return this.selectedDimensions.reduce((acc, v) => {
      if (this.dimensions[v.dimension]) {
        acc[this.dimensions[v.dimension].type] = [...(acc[this.dimensions[v.dimension].type] || []), v];
      }
      return acc;
    }, {});
  }

  @computed get tablesSelectedDimensions(): IFilter[] {
    return this.tables.reduce<IFilter[]>((acc, table) => [...acc, ...table.filters, ...table.rows, ...table.columns], []);
  }

  @computed get allTablesRowsAndCols(): IFilter[] {
    return this.tables.reduce<IFilter[]>((acc, table) => [...acc, ...table.rows, ...table.columns], []);
  }

  @computed get multiCategoryColumns(): IFilter[] {
    return this.columns.filter((d) => d.values.length > 1);
  }

  @computed get multiCategoryRows(): IFilter[] {
    return this.tables
      .filter((table) => table.type === "result")
      .reduce<IFilter[]>((acc, table) => [...acc, ...table.rows], [])
      .filter((d) => d.values.length > 1);
  }

  @computed get singleCategoryDimensions(): IFilter[] {
    return this.tablesSelectedDimensions.filter((d) => d.values.length === 1);
  }

  @computed get uniqueMultiCategoryVariables(): IFilter[] {
    const combinedCols = getCombinedDims(this.columns);
    const uniqueMultiCategoryCols = combinedCols.filter((c) => c.values.length > 1);
    const uniqueMultiCategoryRows = uniq(this.multiCategoryRows);
    return [...uniqueMultiCategoryCols, ...uniqueMultiCategoryRows];
  }

  @computed get userDefinedChartsVariables(): IFilter[] {
    return flatten(Object.values(this.userDefinedCharts));
  }

  @computed get allocatedVariables(): string[] {
    return uniq(
      [...this.userDefinedCharts.legend, ...this.userDefinedCharts.xAxis, ...this.userDefinedCharts.series].map((d) => d.dimension),
    );
  }

  @computed get allocatedXAxis(): string[] {
    return this.userDefinedCharts.xAxis.map((d) => d.dimension);
  }

  @computed get allocatedLegend(): string[] {
    return this.userDefinedCharts.legend.map((d) => d.dimension);
  }

  @computed get allocatedSeries(): string[] {
    return this.userDefinedCharts.series.map((d) => d.dimension);
  }

  // get json string to save for insight
  @computed get json(): Record<string, any> {
    // @TODO - chart (graph), sort? (not certain yet if at root or tables level)
    const {
      columns,
      tables,
      userDefinedCharts,
      customChartColors,
      chartLayout,
      chart,
      canvas,
      selectedBrush,
      dynamicQueries,
      autoRefresh,
      decimalPoints,
      calcMissingFallback,
      calcMissingFallbackValue,
      RATAutoQuery,
    } = this;

    const insightJson = {
      builderVersion: "2.0.0",
      columns,
      dynamicQueries,
      tables: tables.map((t) => omit(t, "dimensions")),
      chartLayout,
      canvas,
      selectedBrush,
      date: new Date(),
      userId: this.parent.user!.id,
      autoRefresh,
      decimalPoints,
      calcMissingFallback,
      calcMissingFallbackValue,
      RATAutoQuery,
    };

    if (chart !== DEFAULT_CHART_CONFIG) {
      insightJson["chart"] = chart;
    }
    if (Object.keys(userDefinedCharts).length > 0) {
      insightJson["userDefinedCharts"] = userDefinedCharts;
    }
    if (customChartColors.length > 0) {
      insightJson["customChartColors"] = customChartColors;
    }

    return insightJson;
  }

  // true if insight has results in at least one result type table and at least one column set
  @computed get isTableStepComplete(): boolean {
    return !!this.columns.length && this.tables.some((t) => t.results?.length > 0);
  }

  @action setDownloadingChart(value: boolean) {
    this.downloadingChart = value;
  }

  @action setDecimalPoints = (value: undefined | 0 | 1 | 2): void => {
    this.decimalPoints = value;
  };

  @action setCalcMissingFallback = (value: boolean): void => {
    this.calcMissingFallback = value;
  };

  @action setCalcMissingFallbackValue = (value: number): void => {
    this.calcMissingFallbackValue = value;
  };

  @action toggleRobotTool = (): void => {
    if (this.robot.open) {
      // reset any tool state on close
      this.robot.filters = [];
      this.robot.dataset = null;
    }
    this.robot.open = !this.robot.open;
    this.ui.setLeftTabFolded(!this.robot.open);
    getMixpanel(this.parent).track("Robot Analysis Tool", { Status: this.robot.open ? "Open" : "Close" });
  };

  @action selectRobotRow = (filters: IFilter[], dataset: string): void => {
    this.robot.calc = null;
    this.robot.filters = filters;
    this.robot.dataset = dataset;
  };

  @action selectRobotCalcRow = (calc: any): void => {
    this.robot.filters = [];
    this.robot.dataset = null;
    this.robot.calc = calc;
  };

  @action resetCalc(): void {
    Object.assign(this.calc, { open: false, type: "Decimal", eqString: "", editIdx: -1 }); // reset calc
  }

  @action setCalcIsOpen(newValue: boolean, edit?: boolean): void {
    if (newValue && !edit) {
      this.resetCalc(); // reset when opening
    }
    this.calc.open = newValue;
    this.ui.setLeftTabFolded(!newValue);
  }

  @action doCalc(): void {
    // Does calculation
    const editTable = this.calc.editIdx !== -1 ? this.tables[this.calc.editIdx] : null;
    const nextId = generateTableId(this.tables); // for new calc
    const tables: number[] = []; // the tables the calculation relates to
    const table: any = {
      // the calc table to be added
      name: `Table ${nextId} - Calculation`,
      id: nextId,
      colors: generateColorsWithPalette(getCalcTableColourNumber(this.columns)),
      graph: true,
      ...(editTable || {}), // don't overwrite edited calc name/id/colors/graph
      type: "calc",
      format: this.calc.type,
      eq: eqStringToEq(this.calc.eqString).map((str) => {
        let table;
        if (str.includes("Row")) {
          const row: number = parseFloat(str.slice(3));
          const tableRowTotals = getTableRowTotals(this.tables); // total rows for each table
          const lastTableRows = tableRowTotals.map((rowTotal, idx) => {
            // the last row number in each table
            const totalPrevRows = tableRowTotals.slice(0, idx).reduce((acc, next) => acc + next, 0);
            return totalPrevRows + rowTotal;
          });

          // TODO: Check on table index logic - may not cover tables being deleted
          const tableIdx = lastTableRows.findIndex((lastTableRow) => row <= lastTableRow);
          table = this.tables[tableIdx];

          if (table.type === "calc") {
            tables.push(table!.id);
            return "calc"; // calc string part
          } else {
            // now find the specific row variable/category combination that must also be filtered
            const rowCatCombos = buildArrayCombos(table.rows.map((row) => row.values));
            const rowCatCombo = rowCatCombos[rowCatCombos.length - 1 + row - lastTableRows[tableIdx]];
            const rowFilters = rowCatCombo ? rowCatCombo.map((cat, idx) => ({ dimension: table.rows[idx].dimension, values: [cat] })) : [];

            tables.push(table!.id);
            // note before type was added row was the only type of eq object, so always default to row type if not explicitly specified
            return {
              type: "Row",
              filters: [...table!.filters, ...rowFilters].map((filter) => filter.dimension),
              filterValues: [...table!.filters, ...rowFilters].map((filter) => filter.values[0]),
            };
          }
        } else if (str.includes("Table")) {
          const tableId = +str.slice(5);
          tables.push(tableId);
          table = this.tables.find((t) => t.id === tableId);
          return { type: "Table" };
        }
        return str; // non calc string part
      }),
    };
    table.tables = tables;
    if (editTable) {
      this.tables[this.calc.editIdx] = table; // update existing calc table
    } else {
      this.tables.push(table); // push the new table into insight tables
    }
    this.resetCalc();
    this.setCalcIsOpen(false);
  }

  @action editCalculation(tableId: number): void {
    const calcIdx = this.tables.findIndex((table) => table.id === tableId);
    const calcTable = calcIdx !== -1 ? this.tables.find((table) => table.id === tableId) : null;
    if (calcTable) {
      this.calc.eqString = calcToFormula(calcTable, this.tables).join("");
      this.calc.type = calcTable.format;
      this.calc.editIdx = calcIdx;
    }
    this.setCalcIsOpen(true, true);
  }

  @action setUserDefinedCharts(variables: any): void {
    this.userDefinedCharts = variables;
  }

  // Hierarchical Table Actions
  @action saveTableQuery(tableId: number | undefined) {
    const activeTableIdx = this.tables.findIndex((table) => table.id === tableId);

    if (activeTableIdx >= 0) {
      const updatedQueryParams = {
        filters: this.filters,
        rows: this.rows,
        columns: this.columns,
        userSelectedDatasets: this.userSelectedDatasets,
        datasetsToQuery: this.datasetsToQuery,
        dimensions: this.dimensions,
      };

      if (this.results.length > 0 && "results" in this.results[0]) {
        updatedQueryParams["results"] = this.results[0].results;
      } else {
        updatedQueryParams["results"] = this.results;
      }

      const oldColors = this.tables[activeTableIdx].colors || undefined;
      const newColors = generateColorsWithPalette(getResultTableColourNumber(this.rows, this.columns));
      // Check if old colors of active table exist or the length is the same as the new colors
      if (!oldColors || oldColors.length === 0 || oldColors.length !== newColors.length) {
        updatedQueryParams["colors"] = newColors;
      }

      const updatedTable = Object.assign({}, this.tables[activeTableIdx], updatedQueryParams);
      this.tables[activeTableIdx] = updatedTable;
    }
  }

  @action async setActiveTableId(tableId: number | undefined) {
    this.activeTableId = tableId;

    if (tableId) {
      const activeTable = this.tables.find((table) => table.id === tableId);

      if (activeTable) {
        const { filters, rows, columns, results, userSelectedDatasets, datasetsToQuery, dimensions } = activeTable;

        this.filters = filters ? cloneDeep(filters) : [];
        this.rows = rows ? cloneDeep(rows) : [];
        this.columns = this.columns && this.columns.length > 0 ? this.columns : columns ? cloneDeep(columns) : [];
        this.results = results || [];
        this.userSelectedDatasets = userSelectedDatasets || [];
        this.datasetsToQuery = datasetsToQuery || [];
        this.dimensions = dimensions || {};

        await this.reloadQuickStat();

        this.ui.setLeftTabFolded(false);
      }
    } else {
      this.ui.setLeftTabFolded(true);
    }
  }

  @action addTable(): number {
    const tableId = generateTableId(this.tables);

    this.tables.push({
      name: `Table ${tableId}`,
      id: tableId,
      type: "result",
      // colors: this.getSelected(this.row).map(
      //   () =>
      //     `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(
      //       Math.random() * 256
      //     )})`
      // ),
      filters: [],
      rows: [],
      columns: this.tables[0]?.columns ? this.tables[0].columns : [],
      userSelectedDatasets: [],
      datasetsToQuery: this.tables[0]?.columns
        ? [
            ...uniq(
              this.tables[0]?.columns.flatMap((col) =>
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                Object.entries(this.globalDimensions[col.dimension].source)
                  .filter(([, v]) => v)
                  .map(([k]) => k),
              ),
            ),
          ]
        : [],
      dimensions: {},
      // rowValues: this.row === "" ? [""] : this.getSelected(this.row),
      // filters: this.filters,
      // filterValues: this.filters.map(filter => this.getSelected(filter)[0]),
      graph: true,
    });
    // getMixpanel(this.parent).track("Add to Insight", { Dataset: this.dataset });
    // this.counter++;
    // this.sort("Both");

    return tableId;
  }

  @action pivotTable() {
    if (this.activeTableId) {
      const tempRows = [...this.rows];
      const tempColumns = [...this.columns];
      this.rows = tempColumns;
      this.columns = tempRows;
      this.saveTableQuery(this.activeTableId);
    } else {
      const tempRows = [...this.tables[0].rows];
      const tempColumns = [...this.tables[0].columns];
      this.tables[0].rows = tempColumns;
      this.tables[0].columns = tempRows;
      this.columns = tempRows;
      this.rows = tempColumns;
    }
  }

  @action clearRows() {
    if (this.activeTableId) {
      this.rows = [];
      this.results = [];
      this.saveTableQuery(this.activeTableId);
    } else {
      this.tables[0].rows = [];
      this.tables[0].results = [];
    }
  }

  @action changeTableName(tableId: number, name: string) {
    const matchedTables = this.tables.filter((table) => table.id === tableId);
    if (matchedTables.length === 1) {
      matchedTables[0].name = name;
    }
  }

  @action duplicateTable(tableId: number) {
    const matchedTables = this.tables.filter((table) => table.id === tableId);
    if (matchedTables.length === 1) {
      const tableCopy = cloneDeep(matchedTables[0]);
      tableCopy.name = `Copy of ${tableCopy.name}`;
      tableCopy.id = generateTableId(this.tables);
      if (tableCopy.rows.length > 0) {
        tableCopy.colors = generateColorsWithPalette(this.generateArrayCombo(tableCopy.rows.map((row) => row.values)).length);
      } else {
        tableCopy.colors = generateColorsWithPalette(
          this.generateArrayCombo(getCombinedDims(tableCopy.columns).map((col) => col.values)).length,
        );
      }
      this.tables.push(tableCopy);
    }
  }

  @action deleteTable(tableId: number): void {
    const tableIndex = this.tables.findIndex((table) => table.id === tableId);
    if (tableIndex !== -1) {
      this.tables.splice(tableIndex, 1);
    }
  }

  // avoid using unless absolutely necessary - this moves store logic into the component
  @action setRows(rows: IFilter[]): void {
    if (rows) {
      this.rows = rows;
    } else {
      this.rows = [];
    }

    // update table
    if (this.activeTableId) {
      this.saveTableQuery(this.activeTableId);
    }
  }

  // avoid using unless absolutely necessary - this moves store logic into the component
  @action setColumns(columns: IFilter[]): void {
    if (columns) {
      this.columns = columns;
    } else {
      this.columns = [];
    }

    // update table
    if (this.activeTableId) {
      this.saveTableQuery(this.activeTableId);
    }
  }

  // avoid using unless absolutely necessary - this moves store logic into the component
  @action setFilters(filters: IFilter[]): void {
    if (filters) {
      this.filters = filters;
    } else {
      this.filters = [];
    }

    // update table
    if (this.activeTableId) {
      this.saveTableQuery(this.activeTableId);
    }
  }

  // Multi-index Charts Actions
  // Get all unique table variables
  @action setChartVariables(): void {
    const allTableVariables: any = this.tables.reduce((prev, curr) => prev.concat(curr.columns).concat(curr.rows), []);
    this.chartVariables = _.uniqWith(allTableVariables, _.isEqual);
  }

  @action updateChart(key: string, value: any): void {
    this.chart = { ...this.chart, [key]: value };
  }

  @action setChartLayout(number: ChartLayout): void {
    this.chartLayout = number;
  }

  @action updateTableGraph(id?: number): void {
    if (id) {
      // When toggle on/off chart tables: reset "userDefinedCharts" & "customChartColors" to only show the available variables on the Chart Drag&Drop section
      this.setUserDefinedCharts({});
      this.setCustomChartColors([]);

      this.tables.forEach((table) => {
        if (table.id === id) {
          table.graph = !table.graph;
          return;
        }
      });
    }
  }

  @action setCustomChartColors(colors: string[]): void {
    this.customChartColors = colors;
  }

  @action updateTableColor(tableID: number, idx: number, color: string): void {
    const findTable = this.tables.find((table) => table.id === tableID);
    if (findTable) {
      findTable.colors.splice(idx, 1, color);
    }
  }

  // load all datasets information
  @action async getAllDatasets(): Promise<void> {
    this.loading.getAllDatasets = true;
    const res: any = await requestDataApi.get("v2/search", {}, this.parent.token ?? "");
    this.allDatasets = res.body.data.datasets;
    this.loading.getAllDatasets = false;
  }

  @action async initialiseGlobalDimensions(): Promise<void> {
    this.loading.initialiseGlobalDimensions = true;
    // TODO: Remove temporary workaround of hardcoding datasets for prod qs once performance issues are fixed
    const res: any = await requestDataApi.post("v2/qs", {}, this.parent.token ?? "");
    this.globalDimensions = sortDimensionValues(transformResToDims(res));
    this.loading.initialiseGlobalDimensions = false;
  }

  // Use this action in situations where we want to track auto-selected datasets, i.e. when a selected variable or category only belong to one dataset for instance
  @action setDatasetsToQueryWithAnalytics(datasets: string[]): void {
    if (this.datasetsToQuery.length !== 1 && datasets.length === 1) {
      const key = datasets[0];
      getMixpanel(this.parent).track("Insight Builder > Select Dataset", {
        "Dataset Key": key,
        "Dataset Name": this.getDatasetName(key),
        "Auto-Selected": true,
      });
    }
    this.datasetsToQuery = datasets;
  }

  // reload QuickStat data
  @action async reloadQuickStat(): Promise<void> {
    this.loading.reloadQuickStat = true;
    // autoFilterTransform(this); // run auto adjustments on [rows,columns,filters] before queries, see function comments

    // compile params
    const params: any = {
      filters: this.qsAllFiltersProcessed,
    };
    if (this.datasetsToQuery.length > 0) {
      params.datasets = this.datasetsToQuery;
    }

    const res: any = await requestDataApi.post("v2/qs", params, this.parent.token ?? "");

    // update datasets
    const resDatasets = res?.body?.data?.datasets.filter((val) => !!val);
    // currently if no access to dataset then qs returns empty array - handle appropriately
    if (!resDatasets.length) {
      const errorId = this.activeTableId ? `${this.activeTableId}` : "global";
      // determine if the failed request is due to a broken request body or no dataset access
      if (params.datasets?.length === 1 && !this.allDatasets.filter((ds) => ds.key === params.datasets[0]).length) {
        if (!this.errors.find((e) => e.id === errorId && e.type === "dataset")) {
          this.errors.push({
            id: errorId,
            type: "dataset",
            text: `Unable to access dataset with key "${params.datasets[0]}".`,
            meta: {
              table: this.activeTableId,
              dataset: params.datasets[0],
            },
          });
        }
      } else {
        // broken request body, generally this is because the underlying dataset variables/categories changed due to a re-ingest
        if (!this.errors.find((e) => e.id === errorId && e.type === "general")) {
          this.errors.push({
            id: errorId,
            type: "general",
            text: "Invalid request body.",
            meta: { table: this.activeTableId },
          });
        }
      }
      this.loading.reloadQuickStat = false;
      return; // stop on errors
    }

    this.setDatasetsToQueryWithAnalytics(resDatasets.map((ds) => ds.key).sort((a, b) => collator.compare(a, b)));
    if (this.datasetsToQuery.length === 1) {
      // only one possible dataset left, so auto-select it for the user
      this.userSelectedDatasets = [...this.datasetsToQuery];
    }

    // update results
    this.results = resDatasets.map((dataset) => ({ key: dataset.key, results: dataset.results || [] }));

    // update qs filters
    const resSelected = getResSelectedAndCompulsory(resDatasets, this.userDeselectedDimensions); // get all selected qs filters from response
    const newFilters = getNewQsFilters(this, resSelected); // get updates to qs filters, if any, from response
    qsFilterTypes.forEach((type) => (this[type] = newFilters[type]));

    // update dimensions after qs filters have had a chance to compare changes in dimensions between the previous request and the current one
    this.dimensions = sortDimensionValues(transformResToDims(res));

    // if there are no multi-category variables selected, but we have results from quickstat - then assign a random filter/row variable to be the table column
    if (this.results.some((x) => x.results?.length > 0) && this.tables.length === 1) {
      if (this.tables[0].columns.length === 0) {
        const moveType = newFilters.filters[0] ? "filters" : "rows"; // if no filters set then use rows
        const moveIdx = this[moveType].findIndex((filter) => filter.values.length === 1);
        if (moveIdx !== -1) {
          this.columns = [newFilters[moveType][moveIdx]];
          this[moveType].splice(moveIdx, 1);

          // move dynamic query to global columns if set
          const dim = newFilters[moveType][moveIdx].dimension;
          if (this.dynamicQueries.tables[this.activeTableId!]?.[dim]) {
            this.dynamicQueries.columns[dim] = cloneDeep(this.dynamicQueries.tables[this.activeTableId!]?.[dim]);
            delete this.dynamicQueries.tables[this.activeTableId!][dim];
          }
        }
      }
    }

    // update dynamicQueries category sort order
    this.sortDynamicQueries();

    // update table
    if (this.activeTableId) {
      this.saveTableQuery(this.activeTableId);
    }

    // check dynamic dims values are in dimensions, if not update to suit
    // this occurs when the qs response no longer has data for the originally selected dynamic values
    const dynamicCheckList = entries(this.dynamicQueries.columns).map(([name, object]) => ({ name, object }));
    if (this.activeTableId && this.dynamicQueries.tables[this.activeTableId]) {
      dynamicCheckList.push(...entries(this.dynamicQueries.tables[this.activeTableId]).map(([name, object]) => ({ name, object })));
    }
    for (const check of dynamicCheckList) {
      const { name, object } = check;
      const dimValues = this.dimensions[name].values;
      if (!dimValues.length) {
        continue;
      }
      for (const type of ["gte", "lte"]) {
        if (object[type]) {
          const exists = dimValues.some((value) => value.name === object[type]);
          if (!exists) {
            // if "from" pick first available value, if "to" pick last available value
            object[type] = type === "gte" ? dimValues[0].name : dimValues[dimValues.length - 1].name;
          }
        }
      }
    }

    this.loading.reloadQuickStat = false;
  }

  // select dimension (variable)
  @action async selectDimension(dimension: string, source: Source): Promise<void> {
    const dimType = getDimType(dimension);
    const data = getDimensionMetadata(this.dimensions[dimension]);
    if (Object.keys(comparableDimensions).includes(dimType) && this.columns.some((x) => getDimType(x.dimension) === dimType)) {
      this.columns.push({ dimension, values: [], data });
    } else {
      this.filters.push({ dimension, values: [], data }); // put in filters by default for now
    }

    // update datasets based on selection, datasets should filter to only include sources in selection
    this.setDatasetsToQueryWithAnalytics(this.datasetsToQuery.filter((dataset) => includes(Object.keys(source), dataset)));

    // default to dynamic query on select compatible variable
    if (RANGE_COMPATIBLE_VARIABLES.includes(dimension)) {
      this.toggleDynamicDim(dimension, true, "range");
    }

    await this.reloadQuickStat();
  }

  @action deselectDimension(dimension: string): void {
    qsFilterTypes.forEach((type) => {
      this[type] = this[type].filter((filter) => filter.dimension !== dimension);
    });
    this.removeDynamicDim(dimension);
    this.reloadQuickStat();
  }

  @action setUserDeselectedDimensions(dimension: string): void {
    if (this.userDeselectedDimensions.indexOf(dimension) < 0) {
      this.userDeselectedDimensions.push(dimension);
    }
  }

  @action selectAllDimensions(dimensions: string[], sources: string[][]): void {
    // update filters with new selections
    const allQsFilters = this.qsAllFilters;
    // determine which options are not already selected
    const newSelections = dimensions.filter((dimension) => !allQsFilters[dimension]);
    const nextFilters = cloneDeep([...this.filters]);
    newSelections.forEach((dimension) => {
      const data = getDimensionMetadata(this.dimensions[dimension]);
      nextFilters.push({ dimension, values: [], data });
    });
    this.filters = nextFilters;

    // filter datasets based on selection - logic is to only keep datasets that exist in every options source list
    this.setDatasetsToQueryWithAnalytics(
      sources[0].filter((firstOptSource) => sources.every((sourceArray) => includes(sourceArray, firstOptSource))),
    );
    this.reloadQuickStat();
  }

  @action deselectAllDimensions(dimensions: string[]): void {
    qsFilterTypes.forEach((type) => {
      const next = cloneDeep([...this[type]]);
      this[type] = next.filter((filter) => !includes(dimensions, filter.dimension));
    });
    for (const dim of dimensions) {
      this.removeDynamicDim(dim);
    }
    this.reloadQuickStat();
  }

  // select value (category)
  @action async selectValue(dimension: string, value: Value, source: Source): Promise<void> {
    this.updateDimensionValues(dimension, [value]);

    // update datasets based on selection, datasets should filter to only include sources in selection
    this.setDatasetsToQueryWithAnalytics(this.datasetsToQuery.filter((dataset) => includes(Object.keys(source), dataset)));

    await this.reloadQuickStat();
  }

  @action async deselectValue(dimension: string, removeValue: Value): Promise<void> {
    qsFilterTypes.forEach((type) => {
      const foundDim = find(this[type], (filter) => filter.dimension === dimension);
      if (foundDim) {
        foundDim.values = foundDim.values.filter((value) => value !== removeValue);
      }
    });
    await this.reloadQuickStat();
  }

  // TODO: Rework the logic here to avoid code repetition
  @action updateDimensionValues(dimension: string, newValues: Value[]): void {
    // check if dimension already exists in selected items
    let type;
    let newType;
    if (find(this.rows, (item) => item.dimension === dimension)) {
      type = "rows";
    } else if (find(this.columns, (item) => item.dimension === dimension)) {
      type = "columns";
    } else if (find(this.filters, (item) => item.dimension === dimension)) {
      type = "filters";
    }

    const dimType = getDimType(dimension);

    // existing dimension
    if (type) {
      const foundDim = find(this[type], (filter) => filter.dimension === dimension);
      const combinedValues = uniq([...foundDim.values, ...newValues]);

      if (type === "filters") {
        // if new value length is greater than one, it needs to be in rows or columns
        if (combinedValues.length > 1) {
          // allocate to columns if columns are not specified
          newType = Object.keys(this.columns).length > 0 ? "rows" : "columns";

          // add dimension and values to new type
          this[newType].push({ dimension, values: combinedValues, data: foundDim.data });

          // remove from filters
          const foundIdx = findIndex(this.filters, (item) => item.dimension === dimension);
          this.filters.splice(foundIdx, 1);
        } else {
          foundDim.values = combinedValues;
        }
      } else {
        foundDim.values = combinedValues;
      }
    } else if (Object.keys(comparableDimensions).includes(dimType) && this.columns.some((x) => getDimType(x.dimension) === dimType)) {
      const data = getDimensionMetadata(this.dimensions[dimension]);
      newType = "columns";
      this[newType].push({ dimension, values: uniq(newValues), data });
    } else {
      // if new value length is greater than one, it needs to be in rows or columns
      if (newValues.length > 1) {
        // allocate to columns if columns are not specified
        newType = Object.keys(this.columns).length > 0 ? "rows" : "columns";
      } else {
        newType = "filters";
      }

      const data = getDimensionMetadata(this.dimensions[dimension]);
      this[newType].push({ dimension, values: uniq(newValues), data });
    }
  }

  @action async selectAllValues(dimension: string, values: Value[], sources: string[][]): Promise<void> {
    this.updateDimensionValues(dimension, values);

    // filter datasets based on selection - logic is to only keep datasets that exist in every options source list
    this.setDatasetsToQueryWithAnalytics(
      sources[0].filter((firstOptSource) => sources.every((sourceArray) => includes(sourceArray, firstOptSource))),
    );
    await this.reloadQuickStat();
  }

  @action async deselectAllValues(dimension: string): Promise<void> {
    qsFilterTypes.forEach((type) => {
      const foundDim = find(this[type], (filter) => filter.dimension === dimension);
      if (foundDim) {
        foundDim.values = [];
      }
    });
    await this.reloadQuickStat();
  }

  // select dataset (source)
  @action async selectDataset(dataset: string): Promise<void> {
    getMixpanel(this.parent).track("Insight Builder > Select Dataset", {
      "Dataset Key": dataset,
      "Dataset Name": this.getDatasetName(dataset),
    });
    this.datasetsToQuery = [dataset];
    this.userSelectedDatasets = [dataset];
    await this.reloadQuickStat();
  }

  // save insight
  @action async saveInsight(id: number | undefined, suitcaseId: number | undefined, imgBase64 = undefined): Promise<any> {
    // compile params
    const params: any = this.getInsightBody(id, suitcaseId, imgBase64);

    let pushId = id;
    if (id) {
      await requestAdminAPI.put(`insights/${id}`, params, this.parent.token!);
    } else {
      const res: any = await requestAdminAPI.post("insights", params, this.parent.token!);
      pushId = res?.body?.data?.insight?.id;
    }

    if (suitcaseId) {
      const res: any = await requestAdminAPI.get(`suitcases/${suitcaseId}`, "", this.parent.token!);
      if (res.body.data) {
        this.parent.socket.emit("updateSuitcases", {
          ids: res.body.data.suitcase.users.map((user: any) => user.id).filter((id) => id !== this.parent.user?.id),
        });
      }
    }

    // push to insight page
    window.location.href = `/insights/${pushId || ""}`;
  }

  @action async newInsight(): Promise<void> {
    this.initialiseGlobalDimensions();

    this.dynamicQueries = defaultDynamicQueries;
    this.filters = [];
    this.rows = [];
    this.columns = [];
    this.datasetsToQuery = [];
    this.results = [];
    this.tables = [];
    this.canvas = [];
    this.selectedBrush = "cycle";
    this.chart = DEFAULT_CHART_CONFIG;
    this.errors = [];
    this.decimalPoints = undefined;
    this.RATAutoQuery = undefined;

    const tableId = this.addTable();
    await this.setActiveTableId(tableId);
  }

  // preloaded data should be of the format of the insight response (i.e. res.body.data.insight)
  @action async loadInsight(id: number, refreshData = false, publicUrlKey?: string, preloadedData?: any): Promise<void> {
    this.errors = [];
    this.loading.loadInsight = true;

    let insight: any = preloadedData || null;
    if (!insight) {
      const url = publicUrlKey ? `insights/${id}/${publicUrlKey}` : `insights/${id}`;
      const res: any = await requestAdminAPI.get(url, "", this.parent.token!);
      insight = res.body?.data?.insight;
    }
    if (insight) {
      const insightJson = insight.json ? JSON.parse(insight.json) : "";
      const {
        filters,
        rows,
        columns,
        userSelectedDatasets,
        datasetsToQuery,
        results,
        tables,
        userDefinedCharts,
        chartLayout,
        chart,
        canvas,
        selectedBrush,
        customChartColors,
        dynamicQueries,
        autoRefresh,
        decimalPoints,
        calcMissingFallback,
        calcMissingFallbackValue,
        RATAutoQuery,
      } = insightJson;
      this.filters = filters || [];
      this.rows = rows || [];
      this.columns = columns || [];
      this.dynamicQueries = dynamicQueries || defaultDynamicQueries;
      this.userSelectedDatasets = userSelectedDatasets || [];
      this.datasetsToQuery = datasetsToQuery || [];
      this.results = results || [];
      this.tables = tables || [];
      this.userDefinedCharts = userDefinedCharts || {};
      this.chartLayout = chartLayout || 1;
      this.chart = chart || DEFAULT_CHART_CONFIG;
      this.canvas = canvas || [];
      this.selectedBrush = selectedBrush || "cycle";
      this.customChartColors = customChartColors || [];
      this.autoRefresh = !!autoRefresh;
      this.decimalPoints = decimalPoints;
      this.calcMissingFallback = !!calcMissingFallback;
      this.calcMissingFallbackValue = calcMissingFallbackValue;
      this.RATAutoQuery = RATAutoQuery;
    } else {
      // @TODO - error handling
    }

    // Fetch latest data from quickstat
    if (refreshData) {
      for (const t of this.tables.filter((_t) => _t.type === "result")) {
        await this.setActiveTableId(t.id);
        this.saveTableQuery(t.id);
      }
      this.setActiveTableId(undefined);
    }

    this.loading.loadInsight = false;
  }

  @action async loadSmartInsight(dataset: string, smartInsightDimensions: IFilter[]): Promise<void> {
    this.loading.loadSmartInsight = true;
    // Select dataset
    await this.selectDataset(dataset);
    // Select categories for auto-selected "selectedDimensions" by dataset
    // Save a shallow copy of current "this.selectedDimensions" for selecting categories loop
    const datasetSelectedDimensions = [...this.selectedDimensions];
    for (const dim of datasetSelectedDimensions) {
      const { dimension, values } = dim;
      // Find current dim index in "smartInsightDimensions"
      const dimIndex = smartInsightDimensions.findIndex((dim) => dim.dimension === dimension);
      // Only select categories if:
      // the current dim in "datasetSelectedDimensions" has no values &
      // the current dim in updated "this.selectedDimensions" has no values (not auto-selected by other selection)
      if (values.length === 0 && this.selectedDimensions.find((dim) => dim.dimension === dimension)?.values.length === 0) {
        for (const val of smartInsightDimensions[dimIndex].values) {
          await this.selectValue(dimension, val, { dataset: true });
        }
      }
      // Remove selected dim from "smartInsightDimensions"
      smartInsightDimensions.splice(dimIndex, 1);
    }
    // Select the remaining "dimensions"
    for (const dim of smartInsightDimensions) {
      const { dimension, values } = dim;
      const ifDimNotSelected = this.selectedDimensions.findIndex((dim) => dim.dimension === dimension) < 0;
      // No need to select dim if it already exists in "this.selectedDimensions"
      if (ifDimNotSelected) {
        await this.selectDimension(dimension, { dataset: true });
      }
      // Select categories
      for (const val of values) {
        await this.selectValue(dimension, val, { dataset: true });
      }
    }
    this.loading.loadSmartInsight = false;
  }

  // Clear/Init builder store for Smart Insight.
  @action async initSmartInsight(): Promise<void> {
    this.filters = [];
    this.rows = [];
    this.columns = [];
    this.dynamicQueries = defaultDynamicQueries;
    this.datasetsToQuery = [];
    this.userSelectedDatasets = [];
    this.tables = [];
    this.results = [];
    this.initialiseGlobalDimensions();
    this.getAllDatasets();
    await this.reloadQuickStat();
    await this.parent.favourite.getFavourites("smart-insight");
  }

  // TODO: Refactor so that the same code block is reused again for both row and column sorting
  @action sortByValues(
    type: "Row" | "Column" | "Both" | "Calc",
    ascending: boolean,
    tableId: number | undefined,
    categories?: any[],
  ): void {
    // common sort function
    const orderFunc = ascending ? (a, b) => a["Value"] - b["Value"] : (a, b) => b["Value"] - a["Value"];
    // common function to get orderedSets
    const getOrderedSets = (variablesToReorder, sortedResults) => {
      const orderedSets = {};
      variablesToReorder.forEach((variable) => (orderedSets[variable.dimension] = new Set<Value>()));
      sortedResults.forEach((res) =>
        variablesToReorder.forEach((variable) => {
          const resultCategory = res[variable.dimension];
          orderedSets[variable.dimension].add(resultCategory);
        }),
      );
      return orderedSets;
    };
    // common function to update sorted table level and global columns
    const updateAllColumns = (variablesToReorder, orderedSets) => {
      this.tables
        .filter((table) => table?.type !== "calc")
        .forEach((table) =>
          variablesToReorder.forEach((variable, j) => {
            table.columns[j] = { dimension: variable.dimension, values: Array.from(orderedSets[variable.dimension]) };
          }),
        );
      variablesToReorder.forEach((variable, j) => {
        this.columns[j] = { dimension: variable.dimension, values: Array.from(orderedSets[variable.dimension]) };
      });
    };

    if (type === "Calc") {
      const table = this.tables.find((t) => t.id === tableId)!;
      const variablesToReorder = [...this.columns];

      const combinedDimensions: IFilter[] = getCombinedDims(this.columns);
      const newColumnIndex = buildArrayCombos(combinedDimensions.map((col) => col.values));
      const calcTableResults = newColumnIndex.map((colCombo) => {
        const res = {};
        variablesToReorder.forEach((variable, idx) => {
          res[variable.dimension] = colCombo[idx].includes(":::") ? colCombo[idx].split(":::")[1] : colCombo[idx];
        });
        res["Value"] = getCNumber(
          this.tables,
          table,
          colCombo,
          combinedDimensions,
          getMissingFallback(this.calcMissingFallback, this.calcMissingFallbackValue),
        );
        return res;
      });
      const sortedResults = calcTableResults.sort(orderFunc);
      const orderedSets = getOrderedSets(variablesToReorder, sortedResults);
      updateAllColumns(variablesToReorder, orderedSets);
    } else if (type === "Row") {
      const table = this.tables.find((t) => t.id === tableId)!;
      const variablesToReorder = [...this.columns];
      let filteredResults = [...table.results];
      for (let i = 0; i < table.rows.length; i++) {
        filteredResults = [...filteredResults].filter((res) => res[table.rows[i].dimension].toString() === categories![i].toString());
      }
      const sortedResults = filteredResults.sort(orderFunc);
      const orderedSets = getOrderedSets(variablesToReorder, sortedResults);
      updateAllColumns(variablesToReorder, orderedSets);
    } else {
      // TODO: for now we update all single row index tables. Think about to enable the ability to only update specified table
      this.tables
        .filter((table) => table?.type !== "calc")
        .filter((table) => table.rows.length === 1)
        .forEach((table) => {
          const variablesToReorder = [...table.rows];
          let filteredResults = [...table.results];
          // For handling "comparable" variables in the column
          const newColumns: any[] = [];
          for (let i = 0; i < categories!.length; i++) {
            const catValue = categories![i].toString();
            if (catValue.includes(":::")) {
              newColumns.push(this.columns.find((col) => col.dimension === catValue.split(":::")[0]));
            } else {
              newColumns.push(this.columns[i]);
            }
          }
          // Remove "variable" label and ":::" for each "category" if exists
          const newCategories = categories!.map((cat) => (cat.toString().includes(":::") ? cat.split(":::")[1] : cat));
          for (let i = 0; i < newColumns.length; i++) {
            filteredResults = [...filteredResults].filter(
              (res) => res[newColumns[i]!.dimension]?.toString() === newCategories[i].toString(),
            );
          }
          const sortedResults = filteredResults.sort(orderFunc);
          const orderedSets = getOrderedSets(variablesToReorder, sortedResults);

          variablesToReorder.forEach((variable, j) => {
            table.rows[j] = { dimension: variable.dimension, values: Array.from(orderedSets[variable.dimension]) };
          });
        });
    }
  }

  @action clearDatasetsToQuery(): void {
    this.datasetsToQuery = [];
  }

  // do not use for selection/deselection, only for toggling on and off via the range selector
  @action toggleDynamicDim(dim: string, on: boolean, type?: "range" | "last"): void {
    const inColumns = this.columns.find((col) => col.dimension === dim);
    if (!inColumns) {
      this.dynamicQueries.tables[this.activeTableId!] = {};
    }
    const dimLocation = inColumns ? this.dynamicQueries.columns : this.dynamicQueries.tables[this.activeTableId!];
    if (on) {
      // for keeping existing options relevant to the dynamic type if set
      const existing = { ...(dimLocation[dim] || {}), type };
      // type specific opts, test "last" first because old insights only ever had range opts without type specified
      const allowedOpts = type === "last" ? ["last"] : ["gte", "lte"];
      allowedOpts.push("type", "exclude"); // general opts / properties
      dimLocation[dim] = pick(existing, allowedOpts);
    } else {
      delete dimLocation[dim];
    }
  }

  // call after deselection of the variable
  @action removeDynamicDim(dim: string): void {
    delete this.dynamicQueries.columns[dim];
    delete this.dynamicQueries.tables[this.activeTableId!]?.[dim];
  }

  // rsArgs are the arguments from react select onChange handler
  @action async changeDynamicDim(dim: string, changeType: "lte" | "gte" | "last", rsArgs: any[]): Promise<void> {
    const [newValue, { action }] = rsArgs;
    const dynamicFilter: DynamicFilter = this.getDynamicDimFilter(dim)!;
    if (action === "select-option") {
      const { value } = newValue;
      dynamicFilter[changeType] = value;
      // on selection if dim is in filters, move it over to rows (if in future we want to move to columns instead we have to update dynamicQueries object too)
      if (this.getDimLocation(dim) === "filters") {
        const dimToMove = this.filters.splice(
          this.filters.findIndex((item) => item.dimension === dim),
          1,
        )[0];
        this.rows.push(dimToMove);
      }
    } else if (action === "clear") {
      delete dynamicFilter[changeType];
    }
    await this.reloadQuickStat();
    // refresh chart config
    this.refreshChartConfig();
  }

  // for all selected dims, ensures the location set within dynamicQueries matches up, use when changes have occurred elsewhere
  @action refreshDynamicQueries(): void {
    const selectedDims = [...this.filters, ...this.rows, ...this.columns].map((item) => item.dimension);
    for (const dim of selectedDims) {
      const existingQuery = this.getDynamicDimFilter(dim);
      if (existingQuery) {
        const inColumns = this.columns.find((col) => col.dimension === dim);
        const dynamicInColumns = this.dynamicQueries.columns[dim];
        if (inColumns === dynamicInColumns) {
          // do nothing, no change
        } else if (inColumns) {
          this.dynamicQueries.columns[dim] = cloneDeep(existingQuery);
          // have to delete from all tables where set if moved to columns
          for (const table of this.tables) {
            if (this.dynamicQueries.tables[table.id]?.[dim]) {
              delete this.dynamicQueries.tables[table.id][dim];
            }
          }
        } else {
          this.dynamicQueries.tables[this.activeTableId!][dim] = cloneDeep(existingQuery);
          delete this.dynamicQueries.columns[dim];
          // @TODO - consider what should happen to all the other tables when moved from columns to rows, columns should be locked and this shouldn't happen
        }
      }
    }
  }

  // sort dynamicQueries dim categories
  @action sortDynamicQueries(): void {
    for (const filterType of ["rows", "columns"]) {
      this[filterType] = this[filterType].map((item) => {
        const { dimension } = item;
        if (this.getDynamicDimFilter(dimension)) {
          sortSingleFilterValues(item, this.dimensions); // sort categories
        }
        return item;
      });
    }
  }

  // clear dynamic query settings on variables where the tab was opened but no configuration was applied
  @action clearEmptyDynamicQueries(): void {
    const configOk = (config: DynamicFilter) => {
      if (config.type === "last" && !config.last) {
        return false;
      } else if (config.type === "range" && (!config.lte || !config.gte)) {
        return false;
      }
      return true;
    };
    for (const tableId of Object.keys(this.dynamicQueries.tables)) {
      for (const dim of Object.keys(this.dynamicQueries.tables[tableId])) {
        if (!configOk(this.dynamicQueries.tables[tableId][dim])) {
          delete this.dynamicQueries.tables[tableId][dim];
        }
      }
    }
    for (const dim of Object.keys(this.dynamicQueries.columns)) {
      if (!configOk(this.dynamicQueries.columns[dim])) {
        delete this.dynamicQueries.columns[dim];
      }
    }
  }

  // refresh customChartColors and userDefinedCharts, generally used when a query is modified by BuilderSelector.tsx
  @action refreshChartConfig(): void {
    // If one category is added/removed which makes builder.uniqueMultiCategoryVariables.length changes and user has defined charts we need to reset the userDefinedCharts and customChartColors
    if (this.userDefinedChartsVariables.length > 0 && this.uniqueMultiCategoryVariables.length !== this.userDefinedChartsVariables.length) {
      this.setUserDefinedCharts({});
      this.setCustomChartColors([]);
    }
    // Update "customChartColors" when it exists and the existing color number is not the same as the new color number.
    if (this.hasCustomChartColors) {
      const newCustomChartColorNumber = getCustomChartColorNumber(
        this.chart.type,
        this.chartXAxisArray,
        this.chartKey,
        this.chartTables,
        this.columns,
        this.chartSeries,
        this.chartLegend,
      );
      if (this.customChartColors.length !== newCustomChartColorNumber) {
        const colors = brushes[this.selectedBrush].brush(this.canvas.length ? this.canvas : DEFAULT_COLOR_PALETTE, [
          newCustomChartColorNumber,
        ]);
        this.setCustomChartColors(colors.reduce((pre, cur) => pre.concat(cur), []));
      }
    }
    // Update userDefinedCharts when categories are changed
    if (this.userDefinedChartsVariables.length > 0) {
      const updatedUserDefinedCharts = {
        xAxis: getUpdatedDims(this.userDefinedCharts.xAxis, this.uniqueMultiCategoryVariables),
        legend: getUpdatedDims(this.userDefinedCharts.legend, this.uniqueMultiCategoryVariables),
        series: getUpdatedDims(this.userDefinedCharts.series, this.uniqueMultiCategoryVariables),
      };
      this.setUserDefinedCharts(updatedUserDefinedCharts);
    }
  }

  @action toggleAutoRefresh(): void {
    this.autoRefresh = !this.autoRefresh;
  }

  @action isValidSimplePieChart(type?): boolean {
    return (
      this.chartLegend.length === 0 &&
      this.chartXAxisArray.length === 1 &&
      this.chartSeries.length === 0 &&
      (type || this.chart.type).includes("Pie")
    );
  }

  @action setRATAutoQuery(query: RATToolQuery | undefined) {
    this.RATAutoQuery = query;
  }

  // TODO: Merge with function in MultiIndexTable
  // Create all label combinations: e.g [["1", "2"], ["a", "b"]] > [["1", "a"], ["1", "b"], ["2", "a"], ["2", "b"]]
  generateArrayCombo(arrays: (string | number)[][]): (string | number)[][] {
    const result: (string | number)[][] = [];
    const max = arrays.length - 1;
    const helper = (array, i) => {
      for (let j = 0, l = arrays[i]?.length; j < l; j++) {
        const clonedArray = array.slice(0); // clone arr
        clonedArray.push(arrays[i][j]);
        if (i === max) {
          result.push(clonedArray);
        } else {
          helper(clonedArray, i + 1);
        }
      }
    };
    helper([], 0);
    return result;
  }

  // compile JSON body for insight
  getInsightBody(id: number | undefined, suitcaseId: number | undefined, imgBase64 = undefined): ObjectAny {
    const body: any = {
      json: JSON.stringify(this.json),
      name: this.parent.insight.draftName || this.parent.insight.result.name || "Untitled Insight",
      keywords: this.parent.insight.draftKeywords,
    };

    if (imgBase64) {
      // generated chart image
      body.banner = imgBase64;
    }
    if (id) {
      body.create_version = true; // existing insight
    } else {
      body.key = this.datasetsToQuery[0] || ""; // new insight
    }

    if (suitcaseId) {
      // save new insight to specified suitcase
      body.suitcase_id = suitcaseId;
    }
    return body;
  }

  getDatasetName(datasetKey: string): string {
    return this.allDatasets.find((source) => source.key === datasetKey)?.name || "";
  }

  // return whether a dimension is being dynamically configured in the QS query
  getDynamicDimFilter(dim: string): DynamicFilter | null {
    return this.dynamicQueries.columns[dim] || this.dynamicQueries.tables[this.activeTableId!]?.[dim];
  }

  getDimLocation(dim: string): "columns" | "rows" | "filters" {
    if (this.columns.find((item) => item.dimension === dim)) {
      return "columns";
    } else if (this.rows.find((item) => item.dimension === dim)) {
      return "rows";
    }
    return "filters";
  }
}
