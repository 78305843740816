import { action, observable } from "mobx";
import { database } from "common/api";
import { ObjectAny } from "common/helpers/types";
import Store from "common/store";

type FavouriteType = "smart-insight";

export default class Favourite {
  @observable favourites: ObjectAny = {};

  parent: Store;

  constructor(parent: Store) {
    this.parent = parent;
  }

  @action getFavourites = async (type: FavouriteType): Promise<any> => {
    const url = `favourites?type=${type}`;
    const res: any = await database.get(url, "", this.parent.token!);
    if (res?.statusCode === 200) {
      const formatFavourites = res.body?.data?.favourites.map((item) => ({ ...item.json, id: item.id }));
      this.favourites[type] = formatFavourites;
    } else {
      return "Network error. Please try again later.";
    }
  };

  @action saveFavourite = async (type: FavouriteType, json: ObjectAny): Promise<any> => {
    const body = { type, json: JSON.stringify(json) };
    const res: any = await database.post("favourites", body, this.parent.token!);
    if (res?.statusCode === 200) {
      await this.getFavourites(type);
      return res.body?.data?.favourite;
    }
  };

  @action unsaveFavourite = async (id: number): Promise<boolean> => {
    const res: any = await database.delete(`favourites/${id}`, "", this.parent.token!);
    if (res?.statusCode === 200) {
      await this.getFavourites(res.body?.data?.favourite.type);
      return true;
    } else {
      return false;
    }
  };
}
