import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Dataset from "common/store/dataset";
import { Button } from "semantic-ui-react";
import { DataTypeEditor } from "./DataTypesEditor";

interface DataTypesList {
  dataset?: Dataset;
}

const getInferredTypes = (value) => (!value || isNaN(Number(value)) ? { type: "string" } : { type: "number" });

const Component = (props: DataTypesList) => {
  const { urlupload } = props.dataset!;
  const { staged, dataTypes } = urlupload;
  const columns = staged![0];

  const configureDataTypes = () => {
    const inferredTypes = {};
    columns.forEach((col, idx) => {
      const value = staged![1][idx].trim();
      inferredTypes[col] = getInferredTypes(value);
    });
    urlupload.setDataTypes(inferredTypes);
  };

  const clearDataTypes = () => {
    urlupload.setDataTypes({});
  };

  const changeSingleDataType = (col, dataType) => {
    const newDataTypes = { ...dataTypes, [col]: dataType };
    urlupload.setDataTypes(newDataTypes);
  };

  React.useEffect(() => {
    // Infer data types for new columns that do not exist in dataTypes when uploading a new CSV file
    if (Object.keys(dataTypes).length) {
      columns.forEach((col) => {
        if (!dataTypes[col]) {
          const value = staged![1][columns.indexOf(col)].trim();
          const inferredType = getInferredTypes(value);
          changeSingleDataType(col, inferredType);
        }
      });
    }
  }, []);

  return (
    <>
      {Object.keys(dataTypes).length === 0 ? (
        <Button color="purple" onClick={configureDataTypes} fluid>
          Configure data types
        </Button>
      ) : (
        <>
          <Button color="red" onClick={clearDataTypes} fluid className="mb-4">
            Clear data types
          </Button>
          {columns.map((col, idx) => (
            <div key={`${col}-${idx}`} className="mb-3">
              <p className="mb-1">{col}</p>
              <DataTypeEditor dataType={dataTypes[col]} onChangeDataType={(dataType) => changeSingleDataType(col, dataType)} />
            </div>
          ))}
        </>
      )}
    </>
  );
};

export const DataTypesList = inject((stores: any) => ({
  dataset: stores.store.dataset,
}))(observer(Component));
