import * as React from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { PageContainer } from "./PageContainer";
import { getMixpanel } from "common/api";
import { Label } from "semantic-ui-react";
import { DataCatalogByDataset } from "component/DataCatalog/DataCatalogByDataset";
import { DataCatalogByVariable } from "component/DataCatalog/DataCatalogByVariable";
import { Heading } from "common/styledComponents/elements";
import Protected from "component/protected";

interface DataCatalogProps {
  store: Store;
}

export const DataCatalog: React.FC<DataCatalogProps> = Protected(
  inject("store")(
    observer(({ store }: DataCatalogProps) => {
      const { builder } = store;
      const [groupBy, setGroupBy] = React.useState("dataset");

      useEffect(() => {
        set();
        getMixpanel(store).track("Page view", { Page: "Data Catalog" }); // only fire on mount
      }, []);

      const set = async () => {
        await builder.getAllDatasets();
        await builder.newInsight();
      };

      return (
        <PageContainer>
          <Helmet>
            <title>Data Catalog</title>
          </Helmet>
          <div>
            <Heading>Seer Data Catalog</Heading>

            <div className="d-inline-block">
              <span>See: </span>
              <Label size="large" color="blue" className="btn-unstyled" onClick={() => setGroupBy("dataset")}>
                Variables grouped by dataset
              </Label>
              <Label size="large" color="purple" className="btn-unstyled" onClick={() => setGroupBy("variable")}>
                Datasets grouped by variable
              </Label>
            </div>

            {groupBy === "dataset" ? <DataCatalogByDataset /> : <DataCatalogByVariable />}
          </div>
        </PageContainer>
      );
    }),
  ),
);
