import * as L from "leaflet";
import "leaflet-fullscreen/dist/Leaflet.fullscreen.js";
import { useLeafletContext } from "@react-leaflet/core";
import * as React from "react";

interface FullScreenControlProps {
  onFullscreen?: () => any;
  onExitFullScreen?: () => any;
}

export const FullScreenControl = (props: FullScreenControlProps): null => {
  const { onFullscreen, onExitFullScreen } = props;
  const context = useLeafletContext();
  const map = context.map;
  React.useEffect(() => {
    const fsControl = new L.Control["Fullscreen"]();
    map.addControl(fsControl);
    const changeHandler = () => {
      if (map["isFullscreen"]() && onFullscreen) {
        onFullscreen();
      } else if (onExitFullScreen) {
        onExitFullScreen();
      }
    };
    map.on("fullscreenchange", changeHandler);
    return () => {
      map.off("fullscreenchange", changeHandler);
      map.removeControl(fsControl);
    };
  }, []);
  return null;
};
