import * as React from "react";
import { Icon } from "semantic-ui-react";
import { COLORS } from "component/UI/common";

export interface ErrorMessageProps {
  heading: string;
  description: string;
}

export const ErrorMessage = (props: ErrorMessageProps): JSX.Element => {
  const { heading, description } = props;

  return (
    <div
      className="text-center w-100 d-flex flex-column align-items-center"
      style={{ borderRadius: 5, border: `2px dashed ${COLORS.grey300}`, padding: "40px 20px" }}
    >
      <div
        className="d-flex align-items-center justify-content-center mb-3"
        style={{ backgroundColor: COLORS.indigo100, width: 50, height: 50, borderRadius: 25 }}
      >
        <Icon name="inbox" className="text-secondary" style={{ marginRight: -2, marginTop: -5 }} />
      </div>
      <p className="fw-700 fs-1500 mb-2" style={{ color: COLORS.red500 }}>
        {heading}
      </p>
      <p className="fw-600 fs-1250 my-0" style={{ color: COLORS.grey800 }}>
        {description}
      </p>
    </div>
  );
};
