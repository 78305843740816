import * as React from "react";
import { Accordion, Icon } from "semantic-ui-react";
import { CustomAccordionContent, CustomAccordionTitle } from "./includes/CustomFormAccordion.style";

export const CustomFormAccordion = (props): JSX.Element => {
  const { items, accordionIdx } = props;
  const [activeIndex, setActiveIndex] = React.useState(null);

  const clickHandler = (idx) => {
    if (idx !== activeIndex) {
      setActiveIndex(idx);
    } else {
      setActiveIndex(null);
    }
  };

  const pressHandler = (e, idx) => {
    if (e.key === "Enter" || e.key === " ") {
      clickHandler(idx);
      e.preventDefault();
    }
  };

  return (
    <Accordion>
      {items.map((item, idx) => (
        <div key={item.title}>
          <CustomAccordionTitle
            id={`accordion-${accordionIdx}-${idx}`}
            tabIndex={0}
            aria-controls={`accordion-content-${accordionIdx}-${idx}`}
            aria-expanded={activeIndex === idx ? "true" : "false"}
            role="button"
            active={activeIndex === idx}
            onClick={() => clickHandler(idx)}
            onKeyDown={(e) => pressHandler(e, idx)}
          >
            <h3>{item.title}</h3>
            <Icon name="chevron right" className="mr-0" style={{ transform: `rotate(${activeIndex === idx ? "90deg" : "0"})` }} />
          </CustomAccordionTitle>
          <CustomAccordionContent
            id={`accordion-content-${accordionIdx}-${idx}`}
            aria-labelledby={`accordion-${accordionIdx}-${idx}`}
            role="region"
            active={activeIndex === idx}
          >
            <div dangerouslySetInnerHTML={{ __html: item.content }} />
          </CustomAccordionContent>
        </div>
      ))}
    </Accordion>
  );
};
