import * as L from "leaflet";
import { orangeIcon, baseIconOptions } from "pages/Maps/includes/Icons";
import { ObjectAny } from "common/helpers/types";

const customConfig = {
  education: {
    color: "#b32c25",
    badge: "https://seer-media.s3.ap-southeast-2.amazonaws.com/images/d3a40ff3-1b3e-424d-ab8e-a717ee485891.png",
    icon: L["ExtraMarkers"].icon({ ...baseIconOptions, markerColor: "#b32c25", iconColor: "#f1dd09" } as ObjectAny),
  },
  employment: {
    color: "#1d65c5",
    badge: "https://seer-media.s3.ap-southeast-2.amazonaws.com/images/7aff423b-99f8-48ce-b418-82de57e96635.png",
    icon: L["ExtraMarkers"].icon({ ...baseIconOptions, markerColor: "#1d65c5", iconColor: "#f1dd09" } as ObjectAny),
  },
};

export const style = (layerOpts) => (feature) => {
  if (feature) {
    // nothing custom allowed here currently, limit of config JSON
  }
  return layerOpts.defaultStyle || {};
};

export const onEachFeature = (layerOpts) => (feature: any, leaflet_layer: any) => {
  const metricColor = customConfig[layerOpts.name]?.color || "#e49323";
  const badge = customConfig[layerOpts.name]?.badge || null;
  const { name, data_metric, data_text, text, insight_link, rap_link } = feature.properties;
  const popup = `
    <div class="d-flex" style="max-width: 100%; width: 225px;">
      <div style="min-width: 16px; height: 16px; background: ${metricColor}; margin-right: 0.5rem;"></div>
      <div style="font-weight: bold; white-space: normal; font-size: 11px; font-family: 'Open Sans', sans-serif; width: 100%;">
        <p>${name}</p>
        <div>
          ${!!badge ? `<img src="${badge}" alt="${layerOpts.name}" width="60px" style="float: right; shape-outside: circle(); -webkit-shape-outside: circle(); margin-left: 3px;" />` : ""}
          ${
            data_metric && data_text
              ? `
            <p>
              <span style="font-weight: bold; font-size: 12px;">${(Number(data_metric) * 100).toFixed()}%</span> ${data_text}
            </p>
          `
              : ""
          }
          <p style="font-weight: normal;">${text}</p>
        </div>
        <div class="mb-3 mt-4 d-flex justify-content-between">
          ${!!insight_link ? `<a class="wnac-link" href="${insight_link}" target="_blank">View Insights</a>` : ""}
          ${!!rap_link ? `<a class="wnac-link" href="${rap_link}" target="_blank">View RAP</a>` : ""}
        </div>
      </div>
    </div>
  `;
  leaflet_layer.bindPopup(popup);
  leaflet_layer.on("mouseover", () => leaflet_layer.openPopup()); // force open on hover so user doesn't have to click
  // layer.on("mouseover", () => {
  //   layer.setStyle({ fillOpacity: 0.95 });
  // });
  // layer.on("mouseout", () => {
  //   layer.setStyle({ fillOpacity: 1 });
  // });
};

export const pointToLayer = (layerOpts) => (_feature, latLng) =>
  L.marker(latLng, { icon: customConfig[layerOpts.name]?.icon || orangeIcon });
