import * as React from "react";
import { Helmet } from "react-helmet";

import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { DatasetDisplay } from "component/DataCatalog/DatasetDisplay";

const SEPARATOR = "|||";

export const DataCatalogByDataset: React.FC<{ store?: Store }> = inject("store")(
  observer(({ store }) => {
    const { builder } = store!;

    const groupByDataset = (dims: Object) => {
      const groupedDims = {};

      Object.keys(dims).forEach(d => {
        Object.keys(dims[d].source).forEach(s => {
          if (!groupedDims[s]) {
            groupedDims[s] = [];
          }

          groupedDims[s].push({
            variable: d,
            type: dims[d].type,
          });
        });
      });

      return groupedDims;
    };

    const getDatasetText = (key: string): string =>
      builder.allDatasets.find(d => d.key === key)?.name || key;

    const allDatasetVariables = groupByDataset(builder.globalDimensions);

    const sortDatasets = variables =>
      Object.keys(variables)
        .sort((a, b) => getDatasetText(a.split(SEPARATOR)[0]).localeCompare(getDatasetText(b.split(SEPARATOR)[0])))
        .reduce((obj, key) => {
          obj[key] = variables[key];
          return obj;
        }, {});

    const sortedDatasetVariables = sortDatasets(allDatasetVariables);

    const leftColumn = variables =>
      Object.keys(variables).reduce((obj, key, i) => {
        if (!(i % 2)) {
          obj[key] = variables[key];
        }
        return obj;
      }, {});

    const rightColumn = variables =>
      Object.keys(variables).reduce((obj, key, i) => {
        if (i % 2) {
          obj[key] = variables[key];
        }
        return obj;
      }, {});

    const leftSingleVars = leftColumn(sortedDatasetVariables);
    const rightSingleVars = rightColumn(sortedDatasetVariables);

    return (
      <>
        <Helmet>
          <title>Data Catalog</title>
        </Helmet>
        <div className="mt-5">
          <h3>Datasets</h3>

          <div className="d-flex">
            <div className="w-50 mx-4">
              {Object.keys(leftSingleVars).map(d => (
                <DatasetDisplay datasetName={getDatasetText(d)} datasetDimensions={leftSingleVars[d]} />
              ))}
            </div>

            <div className="w-50">
              {Object.keys(rightSingleVars).map(d => (
                <DatasetDisplay
                  datasetName={getDatasetText(d)}
                  datasetDimensions={rightSingleVars[d]}
                />
              ))}
            </div>
          </div>
        </div>
      </>
    );
  })
);
