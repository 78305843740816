import { COLORS } from "component/UI/common";

export const DefaultMentionsInputStyle = {
  control: {
    backgroundColor: "#fff",
    fontSize: 16,
    fontWeight: "normal",
    fontFamily: "Open Sans",
    borderRadius: 5,
  },

  "&multiLine": {
    control: {
      minHeight: 64,
      maxHeight: 400,
    },
    highlighter: {
      padding: "8px 8px 34px 8px",
      border: "1px solid transparent",
      overflow: "auto",
      overflowWrap: "anywhere",
    },
    input: {
      padding: "8px 8px 34px 8px",
      border: `1px solid ${COLORS.grey200}`,
      borderRadius: 5,
      color: COLORS.grey900,
      outline: 0,
      lineHeight: "inherit",
      overflow: "auto",
      overflowWrap: "anywhere",
    },
  },

  suggestions: {
    zIndex: 2,
    list: {
      backgroundColor: "white",
      border: `1px solid ${COLORS.grey300}`,
      color: COLORS.grey900,
      fontSize: 14,
    },
    item: {
      padding: "5px 15px",
      "&focused": {
        backgroundColor: COLORS.indigo200,
      },
    },
  },
};

export const DisabledMentionsInputStyle = {
  "&multiLine": {
    control: {
      minHeight: 32,
    },
    highlighter: {
      padding: 0,
      border: "1px solid transparent",
      overflowWrap: "anywhere",
    },
    input: {
      padding: 0,
      border: "1px solid transparent",
      color: COLORS.grey900,
      outline: 0,
      lineHeight: "inherit",
      overflowWrap: "anywhere",
    },
  },
};

export const DefaultMentionStyle = {
  color: COLORS.blue,
  position: "relative",
  zIndex: 1,
  textShadow: "1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white",
};
