// hex string to rgb tuple ([#, #, #] = [r, g, b])
export const hexToRgb = (hex: string): number[] => {
  const rgb = [0, 0, 0];
  if (hex.length === 4) {
    // 3 digits
    rgb[0] = parseInt(`0x${hex[1]}${hex[1]}`, 16);
    rgb[1] = parseInt(`0x${hex[2]}${hex[2]}`, 16);
    rgb[2] = parseInt(`0x${hex[3]}${hex[3]}`, 16);
  } else if (hex.length === 7) {
    // 6 digits
    rgb[0] = parseInt(`0x${hex[1]}${hex[2]}`, 16);
    rgb[1] = parseInt(`0x${hex[3]}${hex[4]}`, 16);
    rgb[2] = parseInt(`0x${hex[5]}${hex[6]}`, 16);
  }
  return rgb;
};

// rgb tuple ([#, #, #] = [r, g, b]) to hex string
export const rgbToHex = (rgb: number[]): string => {
  const pad = (str) => (str.length === 1 ? `0${str}` : str);
  const r = pad(rgb[0].toString(16));
  const g = pad(rgb[1].toString(16));
  const b = pad(rgb[2].toString(16));
  return `#${r}${g}${b}`;
};

// for working with historic colors which are not always hex
export const colorStringType = (str: string): string => (str.length > 7 ? "rgb" : "hex");

// ignoring alpha, turn rgb string into rgb tuple ([#, #, #] = [r, g, b])
export const rgbStringToRgb = (rgbStr: string): number[] => {
  const rgb = rgbStr
    .replace(/[^,0-9]/g, "")
    .split(",")
    .map((str) => Number(str));
  if (rgb.some((num) => isNaN(num)) || rgb.length !== 3) {
    // rgb string in invalid format so return black by default
    return [0, 0, 0];
  }
  return rgb;
};

// Finds an intermediate value between two bounding values based on a fractional position between them
export const interpolate = (start: number, end: number, fraction: number): number => fraction * (end - start) + start;

// mimics behaviour similar to python zip https://docs.python.org/3.3/library/functions.html#zip
// copied from https://stackoverflow.com/questions/4856717/javascript-equivalent-of-pythons-zip-function
export const zip = (rows: any[]): any[] => rows[0].map((_, c) => rows.map((row) => row[c]));

// js implementation of numpy.linspace
// https://numpy.org/doc/stable/reference/generated/numpy.linspace.html
// https://github.com/sloisel/numeric/blob/master/src/numeric.js#L922 (implementation)
export const linspace = (a: number, b: number, n: number): number[] => {
  if (typeof n === "undefined") {
    n = Math.max(Math.round(b - a) + 1, 1);
  }
  if (n < 2) {
    return n === 1 ? [a] : [];
  }
  const ret = Array(n);
  n--;
  for (let i = n; i >= 0; i--) {
    ret[i] = (i * b + (n - i) * a) / n;
  }
  return ret;
};

export const brushCycle = (canvas: string[], rows: number[]): any[] => {
  let nextColour = 0;
  const colours: any[] = [];
  rows.forEach((numCols) => {
    const rowColours: string[] = [];
    for (let i = 0; i < numCols; i++) {
      rowColours.push(canvas[nextColour]);
      nextColour = (nextColour + 1) % canvas.length;
    }
    colours.push(rowColours);
  });
  return colours;
};

export const brushCycleFade = (canvas: string[], rows: number[]): any[] => {
  const totalColors = rows.reduce((total, curr) => total + curr);
  let nextColour = 0;
  const colours: any[] = [];
  const cycles = Math.ceil(totalColors / canvas.length);
  const factors = linspace(0, 1, cycles + 1); // add more cycles if the fade is too strong on the last cycle
  let cycle = 0;

  const fadeCanvas = (oldCanvas) =>
    oldCanvas.map((color) => {
      const rgb = hexToRgb(color);
      const fadeRgb = rgb.map((colorComponent) => Math.round(interpolate(colorComponent, 255, factors[cycle])));
      return rgbToHex(fadeRgb);
    });
  let currCanvas = fadeCanvas(canvas);
  rows.forEach((numCols) => {
    const rowColours: string[] = [];
    for (let i = 0; i < numCols; i++) {
      rowColours.push(currCanvas[nextColour]);
      nextColour = (nextColour + 1) % canvas.length;
      if (nextColour === 0) {
        // fade canvas, cycle complete
        cycle++;
        currCanvas = fadeCanvas(canvas);
      }
    }
    colours.push(rowColours);
  });
  return colours;
};

export const generateInterpolatedCanvas = (oldCanvas: string[], totalColors: number): string[] => {
  const rgbOldCanvas = oldCanvas.map((color) => hexToRgb(color));
  const rgbNewCanvas: any[] = [];
  const factors = linspace(0, oldCanvas.length - 1, totalColors);
  factors.forEach((factor) => {
    const factorFloor = Math.floor(factor);
    const factorCeiling = Math.ceil(factor);
    const fraction = factor % 1;
    const interpolatedRgbPairs = zip([rgbOldCanvas[factorFloor], rgbOldCanvas[factorCeiling]]);
    const interpolatedColor = interpolatedRgbPairs.map((pair) => Math.round(interpolate(pair[0], pair[1], fraction)));
    rgbNewCanvas.push(interpolatedColor);
  });
  return rgbNewCanvas.map((color) => rgbToHex(color));
};

export const brushInterpolate = (canvas: string[], rows: number[]): any[] => {
  let nextColour = 0;
  const colours: any[] = [];

  const totalColors = rows.reduce((total, curr) => total + curr);
  const interpolatedCanvas = generateInterpolatedCanvas(canvas, totalColors);

  rows.forEach((numCols) => {
    const rowColours: string[] = [];
    for (let i = 0; i < numCols; i++) {
      rowColours.push(interpolatedCanvas[nextColour]);
      // just to be safe but shouldn't go past length as should be same as totalColors
      nextColour = (nextColour + 1) % interpolatedCanvas.length;
    }
    colours.push(rowColours);
  });
  return colours;
};
