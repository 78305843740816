import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Icon } from "semantic-ui-react";
import Store from "common/store";
import { getCategorySelectionState, TABLE_SELECTION_STATE_OPTIONS } from "common/helpers/explore";

interface IExploreTablePrompt {
  store?: Store;
}

// export const ExploreTablePrompt = observer(({ insightbuilder }: IExploreTablePrompt ): JSX.Element => {

export const ExploreTablePrompt: React.FC<IExploreTablePrompt> = inject("store")(
  observer((props) => {
    const { builder } = props.store!;

    const { GREY_LABEL, RED_DOT, GREEN_DOT, NO_DOT } = TABLE_SELECTION_STATE_OPTIONS;

    // Check to show different prompt sentences
    const whatsSelectionState = getCategorySelectionState(builder, "What");
    const howsSelectionState = getCategorySelectionState(builder, "How");
    const whensSelectionState = getCategorySelectionState(builder, "When");
    const wheresSelectionState = getCategorySelectionState(builder, "Where");
    const datasetSelectionState = builder.userSelectedDatasets.length ? NO_DOT : RED_DOT;

    const promptItems = [
      {
        name: "Topic",
        selectionState: whatsSelectionState,
      },
      {
        name: "Quantity",
        selectionState: howsSelectionState,
      },
      {
        name: "Time",
        selectionState: whensSelectionState,
      },
      {
        name: "Location",
        selectionState: wheresSelectionState,
      },
      {
        name: "Dataset",
        selectionState: datasetSelectionState,
      },
    ];

    return (
      <div className="w-100 mt-4 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-between rounded-3 bg-light p-3" style={{ height: 300, width: 500 }}>
          <div className="m-5">
            <img src="/assets/no_results_icon.png" />
          </div>
          <div className="ff-primary">
            <p className="fs-1250 fw-300 text-medium mb-2">
              {builder.tables.length > 0
                ? builder.activeTableId
                  ? "Follow the red dots & the tips below to create a table that answers your query"
                  : "Use the actions dropdown next to the table name to edit your table query"
                : "Click the 'Add table' button to start a query"}
            </p>
            <div>
              {promptItems.map((item) => {
                const { name, selectionState } = item;
                return (
                  <div
                    className={
                      selectionState === GREY_LABEL
                        ? "text-grey"
                        : selectionState === NO_DOT
                          ? "text-secondary"
                          : selectionState === GREEN_DOT
                            ? "text-info"
                            : "text-medium"
                    }
                  >
                    <Icon name={selectionState === NO_DOT ? "check circle" : "info circle"} size="small" />
                    <p className="d-inline fs-1000">
                      {`${name} ${
                        selectionState === GREY_LABEL
                          ? "has no options"
                          : selectionState === RED_DOT
                            ? "required"
                            : selectionState === GREEN_DOT
                              ? "has more options"
                              : "done"
                      }`}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }),
);
