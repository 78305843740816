// declare storage exports, getters/setters for cookies, localstorage etc
import Cookies from "universal-cookie";
import { dateSecondsFromNow } from "common/helpers/date";
import { FunctionAny } from "common/helpers/types";

export const cookies = new Cookies();

// simple test for if cookies are enabled for the browser
cookies.set("SEER_COOKIE_TEST", { status: "OK" }, { expires: dateSecondsFromNow(60 * 5), path: "/" });
const getTest = cookies.get("SEER_COOKIE_TEST");
cookies.remove("SEER_COOKIE_TEST", { path: "/" });

export const cookiesAvailable = getTest?.status === "OK";

// retrieve custom format json
const getCustomLsItem = (key: string) => {
  try {
    const value = localStorage.getItem(key);
    if (!value) {
      return null;
    }
    return JSON.parse(value);
  } catch (e) {
    // @TODO - re-enable this logging once direct localstorage use fades out
    // console.log("Invalid custom ls item, did you mean to use localStorage directly?");
    // console.log(e);
    return null;
  }
};

let lsAvailable = true;
try {
  localStorage.setItem("ls_test", "ls_test");
  localStorage.removeItem("ls_test");
} catch {
  lsAvailable = false;
}

// fail gracefully when LS not available
const setCustomLsFunction = (fn: FunctionAny): FunctionAny => (lsAvailable ? fn : () => null);

// custom localstorage getters/setters for handling expiry
export const ls = {
  clear: setCustomLsFunction(() => localStorage.clear()),
  removeItem: setCustomLsFunction((key) => localStorage.removeItem(key)),
  key: setCustomLsFunction((index) => localStorage.key(index)),
  getItem: setCustomLsFunction((key: string): string | null => {
    const item = getCustomLsItem(key);
    if (!item) {
      // may have used ls directly, return whatever would have normally been returned by getItem
      return localStorage.getItem(key);
    }
    const { data, expires } = item;
    if (expires && Date.now() > new Date(expires).getTime()) {
      // expired
      localStorage.removeItem(key);
      return null;
    }
    return data;
  }),
  getExpires: setCustomLsFunction((key: string): number | null => {
    const item = getCustomLsItem(key);
    return item?.expires || null;
  }),
  // expires - unix timestamp or 0 if no expiry
  setItem: setCustomLsFunction((key: string, value: string, expires = 0): void => {
    localStorage.setItem(key, JSON.stringify({ data: value, expires }));
  }),
};
