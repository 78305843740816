import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { Avatar } from "component/Avatar";
import { Comments } from "./includes/Comments";
import { CommentMentions } from "./includes/CommentMentions";
import { getMixpanel } from "common/api";
import { CollaborateTopSection, FilterOption } from "./includes/components";
import { COLORS } from "component/UI/common";

interface Props {
  dashboardID: number;
  collaborators: any[];
  onClose: () => void;
  fullscreen: boolean;
  store?: Store;
}

const Component = (props: Props): JSX.Element => {
  const { dashboardID, collaborators, onClose, fullscreen, store } = props;
  const { user, dashboard: dashboardStore } = store!;

  const [selectedFilter, setSelectedFilter] = React.useState<FilterOption>("unresolved");

  const createComment = async (message: string, replyTo: number | null): Promise<boolean> => {
    getMixpanel(store!).track("Collaborate > Conversations > Send Comment", {
      "Dashboard ID": dashboardID,
      Message: message,
      "Reply Message": replyTo ? "Yes" : "No",
    });
    const success = await dashboardStore.createDashboardComment(dashboardID, message, replyTo);
    if (success) {
      await dashboardStore.refreshDashboardComments(dashboardID);
      setSelectedFilter("unresolved");
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="w-100 d-flex flex-column" style={{ height: "calc(100vh - 200px)" }}>
      <CollaborateTopSection
        activeFilter={selectedFilter}
        filters={[
          { label: "Active", value: "unresolved" },
          { label: "Archived", value: "resolved" },
          { label: "All", value: "all" },
        ]}
        onSelect={(value) => setSelectedFilter(value)}
        fullscreen={fullscreen}
        dashboardID={dashboardID}
        collaborators={collaborators}
        onClose={onClose}
      />
      <div className="d-flex align-items-start w-100 position-relative mb-4" style={{ zIndex: 2 }}>
        <Avatar
          className="cursor-pointer mr-2"
          color={COLORS.indigo500}
          image={user!.avatar}
          name={`${user!.first_name} ${user!.last_name}`}
          size={25}
        />
        <CommentMentions mentionUsers={collaborators} onSave={(message) => createComment(message, null)} />
      </div>
      <Comments
        dashboardID={dashboardID}
        dashboardUsers={collaborators}
        filter={selectedFilter}
        onReply={(message, replyTo) => createComment(message as string, replyTo as number | null)}
      />
    </div>
  );
};

export const Conversations = inject("store")(observer(Component));
