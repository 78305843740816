import styled from "styled-components";

export const FormWrapper = styled.div`
  width: 556px;
  margin: 0 auto;
  margin-top: -90px;
  padding: 20px;
  overflow: auto;
  height: calc(100vh - 180px);
  @media only screen and (max-width: 768px) {
    height: calc(100vh - 200px);
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;
