import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import Protected from "component/protected";
import { Helmet } from "react-helmet";
import { Members } from "component/Members/Members";
import { getMixpanel } from "common/api";
import { ls } from "common/helpers/storage";
import { Button } from "component/UI/Button";
import {
  BaseContainer,
  LeftContentContainer,
  Heading1,
  LeftContentWrapper,
  OnboardingContainer,
  RoleCard,
  StyledGrid,
  RightContentContainer,
  ArtworkContainer,
} from "./includes/Onboarding.style";
import { useHistory } from "react-router-dom";
import { FeatureCarousel } from "pages/SignupLoginForms/includes/FeatureCarousel";
import { COLORS } from "component/UI/common";
import { Icon } from "semantic-ui-react";

interface OnboardingProps {
  store?: Store;
}

const USER_ROLES = [
  {
    role: "Data Analyst",
    description: "You are skilled at exploring data, building datasets and uncovering insights.",
    url: "/assets/onboarding/data_analyst.png",
  },
  {
    role: "Decision Maker",
    description: "You make data-driven decisions that guide your teams strategy.",
    url: "/assets/onboarding/decision_maker.png",
  },
  {
    role: "Data Contributor",
    description: "You help build existing dataset(s) by providing collected data.",
    url: "/assets/onboarding/data_consumer.png",
  },
  {
    role: "Data Enthusiast",
    description: "You are enthusiastic about building your confidence working with data.",
    url: "/assets/onboarding/data_enthusiast.png",
  },
];

export const Component = (props: OnboardingProps): JSX.Element => {
  const { store } = props;
  const { user } = props.store!;
  const history = useHistory();

  const { breakpoint } = store!.ui;
  const isMobileScreen = breakpoint === "mobile";
  const isLargeScreen = breakpoint === "computer" || breakpoint === "largeMonitor" || breakpoint === "wideScreenMonitor";

  const [step, setStep] = React.useState(1);

  React.useEffect(() => {
    getMixpanel(store!).track("Page view", { Page: "Onboarding" }); // only fire on mount
  }, []);

  // Create this button component as its positions are different on large screen and small screen
  const NotSureButton = () => (
    <Button
      onClick={() => {
        setStep(step + 1);
        ls.setItem("userRole", "");
        getMixpanel(store!).track("Onboarding", { Role: "Not sure" });
      }}
      fluid={isMobileScreen}
    >
      I'm not sure <Icon name="arrow right" />
    </Button>
  );

  return (
    <>
      <Helmet>
        <title>Onboarding</title>
      </Helmet>
      <div className="d-flex justify-content-center" style={{ marginTop: -90 }}>
        <OnboardingContainer>
          {step === 1 ? (
            <>
              <LeftContentContainer>
                <LeftContentWrapper
                  content={
                    <>
                      <Heading1>{`Hi ${user?.first_name}!`}</Heading1>
                      <p className="fs-1250 fw-600" style={{ color: COLORS.grey900 }}>
                        Seer Data is an Australian technology company that empowers communities with data for local actions and better
                        decision making. Our vision is to democratise data access for all people.
                      </p>
                    </>
                  }
                  buttons={
                    <Button colorConfig="purpleInverted" fluid={isMobileScreen} onClick={() => setStep(step + 1)}>
                      Continue <Icon name="arrow alternate circle right outline" className="ml-1" />
                    </Button>
                  }
                  currentStep={step}
                />
              </LeftContentContainer>
              <BaseContainer>
                {isLargeScreen ? ( // Use background image for large screen
                  <div
                    style={{
                      backgroundImage: 'url("/assets/onboarding/onboarding_lee_townsend_artwork_large_screen.png")',
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "100%",
                      height: 680,
                    }}
                  ></div>
                ) : (
                  // Use <img> for small screen
                  <ArtworkContainer>
                    <img
                      src="/assets/onboarding/onboarding_lee_townsend_artwork_small_screen.png"
                      alt="Lee Townsend Artwork"
                      style={{ width: "100%" }}
                    />
                  </ArtworkContainer>
                )}
              </BaseContainer>
            </>
          ) : step === 2 ? (
            <>
              <LeftContentContainer>
                <LeftContentWrapper
                  content={
                    <>
                      <Heading1>Which description best matches your role?</Heading1>
                      <p className="fs-1250 fw-600" style={{ color: COLORS.grey900 }}>
                        This will help us personalise your experience.
                      </p>
                    </>
                  }
                  buttons={isLargeScreen ? <NotSureButton /> : <></>}
                  currentStep={step}
                />
              </LeftContentContainer>
              <RightContentContainer>
                <StyledGrid>
                  {USER_ROLES.map((item, idx) => {
                    const { url, role, description } = item;
                    return (
                      <RoleCard
                        key={idx}
                        url={url}
                        heading={role}
                        description={description}
                        onClick={() => {
                          setStep(step + 1);
                          ls.setItem("userRole", role);
                          getMixpanel(store!).track("Onboarding", { Role: role });
                        }}
                      />
                    );
                  })}
                </StyledGrid>
                {!isLargeScreen && (
                  <div className="d-flex justify-content-center justify-content-sm-end mt-5">
                    <NotSureButton />
                  </div>
                )}
              </RightContentContainer>
            </>
          ) : (
            <>
              <LeftContentContainer>
                <LeftContentWrapper
                  content={
                    <>
                      <p className="fs-1500 fw-700 mb-3" style={{ color: COLORS.indigo600 }}>
                        Invite members to join your Seer Data Organisation.
                      </p>
                      <p className="fs-1000 fw-700 mb-2" style={{ color: COLORS.grey900 }}>
                        Email
                      </p>
                      <div style={{ maxHeight: 350, overflowY: "auto", overflowX: "hidden" }}>
                        <Members />
                      </div>
                    </>
                  }
                  buttons={
                    <div className="d-flex flex-column flex-sm-row justify-content-between flex-grow-1">
                      <Button onClick={() => setStep(step - 1)} className="mr-2 mb-2 mb-sm-0">
                        Go back <Icon name="arrow left" />
                      </Button>
                      <Button colorConfig="purpleInverted" onClick={() => history.push("/")}>
                        Continue to platform <Icon name="arrow alternate circle right outline" className="ml-1" />
                      </Button>
                    </div>
                  }
                  currentStep={step}
                />
              </LeftContentContainer>
              <BaseContainer>
                <FeatureCarousel />
              </BaseContainer>
            </>
          )}
        </OnboardingContainer>
      </div>
    </>
  );
};

export const Onboarding = Protected(inject("store")(observer(Component)));
