import * as React from "react";
import { Form } from "semantic-ui-react";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import { SliderProps } from "rc-slider";
import raf from "rc-util/lib/raf";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";

interface IFieldRcSlider extends SliderProps {
  input: any;
  label?: string;
}

const HandleTooltip = (props: {
  value: number;
  children: React.ReactElement;
  visible: boolean;
  tipFormatter?: (value: number) => React.ReactNode;
}) => {
  const { value, children, visible, tipFormatter = (val) => val, ...restProps } = props;
  const tooltipRef = React.useRef<any>();
  const rafRef = React.useRef<number | null>(null);

  const cancelKeepAlign = () => {
    raf.cancel(rafRef.current!);
  };

  const keepAlign = () => {
    rafRef.current = raf(() => {
      tooltipRef.current?.forcePopupAlign();
    });
  };

  React.useEffect(() => {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }
    return cancelKeepAlign;
  }, [value, visible]);

  return (
    <Tooltip
      placement="top"
      overlay={tipFormatter(value)}
      overlayInnerStyle={{ minHeight: "auto" }}
      ref={tooltipRef}
      visible={visible}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

const handleRender: SliderProps["handleRender"] = (node, props) => (
  <HandleTooltip value={props.value} visible={props.dragging}>
    {node}
  </HandleTooltip>
);

export const FieldRcSlider = (props: IFieldRcSlider): JSX.Element => {
  const { input, label, defaultValue, min = 0, max = 100, step = 1 } = props;
  const midVal = (max! - min!) / 2;
  const marks = {
    [min]: `${min}`,
    [midVal]: `${midVal}`,
    [max]: `${max}`,
  };
  return (
    <Form.Field>
      {label && <label className="text-secondary mb-2 fs-1000">{label}</label>}
      <div className="mt-4">
        <Slider min={min} max={max} defaultValue={defaultValue} marks={marks} step={step} handleRender={handleRender} {...input} />
      </div>
    </Form.Field>
  );
};
