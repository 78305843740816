import * as Excel from "exceljs/dist/exceljs.min.js";
import { parse } from "csv-parse/browser/esm";
import { stringify } from "csv-stringify/browser/esm/sync";
import { hashSHA512 } from "common/helpers/string";

export type ParsedRecords = string[][];

export interface SSDCConfig {
  columnHash: string[]; // "variable_name"
  columnRename: [string, string][]; // [["variable_to_rename", "new_name"], ...]
  columnDrop: string[]; // "variable_name"
  columnMerge: any[]; // @TODO final format TBD
  salt: string;
}

// parse csv / xls,xlsx with csv-parse
export const parseInputFile = async (file: File): Promise<ParsedRecords | undefined> => {
  if (!file) {
    return;
  }
  let inputString;

  // get string contents of Excel sheet or csv
  if (file.type !== "text/csv") {
    const workbook = new Excel.Workbook();
    const inBuffer = await file.arrayBuffer();
    await workbook.xlsx.load(inBuffer);
    const outBuffer = await workbook.csv.writeBuffer();
    inputString = outBuffer.toString();
  } else {
    inputString = await file.text();
  }

  // parse and return
  return await new Promise((resolve) => {
    parse(inputString, (err, records) => {
      if (err) {
        console.error(err);
        resolve(undefined);
      } else {
        resolve(records);
      }
    });
  });
};

// apply URL SSDC configuration to records
export const processRecords = async (records: ParsedRecords, config: SSDCConfig): Promise<ParsedRecords> => {
  const { columnHash, columnRename, columnDrop, columnMerge, salt } = config;
  const headerRow = records[0];
  const headerRowMeta = headerRow.map((variable, idx) => ({ variable, idx }));
  const dropIdxs = headerRowMeta.filter((item) => columnDrop.includes(item.variable)).map((item) => item.idx);
  const hashIdxs = headerRowMeta.filter((item) => columnHash.includes(item.variable)).map((item) => item.idx);
  // update header row based on config
  let updatedHeaderRow = headerRow
    .map((variable) => {
      // rename  based on config
      for (const [renameVariable, rename] of columnRename) {
        if (variable === renameVariable) {
          return rename;
        }
      }
      return variable;
    })
    .filter((_, idx) => !dropIdxs.includes(idx)); // drop columns based on config
  // process the rest of the data
  const processedData = records.slice(1);
  const rowLength = processedData[0].length || 0;
  for (let rowIdx = 0; rowIdx < processedData.length; rowIdx++) {
    const row = processedData[rowIdx];
    for (let colIdx = 0; colIdx < rowLength; colIdx++) {
      // hash based on config
      if (!dropIdxs.includes(colIdx) && hashIdxs.includes(colIdx)) {
        row[colIdx] = await hashSHA512(`${row[colIdx]}${salt || ""}`);
      }
    }
    processedData[rowIdx] = row.filter((_, idx) => !dropIdxs.includes(idx)); // drop columns based on config
  }
  // column merges take place last
  for (const mergeConfig of columnMerge || []) {
    const mergeColumnIdxs = mergeConfig.columns.map((name) => updatedHeaderRow.indexOf(name));
    if (mergeColumnIdxs.some((idx) => idx < 0)) {
      continue; // a required column was not found
    }
    // remove source columns and append merged column, to header and records
    updatedHeaderRow = [...updatedHeaderRow.filter((_col, cIdx) => !mergeColumnIdxs.includes(cIdx)), mergeConfig.name];
    for (let rowIdx = 0; rowIdx < processedData.length; rowIdx++) {
      const mergedSource = mergeColumnIdxs.map((idx) => processedData[rowIdx][idx]).join(mergeConfig.separator);
      let mergeString = mergedSource;
      if (mergeConfig.hash) {
        mergeString = await hashSHA512(`${mergedSource}${salt || ""}`);
      }
      processedData[rowIdx] = [...processedData[rowIdx].filter((_col, cIdx) => !mergeColumnIdxs.includes(cIdx)), mergeString];
    }
  }
  return [updatedHeaderRow, ...processedData];
};

// convert ParsedRecords to a File (text/csv) for upload
export const parsedRecordsToFile = async (records: ParsedRecords, filename: string): Promise<File> => {
  // stringify the records to a csv string
  const csvString = stringify(records);
  // encode and create file for upload
  const encoder = new TextEncoder();
  const csvEncoded = encoder.encode(csvString);
  return new File([csvEncoded], filename, { type: "text/csv" });
};
